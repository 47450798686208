<!--
  舆情看板1 事件关注信息
 -->
<template>
  <div class="chart_20">
    <vue-scroll>
      <div class="chart_20_content">
        <div class="info_box" v-for="item in newList" :key="item.id">
          <div class="header">
            <div class="title_box">
              <div class="box_content">
                {{ item.title }}
              </div>
            </div>
            <div class="data_box">
              <div class="bg" :style="{width: item.width}"></div>
              <p><span>收集总数据量</span><span class="date">{{ item.number }}</span></p>
            </div>
          </div>
          <div class="content">
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [
        {
          id: 1,
          title: '小黄车退押金事件',
          content: '2018年6月，用户在微博上吐槽小黄车退押金套路多，困难重重。2018年9月，有媒体曝光小黄车办公区人去楼空深陷欠款危机，多名用户现场排队退款。2018年12月17日，不少用户前往小黄车总部现场办理退押金手续。下午4点半左右，ofo小黄车现场工作人员表示，考虑用户实际情况，退押金业务办理延长至晚上10点。2018年12月18日，ofo的在线排队退押金人数已经达到1013万人。按照ofo押金为99元或199元计算，退押金总额在10亿',
          number: '10671'
        },
        {
          id: 2,
          title: '长春长生疫苗事件',
          content: '2018年7月15日，国家药品监督管理局通告称，在飞行检查中发现长生生物全资子公司长春长生生物科技有限责任公司（简称长春长生）在冻干人用狂犬病疫苗生产过程中有记录造假等严重违反《药品生产质量管理规范》的行为。国家药监局责令吉林省食品药品监督管理局收回长春长生相关的GMP证书、长春长生停产狂犬病疫苗。吉林省食药监局组织调查组进驻长春长生进行调查。根据国家药监局的通报，飞行检查发现的违规行为所涉产品并未出厂上市销售。7月17日，长生生物发布公告，宣称将召回有效期内所有批次的狂犬病疫苗。报道中，长生生物董秘透露，冻干人用狂犬病疫苗销售收入约占公司总收入的一半。同时出口境外的疫苗也开始召回。周末，微信自媒体文章《疫苗之王》整理了几家疫苗公司的发家史，问题疫苗事件进一步发酵。7月22日，国家药监局通报，已查明长春长生编造生产记录和产品检验记录、随意变更工艺参数和设备，违反药品管理法和药品生产质量管理规范，国家药监局已责令长春长生停止生产，收回GMP证书，召回尚未使用的狂犬病疫苗；国家药监局已会同吉林省食药监局立案调查，如涉嫌犯罪将移交公安机关；国家药监局已部署全国疫苗企业自查，并将对全部疫苗企业开展飞行检查。 ',
          number: '15671'
        }
      ]
    }
  },
  computed: {
    newList () {
      this.list.forEach(item => {
        // 假设最大值为2万
        item.width = `${parseInt((item.number / 20000) * 210)}px`
      })
      return this.list
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart_20 {
    height: 672px;
    .chart_20_content {
      padding: 0 12px 0 2px;
      .info_box {
        position: relative;
        width: 680px;
        background-color: #384760;
        padding: 20px 24px 30px 40px;
        &::before {
          content: "";
          position: absolute;
          left: -1px;
          top: 0;
          width: 57px;
          height: 15px;
          display: block;
          background-image: url('../pic/border-left-top.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
        &::after {
          content: "";
          position: absolute;
          left: -1px;
          bottom: 0;
          width: 463px;
          height: 24px;
          display: block;
          background-image: url('../pic/border-left-bottom.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
        .header {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 40px;
          .title_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 220px;
            height: 40px;
            background: rgba(67, 167, 253, 0.3);
            .box_content {
              width: 212px;
              height: 36px;
              background: #43A7FD;
              font-size: 20px;
              font-weight: bold;
              line-height: 36px;
              color: #FFFFFF;
              text-align: center;
              padding: 0 6px;
            }
          }
          .data_box {
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 393px;
            height: 40px;
            background: rgba(94, 105, 123, 0.3);
            border: 1px solid rgba(67, 167, 253, 0.3);
            padding-right: 22px;
            & > p {
              display: flex;
              align-items: center;
              height: 100%;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              line-height: 20px;
              color: #FFFFFF;
              .date {
                font-size: 24px;
                font-family: DIN;
                font-weight: 500;
                line-height: 29px;
                color: #43A7FD;
                margin-left: 13px;
              }
            }
            .bg {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              background-image: url('../pic/N_line.png');
            }
          }
        }
        .content {
          padding-top: 20px;
          & > p {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 24px;
            color: #FFFFFF;
          }
        }
        &+.info_box {
          margin-top: 20px;
        }
      }
    }
  }
</style>
