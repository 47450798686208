import echarts from 'echarts'

function Gauge (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  var myChart = echarts.init(element.children[0])
  // 图表参数配置
  // const backgroundColor = props.data[0].backgroundColor
  const finalData = props.data[0].final_data
  const xAxisMaxValue = props.data[0].x_axis_max_value
  const xAxisMinValue = props.data[0].x_axis_min_value
  const textFontSize = props.data[0].textFontSize
  const axisFontSize = props.data[0].axisFontSize
  const splitNumber = props.data[0].splitNumber
  const XlabelToAxis = props.data[0].XlabelToAxis
  const axisLineWidth = props.data[0].axisLineWidth

  const newDarkGuage = {
    name: '速度',
    type: 'gauge',
    min: xAxisMinValue[0],
    max: xAxisMaxValue[0],
    splitNumber: splitNumber,
    radius: '90%',
    axisLine: { // 坐标轴线
      lineStyle: { // 属性lineStyle控制线条样式
        color: [[0.09, 'lime'], [0.82, '#1e90ff'], [1, '#ff4500']],
        width: axisLineWidth,
        shadowColor: '#fff', // 默认透明
        shadowBlur: 10
      }
    },
    axisLabel: { // 坐标轴小标记
      fontWeight: 'bolder',
      fontSize: axisFontSize,
      distance: XlabelToAxis,
      color: '#fff',
      shadowColor: '#fff', // 默认透明
      shadowBlur: 10
    },
    axisTick: { // 坐标轴小标记
      length: 15, // 属性length控制线长
      lineStyle: { // 属性lineStyle控制线条样式
        color: 'auto',
        shadowColor: '#fff', // 默认透明
        shadowBlur: 10
      }
    },
    splitLine: { // 分隔线
      length: 25, // 属性length控制线长
      lineStyle: { // 属性lineStyle（详见lineStyle）控制线条样式
        width: axisLineWidth,
        color: '#fff',
        shadowColor: '#fff', // 默认透明
        shadowBlur: 10
      }
    },
    pointer: { // 分隔线
      shadowColor: '#fff', // 默认透明
      shadowBlur: 5
    },
    title: {
      textStyle: { // 其余属性默认使用全局文本样式，详见TEXTSTYLE
        fontWeight: 'bolder',
        fontSize: textFontSize,
        fontStyle: 'italic',
        color: '#fff',
        shadowColor: '#fff', // 默认透明
        shadowBlur: 10
      }
    },
    detail: {
      show: true,
      backgroundColor: 'rgba(30,144,255,0.8)',
      borderWidth: 1,
      borderColor: '#fff',
      shadowColor: '#fff', // 默认透明
      shadowBlur: 5,
      offsetCenter: [0, '50%'], // x, y，单位px
      textStyle: { // 其余属性默认使用全局文本样式，详见TEXTSTYLE
        fontWeight: 'bolder',
        color: '#fff'
      }
    },
    data: finalData
  }
  // 图表参数配置
  var option = {
    // backgroundColor: backgroundColor,
    tooltip: { // CSS 提示工具，鼠标动作提示
      formatter: '{a} <br/>{c} {b}' // formatter: "{a} <br/>{b} : {c}%"
    },
    series: [
      newDarkGuage
    ]
  }

  // 使用上述配置显示图标
  myChart.setOption(option)

  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default Gauge
