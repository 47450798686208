<!-- 高影响力微博 -->
<template>
  <div class="chart_13">
    <div class="info_box" v-for="(item, index) in list" :key="index">
      <h1>{{ item.num }}</h1>
      <div class="bar" :style="getNumWidth(item.num)"></div>
      <div class="content">
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [
        {
          title: '小黄车退押金套路多，困难重重',
          num: 2656
        },
        {
          title: '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款',
          num: 2099
        },
        {
          title: '小黄车退押金周期长',
          num: 980
        },
        {
          title: '退押金排队（系统）人数多',
          num: 1327
        },
        {
          title: 'ofo推出押金新政策，押金投资理财项目后可免押金',
          num: 2242
        },
        {
          title: '小黄车推出折扣商城，押金兑换金币购物',
          num: 1318
        }
      ]
    }
  },
  computed: {
    // 图表宽高
    chartBox () {
      const style = {
        height: this.frontDict.parentHeight + 'px'
      }
      return style
    }
  },
  methods: {
    getNumWidth (num) {
      const w = `${parseInt((num / 3000) * 181)}px`

      return {
        width: w
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chart_13 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 580px;
  height: 278px;
  .info_box {
    position: relative;
    display: flex;
    width: 280px;
    height: 82px;
    min-width: 280px;
    background-image: url('./pic/chart13/chart_13_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0 10px;
    & > h1 {
      position: absolute;
      top: 7px;
      left: 10px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 20px;
      color: #FFFFFF;
    }
    .content {
      position: absolute;
      bottom: 0px;
      left: 0px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      padding: 5px 10px;
      & > p {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #FFFFFF;
      }
    }
    .bar {
      position: absolute;
      left: 98px;
      top: 4px;
      width: 181px;
      height: 26px;
      background-image: url('./pic/N_line.png');
    }
  }
}
</style>
