/* eslint-disable indent */
/* eslint-disable no-undef */
import echarts from 'echarts'
(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory)
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('echarts'))
    } else {
        // Browser globals
        factory({}, echarts)
    }
}(this, function (exports, echarts) {
    var log = function (msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg)
        }
    }
    if (!echarts) {
        log('ECharts is not Loaded')
        return
    }
    echarts.registerTheme('default', {
        color: [
            '#43bafe',
            '#51ffc1',
            '#f39e39',
            '#01effc',
            '#e8ea00',
            '#c8d8ff'
        ],
        backgroundColor: 'rgba(0,0,0,0)',
        textStyle: {},
        title: {
            textStyle: {
                color: '#516b91'
            },
            subtextStyle: {
                color: '#818a9c'
            }
        },
        line: {
            itemStyle: {
                normal: {
                    borderWidth: '1'
                }
            },
            lineStyle: {
                normal: {
                    width: '2'
                }
            },
            symbolSize: '2',
            symbol: 'circle',
            smooth: false
        },
        radar: {
            itemStyle: {
                normal: {
                    borderWidth: '1'
                }
            },
            lineStyle: {
                normal: {
                    width: '2'
                }
            },
            symbolSize: '2',
            symbol: 'circle',
            smooth: false
        },
        bar: {
            itemStyle: {
                normal: {
                    barBorderWidth: 0,
                    barBorderColor: '#ffffff'
                },
                emphasis: {
                    barBorderWidth: 0,
                    barBorderColor: '#ffffff'
                }
            }
        },
        pie: {
            itemStyle: {
                normal: {
                    borderWidth: 0,
                    borderColor: '#ffffff'
                },
                emphasis: {
                    borderWidth: 0,
                    borderColor: '#ffffff'
                }
            }
        },
        scatter: {
            itemStyle: {
                normal: {
                    borderWidth: 0,
                    borderColor: '#ffffff'
                },
                emphasis: {
                    borderWidth: 0,
                    borderColor: '#ffffff'
                }
            }
        },
        boxplot: {
            itemStyle: {
                normal: {
                    borderWidth: 0,
                    borderColor: '#ffffff'
                },
                emphasis: {
                    borderWidth: 0,
                    borderColor: '#ffffff'
                }
            }
        },
        parallel: {
            itemStyle: {
                normal: {
                    borderWidth: 0,
                    borderColor: '#ffffff'
                },
                emphasis: {
                    borderWidth: 0,
                    borderColor: '#ffffff'
                }
            }
        },
        sankey: {
            itemStyle: {
                normal: {
                    borderWidth: 0,
                    borderColor: '#ffffff'
                },
                emphasis: {
                    borderWidth: 0,
                    borderColor: '#ffffff'
                }
            }
        },
        funnel: {
            itemStyle: {
                normal: {
                    borderWidth: 0,
                    borderColor: '#ffffff'
                },
                emphasis: {
                    borderWidth: 0,
                    borderColor: '#ffffff'
                }
            }
        },
        gauge: {
            itemStyle: {
                normal: {
                    borderWidth: 0,
                    borderColor: '#ffffff'
                },
                emphasis: {
                    borderWidth: 0,
                    borderColor: '#ffffff'
                }
            }
        },
        candlestick: {
            itemStyle: {
                normal: {
                    color: '#e8ea00',
                    color0: 'transparent',
                    borderColor: '#f39e39',
                    borderColor0: '#01effc',
                    borderWidth: '2'
                }
            }
        },
        graph: {
            itemStyle: {
                normal: {
                    borderWidth: 0,
                    borderColor: '#ffffff'
                }
            },
            lineStyle: {
                normal: {
                    width: 1,
                    color: '#818a9c'
                }
            },
            symbolSize: '2',
            symbol: 'circle',
            smooth: false,
            color: [
                '#43bafe',
                '#51ffc1',
                '#f39e39',
                '#01effc',
                '#e8ea00',
                '#c8d8ff'
            ],
            label: {
                normal: {
                    textStyle: {
                        color: '#ffffff'
                    }
                }
            }
        },
        map: {
            itemStyle: {
                normal: {
                    areaColor: '#f3f3f3',
                    borderColor: '#39c7fb',
                    borderWidth: 0.5
                },
                emphasis: {
                    areaColor: 'rgba(165,231,240,1)',
                    borderColor: '#4dcdfb',
                    borderWidth: 1
                }
            },
            label: {
                normal: {
                    textStyle: {
                        color: '#ffffff'
                    }
                },
                emphasis: {
                    textStyle: {
                        color: '#ffffff'
                    }
                }
            }
        },
        geo: {
            itemStyle: {
                normal: {
                    areaColor: '#f3f3f3',
                    borderColor: '#39c7fb',
                    borderWidth: 0.5
                },
                emphasis: {
                    areaColor: 'rgba(165,231,240,1)',
                    borderColor: '#4dcdfb',
                    borderWidth: 1
                }
            },
            label: {
                normal: {
                    textStyle: {
                        color: '#ffffff'
                    }
                },
                emphasis: {
                    textStyle: {
                        color: '#ffffff'
                    }
                }
            }
        },
        categoryAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#cccccc'
                }
            },
            axisTick: {
                show: false,
                lineStyle: {
                    color: '#333'
                }
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#ffffff'
                }
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: [
                        '#818a9c'
                    ]
                }
            },
            splitArea: {
                show: false,
                areaStyle: {
                    color: [
                        'rgba(250,250,250,0.05)',
                        'rgba(200,200,200,0.02)'
                    ]
                }
            }
        },
        valueAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#cccccc'
                }
            },
            axisTick: {
                show: false,
                lineStyle: {
                    color: '#333'
                }
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#ffffff'
                }
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: [
                        '#818a9c'
                    ]
                }
            },
            splitArea: {
                show: false,
                areaStyle: {
                    color: [
                        'rgba(250,250,250,0.05)',
                        'rgba(200,200,200,0.02)'
                    ]
                }
            }
        },
        logAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#cccccc'
                }
            },
            axisTick: {
                show: false,
                lineStyle: {
                    color: '#333'
                }
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#ffffff'
                }
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: [
                        '#818a9c'
                    ]
                }
            },
            splitArea: {
                show: false,
                areaStyle: {
                    color: [
                        'rgba(250,250,250,0.05)',
                        'rgba(200,200,200,0.02)'
                    ]
                }
            }
        },
        timeAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#cccccc'
                }
            },
            axisTick: {
                show: false,
                lineStyle: {
                    color: '#333'
                }
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#ffffff'
                }
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: [
                        '#818a9c'
                    ]
                }
            },
            splitArea: {
                show: false,
                areaStyle: {
                    color: [
                        'rgba(250,250,250,0.05)',
                        'rgba(200,200,200,0.02)'
                    ]
                }
            }
        },
        toolbox: {
            iconStyle: {
                normal: {
                    borderColor: '#818a9c'
                },
                emphasis: {
                    borderColor: '#ffffff'
                }
            }
        },
        legend: {
            textStyle: {
                color: '#ffffff'
            }
        },
        tooltip: {
            axisPointer: {
                lineStyle: {
                    color: '#01effc',
                    width: 1
                },
                crossStyle: {
                    color: '#01effc',
                    width: 1
                }
            }
        },
        timeline: {
            lineStyle: {
                color: '#01effc',
                width: 1
            },
            itemStyle: {
                normal: {
                    color: '#43bafe',
                    borderWidth: '1'
                },
                emphasis: {
                    color: '#01effc'
                }
            },
            controlStyle: {
                normal: {
                    color: '#01effc',
                    borderColor: '#01effc',
                    borderWidth: 0.5
                },
                emphasis: {
                    color: '#01effc',
                    borderColor: '#01effc',
                    borderWidth: 0.5
                }
            },
            checkpointStyle: {
                color: '#01effc',
                borderColor: '#01effc'
            },
            label: {
                normal: {
                    textStyle: {
                        color: '#818a9c'
                    }
                },
                emphasis: {
                    textStyle: {
                        color: '#818a9c'
                    }
                }
            }
        },
        visualMap: {
            color: [
                '#516b91',
                '#59c4e6',
                '#a5e7f0'
            ]
        },
        dataZoom: {
            backgroundColor: 'rgba(0,0,0,0)',
            dataBackgroundColor: 'rgba(255,255,255,0.3)',
            fillerColor: 'rgba(167,183,204,0.4)',
            handleColor: '#a7b7cc',
            handleSize: '100%',
            textStyle: {
                color: '#333'
            }
        },
        markPoint: {
            label: {
                normal: {
                    textStyle: {
                        color: '#ffffff'
                    }
                },
                emphasis: {
                    textStyle: {
                        color: '#ffffff'
                    }
                }
            }
        }
    })
}))
