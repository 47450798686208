import echarts from 'echarts'

import { commonFunc } from '../chartCommonPart'
import { setXaxisDefault, setYaxisDefault, setGrid } from '../../echartSetting/axis'

function ThirdBarLine (element, props) {
  // console.log(props)
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)

  const myChart = echarts.init(element.children[0], props.colorTheme)

  const originData = props.data[0].originData
  const xAxisData = props.data[0].xAxisData
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const isExchangeBarLine = props.data[0].isExchangeBarLine || props.data[0].is_exchange_bar_line
  const legend = props.data[0].legend
  const gradientColorStart = props.data[0].gradientColorStart
  const gradientColorEnd = props.data[0].gradientColorEnd
  const lineColor = props.data[0].lineColor
  const backgroundColor = props.data[0].backgroundColor
  const barWidth = props.data[0].barWidth

  const barBorderRadius = props.data[0].barBorderRadius
  const isAddTailColor = false
  const lineSmoothNumber = props.data[0].lineSmoothNumber

  // 获取输入数据的长度;  ceil 向上取整数
  const barNum = Math.round(originData.length / 2) // 四舍五入

  // 每组数据的最后
  const convertData = function (barData, name) {
    const newBarData = []
    const dataNum = barData.length - 1
    if (isAddTailColor !== true) {
      return barData
    }
    for (let i = 0; i < barData.length; i++) {
      if (i === dataNum) {
        const neaLastItem = {
          name: name,
          value: barData[i],
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              // 设置最后一列柱子的颜色
              colorStops: [{
                offset: 1, color: '#124A4C' // 0% 处的颜色
              }, {
                offset: 0, color: '#2bf5ef' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        }
        newBarData.push(neaLastItem)
      } else {
        newBarData.push(barData[i])
      }
    }
    return newBarData
  }

  const initSeries = function (index, seriesType) {
    let setting
    if (seriesType === 'bar') {
      setting = {
        name: legend[index],
        type: 'bar',
        // 不适用百分比, 保证不同图表中 bar的 宽度一致
        barWidth: barWidth,
        itemStyle: {
          // color: barColor,
          barBorderRadius: barBorderRadius,
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 1, color: gradientColorStart // 0% 处的颜色
            }, {
              offset: 0, color: gradientColorEnd // 100% 处的颜色
            }],
            global: false // 缺省为 false
          }
        },
        yAxisIndex: 0,
        data: convertData(originData[index], xAxisData[index])
      }
    } else {
      setting = {
        name: legend[index],
        type: 'line',
        itemStyle: {
          color: lineColor
        },
        smooth: lineSmoothNumber,
        yAxisIndex: 1,
        data: originData[index]
      }
    }
    return setting
  }

  const series = []
  for (let i = 0; i < originData.length; i++) {
    if (isExchangeBarLine === true) {
      if (i < barNum) {
        series.push(
          initSeries(i, 'bar')
        )
      } else {
        series.push(
          initSeries(i, 'line')
        )
      }
    } else {
      if (i < barNum) {
        series.push(
          initSeries(i, 'line')
        )
      } else {
        series.push(
          initSeries(i, 'bar')
        )
      }
    }
  }

  // console.log(series)
  const option = {
    backgroundColor: backgroundColor,
    grid: setGrid(props.data[0]),
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          show: true,
          color: '#5affc2',
          backgroundColor: '#072B4D',
          //   borderColor:'none',
          //   shadowColor:'none',
          shadowBlur: 0
        }
      }
    },
    legend: {
      show: false,
      right: '7%',
      top: '3%',
      itemGap: 10,
      itemHeight: 10,
      itemWidth: 10,
      textStyle: {
        fontSize: 10,
        color: '#fff'
      },
      data: legend
    },
    xAxis: setXaxisDefault(props.data[0]),
    yAxis: setYaxisDefault(props.data[0]),
    series: series
  }

  // 使用上述配置显示图标 ， 保留不变
  const startPlay = commonFunc(myChart, option, props)

  return startPlay
}

export default ThirdBarLine // 修改为Classname
