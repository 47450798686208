import { SUPERSET_URL } from '~/config'

import './PicCarouselThird.css'
import Swiper from 'swiper'

function PicCarouselThird (element, props) {
  // console.log(props)
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  // eushow 3.0
  // 预留的div  let body = div[0][0].children[0];
  const textData = props.data[0].data
  // ----------------------------------
  //   todo 项目变量设置
  // ----------------------------------
  // ----------------------------------
  const contentWidth = props.data[0].parentWidth
  const contentHeight = props.data[0].parentHeight
  const delayTime = props.data[0].delayTime
  const speedTime = props.data[0].speedTime

  // const targetMarginLeft = props.data[0].targetMarginLeft;

  const createDom = function (domType, className, text) {
    const oneDom = document.createElement(domType)
    if (className !== '') {
      oneDom.setAttribute('class', className)
    }
    if (text !== '') {
      oneDom.innerHTML = text
    }
    return oneDom
  }

  const pngImgId = ['1478227',
    '1478248',
    '1481793',
    '1481861',
    '1481915',
    '1481939',
    '1482037',
    '1482140',
    '1482716',
    '1483031',
    '1484441',
    '1484609',
    '1484754',
    '1485879',
    '1485880',
    '1485882',
    '1485885',
    '1485891',
    '1485894',
    '1486790',
    '1487162',
    '1487506',
    '1600201',
    '1600202',
    '1600203',
    '1600206',
    '1600209',
    '1600212',
    '1600213',
    '1600214',
    '1600215',
    '1600222',
    '1600223',
    '1600224',
    '1600226',
    '1600229',
    '1600230',
    '1600246',
    '1600247',
    '1600321',
    '1602053',
    '1602841',
    '1602850',
    '1602852',
    '1602860',
    '1602864',
    '1602866',
    '1602867',
    '1602871',
    '1602879',
    '1602882',
    '1602883',
    '1602917',
    '1602918',
    '1602919',
    '1602920',
    '1602922',
    '1602924',
    '1602929',
    '1602930',
    '1602933',
    '1602935',
    '1602938',
    '1602941',
    '1602942',
    '1602944',
    '1603530',
    '1603557',
    '1603561',
    '1603731',
    '1603732',
    '1604181',
    '1611113',
    '1611227',
    '1611252',
    '1611259',
    '1611279',
    '1611281',
    '1611286',
    '1611368',
    '1611384',
    '1611387',
    '1612431',
    '1612432',
    '1612433',
    '1612777',
    '1612781',
    '1612845',
    '1613009']

  const createContentList = function (parentDiv, data) {
    const len = data.length
    for (let i = 0; i < len; i++) {
      const targetDict = data[i]
      const oneContent = document.createElement('div')
      // oneContent.setAttribute('class', 'pic-li_list');
      oneContent.setAttribute('class', 'swiper-slide')

      // 整体用于布局的div
      const layContent = document.createElement('div')
      layContent.setAttribute('class', 'slide-content piccarouselthird')
      // layContent.setAttribute('style', 'margin-left:' + targetMarginLeft + 'px')
      oneContent.appendChild(layContent)

      const domainText = targetDict['技术领域']
      const domainDom = createDom('div', 'pic-domain-text', domainText)

      // 标题
      const titleText = targetDict['成果名称']
      const titleDom = createDom('div', 'pic-title', titleText)

      const subTitleText = targetDict['第一完成单位']
      const subTitleDom = createDom('div', 'pic-sub-title', subTitleText)

      const downBodyDom = createDom('div', 'pic-down-body', '')

      // 添加图片节点
      const imgParentDom = createDom('div', 'pic-img-dom', '')
      const imgDiv = createDom('img', 'pic-carousel', '')
      // 设置图片的宽高
      imgDiv.setAttribute('style', 'width: 100%; height: 100%;border-radius:6px; ')
      const imgId = targetDict['成果编号']
      let path
      if (pngImgId.indexOf(String(imgId)) > -1) {
        path = SUPERSET_URL + '/static/assets/images/achieveImg/' + imgId + '.png'
      } else {
        path = SUPERSET_URL + '/static/assets/images/achieveImg/' + imgId + '.jpg'
      }
      imgDiv.setAttribute('src', path)
      imgParentDom.appendChild(imgDiv)

      // 添加主体文本
      const rigthBodyDom = createDom('div', 'pic-right-dom', '')

      const firstTitleDom = createDom('p', 'pic-first-title', '发明人')

      const firstText = targetDict['发明人']
      const firstTextDom = createDom('p', 'pic-first-res', firstText)

      const secondTitleDom = createDom('p', 'pic-first-title', '成果类型')
      const secondText = targetDict['成果类型']
      const secondTextDom = createDom('p', 'pic-second-res', secondText)

      const thirdTitleDom = createDom('p', 'pic-first-title', '成果简介')
      const thirdText = targetDict['成果简介']
      const thirdTextDom = createDom('p', 'pic-second-res', thirdText)

      rigthBodyDom.appendChild(firstTitleDom)
      rigthBodyDom.appendChild(firstTextDom)
      rigthBodyDom.appendChild(secondTitleDom)
      rigthBodyDom.appendChild(secondTextDom)
      rigthBodyDom.appendChild(thirdTitleDom)
      rigthBodyDom.appendChild(thirdTextDom)

      downBodyDom.appendChild(imgParentDom)
      downBodyDom.appendChild(rigthBodyDom)

      layContent.appendChild(domainDom)
      layContent.appendChild(titleDom)
      layContent.appendChild(subTitleDom)
      layContent.appendChild(downBodyDom)
      // layContent.appendChild(titleDom)
      // layContent.appendChild(subTitleDom)
      // layContent.appendChild(firstText)

      parentDiv.append(oneContent)
    }
  }

  onload = function () {
    const body = element.children[0]
    body.setAttribute('style', 'width: 100%;height: 100%; overflow: hidden;')

    // 整个图表的div
    const originDiv = document.createElement('div')
    // originDiv.setAttribute('id', 'pic-box')
    originDiv.setAttribute('style', 'height:' + contentHeight + 'px; width: ' + contentWidth + 'px; background-color: rgba(255, 255, 255, 0.89)')
    originDiv.setAttribute('class', 'swiper-container pic-box-third')
    body.appendChild(originDiv)

    //  轮播内容div
    const carouselContent = document.createElement('div')
    carouselContent.setAttribute('class', 'swiper-wrapper')
    originDiv.appendChild(carouselContent)

    createContentList(carouselContent, textData)

    return originDiv
  }
  const originDiv = onload()
  // eslint-disable-next-line no-new
  new Swiper(originDiv, {
    speed: speedTime,
    autoplay: {
      disableOnInteraction: true,
      // 每次切换的时间间隔
      delay: delayTime
    }, // 可选选项，自动滑动
    direction: 'horizontal',

    // 设置slide 的宽度
    // width: 600,
    // autoHeight: true,
    setWrapperSize: true,
    // 设置前进 or 后退的按钮
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
    //  开启循环放到最后面
    loop: true
  })

  const Play = function () {

    // mySwiper()
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default PicCarouselThird // 修改为Classname
