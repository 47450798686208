<template>
  <div class="chart_7">
    <div ref="chartDom" :style="chartBox"></div>
    <div class="modal">
      <p class="modal_title" :title="topicMap.title">{{topicMap.title}}</p>

      <div class="modal_number">
        <p class="modal_number_1">{{topicMap.spared}}</p>
        <p class="modal_number_2">{{topicMap.speed}}</p>
      </div>

      <!-- 饼图 -->
      <div class="modal_pie" ref="pie"></div>
      <!-- 数据 -->
      <div class="pie_data">
        <p class="pie_data_item" style="color: #FF6F56;">
          <span>{{topicMap.nValue}}</span>
          <span>{{topicMap.nPer}}%</span>
        </p>
        <p class="pie_data_item" style="color: #68E5BA;">
          <span>{{topicMap.oValue}}</span>
          <span>{{topicMap.oPer}}%</span>
        </p>
        <p class="pie_data_item" style="color: #6BBBFF;">
          <span>{{topicMap.mValue}}</span>
          <span>{{topicMap.mPer}}%</span>
        </p>
      </div>
      <!-- 折线图 -->
      <div class="modal_line" ref="line"></div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import initChart from '../chart/chart7'
import pieOptions from './options/pie'
import lineOptions from './options/line'
import { nameToNo, spreads } from './data-source/base'
import { lineData } from './data-source/line'
import { pieData } from './data-source/pie'

export default {
  data () {
    return {
      showModal: false,
      pieChart: null,
      lineChart: null,
      // 默认值
      topic: 'Topic3',
      // 默认值
      topicMap: {
        title: '小黄车退押金套路多，困难重重',
        spared: 10,
        speed: 10,
        nValue: 10,
        nPer: 10,
        oValue: 10,
        oPer: 10,
        mValue: 10,
        mPer: 10
      }
    }
  },
  computed: {
    // 右边图表宽高
    chartBox () {
      const style = {
        width: this.frontDict.parentWidth + 'px',
        height: this.frontDict.parentHeight + 'px'
      }
      return style
    }
  },
  mounted () {
    // 初始化
    this.initChart()
    // 根据默认的 ‘topic’和‘topicMap.title’ 获取到右边弹窗的数据
    this.updateData()
  },
  methods: {
    // 初始化左边图表
    initChart (category) {
      const option = initChart(this.frontDict)
      const targetDom = this.$refs.chartDom
      if (targetDom) {
        const myChart = echarts.init(targetDom)
        myChart.setOption(option)

        myChart.on('click', (params) => {
          if (params.dataType === 'node') {
            console.log(params.name, nameToNo[params.name])
            // 触发点击事件
            this.topic = nameToNo[params.name]
            this.topicMap.title = params.name
            // 修改数据
            this.updateData()
          }
        })
      }
    },
    // 更新弹窗中的数据
    updateData () {
      const spreadData = spreads[this.topic]
      const targetPie = pieData[this.topic]
      this.topicMap.spared = spreadData.spread
      this.topicMap.speed = spreadData.speed
      this.topicMap.nValue = targetPie.nValue
      this.topicMap.nPer = targetPie.nPer
      this.topicMap.oValue = targetPie.oValue
      this.topicMap.oPer = targetPie.oPer
      this.topicMap.mValue = targetPie.mValue
      this.topicMap.mPer = targetPie.mPer

      // 修改折线图数据
      const newLine = lineData[this.topic]
      lineOptions.series[0].data = newLine

      // 修改饼图
      const newPie = [
        {
          value: targetPie.nValue,
          name: '负面消息'
        }, {
          value: targetPie.oValue,
          name: '正面消息'
        }, {
          value: targetPie.mValue,
          name: '中立消息'
        }
      ]
      pieOptions.series[0].data = newPie

      // 初始化饼图和折线图
      this.initPieAndLine()
    },
    // 初始化饼图和折线图
    initPieAndLine () {
      this.initPie()
      this.initLine()
    },
    // 初始化饼图
    initPie () {
      this.pieChart = echarts.init(this.$refs.pie)
      this.pieChart.setOption(pieOptions)
    },
    // 初始化折线图
    initLine () {
      this.lineChart = echarts.init(this.$refs.line)
      this.lineChart.setOption(lineOptions)
    }
  }
}
</script>

<style lang="scss" scoped>
.chart_7 {
  display: flex;
  position: relative;
  .modal {
    position: relative;
    width: 488px;
    height: 604px;
    margin-top: 50px;
    padding: 2px 0;
    background-image: url('./img/bg.png');
    background-repeat: no-repeat;
    .modal_title {
      position: absolute;
      left: 30px;
      top: 58px;
      width: 100%;
      line-height: 25px;
      padding-right: 30px;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .modal_number {
      position: absolute;
      top: 146px;
      left: 35px;
      height: 28px;
      line-height: 28px;
      .modal_number_1 {
        position: absolute;
        left: 40px;
        color: #FECC51;
        font-size: 20px;
        font-weight: bold;
      }
      .modal_number_2 {
        position: absolute;
        left: 271px;
        color: #70D881;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .modal_pie {
      position: absolute;
      top: 190px;
      left: 0px;
      width: 250px;
      height: 150px;
    }
    .pie_data {
      position: absolute;
      top: 224px;
      left: 295px;
      font-size: 16px;
      font-weight: bold;
      &_item {
        line-height: 22px;
        margin-bottom: 20px;
        & > span:last-child {
          margin-left: 14px;
        }
      }
    }
    .modal_line {
      position: absolute;
      left: 30px;
      right: 30px;
      bottom: 27px;
      height: 173px;
    }
  }
}
</style>
