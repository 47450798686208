import { SUPERSET_URL } from '~/config'

import './PicCarouselSeven.css'
import Swiper from 'swiper'

function PicCarouselSeven (element, props) {
  // console.log(props)
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  console.log('piccrousel seveb', props.data)
  const textData = props.data[0].data
  const contentWidth = props.data[0].parentWidth
  const contentHeight = props.data[0].parentHeight
  const delayTime = props.data[0].delayTime
  const speedTime = props.data[0].speedTime

  const createContentList = function (parentDiv, data) {
    const len = data.length
    for (let i = 0; i < len; i++) {
      // console.log('target dict', targetDict)
      const oneContent = document.createElement('div')
      oneContent.setAttribute('class', 'swiper-slide')

      // 整体用于布局的div
      const layContent = document.createElement('div')
      layContent.setAttribute('class', 'slide-content')
      // layContent.setAttribute('style', 'margin-left:' + targetMarginLeft + 'px')
      oneContent.appendChild(layContent)

      const eachDict = data[i]
      // es6 字符串占位符号
      const imgPath = SUPERSET_URL + '/static/assets/images/achieveImgTwo/' + eachDict.imgName
      const obj = {
        achieveName: eachDict['成果名称'],
        imgName: imgPath,
        patentId: eachDict['专利号'],
        creator: eachDict['发明人'],
        openDate: eachDict['公开日'],
        techfieldName: eachDict['技术领域'],
        fieldName: eachDict['行业领域'],
        brief: eachDict['成果简介'],
        status: eachDict['法律状态'],
        companent: eachDict['第一完成单位'],
        people: eachDict['联系人'],
        phone: eachDict['联系手机'],
        mail: eachDict['联系邮箱'],
        price: eachDict['供方定价.1'],
        achieveStatus: eachDict['成果阶段'],
        method: eachDict['转化形式']
      }

      layContent.innerHTML = ` <div class="carousel7-table-body">
    <div class="carousel7-tbody-style">
      <div class="carousel7-top">
        <div class="carousel7-top-title">${obj.achieveName}</div>
        <div class="carousel7-top-pic"><img class="carousel7-pic-style" src="${obj.imgName}"></div>
      </div>
      <div class="carousel7-row">
        <div class="carousel7-1-col">专利号</div>
        <div class="carousel7-2-col">${obj.patentId}</div>
        <div class="carousel7-3-col">发明人</div>
        <div class="carousel7-4-col">${obj.creator}</div>
      </div>
      <div class="carousel7-dashed-line"></div>
      <div class="carousel7-row">
        <div class="carousel7-1-col">公开日</div>
        <div class="carousel7-2-col">${obj.openDate}</div>
        <div class="carousel7-3-col">技术领域</div>
        <div class="carousel7-4-col">${obj.techfieldName}</div>
      </div>
      <div class="carousel7-dashed-line"></div>
      <div class="carousel7-row">
        <div class="carousel7-1-col">行业领域</div>
        <div class="carousel7-multi-text">${obj.fieldName}</div>
      </div>
      <div class="carousel7-dashed-line"></div>
      <div class="carousel7-row">
        <div class="carousel7-1-col">成果简介</div>
        <div class="carousel7-multi-text">${obj.brief}
        </div>
      </div>
      <div class="carousel7-dashed-line"></div>
      <div class="carousel7-row">
        <div class="carousel7-1-col">法律状态</div>
        <div class="carousel7-2-col">${obj.status}</div>
        <div class="carousel7-3-col">权属单位</div>
        <div class="carousel7-4-col">${obj.companent}</div>
      </div>
      <div class="carousel7-dashed-line"></div>
      <div class="carousel7-row">
        <div class="carousel7-1-col">联系人</div>
        <div class="carousel7-multi-text">${obj.people}</div>
      </div>
      <div class="carousel7-dashed-line"></div>
      <div class="carousel7-row">
        <div class="carousel7-1-col">联系手机</div>
        <div class="carousel7-2-col">${obj.phone}</div>
        <div class="carousel7-3-col">联系邮箱</div>
        <div class="carousel7-4-col">${obj.mail}</div>
      </div>
      <div class="carousel7-dashed-line"></div>
      <div class="carousel7-row">
        <div class="carousel7-1-col">供方定价</div>
        <div class="carousel7-2-col">${obj.price}</div>
        <div class="carousel7-3-col">成果阶段</div>
        <div class="carousel7-4-col">${obj.achieveStatus}</div>
      </div>
      <div class="carousel7-dashed-line"></div>
      <div class="carousel7-row">
        <div class="carousel7-1-col">转化形式</div>
        <div class="carousel7-multi-text">${obj.method}</div>
      </div>
      <div class="carousel7-dashed-line"></div>
    </div>
  </div>`

      parentDiv.append(oneContent)
    }
  }

  onload = function () {
    const body = element.children[0]
    body.setAttribute('style', 'width: 100%;height: 100%; overflow: hidden;')

    // 整个图表的div
    const originDiv = document.createElement('div')
    // originDiv.setAttribute('id', 'pic-box')
    originDiv.setAttribute('style', 'height:' + contentHeight + 'px; width: ' + contentWidth + 'px; background-color: rgba(255, 255, 255, 0)')
    originDiv.setAttribute('class', 'swiper-container pic-box')
    body.appendChild(originDiv)

    //  轮播内容div
    const carouselContent = document.createElement('div')
    carouselContent.setAttribute('class', 'swiper-wrapper')
    originDiv.appendChild(carouselContent)

    createContentList(carouselContent, textData)

    return originDiv
  }
  const originDiv = onload()
  // eslint-disable-next-line no-new
  new Swiper(originDiv, {
    speed: speedTime,
    autoplay: {
      disableOnInteraction: true,
      // 每次切换的时间间隔
      delay: delayTime
    }, // 可选选项，自动滑动
    direction: 'horizontal',
    setWrapperSize: true,
    loop: true
  })

  const Play = function () {

    // mySwiper()
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default PicCarouselSeven // 修改为Classname
