import echarts from 'echarts'
import 'echarts-gl' // bar3d 图需要引入该文件
import 'echarts/map/js/china'
import './maptooltipmove.css'
import allChart from './all'
import AsiaMap from './asia'

async function MapChina (element, props) {
  // 建立chart图表的容器， 在HTML中生成div标签 用来插入表格
  const divId = 'echarts_Hubei'
  element.innerHTML = `<div id="${divId}" style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  var myChart = echarts.init(element.children[0])
  const frontFields = props.data[0]

  // 获取地图 aspectScale
  const aspectScale = parseFloat(frontFields.titleUnit)
  if (aspectScale === undefined || aspectScale === '') {
    frontFields.aspectScale = 0.85
  } else {
    frontFields.aspectScale = aspectScale
  }

  // 折线可以考虑使用图片的显示
  const countryName = frontFields.siteNamePY
  frontFields.countryName = countryName
  if (countryName === 'wuhan') {
    const data = require(`../../mapGeo/countryJson/${countryName}.json`)
    echarts.registerMap(countryName, data)

    // await import(`../../mapGeo/countryJson/${countryName}.json`).then(data => {
    //   echarts.registerMap(countryName, data)
    // })
  }
  echarts.registerMap('asia', AsiaMap)

  const barStandardSelect = frontFields.barStandardSelect

  allChart(barStandardSelect)(props, myChart)
}

export default MapChina
