var chart3 = function (data, colWidth, params) {
  var NumberDiv = document.createElement('div')
  NumberDiv.setAttribute('style', 'display: flex; text-align: center; height: 100%')
  for (var i = 0; i < data.length; i++) {
    const eachData = data[i]
    // console.log(eachData)

    var NumberDivChild = document.createElement('div')
    NumberDivChild.setAttribute('style', 'display: flex; justify-content: center;align-items: center;  width: ' + colWidth + '%; font-family: ' + params.digtalfontFamily)

    if (params.isShowSign === true) {
      const unitPreifx = document.createElement('span')
      unitPreifx.setAttribute('style', 'margin-left: ' + params.textGap + 'px; text-align: center; vertical-align: auto; color: ' + params.textColor + '; font-size: ' + params.textFontSize + 'px')
      unitPreifx.innerHTML = params.unit
      if (eachData.value <= 0) {
        unitPreifx.innerHTML = '- '
      } else {
        unitPreifx.innerHTML = '+ '
      }
      NumberDivChild.appendChild(unitPreifx)
    }

    var zeroNum = (str, num) => str.repeat(num)
    var numberTarget = document.createElement('span')
    numberTarget.setAttribute('class', 'target-number')
    numberTarget.setAttribute('style', 'font-size: ' + params.numberFontSize + 'px ; color: ' + params.numberColor + ';letter-spacing: ' + params.letterSpacing + 'px')
    numberTarget.setAttribute('id', 'number' + i)
    numberTarget.innerHTML = Math.abs(eachData.value).toString() + zeroNum('0', params.tailZeroNum)

    var unitSuffix = document.createElement('span')
    unitSuffix.setAttribute('style', 'margin-left: ' + params.textGap + 'px; text-align: center; vertical-align: auto; color: ' + params.textColor + '; font-size: ' + params.textFontSize + 'px')
    unitSuffix.innerHTML = params.unit

    // NumberDivChild.appendChild(unitPreifx);
    NumberDivChild.appendChild(numberTarget)
    NumberDivChild.appendChild(unitSuffix)

    NumberDiv.appendChild(NumberDivChild)
  }

  return NumberDiv
}

export default chart3
