<!--
  根据item.type 显示不同的表单项
-->
<template>
  <div class="s_form_item" :title="setting.description">
    <!-- 选择器 -->
    <el-select
      v-if="setting.type === 'select'"
      style="width: 100%;"
      :placeholder="setting.description"
      size="small"
      v-model="$_value"
      filterable
      clearable>
      <el-option
        v-for="optionItem in setting.options"
        :key="optionItem"
        :label="optionItem"
        :value="optionItem">
      </el-option>
    </el-select>

    <!-- 输入框 -->
    <el-input
      v-if="setting.type === 'input'"
      :placeholder="setting.description"
      style="width: 100%;"
      type="text"
      size="small"
      v-model="$_value">
    </el-input>

    <!-- checkbox 单独使用表示两种状态的切换 -->
    <el-checkbox
      v-if="setting.type === 'checkBox'"
      v-model="$_value">
      {{ setting.label }}
    </el-checkbox>

    <!-- 颜色选择器 -->
    <el-color-picker
      v-if="setting.type === 'colorPicker'"
      v-model="$_value"
      show-alpha
      popper-class="my-el-color-picker"
      size="small">
    </el-color-picker>
  </div>
</template>

<script>
export default {
  props: {
    // 值
    value: {
      validator: function (value) {
        return true
      }
    },
    // 配置项
    setting: Object
  },
  computed: {
    // 值
    $_value: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('update:value', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .s_form_item {
    width: 100%;
  }
</style>
