<template>
  <div class="htmlchartset1-chart1">
    <div class="tab">
      <div class="positive" @click="selectPositive()" style="cursor: pointer;">
        <img src="./pic/O.png" />
      </div>
      <div class="negative" @click="selectNegative()" style="cursor: pointer;">
        <img src="./pic/N.png" />
      </div>
    </div>
    <div class="body">
      <vue-scroll>
        <div class="scroll-content">
          <div v-for="(item) in getItmes()" :key="item.name">
            <div class="row" :style="rowBackIMg">
              <div class="row-header">
                <span>{{item.countryCh}}</span>
                <span class="back-img" :style="{backgroundImage: samllImg1(), width:item.width}"></span>
                <span class="number">{{Math.abs(item.ToneCount)}}</span>
              </div>
              <a :href="item.Url" target="_blank">
                <div class="row-body">{{item.Title}}</div>
              </a>
            </div>
          </div>
        </div>
      </vue-scroll>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    // let maxValue = 100
    // let boxLength = 263
    return {
    }
  },
  methods: {
    selectPositive: function () {
      this.isPositve = true
    },
    selectNegative: function () {
      this.isPositve = false
    },
    samllImg1 () {
      let img
      if (this.isPositve) {
        img = require('./pic/N_line.png')
      } else {
        img = require('./pic/O_line.png')
      }
      return `url(${img})`
    },
    getItmes: function () {
      const originData = this.frontDict.originData
      const items = []
      let selected = 'O'
      if (this.isPositve === false) {
        selected = 'N'
      }
      for (let i = 0; i < originData.length; i++) {
        const article = originData[i]
        if (article.NO === selected) {
          // console.log(article)
          const number = article.ToneCount
          article.width = `${parseInt((number / 30) * 263)}px`
          items.push(article)
        }
      }
      return items
    }
  },
  computed: {
    chartWidth () {
      return this.props.width + 'px'
    },
    chartHeight () {
      return this.props.height + 'px'
    },
    rowBackIMg () {
      let img
      if (this.isPositve) {
        img = require('./pic/N_box.png')
      } else {
        img = require('./pic/O_box.png')
      }
      return {
        backgroundImage: `url(${img})`
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
