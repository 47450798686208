import Vue from 'vue'
import allChart from './all'
import { timeHMS } from './com'
import './timetext.css'

function ChartFunc (element, props) {
  const chartFunc = allChart(props.data[0].barStandardSelect)

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const body = element.children[0]

  function getNowFormatDate () {
    var date = new Date()
    var seperator1 = '-'
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var strDate = date.getDate()
    if (month >= 1 && month <= 9) {
      month = '0' + month
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = '0' + strDate
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate
    return currentdate
  }

  const isCurrentDate = props.data[0].isCurrentDate
  if (isCurrentDate === true) {
    const nowFormat = getNowFormatDate()
    props.data[0].targetTime = nowFormat
  }

  // eslint-disable-next-line no-unused-vars
  const baseDom1 = new Vue({
    el: body,
    extends: chartFunc,
    data () {
      return {
        options: [],
        value: '',
        frontDict: props.data[0],
        timer: '',
        props: props,
        currentTime: timeHMS()
      }
    },
    props: {}
  })
}

export default ChartFunc // 修改为Classname
