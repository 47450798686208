<template>
  <div class="chart41">
    <el-select class="my-lib-el-select transparency" v-model="selectValue" size="small" placeholder="请选择" @change="selectChange">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>

    <div class="line" ref="line" :style="chartStyle"></div>
  </div>
</template>

<script>
import echarts from 'echarts'
import option from './option'

export default {
  data () {
    return {
      chart: null,
      selectValue: '1',
      options: [
        {
          label: '授权专利数量',
          value: '1'
        },
        {
          label: '专利权人数量',
          value: '2'
        }
      ]
    }
  },
  mounted () {
    this.initChart()
  },
  methods: {
    selectChange () {
      this.initChart()
    },
    initChart () {
      const data = { patent_num: [[886, 895, 768, 723, 841, 875, 793, 898, 1003, 1094, 1284, 1631, 1481, 1935, 2120, 2793, 2800, 3083, 3572, 3710, 3749, 4227, 5637, 6955, 8290, 9928, 9838, 10128, 10473, 9968, 10767, 10520, 10341, 11603, 15659, 15765, 14938, 17231, 21531, 25686, 26529, 26822, 25094, 28029], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 0, 0, 0, 1, 1, 0, 1, 1, 1, 9, 1, 2, 5, 13, 6, 17, 24, 37, 50, 84, 138, 151, 185, 395, 582, 840, 1123, 1509, 1511, 2464]], people_num: [[206, 224, 185, 174, 217, 213, 204, 234, 262, 257, 317, 369, 386, 442, 455, 533, 544, 556, 584, 558, 622, 607, 727, 848, 958, 1093, 1085, 1185, 1267, 1318, 1408, 1369, 1347, 1445, 1701, 1811, 1813, 1888, 2297, 2713, 2669, 2699, 2632, 2926], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 0, 1, 1, 0, 1, 1, 1, 6, 1, 2, 5, 9, 6, 11, 14, 14, 21, 35, 42, 46, 59, 87, 109, 192, 198, 223, 245, 323]] }

      if (this.selectValue === '1') {
        option.series[0].name = '世界各国授权专利数量'
        option.series[0].data = data.patent_num[0]

        option.series[1].name = '中国大陆授权专利数量'
        option.series[1].data = data.patent_num[1]
      }
      if (this.selectValue === '2') {
        option.series[0].name = '世界各国专利权人数量'
        option.series[0].data = data.people_num[0]

        option.series[1].name = '中国大陆专利权人数量'
        option.series[1].data = data.people_num[1]
      }
      this.chart = echarts.init(this.$refs.line)
      this.chart.setOption(option)
    }
  },
  computed: {
    chartStyle () {
      const style = {
        width: this.frontDict.parentWidth + 'px',
        height: this.frontDict.parentHeight + 'px'
      }
      return style
    }

  }
}
</script>

<style lang="scss" scoped>
// .chart41 {
//   width: 380px;
//   height: 300px;
//   .line {
//     width: 100%;
//     height: 348px;
//   }
// }
</style>
