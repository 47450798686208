var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart"},[_c('vue-scroll',{attrs:{"ops":{
    scrollPanel: {
        scrollingY: false
      },
      rail: {
        background: 'rgba(255, 255, 255, 0.2)',
        size: '4px',
        keepShow: false
      },
      bar: {
        keepShow: true,
        background: '#3E84A6',
        specifyBorderRadius: '6px',
        size: '6px'
      }
  }}},[_c('div',{staticClass:"chart_wrapper"},_vm._l((_vm.data),function(item){return _c('div',{key:item.id,staticClass:"chart_item"},[_c('img',{staticClass:"chart_item_img",attrs:{"src":item.img}}),_c('div',{staticClass:"chart_item_right"},[_c('p',{staticClass:"chart_item_title"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"chart_item_content"},[_vm._v(_vm._s(item.content))])])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }