<template>
  <div class="weather-2">
    <a target="_blank">
      <div class="weather2">
        <div class="weather2-img">
          <img class="pngtqico" align="absmiddle" :src="baseUrl" style="border:0;" />
        </div>
        <div class="tianqi-chart2">
          <ul>
            <li>
              <span class="weather2-num">{{frontDict.weatherInfo.tempLow}}℃</span>
              <span class="weather2-info" style="margin-left：10px">{{frontDict.weatherInfo.weather}}</span>
            </li>
            <li style='font-family: YueRoundedGothic'>当前定位：武汉市</li>
          </ul>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  computed: {
    baseUrl () {
      return (
        '//plugin.tianqistatic.com/static/images/tianqibig/' +
        this.frontDict.weatherInfo.weatherPic +
        '.png'
      )
    },
    siteUrl () {
      return (
        'http://www.tianqi.com/' +
        this.frontDict.weatherInfo.siteNamePY +
        '/?tq'
      )
    },
    aveagerTemp () {
      const low = this.frontDict.weatherInfo.tempLow
      const height = this.frontDict.weatherInfo.tempHeight
      const temp = Math.round((low + height) / 2, 2)
      return temp
    }
  }
}
</script>
<style lang="scss" scoped>
.weather2 {
  padding: 5px;
  display: flex;
  align-items: center;
}

.weather2-img > img {
  width: 58px;
  float: left;
  text-align: center;
}

.tianqi-chart2 {
  color: rgba(255, 255, 255, 0.61);
  margin: 0 0;
  margin-left: 23px;
  float: left;
  font-size: 14px;
  line-height: 19px;
  font-family: "YueRoundedGothic";
  height: 57px;
  list-style: none;
}

.weather2-num {
  font-size: 32px;
  font-family: "SonyEricssonLogo";
  color: rgba(255, 255, 255, 0.81);
  margin-right: 10px;
  line-height: 38px;
}
.weather2-info {
  font-family: "YueRoundedGothic";
  margin-top: 9px;
}
</style>
// <style lang="scss">
//
</style>
