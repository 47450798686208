import { chinaMapData, proviceIndex } from '../chartCommonPart'

const scatterData = function () {
  const res = []
  for (const key in chinaMapData) {
    // console.log(chinaMapData[key])
    const coord = chinaMapData[key]
    // let ranValue = [Math.random() * 1]
    res.push({
      name: key,
      value: coord,
      selected: false,
      symbolSize: Math.random() * 20
    })
  }
  // console.log('scatter data', res)
  return res
}

const edgeCountry = function () {
  const countryAttr = []
  const countryNames = [
    'India',
    'Kazakhstan',
    'Kyrgyzstan',
    'Bangladesh',
    'Myanmar',
    'Lao PDR',
    'Cambodia',
    'Vietnam',
    'Mongolia',
    'Korea',
    'Dem. Rep. Korea',
    'Japan',
    'Pakistan',
    'Afghanistan',
    'Tajikistan',
    'Thailand'
  ]
  const baseStyle = function (name) {
    const base = {
      name: name,
      height: 0,
      itemStyle: {
        color: 'rgba(1,1,1,0)',
        borderColor: 'rgba(255,255,255,0.2)',
        borderWidth: 0.3
      },
      emphasis: {
        itemStyle: {
          color: 'rgba(0,0,0,0)'
        },
        label: { show: false }
      }
    }
    return base
  }
  countryNames.forEach(function (item) {
    countryAttr.push(baseStyle(item))
  })
  return countryAttr
}

const targetProvice = function (name) {
  const hoverSite = {
    name: name,
    height: 4.7,
    itemStyle: {
      color: 'rgba(68, 197, 255, 1)',
      borderColor: '#61a5df',
      borderWidth: 1
    }
  }
  return hoverSite
}

const chinaOuterLine = {
  name: 'ChinaOuterLine',
  height: 0,
  emphasis: {
    label: {
      show: false
    },
    itemStyle: {
      color: 'rgb(56,56,56)' // 地图配色
      // color: 'rgba(1,1,1,0)'
    }
  },
  itemStyle: {
    color: 'rgba(1,1,1,0)',
    borderColor: '#50c7ff',
    borderWidth: 6
  }
}

const initRegionData = function (index) {
  let res = []
  const countries = edgeCountry()
  const targetSite = proviceIndex[index]
  const site = targetProvice(targetSite)
  res = res.concat(countries)
  res.push(site)
  res.push(chinaOuterLine)
  return res
}

export { scatterData, initRegionData }
