const nameToNo = {
  小黄车退押金周期长: '主题一',
  '退押金排队（系统）人数多': '主题二',
  '小黄车退押金套路多，困难重重': '主题三',
  'ofo推出押金新政策，押金投资理财项目后可免押金': '主题四',
  '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款': '主题五',
  '小黄车推出折扣商城，押金兑换金币购物': '主题六'
}

const nameMap = {
  主题一: '小黄车退押金周期长',
  主题二: '退押金排队（系统）人数多',
  主题三: '小黄车退押金套路多，困难重重',
  主题四: 'ofo推出押金新政策，押金投资理财项目后可免押金',
  主题五: '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款',
  主题六: '小黄车推出折扣商城，押金兑换金币购物'
}
const spreads = {
  主题三: {
    spread: '10,684',
    speed: 9
  },
  主题五: {
    spread: '111,902',
    speed: 13
  },
  主题一: {
    spread: '4,149',
    speed: 1
  },
  主题二: {
    spread: '15,937',
    speed: 1
  },
  主题四: {
    spread: '94,262',
    speed: 85
  },
  主题六: {
    spread: '20,647',
    speed: 411
  }
}

const lineData = {
  主题二: [0, 0, 0, 2, 3, 14, 685, 319, 175, 129],
  主题一: [3, 1, 6, 3, 136, 210, 161, 191, 269],
  主题三: [69, 11, 13, 30, 24, 328, 876, 457, 356, 492],
  主题六: [72, 11, 5, 15, 12, 248, 122, 43, 36, 754],
  主题四: [47, 23, 14, 35, 51, 489, 813, 287, 172, 311],
  主题五: [26, 2, 5, 28, 32, 290, 989, 318, 192, 218]
}

const pieData = {
  主题二: {
    sValue: 11833,
    nValue: 3809,
    nPer: 32.19,
    oValue: 3305,
    oPer: 28,
    mValue: 4719,
    mPer: 39.81
  },
  主题一: {
    sValue: 15253,
    nValue: 7572,
    nPer: 49.64,
    oValue: 2934,
    oPer: 19,
    mValue: 4747,
    mPer: 31.36
  },
  主题三: {
    sValue: 17121,
    nValue: 8147,
    nPer: 47.58,
    oValue: 4078,
    oPer: 24,
    mValue: 4896,
    mPer: 28.42
  },
  主题六: {
    sValue: 22017,
    nValue: 9643,
    nPer: 43.8,
    oValue: 6544,
    oPer: 30,
    mValue: 5830,
    mPer: 26.20
  },
  主题四: {
    sValue: 72451,
    nValue: 29378,
    nPer: 40.55,
    oValue: 21601,
    oPer: 30,
    mValue: 21472,
    mPer: 29.45
  },
  主题五: {
    sValue: 91696,
    nValue: 40247,
    nPer: 43.89,
    oValue: 22497,
    oPer: 25,
    mValue: 28952,
    mPer: 31.11
  }
}

export {
  nameToNo,
  lineData,
  spreads,
  pieData,
  nameMap
}
