import echarts from 'echarts'
import 'echarts/map/js/china' // 需要引入echarts 的地图文件
import 'echarts/map/js/world' // 需要引入echarts 的地图文件
import 'echarts-gl' // bar3d 图需要引入该文件
import { hideDelayTime, trigger } from '../chartCommonPart'

function MapAndBar3d (element, props) {
  // 后端传入的数据，转化为 echarts自身定义的同类型， 不用改动echarts的输入数据类型，主要依靠后端代码处理数据格式
  const allSites = props.data[0].all_sites
  const maxValue = props.data[0].max_value
  const minValue = props.data[0].min_value
  const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const myChart = echarts.init(element.children[0], colorEchartsTheme)

  const option = {
    title: {
      text: '',
      x: 'left',
      top: '10',
      textStyle: {
        color: '#000',
        fontSize: 14
      }
    },
    tooltip: {
      show: true,
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      formatter: (params) => {
        var res = '测试1:' + params.name + '<br/>' + '值:' + params.value[2] + '<br/>' + '地理坐标:[' + params.value[0] + ',' + params.value[1] + ']'
        return res
      }
    },
    visualMap: [{
      show: true,
      type: 'continuous',
      seriesIndex: 0,
      text: ['bar3D'],
      calculable: true,
      max: 300
      // inRange: {
      //   color: ['#f2c31a', '#24b7f2']
      // }
    }, {
      show: false,
      type: 'continuous',
      seriesIndex: 1,
      text: ['scatter3D'],
      left: 'right',
      max: maxValue * 1.2,
      min: minValue,
      calculable: true,
      inRange: {
        color: ['#f2c31a', '#24b7f2']
      }
    }],
    geo3D: {
      // 各个省份使用中文名称 湖北 北京
      // map: 'world',
      // map: '湖北',
      map: 'china',
      // boxWidth boxHeight 两者会影响图表的zoom大小, 综合影响上面柱状图的高度,两者之间的比例关系
      // boxWidth: 100,
      // boxHeight: 15,
      regionHeight: 2,
      groundPlane: {
        // 地图下面的地面
        // show: true
        // color: '#fff'
      },
      itemStyle: {
        // 整个地图的颜色
        // color: 'red',
        opacity: 1, // 只影响3d图的第一层的透明度
        // 各个局部区域之间的分割线
        borderColor: '#333',
        borderWidth: 0.5
      },
      label: {
        show: false,
        textStyle: {
          // color: "#fff", // 地图初始化区域字体颜色
          fontSize: 12, // 字体大小
          opacity: 1 // 字体透明度
          // backgroundColor: "rgba(0,23,11,0)" // 字体背景色
        }
      },
      viewControl: {
        autoRotate: false,
        minBeta: -360,
        maxBeta: 360
      },
      regions: [{
        // TODO 没有实现
        name: '湖北',
        regionHeight: 20,
        itemStyle: {
          color: 'red',
          borderColor: 'red'
        }
      }],
      light: {
        main: {
          color: '#fff',
          shadow: true,
          // 上下旋转的角度,仰角 ==> 阴影的长度
          alpha: 55,
          // 左右旋转的角度 ==> 阴影方向
          beta: 250
        }
      }
    },
    series: [
      {
        name: 'bar3D',
        type: 'bar3D',
        coordinateSystem: 'geo3D',
        // 柱子的倒角程度
        bevelSmoothness: 0,
        barSize: 0.5, // 柱子粗细
        shading: 'lambert',
        opacity: 1,
        bevelSize: 0.3,
        minHeight: 2,
        itemStyle: {
          color: 'red'
        },
        label: {
          show: false,
          formatter: '{b}'
        },
        data: allSites,
        animationDuration: 2000
      }
    ]
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default MapAndBar3d // 修改为Classname
