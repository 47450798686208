const allData = {
  起始期: {
    theme: 1491,
    all: 1931
  },
  爆发期: {
    theme: 3238,
    all: 17480
  },
  蔓延期: {
    theme: 21790,
    all: 52179
  },
  衰退期: {
    theme: 8534,
    all: 29416
  }
}

export default allData
