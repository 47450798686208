import echarts from 'echarts'
import 'echarts/map/js/china' // 需要引入echarts 的地图文件
import { hideDelayTime, trigger } from '../chartCommonPart'

function MapAndBar (element, props) {
  // 后端传入的数据，转化为 echarts自身定义的同类型， 不用改动echarts的输入数据类型，主要依靠后端代码处理数据格式
  const cityData = props.data[0].city_data
  const title = props.data[0].title
  const yAxisUnit = props.data[0].y_axis_unit
  const maxValue = props.data[0].max_value
  const minValue = props.data[0].min_value
  const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const myChart = echarts.init(element.children[0], colorEchartsTheme)

  var yData = []

  cityData.sort(function (o1, o2) {
    if (isNaN(o1.value) || o1.value == null) return -1
    if (isNaN(o2.value) || o2.value == null) return 1
    return o1.value - o2.value
  })

  for (var i = 0; i < cityData.length; i++) {
    yData.push(cityData[i].name)
  };

  const option = {
    title: {
      text: title,
      textStyle: {
        fontSize: 30
      },
      x: 'center'
    },
    tooltip: {
      show: true,
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      formatter: function (params) {
        return params.name + '：' + params.data.value + ' ' + yAxisUnit
      }
    },
    visualMap: {
      type: 'continuous',
      text: ['', ''],
      showLabel: true,
      seriesIndex: [0],
      min: minValue,
      max: maxValue,
      // inRange: {
      //     color: ['#edfbfb', '#b7d6f3', '#40a9ed', '#3598c1', '#215096', ]
      // },
      textStyle: {
        color: '#000'
      },
      bottom: 30,
      left: 'left'
    },
    grid: {
      right: 10,
      top: 80,
      bottom: 30,
      width: '20%'
    },
    xAxis: {
      type: 'value',
      scale: true,
      position: 'top',
      splitNumber: 1,
      boundaryGap: false,
      splitLine: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        margin: 2,
        textStyle: {
          color: '#aaa'
        }
      }
    },
    yAxis: {
      type: 'category',
      nameGap: 16,
      axisLine: {
        show: false,
        lineStyle: {
          color: '#ddd'
        }
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#ddd'
        }
      },
      axisLabel: {
        interval: 0,
        textStyle: {
          color: '#999'
        }
      },
      data: yData
    },
    geo: {
      roam: true,
      map: 'china',
      left: 'left',
      right: '300',
      layoutSize: '80%',
      label: {
        emphasis: {
          show: false
        }
      },
      itemStyle: {
        emphasis: {
          areaColor: '#fff464'
        }
      },
      regions: [{
        name: '南海诸岛',
        value: 0,
        itemStyle: {
          normal: {
            opacity: 0,
            label: {
              show: false
            }
          }
        }
      }]
    },
    series: [{
      name: 'mapSer',
      type: 'map',
      roam: false,
      geoIndex: 0,
      label: {
        show: false
      },
      data: cityData,
      animationDuration: 2000
    }, {
      name: 'barSer',
      type: 'bar',
      roam: false,
      visualMap: false,
      zlevel: 2,
      barMaxWidth: 20,
      // itemStyle: {
      //     normal: {
      //         color: '#40a9ed'
      //     },
      //     emphasis: {
      //         color: "#3596c0"
      //     }
      // },
      label: {
        normal: {
          show: false,
          position: 'right',
          offset: [0, 10]
        },
        emphasis: {
          show: true,
          position: 'right',
          offset: [10, 0]
        }
      },
      data: cityData,
      animationDuration: 2000
    }]
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  // 监听页面点击事件
  myChart.on('click', function (params) {
    const groupByValue = params.name
    console.log(groupByValue)
    props.onInteract(groupByValue)
  })
  function Play () {
    myChart.clear()
    myChart.setOption(option)
    // 监听页面点击事件
    myChart.on('click', function (params) {
      const groupByValue = params.name
      console.log(groupByValue)
      props.onInteract(groupByValue)
    })
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default MapAndBar // 修改为Classname
