<template>
  <div class="filter">
    <div class="filter_item" v-for="(item, index) in result" :key="index">
      <p class="filter_item_label text_size_14 text_color_primary">F{{index + 1}}</p>
      <el-popover
        v-model="result[index].showPopover"
        popper-class="my_popover"
        trigger="manual"
        width="340"
        placement="right"
        :visible-arrow="false"
        style="flex: 1;">
        <el-select
          slot="reference"
          :disabled="disabled"
          v-model="result[index].subject"
          :clearable="true"
          style="width: 100%;" size="small"
          @visible-change="value => onSelectVisible(value, index)"
          @change="value => onResultChange(value, index)">
          <el-option v-for="item in columns" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <div class="popover">
          <div class="popover_header">
            <div
              class="popover_header_item"
              :class="editItem.expressionType === 'SIMPLE' ? 'select' : ''"
              @click="selectType(index, 'SIMPLE')">
              简单设置
            </div>
            <div
              class="popover_header_item"
              :class="editItem.expressionType === 'SQL' ? 'select' : ''"
              @click="selectType(index, 'SQL')">
              自定义SQL语句
            </div>
          </div>
          <div class="popover_body">
            <template v-if="editItem.expressionType === 'SIMPLE'">
              <el-form size="small">
                <el-form-item>
                  <el-select v-model="editItem.subject" style="width: 100%;">
                    <el-option v-for="item in columns" :key="item" :label="item" :value="item"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select v-model="editItem.operator" style="width: 100%;">
                    <el-option label="==" value="=="></el-option>
                    <el-option label="!=" value="!="></el-option>
                    <el-option label=">" value=">"></el-option>
                    <el-option label="<" value="<"></el-option>
                    <el-option label=">=" value=">="></el-option>
                    <el-option label="<=" value="<="></el-option>
                    <el-option label="in" value="in"></el-option>
                    <el-option label="not in" value="not in"></el-option>
                    <el-option label="LIKE" value="LIKE"></el-option>
                    <el-option label="regex" value="regex"></el-option>
                    <el-option label="IS NOT NULL" value="IS NOT NULL"></el-option>
                    <el-option label="IS NULL" value="IS NULL"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="editItem.comparator"></el-input>
                </el-form-item>
              </el-form>
            </template>
            <template v-if="editItem.expressionType === 'SQL'">
              <el-select size="small" v-model="editItem.clause">
                <el-option label="WHERE" value="WHERE"></el-option>
                <el-option label="HAVING" value="HAVING"></el-option>
              </el-select>
              <p class="popover_body_tips text_size_12 text_color_primary">Where通过列来过滤筛选，Having通过指标来过滤筛选</p>
              <code-editor :codes.sync="editItem.sqlExpression" language="sql" editorHeight="200px" @onEditorMounted="onEditorMounted"></code-editor>
            </template>
          </div>
          <div class="popover_footer">
            <el-button size="small" @click="onCancel(index)">关闭</el-button>
            <el-button type="primary" size="small" @click="onConfirm(index)">保存</el-button>
          </div>
        </div>
      </el-popover>
    </div>
    <p class="filter_add text_color_primary" @click="addItem">
      <i class="iconfont iconjia"></i>
      <span class="text_size_14">新增</span>
    </p>
  </div>
</template>

<script>
import codeEditor from '~/components/code-editor'
export default {
  props: {
    disabled: Boolean,
    filters: {
      type: Array,
      default: () => {
        return []
      }
    },
    columns: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      result: [],
      monaco: null,
      editItem: {
        index: 0,
        expressionType: '',
        subject: '',
        operator: '',
        comparator: '',
        clause: '',
        sqlExpression: ''
      }
    }
  },
  computed: {
    $_filters: {
      get () {
        return this.filters
      },
      set (value) {
        this.$emit('update:filters', value)
      }
    }
  },
  watch: {
    filters: function (newVal, oldVal) {
      this.getResult()
    },
    disabled: function (newVal, oldVal) {
      if (newVal) {
        this.hidePopover()
      }
    }
  },
  components: {
    codeEditor
  },
  created () {
    this.getResult()
  },
  methods: {
    // 根据filter获得result
    getResult () {
      this.result = []
      if (this.$_filters.length === 0) {
        this.result.push({
          expressionType: 'SIMPLE',
          subject: '',
          operator: '',
          comparator: '',
          clause: 'WHERE',
          sqlExpression: '',
          showPopover: false
        })
      } else {
        this.$_filters.forEach(element => {
          this.result.push({
            expressionType: element.expressionType,
            subject: element.subject,
            operator: element.operator,
            comparator: element.comparator,
            clause: element.clause,
            sqlExpression: element.sqlExpression,
            showPopover: false
          })
        })
      }
    },
    onSelectVisible (value, index) {
      this.hidePopover()
      if (this.result[index].subject) {
        for (const key in this.editItem) {
          if (Object.prototype.hasOwnProperty.call(this.editItem, key)) {
            this.editItem[key] = this.result[index][key]
          }
        }
        this.editItem.index = index
        this.result[index].showPopover = true
      }
    },
    onResultChange (value, index) {
      if (value === '') {
        this.result.splice(index, 1)

        const result = []
        this.result.forEach(element => {
          result.push({
            expressionType: element.expressionType,
            subject: element.subject,
            operator: element.operator,
            comparator: element.comparator,
            clause: element.clause,
            sqlExpression: element.sqlExpression
          })
        })
        this.$_filters = result

        if (this.result.length === 0) {
          this.result.push({
            expressionType: 'SIMPLE',
            subject: '',
            operator: '',
            comparator: '',
            clause: 'WHERE',
            sqlExpression: '',
            showPopover: false
          })
        }
      } else {
        for (const key in this.editItem) {
          if (Object.prototype.hasOwnProperty.call(this.editItem, key)) {
            this.editItem[key] = this.result[index][key]
          }
        }
        this.editItem.index = index
        this.result[index].showPopover = true
      }
    },
    selectType (index, type) {
      this.editItem.expressionType = type
    },
    onEditorMounted (monaco) {
      this.monaco = monaco
    },
    onCancel (index) {
      this.result[index].showPopover = false
    },
    onConfirm (index) {
      for (const key in this.result[index]) {
        if (Object.prototype.hasOwnProperty.call(this.result[index], key)) {
          this.result[index][key] = this.editItem[key]
        }
      }
      this.result[index].showPopover = false

      const result = []
      this.result.forEach(element => {
        result.push({
          expressionType: element.expressionType,
          subject: element.subject,
          operator: element.operator,
          comparator: element.comparator,
          clause: element.clause,
          sqlExpression: element.sqlExpression
        })
      })
      this.$_filters = result
    },
    addItem () {
      if (this.disabled) {
        return
      }
      this.hidePopover()
      this.result.push({
        expressionType: 'SIMPLE',
        subject: '',
        operator: '',
        comparator: '',
        clause: 'WHERE',
        sqlExpression: '',
        showPopover: false
      })
    },
    hidePopover () {
      this.result.forEach(element => {
        element.showPopover = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.filter {
  &_item {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-top: 18px;
    }
    &_label {
      width: 40px;
    }
  }
  &_add {
    width: 45px;
    height: 20px;
    margin-left: 60px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      color: $color-primary;
    }
  }
}
@import './popover.scss';
</style>
