import echarts from 'echarts'

function TreeBasic (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const myChart = echarts.init(element.children[0])

  // 后端传入的数据，转化为 echarts自身定义的同类型， 不用改动echarts的输入数据类型，主要依靠后端代码处理数据格式
  const diskData = props.data[0].diskData[0]

  echarts.util.each(diskData.children, function (datum, index) {
    index % 2 === 0 && (datum.collapsed = true)
  })

  const option = {
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove'
    },
    grid: {
      left: '15px',
      right: '5%',
      containLabel: true
    },
    series: [
      {
        type: 'tree',

        data: [diskData],

        top: '1%',
        left: '7%',
        bottom: '1%',
        right: '20%',

        symbolSize: 7,

        label: {
          normal: {
            position: 'left',
            verticalAlign: 'middle',
            align: 'right',
            fontSize: 9
          }
        },

        leaves: {
          label: {
            normal: {
              position: 'right',
              verticalAlign: 'middle',
              align: 'left'
            }
          }
        },

        expandAndCollapse: true,
        animationDuration: 2000,
        animationDurationUpdate: 750
      }
    ]
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default TreeBasic
