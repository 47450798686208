export default {
  color: ['#FF6F56', '#68E5BA', '#6BBBFF'],
  legend: {
    width: 110,
    height: 110,
    top: 30,
    right: 0,
    data: ['负面消息', '正面消息', '中立消息'],
    orient: 'vertical',
    itemGap: 30,
    textStyle: {
      color: '#FFFFFF',
      fontSize: 12,
      padding: [0, 0, 0, 10]
    }
  },
  series: [
    {
      type: 'pie',
      radius: '55',
      left: 30,
      top: 30,
      center: [55, 55],
      data: [
        {
          value: 335,
          name: '负面消息'
        }, {
          value: 310,
          name: '正面消息'
        }, {
          value: 234,
          name: '中立消息'
        }
      ],
      label: {
        show: false
      },
      labelLine: {
        show: false
      }
    }
  ]
}
