<template>
  <div class="base_filter">
    <a target="_blank" href="http://www.tianqi.com/chibi/?tq">
      <div class="weather-3">
        <div class="tianqi-img-3">
          <img class="pngtqico" align="absmiddle" :src="baseUrl" style="border:0;" />
        </div>
        <div class="tianqi-number-3">
            <li>
              <p>{{frontDict.weatherInfo.tempLow}}℃/{{frontDict.weatherInfo.tempHeight}}℃</p>
            </li>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  computed: {
    update () {
      // 添加定时更新操作
      return ''
    },
    baseUrl () {
      return '//plugin.tianqistatic.com/static/images/tianqibig/' + this.frontDict.weatherInfo.weatherPic + '.png'
    }
  }
}
</script>
