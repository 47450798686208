// import echarts from 'echarts'
import './digitalflopCarousel.css'
import Swiper from 'swiper'

// import {EchartsSchemes} from '../echarts_theme/echarts_color_schemes'
function digitalflopCarousel (element, props) {
  // console.log(props.data)

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const data = props.data[0].data

  // 前缀符号, 后缀符号
  const textData = props.data[0].data
  const contentWidth = props.data[0].parentWidth
  const contentHeight = props.data[0].parentHeight

  const legend = props.data[0].legend
  const delayTime = props.data[0].delayTime
  const speedTime = props.data[0].speedTime
  const nameData = ['高新技术企业数量', '高新技术产业增加值']

  const backgroundColor = 'rgba(1,1,1,0)'
  // 生成dom节点
  const createDom = function (domType, className, text) {
    const oneDom = document.createElement(domType)
    if (className !== '') {
      oneDom.setAttribute('class', className)
    }
    if (text !== '') {
      oneDom.innerHTML = text
    }
    return oneDom
  }

  const createContentList = function (parentDiv, data) {
    const len = nameData.length
    for (let i = 0; i < len; i++) {
      const oneContent = document.createElement('div')
      oneContent.setAttribute('class', 'swiper-slide')

      // 整体用于布局的div
      const layContent = document.createElement('div')
      layContent.setAttribute('class', 'slide-content digitalflopcarousel')
      oneContent.appendChild(layContent)

      // 解析数据
      const currentDict = data[i]

      const firstRow = createDom('div', 'dig-firstRow', '')

      const domainText = nameData[i]
      const domainDom = createDom('div', 'dig-first-title', domainText)

      // 标题
      const titleText = '相较上一年'
      const titleDom = createDom('div', 'dig-second-title', titleText)

      firstRow.appendChild(domainDom)
      firstRow.appendChild(titleDom)

      const secondRow = createDom('div', 'dig-firstRow', '')

      const firstNumberDiv = createDom('div', 'first-parent-node', '')

      const firstNumber = currentDict[legend[1]]
      const firstNumberDom = createDom('span', 'dig-first-number', firstNumber)
      const numberUnit = createDom('span', 'dig-first-unit', '亿元')
      firstNumberDiv.appendChild(firstNumberDom)
      firstNumberDiv.appendChild(numberUnit)

      const secondNumberDiv = createDom('div', 'second-parent-node', '')
      const secondNumber = currentDict[legend[2]]
      const secondNumberDom = createDom('span', 'dig-second-number', secondNumber)
      const secondNumberUnit = createDom('span', 'dig-second-unit', '亿元')
      secondNumberDiv.appendChild(secondNumberDom)
      secondNumberDiv.appendChild(secondNumberUnit)

      secondRow.appendChild(firstNumberDiv)
      secondRow.appendChild(secondNumberDiv)

      layContent.appendChild(firstRow)
      layContent.appendChild(secondRow)

      parentDiv.append(oneContent)
    }
  }

  onload = function () {
    const body = element.children[0]
    body.setAttribute('style', 'width: 100%;height: 100%; overflow: hidden;')

    // 整个图表的div
    const originDiv = document.createElement('div')
    // originDiv.setAttribute('id', 'pic-box')
    originDiv.setAttribute('style', 'height:' + contentHeight + 'px; width: ' + contentWidth + 'px; background-color: ' + backgroundColor)
    originDiv.setAttribute('class', 'swiper-container pic-box-third')
    body.appendChild(originDiv)

    //  轮播内容div
    const carouselContent = document.createElement('div')
    carouselContent.setAttribute('class', 'swiper-wrapper')
    originDiv.appendChild(carouselContent)

    createContentList(carouselContent, textData)

    return originDiv
  }
  const originDom = onload()

  // eslint-disable-next-line no-new
  new Swiper(originDom, {
    // 设置每一次滑动的时间
    speed: speedTime,
    autoplay: {
      // disableOnInteraction: true,
      // 每次切换的时间间隔
      delay: delayTime
    }, // 可选选项，自动滑动
    direction: 'vertical',
    setWrapperSize: true,
    loop: true
  })

  const Play = function () {

    // mySwiper()
  }

  //  props返回的内容增加 mode[edit show]  autoplay[True false],只有当 show, True时, 才执行循环刷新的方法
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000 * 2)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default digitalflopCarousel // 修改为Classname
