import html1 from './html-1'
import html2 from './html-2'
import html3 from './html-3'
import html4 from './html-4'

const allHtml = function (name) {
  const chartMap = {
    chart1: html1,
    chart2: html2,
    chart3: html3,
    chart4: html4
  }
  let target = chartMap[name]
  if (target === undefined) {
    target = html1
  }

  return target
}

export default allHtml
