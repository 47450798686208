// 长春长生
import { SUPERSET_URL } from '~/config'
const chart = function (params) {
  const option = {
    backgroundColor: 'rgba(0,0,0,0)',
    geo: [{
      map: '吉林',
      zoom: 1,
      // aspectScale: 1,
      label: {
        show: false,
        textStyle: {
          color: '#ff0'
        }
      },
      itemStyle: { // 地图区域的多边形 图形样式
        normal: {
          areaColor: 'rgba(0,0,0,0)',
          borderWidth: params.lineWidth,
          borderColor: params.lineColor
        }
      },
      emphasis: {
        label: {
          show: true
        },
        itemStyle: {
          areaColor: 'rgba(38, 171, 255, 1)',
          borderWidth: params.lineWidth
        }
      },
      roam: false
    }],
    series: [
      {
        type: 'map',
        mapType: '吉林',
        symbolSize: [370, 120],
        symbolKeepAspect: true,
        label: {
          show: false,
          textStyle: {
            color: '#ff0'
          }
        },
        // 传入进来的地图款背景颜色、边框颜色、边框粗细
        itemStyle: {
          normal: {
            areaColor: params.areaColor,
            borderWidth: params.lineWidth,
            borderColor: params.lineColor
          }
        },
        // // 高亮
        emphasis: {
          label: {
            show: false
          },
          itemStyle: {
            areaColor: 'rgba(38, 171, 255, 1)',
            borderWidth: params.lineWidth
          }
        },
        roam: false,
        data: [
          {
            name: '长春市',
            selected: true
          }
        ]
      },
      {
        name: 'center',
        type: 'scatter',
        coordinateSystem: 'geo',
        geoIndex: 0,
        hoverAnimation: false,
        animationDuration: 2000,
        symbolSize: function (val) {
          return [val[2], val[3]]
        },
        // 为了确保加载图片不会变形,symbolSize 需要是图片原始尺寸的数组；symbolKeepAspect无效, 且会导致图片变形
        // symbolSize: [370, 120],
        data: [
          {
            name: '长春市',
            symbol: `image://${SUPERSET_URL}/static/assets/images/202011/changchunshengwu.png`,
            label: { show: false },
            symbolOffset: [-120, -50],
            value: [125.35, 43.88, 370, 120]
          }
        ]
      }]
  }
  return option
}

export default chart
