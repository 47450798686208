// 中东呼吸症
import mapData from './data-source/chart-3'
import { visualmapPiece } from '../../echartSetting/visualmap'
const chart = function (params) {
  const newVisual = {
    left: params.targetMarginLeft,
    pieces: [
      { gte: 10000, label: '>=10000', color: 'rgba(67, 167, 253, 1)' },
      { gte: 7500, lte: 9999, label: '7500-9999', color: 'rgba(67, 167, 253, 0.8)' },
      { gte: 5000, lte: 7499, label: '5000-7499', color: 'rgba(67, 167, 253, 0.6)' },
      { gte: 2500, lte: 4999, label: '2500-4999', color: 'rgba(67, 167, 253, 0.4)' },
      { gte: 0, lte: 2499, label: '0-2499', color: 'rgba(67, 167, 253, 0.2)' }
    ],
    textStyle: {
      fontSize: 16,
      color: 'rgba(255, 255, 255, 1)'
    },
    selectedModel: 'single',
    itemWidth: 24,
    itemHeight: 16
  }
  const visualMapDict = Object.assign(visualmapPiece, newVisual)
  delete visualMapDict.text // 设置text 属性后, pieces 属性失效

  const option = {
    tooltip: {
      show: true,
      enterable: true,
      // 背景设置为0 解决有圆点
      backgroundColor: 'rgba(50,50,50,0)',
      confine: true, // tooltip 不超出图表范围
      trigger: 'item',
      formatter: function (params, ticket, callback) {
        const name = params.name
        const value = params.value
        const html = `
          <div class="chart_3 tooltip_box">
            <h1>${name}</h1>
            <div class="tooltip_box_main">
              <span class="label">发博人数</span>
              <span class="data">${value}</span>
            </div>
          </div>
        `
        return html
      }
    },
    visualMap: visualMapDict,
    series: [
      {
        type: 'map',
        mapType: 'china',
        label: {
          show: false,
          textStyle: {
            color: '#ff0'
          }
        },
        // 传入进来的地图款背景颜色、边框颜色、边框粗细
        itemStyle: {
          normal: {
            areaColor: params.areaColor,
            borderWidth: params.lineWidth,
            borderColor: params.lineColor
          }
        },
        // 高亮
        emphasis: {
          label: {
            show: false
          },
          itemStyle: {
            areaColor: 'rgba(38, 171, 255, 1)',
            borderWidth: params.lineWidth
          }
        },
        roam: false,
        data: mapData
      }
    ]
  }
  return option
}

export default chart
