// import echarts from 'echarts'
import { setXaxisDefault, setYaxisDefault } from '../../echartSetting/axis'
const chart = function (params) {
  const originData = params.originData
  const legend = params.legend
  const xAxisData = params.xAxisData

  const lineWidth = params.lineWidth
  const events = params.events
  function dealPoint (data) {
    const res = []
    for (let i = 0; i < data.length; i++) {
      const e = events[i]
      if (e === '无') {
        res.push({
          value: data[i],
          symbolSize: 0
        })
      } else {
        res.push(
          {
            selected: true,
            value: data[i],
            symbolSize: 5,
            itemStyle: {
              color: 'rgba(67, 167, 253, 1)',
              borderColor: 'rgba(255, 255, 255, 1)',
              borderWidth: 1
            },
            emphasis: {
              itemStyle: {
                color: 'rgba(254, 204, 81, 1)',
                borderColor: 'rgba(254, 204, 81, 1)',
                borderWidth: 2.5
              }

            }
          }
        )
      }
    }
    return res
  }

  const series = []
  for (let i = 0; i < originData.length; i++) {
    series.push(
      {
        name: legend[i],
        type: 'line',
        smooth: params.lineSmoothNumber,
        // stack: '总量',
        lineStyle: {
          color: params.textColorList[i],
          width: lineWidth
        },
        itemStyle: {
          // 设置数据点的颜色 与 标注原点的尺寸
          color: params.textColorList[i]
          // borderWidth: 10
        },
        data: dealPoint(originData[i])
      }
    )
  }

  const option = {
    tooltip: {
      alwaysShowContent: true,
      confine: true,
      // padding: ['50%', '50%'],
      formatter: function (data) {
        const date = data[0].name
        const title = events[data[0].dataIndex]
        if (title === '无') {
          return
        }
        const res = `<div class="event-back">
          <div style="color: rgba(255, 255, 255, 1); margin-bottom: 18px;
          font-family:"PingFangMedium;">${date}</div>
          <span>${title}</span></div>`
        return res
      },
      textStyle: {
        fontSize: 14,
        lineHeight: 28,
        height: 28
      },
      trigger: 'axis',
      position: [20, 200],
      axisPointer: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.3)',
          width: 1,
          type: 'solid'
        }
      }
    },
    legend: {
      icon: 'rect',
      itemHeight: 10,
      itemWidth: 20,
      align: 'right',
      right: '7%',
      top: '5%',
      data: xAxisData
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: setXaxisDefault(params),
    yAxis: setYaxisDefault(params),
    series: series
  }

  return option
}

export default chart
