/**
 * 在此图表
 *  + lineColor
 *    此配置项是一个颜色选择器，只单单选择了一个颜色，但是因为历史原因
 *    旧的图表数据库存储的是数组，返回的是一个颜色数组
 *    但配置项移动到前端处理后，lineColor，只会是一个string
 *    所以，此处的lineColor会有两类图表会用到
 *      + 以前制作的旧图表，其配置项以存储在了数据库，lineColor可能是一个数组
 *      + superset 中制作的图表，其lineColor可能是颜色数组
 *    对其进行兼容处理
 *      + 返回的是字符串的话，则new Array一个1个元素长度的数组，值为此lineColor
 *      + 返回的是数组的话，是老数据，则不做处理
 */
import { isOfType } from '../../utils/index'
const chart1 = function (params) {
  console.log('chart6', params)
  const { originData, legend, xAxisData, lineWidth, borderColor } = params
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const yAxisMaxValue = params.yMaxValueList || params.y_axis_max_value
  const yAxisMinValue = params.yMinValueList || params.y_axis_min_value
  // 正常的区间
  const yMaxValue = yAxisMaxValue[0]
  const yMinValue = yAxisMinValue[0]

  let lineColor = params.lineColor

  // 如果lineColor 是字符串的话 则是新的配置项 需要做兼容处理
  if (isOfType.string(lineColor)) {
    lineColor = [lineColor]
  }

  // 有哪些点需要被标记
  const markData = []
  originData[0].forEach((item, index) => {
    // 取非正常区间的值
    if (!(item >= yMinValue && item <= yMaxValue)) {
      markData.push({
        coord: [index, item],
        value: item
      })
    }
  })
  console.log('markData', markData)

  const option = {
    tooltip: {
      // trigger: 'axis'
    },
    xAxis: {
      show: false,
      data: xAxisData,
      axisLabel: {
        color: '#fff'
      }
    },
    yAxis: {
      show: false,
      axisLabel: {
        color: '#fff'
      }
    },
    series: {
      name: legend[0],
      type: 'line',
      // 线上的圆点
      symbolSize: 5,
      symbol: 'circle',
      // 平滑度 平滑曲线展示
      smooth: params.lineSmoothNumber,
      // 标注某一个点
      markPoint: {
        // 带圆角矩形
        symbol: 'path://M38,20H2c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h36c1.1,0,2,0.9,2,2v16C40,19.1,39.1,20,38,20z',
        symbolSize: [39, 20],
        symbolOffset: [0, '-80%'],
        label: {
          fontSize: 14,
          fontWeight: 'bold'
        },
        itemStyle: {
          color: 'rgba(255, 107, 107, 1)'
        },
        data: markData
      },
      label: {
        show: true,
        color: '#FFFFFF'
      },
      lineStyle: {
        color: lineColor[0],
        width: lineWidth
      },
      itemStyle: {
        color: lineColor[0],
        borderColor: borderColor
      },
      data: originData[0]
    }
  }
  console.log('m-chart1', option)

  return option
}

export default chart1
