const chart = function (params) {
  const data = params.originData[params.category]

  const categoryInfo = params.categoryInfo[params.category]
  const xAxisData = params.xAxisData
  // const backgroundBarList = params.background_bar_list
  const YlabelToAxis = 30

  const legend = params.legend
  const barBorderRadius = [0, 0, 0, 0]
  const barWidth = 10
  const axisFontColor = 'rgba(255, 255, 255, 0.8)'
  const axisLableColor = 'rgba(0, 122, 135, 0)'

  // 添加象形图案
  const isAddPictorialBar = true
  const pictorialBarType = 'rect'
  const pictorialBarHeight = 10
  const pictorialBarOffsetY = 0
  const pictorialBarWidth = 4
  const pictorialBarOffsetX = 4
  const axisLableFontSize = 20

  const backgroundColor = 'rgba(0, 122, 135, 0)'

  const series = []
  for (let i = 0; i < data.length; i++) {
    const currentData = data[i]
    series.push(
      {
        name: legend[i],
        type: 'bar',
        data: currentData,
        barWidth: barWidth,
        barGap: '100%',
        backgroundStyle: {
          color: params.barBackgroundColor
        },
        label: {
          show: true,
          position: 'right',
          //  label 距离顶部的位置
          distance: 20,
          //   data ==> 该范围内的 data
          formatter: '{c}',
          // formatter: '{b}数量: {c}',
          textStyle: {
            color: axisLableColor,
            fontSize: axisLableFontSize,
            fontFamily: 'Helvetica Narrow'
          }
        },
        emphasis: {
          itemStyle: {
            color: 'rgba(67, 167, 253, 1)'
          }
        },
        itemStyle: {
          normal: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 1, color: 'rgba(67, 167, 253, 0.3)' // 0% 处的颜色
              }, {
                offset: 0, color: 'rgba(67, 167, 253, 0.3)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            },
            barBorderRadius: barBorderRadius
          }
        },
        z: 2
      }
    )
    if (isAddPictorialBar === true) {
      series.push(
        {
          name: 'pictorialBar',
          type: 'pictorialBar',
          xAxisIndex: 0,
          yAxisIndex: 0,
          symbolPosition: 'end',
          symbol: pictorialBarType,
          symbolSize: [pictorialBarWidth, pictorialBarHeight],
          symbolOffset: [pictorialBarOffsetX, pictorialBarOffsetY],
          z: 10 + i * 2,
          tooltip: {
            show: false
          },
          itemStyle: {
            normal: {
              color: 'rgba(67, 167, 253, 1)'
            }
          },
          data: data[i]
        }
      )
    }
  }

  const option = {
    backgroundColor: backgroundColor,
    legend: {
      show: false,
      top: '3%',
      right: '7%',
      itemWidth: 10,
      itemHeight: 10,
      data: legend
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          color: 'rgba(0,0,0,0)'
        }
      },
      position: [250, 0],
      confine: true, // tooltip 不超出图表范围
      // alwaysShowContent: true,
      formatter: function (params) {
        const label = params[0].axisValue
        const articles = categoryInfo[label]
        if (articles === undefined) {
          return '无数据'
        }
        let res = ''
        console.log('chart6 category', articles)
        articles.forEach(element => {
          res = res + `<div style="width: 300px; text-overflow:ellipsis; white-space:nowrap; overflow:hidden; margin-top:11px">
          <a href=${element.url} style="color: rgba(255, 255, 255, 0.8);
          font-family:"PingFangMedium;">
          <span>${element.title}</span></a></div>`
        })

        return res
      },
      backgroundColor: 'rgba(56, 71, 96, 0.7)',
      padding: [9, 40, 20, 20],
      textStyle: {
        color: 'rgba(255, 255, 255, 1)',
        fontSize: 14,
        fontFamily: 'PingFangMedium',
        width: 300
      }
    },
    grid: {
      left: '8%',
      top: '5%',
      right: '8%',
      height: '90%',
      containLabel: true
    },
    xAxis: {
      gridIndex: 0,
      type: 'value',
      max: function (value) {
        return parseInt(value.max * 1.2)
      },
      show: true,
      splitLine: {
        lineStyle: {
          width: 1,
          color: 'rgba(255, 255, 255, 0.1)'
        }
      },
      axisLine: 'none',
      axisLabel: {
        textStyle: {
          color: axisFontColor,
          fontSize: 14,
          align: 'left'
        }
      }
    },
    yAxis: {
      show: true,
      type: 'category',
      axisTick: 'none',
      axisLine: {
        // show: true,
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.6)',
          width: 1
        }
      },
      // splitLine: 'none',
      // bar 距离 y轴的距离
      offset: YlabelToAxis,
      axisLabel: {
        textStyle: {
          color: axisFontColor,
          fontSize: 10,
          align: 'left'
        }
      },
      data: xAxisData
    },
    series: series
  }
  return option
}

export default chart
