<template>
  <div class="element-theme-409EFF base_filter">
    <el-select popper-class="element-theme-409EFF"
               v-model="value"
               placeholder="请选择"
               class="base-filter">
      <el-option-group v-for="group in options"
                       :key="group.label"
                       :label="group.label">
        <el-option v-for="item in group.options"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
        </el-option>
      </el-option-group>
    </el-select>
    <!-- <el-button type="warning">警告按钮</el-button> -->
    <el-button type="primary"
               icon="el-icon-search"
               class="search-icon"
               @click="searchMap"></el-button>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    searchMap () {
      // this.value
      console.log(this.value)
      let parentId = this.value
      if (parentId === '' || parentId === null || parentId === undefined) {
        parentId = '0'
      }
      console.log(this.onInteractFunc)
      // 这儿
      this.onInteractFunc(parentId)
    }
  }
}
</script>

<style lang="scss" scoped>
  .base_filter {
    flex: 2;
    display: flex;
    justify-content: space-evenly;
  }
</style>

<style lang="scss">
.element-theme-409EFF.base_filter {
  .el-input__inner {
    width: 320px;
    height: 32px;
    line-height: 32px;
    border-radius: 0;
    color: rgba(0, 0, 0, 1);
    font-family: PingFangSC;
  }
  .el-input__icon {
    line-height: 32px;
  }

  .el-select .el-input.is-focus .el-input__inner,
  .el-select .el-input__inner:focus {
    border-color: rgba(158, 158, 158, 0.5);
    border-width: 1px;
  }

  .el-select-dropdown__item.selected {
    color: #606266;
  }

  .element-theme-409EFF .el-select-dropdown__item.selected {
    color: #606266;
  }
  .el-select-dropdown__item.selected {
    color: #606266;
  }

  .search-icon {
    width: 60px;
    height: 32px;
    background: #ecad18;
    opacity: 1;
    border-color: #ecad18 !important;
    background-color: #ecad18 !important;
    padding: 0 0;
    border-radius: 0;
  }
}
</style>
