/**
 * 小黄车主体分布柱状图
 */
import { lineData, axisData, nameMap } from './data-source/chart-5'
const chart = function (params) {
  console.log(params.y_axis_max_value)
  console.log(nameMap)
  // let legend = ['负面评价', '中立评价', '正面评价']
  const colorList = ['#37A2DA', '#32C5E9', '#67E0E3', '#9FE6B8', '#FFDB5C', '#FF9F7F']
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const yAxisMaxValue = params.yMaxValueList || params.y_axis_max_value
  const series = []
  for (let i = 0; i < lineData.length; i++) {
    const content = lineData[i]
    series.push({
      name: axisData[1],
      type: 'line',
      symbolSize: 0,
      label: {
        show: false
      },
      lineStyle: {
        color: colorList[i],
        width: 2
      },
      data: content
    })
  }

  const option = {
    color: colorList,
    tooltip: {
      show: true,
      padding: [0, 0],
      textStyle: {
        fontSize: 12,
        lineHeight: 28,
        height: 28
      },
      backgroundColor: 'rgba(56, 71, 96, 0)',
      axisShowContent: true,
      alwaysShowContent: true,
      trigger: 'axis',
      confine: true,
      axisPointer: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.3)',
          width: 1,
          type: 'solid'
        }
      },
      formatter: function (params, ticket, callback) {
        const topic = params[0].name
        // console.log(params)
        const data = nameMap[topic]
        // console.log(barData)
        // let marginTop = 30
        let body = ''
        for (let i = 0; i < data.length; i++) {
          const item = data[i]
          const row = `<div style="height: 50px; display:flex;">
          <div style="color: rgba(255, 255, 255, 1); width: 260px; white-space: normal">${item.name}</div>
          <div style="display:flex;">
          <div style='background: ${colorList[i]}; width: 30px; height: 16px; margin:0px 10px 0px 30px '></div>
          <div>${item.value}</div>
          </div>
        </div>`
          body = body + row
        }
        const html = `
          <div style="width: 460px; height: 360px; padding: 20px 50px 30px 20px; background: rgba(56, 71, 96, 0.7)">
            <h1 style="margin-bottom: 20px; margin-left: -5px">${topic}</h1>
            ${body}
          </div>
        `
        return html
      }
    },
    legend: {
      show: false,
      top: 5,
      left: 0,
      icon: 'rect',
      itemGap: 25,
      itemWidth: 30,
      itemHeight: 12,
      textStyle: {
        color: 'rgba(255, 255, 255, 1)'
      }
    },
    grid: {
      left: 5,
      right: 40,
      bottom: 40,
      top: 40,
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        name: '事件时间：2018-06至2019-03',
        nameLocation: 'middle',
        nameGap: 8,
        nameTextStyle: {
          color: 'rgba(255, 255, 255, 0)',
          fontSize: 14
        },
        boundaryGap: false,
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.6)'
          }
        },
        axisLabel: {
          color: params.axisFontColor,
          fontSize: params.axisFontSize
        },
        data: axisData
      },
      {
        type: 'value',
        axisTick: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.6)'
          }
        }
      }
    ],
    yAxis: {
      type: 'value',
      max: yAxisMaxValue,
      splitLine: {
        lineStyle: {
          width: 1,
          color: 'rgba(255, 255, 255, 0.2)'
        }
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisLabel: {
        color: '#ffffff',
        margin: 16,
        fontSize: 14
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: 'rgba(255, 255, 255, 0.05)'
        }
      }
    },
    series: series
  }
  return option
}

export default chart
