import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function HorizontalBar (element, props) {
  // console.log(props)
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  // eushow 3.0
  console.log(props.colorTheme)
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const contentData = props.data[0].content_data
  const axisNames = props.data[0].axis_names
  const legend = props.data[0].legend
  const xAxisLabel = props.data[0].x_axis_label
  const yAxisLabel = props.data[0].y_axis_label

  const series = []
  for (let i = 0; i < contentData.length; i++) {
    series.push({
      name: legend[i],
      type: 'bar',
      data: contentData[i]
    }
    )
  }

  const option = {
    tooltip: {
      triggerOn: trigger,
      hideDelay: hideDelayTime
    },
    legend: {
      left: '7%',
      show: true,
      data: legend,
      itemHeight: 10,
      itemWight: 10
    },
    grid: {
      left: '5%',
      right: '7%',
      top: '10%',
      bottom: '5%',
      containLabel: true
    },
    xAxis: { name: xAxisLabel },
    yAxis: {
      name: yAxisLabel,
      type: 'category',
      data: axisNames
    },
    series: series
  }

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default HorizontalBar // 修改为Classname
