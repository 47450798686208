import {
  manageImgPath
} from '../chartCommonPart'
import './maptooltipmove.css'
const chart1 = function (props, myChart) {
  const params = props.data[0]
  const imgPath = manageImgPath('/static/assets/images/icons/gornThree.png')
  const imgPathTwo = manageImgPath('/static/assets/images/icons/gornFour.png')
  let oneNum = Math.round(Math.random() * 1500 + 800)
  let twoNum = Math.round((Math.random() * 0.5 + 0.4) * oneNum)
  let threeNum = Math.round(twoNum / oneNum * 100, 2)

  // console.log(params.aspectScale)

  // 该列表顺序代表了循环展示的顺序
  var center = {
    武汉市: [114.31,
      30.52
    ],
    江岸区: [114.30304,
      30.594911
    ],
    江汉区: [114.283109,
      30.578771
    ],
    硚口区: [114.264568,
      30.57061
    ],
    汉阳区: [114.265807,
      30.549326
    ],
    武昌区: [114.307344,
      30.546536
    ],
    青山区: [114.39707,
      30.634215
    ],
    洪山区: [114.400718,
      30.504259
    ],
    东西湖区: [114.142483,
      30.622467
    ],
    汉南区: [114.08124,
      30.309637
    ],
    蔡甸区: [114.029341,
      30.582186
    ],
    江夏区: [114.313961,
      30.349045
    ],
    黄陂区: [114.374025,
      30.874155
    ],
    新洲区: [114.802108,
      30.842149
    ]
  }
  // 地区总数
  const cityTotal = 14
  const option = {
    grip: {
      top: 10,
      bottom: 10
    },
    geo: [{
      map: params.countryName,
      roam: false, // 是否允许缩放
      zoom: 1.1, // 默认显示级别
      aspectScale: 1,
      top: 10,
      bottom: 10,
      scaleLimit: {
        min: 0,
        max: 3
      }, // 缩放级别
      label: {
        show: false,
        emphasis: {
          color: 'rgba(0,0,0,0)',
          show: false
        }
      },
      itemStyle: { // 地图区域的多边形 图形样式
        normal: {
          // areaColor: '#192C36',
          areaColor: 'rgba(1,1,1,0)',
          borderWidth: 0.1,
          // borderColor: '#2BF5EF',
          borderColor: 'rgba(1,1,1,0)'
        },
        emphasis: {
          show: false,
          label: {
            show: false
          },
          // areaColor: '#192C36',
          areaColor: 'rgba(1,1,1,0)',
          borderWidth: 0
        }
      },
      tooltip: {
        show: false
      }
    }],
    series: [{
      type: 'scatter',
      coordinateSystem: 'geo',
      z: 5,
      data: [{
        city: '武汉市',
        value: [114.31, 30.52]
      }],
      symbol: 'image://' + manageImgPath('/static/assets/images/icons/gornSite.png'),
      symbolSize: params.circleSize,
      // (value: Array|number, params: Object) => number
      // 设置旋转的角度
      symbolRotate: 0,
      animation: false,
      animationDuration: 3000,
      label: {
        normal: {
          show: true,
          formatter: function (params) {
            const name = params.data.city
            if (name === '东西湖区') {
              return `{backImgTwo|}{cityName|${name}}\n{oneNum| ${oneNum}}\n{twoNum| ${twoNum}}\n{threeNum| ${threeNum}}`
            } else {
              return `{backImg|}{cityName|${name}}\n{oneNum| ${oneNum}}\n{twoNum| ${twoNum}}\n{threeNum| ${threeNum}}`
            }
          },
          // 设置显示框的位置
          position: [-360, -100],
          padding: [0, 0],
          // offset: [-100, 0],
          fontSize: 26,
          lineHeight: 34,
          rich: {
            backImg: {
              // position: 'absolute',
              backgroundColor: {
                image: imgPath
              },
              width: 300,
              height: 340
            },
            backImgTwo: {
              // position: 'absolute',
              backgroundColor: {
                image: imgPathTwo
              },
              width: 300,
              height: 340
            },
            cityName: {
              fontFamily: 'YouSheBiaoTiHei ',
              fontWeight: 'normal',
              padding: [260, 0, 0, -280],
              color: '#ffffff',
              fontSize: 26
            },
            oneNum: {
              fontFamily: 'SonyEricssonLogo ',
              padding: [180, 0, 0, 85],
              color: '#6EF1FF',
              fontSize: 26,
              shadowColor: 'rgba(110, 241, 255, 0.8)',
              shadowOffsetX: 10,
              shadowOffsetY: 4,
              shadowBlur: 5
            },
            twoNum: {
              fontFamily: 'SonyEricssonLogo ',
              padding: [90, 0, 0, 85],
              color: '#6EF1FF',
              fontSize: 26
            },
            threeNum: {
              fontFamily: 'SonyEricssonLogo',
              padding: [0, 0, 0, 85],
              color: '#6EF1FF',
              fontSize: 26
            }
          }
        },
        emphasis: {
          show: false
        }
      },
      itemStyle: {
        color: params.circleColor
      }
    },
    // 地图
    {
      type: 'map',
      mapType: params.countryName,
      geoIndex: -1,
      zoom: 1.1, // 默认显示级别
      // 需要结合geo中的参数,共同确定地图的尺寸比例
      aspectScale: params.aspectScale,
      top: '5%',
      bottom: '5%',
      label: {
        show: params.isShowAreaTitle,
        color: params.titleColor,
        fontSize: 16,
        fontFamily: 'PingFangMedium',
        emphasis: {
          // 没有生效
          show: params.isShowAreaTitle,
          color: params.titleColor
        }
      },
      itemStyle: {
        normal: {
          borderColor: params.lineColor,
          borderWidth: 1,
          areaColor: params.areaColor
        },
        emphasis: {
          areaColor: params.hoverColor,
          borderWidth: 2,
          color: 'rgba(1, 153, 249, 1)',
          shadowBlur: 5,
          shadowColor: 'rgba(26, 26, 27, 0.3)',
          shadowOffsetX: 5,
          shadowOffsetY: 5
        }
      },
      data: []
    },
    // 自定义折线
    {
      name: 'link-line',
      type: 'custom',
      coordinateSystem: 'geo',
      z: 10,
      zlevel: 10,
      renderItem: function (params, api) {
        // 获取当前地点的原始经纬度
        const categoryFisrt = api.value(0)
        const categorySecond = api.value(1)
        // 获取经纬度映射的实际坐标
        const point = api.coord([categoryFisrt, categorySecond])
        // console.log('point', point)
        const startPonitX = point[0]
        const startPonitY = point[1]

        //  调整折线的样式
        let moveX = -20
        let movex2 = -70
        let moveY = -25
        if (categoryFisrt < 114.15) {
          moveX = -moveX
          movex2 = -movex2
        } else if (categorySecond > 30.8) {
          moveY = -moveY
        }

        const baseLine = {
          type: 'polyline',
          shape: {
            points: [
              [startPonitX, startPonitY],
              [startPonitX + moveX, startPonitY],
              [startPonitX + movex2, startPonitY + moveY]
            ],
            smooth: 0
          },
          style: {
            fill: 'rgba(0, 0, 0, 0)',
            stroke: '#FFC933',
            lineWidth: 2
          }
        }

        return baseLine
      },
      itemStyle: {
        color: 'red'
      },
      data: [{
        city: '武汉市',
        value: [114.31, 30.52]
      }]
    }
    ]
  }

  // let colorList = ['rgba(233,63,66,.9)', 'rgba(8,186,236,.9)', 'rgba(254,174,33,.8)']
  // let colorList = params.textColorList

  myChart.setOption(option)
  let cityIndex = 0
  setInterval(() => {
    // 数据情况重绘，清除formatter移动效果，也可根据自身需求是否需要，删除这两行代码
    option.series[0].data = []
    cityIndex += 1
    if (cityIndex >= cityTotal) {
      cityIndex = 0
    }
    // 可以根据特定规则让不同区域显示不同的颜色
    // var runidx = Math.floor(Math.random() * 3)
    var coordCity = Object.keys(center)[cityIndex]
    var coord = center[coordCity]
    option.series[0].data = [{
      city: coordCity,
      value: coord
    }]

    if (coord[0] < 114.15) {
      option.series[0].label.normal.position = [75, -100]
      // 设置点图标的旋转角度
      option.series[0].symbolRotate = 180
    } else if (coord[1] > 30.8) {
      option.series[0].label.normal.position = [-360, 100]
      option.series[0].symbolRotate = 0
    } else {
      option.series[0].label.normal.position = [-360, -100]
      option.series[0].symbolRotate = 0
    }

    // 设置折线
    option.series[2].data = [{
      city: coordCity,
      value: coord
    }]

    if (coordCity !== '武汉市') {
      option.series[1].data = [{
        name: coordCity,
        value: 0,
        selected: true,
        label: {
          show: false
        }
      }]
    } else {
      // 取消武汉市前一个地区的高亮显示
      option.series[1].data = [{
        name: '新洲区',
        value: 0,
        selected: false,
        label: {
          show: true
        }
      }]
    }

    props.onInteract(coordCity)
    myChart.setOption(option, false, true)
  }, params.delayTime)

  setInterval(() => {
    oneNum = oneNum + Math.round(Math.random() * 30)
    twoNum = twoNum + Math.round(Math.random() * 10)
    threeNum = Math.round(twoNum / oneNum * 100, 1)

    option.series[0].label.formatter = function (params) {
      const name = params.data.city
      if (name === '东西湖区') {
        return `{backImgTwo|}{cityName|${name}}\n{oneNum| ${oneNum}}\n{twoNum| ${twoNum}}\n{threeNum| ${threeNum}}`
      } else {
        return `{backImg|}{cityName|${name}}\n{oneNum| ${oneNum}}\n{twoNum| ${twoNum}}\n{threeNum| ${threeNum}}`
      }
    }
    myChart.setOption(option, true)
  }, 1500)
}

export default chart1
