const initChart = function ({ topics, links, color }, params) {
  console.log('chart10', params)
  // 修改点的比例
  const newData = []
  topics.forEach(function (ele) {
    ele.symbolSize = Math.round(ele.symbolSize * params.circleSize, 2)
    newData.push(ele)
  })
  const option = {
    tooltip: {
      show: true,
      padding: [12, 50, 12, 20],
      textStyle: {
        fontSize: 14,
        lineHeight: 28,
        height: 28
      },
      formatter: function (params) {
        // console.log(params)
        const targetType = params.dataType
        const res = ''
        if (targetType === 'node') {
          return `<span>${params.seriesName}</span><p>${params.name}: ${params.value}</p>`
        }
        return res
      },
      backgroundColor: 'rgba(56, 71, 96, 0.7)',
      confine: true
    },
    legend: {
      show: false,
      selectedModel: 'multi',
      itemWidth: 30,
      itemHeight: 16,
      borderRadius: 2,
      bottom: 10,
      data: ['主题'],
      textStyle: {
        color: 'rgba(255, 255, 255, 1)'
      },
      selected: {
        主题: true
      }
    },
    animationDuration: 1500,
    animationEasingUpdate: 'quinticInOut',
    series: [{
      name: '中东呼吸综合症',
      type: 'graph',
      layout: 'circular',
      label: {
        show: false,
        color: 'rgba(0,0,0,0)'
      },
      lineStyle: {
        color: 'source',
        curveness: 0.2
      },
      categories: [
        { name: '主题', itemStyle: { color: color } }
      ],
      // 接收传入进来的数据
      data: newData,
      links: links
    }]
  }
  return option
}

export default initChart
