// 山东问题疫苗
import { SUPERSET_URL } from '~/config'
const chart = function (params) {
  const option = {
    backgroundColor: 'rgba(0,0,0,0)',
    geo: {
      map: '山东',
      label: {
        show: false,
        textStyle: {
          color: '#ff0'
        }
      },
      itemStyle: { // 地图区域的多边形 图形样式
        normal: {
          areaColor: 'rgba(0,0,0,0)',
          borderWidth: params.lineWidth,
          borderColor: params.lineColor
        }
      },
      emphasis: {
        label: {
          show: false
        },
        itemStyle: {
          areaColor: 'rgba(38, 171, 255, 1)',
          borderWidth: params.lineWidth
        }
      },
      roam: false,
      // 对特定区域进行配置
      regions: [{
        name: '济南市',
        selected: true
      }]
    },
    series: [{
      type: 'map',
      mapType: '山东',
      label: {
        show: false,
        textStyle: {
          color: '#ff0'
        }
      },
      // 传入进来的地图款背景颜色、边框颜色、边框粗细
      itemStyle: {
        normal: {
          areaColor: params.areaColor,
          borderWidth: params.lineWidth,
          borderColor: params.lineColor
        }
      },
      // 高亮
      emphasis: {
        label: {
          show: false
        },
        itemStyle: {
          areaColor: 'rgba(38, 171, 255, 1)',
          borderWidth: params.lineWidth
        }
      },
      roam: false,
      data: []
    }, {
      name: 'center',
      type: 'scatter',
      coordinateSystem: 'geo',
      hoverAnimation: false,
      symbolSize: function (val) {
        return [val[2], val[3]]
      },
      animationDuration: 2000,
      data: [
        {
          name: '山东',
          symbol: `image://${SUPERSET_URL}/static/assets/images/202011/shanDong1.png`,
          label: { show: false },
          // symbolSize: [394, 150],
          symbolOffset: [170, -40],
          value: [117, 36.65, 394, 150]
        }
      ]
    }]
  }
  return option
}

export default chart
