import 'echarts-wordcloud'

const chart1 = function (params) {
  // let colors = ['#FFFFFF', '#43A7FD', '#8AD500', '#FF6F56', '#FECC51', 'rgba(255, 255, 255, 0.6)']

  function toInt (data) {
    return parseInt(data)
  }

  const data = params.originData

  const colors = params.textColorList
  const option = {
    tooltip: {
      show: true,
      position: 'top',
      textStyle: {
        fontSize: 30
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    series: [{
      type: 'wordCloud',
      // 网格大小，各项之间间距
      gridSize: params.textGap,
      // 形状 circle 圆，cardioid  心， diamond 菱形，
      // triangle-forward 、triangle 三角，star五角星
      // shape: 'diamond',
      // 字体大小范围
      sizeRange: [toInt(params.fontSizeList[0]), toInt(params.fontSizeList[1])],
      // 文字旋转角度范围
      rotationRange: [0, 0],
      // 旋转步值
      rotationStep: 0,
      textPadding: 0,
      // 自定义图形
      // maskImage: maskImage,
      left: 'center',
      top: 'center',
      right: null,
      bottom: null,
      // 画布宽
      width: params.targetWidth,
      // 画布高
      height: params.targetHeight,
      // 是否渲染超出画布的文字
      drawOutOfBound: false,
      textStyle: {
        normal: {
          color: function () {
            const index = Math.floor((Math.random() * colors.length))
            return colors[index]
          }
        },
        emphasis: {
          shadowBlur: 10,
          shadowColor: '#2ac'
        }
      },
      data: data
    }]
  }

  return option
}

export default chart1
