import { hideDelayTime, trigger } from '../chartCommonPart'
const chart1 = function (params) {
  const option = {
    backgroundColor: 'rgba(0,0,0,0)',
    tooltip: {
      show: false,
      trigger: 'item',
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      backgroundColor: 'rgba(234,234,234,0.13)',
      // borderColor: '#FFFFCC',
      showDelay: 0,
      enterable: true,
      confine: true, // tooltip 不超出图表范围
      transitionDuration: 0,
      extraCssText: 'z-index:100',
      formatter: function (params, ticket, callback) {
        // 根据业务自己拓展要显示的内容
        var nameObject = params.name
        // console.log(params);
        // console.log(name_object);
        if (nameObject === '1') {
          // ProvinceName = MapData[name_object].name;
          return '技术合同交易金额' + ': ' + params.value
        }
      }
    },
    geo: {
      map: 'china',
      // left: '500',
      // center:[114.298572, 30.584355],
      label: {
        show: false,
        textStyle: {
          color: '#ff0'
        }
      },
      itemStyle: { // 地图区域的多边形 图形样式
        normal: {
          areaColor: '#192C36',
          borderWidth: 1,
          borderColor: '#167d7c'
        }
      },
      emphasis: {
        label: {
          show: false
        },
        itemStyle: {
          areaColor: '#192C36',
          borderWidth: 2
        }
      },
      roam: false
    },
    series: [
      {
        type: 'map',
        mapType: 'china',
        roam: false, // 是否开启鼠标缩放和平移漫游
        geoIndex: 0,
        label: {
          // 决定了高亮时是否显示地理名称
          show: false,
          textStyle: {
            color: 'transparent'
          }
        },
        itemStyle: { // 地图区域的多边形 图形样式
          normal: {
            label: {
              show: false
            },
            areaColor: '#192C36',
            borderWidth: 1,
            borderType: 'solid',
            borderColor: '#167d7c'
          },
          emphasis: {
            // 是图形在高亮状态下的样式,比如在鼠标悬浮或者图例联动高亮时
            label: {
              show: false,
              color: '#fff'
            },
            borderWidth: 1,
            borderType: 'solid',
            // borderColor: 'rgba(81, 255, 254)',
            areaColor: 'transparent'
          }
        },
        data: []
      }
    ]
  }
  return option
}

export default chart1
