// 小黄车退押金事件
import { SUPERSET_URL } from '~/config'
import mapData from './data-source/chart-7'
import { visualmapPiece } from '../../echartSetting/visualmap'
// import { nameMap } from '../multiline/data-source/chart-5'
const chart = function (params) {
  const newVisual = {
    left: params.targetMarginLeft,
    pieces: [
      { gte: 400, label: '>=400', color: 'rgba(67, 167, 253, 1)' },
      { gte: 300, lte: 399, label: '300-399', color: 'rgba(67, 167, 253, 0.8)' },
      { gte: 200, lte: 299, label: '200-299', color: 'rgba(67, 167, 253, 0.6)' },
      { gte: 100, lte: 199, label: '100-199', color: 'rgba(67, 167, 253, 0.4)' },
      { gte: 0, lte: 99, label: '0-99', color: 'rgba(67, 167, 253, 0.2)' }
    ],
    textStyle: {
      fontSize: 16,
      color: 'rgba(255, 255, 255, 1)'
    },
    selectedModel: 'single',
    itemWidth: 24,
    itemHeight: 16
  }
  const visualMapDict = Object.assign(visualmapPiece, newVisual)
  delete visualMapDict.text // 设置text 属性后, pieces 属性失效

  const option = {
    tooltip: {
      show: true,
      enterable: true,
      confine: true, // tooltip 不超出图表范围
      // 背景设置为0 解决有圆点
      backgroundColor: 'rgba(50,50,50,0)',
      trigger: 'item',
      formatter: function (params, ticket, callback) {
        const name = params.name
        let value = params.value
        if (name === '北京') {
          value = 1224
        }
        // console.log(params, 'bike')
        const html = `
          <div class="chart_3 tooltip_box">
            <h1>${name}</h1>
            <div class="tooltip_box_main">
              <span class="label">发博人数</span>
              <span class="data">${value}</span>
            </div>
          </div>
        `
        return html
      }
    },
    visualMap: visualMapDict,
    geo: {
      map: 'china',
      // left: '500',
      // center:[114.298572, 30.584355],
      label: {
        show: false,
        textStyle: {
          color: '#ff0'
        }
      },
      // 地图区域的多边形 图形样式
      itemStyle: {
        normal: {
          // geo 会产生颜色叠加 需要把透明度设置为0
          areaColor: 'rgba(0,0,0,0)',
          borderWidth: params.lineWidth,
          borderColor: params.lineColor
        }
      },
      // 高亮
      emphasis: {
        label: {
          show: false
        },
        itemStyle: {
          areaColor: 'rgba(38, 171, 255, 1)',
          borderWidth: params.lineWidth
        }
      },
      roam: false
    },
    series: [
      {
        type: 'map',
        mapType: 'china',
        label: {
          show: false,
          textStyle: {
            color: '#ff0'
          }
        },
        // 传入进来的地图款背景颜色、边框颜色、边框粗细
        itemStyle: {
          normal: {
            areaColor: params.areaColor,
            borderWidth: params.lineWidth,
            borderColor: params.lineColor
          }
        },
        // 高亮
        emphasis: {
          label: {
            show: false
          },
          itemStyle: {
            areaColor: 'rgba(38, 171, 255, 1)',
            borderWidth: params.lineWidth
          }
        },
        roam: false,
        data: mapData
      },
      {
        name: 'center',
        type: 'scatter',
        tooltip: { show: true },
        coordinateSystem: 'geo',
        symbolSize: function (val) {
          return [val[2], val[3]]
        },
        hoverAnimation: false,
        data: [
          {
            name: '北京',
            label: { show: false },
            symbol: `image://${SUPERSET_URL}/static/assets/images/202011/bike.png`,
            // symbolSize: [315, 120],
            symbolOffset: [-100, 0],
            // 需要单独处理tooltip内的值
            value: [116.4551, 40.2539, 315, 120]
          }
        ],
        label: {
          normal: {
            formatter: '{b}',
            position: 'bottom',
            color: '#fff',
            show: true
          }
        },
        z: 9,
        animationDuration: 2000
      }
    ]
  }
  return option
}

export default chart
