// eslint-disable-next-line no-unused-vars
// import echarts from 'echarts'

import { EchartsSchemes } from '../echarts_theme/echarts_color_schemes'
import './table_carousel.css'

function TableCarousel (element, props) {
  // console.log(wonderland.color)
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  var data = props.data[0].data

  const colName = props.data[0].legend
  const colorTheme = EchartsSchemes[props.colorTheme]
  // console.log('color theme: ', props.colorTheme, colorTheme)
  // 当 rowCount 超过 data的数据长度时候, 只会显示最大长度, 不会产生空行
  const rowCount = props.data[0].show_row_limit
  const colorArray = colorTheme.colors
  // 自适应高度, 总行数 需要带上列名字
  const rowHeight = Math.round(props.height * 0.9 / (rowCount + 1))
  // const rowHeight = 25
  // console.log('rowHeight: ', rowHeight, props.height * 0.9 / (rowCount + 1))

  // TODO 需要设置表格颜色设置
  // TODO 设置文本居中对齐, 为长文本留出足够的的宽度, 避免图表标题移位
  // var body = div[0][0].children[0];
  // 网页加载完成后执行该onload事件
  onload = function (isPlay) {
    var body = element.children[0]
    body.setAttribute('style', 'width: 90%; margin: 0 auto')

    var originDiv = document.createElement('div')
    originDiv.setAttribute('style', 'margin: 0 auto')
    body.appendChild(originDiv)

    var firstDiv = document.createElement('div')
    firstDiv.setAttribute('style', 'margin: 0 auto')
    // 设置 firstDiv的位置信息
    var secondDiv = document.createElement('div')
    // 设置 firstDiv的位置信息
    secondDiv.setAttribute('class', 'demo')
    secondDiv.setAttribute('style', 'padding: 0px 0px; overflow: hidden; height: ' + rowHeight * rowCount + 'px') // 设置行高的整数倍, 决定了页面显示多少行内容
    // 将div 添加到 预留的节点 下
    originDiv.appendChild(firstDiv)
    originDiv.appendChild(secondDiv)

    //  添加第一个 first_table
    firstDiv.appendChild(createFirstTable(colName))
    firstDiv.childNodes[0].setAttribute('style', 'width: 100%; height: 100%;')

    //  在 secondDiv 下创建 两个div, 用于生成 表格的行
    var firstDemo = document.createElement('div')
    firstDemo.setAttribute('class', 'demo1')
    var secondDemo = document.createElement('div')
    secondDemo.setAttribute('class', 'demo2')

    //  在 firstDemo 下生成数据行
    firstDemo.appendChild(createSecondTable(data))
    firstDemo.childNodes[0].setAttribute('style', 'width: 100%; height: 100%;')

    secondDiv.appendChild(firstDemo)
    secondDiv.appendChild(secondDemo)

    // 选择当前节点下的对象
    var secondNode = body.childNodes[0].childNodes[1].children[1]
    var firstNode = body.childNodes[0].childNodes[1].children[0]
    var thirdNode = body.childNodes[0].childNodes[1]
    secondNode.innerHTML = firstNode.innerHTML

    // 设置文本播放功能
    // 选取所有的span标签， 表格中的cell对象
    const firstTableTrNodes = body.childNodes[0].childNodes[1].children[0].childNodes[0].childNodes[0].childNodes
    const secondTableTrNodes = body.childNodes[0].childNodes[1].children[1].childNodes[0].childNodes[0].childNodes
    // console.log(firstTableTrNodes)
    // console.log(firtsTableTrNodes[0].childNodes[0].childNodes[0].offsetWidth)
    setTextAnimation(firstTableTrNodes, firstNode)
    setTextAnimation(secondTableTrNodes, secondNode)

    // 参考文章 https://www.cnblogs.com/trlanfeng/archive/2012/11/04/2753280.html
    // speed 调用向上移动函数的间隔时间, moveSpeed ==> 设置移动速度, 乘积可得移动时间,  两者时间差, 是暂停时间
    const speed = 20
    let startIndex = 0
    let isStop = false
    const waitTime = 1500
    // var moveGap = Math.floor(moveTime / rowHeight)
    // 选择当前节点下的对象

    function Marquee () {
      // 两者差值 小于 行高,就可以切换 重新开始循环了
      if (secondNode.offsetHeight - thirdNode.scrollTop <= 0) {
        thirdNode.scrollTop -= firstNode.offsetHeight
        // setTimeout(1000)
      } else {
        if (startIndex === rowHeight) {
          isStop = true
          // console.log(isStop)
          setTimeout(function () {
            thirdNode.scrollTop++
            isStop = false
          }, waitTime)
          startIndex = 1
        } else if (startIndex !== rowHeight && isStop === false) {
          thirdNode.scrollTop++
          startIndex++
        }
      }
    }
    // 判断是否开始动画
    if (isPlay === true) {
      // 开启循环
      var MyMar = setInterval(Marquee, speed)
      // 鼠标放置, 停止循环
      thirdNode.onmouseover = function () {
        clearInterval(MyMar)
      }
      // 鼠标离开, 开始循环
      thirdNode.onmouseout = function () {
        MyMar = setInterval(Marquee, speed)
      }
    }
  }

  var createFirstTable = function (colNname) {
    // ----------------------------------
    //   在 firstDiv下, 生成第一个table, 用于显示标题
    // ----------------------------------
    var firstTable = document.createElement('table')
    // firstTable.setAttribute('style', 'border:0; align: center')
    firstTable.setAttribute('class', 'table-carousel-table')
    firstTable.setAttribute('cellpading', '0')
    firstTable.setAttribute('cellspacing', '0')

    // // 添加 tbody节点， 避免浏览器自己添加后，造成bug
    // eushow 中不需要
    // var firstTableTbody = document.createElement('tbody')
    // firstTableTbody.setAttribute('sytle', 'display: block')
    // firstTable.append(firstTableTbody)

    firstTable.appendChild(createTr(colName))
    firstTable.childNodes[0].setAttribute('style', 'padding: 0; margin:0; opacity: 1;color: rgba(255,255,255,1); fontSize: 30px;height: ' + rowHeight + 'px; background: ' + colorArray[0])
    return firstTable
  }

  var createSecondTable = function () {
    var secondTable = document.createElement('table')
    secondTable.setAttribute('class', 'table-carousel-table')
    secondTable.setAttribute('cellspacing', '0')

    // 添加 tbody节点， 避免浏览器自己添加后，造成bug
    var secondTableTbody = document.createElement('tbody')
    secondTableTbody.setAttribute('class', 'table-carousel-tbody')
    // secondTableTbody.setAttribute('sytle', 'display: block')
    secondTable.append(secondTableTbody)

    // for循环json对象,然后将循环到的对象通过createTr()方法生成行，添加到table中
    // TODO 需要确定数据的长度 length ==>  行的数量
    for (var i = 0; !(i >= data.length); i++) {
      const eachData = data[i]
      var colData = []
      for (var key in eachData) {
        colData.push(eachData[key])
      }
      // 需要 根据 奇偶数 设置行的颜色
      var trNode = createTr(colData)
      if (i % 2 === 0) {
        trNode.setAttribute('style', 'padding: 0; margin:0; opacity: 0.55; color: rgba(255,255,255,1);height: ' + rowHeight + 'px; background: ' + colorArray[0])
      } else {
        trNode.setAttribute('style', 'padding: 0; margin:0; color: rgba(255,255,255,1); height: ' + rowHeight + 'px')
      }

      secondTableTbody.appendChild(trNode)
    }
    return secondTable
  }

  // ----------------------------------
  //   根据用户传过来的变量生成表格中行的方法
  // ----------------------------------
  var createTr = function (colName) {
    // 定义行元素标签
    var tr = document.createElement('tr')
    tr.setAttribute('class', 'row-tr')
    const colLength = colName.length
    // console.log('colLength:', colLength);
    const colWidth = Math.floor(100 / colLength)
    // console.log('colWidth:', colWidth);
    for (var i = 0; i < colLength; i++) {
      var tdName = document.createElement('td')
      tdName.setAttribute('style', 'width: ' + colWidth + '%')
      tdName.setAttribute('class', 'cell-td')
      tr.append(tdName)

      // console.log(tr)
      // 创建 包含文本的 span标签， 用来求文本的长度
      const spanNode = document.createElement('span')
      tdName.append(spanNode)
      spanNode.innerHTML = colName[i]
      // spanNode.setAttribute('class', 'text-span')
      // tr.appendChild(tdName)
    }
    return tr
  }

  // 为span标签添加动画效果
  var setTextAnimation = function (trNodes, tableNode) {
    const colWidth = Math.floor(100 / colName.length)
    const colLength = trNodes[0].offsetWidth * colWidth * 0.01
    for (let i = 0; i < trNodes.length; i++) {
      const tdNodes = trNodes[i].childNodes
      // console.log(trNodes)
      for (let j = 0; j < tdNodes.length; j++) {
        const textLength = tdNodes[j].firstChild.offsetWidth
        // console.log('true', textLength, colLength)
        if (textLength > colLength) {
          // console.log('true', i, j, textLength, colLength)
          tdNodes[j].firstChild.setAttribute('class', 'table-animate')
          tdNodes[j].firstChild.setAttribute('transform', 'translateX( ' + textLength + ')')
        } else {
          tdNodes[j].firstChild.setAttribute('class', 'text-span')
        }
      }
    }
  }

  //  不能使用 setInterval 方法,本身就是一个递归方法
  //  props返回的内容增加 mode[edit show]  autoplay[True false],只有当 show, True时, 才执行循环刷新的方法
  const mode = props.mode
  const autoPlay = props.autoPlay
  // console.log(mode, autoPlay)
  if (mode === 'show' && autoPlay === true) {
    onload(autoPlay)
  } else {
    var noPlay = false
    onload(noPlay)
  }
}

export default TableCarousel // 修改为Classname
