import echarts from 'echarts'
import { EchartsSchemes } from '../echarts_theme/echarts_color_schemes'

function TreemapDisk (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const myChart = echarts.init(element.children[0])

  // 后端传入的数据，转化为 echarts自身定义的同类型， 不用改动echarts的输入数据类型，主要依靠后端代码处理数据格式
  const diskData = props.data[0].diskData
  const colorEchartsTheme = EchartsSchemes[props.data[0].color_echarts_theme]
  const colorArray = colorEchartsTheme.colors

  // 更换主题颜色, 前端代码传入的数据
  // const colorTheme = EchartsSchemes[props.colorTheme]
  // const colorArray = colorTheme.colors

  var formatUtil = echarts.format

  function getLevelOption () {
    return [
      {
        itemStyle: {
          normal: {
            borderWidth: 0,
            gapWidth: 2
          }
        }
      },
      {
        itemStyle: {
          normal: {
            gapWidth: 1
          }
        }
      },
      {
        colorSaturation: [0.35, 0.5],
        itemStyle: {
          normal: {
            gapWidth: 1,
            borderColorSaturation: 0.6
          }
        }
      }
    ]
  }

  const option = {
    color: colorArray,
    // title: {
    //   text: 'Disk Usage',
    //   left: 'center'
    // },
    grid: {
      top: '5%',
      left: '5%',
      right: '5%',
      bottom: '10%',
      containLabel: true
    },
    tooltip: {
      formatter: function (info) {
        var value = info.value
        var treePathInfo = info.treePathInfo
        var treePath = []

        for (var i = 1; i < treePathInfo.length; i++) {
          treePath.push(treePathInfo[i].name)
        }

        return [
          '<div class="tooltip-title">' + formatUtil.encodeHTML(treePath.join('/')) + '</div>',
          'Disk Usage: ' + formatUtil.addCommas(value) + ' KB'
        ].join('')
      }
    },

    series: [
      {
        name: 'Disk Usage',
        type: 'treemap',
        visibleMin: 300,
        label: {
          show: true,
          formatter: '{b}'
        },
        itemStyle: {
          normal: {
            borderColor: '#fff'
          }
        },
        levels: getLevelOption(),
        data: diskData,
        animationEasing: 'elasticOut',
        animationDuration: 2000
      }
    ]
  }

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default TreemapDisk
