import echarts from 'echarts'

function FirstBarOne (element, props) {
  const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const myChart = echarts.init(element.children[0], colorEchartsTheme)

  const originData = props.data[0].content_data[0]
  const xAxisData = props.data[0].xAxisData
  const backgroundBarList = props.data[0].background_bar_list
  const YlabelToAxis = props.data[0].YlabelToAxis

  const legend = props.data[0].legend
  const gradientColorStart = props.data[0].gradientColorStart
  const gradientColorEnd = props.data[0].gradientColorEnd
  const barBorderRadius = props.data[0].barBorderRadius
  const barWidth = props.data[0].barWidth
  const barBackgroundColor = props.data[0].barBackgroundColor
  // const backgroundColor = props.data[0].backgroundColor
  const axisFontSize = props.data[0].axisFontSize
  const axisFontColor = props.data[0].axisFontColor
  const axisLableColor = props.data[0].axisLableColor

  // 添加象形图案
  const isAddPictorialBar = props.data[0].isAddPictorialBar
  const pictorialBarType = props.data[0].pictorialBarType
  const pictorialBarHeight = props.data[0].pictorialBarHeight
  const pictorialBarOffsetY = props.data[0].pictorialBarOffsetY
  const pictorialBarWidth = props.data[0].pictorialBarWidth
  const pictorialBarOffsetX = props.data[0].pictorialBarOffsetX
  const pictorialBarColor = props.data[0].pictorialBarColor
  const axisLableFontSize = props.data[0].axisLableFontSize

  const series = [
    {
      name: legend[0],
      type: 'bar',
      // gridIndex:0,
      xAxisIndex: 0,
      yAxisIndex: 0,
      data: originData,
      barWidth: barWidth,
      label: {
        show: true,
        position: 'right',
        //  label 距离顶部的位置
        distance: 20,
        //   data ==> 该范围内的 data
        formatter: '{c}',
        // formatter: '{b}数量: {c}',
        textStyle: {
          color: axisLableColor,
          fontSize: axisLableFontSize,
          fontFamily: 'Helvetica Narrow'
        }
      },
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(
            //  x, y, x2, y2
            0, 0, 1, 0,
            [{
              offset: 0,
              color: gradientColorStart
            },
            {
              offset: 1,
              color: gradientColorEnd
            }
            ]
          ),
          barBorderRadius: barBorderRadius
        }
      },
      z: 2
    },
    {
      name: '背景色',
      type: 'bar',
      barWidth: barWidth,
      hoverAnimation: false,
      data: backgroundBarList,
      xAxisIndex: 1,
      yAxisIndex: 1,
      symbolSize: 35,
      label: {
        show: false,
        position: 'right',
        //  label 距离顶部的位置
        distance: 20,
        //   data ==> 该范围内的 data
        formatter: function (data) {
          return originData[data.dataIndex]
        },
        textStyle: {
          color: '#ffffff',
          fontSize: '16'
        }
      },
      itemStyle: {
        normal: {
          color: barBackgroundColor,
          barBorderRadius: barBorderRadius
        }
      },
      z: 1
    }
  ]

  // 添加象形图案
  const pictorialData = function (barData) {
    const res = []
    for (let i = 0; i < barData.length; i++) {
      res.push(
        {
          value: barData[i],
          symbolPosition: 'end'
        }
      )
    }
    return res
  }

  if (isAddPictorialBar === true) {
    series.push(
      {
        name: 'pictorialBar',
        type: 'pictorialBar',
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbol: pictorialBarType,
        symbolSize: [pictorialBarWidth, pictorialBarHeight],
        symbolOffset: [pictorialBarOffsetX, pictorialBarOffsetY],
        z: 2,
        tooltip: {
          show: false
        },
        itemStyle: {
          normal: {
            color: pictorialBarColor
          }
        },
        data: pictorialData(originData)
      }
    )
  }

  console.log(series)

  const option = {
    // backgroundColor: backgroundColor,
    legend: {
      show: false,
      top: '3%',
      right: '7%',
      itemWidth: 10,
      itemHeight: 10,
      // 如何控制图标的颜色??
      data: legend
    },
    tooltip: {
      trigger: 'item',
      textStyle: {
        fontSize: 16
      },
      formatter: function (params) {
        const name = params.seriesName
        let res = ''
        if (name !== '背景色') {
          res = params.name + ':' + '<bar>' + params.value
          return res
        }
      }
      // formatter: '{b}数量: {c}'
    },
    grid: [{
      left: '8%',
      top: '5%',
      right: '8%',
      // width: '80%',
      height: '90%',
      containLabel: true
    },
    {
      left: '8%',
      top: '5%',
      // right: '8%',
      width: '80%',
      height: '90%',
      containLabel: true
    }],
    xAxis: [{
      gridIndex: 0,
      max: backgroundBarList[0],
      show: false
    },
    {
      gridIndex: 0,
      show: false
    },
    {
      gridIndex: 1,
      max: backgroundBarList[0],
      show: false
    },
    {
      gridIndex: 1,
      show: false
    }
    ],
    yAxis: [{
      axisTick: 'none',
      axisLine: 'none',
      splitLine: 'none',
      gridIndex: 0,
      // bar 距离 y轴的距离
      offset: YlabelToAxis,
      axisLabel: {
        textStyle: {
          color: axisFontColor,
          fontSize: axisFontSize,
          align: 'left'
        }
      },
      data: xAxisData
    },
    {
      axisTick: 'none',
      axisLine: 'none',
      splitLine: 'none',
      gridIndex: 0,
      // 消除底部的超出部分
      offset: '11',
      data: []
    }
    ],
    series: series
  }

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default FirstBarOne // 修改为Classname
