import echarts from 'echarts'
import './PicCarouselTen.css'

const lineColor = ['#659EFF', '#FFC35B', '#74FF99']

const legendName = ['科技成果登记数（项）', '专利申请量（件）', '组织实施科技项目数（项）']

const originData = [
  [9, 10, 9, 9, 10],
  [317, 461, 431, 504, 619],
  [38, 34, 40, 37, 33]
]
const xAxisData = [
  [2015, 2016, 2017, 2018, 2019],
  [2015, 2016, 2017, 2018, 2019],
  [2015, 2016, 2017, 2018, 2019]
]
const maxValuesList = [12, 700, 45]
const minValuesList = [7, 300, 30]

function drawChart (targetDom, index) {
  console.log('ten chart', index)
  const myChart = echarts.init(targetDom)
  const series = []
  series.push(
    {
      name: legendName[index],
      type: 'bar',
      barWidth: 20,
      itemStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 1, color: 'rgba(255,255,255,0)'
          }, {
            offset: 0, color: lineColor[index]
          }
          ]
        }
      },
      data: originData[index],
      label: {
        show: false,
        position: 'top',
        color: lineColor[index],
        fontFamily: 'Helvetica Narrow',
        fontSize: 26
      }
    })

  const option = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      show: true,
      itemWidth: 0,
      itemHeight: 0,
      top: 0,
      left: 0,
      textStyle: {
        color: 'rgba(255,255,255,0.81)',
        fontSize: 26,
        fontFamily: 'PingFangMedium'
      }
    },
    xAxis: [{
      type: 'category',
      data: xAxisData[index],
      axisTick: {
        show: false
      },
      axisLabel: {
        // rotate: '-45',
        margin: 20,
        textStyle: {
          fontSize: 30,
          color: 'rgba(255, 255, 255, 0.6)',
          fontFamily: 'PingFangMedium'
        }
      },
      axisLine: {
        lineStyle: {
          width: 1,
          color: 'rgba(174, 238, 237, 0)'
        }
      },
      splitLine: {
        show: false
      }
    }],
    yAxis: [
      {
        type: 'value',
        name: '',
        nameGap: 10,
        max: maxValuesList[index],
        min: minValuesList[index],
        minInterval: 0.2,
        axisTick: {
          show: false
        },
        axisLabel: {
          show: true,
          margin: 37,
          fontSize: 26,
          color: 'rgba(255, 255, 255, 0.6)',
          fontFamily: 'PingFangMedium'
        },
        nameTextStyle: {
          fontSize: 20,
          color: 'rgba(255, 255, 255, 0.6)'
        },
        axisLine: {
          lineStyle: {
            width: 1,
            color: 'rgba(174, 238, 237, 0.3)'
          }
        },
        splitLine: {
          show: true,
          interval: 2,
          lineStyle: {
            width: 1,
            type: 'solid',
            color: 'rgba(174, 238, 237, 0.3)'
          }
        }
      },
      {
        type: 'value',
        axisTick: {
          show: false
        },
        show: false,
        nameGap: 10,
        max: 100,
        // max: 0.6,
        yAxisIndex: 0,
        position: 'right',
        axisLabel: {
          show: false,
          margin: 10,
          fontSize: 20
        },
        nameTextStyle: {
          fontSize: 20
          // color: axisFontColor
        },
        axisLine: {
          lineStyle: {
            width: 1,
            color: 'rgba(174, 238, 237, 0.3)'
          }
        },
        splitLine: {
          show: false
        }
      }
    ],
    grid: {
      left: 20,
      right: 10,
      top: 60,
      buttom: 5,
      containLabel: true
    },
    series: series
  }

  myChart.setOption(option, true)
}

export default drawChart
