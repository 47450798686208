<template>
  <div class="metrics">
    <div class="metrics_item" v-for="(item, index) in result" :key="index">
      <p class="metrics_item_label text_size_14 text_color_primary">Y{{index + 1}}</p>
      <el-popover
        :value="result[index].showPopover"
        popper-class="my_popover"
        trigger="manual"
        width="340"
        placement="right"
        :visible-arrow="false"
        style="flex: 1;">
        <!-- <el-select
          slot="reference"
          v-model="result[index].column.column_name"
          :clearable="true"
          style="width: 100%;" size="small"
          @visible-change="value => onSelectVisible(value, index)"
          @change="value => onResultChange(value, index)">
          <el-option v-for="item in columns" :key="item" :label="item" :value="item"></el-option>
        </el-select> -->
        <!-- 显示函数，点击的时候显示指标项，删除就删除此指标项 -->
        <div slot="reference" class="reference_input" style="width: 100%;">
          <input
            title="点击设置指标项"
            type="text"
            autocomplete="off"
            :value="item.label"
            readonly
            @click="onSelectVisible(index)" />
          <span class="input__suffix">
            <span class="input__suffix_inner">
              <i title="删除指标项" @click.stop="deleteItem(index)" class="icon el-icon-circle-close"></i>
            </span>
          </span>
        </div>
        <div class="popover">
          <div class="popover_header">
            <div
              class="popover_header_item"
              :class="editItem.expressionType === 'SIMPLE' ? 'select' : ''"
              @click="selectType(index, 'SIMPLE')">
              简单设置
            </div>
            <div
              class="popover_header_item"
              :class="editItem.expressionType === 'SQL' ? 'select' : ''"
              @click="selectType(index, 'SQL')">
              自定义SQL语句
            </div>
          </div>
          <div class="popover_body">
            <template v-if="editItem.expressionType === 'SIMPLE'">
              <el-form size="small">
                <el-form-item>
                  <el-select v-model="editItem.column.column_name" style="width: 100%;">
                    <el-option v-for="item in columns" :key="item" :label="item" :value="item"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select v-model="editItem.aggregate"  style="width: 100%;">
                    <el-option label="AVG" value="AVG"></el-option>
                    <el-option label="COUNT" value="COUNT"></el-option>
                    <el-option label="COUNT_DISTINCT" value="COUNT_DISTINCT"></el-option>
                    <el-option label="MAX" value="MAX"></el-option>
                    <el-option label="MIN" value="MIN"></el-option>
                    <el-option label="SUM" value="SUM"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </template>
            <template v-if="editItem.expressionType === 'SQL'">
              <code-editor :codes.sync="editItem.sqlExpression" language="sql" editorHeight="200px" @onEditorMounted="onEditorMounted"></code-editor>
            </template>
          </div>
          <div class="popover_footer">
            <el-button size="small" @click="onCancel(index)">关闭</el-button>
            <el-button type="primary" size="small" @click="onConfirm(index)">保存</el-button>
          </div>
        </div>
      </el-popover>
    </div>
    <p class="metrics_add text_color_primary" @click="addItem">
      <i class="iconfont iconjia"></i>
      <span class="text_size_14">新增</span>
    </p>
  </div>
</template>

<script>
import codeEditor from '~/components/code-editor'
export default {
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    columns: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data  () {
    return {
      // 指标数组
      result: [],
      monaco: null,
      // 当前所编辑的指标对象
      editItem: {
        index: 0,
        expressionType: '',
        sqlExpression: '',
        column: {
          column_name: ''
        },
        aggregate: null,
        hasCustomLabel: false,
        label: ''
      }
    }
  },
  components: {
    codeEditor
  },
  created () {
    // 根据传入的form_data.metrics循环渲染指标项 数组为0 则添加一项
    this.getResult(this.value)
  },
  watch: {
    // 外部传进来的 form_data.metrics 一变化
    value: function (newVal, oldVal) {
      this.getResult(newVal)
    }
  },
  methods: {
    // 根据 metrics 改变 result
    getResult (newVal) {
      this.result = []
      // 最起码有一个数组项
      if (newVal.length <= 0) {
        this.result.push({
          expressionType: 'SIMPLE',
          sqlExpression: '',
          column: {
            column_name: ''
          },
          aggregate: null,
          hasCustomLabel: false,
          label: '',
          showPopover: false
        })
      // 不为零 复制一些项，有些冗余项
      } else {
        newVal.forEach((element, index) => {
          this.result.push({
            expressionType: element.expressionType,
            sqlExpression: element.sqlExpression,
            column: {
              column_name: element.column.column_name || ''
            },
            aggregate: element.aggregate,
            hasCustomLabel: false,
            label: element.label,
            showPopover: false
          })
        })
      }
    },
    // 下拉框出现/隐藏时触发
    onSelectVisible (index) {
      this.hidePopover()
      // if (this.result[index].column.column_name) {
      for (const key in this.editItem) {
        if (Object.prototype.hasOwnProperty.call(this.editItem, key)) {
          if (key !== 'column') {
            this.editItem[key] = this.result[index][key]
          } else {
            this.editItem[key].column_name = this.result[index][key].column_name
          }
        }
      }
      this.editItem.index = index
      this.result[index].showPopover = true
      // }
    },
    // 选择简单设置还是自定义SQL语句
    selectType (index, type) {
      this.editItem.expressionType = type
    },
    // 编辑器挂载完成
    onEditorMounted (monaco) {
      this.monaco = monaco
    },
    // 关闭popover面板
    onCancel (index) {
      this.result[index].showPopover = false
    },
    // 保存popover内容
    onConfirm (index) {
      for (const key in this.result[index]) {
        if (Object.prototype.hasOwnProperty.call(this.result[index], key)) {
          if (key !== 'column') {
            this.result[index][key] = this.editItem[key]
          } else {
            this.result[index].column.column_name = this.editItem.column.column_name
          }
        }
      }
      this.result[index].showPopover = false

      const result = []
      this.result.forEach(element => {
        result.push({
          expressionType: element.expressionType,
          sqlExpression: element.sqlExpression,
          column: {
            column_name: element.column.column_name || ''
          },
          aggregate: element.aggregate,
          hasCustomLabel: false,
          label: this.getDefaultLabel(element)
        })
      })
      this.$emit('input', result)
    },
    // 处理label
    getDefaultLabel (element) {
      if (element.expressionType === 'SIMPLE' && element.aggregate && element.column && element.column.column_name) {
        return `${element.aggregate}(${element.column.column_name})`
      } else if (element.expressionType === 'SQL') {
        return element.sqlExpression
      }
      return ''
    },
    // 删除一项
    deleteItem (index) {
      this.result.splice(index, 1)

      const result = []
      this.result.forEach(element => {
        result.push({
          expressionType: element.expressionType,
          sqlExpression: element.sqlExpression,
          column: {
            column_name: element.column.column_name || ''
          },
          aggregate: element.aggregate,
          hasCustomLabel: false,
          label: this.getDefaultLabel(element)
        })
      })
      this.$emit('input', result)
      if (this.result.length === 0) {
        this.result.push({
          expressionType: 'SIMPLE',
          sqlExpression: '',
          column: {
            column_name: ''
          },
          aggregate: null,
          hasCustomLabel: false,
          label: '',
          showPopover: false
        })
      }
    },
    // 添加一个指标
    addItem () {
      this.hidePopover()
      this.result.push({
        expressionType: 'SIMPLE',
        sqlExpression: '',
        column: {
          column_name: ''
        },
        aggregate: null,
        hasCustomLabel: false,
        label: '',
        showPopover: false
      })
    },
    // 隐藏popover
    hidePopover () {
      this.result.forEach(element => {
        element.showPopover = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './popover.scss';
.metrics {
  &_item {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-top: 18px;
    }
    &_label {
      width: 40px;
    }
  }
  &_add {
    width: 45px;
    height: 20px;
    margin-left: 60px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      color: $color-primary;
    }
  }
}
</style>

<style lang="scss">
  .reference_input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    & > input {
      background-color: #030D17;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #606A75;
      box-sizing: border-box;
      color: #D0D2D5;
      display: inline-block;
      font-size: inherit;
      width: 100%;
      height: 32px;
      line-height: 32px;
      outline: none;
      padding: 0 30px 0 15px;
      cursor: pointer;
    }
    .input__suffix {
      position: absolute;
      height: 100%;
      right: 5px;
      top: 0;
      text-align: center;
      color: #D0D2D5;
      transition: all .3s;
      pointer-events: none;
      .input__suffix_inner {
        pointer-events: all;
        .icon {
          height: 100%;
          width: 25px;
          text-align: center;
          transition: all .3s;
          line-height: 32px;
          opacity: 0;
        }
      }
    }
    &:hover {
      .input__suffix {
        .input__suffix_inner {
          .icon {
            opacity: 1;
          }
        }
      }
    }
  }
</style>
