// 饼图
const pieData = {
  total: [
    {
      name: 'H01L 21/00',
      content: '专门适用于制造或处理半导体或固体器件或其部件的方法或设备',
      value: 169653
    }, {
      name: 'H01L 29/00',
      content: '专门适用于整流、放大、振荡或切换，并具有至少一个电位跃变势垒或表面势垒的半导体器件；具有至少一个电位跃变势垒或表面势垒，例如PN结耗尽层或载流子集结层的电容器或电阻器；半导体本体或其电极的零部件',
      value: 91590
    }, {
      name: 'H01L 23/00',
      content: '半导体或其他固态器件的零部件',
      value: 68056
    }, {
      name: 'H01L 27/00',
      content: '由在一个共用衬底内或其上形成的多个半导体或其他固态组件组成的器件',
      value: 70654
    }, {
      name: 'H01L 31/00',
      content: '对红外辐射、光、较短波长的电磁辐射，或微粒辐射敏感的，并且专门适用于把这样的辐射能转换为电能的，或者专门适用于通过这样的辐射进行电能控制的半导体器件；专门适用于制造或处理这些半导体器件或其部件的方法或设备；其零部件',
      value: 31723
    }, {
      name: 'H01L 33/00',
      content: '至少有一个电位跃变势垒或表面势垒的专门适用于光发射的半导体器件；专门适用于制造或处理这些半导体器件或其部件的方法或设备；这些半导体器件的零部件',
      value: 20759
    }, {
      name: 'H01L 51/00',
      content: '使用有机材料作有源部分或使用有机材料与其他材料的组合作有源部分的固态器件；专门适用于制造或处理这些器件或其部件的工艺方法或设备',
      value: 20350
    }, {
      name: 'H01L 25/00',
      content: '由多个单个半导体或其他固态器件组成的组装件',
      value: 13146
    }, {
      name: 'H01L 41/00',
      content: '一般压电器件；一般电致伸缩器件；一般磁致伸缩器件；专门适用于制造或处理这些器件或其部件的方法或设备；这些器件的零部件',
      value: 12028
    }, {
      name: 'G02F 1/00',
      content: '控制来自独立光源的光的强度、颜色、相位、偏振或方向的器件或装置，例如，转换、选通或调制；非线性光学',
      value: 6260
    }
  ],
  five: [
    {
      name: 'H01L 21/00',
      content: '专门适用于制造或处理半导体或固体器件或其部件的方法或设备',
      value: 60104
    }, {
      name: 'H01L 29/00',
      content: '专门适用于整流、放大、振荡或切换，并具有至少一个电位跃变势垒或表面势垒的半导体器件；具有至少一个电位跃变势垒或表面势垒，例如PN结耗尽层或载流子集结层的电容器或电阻器；半导体本体或其电极的零部件',
      value: 40773
    }, {
      name: 'H01L 23/00',
      content: '半导体或其他固态器件的零部件',
      value: 33094
    }, {
      name: 'H01L 27/00',
      content: '由在一个共用衬底内或其上形成的多个半导体或其他固态组件组成的器件',
      value: 46784
    }, {
      name: 'H01L 31/00',
      content: '对红外辐射、光、较短波长的电磁辐射，或微粒辐射敏感的，并且专门适用于把这样的辐射能转换为电能的，或者专门适用于通过这样的辐射进行电能控制的半导体器件；专门适用于制造或处理这些半导体器件或其部件的方法或设备；其零部件',
      value: 11844
    }, {
      name: 'H01L 33/00',
      content: '至少有一个电位跃变势垒或表面势垒的专门适用于光发射的半导体器件；专门适用于制造或处理这些半导体器件或其部件的方法或设备；这些半导体器件的零部件',
      value: 11928
    }, {
      name: 'H01L 51/00',
      content: '使用有机材料作有源部分或使用有机材料与其他材料的组合作有源部分的固态器件；专门适用于制造或处理这些器件或其部件的工艺方法或设备',
      value: 15354
    }, {
      name: 'H01L 25/00',
      content: '由多个单个半导体或其他固态器件组成的组装件',
      value: 10760
    }, {
      name: 'H01L 41/00',
      content: '一般压电器件；一般电致伸缩器件；一般磁致伸缩器件；专门适用于制造或处理这些器件或其部件的方法或设备；这些器件的零部件',
      value: 3270
    }, {
      name: 'G02F 1/00',
      content: '控制来自独立光源的光的强度、颜色、相位、偏振或方向的器件或装置，例如，转换、选通或调制；非线性光学',
      value: 5124
    }
  ],
  ten: [
    {
      name: 'H01L 21/00',
      content: '专门适用于制造或处理半导体或固体器件或其部件的方法或设备',
      value: 98707
    }, {
      name: 'H01L 29/00',
      content: '专门适用于整流、放大、振荡或切换，并具有至少一个电位跃变势垒或表面势垒的半导体器件；具有至少一个电位跃变势垒或表面势垒，例如PN结耗尽层或载流子集结层的电容器或电阻器；半导体本体或其电极的零部件',
      value: 60697
    }, {
      name: 'H01L 23/00',
      content: '半导体或其他固态器件的零部件',
      value: 46761
    }, {
      name: 'H01L 27/00',
      content: '由在一个共用衬底内或其上形成的多个半导体或其他固态组件组成的器件',
      value: 55827
    }, {
      name: 'H01L 31/00',
      content: '对红外辐射、光、较短波长的电磁辐射，或微粒辐射敏感的，并且专门适用于把这样的辐射能转换为电能的，或者专门适用于通过这样的辐射进行电能控制的半导体器件；专门适用于制造或处理这些半导体器件或其部件的方法或设备；其零部件',
      value: 19904
    }, {
      name: 'H01L 33/00',
      content: '至少有一个电位跃变势垒或表面势垒的专门适用于光发射的半导体器件；专门适用于制造或处理这些半导体器件或其部件的方法或设备；这些半导体器件的零部件',
      value: 17799
    }, {
      name: 'H01L 51/00',
      content: '使用有机材料作有源部分或使用有机材料与其他材料的组合作有源部分的固态器件；专门适用于制造或处理这些器件或其部件的工艺方法或设备',
      value: 19281
    }, {
      name: 'H01L 25/00',
      content: '由多个单个半导体或其他固态器件组成的组装件',
      value: 11921
    }, {
      name: 'H01L 41/00',
      content: '一般压电器件；一般电致伸缩器件；一般磁致伸缩器件；专门适用于制造或处理这些器件或其部件的方法或设备；这些器件的零部件',
      value: 5661
    }, {
      name: 'G02F 1/00',
      content: '控制来自独立光源的光的强度、颜色、相位、偏振或方向的器件或装置，例如，转换、选通或调制；非线性光学',
      value: 5755
    }
  ],
  twenty: [
    {
      name: 'H01L 21/00',
      content: '专门适用于制造或处理半导体或固体器件或其部件的方法或设备',
      value: 147432
    }, {
      name: 'H01L 29/00',
      content: '专门适用于整流、放大、振荡或切换，并具有至少一个电位跃变势垒或表面势垒的半导体器件；具有至少一个电位跃变势垒或表面势垒，例如PN结耗尽层或载流子集结层的电容器或电阻器；半导体本体或其电极的零部件',
      value: 78132
    }, {
      name: 'H01L 23/00',
      content: '半导体或其他固态器件的零部件',
      value: 60706
    }, {
      name: 'H01L 27/00',
      content: '由在一个共用衬底内或其上形成的多个半导体或其他固态组件组成的器件',
      value: 62821
    }, {
      name: 'H01L 31/00',
      content: '对红外辐射、光、较短波长的电磁辐射，或微粒辐射敏感的，并且专门适用于把这样的辐射能转换为电能的，或者专门适用于通过这样的辐射进行电能控制的半导体器件；专门适用于制造或处理这些半导体器件或其部件的方法或设备；其零部件',
      value: 26771
    }, {
      name: 'H01L 33/00',
      content: '至少有一个电位跃变势垒或表面势垒的专门适用于光发射的半导体器件；专门适用于制造或处理这些半导体器件或其部件的方法或设备；这些半导体器件的零部件',
      value: 19676
    }, {
      name: 'H01L 51/00',
      content: '使用有机材料作有源部分或使用有机材料与其他材料的组合作有源部分的固态器件；专门适用于制造或处理这些器件或其部件的工艺方法或设备',
      value: 20313
    }, {
      name: 'H01L 25/00',
      content: '由多个单个半导体或其他固态器件组成的组装件',
      value: 12596
    }, {
      name: 'H01L 41/00',
      content: '一般压电器件；一般电致伸缩器件；一般磁致伸缩器件；专门适用于制造或处理这些器件或其部件的方法或设备；这些器件的零部件',
      value: 8557
    }, {
      name: 'G02F 1/00',
      content: '控制来自独立光源的光的强度、颜色、相位、偏振或方向的器件或装置，例如，转换、选通或调制；非线性光学',
      value: 6057
    }
  ]
}
// 表格
const tableData = {
  total: [
    {
      name: '国际商业机器公司',
      value: 17870
    }, {
      name: '台湾积体电路制造股份有限公司',
      value: 15015
    }, {
      name: '三星电子株式会社',
      value: 14464
    }, {
      name: '美光科技有限公司',
      value: 12866
    }, {
      name: '株式会社东芝',
      value: 10098
    }, {
      name: '株式会社半导体能源研究所',
      value: 9104
    }, {
      name: '德州仪器公司',
      value: 5928
    }, {
      name: '三星显示有限公司',
      value: 5789
    }, {
      name: '英特尔公司',
      value: 5237
    }, {
      name: '英飞凌科技公司',
      value: 5081
    }, {
      name: '株式会社索尼',
      value: 4765
    }, {
      name: '联华电子股份有限公司',
      value: 4493
    }, {
      name: '超威半导体公司',
      value: 4396
    }, {
      name: '日本电气股份有限公司',
      value: 4090
    }, {
      name: '格罗方德半导体股份有限公司',
      value: 4010
    }, {
      name: '应用材料公司',
      value: 3954
    }, {
      name: '佳能株式会社',
      value: 3752
    }, {
      name: '株式会社日立制作所',
      value: 3684
    }, {
      name: '三菱电机株式会社',
      value: 3499
    }, {
      name: '东京电子有限公司',
      value: 3335
    }
  ],
  five: [
    {
      name: '国际商业机器公司',
      value: 6573
    }, {
      name: '台湾积体电路制造股份有限公司',
      value: 9140
    }, {
      name: '三星电子株式会社',
      value: 5518
    }, {
      name: '美光科技有限公司',
      value: 2166
    }, {
      name: '株式会社东芝',
      value: 2342
    }, {
      name: '株式会社半导体能源研究所',
      value: 3754
    }, {
      name: '德州仪器公司',
      value: 995
    }, {
      name: '三星显示有限公司',
      value: 4701
    }, {
      name: '英特尔公司',
      value: 1841
    }, {
      name: '英飞凌科技公司',
      value: 1608
    }, {
      name: '株式会社索尼',
      value: 1580
    }, {
      name: '联华电子股份有限公司',
      value: 1493
    }, {
      name: '超威半导体公司',
      value: 59
    }, {
      name: '日本电气股份有限公司',
      value: 113
    }, {
      name: '格罗方德半导体股份有限公司',
      value: 3186
    }, {
      name: '应用材料公司',
      value: 1661
    }, {
      name: '佳能株式会社',
      value: 1150
    }, {
      name: '株式会社日立制作所',
      value: 165
    }, {
      name: '三菱电机株式会社',
      value: 1
    }, {
      name: '东京电子有限公司',
      value: 1573
    }
  ],
  ten: [
    {
      name: '国际商业机器公司',
      value: 11141
    }, {
      name: '台湾积体电路制造股份有限公司',
      value: 11708
    }, {
      name: '三星电子株式会社',
      value: 9738
    }, {
      name: '美光科技有限公司',
      value: 4382
    }, {
      name: '株式会社东芝',
      value: 5198
    }, {
      name: '株式会社半导体能源研究所',
      value: 6542
    }, {
      name: '德州仪器公司',
      value: 1894
    }, {
      name: '三星显示有限公司',
      value: 5781
    }, {
      name: '英特尔公司',
      value: 2752
    }, {
      name: '英飞凌科技公司',
      value: 2960
    }, {
      name: '株式会社索尼',
      value: 2784
    }, {
      name: '联华电子股份有限公司',
      value: 2168
    }, {
      name: '超威半导体公司',
      value: 347
    }, {
      name: '日本电气股份有限公司',
      value: 410
    }, {
      name: '格罗方德半导体股份有限公司',
      value: 4001
    }, {
      name: '应用材料公司',
      value: 2531
    }, {
      name: '佳能株式会社',
      value: 1991
    }, {
      name: '株式会社日立制作所',
      value: 482
    }, {
      name: '三菱电机株式会社',
      value: 44
    }, {
      name: '东京电子有限公司',
      value: 2539
    }
  ],
  twenty: [
    {
      name: '国际商业机器公司',
      value: 33252
    }, {
      name: '台湾积体电路制造股份有限公司',
      value: 29199
    }, {
      name: '三星电子株式会社',
      value: 28067
    }, {
      name: '美光科技有限公司',
      value: 23940
    }, {
      name: '株式会社东芝',
      value: 17917
    }, {
      name: '株式会社半导体能源研究所',
      value: 17581
    }, {
      name: '德州仪器公司',
      value: 9633
    }, {
      name: '三星显示有限公司',
      value: 11571
    }, {
      name: '英特尔公司',
      value: 10020
    }, {
      name: '英飞凌科技公司',
      value: 10140
    }, {
      name: '株式会社索尼',
      value: 8672
    }, {
      name: '联华电子股份有限公司',
      value: 7952
    }, {
      name: '超威半导体公司',
      value: 7475
    }, {
      name: '日本电气股份有限公司',
      value: 5803
    }, {
      name: '格罗方德半导体股份有限公司',
      value: 8020
    }, {
      name: '应用材料公司',
      value: 7616
    }, {
      name: '佳能株式会社',
      value: 6592
    }, {
      name: '株式会社日立制作所',
      value: 5585
    }, {
      name: '三菱电机株式会社',
      value: 4745
    }, {
      name: '东京电子有限公司',
      value: 6509
    }
  ]
}
// 雷达图
const secondPieData = {
  five: {
    country: [{
      value: [21618.0, 12863.0, 12027.0, 12486.0, 4577.0, 2484.0, 2287.0, 3774.0, 975.0, 431.0],
      name: '美国'
    }, {
      value: [14503.0, 9925.0, 7689.0, 13256.0, 2889.0, 3688.0, 4707.0, 2294.0, 1634.0, 1707.0],
      name: '日本'
    }, {
      value: [3236.0, 2927.0, 1173.0, 5008.0, 509.0, 739.0, 2087.0, 406.0, 99.0, 1665.0],
      name: '中国大陆'
    }, {
      value: [11261.0, 7438.0, 6782.0, 6545.0, 966.0, 1551.0, 678.0, 2304.0, 78.0, 377.0],
      name: '中国台湾'
    }, {
      value: [5687.0, 5222.0, 3289.0, 10278.0, 1320.0, 2525.0, 5874.0, 1395.0, 240.0, 1493.0],
      name: '韩国'
    }],
    system: [{
      value: [5390.0, 4186.0, 2124.0, 2675.0, 502.0, 104.0, 173.0, 298.0, 77.0, 13.0],
      name: 'IBM'
    }, {
      value: [7485.0, 4524.0, 3974.0, 3446.0, 380.0, 58.0, 37.0, 1283.0, 6.0, 5.0],
      name: '台积电'
    }, {
      value: [2784.0, 2178.0, 1604.0, 3128.0, 287.0, 563.0, 442.0, 623.0, 51.0, 111.0],
      name: '三星电子'
    }, {
      value: [1258.0, 705.0, 740.0, 1391.0, 54.0, 130.0, 20.0, 367.0, 1.0, 4.0],
      name: '美光科技'
    }, {
      value: [872.0, 1013.0, 468.0, 1072.0, 190.0, 305.0, 109.0, 137.0, 9.0, 13.0],
      name: '东芝'
    }]
  },
  ten: {
    country: [{
      value: [35366, 18806, 16300, 14975, 7239, 3394, 2978, 4191, 1530, 495],
      name: '美国'
    }, {
      value: [24625, 16290, 11478, 16403, 5379, 5637, 6314, 2576, 2840, 1947],
      name: '日本'
    }, {
      value: [3999, 3283, 1390, 5175, 613, 881, 2133, 420, 135, 1692],
      name: '中国大陆'
    }, {
      value: [15174, 9552, 8584, 7352, 1633, 2492, 851, 2399, 144, 435],
      name: '中国台湾'
    }, {
      value: [10538, 8166, 4521, 11678, 2365, 3909, 6839, 1530, 324, 1709],
      name: '韩国'
    }],
    system: [{
      value: [8198, 5371, 2852, 3215, 779, 121, 216, 328, 81, 17],
      name: 'IBM'
    }, {
      value: [8972, 5221, 4539, 3738, 525, 94, 39, 1318, 10, 5],
      name: '台积电'
    }, {
      value: [4688, 3370, 2213, 3662, 613, 807, 585, 684, 86, 159],
      name: '三星电子'
    }, {
      value: [2606, 1268, 1038, 1631, 159, 167, 27, 385, 3, 4],
      name: '美光科技'
    }, {
      value: [1915, 2180, 824, 1449, 383, 561, 128, 160, 33, 16],
      name: '东芝'
    }]
  },
  twenty: {
    country: [{
      value: [56345, 25299, 22108, 17514, 9759, 3856, 3339, 4551, 2266, 546],
      name: '美国'
    }, {
      value: [36390, 22446, 16025, 19008, 7961, 6531, 6640, 2761, 4303, 2060],
      name: '日本'
    }, {
      value: [4104, 3306, 1462, 5190, 626, 883, 2136, 421, 142, 1692],
      name: '中国大陆'
    }, {
      value: [21279, 10967, 10069, 7814, 2046, 2645, 888, 2415, 192, 455],
      name: '中国台湾'
    }, {
      value: [15901, 9764, 5228, 12291, 2916, 4045, 7011, 1543, 409, 1808],
      name: '韩国'
    }],
    system: [{
      value: [10660, 6238, 3528, 3576, 1008, 135, 236, 336, 85, 19],
      name: 'IBM'
    }, {
      value: [10814, 5633, 4784, 3867, 627, 95, 39, 1318, 11, 6],
      name: '台积电'
    }, {
      value: [6910, 4236, 2619, 4004, 865, 840, 613, 693, 107, 185],
      name: '三星电子'
    }, {
      value: [6660, 2376, 2136, 2200, 503, 180, 29, 399, 7, 7],
      name: '美光科技'
    }, {
      value: [2860, 3093, 1214, 1789, 643, 640, 133, 176, 67, 20],
      name: '东芝'
    }]
  },
  total: {
    country: [{
      value: [67333, 31271, 25648, 20898, 11979, 4240, 3361, 4849, 3576, 603],
      name: '美国'
    }, {
      value: [42899, 27767, 18789, 22214, 9761, 7053, 6647, 2922, 5704, 2161],
      name: '日本'
    }, {
      value: [4113, 3311, 1463, 5192, 628, 883, 2136, 421, 143, 1692],
      name: '中国大陆'
    }, {
      value: [22986, 11244, 10201, 8010, 2113, 2663, 889, 2415, 198, 456],
      name: '中国台湾'
    }, {
      value: [17086, 10129, 5419, 12530, 3025, 4061, 7012, 1549, 426, 1825],
      name: '韩国'
    }],
    system: [{
      value: [11793, 6773, 3906, 3858, 1081, 151, 238, 358, 95, 23],
      name: 'IBM'
    }, {
      value: [11281, 5685, 4822, 3906, 641, 95, 40, 1318, 11, 6],
      name: '台积电'
    }, {
      value: [7307, 4389, 2700, 4121, 898, 846, 613, 695, 108, 188],
      name: '三星电子'
    }, {
      value: [7457, 2568, 2304, 2335, 531, 180, 29, 402, 7, 7],
      name: '美光科技'
    }, {
      value: [3570, 3878, 1626, 2196, 794, 717, 133, 202, 95, 30],
      name: '东芝'
    }]
  }
}
// 雷达图的维度
const radarMaxValue = {
  country: [
    { name: 'H01L 21/00', max: 67333 },
    { name: 'H01L 29/00', max: 31271 },
    { name: 'H01L 23/00', max: 25648 },
    { name: 'H01L 27/00', max: 22214 },
    { name: 'H01L 31/00', max: 11979 },
    { name: 'H01L 33/00', max: 7053 },
    { name: 'H01L 51/00', max: 7012 },
    { name: 'H01L 25/00', max: 4849 },
    { name: 'H01L 41/00', max: 5704 },
    { name: 'G02F 1/00', max: 2161 }
  ],
  system: [
    { name: 'H01L 21/00', max: 11793 },
    { name: 'H01L 29/00', max: 6773 },
    { name: 'H01L 23/00', max: 4822 },
    { name: 'H01L 27/00', max: 4121 },
    { name: 'H01L 31/00', max: 1081 },
    { name: 'H01L 33/00', max: 846 },
    { name: 'H01L 51/00', max: 613 },
    { name: 'H01L 25/00', max: 1318 },
    { name: 'H01L 41/00', max: 108 },
    { name: 'G02F 1/00', max: 188 }
  ]
}

// 下拉选项列表
const options = [
  {
    label: '1976年至今',
    value: 'total'
  },
  {
    label: '近5年',
    value: 'five'
  },
  {
    label: '近10年',
    value: 'ten'
  },
  {
    label: '近20年',
    value: 'twenty'
  }
]
// 雷达图下拉选项
const radarOptions = [
  {
    label: '主要机构的技术领域分布',
    value: 'system'
  },
  {
    label: '主要地区的技术领域分布',
    value: 'country'
  }
]

// 全部数据
const allData = {}

options.forEach(item => {
  allData[item.value] = {
    pieData: pieData[item.value],
    tableData: tableData[item.value],
    secondPieData: secondPieData[item.value]
  }
})

export {
  options,
  radarOptions,
  radarMaxValue,
  allData
}
