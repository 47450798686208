<template>
  <div class="chart_30">
    <section class="periods">
      <div class="select_box">
        <el-select
          class="my-lib-el-select transparency"
          size="small"
          v-model="periodsValue"
          placeholder="请选择">
          <el-option
            v-for="item in periodsOptions"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </div>
      <div class="periods_main">
        <vue-scroll>
          <div
            v-for="(value, key) in curPeriods"
            :key="key"
            :class="['main_list',
            {'dianzan': key === '点赞'},
            {'pinglun': key === '评论'},
            {'zhuanfa': key === '转发'}]">
            <div class="list_header">
              <span>{{ key }}</span>
            </div>
            <div class="list_content">
              <div class="item" v-for="(item, index) in value" :key="index">
                <p class="" :title="item">{{ item }}</p>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
    </section>
  </div>
</template>

<script>
import { periods } from './data-source/data'
export default {
  data () {
    return {
      // 事件周期选项
      periodsValue: '潜伏期'
    }
  },
  computed: {
    periods () {
      return periods
    },
    // 周期选项
    periodsOptions () {
      const kes = Object.keys(periods)
      return kes
    },
    // 当前时间周期
    curPeriods () {
      const list = this.periods[this.periodsValue]
      return list
    }
  }
}
</script>
<style lang="scss" scoped>
  .chart_30 {
    position: relative;
    width: 366px;
    .periods {
      width: 100%;
      .select_box {
        margin-bottom: 16px;
      }
      .periods_main {
        // width: 366px;
        width: 100%;
        height: 441px;
        .main_list {
          width: 100%;
          .list_header {
            display: flex;
            align-items: center;
            width: 100%;
            height: 30px;
            background-repeat: no-repeat;
            background-position: 100% 50%;
            margin-bottom: 10px;
            & > span {
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: #FFFFFF;
            }
          }
          .list_content {
            width: 100%;
            .item {
              display: flex;
              align-items: center;
              width: 366px;
              height: 40px;
              background-size: 100% 100%;
              background-repeat: no-repeat;
              padding: 0 10px;
              &+.item {
                margin-top: 16px;
              }
              & > p {
                width: 100%;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 20px;
                color: #FFFFFF;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
          &+.main_list {
            margin-top: 20px;
          }
          &.dianzan {
            .list_header {
              background-image: url('./img/dianzan_icon.png');
            }
            .item {
              background-image: url('./img/dianzan_bg.png');
            }
          }
          &.pinglun {
            .list_header {
              background-image: url('./img/pinglun_icon.png');
            }
            .item {
              background-image: url('./img/pinglun_bg.png');
            }
          }
          &.zhuanfa {
            .list_header {
              background-image: url('./img/zhuanfa_icon.png');
            }
            .item {
              background-image: url('./img/zhuanfa_bg.png');
            }
          }
        }
      }
    }
  }
</style>
