import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function SecondBarColor (element, props) {
  const resultData = props.data[0].resultData
  const xAxisData = props.data[0].xAxisData
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const PieType = props.data[0].pieTableType || props.data[0].pie_table_type
  // const colorEchartTheme = props.data[0].color_echarts_theme;

  const backgroundColor = 'rgba(0,0,0,0)'
  const numberFontSize = props.data[0].numberFontSize
  const startLine = props.data[0].startLine
  const endLine = props.data[0].endLine

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const series = []
  if (PieType === '南丁格尔玫瑰图') {
    series.push(
      {
        name: 'pie',
        type: 'pie',
        radius: '70%',
        // 选中对象偏移的距离
        // selectedOffset: 20,
        center: ['40%', '50%'],
        data: resultData,
        roseType: 'radius',
        label: {
          fontSize: numberFontSize,
          formatter: '{b}:\n {c} ({d}%)'

        },
        labelLine: {
          length: startLine,
          length2: endLine
        },
        itemStyle: {
          normal: {
            shadowBlur: 10,
            shadowOffsetX: 2,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          },
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 2,
            shadowColor: 'rgba(0, 0, 0, 0.9)'
          }
        }
      }
    )
  } else {
    series.push(
      {
        name: 'pie',
        type: 'pie',
        radius: '70%',
        // 选中对象偏移的距离
        selectedOffset: 20,
        center: ['50%', '50%'],
        data: resultData,
        label: {
          fontSize: numberFontSize,
          formatter: '{b}:\n {c} ({d}%)'
        },
        labelLine: {
          length: startLine,
          length2: endLine
        },
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    )
  }

  const option = {
    // color: ['#8B52D6', '#AEEEED', '#FD8C57', '#4F7BF4', '#38C589'],
    // color: ['#38C589', '#8B52D6', '#AEEEED', '#FD8C57', '#4F7BF4'],
    color: ['#4F7BF4', '#FD8C57', '#AEEEED', '#8B52D6', '#38C589'],
    backgroundColor: backgroundColor,
    tooltip: {
      fontSize: numberFontSize,
      trigger: 'item',
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      textStyle: {
        fontSize: 22
      },
      formatter: '{a} <br/>{b} : {c} ({d}%)'
    },
    grid: {
      left: 10,
      right: 10,
      containLabel: true
    },
    legend: {
      show: false,
      itemHeight: 10,
      itemWidth: 20,
      textStyle: {
        fontSize: 10
      },
      orient: 'vertical',
      right: '3%',
      top: '10%',
      data: xAxisData
    },
    series: series
  }

  // 添加页面点击事件的监听
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    // console.log(params)
    // 传入鼠标点击的对象内容
    props.onInteract(gorupByValue)
  })

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  //  props返回的内容增加 mode[edit show]  autoplay[True false],只有当 show, True时, 才执行循环刷新的方法
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default SecondBarColor // 修改为Classname
