// 横坐标的数据
const xData = ['1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019']

// 折线图数据
const yData = {
  美国: [579, 558, 478, 463, 542, 537, 464, 541, 578, 607, 726, 847, 745, 964, 997, 1339, 1287, 1360, 1540, 1536, 1558, 1702, 2287, 2920, 3566, 4296, 4246, 4314, 4357, 4269, 4387, 4161, 3899, 3953, 5083, 4981, 4808, 5400, 6697, 7396, 7433, 7675, 7146, 7883],
  日本: [156, 194, 124, 124, 158, 191, 183, 215, 277, 319, 398, 543, 504, 675, 814, 1093, 1214, 1346, 1541, 1492, 1471, 1588, 1952, 2281, 2397, 2728, 2960, 3095, 3306, 3005, 3251, 3148, 3023, 3638, 4922, 4960, 4590, 5167, 6030, 7271, 6774, 6720, 6216, 6763],
  中国: [
    // 中国大陆
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 0, 0, 0, 1, 1, 0, 1, 1, 1, 9, 1, 2, 5, 13, 6, 17, 24, 37, 50, 84, 138, 151, 185, 395, 582, 840, 1123, 1509, 1511, 2464],
    // 中国台湾
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 4, 17, 19, 35, 69, 190, 250, 329, 514, 750, 1142, 1397, 1017, 904, 983, 850, 951, 934, 987, 974, 1277, 1401, 1494, 1884, 2490, 3008, 3395, 3514, 3468, 3660],
    // 中国香港
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 3, 3, 1, 1, 4, 2, 7, 8, 9, 19, 19, 25, 13, 19, 9, 18, 20, 27, 14, 21, 33, 33, 32, 26, 26],
    // 中国澳门
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0]
  ],
  韩国: [0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 6, 17, 45, 43, 79, 132, 171, 168, 253, 415, 496, 522, 629, 672, 698, 745, 761, 965, 1099, 1313, 1706, 2214, 2290, 1926, 2120, 2813, 3476, 3618, 3699, 3465, 3819],
  德国: [50, 52, 60, 47, 48, 50, 45, 47, 52, 58, 53, 74, 74, 80, 89, 74, 60, 73, 73, 102, 76, 107, 159, 189, 254, 286, 318, 389, 439, 477, 464, 456, 429, 428, 711, 641, 561, 631, 803, 1031, 1026, 1052, 903, 933],
  法国: [26, 20, 16, 20, 27, 23, 32, 22, 26, 28, 29, 37, 41, 54, 34, 55, 30, 49, 58, 38, 44, 41, 59, 66, 69, 89, 91, 101, 102, 113, 125, 121, 98, 155, 190, 211, 241, 280, 374, 429, 400, 415, 350, 363],
  新加坡: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 5, 4, 2, 8, 28, 34, 39, 75, 124, 139, 107, 97, 69, 101, 125, 133, 139, 255, 336, 365, 380, 396, 404, 425, 396, 285, 304],
  荷兰: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 10, 1, 3, 4, 3, 6, 4, 3, 3, 2, 5, 3, 7, 10, 68, 120, 106, 88, 89, 100, 82, 102, 164, 163, 153, 150, 206, 251, 307, 286, 205, 238],
  英国: [14, 9, 15, 9, 7, 17, 12, 5, 13, 10, 20, 26, 20, 33, 32, 27, 15, 11, 21, 15, 17, 10, 14, 13, 20, 29, 29, 43, 29, 38, 45, 47, 37, 49, 57, 62, 71, 92, 102, 138, 114, 126, 145, 155],
  意大利: [1, 0, 2, 0, 5, 4, 4, 2, 3, 1, 5, 9, 13, 23, 25, 27, 21, 19, 20, 25, 33, 40, 49, 53, 61, 98, 96, 77, 51, 45, 43, 37, 35, 38, 43, 60, 45, 66, 77, 98, 84, 82, 92, 93],
  奥地利: [0, 0, 0, 1, 1, 0, 0, 2, 1, 1, 0, 1, 1, 1, 2, 1, 1, 0, 1, 1, 1, 3, 0, 1, 5, 4, 1, 1, 3, 3, 7, 5, 12, 21, 53, 60, 61, 77, 117, 157, 192, 193, 157, 164],
  加拿大: [7, 4, 10, 1, 1, 1, 6, 4, 10, 6, 6, 13, 6, 12, 13, 12, 8, 10, 21, 17, 19, 14, 17, 18, 19, 22, 19, 40, 26, 33, 32, 26, 27, 16, 28, 36, 48, 50, 83, 80, 88, 75, 58, 75],
  瑞士: [8, 13, 14, 10, 11, 7, 10, 7, 5, 11, 8, 13, 5, 10, 18, 16, 12, 9, 7, 9, 13, 18, 32, 23, 23, 16, 15, 16, 19, 15, 22, 23, 15, 31, 41, 36, 36, 46, 62, 71, 74, 63, 61, 77],
  比利时: [0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0, 1, 1, 2, 1, 0, 1, 2, 5, 8, 22, 12, 27, 23, 20, 18, 22, 24, 29, 45, 39, 41, 49, 47, 72, 82, 61, 70, 90],
  以色列: [0, 1, 0, 0, 0, 1, 0, 1, 0, 0, 0, 1, 1, 1, 5, 2, 1, 1, 3, 3, 2, 9, 5, 8, 15, 20, 17, 18, 15, 16, 16, 18, 13, 24, 27, 20, 15, 25, 34, 47, 49, 74, 58, 55],
  瑞典: [4, 0, 0, 0, 1, 1, 0, 1, 2, 2, 2, 2, 2, 3, 4, 2, 2, 2, 1, 4, 6, 8, 8, 15, 30, 30, 32, 18, 12, 10, 10, 8, 8, 12, 19, 19, 22, 17, 28, 42, 46, 26, 34, 37],
  百慕大: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 5, 9, 9, 3, 17, 33, 53, 66, 28, 31, 31, 21, 23, 10, 10, 13],
  芬兰: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 1, 0, 0, 1, 3, 0, 1, 0, 0, 3, 3, 8, 9, 10, 10, 8, 9, 9, 14, 5, 9, 18, 15, 14, 17, 17, 35, 32, 25, 29, 44],
  爱尔兰: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 1, 0, 0, 3, 0, 1, 1, 3, 6, 0, 2, 4, 1, 4, 11, 17, 10, 12, 35, 43, 58, 110],
  澳大利亚: [0, 0, 0, 0, 0, 2, 2, 1, 1, 1, 3, 0, 2, 0, 2, 4, 4, 0, 2, 1, 1, 2, 1, 2, 4, 7, 7, 16, 9, 15, 17, 16, 11, 13, 13, 24, 9, 15, 7, 14, 21, 21, 21, 19],
  卢森堡: [0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 0, 0, 0, 0, 0, 1, 1, 2, 1, 4, 63, 89, 67, 24, 14, 14],
  巴巴多斯: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 1, 4, 6, 8, 3, 9, 15, 22, 18, 10, 4, 13],
  挪威: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 1, 3, 5, 5, 4, 3, 3, 2, 4, 3, 2, 1, 1, 11, 10, 9, 10, 13, 15],
  沙特阿拉伯: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 0, 0, 0, 0, 0, 0, 0, 2, 9, 13, 17, 16, 16, 30],
  丹麦: [0, 1, 0, 0, 3, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 2, 2, 0, 1, 2, 3, 1, 0, 4, 1, 5, 4, 4, 6, 10, 4, 4, 6, 5, 4, 2, 7, 8, 10],
  '不列颠岛(英)': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 3, 1, 2, 2, 4, 4, 4, 4, 5, 11, 5, 3, 7, 5, 12, 7, 12],
  马来西亚: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 1, 4, 4, 7, 5, 1, 1, 3, 2, 1, 5, 3, 10, 8, 6, 8, 3],
  印度: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 1, 1, 1, 0, 1, 2, 2, 2, 0, 1, 2, 5, 10, 2, 4, 10, 10, 5, 9],
  西班牙: [0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 2, 0, 1, 0, 0, 0, 1, 0, 0, 1, 0, 1, 0, 0, 0, 2, 0, 1, 0, 1, 0, 3, 4, 2, 10, 7, 10, 8, 13],
  泰国: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1, 1, 3, 1, 1, 0, 4, 3, 4, 10, 6, 9, 4, 4, 3, 0],
  萨摩亚: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 4, 9, 5, 7, 15],
  菲律宾: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 4, 4, 11, 12, 5],
  俄罗斯: [0, 1, 0, 3, 2, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 1, 0, 1, 2, 0, 0, 0, 2, 0, 0, 1, 2, 1, 0, 0, 0, 0, 1, 1, 3, 3, 4, 0, 1, 0, 2, 2, 0, 2],
  南非: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 1, 1, 2, 5, 4, 5, 3, 1, 2],
  毛里求斯: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 1, 4, 8, 5, 2, 0, 0, 0, 0, 0, 0, 0],
  马耳他: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 2, 4, 0, 7, 3, 0, 3],
  阿拉伯联合酋长国: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 4, 1, 3, 1, 5, 2],
  埃及: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 2, 1, 3, 1, 3, 4, 2],
  波兰: [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1, 0, 0, 0, 2, 0, 1, 1, 1, 2, 0, 0, 1, 2, 0, 1, 1, 0],
  匈牙利: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 1, 2, 3, 0, 1, 0, 0, 0, 1, 0, 0, 0],
  土尔其: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 1, 5, 1, 3, 3],
  新西兰: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 1, 0, 1, 3, 1, 0, 1, 1, 2],
  文莱: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 1, 4, 1, 2, 0, 1, 1, 0, 0, 0, 0],
  捷克: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 2, 1, 2, 4],
  塞浦路斯: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 2, 1, 0, 1, 2, 0, 0, 0],
  葡萄牙: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 3, 3, 0, 0, 1],
  保加利亚: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 2, 1, 1, 1, 0, 0, 0, 2, 0, 0, 0, 0],
  墨西哥: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 4, 1, 0],
  卡塔尔: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 0],
  爱沙尼亚: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 1, 0, 0, 0, 1, 0],
  立陶宛: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 1, 1, 0],
  巴基斯坦: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2],
  罗马尼亚: [0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0],
  伯利兹: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  巴西: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1],
  委内瑞拉: [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  黎巴嫩: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1],
  乌克兰: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0],
  印度尼西亚: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  科威特: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
  哥伦比亚: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0],
  伊朗: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0],
  摩洛哥: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
}

export {
  xData,
  yData
}
