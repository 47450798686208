<template>
  <div class="chart_28">
    <vue-scroll>
      <div class="info_box" v-for="(item, index) in listData" :key="index">
        <h1 class="" :title="item.title">{{ item.title }}</h1>
        <div class="num_box">
          <span class="num1">{{ item.spread }}</span>
          <span class="num2">{{ item.speed }}</span>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import listData from '../data-source/chart-28'
export default {
  data () {
    return {
    }
  },
  computed: {
    // 列表数据
    listData () {
      return listData
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart_28 {
    width: 580px;
    height: 374px;
    .info_box {
      position: relative;
      width: 100%;
      height: 150px;
      // background: rgba(94, 105, 123, 0.3);
      background-image: url('../pic/chart28/chart_28.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      & > h1 {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 20px;
        color: #FFFFFF;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .num_box {
        & > span {
          position: absolute;
          bottom: 16px;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 28px;
        }
        .num1 {
          color: #FECC51;
          left: 68px;
        }
        .num2 {
          color: #70D881;
          left: 358px;
        }
      }
      &+.info_box {
        margin-top: 10px;
      }
    }
  }
</style>
