import echarts from 'echarts'
import {
  setXaxisDefault,
  setYaxisDefault,
  setGrid
} from '../../echartSetting/axis'

const chart = function (frontDict) {
  var xAxisData = frontDict.xAxisData
  var data1 = []
  var data2 = []
  for (let i = 0; i < xAxisData.length; i++) {
    const value = frontDict.content_data[0][i]
    data1.push(value)
    data2.push((-1 * value))
  }
  frontDict.xAxisData = xAxisData
  const series = [{
    name: 'bar',
    type: 'bar',
    stack: 'one',
    barWidth: 2,
    data: data1,
    itemStyle: {
      color: 'rgba(67, 167, 253, 0.5)'
    }
  }, {
    name: 'bar2',
    type: 'bar',
    stack: 'one',
    data: data2,
    barWidth: 2,
    itemStyle: {
      color: 'rgba(67, 167, 253, 0.5)'
    }
  }]

  const option = {
    legend: {
      // legend: 可设置
      show: false,
      itemHeight: 10,
      itemWidth: 10,
      data: frontDict.legend,
      align: 'right',
      right: '7%',
      top: '5%'
    },
    tooltip: {
      show: true,
      // alwaysShowContent: true,
      hideDelay: 1000,
      backgroundColor: 'rgba(56, 71, 96, 0.7)',
      padding: [12, 70, 12, 10],
      formatter: function (data) {
        const date = echarts.format.formatTime('yyyy年MM月dd日', data.name)
        const value = Math.abs(data.value)
        const res = `<div style="margin-bottom: 12px">${date}</div><div>搜索热度: <span style="color: rgba(254, 204, 81, 1);margin-left: 17px">${value}</span></div>`
        return res
      },
      textStyle: {
        color: 'rgba(255, 255, 255, 1)',
        fontSize: 14,
        fontFamily: 'PingFangMedium'
      }
    },
    xAxis: setXaxisDefault(frontDict),
    yAxis: setYaxisDefault(frontDict),
    grid: setGrid(frontDict),
    series: series
  }
  return option
}

export default chart
