const setXaxisDefault = function (xAxisDict) {
  if (xAxisDict.boundaryGap === undefined) {
    xAxisDict.boundaryGap = true
  }
  const xAxis = [
    {
      type: 'category',
      position: 'bottom',
      //   formatter: '{value}',
      boundaryGap: xAxisDict.boundaryGap,
      axisTick: {
        show: true,
        interval: 0,
        length: xAxisDict.axisTickLength,
        lineStyle: {
          color: xAxisDict.axisTickColor,
          width: xAxisDict.axisTickWidth
        }
      },
      axisLabel: {
        margin: xAxisDict.XlabelToAxis,
        textStyle: {
          fontSize: xAxisDict.axisFontSize,
          color: xAxisDict.axisFontColor
        }
      },
      axisLine: {
        lineStyle: {
          width: xAxisDict.axisLineWidth,
          color: xAxisDict.XAxisLineColor
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          width: xAxisDict.xSplitLineWidth,
          type: xAxisDict.xInlineType,
          color: xAxisDict.xSplitLineColor
        }
      },
      // 设置分隔区域的颜色
      splitArea: {
        show: false,
        areaStyle: {
          color: 'rgba(81, 201, 255, 0.05)',
          opacity: 0.5
        }
      },
      data: xAxisDict.xAxisData
    }
  ]
  return xAxis
}

const setYaxisDefault = function (yAxisDict) {
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const yAxisMaxValue = yAxisDict.yMaxValueList || yAxisDict.y_axis_max_value

  if (yAxisDict.YAxisFontSize === undefined) {
    yAxisDict.YAxisFontSize = yAxisDict.axisFontSize
  }
  if (yAxisDict.splitLineWidth === undefined) {
    yAxisDict.splitLineWidth = yAxisDict.axisLineWidth
  }
  if (yAxisDict.splitLineColor === undefined) {
    yAxisDict.splitLineColor = yAxisDict.axisLineColor
  }
  if (yAxisDict.interval === 0) {
    yAxisDict.interval = 'auto'
  }
  const yAxis = [
    {
      type: 'value',
      name: yAxisDict.yAxisUnit,
      nameTextStyle: {
        fontSize: yAxisDict.YAxisFontSize,
        color: yAxisDict.axisFontColor
      },
      nameGap: 10,
      max: yAxisMaxValue[0],
      interval: yAxisDict.interval,
      // minInterval: 80,
      // splitNumber: yAxisDict.splitNumber,
      axisTick: {
        show: false
      },
      axisLabel: {
        show: yAxisDict.isShowLeftYLabel,
        margin: yAxisDict.YlabelToAxis,
        fontSize: yAxisDict.YAxisFontSize,
        color: yAxisDict.axisFontColor
      },
      axisLine: {
        lineStyle: {
          width: yAxisDict.YAxisLineWidth,
          color: yAxisDict.axisLineColor
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          width: yAxisDict.splitLineWidth,
          type: yAxisDict.inlineType,
          color: yAxisDict.splitLineColor
        }
      }
    },
    {
      type: 'value',
      axisTick: {
        show: false
      },
      show: yAxisDict.isShowRightYAxis,
      // name: secondyYAxisUnit,
      // 垂直方向的距离
      nameGap: 10,
      max: yAxisMaxValue[1],
      // max: 0.6,
      yAxisIndex: 0,
      position: 'right',
      axisLabel: {
        show: yAxisDict.isShowRightYLabel,
        // show: true,
        margin: yAxisDict.YlabelToAxis,
        fontSize: yAxisDict.axisFontSize,
        color: yAxisDict.axisFontColor
      },
      nameTextStyle: {
        fontSize: yAxisDict.YAxisFontSize,
        color: yAxisDict.axisFontColor
      },
      axisLine: {
        lineStyle: {
          width: yAxisDict.YAxisLineWidth,
          color: yAxisDict.axisLineColor
        }
      },
      splitLine: {
        show: false
      }
    }
  ]
  return yAxis
}

const setGrid = function (params) {
  let gridPosition = ['7%', '10%', '7%', '10%']
  if (params.gridPosition !== undefined) {
    gridPosition = params.gridPosition
  }
  const addIndex = 4 - gridPosition.length
  if (addIndex > 0.0) {
    for (let i = 0; i < addIndex; i++) {
      gridPosition.push('7%')
    }
  }
  const grid = {
    left: gridPosition[0],
    top: gridPosition[1],
    right: gridPosition[2],
    bottom: gridPosition[3],
    tooltip: {
      textStyle: {
        fontSize: 10
      }
    },
    containLabel: true
  }
  return grid
}

export { setXaxisDefault, setYaxisDefault, setGrid }
