import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function CalendarEcharts (element, props) {
  // 后端传入的数据，转化为 echarts自身定义的同类型， 不用改动echarts的输入数据类型，主要依靠后端代码处理数据格式
  const allData = props.data[0].all_data
  const echartStyle = props.data[0].echart_style
  const startYear = props.data[0].start_year

  // 构造年份
  const yearOne = startYear + '-01-01'
  const yearTwo = startYear + '-06-30'
  const yearThree = startYear + '-07-01'
  const yearFour = startYear + '-12-31'

  // top n
  const res = 'Top' + String(echartStyle.top_num)

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const myChart = echarts.init(element.children[0])

  const option = {
    // backgroundColor: '#404a59',

    title: {
      top: 30,
      text: echartStyle.title,
      subtext: echartStyle.subtitle,
      left: 'center',
      textStyle: {
        color: '#fff'
      }
    },
    tooltip: {
      trigger: 'item',
      triggerOn: trigger,
      hideDelay: hideDelayTime
    },
    legend: {
      top: '30',
      left: '70',
      data: [echartStyle.legend, res],
      textStyle: {
        color: '#fff'
      }
    },
    calendar: [{
      top: '20%',
      width: '80%',
      height: '30%',
      left: 'center',
      // start ,end
      range: [yearOne, yearTwo],
      splitLine: {
        show: true,
        lineStyle: {
          color: '#000',
          width: 2.5,
          type: 'solid'
        }
      },
      yearLabel: {
        formatter: '{start}  1st',
        textStyle: {
          color: '#fff'
        }
      },
      monthLabel: {
        textStyle: {
          color: '#fff'
        }
      },
      dayLabel: {
        textStyle: {
          color: '#fff'
        }
      },
      itemStyle: {
        normal: {
          color: '#323c48',
          // cell_padding
          borderWidth: echartStyle.cell_padding,
          borderColor: '#111'
        }
      }
    }, {
      // 6-12月份
      top: '55%',
      width: '80%',
      height: '30%',
      left: 'center',
      range: [yearThree, yearFour],
      splitLine: {
        show: true,
        lineStyle: {
          color: '#000',
          width: 2.5,
          type: 'solid'
        }
      },
      yearLabel: {
        formatter: '{start}  2nd',
        textStyle: {
          color: '#fff'
        }
      },
      monthLabel: {
        textStyle: {
          color: '#fff'
        }
      },
      dayLabel: {
        textStyle: {
          color: '#fff'
        }
      },
      itemStyle: {
        normal: {
          color: '#323c48',
          borderWidth: echartStyle.cell_padding,
          borderColor: '#111'
        }
      }
    }],
    series: [
      {
        name: echartStyle.legend,
        type: 'scatter',
        coordinateSystem: 'calendar',
        data: allData,
        symbolSize: function (val) {
          return val[1] / echartStyle.max_value * 15
        },
        itemStyle: {
          normal: {
            color: '#ddb926'
          }
        },
        animationDuration: 2000
      },
      {
        name: echartStyle.legend,
        type: 'scatter',
        coordinateSystem: 'calendar',
        calendarIndex: 1,
        data: allData,
        symbolSize: function (val) {
          return val[1] / echartStyle.max_value * 15
        },
        itemStyle: {
          normal: {
            color: '#ddb926'
          }
        },
        animationDuration: 2000
      },
      {
        name: res,
        type: 'effectScatter',
        coordinateSystem: 'calendar',
        calendarIndex: 1,
        data: allData.sort(function (a, b) {
          return b[1] - a[1]
        }).slice(0, echartStyle.top_num),
        symbolSize: function (val) {
          return val[1] / echartStyle.max_value * 15
        },
        showEffectOn: 'render',
        rippleEffect: {
          brushType: 'stroke'
        },
        hoverAnimation: true,
        itemStyle: {
          normal: {
            color: '#f4e925',
            shadowBlur: 10,
            shadowColor: '#333'
          }
        },
        zlevel: 1
      },
      {
        name: res,
        type: 'effectScatter',
        coordinateSystem: 'calendar',
        data: allData.sort(function (a, b) {
          return b[1] - a[1]
        }).slice(0, echartStyle.top_num),
        symbolSize: function (val) {
          return val[1] / echartStyle.max_value * 15
        },
        showEffectOn: 'render',
        rippleEffect: {
          brushType: 'stroke'
        },
        hoverAnimation: true,
        itemStyle: {
          normal: {
            color: '#f4e925',
            shadowBlur: 10,
            shadowColor: '#333'
          }
        },
        zlevel: 1
      }
    ]
  }

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  var startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}
export default CalendarEcharts
