import echarts from 'echarts'
import 'echarts-gl' // bar3d 图需要引入该文件
import allChart from './all'

import './globe.css'

async function GlobeLayers (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  // 初始化
  const myChart = echarts.init(element.children[0])

  const frontDict = props.data[0]
  const option = allChart(frontDict.barStandardSelect)(frontDict)

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
}

export default GlobeLayers // 修改为Classname
