<template>
  <div class="medical_chart4">
    <div class="chart_tabs">
      <div
        :class="['chart_tabs_item', { 'active':item.value === curActiveHeaderTabName }]"
        v-for="item in headerTabList"
        :key="item.value"
        @click="changeHeaderTabName(item.value)">
        <p>{{ item.label }}</p>
      </div>
    </div>
    <div class="chart_tabpanel">
      <div class="left_box">
        <vue-scroll>
          <div class="left_tabs">
            <div
              :class="['left_tabs_item', { 'active':curActiveLeftTabName === item.date }]"
              v-for="item in curActiveTabData"
              :key="item.date"
              @click="curActiveLeftTabName = item.date">
              {{ item.date }}
            </div>
          </div>
        </vue-scroll>
      </div>
      <div class="right_box">
        <vue-scroll>
          <div class="todo" v-if="curActiveHeaderTabName === 'todo'">
            <div class="time_line_item" v-for="item in curRightData" :key="item.time">
              <div class="item_header">
                <span class="header_date">{{ item.time_str }}</span>
                <div class="header_line"></div>
                <span class="header_status">{{ item.status }}</span>
              </div>
              <div class="room">
                {{ item.room }}-{{ item.doctor }}
              </div>
              <p>{{ item.project }}</p>
            </div>
          </div>
          <div class="log" v-else>
            <div class="time_line_item" v-for="item in curRightData" :key="item.time">
              <div class="item_header">
                <span class="header_date">{{ item.project }}</span>
                <div class="header_line"></div>
              </div>
              <p>{{ item.description }}</p>
              <!-- <img :src="item.images" alt="检查图片"> -->
              <el-image
                style="width: 210px;"
                :src="item.images"
                :preview-src-list="[item.images]"
                :z-index="10000">
              </el-image>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import s4Data from '../data-source/medical.sheet4.json'
export default {
  data () {
    return {
      // 当前激活的headerTab
      curActiveHeaderTabName: 'todo',
      // 当前激活的左侧tab
      curActiveLeftTabName: ''
    }
  },
  computed: {
    // 顶部tab列表
    headerTabList () {
      return s4Data.map(item => {
        return {
          value: item.value,
          label: item.label
        }
      })
    },
    // 当前激活的tabData
    curActiveTabData () {
      return s4Data.find(item => {
        return item.value === this.curActiveHeaderTabName
      }).data
    },
    // 当前右侧的数据
    curRightData () {
      const curData = this.curActiveTabData.find(item => {
        return item.date === this.curActiveLeftTabName
      })
      return curData ? curData.data : []
    }
  },
  created () {
    // 取第一项
    this.curActiveLeftTabName = this.curActiveTabData[0].date
  },
  methods: {
    // 切换顶部的tab
    changeHeaderTabName (tabName) {
      this.curActiveHeaderTabName = tabName
      // 取第一项
      this.curActiveLeftTabName = this.curActiveTabData[0].date
    }
  }
}
</script>

<style lang="scss" scoped>
  .medical_chart4 {
    position: relative;
    .chart_tabs {
      display: flex;
      margin-bottom: 8px;
      .chart_tabs_item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 30px;
        background-color: rgba(255, 255, 255, 0.2);
        background: linear-gradient(-45deg, transparent 8px,rgba(255, 255, 255, 0.2) 0);
        cursor: pointer;
        &.active {
          background: linear-gradient(-45deg, transparent 8px,rgba(0, 158, 228, 0.95) 0);
        }
        & > p {
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
          color: #FFFFFF;
        }
        &+.chart_tabs_item {
          margin-left: 10px;
        }
      }
    }
    .chart_tabpanel {
      display: flex;
      width: 100%;
      height: 226px;
      .left_box {
        width: 150px;
        min-width: 150px;
        height: 226px;
        margin-right: 4px;
        .left_tabs {
          width: 100%;
          padding-left: 10px;
          .left_tabs_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 140px;
            height: 30px;
            background-color: rgba(219, 246, 255, 0.1);
            padding: 0 16px 0 10px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.8);
            cursor: pointer;
            &::before {
              content: "";
              display: block;
              width: 4px;
              height: 4px;
              background: #009EE4;
              border-radius: 50%;
            }
            &.active {
              font-weight: bold;
              color: #ffffff;;
              background-color: rgba(0, 158, 228, 1);
              &::before {
                background-color: #ffffff;
              }
            }
            &+.left_tabs_item {
              margin-top: 4px;
            }
          }
        }
      }
      .right_box {
        width: 402px;
        height: 226px;
        background-color: rgba(219, 246, 255, 0.1);
        .todo {
          padding: 16px 37px 16px 20px;
          .time_line_item {
            .item_header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              height: 17px;
              margin-bottom: 10px;
              .header_date {
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                color: rgba(255, 255, 255, 0.6);
              }
              .header_line {
                width: 240px;
                height: 1px;
                background-color: rgba(255, 255, 255, 0.3);
              }
              .header_status {
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                color: #7DFFA0;
              }
            }
            .room {
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
              color: #7DE0FF;
            }
            & > p {
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
              color: #FFFFFF;
            }
            &+.time_line_item {
              margin-top: 16px;
            }
          }
        }
        .log {
          padding: 16px 37px 16px 20px;
          .time_line_item {
            .item_header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              height: 17px;
              margin-bottom: 10px;
              .header_date {
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                color: #7DFFA0;
                margin-right: 10px;
              }
              .header_line {
                // width: 280px;
                flex: 1;
                height: 1px;
                background-color: rgba(255, 255, 255, 0.3);
              }
            }
            & > p {
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
              color: #FFFFFF;
              margin-bottom: 14px;
            }
            & > img {
              width: 210px;
              cursor: pointer;
            }
            &+.time_line_item {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  .medical_chart4 {
    .el-image-viewer__wrapper {
      .el-icon-circle-close {
        color: #ffffff;
      }
    }
  }
</style>
