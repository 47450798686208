
function html2 (targetDom, parmas) {
  // 整个图表的div
  const scrollDom = document.createElement('div')
  scrollDom.setAttribute('style', 'height: 100%; overflow: hidden')
  scrollDom.setAttribute('class', 'htmlChart1-html2-scroll')
  const originDiv = document.createElement('div')
  originDiv.setAttribute(
    'style',
    'height:' + parmas.parentHeight + 'px; width: ' + parmas.parentWidth +
     'px; color:rgba(255, 255, 255, 1); font-size: 14px; line-height: 26px; ' +
     'letter-spacing: 1.4; font-family: PingFangMedium; margin: 10px 10px;'
  )
  originDiv.setAttribute('class', 'htmlChart3-html3-table')
  scrollDom.appendChild(originDiv)
  targetDom.appendChild(scrollDom)
  const data = [
    '消毒水',
    '口罩',
    '方便面',
    '洗发水',
    '薯片',
    '卫生纸',
    '饼干',
    '方便面',
    '鱼干',
    '火腿肠',
    '面条',
    '可口可乐',
    '百事可乐',
    '包子',
    '咖啡'
  ]
  let removeNum = 0
  while (true) {
    for (let i = 0; i < data.length; i++) {
      if (Math.random() < 0.4) {
        data.splice(i, 1)
        removeNum = removeNum + 1
      }
      if (removeNum > 5) {
        const textString = data.join(', ')
        originDiv.innerHTML = textString
        return originDiv
      }
    }
  }
}

export default html2
