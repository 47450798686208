import chart1 from './chart-1'
import chart2 from './chart-2'
import chart3 from './chart-3'
import chart4 from './chart-4'
import chart5 from './chart-5'
import chart6 from './chart-6'
import chart7 from './chart-7'

const allChart = function (name) {
  const chartMap = {
    // 中国地图 旧版本
    chart1,
    // 中国 -- 舆情项目小黄车
    chart2,
    // 中国 -- 中东呼吸综合症
    chart3,
    // 山东问题疫苗事件
    chart4,
    // 昆明火车站暴力恐怖案
    chart5,
    // 长春疫苗事件
    chart6,
    // 小黄车退押金事件
    chart7
  }

  let target = chartMap[name]
  if (target === undefined) {
    target = chart1
  }

  return target
}

export default allChart
