import { numFormatter } from '../chartCommonPart'
var chart2 = function (data, colWidth, params) {
  const firstRowHeight = params.numberFontSize + 6
  let num = data[0].value
  const tailNum = params.tailZeroNum
  num = numFormatter(parseInt(Math.pow(10, tailNum) * (Math.random() + 0.3)))

  var parentDom = document.createElement('div')
  parentDom.setAttribute('style', 'display: flex; text-align: center; height: 100%; flex-direction: column; margin: 10px 10px;')
  parentDom.innerHTML = `<div style="display: flex; text-align: center; height: 34px">
  <div style="display: flex; justify-content: flex-start;align-items: center;  width: 100%; font-family: SonyEricssonLogo">
    <span class="target-number" style="font-size: ${params.numberFontSize}px ;
     color: ${params.numberColor};letter-spacing: ${params.letterSpacing}px; line-height: ${firstRowHeight}}px;
     text-shadow: 10px 4px 1px ${params.textShadowColor};"
          id="number-0">${num}</span>
  </div>
  </div>`

  return parentDom
}
export default chart2
