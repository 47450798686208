<!-- 高影响力微博 -->
<template>
  <div class="chart_22">
    <vue-scroll>
      <div class="scroll_content" :style="chartBox">
        <div
          class="info_item"
          v-for="(item, index) in newList"
          :key="index">
          <div class="item_header">
            <div class="title_box">
              <div class="txt_box">
                {{ item.username }}
              </div>
            </div>
            <div class="data_box">
              <div class="bg" :style="{width: item.width}"></div>
              <span class="data_txt">{{ item.number }}</span>
            </div>
          </div>
          <div class="item_main">
            <p class="">
              {{ item.content }}
            </p>
            <div class="popover_box">
              <p>{{ item.content }}</p>
            </div>
          </div>
          <div class="right_border"></div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import { influenceData, influenceMax } from '../data-source/chart-22'
export default {
  data () {
    return {
    }
  },
  computed: {
    newList () {
      let targetName = this.frontDict.legend[0]
      let targetList = influenceData[targetName]
      let maxValue = influenceMax[targetName]
      if (targetList === undefined) {
        targetName = '山东'
        targetList = influenceData[targetName]
        maxValue = influenceMax[targetName]
      }
      console.log(targetList)
      targetList.forEach(item => {
        // 假设最大值为2万
        item.width = `${parseInt((item.number / maxValue) * 156)}px`
      })
      return targetList
    },
    // 图表宽高
    chartBox () {
      const style = {
        height: this.frontDict.parentHeight + 'px'
      }
      return style
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart_22 {
    .scroll_content {
      width: 382px;
      padding-right: 10px;
      .info_item {
        position: relative;
        width: 100%;
        height: 82px;
        background-size: 100%;
        background-repeat: no-repeat;
        padding-right: 10px;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 6px;
          height: 14px;
          background: rgba(67, 167, 253, 0.5);
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 18px;
          right: 0;
          width: 6px;
          height: 14px;
          border: 1px solid rgba(67, 167, 253, 0.5);
        }
        .right_border {
          position: absolute;
          bottom: 0px;
          right: 0;
          width: 6px;
          height: 46px;
          border:1px solid rgba(67, 167, 253, 0.5);
        }
        .item_header {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 26px;
          margin-bottom: 2px;
          .title_box {
            width: 120px;
            height: 100%;
            padding: 2px 4px;
            background-color: rgba(67, 167, 253, 0.3);
            .txt_box {
              display: flex;
              align-items: center;
              width: 112px;
              height: 22px;
              background: rgba(67, 167, 253, 0.3);
              padding-left: 4px;
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
          .data_box {
            position: relative;
            display: flex;
            justify-content: flex-end;
            width: 240px;
            height: 100%;
            background-color: rgba(94, 105, 123, 0.3);
            border-right: 2px solid rgba(67, 167, 253, 0.5);
            border-left: 2px solid rgba(67, 167, 253, 0.5);
            border-top: 1px solid rgba(67, 167, 253, 1);
            .bg {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              background-image: url('../pic/N_line.png');
            }
            .data_txt {
              font-size: 16px;
              font-family: DIN;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
        }
        .item_main {
          position: relative;
          width: 100%;
          height: 54px;
          padding: 5px 10px;
          background-color: rgba(94, 105, 123, 0.3);
          & > p {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 20px;
            color: #FFFFFF;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2
          }
          .popover_box {
            display: none;
            position: absolute;
            top: 16px;
            left: 72px;
            width: 300px;
            background: #384760;
            padding: 10px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 20px;
            color: #FFFFFF;
            z-index: 10;
          }
          &:hover {
            .popover_box {
              display: block;
            }
          }
        }
      }
    }
  }
</style>
