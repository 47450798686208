const allData = [
  {
    content:
        '国务院关于印发新时期促进集成电路产业和软件产业高质量发展若干政策的通知',
    date1: '2020.07.27发布',
    date2: '2020.07.27实施',
    type: '中央'
  },
  {
    content: '财政部、税务总局关于集成电路设计和软件产业企业所得税政策的公告',
    date1: '2019.05.17发布',
    date2: '2019.05.17实施',
    type: '中央'
  },
  {
    content:
        '国家知识产权局关于印发《集成电路布图设计审查与执法指南(试行)》的通知',
    date1: '2019.04.08发布',
    date2: '2019.04.08实施',
    type: '中央'
  },
  {
    content:
        '国家发展改革委、教育部、科技部等关于印发《半导体照明产业“十三五”发展规划》的通知',
    date1: '2017.07.10发布',
    date2: '2017.07.10实施',
    type: '中央'
  },
  {
    content: '战略性新兴产业重点产品和服务指导目录（2016版）',
    date1: '2017.01.25发布',
    date2: '2017.01.25实施',
    type: '中央'
  },
  {
    content: '国务院关于印发“十三五”国家信息化规划的通知',
    date1: '2016.12.15发布',
    date2: '2016.12.15实施',
    type: '中央'
  },
  {
    content: '国务院关于印发“十三五”国家战略性新兴产业发展规划的通知',
    date1: '2016.11.29发布',
    date2: '2016.11.29实施',
    type: '中央'
  },
  {
    content: '国家信息化发展战略纲要',
    date1: '2016.07发布',
    date2: '2016.07实施',
    type: '中央'
  },
  {
    content: '中国制造2025',
    date1: '2015.05.19发布',
    date2: '2015.05.19实施',
    type: '中央'
  },
  {
    content: '国家集成电路产业发展推进纲要',
    date1: '2014.06.24发布',
    date2: '2014.06.24实施',
    type: '中央'
  },
  {
    content:
        '广东省人民政府关于培育发展战略性支柱产业集群和战略性新兴产业集群的意见',
    date1: '2020.05.18发布',
    date2: '2020.05.18实施',
    type: '地方'
  },
  {
    content:
        '长沙市工业和信息化局、长沙市财政局关于印发《长沙市关于加快新一代半导体和集成电路产业发展的若干政策实施细则》的通知',
    date1: '2020.04.02发布',
    date2: '2020.04.02实施',
    type: '地方'
  },
  {
    content: '广东省加快半导体及集成电路产业发展的若干意见',
    date1: '2020.02.03发布',
    date2: '2020.02.03实施',
    type: '地方'
  },
  {
    content:
        '成都市人民政府办公厅关于印发支持集成电路设计业加快发展若干政策的通知',
    date1: '2018.11.08发布',
    date2: '2018.12.08实施',
    type: '地方'
  },
  {
    content:
        '重庆市人民政府办公厅关于印发重庆市加快集成电路产业发展若干政策的通知',
    date1: '2018.08.22发布',
    date2: '2018.08.22实施',
    type: '地方'
  },
  {
    content:
        '杭州市人民政府办公厅关于印发进一步鼓励集成电路产业加快发展专项政策的通知',
    date1: '2018.07.11发布',
    date2: '2018.08.12实施',
    type: '地方'
  },
  {
    content:
        '中共无锡市委、无锡市人民政府关于进一步支持集成电路产业发展的政策意见',
    date1: '2018.02.05发布',
    date2: '2018.01.01实施',
    type: '地方'
  },
  {
    content: '厦门市人民政府办公厅关于印发加快发展集成电路产业实施细则的通知',
    date1: '2018.04.10发布',
    date2: '2018.04.10实施',
    type: '地方'
  },
  {
    content:
        '上海市人民政府印发《关于本市进一步鼓励软件产业和集成电路产业发展的若干政策》的通知',
    date1: '2017.04.17发布',
    date2: '2017.04.15实施',
    type: '地方'
  },
  {
    content:
        '苏州市人民政府印发关于推进软件和集成电路产业发展的若干政策的通知',
    date1: '2016.03.07发布',
    date2: '2016.03.07实施',
    type: '地方'
  },
  {
    content:
        '合肥市人民政府办公厅关于印发合肥市促进集成电路产业发展政策的通知',
    date1: '2014.08.23发布',
    date2: '2014.01.01实施',
    type: '地方'
  }
]

export default allData
