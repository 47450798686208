var chart = function (data, colWidth, params) {
  const firstRowHeight = params.numberFontSize + 6
  const num = data[0].value
  // 前缀符号, 后缀符号 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const unit = params.xAxisUnit || params.x_axis_unit
  // let tailNum = params.tailZeroNum
  // num = numFormatter(parseInt(Math.pow(10, tailNum) * (Math.random() + 0.3)))

  var parentDom = document.createElement('div')
  parentDom.setAttribute('style', 'display: flex; text-align: center; height: 100%; flex-direction: column; margin: 10px 10px;')
  parentDom.innerHTML = `<div style="display: flex; text-align: center; height: 34px">
  <div style="display: flex; justify-content: flex-start;align-items: center;  width: 100%; font-family: SonyEricssonLogo">
    <span class="target-number" style="font-size: ${params.numberFontSize}px ;  font-family: ${params.digtalfontFamily};
     color: ${params.numberColor};letter-spacing: ${params.letterSpacing}px; line-height: ${firstRowHeight}}px;
     text-shadow: 10px 4px 1px ${params.textShadowColor};"
          id="number-0">${num}</span>
    <span class="target-number" style="font-size: ${params.numberFontSize}px ;  font-family: ${params.digtalfontFamily};
    color: ${params.numberColor};letter-spacing: ${params.letterSpacing}px; line-height: ${firstRowHeight}}px;
    text-shadow: 10px 4px 1px ${params.textShadowColor};"
          id="number-0">${unit}</span>
  </div>
  </div>`

  return parentDom
}
export default chart
