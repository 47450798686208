import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function BubbleTimeline (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const myChart = echarts.init(element.children[0])
  // eushow 3.0
  // const myChart = echarts.init(div[0][0].children[0], props.colorTheme)

  const AllData = props.data[0].AllData
  // const title = props.data[0].title
  const legend = props.data[0].legend
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const XAxisUnit = props.data[0].XAxisUnit || props.data[0].xAxisUnit || props.data[0].x_axis_unit
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  // const YAxisUnit = props.data[0].YAxisUnit
  const YAxisUnit = props.data[0].YAxisUnit || props.data[0].yAxisUnit || props.data[0].y_axis_unit
  const XRange = props.data[0].XRange
  // const YRange = props.data[0].YRange

  var itemStyle = {
    normal: {
      opacity: 0.8,
      shadowBlur: 10,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowColor: 'rgba(0, 0, 0, 0.5)'
    }
  }

  var sizeFunction = function (x) {
    var y = Math.sqrt(x / 5e8) + 0.1
    return y * 80
  }
  // Schema:
  var schema = [
    { name: 'first', index: 0, text: legend[0], unit: XAxisUnit },
    { name: 'second', index: 1, text: legend[1], unit: YAxisUnit },
    { name: 'three', index: 2, text: legend[2], unit: '' },
    { name: 'four', index: 3, text: legend[3], unit: '' }
  ]

  const option = {
    baseOption: {
      timeline: {
        axisType: 'category',
        orient: 'vertical',
        autoPlay: true,
        inverse: true,
        playInterval: 1000,
        left: null,
        right: 0,
        top: 20,
        bottom: 20,
        width: 55,
        height: null,
        label: {
          normal: {
            textStyle: {
              color: '#999'
            }
          },
          emphasis: {
            textStyle: {
              color: '#fff'
            }
          }
        },
        symbol: 'none',
        lineStyle: {
          color: '#555'
        },
        checkpointStyle: {
          color: '#bbb',
          borderColor: '#777',
          borderWidth: 2
        },
        controlStyle: {
          showNextBtn: false,
          showPrevBtn: false,
          normal: {
            color: '#666',
            borderColor: '#666'
          },
          emphasis: {
            color: '#aaa',
            borderColor: '#aaa'
          }
        },
        data: []
      },
      // backgroundColor: '#404a59',
      title: [{
        text: AllData.timeline[0],
        textAlign: 'center',
        left: '80%',
        top: '10%',
        textStyle: {
          fontSize: 60,
          color: 'rgba(255, 255, 255, 0.7)'
        }
      }, {
        // text: title,
        left: 'center',
        top: 10,
        textStyle: {
          color: '#aaa',
          fontWeight: 'normal',
          fontSize: 20
        }
      }],
      tooltip: {
        padding: 5,
        backgroundColor: '#222',
        triggerOn: trigger,
        hideDelay: hideDelayTime,
        borderColor: '#777',
        borderWidth: 1,
        formatter: function (obj) {
          var value = obj.value
          return schema[3].text + '：' + value[3] + '<br>' +
                            schema[1].text + '：' + value[1] + schema[1].unit + '<br>' +
                            schema[0].text + '：' + value[0] + schema[0].unit + '<br>' +
                            schema[2].text + '：' + value[2] + '<br>'
        }
      },
      grid: {
        top: '7%',
        containLabel: true,
        left: '7%',
        bottom: '5%',
        right: '80'
      },
      xAxis: {
        type: 'log',
        name: legend[0],
        max: XRange[1],
        min: 10,
        // scale: true,
        splitNumber: 7,
        nameGap: 25,
        nameLocation: 'middle',
        nameTextStyle: {
          fontSize: 18
        },
        splitLine: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: '#ccc'
          }
        },
        axisLabel: {
          formatter: '{value} ' + XAxisUnit
        },
        bottom: '2%'
      },
      yAxis: {
        type: 'value',
        name: legend[1],
        // max: YRange[1],
        nameTextStyle: {
          color: '#ccc',
          fontSize: 18
        },
        scale: true,
        axisLine: {
          lineStyle: {
            color: '#ccc'
          }
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          formatter: '{value} ' + YAxisUnit
        },
        top: '2%'
      },
      // 控制颜色范围
      visualMap: {
        show: false,
        min: XRange[0],
        max: XRange[1],
        dimension: 1,
        orient: 'vertical',
        right: 10,
        top: 'center',
        text: ['HIGH', 'LOW'],
        calculable: true,
        inRange: {
          // 颜色很好
          color: ['#f2c31a', '#24b7f2']
        }
      },
      series: [
        {
          type: 'scatter',
          itemStyle: itemStyle,
          data: AllData.series[0],
          symbolSize: function (val) {
            return sizeFunction(val[2])
          }
        }
      ],
      animationDuration: 2000,
      animationDurationUpdate: 1000,
      animationEasingUpdate: 'quinticInOut'
    },
    options: []
  }

  for (var n = 0; n < AllData.timeline.length; n++) {
    option.baseOption.timeline.data.push(AllData.timeline[n])
    option.options.push({
      title: {
        show: true,
        text: AllData.timeline[n] + ''
      },
      series: {
        name: AllData.timeline[n],
        type: 'scatter',
        itemStyle: itemStyle,
        data: AllData.series[n],
        symbolSize: function (val) {
          return sizeFunction(val[2])
        }
      }
    })
  }

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  var startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default BubbleTimeline // 修改为Classname
