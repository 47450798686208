import { setXaxisDefault, setYaxisDefault } from '../../echartSetting/axis'
// import {numFormatter} from '../chartCommonPart'
const chart2 = function (params) {
  const originData = params.content_data[0]
  const barWidth = params.barWidth
  const axisFontSize = params.axisFontSize
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const yAxisUnit = params.yAxisUnit || params.y_axis_unit

  const xAxisBase = setXaxisDefault(params)

  const option = {
    backgroundColor: params.backgroundColor,
    xAxis: xAxisBase,
    yAxis: setYaxisDefault(params),
    series: [
      {
        // 外部的单线框
        name: '外框',
        type: 'bar',
        // barGap: '-200%', // 设置外框间距
        data: originData,
        // 设置外边框的宽度
        barWidth: barWidth,
        itemStyle: {
          normal: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 1, color: params.gradientColorStart // 0% 处的颜色
              }, {
                offset: 0, color: params.gradientColorEnd // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }, // 填充色
            label: {
              // 标签显示位置
              show: true,
              position: 'top', // insideTop 或者横向的 insideLeft,
              distance: 4,
              formatter: function (params) {
                const value = params.data
                // console.log(name)
                // value = numFormatter(value)
                return `{a|${value}} {b|${yAxisUnit}}`
              },
              rich: {
                a: {
                  fontSize: params.barLabelFontSize,
                  color: params.barLabelColor,
                  fontFamily: 'SonyEricssonLogo'
                },
                c: {
                  fontSize: params.barLabelFontSize,
                  color: 'rgba(68, 197, 255, 1)',
                  fontFamily: 'SonyEricssonLogo'
                },
                b: {
                  fontSize: axisFontSize,
                  color: 'rgba(255, 255, 255, 0.61)',
                  fontFamily: 'PingFangRegular',
                  marginLeft: 4,
                  verticalAlign: 'center'
                }
              }
            }
          }
        }
      },
      {
        name: 'pictorialBar',
        type: 'pictorialBar',
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolPosition: 'end',
        symbol: params.pictorialBarType,
        symbolSize: [params.pictorialBarWidth, params.pictorialBarHeight],
        symbolOffset: [params.pictorialBarOffsetX, params.pictorialBarOffsetY],
        tooltip: {
          show: false
        },
        itemStyle: {
          normal: {
            color: params.pictorialBarColor
          }
        },
        data: originData
      }
    ]
  }
  return option
}

export default chart2
