const allData = {
  个人: {
    timeline: [
      '谴责长春长生',
      '造假疫苗去向拷问',
      '黑客攻击长生生物官网首页'
    ],
    table: [
      {
        name: '刘璇',
        influence: 78848
      }, {
        name: '一个有点理想的记者',
        influence: 44496
      },
      {
        name: '法师哥',
        influence: 43349
      }, {
        name: '傅蔚冈',
        influence: 24684
      },
      {
        name: '樊建川',
        influence: 20044
      }, {
        name: '假装奋青',
        influence: 15893
      },
      {
        name: '胡锡进',
        influence: 12012
      }, {
        name: '闪电站小猪',
        influence: 10910
      },
      {
        name: 'Benny董子初',
        influence: 9031
      }, {
        name: '江宁婆婆',
        influence: 7961
      },
      {
        name: '有限次重复博弈',
        influence: 5481
      }, {
        name: '王照宇Schnappi',
        influence: 5415
      },
      {
        name: '黄胜友',
        influence: 5207
      }, {
        name: '陈小兜律师',
        influence: 4779
      },
      {
        name: '黑画笔Heihuabi',
        influence: 4215
      }, {
        name: '八倍哥',
        influence: 3945
      },
      {
        name: '狂野男孩儿_',
        influence: 3464
      }, {
        name: '北京人不知道的北京事儿',
        influence: 3441
      },
      {
        name: '金融段子王',
        influence: 3389
      }, {
        name: '谢楠',
        influence: 3225
      }
    ]
  },
  企业: {
    timeline: [
      '长生长春公司就造假疫苗致歉',
      '百白破生产车间停产整改',
      '长生生物股票跌停临时停牌，存在退市风险',
      '武汉生物疫苗造假；疫苗事件涉及行贿',
      '医药板块股票集体下跌'
    ],
    table: [
      {
        name: '头条新闻',
        influence: 364847
      }, {
        name: '中新经纬',
        influence: 250451
      },
      {
        name: '新浪财经',
        influence: 207090
      }, {
        name: '微博热点',
        influence: 180227
      },
      {
        name: '财经网',
        influence: 134616
      }, {
        name: '搜狐视频',
        influence: 41744
      },
      {
        name: '新浪视频',
        influence: 30794
      }, {
        name: '商界BUSINESS',
        influence: 15817
      },
      {
        name: '创业邦杂志',
        influence: 12926
      }, {
        name: '新浪新闻',
        influence: 6495
      },
      {
        name: '南方都市报',
        influence: 6168
      }, {
        name: '扬子晚报',
        influence: 6095
      },
      {
        name: '全景网',
        influence: 5322
      }, {
        name: '新浪山东',
        influence: 4978
      },
      {
        name: '凤凰周刊',
        influence: 4840
      }, {
        name: '京蜜',
        influence: 4335
      },
      {
        name: '新浪证券',
        influence: 4190
      }, {
        name: '北京青年报',
        influence: 3745
      },
      {
        name: '今晚报',
        influence: 3565
      }, {
        name: '千里眼短视频',
        influence: 3549
      }
    ]
  },
  政府: {
    timeline: [
      '国家药监局立案调查',
      '国家药监局彻查疫苗造假案',
      '国家药监局通报疫苗案件情况',
      '公安机关立案调查；董事长被刑事拘留',
      '国务院调查组开展疫苗调查处理工作',
      '国务院调查组开展疫苗调查处理工作',
      '公安机关逮捕犯罪嫌疑人',
      '山东省疾控中心官员自杀',
      '证监会修改上市公司退市制度',
      '山东疾控中心查明疫苗流向'
    ],
    table: [
      {
        name: '人民日报',
        influence: 508257
      }, {
        name: '长春公安',
        influence: 160323
      },
      {
        name: '中国经济网',
        influence: 153223
      }, {
        name: '吉林发布',
        influence: 99019
      },
      {
        name: '中国新闻网',
        influence: 93632
      }, {
        name: '央视网',
        influence: 93140
      },
      {
        name: '新华视点',
        influence: 62890
      }, {
        name: '吉林检察',
        influence: 60131
      },
      {
        name: '法制网',
        influence: 32976
      }, {
        name: '央视新闻',
        influence: 29736
      },
      {
        name: '共青团中央',
        influence: 18762
      }, {
        name: '中国政府网',
        influence: 15865
      },
      {
        name: '检察日报',
        influence: 14276
      }, {
        name: '中国日报',
        influence: 9972
      },
      {
        name: '武汉发布',
        influence: 8989
      }, {
        name: '人民日报评论',
        influence: 7177
      },
      {
        name: '中国新闻社',
        influence: 6942
      }, {
        name: '人民网',
        influence: 6016
      },
      {
        name: '中国之声',
        influence: 5938
      }, {
        name: '中国青年报',
        influence: 3364
      }
    ]
  }
}

export {
  allData
}
