import echarts from 'echarts'
import allChart from './all'
import { commonFunc } from '../chartCommonPart'

function barLinePictorialBar (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const myChart = echarts.init(element.children[0], props.colorTheme)
  const frontDict = props.data[0]

  // 如果传入了具体传来的图表类型 则使用具体传入的，否则使用chart1
  if (!frontDict.barStandardSelect) {
    frontDict.barStandardSelect = 'chart1'
  }
  const option = allChart(frontDict.barStandardSelect)(frontDict)

  const startPlay = commonFunc(myChart, option, props)

  return startPlay
}

export default barLinePictorialBar // 修改为Classname
