import {
  hideDelayTime,
  trigger
} from '../chartCommonPart'
import { SUPERSET_URL } from '~/config'
const chart = function (params) {
  const option = {
    backgroundColor: 'rgba(0,0,0,0)',
    tooltip: {
      show: false,
      trigger: 'item',
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      backgroundColor: 'rgba(234,234,234,0)',
      // borderColor: '#FFFFCC',
      showDelay: 0,
      confine: true, // tooltip 不超出图表范围
      enterable: true,
      transitionDuration: 0,
      extraCssText: 'z-index:100',
      formatter: function (params, ticket, callback) {
        // 根据业务自己拓展要显示的内容
        var nameObject = params.name
        // console.log(params);
        // console.log(name_object);
        if (nameObject === '1') {
          // ProvinceName = MapData[name_object].name;
          return '技术合同交易金额' + ': ' + params.value
        }
      }
    },
    geo: {
      map: 'china',
      // left: '500',
      // center:[114.298572, 30.584355],
      label: {
        show: false,
        textStyle: {
          color: '#ff0'
        }
      },
      itemStyle: { // 地图区域的多边形 图形样式
        normal: {
          areaColor: params.areaColor,
          borderWidth: params.lineWidth,
          borderColor: params.lineColor
        }
      },
      emphasis: {
        label: {
          show: false
        },
        itemStyle: {
          areaColor: 'rgba(38, 171, 255, 0.6)',
          borderWidth: params.lineWidth
        }
      },
      roam: false,
      regions: [{
        name: '北京',
        selected: true
      }, {
        name: '吉林',
        selected: true
      }, {
        name: '山东',
        selected: true
      }, {
        name: '云南',
        selected: true
      }]
    },
    series: [{
      type: 'map',
      mapType: 'china',
      roam: false, // 是否开启鼠标缩放和平移漫游
      geoIndex: 0,
      label: {
        // 决定了高亮时是否显示地理名称
        show: false,
        textStyle: {
          color: 'transparent'
        }
      },
      itemStyle: { // 地图区域的多边形 图形样式
        normal: {
          label: {
            show: false
          },
          areaColor: params.areaColor,
          borderWidth: 1,
          borderType: 'solid',
          borderColor: params.lineColor
        },
        emphasis: {
          // 是图形在高亮状态下的样式,比如在鼠标悬浮或者图例联动高亮时
          label: {
            show: false,
            color: '#fff'
          },
          borderWidth: 1,
          borderType: 'solid',
          // borderColor: 'rgba(81, 255, 254)',
          areaColor: 'transparent'
        }
      },
      data: []
    }, {
      name: 'center',
      type: 'scatter',
      coordinateSystem: 'geo',
      hoverAnimation: false,
      symbolSize: function (val) {
        return [val[2], val[3]]
      },
      data: [
        {
          name: '北京',
          label: { show: false },
          symbol: `image://${SUPERSET_URL}/static/assets/images/202011/bike.png`,
          symbolOffset: [-100, 0],
          value: [116.4551, 40.2539, 315, 120]
        },
        {
          name: '云南',
          symbol: `image://${SUPERSET_URL}/static/assets/images/202011/kunming.png`,
          label: { show: false },
          symbolOffset: [95, -15],
          value: [102.73, 25.04, 255, 86]
        },
        {
          name: '山东',
          symbol: `image://${SUPERSET_URL}/static/assets/images/202011/shandong.png`,
          label: { show: false },
          symbolOffset: [125, 0],
          value: [117, 36.65, 282, 60]
        },
        {
          name: '吉林',
          symbol: `image://${SUPERSET_URL}/static/assets/images/202011/changchun.png`,
          label: { show: false },
          // symbolSize: [255, 111],
          symbolOffset: [-100, -25],
          value: [126.57, 43.87, 255, 111]
        }
      ],
      label: {
        normal: {
          formatter: '{b}',
          position: 'bottom',
          color: '#fff',
          show: true
        }
      },
      z: 9,
      animationDuration: 2000
    }]
  }
  return option
}

export default chart
