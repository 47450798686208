const axisData = ['2018年6月', '2018年7月', '2018年8月', '2018年9月',
  '2018年10月', '2018年11月', '2018年12月', '2019年1月', '2019年2月', '2019年3月']

const nameMap = {
  '2018年10月': [{ name: '小黄车退押金周期长', value: 3 },
    { name: '退押金排队（系统）人数多', value: 3 },
    { name: '小黄车退押金套路多，困难重重', value: 24 },
    { name: 'ofo推出押金新政策，押金投资理财项目后可免押金', value: 51 },
    { name: '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款', value: 32 },
    { name: '小黄车推出折扣商城，押金兑换金币购物', value: 12 }],
  '2018年11月': [{ name: '小黄车退押金周期长', value: 136 },
    { name: '退押金排队（系统）人数多', value: 14 },
    { name: '小黄车退押金套路多，困难重重', value: 328 },
    { name: 'ofo推出押金新政策，押金投资理财项目后可免押金', value: 489 },
    { name: '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款', value: 290 },
    { name: '小黄车推出折扣商城，押金兑换金币购物', value: 248 }],
  '2018年12月': [{ name: '小黄车退押金周期长', value: 210 },
    { name: '退押金排队（系统）人数多', value: 685 },
    { name: '小黄车退押金套路多，困难重重', value: 876 },
    { name: 'ofo推出押金新政策，押金投资理财项目后可免押金', value: 813 },
    { name: '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款', value: 989 },
    { name: '小黄车推出折扣商城，押金兑换金币购物', value: 122 }],
  '2018年6月': [{ name: '小黄车退押金周期长', value: 3 },
    { name: '退押金排队（系统）人数多', value: 0 },
    { name: '小黄车退押金套路多，困难重重', value: 69 },
    { name: 'ofo推出押金新政策，押金投资理财项目后可免押金', value: 47 },
    { name: '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款', value: 26 },
    { name: '小黄车推出折扣商城，押金兑换金币购物', value: 72 }],
  '2018年7月': [{ name: '小黄车退押金周期长', value: 1 },
    { name: '退押金排队（系统）人数多', value: 0 },
    { name: '小黄车退押金套路多，困难重重', value: 11 },
    { name: 'ofo推出押金新政策，押金投资理财项目后可免押金', value: 23 },
    { name: '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款', value: 2 },
    { name: '小黄车推出折扣商城，押金兑换金币购物', value: 11 }],
  '2018年8月': [{ name: '小黄车退押金周期长', value: 0 },
    { name: '退押金排队（系统）人数多', value: 0 },
    { name: '小黄车退押金套路多，困难重重', value: 13 },
    { name: 'ofo推出押金新政策，押金投资理财项目后可免押金', value: 14 },
    { name: '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款', value: 5 },
    { name: '小黄车推出折扣商城，押金兑换金币购物', value: 5 }],
  '2018年9月': [{ name: '小黄车退押金周期长', value: 6 },
    { name: '退押金排队（系统）人数多', value: 2 },
    { name: '小黄车退押金套路多，困难重重', value: 30 },
    { name: 'ofo推出押金新政策，押金投资理财项目后可免押金', value: 35 },
    { name: '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款', value: 28 },
    { name: '小黄车推出折扣商城，押金兑换金币购物', value: 15 }],
  '2019年1月': [{ name: '小黄车退押金周期长', value: 161 },
    { name: '退押金排队（系统）人数多', value: 319 },
    { name: '小黄车退押金套路多，困难重重', value: 457 },
    { name: 'ofo推出押金新政策，押金投资理财项目后可免押金', value: 287 },
    { name: '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款', value: 318 },
    { name: '小黄车推出折扣商城，押金兑换金币购物', value: 43 }],
  '2019年2月': [{ name: '小黄车退押金周期长', value: 191 },
    { name: '退押金排队（系统）人数多', value: 175 },
    { name: '小黄车退押金套路多，困难重重', value: 356 },
    { name: 'ofo推出押金新政策，押金投资理财项目后可免押金', value: 172 },
    { name: '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款', value: 192 },
    { name: '小黄车推出折扣商城，押金兑换金币购物', value: 36 }],
  '2019年3月': [{ name: '小黄车退押金周期长', value: 269 },
    { name: '退押金排队（系统）人数多', value: 129 },
    { name: '小黄车退押金套路多，困难重重', value: 492 },
    { name: 'ofo推出押金新政策，押金投资理财项目后可免押金', value: 311 },
    { name: '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款', value: 218 },
    { name: '小黄车推出折扣商城，押金兑换金币购物', value: 754 }]
}

const lineData = [
  [0, 0, 0, 2, 3, 14, 685, 319, 175, 129],
  [3, 1, 6, 3, 136, 210, 161, 191, 269],
  [69, 11, 13, 30, 24, 328, 876, 457, 356, 492],
  [72, 11, 5, 15, 12, 248, 122, 43, 36, 754],
  [47, 23, 14, 35, 51, 489, 813, 287, 172, 311],
  [26, 2, 5, 28, 32, 290, 989, 318, 192, 218]
]
export {
  axisData,
  lineData,
  nameMap
}
