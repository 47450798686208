import { SUPERSET_URL } from '~/config'

import './PicCarouselSecond.css'
import Swiper from 'swiper'

function PicCarouselSecond (element, props) {
  // console.log(props)
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  // eushow 3.0
  // 预留的div  let body = div[0][0].children[0];
  const textData = props.data[0].data
  const contentWidth = props.data[0].parentWidth
  const contentHeight = props.data[0].parentHeight
  const delayTime = props.data[0].delayTime
  const speedTime = props.data[0].speedTime

  // const targetMarginLeft = props.data[0].targetMarginLeft;
  const imgDict = {
    张俐娜: 0,
    丁汉: 1,
    秦顺全: 2,
    叶朝辉: 3,
    李培武: 4,
    马伟明: 5,
    刘经南: 6,
    谢先启: 7,
    钮新强: 8,
    余少华: 9,
    张联盟: 10,
    陈孝平: 11,
    王焰新: 12,
    傅廷栋: 13,
    邓子新: 14,
    邓秀新: 15,
    张祖勋: 16,
    熊有伦: 17,
    樊明武: 18,
    李德群: 19,
    丁烈云: 20,
    龚健雅: 21,
    徐青: 22,
    潘垣: 23,
    姜德生: 24,
    程时杰: 25,
    邵新宇: 26,
    张启发: 27,
    陈焕春: 28,
    张清杰: 29,
    严新平: 30,
    杨春和: 31,
    许厚泽: 32
  }

  const createDom = function (domType, className, text) {
    const oneDom = document.createElement(domType)
    if (className !== '') {
      oneDom.setAttribute('class', className)
    }
    if (text !== '') {
      oneDom.innerHTML = text
    }
    return oneDom
  }

  const createContentList = function (parentDiv, data) {
    const len = data.length
    for (let i = 0; i < len; i++) {
      const targetDict = data[i]
      const titleText = targetDict['姓名']
      console.log(titleText)
      const oneContent = document.createElement('div')
      // oneContent.setAttribute('class', 'pic-li_list');
      oneContent.setAttribute('class', 'swiper-slide')

      // 整体用于布局的div
      const layContent = document.createElement('div')
      layContent.setAttribute('class', 'slide-content-second')
      // layContent.setAttribute('style', 'margin-left:' + targetMarginLeft + 'px')
      oneContent.appendChild(layContent)

      // const domainText = targetDict['技术领域']
      const imgParentDom = createDom('div', 'pic-img-second-dom', '')
      const imgDiv = createDom('img', 'pic-carousel', '')
      // 设置图片的宽高
      imgDiv.setAttribute('style', 'width: 300px; height: 340px;')
      let imgName = imgDict[titleText]
      if (titleText === '丁汉') {
        imgName = 1
      }

      const path = SUPERSET_URL + '/static/assets/images/peopleImg/' + imgName + '.png'
      imgDiv.setAttribute('src', path)
      imgParentDom.appendChild(imgDiv)

      // 第一部分的文本
      const firstText = createDom('div', 'pic-right-body', '')

      // 标题
      const titleDom = createDom('div', 'pic-name', titleText)

      const subTitleText = targetDict['人才简介']
      const subTitleDom = createDom('div', 'pic-sub-body', subTitleText)

      const fourBodyDom = createDom('div', 'pic-four', '')

      // one
      const firstBodyDom = createDom('div', 'pic-four-title', '')
      const firstTitle = createDom('span', 'pic-first-text', '工作单位: ')
      const firstBodyText = targetDict['单位']
      const firstBody = createDom('span', 'pic-first-sub', firstBodyText)
      firstBodyDom.appendChild(firstTitle)
      firstBodyDom.appendChild(firstBody)

      // two
      const secondBodyDom = createDom('div', 'pic-four-title', '')
      const secondTitle = createDom('span', 'pic-first-text', '专业领域: ')
      const secondBodyText = targetDict['专业领域']
      const secondBody = createDom('span', 'pic-first-sub', secondBodyText)
      secondBodyDom.appendChild(secondTitle)
      secondBodyDom.appendChild(secondBody)

      // three
      const thirdBodyDom = createDom('div', 'pic-four-title', '')
      const thirdTitle = createDom('span', 'pic-first-text', '专业类别: ')
      const thirdBodyText = targetDict['专业类别']
      const thirdBody = createDom('span', 'pic-first-sub', thirdBodyText)
      thirdBodyDom.appendChild(thirdTitle)
      thirdBodyDom.appendChild(thirdBody)

      // four
      const fourthBodyDom = createDom('div', 'pic-four-title', '')
      const fourthTitle = createDom('span', 'pic-first-text', '主要成就: ')
      const fourthBodyText = targetDict['主要成就']
      const fourthBody = createDom('span', 'pic-first-sub', fourthBodyText)
      fourthBodyDom.appendChild(fourthTitle)
      fourthBodyDom.appendChild(fourthBody)

      // 添加虚线
      const dashedLine = createDom('div', 'dashed-line', '')
      const seconddashedLine = createDom('div', 'dashed-line', '')

      // 将文本全部放到一个节点内
      firstText.appendChild(titleDom)
      firstText.appendChild(subTitleDom)
      fourBodyDom.appendChild(firstBodyDom)
      fourBodyDom.appendChild(secondBodyDom)
      // 添加虚线
      fourBodyDom.appendChild(dashedLine)
      fourBodyDom.appendChild(thirdBodyDom)
      fourBodyDom.appendChild(fourthBodyDom)
      // 添加虚线
      fourBodyDom.appendChild(seconddashedLine)
      firstText.appendChild(fourBodyDom)

      layContent.appendChild(imgParentDom)
      layContent.appendChild(firstText)

      parentDiv.append(oneContent)
    }
  }

  onload = function () {
    const body = element.children[0]
    body.setAttribute('style', 'width: 100%;height: 100%; overflow: hidden;')

    // 整个图表的div
    const originDiv = document.createElement('div')
    // originDiv.setAttribute('id', 'pic-box')
    originDiv.setAttribute('style', 'height:' + contentHeight + 'px; width: ' + contentWidth + 'px;')
    originDiv.setAttribute('class', 'swiper-container pic-box-second')
    body.appendChild(originDiv)

    //  轮播内容div
    const carouselContent = document.createElement('div')
    carouselContent.setAttribute('class', 'swiper-wrapper')
    originDiv.appendChild(carouselContent)

    createContentList(carouselContent, textData)

    return originDiv
  }
  const originDiv = onload()
  // eslint-disable-next-line no-new
  new Swiper(originDiv, {
    speed: speedTime,
    autoplay: {
      disableOnInteraction: true,
      // 每次切换的时间间隔
      delay: delayTime
    }, // 可选选项，自动滑动
    direction: 'horizontal',

    // 设置slide 的宽度
    // width: 600,
    // autoHeight: true,
    setWrapperSize: true,
    // 设置前进 or 后退的按钮
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
    //  开启循环放到最后面
    loop: true
  })

  const Play = function () {

    // mySwiper()
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default PicCarouselSecond // 修改为Classname
