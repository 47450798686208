import { setXaxisDefault, setYaxisDefault } from '../../echartSetting/axis'
const chartFunc = function (params) {
  const originData = params.content_data
  const xAxisData = params.xAxisData
  // const echartsThemeColor = params.color_echarts_theme
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const isExchangeBarLine = params.isExchangeBarLine || params.is_exchange_bar_line
  const legend = params.legend
  const gradientColorStart = params.gradientColorStart
  const gradientColorEnd = params.gradientColorEnd
  const pictorialBarColor = params.pictorialBarColor
  const lineColor = params.lineColor
  const TailColor = params.TailColor
  const barWidth = params.barWidth
  const isShowBarLabel = params.isShowBarLabel
  const axisLableColor = params.axisLableColor
  const barLabelFontSize = params.barLabelFontSize

  const barBorderRadius = params.barBorderRadius

  const pictorialBarType = params.pictorialBarType
  const pictorialBarHeight = params.pictorialBarHeight
  const pictorialBarOffsetY = params.pictorialBarOffsetY
  const pictorialBarWidth = params.pictorialBarWidth
  const pictorialBarOffsetX = params.pictorialBarOffsetX

  const barNum = Math.round(originData.length / 2) // 四舍五入

  const circleColor = params.circleColor

  const barLabel = {
    show: isShowBarLabel,
    position: 'top',
    //  label 距离顶部的位置
    distance: 20,
    //   data ==> 该范围内的 data
    formatter: '{c}',
    // formatter: '{b}数量: {c}',
    fontFamily: 'Helvetica Narrow',
    textStyle: {
      color: axisLableColor,
      fontSize: barLabelFontSize,
      fontFamily: 'Helvetica Narrow'
    }
  }
  // 每组数据的最后
  const isAddTailColor = false
  const convertData = function (barData, name) {
    const newBarData = []
    const dataNum = barData.length - 1
    if (isAddTailColor !== true) {
      return barData
    }
    for (let i = 0; i < barData.length; i++) {
      if (i === dataNum) {
        const neaLastItem = {
          name: name,
          value: barData[i],
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              // 设置最后一列柱子的颜色
              colorStops: [{
                offset: 1, color: gradientColorStart // 0% 处的颜色
              }, {
                offset: 0, color: TailColor // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        }
        newBarData.push(neaLastItem)
      } else {
        newBarData.push(barData[i])
      }
    }
    return newBarData
  }
  const pictorialData = function (barData) {
    const res = []
    for (let i = 0; i < barData.length; i++) {
      res.push(
        {
          value: barData[i],
          symbolPosition: 'end'
        }
      )
    }
    return res
  }

  const series = []
  for (let i = 0; i < originData.length; i++) {
    if (isExchangeBarLine === true) {
      if (i < barNum) {
        series.push(
          {
            name: legend[i],
            type: 'bar',
            // 不适用百分比, 保证不同图表中 bar的 宽度一致
            barWidth: barWidth,
            label: barLabel,
            itemStyle: {
              // color: barColor,
              barBorderRadius: barBorderRadius,
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 1, color: gradientColorStart // 0% 处的颜色
                }, {
                  offset: 0, color: gradientColorEnd // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            yAxisIndex: 0,
            data: convertData(originData[i], xAxisData[i])
          }
        )
        series.push(
          {
            name: 'pictorialBar',
            type: 'pictorialBar',
            symbol: pictorialBarType,
            symbolSize: [pictorialBarWidth, pictorialBarHeight],
            symbolOffset: [pictorialBarOffsetX, pictorialBarOffsetY],
            z: 2,
            yAxisIndex: 0,
            tooltip: {
              show: false
            },
            itemStyle: {
              normal: {
                color: pictorialBarColor
              }
            },
            data: pictorialData(originData[i])
          }
        )
      } else {
        series.push(
          {
            name: legend[i],
            type: 'line',
            symbol: 'circle',
            symbolSize: 40,
            lineStyle: {
              color: lineColor,
              width: 2
            },
            itemStyle: {
              // color: lineColor,
              // 设置转折圆点的样式
              color: 'rgba(255,255,0,0)', // 设置圆点的颜色
              borderColor: circleColor,
              borderWidth: 2 // 设置圆点边线的宽度
            },
            yAxisIndex: 1,
            data: originData[i]
          }
        )
        // 添加圆环
        series.push(
          {
            name: 'pictorialBar',
            type: 'pictorialBar',
            symbol: 'circle',
            symbolPosition: 'end',
            symbolSize: [16, 16],
            symbolOffset: [0, -8],
            z: 2,
            yAxisIndex: 1,
            tooltip: {
              show: false
            },
            itemStyle: {
              normal: {
                color: circleColor
              }
            },
            data: originData[i]
          }
        )
      }
    } else if (isExchangeBarLine === false) {
      if (i < barNum) {
        series.push(
          {
            name: legend[i],
            type: 'line',
            symbol: 'circle',
            symbolSize: 40,
            lineStyle: {
              color: lineColor,
              width: 2
            },
            itemStyle: {
              // color: lineColor,
              // 设置转折圆点的样式
              color: 'rgba(255,255,0,0)', // 设置圆点的颜色
              borderColor: circleColor,
              borderWidth: 2 // 设置圆点边线的宽度
            },
            yAxisIndex: 1,
            data: originData[i]
          }
        )
        // 添加圆环
        series.push(
          {
            name: 'pictorialBar',
            type: 'pictorialBar',
            symbol: 'circle',
            symbolPosition: 'end',
            symbolSize: [16, 16],
            symbolOffset: [0, -8],
            z: 2,
            yAxisIndex: 1,
            tooltip: {
              show: false
            },
            itemStyle: {
              normal: {
                color: circleColor
              }
            },
            data: originData[i]
          }
        )
      } else {
        series.push(
          {
            name: legend[i],
            type: 'bar',
            label: barLabel,
            itemStyle: {
              // color: barColor
              barBorderRadius: barBorderRadius,
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 1, color: gradientColorStart // 0% 处的颜色
                }, {
                  offset: 0, color: gradientColorEnd // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            barWidth: barWidth,
            yAxisIndex: 0,
            data: convertData(originData[i], xAxisData[i])
          }
        )
        series.push(
          {
            name: 'pictorialBar',
            type: 'pictorialBar',
            symbol: pictorialBarType,
            symbolSize: [pictorialBarWidth, pictorialBarHeight],
            symbolOffset: [pictorialBarOffsetX, pictorialBarOffsetY],
            z: 2,
            yAxisIndex: 0,
            tooltip: {
              show: false
            },
            itemStyle: {
              normal: {
                color: pictorialBarColor
              }
            },
            data: pictorialData(originData[i])
          }
        )
      }
    }
  }

  const option = {
    backgroundColor: params.backgroundColor,
    grid: {
      left: '7%',
      right: '7%',
      top: '10%',
      bottom: '5%',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          show: true,
          color: '#5affc2',
          backgroundColor: '#072B4D',
          //   borderColor:'none',
          //   shadowColor:'none',
          shadowBlur: 0
        }
      }
    },
    legend: {
      show: false,
      right: '7%',
      top: '3%',
      itemGap: 10,
      itemHeight: 10,
      itemWidth: 10,
      textStyle: {
        fontSize: 10,
        color: '#fff'
      },
      data: legend
    },
    xAxis: setXaxisDefault(params),
    yAxis: setYaxisDefault(params),
    series: series
  }
  return option
}

export default chartFunc
