const maxIconColor = function (data, maxValue) {
  const res = []
  // 单独设置最大值的颜色
  data.forEach(function (item) {
    if (item === maxValue) {
      res.push({
        value: item,
        itemStyle: {
          color: 'rgba(68, 197, 255, 1)'
        }
      })
    } else {
      res.push({
        value: item,
        itemStyle: {
          color: 'rgba(255, 255, 255, 1)'
        }
      })
    }
  })

  return res
}

const maxBarColor = function (data, maxValue) {
  const res = []
  console.log('data', data)
  // 单独设置最大值的颜色
  data.forEach(function (item) {
    if (item === maxValue) {
      res.push({
        value: item,
        itemStyle: {
          color: 'rgba(68, 197, 255, 0.2)'
        }
      })
    } else {
      res.push(item)
    }
  })
  console.log('result', res)
  return res
}

export { maxIconColor, maxBarColor }
