import {
  manageImgPath
} from '../chartCommonPart'

const chart2 = function (params) {
  const originData = params.content_data[0]
  const data = []
  const newTemp = []
  // 随机修改数据
  for (let i = 0; i < originData.length; i++) {
    newTemp.push(
      originData[i] + Math.round(Math.random() * 8500 - 4000)

    )
  }

  const maxValue = Math.max(...newTemp)
  for (let i = 0; i < newTemp.length; i++) {
    if (newTemp[i] === maxValue) {
      data.push({
        value: newTemp[i],
        symbol: 'image://' + manageImgPath('/static/assets/images/icons/yellowRect.png'),
        label: {
          color: 'rgba(255, 201, 51, 1)',
          fontFamily: 'SonyEricssonLogo'
        }
      })
    } else {
      data.push({
        value: newTemp[i],
        symbol: 'image://' + manageImgPath('/static/assets/images/icons/blueRect.png')
      })
    }
  }

  const series = []
  series.push({
    name: 'picBar',
    type: 'pictorialBar',
    symbol: 'circle',
    symbolSize: [16, 16],
    // symbolOffset: [pictorialBarOffsetX, pictorialBarOffsetY],
    symbolPosition: 'end',
    z: 2,
    yAxisIndex: 0,
    tooltip: {
      show: false
    },
    label: {
      show: true,
      position: 'top',
      distance: 7,
      fontSize: 12,
      color: '#ffffff',
      fontFamily: 'SonyEricssonLogo'
    },
    itemStyle: {
      normal: {
        color: '#FFC933'
      }
    },
    data: data
  })

  return series
}

export default chart2
