/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
// import echarts from 'echarts'
// import {EchartsSchemes} from '../echarts_theme/echarts_color_schemes'
import './tablestatic.css'

function TableStatic (element, props) {
  // console.log(wonderland.color)
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化

  const data = props.data[0].data

  const colName = props.data[0].legend
  const rowHeight = props.data[0].rowHeight
  const textFontSize = props.data[0].textFontSize
  // const backgroundColor = props.data[0].backgroundColor
  const textColor = props.data[0].textColor
  const lineColor = props.data[0].lineColor
  const titleBackgroundColor = props.data[0].titleBackgroundColor
  const iconHeight = props.data[0].iconHeight
  const showIcon = props.data[0].showIcon

  onload = function () {
    const body = element.children[0]

    const originDiv = document.createElement('div')
    originDiv.setAttribute('style', 'display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; margin: 0 auto; background-color: rgba(0,0,0,0)')
    body.appendChild(originDiv)

    const firstDiv = document.createElement('table')
    firstDiv.setAttribute('style', 'border: 1px solid ' + lineColor)
    firstDiv.setAttribute('class', 'static-table')

    // 添加 th
    firstDiv.appendChild(createTh(colName))

    // 添加 td
    // TODO 需要确定数据的长度 length ==>  行的数量
    for (let i = 0; !(i >= data.length); i++) {
      const eachData = data[i]
      const colData = []
      for (const key in eachData) {
        colData.push(eachData[key])
      }
      // 需要 根据 奇偶数 设置行的颜色
      const trNode = createTr(colData)

      firstDiv.appendChild(trNode)
    }

    // 设置表格中 虚线 的样式
    const targetRows = firstDiv.children
    for (let i = 1; i < targetRows.length - 1; i++) {
      const targetRow = targetRows[i]
      // console.log(targetRow)
      targetRow.setAttribute('style', 'border-bottom: 2px dashed ' + lineColor)
    }
    originDiv.appendChild(firstDiv)
  }

  // ----------------------------------
  //   根据用户传过来的变量生成表格中行的方法
  // ----------------------------------
  const createTr = function (colName) {
    // 定义行元素标签
    const tr = document.createElement('tr')
    tr.setAttribute('class', 'static-row-tr')
    const colLength = colName.length
    const colWidth = Math.floor(100 / colLength)

    // 添加表格主体的每行的数据对象
    for (let i = 0; i < colLength; i++) {
      const tdName = document.createElement('td')
      tdName.setAttribute('style', 'width: ' + colWidth + '%; height: ' + rowHeight + 'px; color: ' + textColor)
      tdName.setAttribute('class', 'static-cell-td')
      tr.append(tdName)

      // 创建 包含文本的 span标签， 用来求文本的长度
      const spanNode = document.createElement('span')
      spanNode.setAttribute('style', 'margin-right: 23px; font-size: ' + textFontSize + 'px')
      tdName.append(spanNode)

      if (i === colLength - 1 && colName[i] < 0) {
        const iconImg = document.createElement('img')
        iconImg.setAttribute('height', iconHeight + 'px')
        tdName.appendChild(iconImg)

        iconImg.setAttribute('src', '/static/img/chart-icon/DownIcon.png')
        spanNode.setAttribute('style', 'margin-right: 23px; font-size: ' + textFontSize + 'px')
        spanNode.innerHTML = -colName[i]
      } else if (i === colLength - 1 && colName[i] > 0) {
        const iconImg = document.createElement('img')
        iconImg.setAttribute('height', iconHeight + 'px')
        tdName.appendChild(iconImg)

        iconImg.setAttribute('src', '/static/img/chart-icon/UpIcon.png')
        spanNode.setAttribute('style', 'margin-right: 23px; font-size: ' + textFontSize + 'px')
        spanNode.innerHTML = colName[i]
      } else if (i === colLength - 1 && colName[i] === 0) {
        spanNode.setAttribute('style', 'font-size: ' + textFontSize + 'px')
        spanNode.innerHTML = '-'
      } else {
        spanNode.setAttribute('style', 'font-size: ' + textFontSize + 'px')
        spanNode.innerHTML = colName[i]
      }

      tr.appendChild(tdName)
    }
    return tr
  }

  // ----------------------------------
  //   创建 th 表格的标题
  // ----------------------------------
  const createTh = function (titleName) {
    // 定义行元素标签
    const tr = document.createElement('tr')
    tr.setAttribute('class', 'static-row-tr')
    tr.setAttribute('style', 'background-color: ' + titleBackgroundColor + ';border: 1px solid ' + titleBackgroundColor)
    const colLength = titleName.length
    // console.log('colLength:', colLength);
    const colWidth = Math.floor(100 / colLength)
    // console.log('colWidth:', colWidth);
    for (let i = 0; i < colLength; i++) {
      const thName = document.createElement('th')
      thName.setAttribute('style', 'width: ' + colWidth + '%; height: ' + rowHeight + 'px;')
      thName.setAttribute('class', 'static-cell-th')
      tr.append(thName)
      const spanNode = document.createElement('span')
      spanNode.setAttribute('style', 'fontSize: ' + textFontSize + 'px ; color: ' + textColor)
      thName.append(spanNode)
      spanNode.innerHTML = titleName[i]
      // console.log(colName[i]);
      tr.appendChild(thName)
    }
    return tr
  }

  //  不能使用 setInterval 方法,本身就是一个递归方法
  //  props返回的内容增加 mode[edit show]  autoplay[True false],只有当 show, True时, 才执行循环刷新的方法
  const mode = props.mode
  const autoPlay = props.autoPlay
  // console.log(mode, autoPlay)
  if (mode === 'show' && autoPlay === true) {
    onload(autoPlay)
  } else {
    var noPlay = false
    onload(noPlay)
  }
}

export default TableStatic // 修改为Classname
