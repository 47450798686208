// import {manageImgPath} from '../chartCommonPart'

const chart3 = function (frontDict) {
  // 方形的长度占比 ==> 数值 / 总和
  const currentValue = 30000 + Math.round(Math.random() * 8000 - 4000)
  const reactWidthRatio = currentValue / frontDict.sumNum
  const customFunc = function (params, api) {
    // 获取当前底图的长宽
    // console.log(params.coordSys)
    const coordSys = params.coordSys
    const chartWidth = coordSys.width
    // let chartHeight = coordSys.height
    const firstValue = api.value(0)
    const secondValue = api.value(1)
    // console.log('firstValue', firstValue)
    // console.log('secondValue', secondValue)
    // 获取第一个点的坐标
    const firstPoint = api.coord([firstValue, secondValue])
    // console.log(firstPoint)

    const customGroup = {
      // 设置自定义图形组合
      type: 'group',
      children: [{
        // 主体长方形颜色
        type: 'rect',
        shape: {
          // 定义起点与长宽
          x: firstPoint[0],
          y: firstPoint[1],
          width: chartWidth * reactWidthRatio,
          // 负数表示反向延伸
          height: -6
        },
        style: {
          fill: {
            // 填充颜色,可以设置渐变色
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [{
              offset: 1,
              color: 'rgba(81, 201, 255, 1)' // 0% 处的颜色
            }, {
              offset: 0,
              color: 'rgba(81, 201, 255, 0)' // 100% 处的颜色
            }],
            global: false // 缺省为 false
          },
          // fill: 'rgba(81, 201, 255, 1)',
          // 设置边框的颜色与宽度
          stroke: '#FFC933',
          lineWidth: 0
        }
      }, {
        // 背景色
        type: 'rect',
        shape: {
          x: firstPoint[0],
          y: firstPoint[1],
          width: chartWidth,
          height: -8
        },
        style: {
          fill: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [{
              offset: 1,
              color: 'rgba(81, 201, 255, 0.0)' // 0% 处的颜色
            }, {
              offset: 0,
              color: 'rgba(81, 201, 255, 0.0)' // 100% 处的颜色
            }],
            global: false // 缺省为 false
          },
          stroke: '#FFC933',
          lineWidth: 0
        }
      }, {
        // 左边的竖线
        type: 'polyline',
        shape: {
          points: [
            [firstPoint[0], firstPoint[1] - 8],
            [firstPoint[0], firstPoint[1] + 8]
          ],
          smooth: 0
        },
        style: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: 'rgba(81, 201, 255, 0.0)',
          lineWidth: 1
        }
      }, {
        // 右边的竖线
        type: 'polyline',
        shape: {
          points: [
            [firstPoint[0] + chartWidth, firstPoint[1] - 8],
            [firstPoint[0] + chartWidth, firstPoint[1] + 8]
          ],
          smooth: 0
        },
        style: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: 'rgba(81, 201, 255, 0.0)',
          lineWidth: 1
        }
      }, {
        // 数值的标注
        type: 'polyline',
        shape: {
          points: [
            [firstPoint[0] + chartWidth * reactWidthRatio, firstPoint[1] - 10],
            [firstPoint[0] + chartWidth * reactWidthRatio, firstPoint[1] - 16]
          ],
          smooth: 0
        },
        style: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: 'rgba(255, 201, 51, 1)',
          lineWidth: 2
        }
      }, {
        type: 'text',
        // position: [0, 0],
        style: {
          text: currentValue,
          x: firstPoint[0] + chartWidth - String(currentValue).length * 10,
          y: firstPoint[1] - 38 - frontDict.XlabelToAxis,
          font: '20px "SonyEricssonLogo"',
          fill: 'rgba(255, 255, 255, 1)',
          textAlign: 'rigth'
        }
      }]
    }

    return customGroup
  }

  const series = []
  series.push({
    name: 'customChart',
    type: 'custom',
    symbol: 'circle',
    coordinateSystem: 'cartesian2d',
    zlevel: 10,
    renderItem: customFunc,
    itemStyle: {
      normal: {
        color: '#FFC933'
      }
    },
    data: [{
      name: 'name-1',
      value: 0
    }]
  })

  return series
}

export default chart3
