import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function BubbleMulti (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const myChart = echarts.init(div[0][0].children[0], props.data[0].color_echarts_theme)
  // eushow 3.0
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const allData = props.data[0].all_data
  const maxSize = props.data[0].max_size
  const sizeCoefficient = props.data[0].Size_coefficient
  const legend = props.data[0].legend

  const series = []

  allData.forEach(function (item, i) {
    series.push({
      name: legend[i],
      data: item,
      type: 'scatter',
      symbolSize: function (data) {
        return Math.sqrt(data[2] / maxSize) * 2 * sizeCoefficient
      },
      label: {
        emphasis: {
          show: true,
          formatter: function (param) {
            var res = ''
            var xValue = param.data[0]
            var yValue = param.data[1]
            var colsName = param.data[3]
            res = colsName + '\n x: ' + xValue + '\n y: ' + yValue
            return res
          },
          position: 'top'
        }
      },
      animationDuration: 2000
    }
    )
  })

  const option = {
  // title: {
  //     text: '1990 与 2015 年各国家人均寿命与 GDP'
  // },
    tooltip: {
      triggerOn: trigger,
      hideDelay: hideDelayTime
    },
    legend: {
      right: '7%',
      data: legend,
      itemWidth: 10,
      itemHeight: 10,
      top: '4%',
      //  为了解决名称过长的问题， 可以缩略的形式显示
      //  配置了 该项之后， data 中设置的内容将被覆盖  ==> 更换名称
      formatter: function (name) {
        console.log('name', name)
        return echarts.format.truncateText(name, 14, '14px Microsoft Yahei', '...')
      },
      tooltip: {
        show: true
        // show: false,
      }

    },
    grid: {
      top: '15%',
      left: '7%',
      right: '7%',
      bottom: '7%',
      containLabel: true
    },
    xAxis: {
      name: props.data[0].x_axis_label,
      nameLocation: 'center',
      nameGap: 25,
      axisTick: {
        show: false,
        color: '#fff'
      },
      axisLabel: {
        // rotate: '-45',
        // margin: 30,
        textStyle: {
          fontSize: 10,
          color: '#fff'
        }
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: 'rgba(109,109, 109)'
        }
      }
    },
    yAxis: {
      name: '(' + props.data[0].y_axis_label + ')',
      axisLabel: {
        // formatter: '{value}',
        // margin: 15,
        fontSize: 10,
        color: '#fff'
      },
      nameTextStyle: {
        fontSize: 10,
        color: 'rgb(195,195,195)'
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: 'rgba(109,109, 109)'
        }
      },
      scale: true
    },
    series: series
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  var startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default BubbleMulti
