import 'echarts/map/js/world'
// import echarts from 'echarts'

// let targetCountry = ['中国', '美国', '俄罗斯', '印度', '日本', '韩国']

// eslint-disable-next-line no-unused-vars
const tooltipPosition = {
  中国: {
    left: 731,
    top: 77,
    isLeft: true,
    arrowTop: 150,
    arrowLeft: 317,
    rotate: 180
  },
  美国: {
    left: 460,
    top: 60,
    isLeft: false,
    arrowTop: 160,
    arrowLeft: -27,
    rotate: 0
  },
  俄罗斯: {
    left: 760,
    top: -17,
    isLeft: true,
    arrowTop: 127,
    arrowLeft: 317,
    rotate: 180
  },
  俄国: {
    left: 760,
    top: -17,
    isLeft: true,
    arrowTop: 127,
    arrowLeft: 317,
    rotate: 180
  },
  印度: {
    left: 800,
    top: 142,
    isLeft: true,
    arrowTop: 145,
    arrowLeft: 317,
    rotate: 180
  },
  日本: {
    left: 630,
    top: 75,
    isLeft: true,
    arrowTop: 150,
    arrowLeft: 317,
    rotate: 180
  },
  韩国: {
    left: 650,
    top: 82,
    isLeft: true,
    arrowTop: 140,
    arrowLeft: 317,
    rotate: 180
  },
  加拿大: {
    left: 420,
    top: 6,
    isLeft: false,
    arrowTop: 160,
    arrowLeft: -27,
    rotate: 0
  },
  爱尔兰: {
    left: 700,
    top: 16,
    isLeft: false,
    arrowTop: 140,
    arrowLeft: -27,
    rotate: 0
  },
  英国: {
    left: 724,
    top: 12,
    isLeft: false,
    arrowTop: 140,
    arrowLeft: -27,
    rotate: 0
  },
  德国: {
    left: 380,
    top: 29,
    isLeft: false,
    arrowTop: 140,
    arrowLeft: 317,
    rotate: 180
  },
  法国: {
    left: 360,
    top: 39,
    isLeft: false,
    arrowTop: 140,
    arrowLeft: 317,
    rotate: 180
  },
  瑞士: {
    left: 390,
    top: 42,
    isLeft: false,
    arrowTop: 140,
    arrowLeft: 317,
    rotate: 180
  },
  意大利: {
    left: 400,
    top: 63,
    isLeft: false,
    arrowTop: 140,
    arrowLeft: 317,
    rotate: 180
  },
  叙利亚: {
    left: 455,
    top: 88,
    isLeft: false,
    arrowTop: 140,
    arrowLeft: 317,
    rotate: 180
  },
  伊拉克: {
    left: 475,
    top: 95,
    isLeft: false,
    arrowTop: 140,
    arrowLeft: 317,
    rotate: 180
  },
  南非: {
    left: 430,
    top: 280,
    isLeft: false,
    arrowTop: 200,
    arrowLeft: 317,
    rotate: 180
  },
  巴基斯坦: {
    left: 565,
    top: 119,
    isLeft: false,
    arrowTop: 140,
    arrowLeft: 317,
    rotate: 180
  },
  尼泊尔: {
    left: 595,
    top: 114,
    isLeft: false,
    arrowTop: 140,
    arrowLeft: 317,
    rotate: 180
  },
  泰国: {
    left: 670,
    top: 165,
    isLeft: false,
    arrowTop: 140,
    arrowLeft: 317,
    rotate: 180
  },
  菲律宾: {
    left: 720,
    top: 186,
    isLeft: false,
    arrowTop: 140,
    arrowLeft: 317,
    rotate: 180
  },
  马来西亚: {
    left: 700,
    top: 210,
    isLeft: false,
    arrowTop: 140,
    arrowLeft: 317,
    rotate: 180
  },
  澳大利亚: {
    left: 750,
    top: 270,
    isLeft: false,
    arrowTop: 180,
    arrowLeft: 317,
    rotate: 180
  },
  斯里兰卡: {
    left: 580,
    top: 155,
    isLeft: false,
    arrowTop: 180,
    arrowLeft: 317,
    rotate: 180
  },
  尼日利亚: {
    left: 380,
    top: 150,
    isLeft: false,
    arrowTop: 180,
    arrowLeft: 317,
    rotate: 180
  },
  伊朗: {
    left: 530,
    top: 150,
    isLeft: false,
    arrowTop: 180,
    arrowLeft: 317,
    rotate: 180
  },
  阿富汗: {
    left: 830,
    top: 97,
    isLeft: false,
    arrowTop: 180,
    arrowLeft: 317,
    rotate: 180
  },
  孟加拉国: {
    left: 754,
    top: 132,
    isLeft: false,
    arrowTop: 180,
    arrowLeft: 317,
    rotate: 180
  }
}

const chart = function (params) {
  const countryName = 'world'
  const title = params.title
  const areaColor = params.areaColor

  const mapData = []
  params.countryList.forEach(element => {
    mapData.push({
      name: element,
      // selected: true,
      label: {
        show: true,
        color: 'rgba(255, 255, 255, 1)',
        fontSize: 12
      }
    })
  })

  const mapStyle = {
    areaColor: areaColor,
    borderWidth: params.lineWidth,
    borderColor: params.lineColor
  }

  const finalSeries = {
    name: title,
    type: 'map',
    mapType: countryName,
    roam: false,
    // zoom: targetZoom,
    label: {
      show: false
    },
    itemStyle: mapStyle,
    emphasis: {
      label: {
        show: true,
        color: 'rgba(255, 255, 255, 1)'
      },
      itemStyle: {
        areaColor: params.hoverColor
      }
    },
    data: mapData,
    nameMap: params.enToch
  }

  // console.log(visualMapDict);
  const option = {
    title: {
      show: false
    },
    tooltip: {
      trigger: 'item',
      // alwaysShowContent: true,
      // 背景设置为0 解决有圆点
      backgroundColor: 'rgba(50,50,50,0)',
      triggerOn: 'click',
      hideDelay: 1000,
      renderMode: 'html',
      enterable: true, // 允许弹窗内交互
      confine: true, // tooltip 不超出图表范围
      position: function (point, params, dom, rect, size) {
        // console.log('params', params)
        const siteName = params.name
        const positionInfo = tooltipPosition[siteName]
        var obj = {
          top: positionInfo.top
        }
        const isLeft = positionInfo.isLeft
        if (isLeft === false) {
          obj.left = positionInfo.left
        } else {
          obj.right = positionInfo.left
        }

        // var obj = {
        //   top: point[1] - 180
        // }
        // let isRight = +(point[0] < size.viewSize[0] / 2)
        // if (isRight === 1) {
        //   obj['left'] = point[0] + 27
        // } else {
        //   obj['right'] = size.viewSize[0] - point[0] + 300 + 27
        // }
        // console.log('position', obj)

        return obj
      },
      formatter: function (chartParams) {
        // let isExist = false
        const siteName = chartParams.name
        const positionInfo = tooltipPosition[siteName]
        if (positionInfo === undefined) {
          return
        }
        const name = chartParams.name
        const info = params.countryInfo[name]
        let textList = ''
        for (let i = 0; i < info.articles.length; i++) {
          if (i >= 5) { break }
          const article = info.articles[i]
          textList = textList + `<li style="">
          <a href="${article.url}" target="_blank">${article.title}</a>
        </li>`
        }

        const res = `<div style="position: relative">
        <div class="tooltip-country-back">
          <div style="height: 90px; width: 300px;">
            <div class="title">${info.title}</div>
            <div class="number">${info.articleCount}</div>
          </div>
          <div style="height: 320px; max-width: 300px; margin: 20px 20px">
            <ul>${textList}
            </ul>
          </div>
        </div>
        <div
          class="tooltip-country-arrow"
          style="margin-left: ${positionInfo.arrowLeft}px;margin-top: ${positionInfo.arrowTop}px;transform:rotate(${positionInfo.rotate}deg);"
        ></div>
      </div>`
        return res
      }
    },
    series: [finalSeries]
  }
  return option
}

export default chart
