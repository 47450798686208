import './chart.scss'
/**
 * 传入图表数据
 *  + 五个国家
 *  + 十个维度
 * @param {*} params
 */
const getOptions = function (chartData, maxValue) {
  const names = chartData.map(item => {
    return item.name
  })
  const option = {
    tooltip: {
      show: true,
      trigger: 'item',
      // 是否一直显示
      // alwaysShowContent: true,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      position: [10, 10],
      formatter: function (p, t) {
        console.log(p)
        const { data } = p
        const list = maxValue.map((item, index) => {
          return {
            label: item.name,
            value: data.value[index]
          }
        })
        let listHtml = ''
        list.forEach((item, index) => {
          listHtml += `
            <div class="value_item">
              <p>${item.label}：${item.value}</p>
            </div>
          `
        })
        const html = `
          <div class="chart27 radar tooltip">
          <p style="white-space: normal;">${data.name}</p>
            <div class="value_list">
              ${listHtml}
            </div>
          </div>
        `
        return html
      }
    },
    color: ['#37A2DA', '#32C5E9', '#67E0E3', '#9FE6B8', '#FFDB5C', '#FF9F7F', '#FB7293', '#E062AE', '#E690D1', '#E690D1'],
    // 图例
    legend: {
      orient: 'vertical',
      itemWidth: 24,
      itemHeight: 10,
      align: 'right',
      top: 15,
      bottom: 0,
      right: 0,
      data: names,
      textStyle: {
        width: 65,
        fontSize: 10,
        padding: [0, 10, 0, 0],
        color: 'rgba(255, 255, 255, 0.8)'
      }
    },
    radar: {
      // shape: 'circle',
      radius: '80px',
      center: [160, '51%'],
      name: {
        textStyle: {
          color: 'rgba(255, 255, 255, 0.8)'
        }
      },
      indicator: maxValue,
      axisLine: {
        show: false
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.6)',
          width: 1
        }
      },
      splitArea: {
        show: false
      }
    },
    series: [{
      type: 'radar',
      // areaStyle: {normal: {}},
      // data: dataList
      data: chartData
    }]
  }

  return option
}

export default getOptions
