// 设置text 属性后, pieces 属性失效
const visualmapPiece = {
  type: 'piecewise',
  min: 1,
  max: 80,
  itemSymbol: 'roundRect',
  itemHeight: 20,
  itemWidth: 20,
  showLable: true,
  text: ['高', '低'],
  realtime: false,
  // calculable: true,
  inRange: {
    color: ['skyblue', 'orangered']
  },
  textStyle: {
    fontSize: 16
  }
}

export { visualmapPiece }
