<!-- 利益相关者 -->
<template>
  <div class="chart_24">
    <div class="chart_table">
      <div class="chart_header">
        <div class="tabs">
          <div
            class="tab_item"
            :class="{'active':item === activeName}"
            v-for="item in tabList"
            :key="item"
            @click="toggleTab(item)">
            {{ item }}
          </div>
        </div>
        <div class="tips">
          <p>利益相关者用户（top20）</p>
        </div>
      </div>
      <div class="chart_main">
        <div class="table_wrapper">
          <div class="th_wrapper">
            <table>
              <colgroup class="col1" width="230"></colgroup>
              <colgroup class="col2" width="133"></colgroup>
              <thead>
                <tr>
                  <th>名称</th>
                  <th>影响力</th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="tb_wrapper">
            <vue-scroll>
              <table>
                <colgroup class="col1" width="230"></colgroup>
                <colgroup class="col2" width="133"></colgroup>
                <tbody>
                  <tr v-for="(item, index) in curTableData.table" :key="index">
                    <td v-for="(value, name) in item" :key="name">
                      {{ value }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </vue-scroll>
          </div>
        </div>
      </div>
    </div>
    <div class="chart_timeline">
      <p class="timeline_title">利益相关者衍生主题</p>
      <div class="timeline_list">
        <vue-scroll>
          <div class="timeline_item" v-for="(item, index) in curTableData.timeline" :key="index">
            <div class="info">
              <p class="" :title="item">{{ item }}</p>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import { allData } from '../data-source/chart-24'
export default {
  data () {
    return {
      // 当前激活项
      activeName: '个人',
      // tab list
      tabList: ['个人', '企业', '政府']
    }
  },
  computed: {
    // 当前数据
    curTableData () {
      return allData[this.activeName]
    }
  },
  methods: {
    toggleTab (value) {
      this.activeName = value
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart_24 {
    width: 366px;
    .chart_table {
      margin-bottom: 24px;
      .chart_header {
        margin-bottom: 15px;
        .tabs {
          display: flex;
          height: 32px;
          min-height: 32px;
          margin-bottom: 20px;
          .tab_item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 32px;
            background-image: url('../pic/btn-border-1.png');
            background-size: 100%;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.9);
            cursor: pointer;
            &.active {
              background-image: url('../pic/btn-bg-blue.png');
            }
            &+.tab_item {
              margin-left: 10px;
            }
          }
        }
        .tips {
          & > p {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }
      .chart_main {
        .table_wrapper {
          position: relative;
          border-top: 1px solid rgba(255, 255, 255, 0.3);
          border-left: 1px solid rgba(255, 255, 255, 0.3);
          .tb_wrapper {
            height: 360px;
          }
          &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.3);
          }
          &::after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 1px;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.3);
          }
          table {
            width: 364px;
            border-collapse:collapse;
            th, td {
              padding: 10px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              line-height: 20px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.3);
              border-right: 1px solid rgba(255, 255, 255, 0.3);
            }
            thead {
              th {
                text-align: left;
                color: rgba(255, 255, 255, 0.8);
                background-color: rgba(22, 215, 255, 0.05);
              }
            }
            tbody {
              // 每行第二个
              tr:nth-child(2n) {
                background-color: rgba(22, 215, 255, 0.05);
              }
              td {
                color: #ffffff;
                &:last-child {
                  color: rgba(88, 238, 255, 1);
                }
              }
            }
          }
        }
      }
    }
    .chart_timeline {
      .timeline_title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 20px;
      }
      .timeline_list {
        // padding-left: 10px;
        height: 274px;
        .timeline_item {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          height: 54px;
          padding-left: 34px;
          // border-left: 1px solid rgba(255, 255, 255, 0.4);
          cursor: pointer;
          .info {
            display: flex;
            align-items: center;
            width: 330px;
            height: 40px;
            background: rgba(94, 105, 123, 0.3);
            padding: 10px;
            border-top: 1px solid #43A7FD;
            border-bottom: 1px solid #43A7FD;
            border-left: 1px solid rgba(255, 255, 255, 0.3);
            border-right: 1px solid rgba(255, 255, 255, 0.3);
            & > p {
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 500;
              line-height: 20px;
              color: #FFFFFF;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 21px;
            left: 14px;
            width: 9px;
            height: 9px;
            background: #43A7FD;
            border-radius: 50%;
            z-index: 10;
          }
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 18px;
            width: 1px;
            height: 100%;
            background: rgba(255, 255, 255, 0.4);
            z-index: 5;
          }
          &:hover {
            .info {
              border-top: 1px solid #FF6F56;
              border-bottom: 1px solid #FF6F56;
            }
            &::before {
              background: #FF6F56;
            }
          }
        }
      }
    }
  }
</style>
