const getOptions = function (params) {
  // 索引1元素 为红色，索引2元素为蓝色
  const total = params.reduce((value, item) => {
    return value + item.value
  }, 0)

  const num2 = params[1].value / total

  const option = {
    backgroundColor: '#000000',
    title: {
      x: 'center',
      y: 'center',
      text: '用 户\n分 析',
      textStyle: {
        color: '#FFFFFF',
        fontSize: 16,
        fontWeight: 'lighter',
        rich: {
          a: {
            padding: 5
          }
        }
      }
    },
    series: [
      {
        type: 'pie',
        name: '最外层细环',
        radius: ['98%', '100%'],
        hoverAnimation: false,
        clockWise: false,
        itemStyle: {
          normal: {
            color: 'rgba(255, 255, 255, 0.5)'
          }
        },
        label: {
          show: false
        },
        data: [100]
      },
      {
        type: 'gauge',
        radius: '85%',
        clockwise: false,
        startAngle: '90',
        endAngle: '-269.9999',
        // 分割的数量
        splitNumber: 30,
        // 是否显示详情
        detail: {
          show: false
        },
        // 是否显示指针
        pointer: {
          show: false
        },
        // 轴线相关配置
        axisLine: {
          show: true,
          lineStyle: {
            color: [
              [0, '#cccccc'],
              // 蓝色占0.6（1-0.4 = 0.6）
              [num2, '#43A7FD'],
              // 红色
              [1, '#FF6F56']
            ],
            width: 13
          }
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        // 分割宽度
        splitLine: {
          show: true,
          // 长度
          length: 90,
          // 分割颜色
          lineStyle: {
            color: '#000D1D',
            width: 3
          }
        }
      },
      // 最内层细环
      {
        type: 'pie',
        name: '内层细圆环',
        radius: ['50%', '52%'],
        hoverAnimation: false,
        clockWise: false,
        itemStyle: {
          normal: {
            color: 'rgba(255, 255, 255, 0.5)'
          }
        },
        label: {
          show: false
        },
        data: [100]
      }
    ]
  }

  return option
}

export default getOptions
