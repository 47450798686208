const topic = [
  {
    label: '类目0',
    value: '医疗卫生部门'
  }, {
    label: '类目1',
    value: '非卫生领域政府部门'
  }, {
    label: '类目2',
    value: '医院'
  }, {
    label: '类目3',
    value: '传统媒体'
  }, {
    label: '类目4',
    value: '自媒体'
  }, {
    label: '类目5',
    value: '普通企业'
  }, {
    label: '类目6',
    value: '医疗健康企业'
  }, {
    label: '类目7',
    value: '校园'
  }, {
    label: '类目8',
    value: '旅游娱乐与消费场所'
  }, {
    label: '类目9',
    value: '医药卫生领域人员'
  }, {
    label: '类目10',
    value: '易感人群'
  }, {
    label: '类目11',
    value: '普通群众'
  }]

const topicBg = {
  医疗卫生部门: 'bg1',
  非卫生领域政府部门: 'bg2',
  医院: 'bg1',
  传统媒体: 'bg2',
  自媒体: 'bg2',
  普通企业: 'bg2',
  医疗健康企业: 'bg3',
  校园: 'bg2',
  旅游娱乐与消费场所: 'bg2',
  医药卫生领域人员: 'bg4',
  易感人群: 'bg5',
  普通群众: 'bg5'
}

const topicTable = {
  医疗卫生部门: [{
    name: '疫病简介及临床表现',
    value: 23.3862
  }, {
    name: '疫情影响',
    value: 10.9344
  }, {
    name: '疫情通报',
    value: 8.2333
  }, {
    name: '广东现首例输入性病例',
    value: 7.6133
  }, {
    name: '患者病情加重，仍有多名密切接触者未找到',
    value: 7.435
  }],
  非卫生领域政府部门: [{
    name: '疫情影响',
    value: 14.01
  }, {
    name: '患者病情加重，仍有多名密切接触者未找到',
    value: 11.3342
  }, {
    name: '疫情通报',
    value: 9.3401
  }, {
    name: '疫病简介及临床表现',
    value: 8.8683
  }, {
    name: '广东现首例输入性病例',
    value: 4.9934
  }],
  医院: [{
    name: '疫病简介及临床表现',
    value: 25.208
  }, {
    name: '疫情通报',
    value: 10.4504
  }, {
    name: '疫情影响',
    value: 8.8483
  }, {
    name: '患者病情加重，仍有多名密切接触者未找到',
    value: 7.4283
  }, {
    name: '广东现首例输入性病例',
    value: 5.0439
  }],
  传统媒体: [{
    name: '疫情通报',
    value: 15.8041
  }, {
    name: '疫情影响',
    value: 14.8469
  }, {
    name: '广东现首例输入性病例',
    value: 7.9572
  }, {
    name: '患者病情加重，仍有多名密切接触者未找到',
    value: 7.4978
  }, {
    name: '疫病简介及临床表现',
    value: 4.2405
  }],
  自媒体: [{
    name: '疫情通报',
    value: 7.8435
  }, {
    name: '疫情影响',
    value: 7.6259
  }, {
    name: '代购广告',
    value: 5.0589
  }, {
    name: '疫病简介及临床表现',
    value: 4.456
  }, {
    name: '患者病情加重，仍有多名密切接触者未找到',
    value: 3.9071
  }],
  普通企业: [{
    name: '疫情影响',
    value: 11.3264
  }, {
    name: '疫情通报',
    value: 10.5599
  }, {
    name: '疫病简介及临床表现',
    value: 8.7951
  }, {
    name: '患者病情加重，仍有多名密切接触者未找到',
    value: 5.1268
  }, {
    name: '广东现首例输入性病例',
    value: 4.8678
  }],
  医疗健康企业: [{
    name: '疫病简介及临床表现',
    value: 17.9057
  }, {
    name: '疫情通报',
    value: 12.9266
  }, {
    name: '疫情影响',
    value: 12.8407
  }, {
    name: '广东现首例输入性病例',
    value: 9.991
  }, {
    name: '抗体研究进展',
    value: 4.7957
  }],
  校园: [{
    name: '疫病简介及临床表现',
    value: 10.1426
  }, {
    name: '疫情通报',
    value: 9.5283
  }, {
    name: '患者病情加重，仍有多名密切接触者未找到',
    value: 7.1828
  }, {
    name: '广东现首例输入性病例',
    value: 6.3211
  }, {
    name: '疫情影响',
    value: 5.1511
  }],
  旅游娱乐与消费场所: [{
    name: '疫情影响',
    value: 24.1651
  }, {
    name: '疫病简介及临床表现',
    value: 7.0362
  }, {
    name: '李敏镐担任韩国旅游大使',
    value: 5.8864
  }, {
    name: '疫情通报',
    value: 5.8124
  }, {
    name: '取消韩国旅游退款问题',
    value: 5.7727
  }],
  医药卫生领域人员: [{
    name: '疫病简介及临床表现',
    value: 5.5911
  }, {
    name: '疫情通报',
    value: 5.1128
  }, {
    name: '代购广告',
    value: 4.6706
  }, {
    name: '护理病患护士的报道',
    value: 4.6051
  }, {
    name: '急寻密切接触者',
    value: 4.3064
  }],
  易感人群: [{
    name: '急寻密切接触者',
    value: 8.4572
  }, {
    name: '代购广告',
    value: 6.169
  }, {
    name: '广东现首例输入性病例',
    value: 4.4465
  }, {
    name: '疫情通报',
    value: 3.9661
  }, {
    name: '因 MERS 事件而辱骂韩国',
    value: 3.9163
  }],
  普通群众: [{
    name: '疑似患者进入昆明遭网民指责',
    value: 5.4804
  }, {
    name: '代购广告',
    value: 5.0098
  }, {
    name: '网民追讨患者八百万治疗费',
    value: 4.8653
  }, {
    name: '因 MERS 事件而辱骂韩国',
    value: 4.6504
  }, {
    name: '疫情通报',
    value: 4.1275
  }]
}
export {
  topic,
  topicTable,
  topicBg
}
