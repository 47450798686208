<template>
  <div class="base_filter">
    <a target="_blank" href="http://www.tianqi.com/chibi/?tq">
      <div class="tianqi">
        <div class="tianqi-img">
          <img class="pngtqico" align="absmiddle" :src="baseUrl" style="border:0;" />
        </div>
        <div class="tianqi-shuzhi">
          <ul>
            <li>
              <p class="tianqi-sz-p1">{{frontDict.weatherInfo.tempLow}}℃</p>
              <p class="tianqi-sz-p2">/{{frontDict.weatherInfo.tempHeight}}℃</p>
              <p class="tianqi-sz-p3">{{frontDict.weatherInfo.siteNamePY}}</p>
            </li>
            <li style="padding-left: 7px;">{{frontDict.weatherInfo.weather}}</li>
          </ul>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  computed: {
    update () {
      // 添加定时更新操作
      return ''
    },
    baseUrl () {
      return '//plugin.tianqistatic.com/static/images/tianqibig/' + this.frontDict.weatherInfo.weatherPic + '.png'
    }
  }
}
</script>
