const dataMap = {
  kunming: {
    '2014-03-01': '起始期',
    '2014-03-02': '爆发期',
    '2014-03-03': '爆发期',
    '2014-03-04': '爆发期',
    '2014-03-05': '爆发期',
    '2014-03-06': '爆发期',
    '2014-03-07': '爆发期',
    '2014-03-08': '衰退期',
    '2014-03-09': '衰退期',
    '2014-03-10': '衰退期',
    '2014-03-11': '衰退期',
    '2014-03-12': '衰退期',
    '2014-03-13': '衰退期',
    '2014-03-14': '衰退期',
    '2014-03-15': '平息期',
    '2014-03-16': '平息期',
    '2014-03-17': '平息期',
    '2014-03-18': '平息期',
    '2014-03-19': '平息期',
    '2014-03-20': '平息期',
    '2014-03-21': '平息期',
    '2014-03-22': '平息期',
    '2014-03-23': '平息期',
    '2014-03-24': '平息期',
    '2014-03-25': '平息期',
    '2014-03-26': '平息期',
    '2014-03-27': '平息期',
    '2014-03-28': '平息期',
    '2014-03-29': '平息期',
    '2014-03-30': '平息期',
    '2014-03-31': '平息期'
  },
  shandong: {
    '2016-03-18': '潜伏期',
    '2016-03-19': '潜伏期',
    '2016-03-20': '潜伏期',
    '2016-03-21': '爆发期',
    '2016-03-22': '爆发期',
    '2016-03-23': '爆发期',
    '2016-03-24': '蔓延期',
    '2016-03-25': '蔓延期',
    '2016-03-26': '蔓延期',
    '2016-03-27': '蔓延期',
    '2016-03-28': '恢复期',
    '2016-03-29': '恢复期',
    '2016-03-30': '恢复期',
    '2016-03-31': '恢复期',
    '2016-04-01': '恢复期',
    '2016-04-02': '恢复期',
    '2016-04-03': '恢复期',
    '2016-04-04': '恢复期',
    '2016-04-05': '恢复期',
    '2016-04-06': '恢复期',
    '2016-04-07': '恢复期',
    '2016-04-08': '恢复期',
    '2016-04-09': '恢复期',
    '2016-04-10': '恢复期',
    '2016-04-11': '恢复期',
    '2016-04-12': '恢复期',
    '2016-04-13': '恢复期',
    '2016-04-14': '恢复期',
    '2016-04-15': '恢复期'
  }
}

export { dataMap }
