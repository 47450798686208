export default {
  series: [
    {
      type: 'line',
      data: [12, 21, 35, 14, 25, 76, 47, 38, 29, 10, 91, 121],
      lineStyle: {
        color: '#43A7FD'
      },
      symbol: 'none'
    }
  ],
  grid: {
    left: 36,
    top: 10,
    bottom: 24,
    right: 0
  },
  xAxis: [
    {
      type: 'category',
      data: ['01月', '02月', '03月', '04月', '05月', '06月', '07月', '08月', '09月', '10月', '11月', '12月'],
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.6)'
        }
      }
    },
    {
      type: 'category',
      data: ['01月', '02月', '03月', '04月', '05月', '06月', '07月', '08月', '09月', '10月', '11月', '12月'],
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.6)'
        }
      }
    }
  ],
  yAxis: {
    axisTick: {
      show: false
    },
    axisLine: {
      show: false,
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.6)'
      }
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.2)'
      }
    }
  }
}
