var chart2 = function (data, colWidth, params) {
  const firstRowHeight = params.numberFontSize + 6
  const secondRowHeight = params.textFontSize + 2

  var parentDom = document.createElement('div')
  parentDom.setAttribute('style', 'display: flex; text-align: center; height: 100%; flex-direction: column; margin: 10px 10px;')
  parentDom.innerHTML = `<div style="display: flex; text-align: center; height: 34px">
  <div style="display: flex; justify-content: flex-start;align-items: center;  width: 100%; font-family: SonyEricssonLogo">
    <span class="target-number" style="font-size: ${params.numberFontSize}px ; color: ${params.numberColor};
    letter-spacing: ${params.letterSpacing}px; line-height: ${firstRowHeight}}px;
    text-shadow: 10px 4px 1px ${params.textShadowColor};"
          id="number-${data[0].value}">${data[0].value}</span>
  </div>
</div>
<div style="display: flex; text-align: center; margin-top: 8px">
  <div style="display: flex; justify-content: flex-start;align-items: center;  width: 100%; font-family: SonyEricssonLogo">
    <span class="target-number" style="font-size: ${params.textFontSize}px ; color: ${params.textColor};letter-spacing: ${params.letterSpacing}px; line-height: ${secondRowHeight}px;margin-left: ${params.letterSpacing}px"
          id="number-${data[0].value + 1}">${data[1].value}</span>
  </div>
  </div>`

  return parentDom
}
export default chart2
