import echarts from 'echarts'
import 'echarts/map/js/china' // 需要引入echarts 的地图文件
import { hideDelayTime, trigger } from '../chartCommonPart'

function MapFlowLine (element, props) {
  // 后端传入的数据，转化为 echarts自身定义的同类型， 不用改动echarts的输入数据类型，主要依靠后端代码处理数据格式
  const chinaGeoCoordMap = props.data[0].chinaGeoCoordMap
  const chinaDatas = props.data[0].many_sites
  const oneSite = props.data[0].one_site
  const maxValue = props.data[0].max_value
  const gradientColorStart = props.data[0].gradientColorStart
  const gradientColorEnd = props.data[0].gradientColorEnd
  const lineWidth = props.data[0].lineWidth
  const rippleScale = props.data[0].rippleScale

  const convertData = function (data, onePoint) {
    var res = []
    for (var i = 0; i < data.length; i++) {
      var dataItem = data[i]
      var toCoord = chinaGeoCoordMap[dataItem[0].name] // [{name: '甘肃', value: 0}]
      var fromCoord = onePoint // 定义了 oneSite 的具体坐标信息,特殊点
      if (fromCoord && toCoord) {
        res.push([{
          coord: fromCoord // 更换起终点坐标，控制流向
        }, {
          coord: toCoord,
          value: dataItem[0].value
        }])
      }
    }
    return res
  }
  const series = [];
  // 将 oneSite chinaDatas 组合成数组 {0：oneSite, 1: chianDatas}
  // oneSite ==>  图上的标识符号
  // 直接利用后端定义的字典的键值来获取对象
  [[oneSite.name, chinaDatas]].forEach(function (item, i) {
    // console.log(item)
    series.push({
      type: 'lines',
      zlevel: 2, // ?
      effect: {
        show: true,
        period: 5, // 箭头指向速度，值越小速度越快
        // constantSpeed: 30,  // 根据页面的像素来确定速度
        trailLength: 0, // 特效尾迹长度[0,1]值越大，尾迹越长重
        symbol: 'arrow', // 箭头图标
        symbolSize: 10 // 图标大小
      },
      lineStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0, color: gradientColorStart
          }, {
            offset: 1, color: gradientColorEnd
          }], false),
          width: lineWidth, // 尾迹线条宽度
          opacity: 0.6, // 尾迹线条透明度
          curveness: 0.2 // 尾迹线条曲直度
        }
      },
      data: convertData(item[1], oneSite.coord), // 处理的是 chinaDatas 的数据
      animation: false
    },
    {
      type: 'effectScatter',
      coordinateSystem: 'geo',
      showEffectOn: 'render',
      zlevel: 2,
      rippleEffect: { // 涟漪特效
        period: 7, // 动画时间，值越小速度越快
        brushType: 'stroke', // 波纹绘制方式 stroke, fill
        scale: rippleScale // 波纹圆环最大限制，值越大波纹越大
      },
      label: {
        normal: {
          show: false,
          position: 'right', // 显示位置
          offset: [5, 0], // 偏移设置
          formatter: function (params) { // 圆环显示文字
            return params.data.name
          },
          fontSize: 13
        },
        emphasis: {
          label: { show: false },
          show: true
        }
      },
      // 设置点的形状与大小
      symbol: 'circle',
      symbolSize: function (val) {
        return 1 + (val[2] / maxValue) * 0 // 每个地点的圆环大小
      },
      itemStyle: {
        normal: {
          show: false,
          color: '#ff8e51'
        }
      },
      data: item[1].map(function (dataItem) {
        return {
          name: dataItem[0].name,
          value: chinaGeoCoordMap[dataItem[0].name].concat([dataItem[0].value]) // 定义了 many_sites 的点的效果
        }
      })
    },
    {
      type: 'map',
      mapType: 'china',
      zoom: 1.2,
      roam: false, // 是否开启鼠标缩放和平移漫游
      label: {
        // 决定了高亮时是否显示地理名称
        show: false,
        textStyle: {
          color: 'transparent'
        }
      },
      emphasis: {
        label: {
          show: false
        }
      },
      itemStyle: { // 地图区域的多边形 图形样式
        normal: {
          areaColor: '#192C36',
          borderWidth: 1,
          borderType: 'solid',
          borderColor: '#167d7c'
        },
        emphasis: {
          // 是图形在高亮状态下的样式,比如在鼠标悬浮或者图例联动高亮时
          label: {
            show: false,
            color: '#fff'
          },
          borderWidth: 1,
          borderType: 'solid',
          // borderColor: 'rgba(81, 255, 254)',
          areaColor: 'transparent'
        }
      },
      data: chinaDatas
    }
    )
  })

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const option = {
    backgroundColor: 'rgba(0,0,0,0)',
    toolbox: {
      feature: {
        saveAsImage: {
          show: true,
          type: 'png',
          // 保存的图片的像素比例， 默认为1， 容器大小， 越大越清晰
          pixelRatio: 2
        }
      }
    },

    tooltip: {
      trigger: 'item',
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      // backgroundColor: 'rgba(166, 200, 76, 0.82)',
      // borderColor: '#FFFFCC',
      showDelay: 0,
      enterable: true,
      transitionDuration: 0,
      extraCssText: 'z-index:100',
      // 显示
      formatter: function (params) {
        // console.log(params)
        // 根据业务自己拓展要显示的内容
        var res = ''
        var name = params.name
        var value = params.value[params.seriesIndex + 1]
        if (params.seriesType === 'effectScatter') {
          res = "<span style='color:#fff;'>" + name + '</span><br/>数据：' + value
          return res
        } else if (params.seriesType === 'lines') {
          const currentIndex = params.dataIndex
          res = oneSite.name + '-->' + chinaDatas[currentIndex][0].name + ': ' + chinaDatas[currentIndex][0].value
          return res
        }
      }
    },
    geo: {
      map: 'china',
      zoom: 1.2,
      label: {
        show: false
      },
      itemStyle: { // 地图区域的多边形 图形样式
        normal: {
          areaColor: '#192C36',
          borderWidth: 3,
          borderColor: '#2BF5EF'
        }
      },
      emphasis: {
        label: {
          show: false
        },
        areaColor: '#192C36',
        borderWidth: 0
      },
      roam: false // 是否允许缩放
    },
    series: series
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default MapFlowLine
