import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function TreeMapEcharts (element, props, id) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const myChart = echarts.init(element.children[0])

  // 后端传入的数据，转化为 echarts自身定义的同类型， 不用改动echarts的输入数据类型，主要依靠后端代码处理数据格式
  const rawData = props.data[0].raw_data

  function convert (source, target, basePath) {
    for (var key in source) {
      var path = basePath ? (basePath + '.' + key) : key

      // 正则表达式 == > ^\\/$
      if (key.match(/^\$/)) {
      } else {
        target.children = target.children || []
        var child = {
          name: path
        }
        target.children.push(child)
        convert(source[key], child, path)
      }
    }
    if (!target.children) {
      target.value = source.$count || 1
    } else {
      target.children.push({
        name: basePath,
        value: source.$count
      })
    }
  }

  var data = []
  const rawData1 = rawData

  convert(rawData1, data, '')

  const option = {
    title: {
      text: 'ECharts 配置项查询分布',
      subtext: '2016/04',
      left: 'leafDepth'
    },
    tooltip: {
      triggerOn: trigger,
      hideDelay: hideDelayTime
    },
    series: [{
      name: 'option',
      type: 'treemap',
      visibleMin: 300,
      data: data.children,
      leafDepth: 2,
      animationDuration: 2000,
      levels: [
        {
          itemStyle: {
            normal: {
              borderColor: '#555',
              borderWidth: 4,
              gapWidth: 4
            }
          }
        },
        {
          colorSaturation: [0.3, 0.6],
          itemStyle: {
            normal: {
              borderColorSaturation: 0.7,
              gapWidth: 2,
              borderWidth: 2
            }
          }
        },
        {
          colorSaturation: [0.3, 0.5],
          itemStyle: {
            normal: {
              borderColorSaturation: 0.6,
              gapWidth: 1
            }
          }
        },
        {
          colorSaturation: [0.3, 0.5]
        }
      ]
    }]
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })
}

export default TreeMapEcharts
