// import $ from 'jquery'

const createDom = function (domType, className, text) {
  const oneDom = document.createElement(domType)
  if (className !== '') {
    oneDom.setAttribute('class', className)
  }
  if (text !== '') {
    oneDom.innerHTML = text
  }
  return oneDom
}

const chart1 = function (div, props) {
  const params = props.data[0]
  const legend = params.legend

  // let animation = 1500
  // let delayTime = params.delayTime
  const rowNum = 9
  const rowHeight = 40
  const rowGap = 0
  // let dis = parseInt(rowHeight) + parseInt(rowGap)
  // let moveDis = '-' + dis + 'px'
  const tableHeight = parseInt(rowNum) * parseInt(rowHeight)
  const rowWidthList = params.rowWidthList

  const onload = function () {
    const body = div[0][0].children[0]
    body.setAttribute(
      'style',
      'width: 100%;height: 100%; position: relative; background-color: ' +
      params.backgroundColor
    )
    const tableDom = createDom('div', 'movebackcolor-body', '')
    tableDom.setAttribute('style', 'height: ' + tableHeight + 'px')
    body.appendChild(tableDom)
    const upDom = createDom('div', 'table-scroll-up-0', '↑')
    const moveBody = createDom('div', 'table-scroll-body-0', '')
    moveBody.setAttribute('style', 'height: ' + tableHeight + 'px')
    tableDom.appendChild(upDom)
    tableDom.appendChild(moveBody)
    const ulDom = createDom('ul', '', '')
    moveBody.appendChild(ulDom)

    const createTable = function (data) {
      for (let i = 0; i < data.length; i++) {
        const rowData = data[i]
        createRow(ulDom, rowData)
      }
    }

    const createRow = function (parentDom, data) {
      const liDom = createDom('li', '', '')
      liDom.setAttribute('style', 'border-bottom: ' + rowGap + 'px solid transparent;')
      parentDom.appendChild(liDom)
      for (let i = 0; i < legend.length; i++) {
        const leg = legend[i]
        const rowWidth = rowWidthList[i]
        const value = data[leg]
        const spanDom = createDom('span', '', `${value}`)
        spanDom.setAttribute('style', 'line-height: ' + rowHeight + 'px; flex-grow: ' + rowWidth + ';')
        liDom.appendChild(spanDom)
      }
      // legend.forEach(element => {
      //   let value = data[element]
      //   let spanDom = createDom('span', '', `${value}`)
      //   spanDom.setAttribute('style', 'line-height: ' + rowHeight + 'px;')
      //   liDom.appendChild(spanDom)
      // })
    }

    createTable(params.data) // 创建图表dom节点

    // $(document).ready(function () {
    //   KeleyiScroll('.table-scroll-body-0', '.table-scroll-up-0')
    // })
    // // eslint-disable-next-line no-unused-vars
    // let AutoScroll = function (obj, statusobj) {
    //   if ($(statusobj).text() === '↑') {
    //     $(obj).find('ul:first').animate({
    //       marginTop: moveDis
    //     },
    //     animation,
    //     function () {
    //       $(this).css({
    //         marginTop: '0px'
    //       }).find('li:first').appendTo(this)
    //       //    appendTo： 将前一个对象添加到 后面对象的末尾
    //     })
    //   }
    // }
    // function KeleyiScroll (obj, statusobj) {
    //   setInterval(() => {
    //     AutoScroll(obj, statusobj)
    //   }, delayTime)
    //   $(obj).hover(function () {
    //     $(statusobj).text('=')
    //   },
    //   function () {
    //     $(statusobj).text('↑')
    //   })
    // }
  }

  return onload
}

export default chart1
