// import echarts from 'echarts'
import './numbertrendicon.css'

// import {EchartsSchemes} from '../echarts_theme/echarts_color_schemes'
function NumberTrendIcon (element, props) {
  // console.log(props.data)

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const data = props.data[0].data

  // 前缀符号, 后缀符号 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const unit = props.data[0].xAxisUnit || props.data[0].x_axis_unit
  const numberFontSize = props.data[0].numberFontSize
  const numberColor = props.data[0].numberColor
  const textFontSize = props.data[0].textFontSize
  const textColor = props.data[0].textColor
  const dataLength = data.length
  const colOfWidth = Math.ceil(100 / dataLength)
  const textGap = props.data[0].textGap
  const iconLeftGap = props.data[0].iconLeftGap
  const selectArrowIcon = props.data[0].selectArrowIcon
  const iconHeight = props.data[0].iconHeight
  const iconTopGap = props.data[0].iconTopGap

  // 生成dom节点
  // 生成dom节点
  onload = function () {
    var targetDiv = element.children[0]
    // target_div.setAttribute('style', 'background: #0b365a');
    targetDiv.appendChild(createNumber(data, colOfWidth))
    // console.log(target_div)
    for (var i = 0; i < dataLength; i++) {
      // 选择数字节点
      const targetNode = targetDiv.children[0].children[i].children[0]
      // console.log("targetNode: ", targetNode);
      // const NodeID = 'number' + i;
      myMove(targetNode)
    }
  }

  var createNumber = function (data, colWidth) {
    var NumberDiv = document.createElement('div')
    NumberDiv.setAttribute('style', 'display: flex; text-align: center; justify-content: space-around; align-items: center')
    for (var i = 0; i < dataLength; i++) {
      const eachData = data[i]
      // console.log(eachData)

      var NumberDivChild = document.createElement('div')
      NumberDivChild.setAttribute('style', 'display: flex;')

      var numberTarget = document.createElement('span')
      numberTarget.setAttribute('class', 'numner-trend')
      numberTarget.setAttribute('style', 'font-size: ' + numberFontSize + 'px ; color: ' + numberColor)
      numberTarget.setAttribute('id', 'number' + i)
      numberTarget.innerHTML = eachData.value

      var unitSuffix = document.createElement('span')
      unitSuffix.setAttribute('style', 'margin-left: ' + textGap + 'px; vertical-align: auto; color: ' + textColor + '; font-size: ' + textFontSize + 'px')
      unitSuffix.innerHTML = unit

      var iconBox = document.createElement('span')
      iconBox.setAttribute('style', 'display: flex; justify-content: center; align-items: center; margin-left: ' + iconLeftGap + 'px; margin-top: ' + iconTopGap + 'px')

      var iconImg = document.createElement('img')
      if (selectArrowIcon === 'down') {
        iconImg.setAttribute('src', '/static/img/chart-icon/bigDownIcon.png')
      } else if (selectArrowIcon === 'up') {
        iconImg.setAttribute('src', '/static/img/chart-icon/bigUpIcon.png')
      } else if (selectArrowIcon === '无') {
        iconImg.setAttribute('src', '')
      }
      iconImg.setAttribute('height', iconHeight + 'px')
      iconBox.appendChild(iconImg)

      // NumberDivChild.appendChild(unitPreifx);
      NumberDivChild.appendChild(numberTarget)
      NumberDivChild.appendChild(unitSuffix)
      NumberDivChild.appendChild(iconBox)

      NumberDiv.appendChild(NumberDivChild)
    }

    return NumberDiv
  }

  function myMove (targetnode) {
    var count = 0
    var result = ''
    // console.log(targetnode);
    var elem3 = targetnode
    var targetNum = elem3.innerText
    // var id3 = setInterval(ascIteration, 0.3);
    var move = setInterval(numAsc, 50)

    // TODO 新方法思路: 针对每一位进行递增
    // 1. 获取数字的每一位上的数字
    // 2. 每次 +1, 直到 除以 10 余数等于自身,停止,
    // 3. 每次运行结果 合并为新的字符

    function splitNum (str) {
      var numArray = []
      for (let i = 0; i < str.length; i++) {
        var currentStr = str.charAt(i)
        if (currentStr !== ',' && currentStr !== '.' && currentStr !== ',' && currentStr !== ' ') {
          var currentNum = parseInt(currentStr)
          // console.log(currentNum)
          currentNum += Math.round(Math.random() * 10)
          if (currentNum < 10) {
            numArray.push(currentNum)
          } else {
            currentNum = currentNum % 10
            // console.log('获取余数 ', currentNum);
            numArray.push(currentNum)
          }
        } else {
          numArray.push(currentStr)
        }
      }
      numArray.reverse()
      var res = ''
      for (var i = 0; i < numArray.length; i++) {
        res = res + numArray[i]
      }
      return res
    }

    function numAsc () {
      if (count < 20) {
        if (result === '') {
          result = targetNum
        }
        // console.log(result)
        result = splitNum(result)
        elem3.innerHTML = result
        count++
      } else {
        // console.log(result)
        elem3.innerHTML = targetNum
        clearInterval(move)
      }
    }
  }

  //  不能使用 setInterval 方法,本身就是一个递归方法
  //  props返回的内容增加 mode[edit show]  autoplay[True false],只有当 show, True时, 才执行循环刷新的方法
  const mode = props.mode
  const noPlay = false
  const autoPlay = props.autoPlay
  console.log(mode, autoPlay)
  if (mode === 'show' && autoPlay === true) {
    // isPlay = true
    onload(autoPlay)
  } else {
    onload(noPlay)
  }
  //  启动
  // onload()
}

export default NumberTrendIcon // 修改为Classname
