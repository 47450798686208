import { mapData, nameMap } from '../chart-12/data-source/chart-12'
import { visualmapPiece } from '../../../echartSetting/visualmap'
const mapChart = function (params) {
  const data = mapData[params.category]

  const newVisual = {
    left: params.targetMarginLeft,
    pieces: [
      { gte: 100000, label: '>=100000', color: 'rgba(67, 167, 253, 1)' },
      { gte: 10000, lte: 99999, label: '10000-99999', color: 'rgba(67, 167, 253, 0.8)' },
      { gte: 1000, lte: 9999, label: '1000-9999', color: 'rgba(67, 167, 253, 0.6)' },
      { gte: 100, lte: 999, label: '100-999', color: 'rgba(67, 167, 253, 0.4)' },
      { gte: 0, lte: 99, label: '0-99', color: 'rgba(67, 167, 253, 0.2)' }
    ],
    textStyle: {
      fontSize: 16,
      color: 'rgba(255, 255, 255, 1)'
    },
    selectedModel: 'single',
    itemWidth: 24,
    itemHeight: 16
  }
  const visualMapDict = Object.assign(visualmapPiece, newVisual)
  delete visualMapDict.text // 设置text 属性后, pieces 属性失效

  // console.log(visualMapDict);
  const option = {
    title: {
      show: false,
      text: '国际STEM教育研究国家（地区）分布',
      left: 'center',
      top: 'top'
    },
    tooltip: {
      show: false,
      enterable: true,
      // 背景设置为0 解决有圆点
      backgroundColor: 'rgba(50,50,50,0)',
      confine: true, // tooltip 不超出图表范围
      trigger: 'item',
      formatter: function (params, ticket, callback) {
        const name = params.name
        let value = params.value
        const data = params.data
        if (value === undefined || value === 'NaN' || data === undefined) {
          value = '无数据'
        }
        const html = `
          <div class="chart_3 tooltip_box">
            <h1>${name}</h1>
            <div class="tooltip_box_main">
              <span class="label">授权专利数</span>
              <span class="data">${value}</span>
            </div>
          </div>
        `
        return html
      }
    },
    visualMap: visualMapDict,
    series: [
      {
        name: 'wrold',
        type: 'map',
        mapType: 'world',
        roam: false,
        // zoom: targetZoom,
        label: {
          show: false,
          fontSize: 12,
          color: '#fff'
        },
        itemStyle: {
          areaColor: 'rgba(67, 167, 253, 0.1)',
          borderWidth: 0.5,
          borderColor: 'rgba(94, 194, 255, 1)'
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 12,
            color: '#fff'
          },
          itemStyle: {
            areaColor: 'rgba(22, 215, 255, 1)',
            borderWidth: 2
          }
        },
        data: data,
        nameMap: nameMap
      }
    ]
  }
  return option
}

export { mapChart }
