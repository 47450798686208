import echarts from 'echarts'
import 'echarts/map/js/china'
import 'echarts/map/js/province/hubei'
// import hubei from 'echarts/map/json/province/hubei.json'
// import {hideDelayTime, trigger} from '../chartCommonPart'

function Hubei (element, props) {
  const divId = 'echarts_Hubei'
  element.innerHTML = `<div id="${divId}" style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  var myChart = echarts.init(element.children[0])

  const moveLines = [{
    fromName: '武汉市',
    toName: '仙桃市',
    coords: [[114.298572, 30.584355], [113.453974, 30.364953]]
  }, {
    fromName: '武汉市',
    toName: '十堰市',
    coords: [[114.298572, 30.584355], [110.787916, 32.646907]]
  }, {
    fromName: '武汉市',
    toName: '咸宁市',
    coords: [[114.298572, 30.584355], [114.328963, 29.832798]]
  }, {
    fromName: '武汉市',
    toName: '天门市',
    coords: [[114.298572, 30.584355], [113.165862, 30.653061]]
  }, {
    fromName: '武汉市',
    toName: '孝感市',
    coords: [[114.298572, 30.584355], [113.926655, 30.926423]]
  }, {
    fromName: '武汉市',
    toName: '宜昌市',
    coords: [[114.298572, 30.584355], [111.290843, 30.702636]]
  }, {
    fromName: '武汉市',
    toName: '恩施土家族苗族自治州',
    coords: [[114.298572, 30.584355], [109.48699, 30.283114]]
  }, {
    fromName: '武汉市',
    toName: '潜江市',
    coords: [[114.298572, 30.584355], [112.896866, 30.421215]]
  }, {
    fromName: '武汉市',
    toName: '神农架林区',
    coords: [[114.298572, 30.584355], [110.651525, 31.524449]]
  }, {
    fromName: '武汉市',
    toName: '荆州市',
    coords: [[114.298572, 30.584355], [112.23813, 30.326857]]
  }, {
    fromName: '武汉市',
    toName: '荆门市',
    coords: [[114.298572, 30.584355], [112.404251, 31.03542]]
  }, {
    fromName: '武汉市',
    toName: '襄阳市',
    coords: [[114.298572, 30.584355], [112.144146, 32.042426]]
  }, {
    fromName: '武汉市',
    toName: '鄂州市',
    coords: [[114.298572, 30.584355], [114.890593, 30.396536]]
  }, {
    fromName: '武汉市',
    toName: '随州市',
    coords: [[114.298572, 30.584355], [113.37377, 31.717497]]
  }, {
    fromName: '武汉市',
    toName: '黄冈市',
    coords: [[114.298572, 30.584355], [115.179365, 30.8000000]]
  }, {
    fromName: '武汉市',
    toName: '黄石市',
    coords: [[114.298572, 30.584355], [115.077048, 30.000074]]
  }, {
    fromName: '仙桃市',
    toName: '宜昌市',
    coords: [[113.453974, 30.364953], [111.290843, 30.702636]]
  }, {
    fromName: '武汉市',
    toName: '仙桃市',
    coords: [[114.298572, 30.584355], [113.453974, 30.364953]]
  }, {
    fromName: '仙桃市',
    toName: '荆门市',
    coords: [[113.453974, 30.364953], [112.404251, 31.03542]]
  }, {
    fromName: '十堰市',
    toName: '咸宁市',
    coords: [[110.787916, 32.646907], [114.328963, 29.832798]]
  }, {
    fromName: '十堰市',
    toName: '孝感市',
    coords: [[110.787916, 32.646907], [113.926655, 30.926423]]
  }, {
    fromName: '武汉市',
    toName: '十堰市',
    coords: [[114.298572, 30.584355], [110.787916, 32.646907]]
  }, {
    fromName: '十堰市',
    toName: '神农架林区',
    coords: [[110.787916, 32.646907], [110.651525, 31.524449]]
  }, {
    fromName: '十堰市',
    toName: '荆州市',
    coords: [[110.787916, 32.646907], [112.23813, 30.326857]]
  }, {
    fromName: '十堰市',
    toName: '荆门市',
    coords: [[110.787916, 32.646907], [112.404251, 31.03542]]
  }, {
    fromName: '十堰市',
    toName: '襄阳市',
    coords: [[110.787916, 32.646907], [112.144146, 32.042426]]
  }, {
    fromName: '十堰市',
    toName: '随州市',
    coords: [[110.787916, 32.646907], [113.37377, 31.717497]]
  }, {
    fromName: '咸宁市',
    toName: '孝感市',
    coords: [[114.328963, 29.832798], [113.926655, 30.926423]]
  }, {
    fromName: '武汉市',
    toName: '咸宁市',
    coords: [[114.298572, 30.584355], [114.328963, 29.832798]]
  }, {
    fromName: '咸宁市',
    toName: '荆门市',
    coords: [[114.328963, 29.832798], [112.404251, 31.03542]]
  }, {
    fromName: '咸宁市',
    toName: '襄阳市',
    coords: [[114.328963, 29.832798], [112.144146, 32.042426]]
  }, {
    fromName: '咸宁市',
    toName: '黄冈市',
    coords: [[114.328963, 29.832798], [115.179365, 30.8000000]]
  }, {
    fromName: '天门市',
    toName: '孝感市',
    coords: [[113.165862, 30.653061], [113.926655, 30.926423]]
  }, {
    fromName: '武汉市',
    toName: '天门市',
    coords: [[114.298572, 30.584355], [113.165862, 30.653061]]
  }, {
    fromName: '天门市',
    toName: '荆门市',
    coords: [[113.165862, 30.653061], [112.404251, 31.03542]]
  }, {
    fromName: '孝感市',
    toName: '仙桃市',
    coords: [[113.926655, 30.926423], [113.453974, 30.364953]]
  }, {
    fromName: '孝感市',
    toName: '十堰市',
    coords: [[113.926655, 30.926423], [110.787916, 32.646907]]
  }, {
    fromName: '孝感市',
    toName: '咸宁市',
    coords: [[113.926655, 30.926423], [114.328963, 29.832798]]
  }, {
    fromName: '孝感市',
    toName: '宜昌市',
    coords: [[113.926655, 30.926423], [111.290843, 30.702636]]
  }, {
    fromName: '孝感市',
    toName: '恩施土家族苗族自治州',
    coords: [[113.926655, 30.926423], [109.48699, 30.283114]]
  }, {
    fromName: '武汉市',
    toName: '孝感市',
    coords: [[114.298572, 30.584355], [113.926655, 30.926423]]
  }, {
    fromName: '孝感市',
    toName: '潜江市',
    coords: [[113.926655, 30.926423], [112.896866, 30.421215]]
  }, {
    fromName: '孝感市',
    toName: '荆州市',
    coords: [[113.926655, 30.926423], [112.23813, 30.326857]]
  }, {
    fromName: '孝感市',
    toName: '荆门市',
    coords: [[113.926655, 30.926423], [112.404251, 31.03542]]
  }, {
    fromName: '孝感市',
    toName: '襄阳市',
    coords: [[113.926655, 30.926423], [112.144146, 32.042426]]
  }, {
    fromName: '孝感市',
    toName: '鄂州市',
    coords: [[113.926655, 30.926423], [114.890593, 30.396536]]
  }, {
    fromName: '孝感市',
    toName: '随州市',
    coords: [[113.926655, 30.926423], [113.37377, 31.717497]]
  }, {
    fromName: '孝感市',
    toName: '黄冈市',
    coords: [[113.926655, 30.926423], [115.179365, 30.8000000]]
  }, {
    fromName: '孝感市',
    toName: '黄石市',
    coords: [[113.926655, 30.926423], [115.077048, 30.000074]]
  }, {
    fromName: '宜昌市',
    toName: '仙桃市',
    coords: [[111.290843, 30.702636], [113.453974, 30.364953]]
  }, {
    fromName: '宜昌市',
    toName: '十堰市',
    coords: [[111.290843, 30.702636], [110.787916, 32.646907]]
  }, {
    fromName: '宜昌市',
    toName: '咸宁市',
    coords: [[111.290843, 30.702636], [114.328963, 29.832798]]
  }, {
    fromName: '宜昌市',
    toName: '天门市',
    coords: [[111.290843, 30.702636], [113.165862, 30.653061]]
  }, {
    fromName: '宜昌市',
    toName: '孝感市',
    coords: [[111.290843, 30.702636], [113.926655, 30.926423]]
  }, {
    fromName: '宜昌市',
    toName: '恩施土家族苗族自治州',
    coords: [[111.290843, 30.702636], [109.48699, 30.283114]]
  }, {
    fromName: '武汉市',
    toName: '宜昌市',
    coords: [[114.298572, 30.584355], [111.290843, 30.702636]]
  }, {
    fromName: '宜昌市',
    toName: '潜江市',
    coords: [[111.290843, 30.702636], [112.896866, 30.421215]]
  }, {
    fromName: '宜昌市',
    toName: '神农架林区',
    coords: [[111.290843, 30.702636], [110.651525, 31.524449]]
  }, {
    fromName: '宜昌市',
    toName: '荆州市',
    coords: [[111.290843, 30.702636], [112.23813, 30.326857]]
  }, {
    fromName: '宜昌市',
    toName: '荆门市',
    coords: [[111.290843, 30.702636], [112.404251, 31.03542]]
  }, {
    fromName: '宜昌市',
    toName: '襄阳市',
    coords: [[111.290843, 30.702636], [112.144146, 32.042426]]
  }, {
    fromName: '宜昌市',
    toName: '鄂州市',
    coords: [[111.290843, 30.702636], [114.890593, 30.396536]]
  }, {
    fromName: '宜昌市',
    toName: '随州市',
    coords: [[111.290843, 30.702636], [113.37377, 31.717497]]
  }, {
    fromName: '宜昌市',
    toName: '黄冈市',
    coords: [[111.290843, 30.702636], [115.179365, 30.8000000]]
  }, {
    fromName: '宜昌市',
    toName: '黄石市',
    coords: [[111.290843, 30.702636], [115.077048, 30.000074]]
  }, {
    fromName: '恩施土家族苗族自治州',
    toName: '十堰市',
    coords: [[109.48699, 30.283114], [110.787916, 32.646907]]
  }, {
    fromName: '恩施土家族苗族自治州',
    toName: '宜昌市',
    coords: [[109.48699, 30.283114], [111.290843, 30.702636]]
  }, {
    fromName: '武汉市',
    toName: '恩施土家族苗族自治州',
    coords: [[114.298572, 30.584355], [109.48699, 30.283114]]
  }, {
    fromName: '恩施土家族苗族自治州',
    toName: '神农架林区',
    coords: [[109.48699, 30.283114], [110.651525, 31.524449]]
  }, {
    fromName: '恩施土家族苗族自治州',
    toName: '荆门市',
    coords: [[109.48699, 30.283114], [112.404251, 31.03542]]
  }, {
    fromName: '恩施土家族苗族自治州',
    toName: '襄阳市',
    coords: [[109.48699, 30.283114], [112.144146, 32.042426]]
  }, {
    fromName: '恩施土家族苗族自治州',
    toName: '鄂州市',
    coords: [[109.48699, 30.283114], [114.890593, 30.396536]]
  }, {
    fromName: '恩施土家族苗族自治州',
    toName: '黄石市',
    coords: [[109.48699, 30.283114], [115.077048, 30.000074]]
  }, {
    fromName: '潜江市',
    toName: '咸宁市',
    coords: [[112.896866, 30.421215], [114.328963, 29.832798]]
  }, {
    fromName: '武汉市',
    toName: '潜江市',
    coords: [[114.298572, 30.584355], [112.896866, 30.421215]]
  }, {
    fromName: '武汉市',
    toName: '神农架林区',
    coords: [[114.298572, 30.584355], [110.651525, 31.524449]]
  }, {
    fromName: '荆州市',
    toName: '仙桃市',
    coords: [[112.23813, 30.326857], [113.453974, 30.364953]]
  }, {
    fromName: '荆州市',
    toName: '十堰市',
    coords: [[112.23813, 30.326857], [110.787916, 32.646907]]
  }, {
    fromName: '荆州市',
    toName: '咸宁市',
    coords: [[112.23813, 30.326857], [114.328963, 29.832798]]
  }, {
    fromName: '荆州市',
    toName: '天门市',
    coords: [[112.23813, 30.326857], [113.165862, 30.653061]]
  }, {
    fromName: '荆州市',
    toName: '孝感市',
    coords: [[112.23813, 30.326857], [113.926655, 30.926423]]
  }, {
    fromName: '荆州市',
    toName: '宜昌市',
    coords: [[112.23813, 30.326857], [111.290843, 30.702636]]
  }, {
    fromName: '荆州市',
    toName: '恩施土家族苗族自治州',
    coords: [[112.23813, 30.326857], [109.48699, 30.283114]]
  }, {
    fromName: '武汉市',
    toName: '荆州市',
    coords: [[114.298572, 30.584355], [112.23813, 30.326857]]
  }, {
    fromName: '荆州市',
    toName: '潜江市',
    coords: [[112.23813, 30.326857], [112.896866, 30.421215]]
  }, {
    fromName: '荆州市',
    toName: '荆门市',
    coords: [[112.23813, 30.326857], [112.404251, 31.03542]]
  }, {
    fromName: '荆州市',
    toName: '襄阳市',
    coords: [[112.23813, 30.326857], [112.144146, 32.042426]]
  }, {
    fromName: '荆州市',
    toName: '鄂州市',
    coords: [[112.23813, 30.326857], [114.890593, 30.396536]]
  }, {
    fromName: '荆州市',
    toName: '随州市',
    coords: [[112.23813, 30.326857], [113.37377, 31.717497]]
  }, {
    fromName: '荆州市',
    toName: '黄冈市',
    coords: [[112.23813, 30.326857], [115.179365, 30.8000000]]
  }, {
    fromName: '荆州市',
    toName: '黄石市',
    coords: [[112.23813, 30.326857], [115.077048, 30.000074]]
  }, {
    fromName: '荆门市',
    toName: '十堰市',
    coords: [[112.404251, 31.03542], [110.787916, 32.646907]]
  }, {
    fromName: '荆门市',
    toName: '咸宁市',
    coords: [[112.404251, 31.03542], [114.328963, 29.832798]]
  }, {
    fromName: '荆门市',
    toName: '天门市',
    coords: [[112.404251, 31.03542], [113.165862, 30.653061]]
  }, {
    fromName: '荆门市',
    toName: '宜昌市',
    coords: [[112.404251, 31.03542], [111.290843, 30.702636]]
  }, {
    fromName: '荆门市',
    toName: '恩施土家族苗族自治州',
    coords: [[112.404251, 31.03542], [109.48699, 30.283114]]
  }, {
    fromName: '武汉市',
    toName: '荆门市',
    coords: [[114.298572, 30.584355], [112.404251, 31.03542]]
  }, {
    fromName: '荆门市',
    toName: '潜江市',
    coords: [[112.404251, 31.03542], [112.896866, 30.421215]]
  }, {
    fromName: '荆门市',
    toName: '荆州市',
    coords: [[112.404251, 31.03542], [112.23813, 30.326857]]
  }, {
    fromName: '荆门市',
    toName: '襄阳市',
    coords: [[112.404251, 31.03542], [112.144146, 32.042426]]
  }, {
    fromName: '荆门市',
    toName: '鄂州市',
    coords: [[112.404251, 31.03542], [114.890593, 30.396536]]
  }, {
    fromName: '荆门市',
    toName: '黄冈市',
    coords: [[112.404251, 31.03542], [115.179365, 30.8000000]]
  }, {
    fromName: '荆门市',
    toName: '黄石市',
    coords: [[112.404251, 31.03542], [115.077048, 30.000074]]
  }, {
    fromName: '襄阳市',
    toName: '仙桃市',
    coords: [[112.144146, 32.042426], [113.453974, 30.364953]]
  }, {
    fromName: '襄阳市',
    toName: '十堰市',
    coords: [[112.144146, 32.042426], [110.787916, 32.646907]]
  }, {
    fromName: '襄阳市',
    toName: '咸宁市',
    coords: [[112.144146, 32.042426], [114.328963, 29.832798]]
  }, {
    fromName: '襄阳市',
    toName: '天门市',
    coords: [[112.144146, 32.042426], [113.165862, 30.653061]]
  }, {
    fromName: '襄阳市',
    toName: '孝感市',
    coords: [[112.144146, 32.042426], [113.926655, 30.926423]]
  }, {
    fromName: '襄阳市',
    toName: '宜昌市',
    coords: [[112.144146, 32.042426], [111.290843, 30.702636]]
  }, {
    fromName: '襄阳市',
    toName: '恩施土家族苗族自治州',
    coords: [[112.144146, 32.042426], [109.48699, 30.283114]]
  }, {
    fromName: '武汉市',
    toName: '襄阳市',
    coords: [[114.298572, 30.584355], [112.144146, 32.042426]]
  }, {
    fromName: '襄阳市',
    toName: '潜江市',
    coords: [[112.144146, 32.042426], [112.896866, 30.421215]]
  }, {
    fromName: '襄阳市',
    toName: '荆州市',
    coords: [[112.144146, 32.042426], [112.23813, 30.326857]]
  }, {
    fromName: '襄阳市',
    toName: '荆门市',
    coords: [[112.144146, 32.042426], [112.404251, 31.03542]]
  }, {
    fromName: '襄阳市',
    toName: '鄂州市',
    coords: [[112.144146, 32.042426], [114.890593, 30.396536]]
  }, {
    fromName: '襄阳市',
    toName: '随州市',
    coords: [[112.144146, 32.042426], [113.37377, 31.717497]]
  }, {
    fromName: '襄阳市',
    toName: '黄冈市',
    coords: [[112.144146, 32.042426], [115.179365, 30.8000000]]
  }, {
    fromName: '襄阳市',
    toName: '黄石市',
    coords: [[112.144146, 32.042426], [115.077048, 30.000074]]
  }, {
    fromName: '鄂州市',
    toName: '十堰市',
    coords: [[114.890593, 30.396536], [110.787916, 32.646907]]
  }, {
    fromName: '鄂州市',
    toName: '天门市',
    coords: [[114.890593, 30.396536], [113.165862, 30.653061]]
  }, {
    fromName: '鄂州市',
    toName: '孝感市',
    coords: [[114.890593, 30.396536], [113.926655, 30.926423]]
  }, {
    fromName: '鄂州市',
    toName: '宜昌市',
    coords: [[114.890593, 30.396536], [111.290843, 30.702636]]
  }, {
    fromName: '武汉市',
    toName: '鄂州市',
    coords: [[114.298572, 30.584355], [114.890593, 30.396536]]
  }, {
    fromName: '鄂州市',
    toName: '黄冈市',
    coords: [[114.890593, 30.396536], [115.179365, 30.8000000]]
  }, {
    fromName: '随州市',
    toName: '十堰市',
    coords: [[113.37377, 31.717497], [110.787916, 32.646907]]
  }, {
    fromName: '随州市',
    toName: '咸宁市',
    coords: [[113.37377, 31.717497], [114.328963, 29.832798]]
  }, {
    fromName: '随州市',
    toName: '天门市',
    coords: [[113.37377, 31.717497], [113.165862, 30.653061]]
  }, {
    fromName: '随州市',
    toName: '孝感市',
    coords: [[113.37377, 31.717497], [113.926655, 30.926423]]
  }, {
    fromName: '随州市',
    toName: '宜昌市',
    coords: [[113.37377, 31.717497], [111.290843, 30.702636]]
  }, {
    fromName: '随州市',
    toName: '恩施土家族苗族自治州',
    coords: [[113.37377, 31.717497], [109.48699, 30.283114]]
  }, {
    fromName: '武汉市',
    toName: '随州市',
    coords: [[114.298572, 30.584355], [113.37377, 31.717497]]
  }, {
    fromName: '随州市',
    toName: '潜江市',
    coords: [[113.37377, 31.717497], [112.896866, 30.421215]]
  }, {
    fromName: '随州市',
    toName: '荆州市',
    coords: [[113.37377, 31.717497], [112.23813, 30.326857]]
  }, {
    fromName: '随州市',
    toName: '荆门市',
    coords: [[113.37377, 31.717497], [112.404251, 31.03542]]
  }, {
    fromName: '随州市',
    toName: '襄阳市',
    coords: [[113.37377, 31.717497], [112.144146, 32.042426]]
  }, {
    fromName: '随州市',
    toName: '黄冈市',
    coords: [[113.37377, 31.717497], [115.179365, 30.8000000]]
  }, {
    fromName: '随州市',
    toName: '黄石市',
    coords: [[113.37377, 31.717497], [115.077048, 30.000074]]
  }, {
    fromName: '黄冈市',
    toName: '十堰市',
    coords: [[115.179365, 30.8000000], [110.787916, 32.646907]]
  }, {
    fromName: '黄冈市',
    toName: '孝感市',
    coords: [[115.179365, 30.8000000], [113.926655, 30.926423]]
  }, {
    fromName: '黄冈市',
    toName: '宜昌市',
    coords: [[115.179365, 30.8000000], [111.290843, 30.702636]]
  }, {
    fromName: '黄冈市',
    toName: '恩施土家族苗族自治州',
    coords: [[115.179365, 30.8000000], [109.48699, 30.283114]]
  }, {
    fromName: '武汉市',
    toName: '黄冈市',
    coords: [[114.298572, 30.584355], [115.179365, 30.8000000]]
  }, {
    fromName: '黄冈市',
    toName: '荆州市',
    coords: [[115.179365, 30.8000000], [112.23813, 30.326857]]
  }, {
    fromName: '黄冈市',
    toName: '鄂州市',
    coords: [[115.179365, 30.8000000], [114.890593, 30.396536]]
  }, {
    fromName: '黄冈市',
    toName: '随州市',
    coords: [[115.179365, 30.8000000], [113.37377, 31.717497]]
  }, {
    fromName: '黄冈市',
    toName: '黄石市',
    coords: [[115.179365, 30.8000000], [115.077048, 30.000074]]
  }, {
    fromName: '黄石市',
    toName: '仙桃市',
    coords: [[115.077048, 30.000074], [113.453974, 30.364953]]
  }, {
    fromName: '黄石市',
    toName: '十堰市',
    coords: [[115.077048, 30.000074], [110.787916, 32.646907]]
  }, {
    fromName: '黄石市',
    toName: '咸宁市',
    coords: [[115.077048, 30.000074], [114.328963, 29.832798]]
  }, {
    fromName: '黄石市',
    toName: '天门市',
    coords: [[115.077048, 30.000074], [113.165862, 30.653061]]
  }, {
    fromName: '黄石市',
    toName: '孝感市',
    coords: [[115.077048, 30.000074], [113.926655, 30.926423]]
  }, {
    fromName: '黄石市',
    toName: '宜昌市',
    coords: [[115.077048, 30.000074], [111.290843, 30.702636]]
  }, {
    fromName: '黄石市',
    toName: '恩施土家族苗族自治州',
    coords: [[115.077048, 30.000074], [109.48699, 30.283114]]
  }, {
    fromName: '武汉市',
    toName: '黄石市',
    coords: [[114.298572, 30.584355], [115.077048, 30.000074]]
  }, {
    fromName: '黄石市',
    toName: '潜江市',
    coords: [[115.077048, 30.000074], [112.896866, 30.421215]]
  }, {
    fromName: '黄石市',
    toName: '荆州市',
    coords: [[115.077048, 30.000074], [112.23813, 30.326857]]
  }, {
    fromName: '黄石市',
    toName: '荆门市',
    coords: [[115.077048, 30.000074], [112.404251, 31.03542]]
  }, {
    fromName: '黄石市',
    toName: '襄阳市',
    coords: [[115.077048, 30.000074], [112.144146, 32.042426]]
  }, {
    fromName: '黄石市',
    toName: '鄂州市',
    coords: [[115.077048, 30.000074], [114.890593, 30.396536]]
  }, {
    fromName: '黄石市',
    toName: '随州市',
    coords: [[115.077048, 30.000074], [113.37377, 31.717497]]
  }, { fromName: '黄石市', toName: '黄冈市', coords: [[115.077048, 30.000074], [115.179365, 30.8000000]] }]

  const cityPoint = [
    { name: '武汉市', value: [114.298572, 30.584355], symbolSize: 10, itemStyle: { normal: { color: '#36FFFC' } } },
    { name: '仙桃市', value: [113.453974, 30.364953], symbolSize: 5, itemStyle: { normal: { color: '#FBBC31' } } },
    { name: '十堰市', value: [110.787916, 32.646907], symbolSize: 5, itemStyle: { normal: { color: '#36FFFC' } } },
    { name: '咸宁市', value: [114.328963, 29.832798], symbolSize: 5, itemStyle: { normal: { color: '#FBBC31' } } },
    { name: '天门市', value: [113.165862, 30.653061], symbolSize: 5, itemStyle: { normal: { color: '#FBBC31' } } },
    { name: '孝感市', value: [113.926655, 30.926423], symbolSize: 5, itemStyle: { normal: { color: '#36FFFC' } } },
    { name: '宜昌市', value: [111.290843, 30.702636], symbolSize: 5, itemStyle: { normal: { color: '#36FFFC' } } },
    {
      name: '恩施土家族苗族自治州',
      value: [109.48699, 30.283114],
      symbolSize: 5,
      itemStyle: { normal: { color: '#36FFFC' } }
    },
    { name: '潜江市', value: [112.896866, 30.421215], symbolSize: 5, itemStyle: { normal: { color: '#FBBC31' } } },
    { name: '神农架林区', value: [110.651525, 31.524449], symbolSize: 5, itemStyle: { normal: { color: '#FBBC31' } } },
    { name: '荆州市', value: [112.23813, 30.326857], symbolSize: 5, itemStyle: { normal: { color: '#36FFFC' } } },
    { name: '荆门市', value: [112.404251, 31.03542], symbolSize: 5, itemStyle: { normal: { color: '#36FFFC' } } },
    { name: '襄阳市', value: [112.144146, 32.042426], symbolSize: 5, itemStyle: { normal: { color: '#36FFFC' } } },
    { name: '鄂州市', value: [114.890593, 30.396536], symbolSize: 5, itemStyle: { normal: { color: '#FBBC31' } } },
    { name: '随州市', value: [113.37377, 31.717497], symbolSize: 5, itemStyle: { normal: { color: '#36FFFC' } } },
    { name: '黄冈市', value: [115.179365, 30.8000000], symbolSize: 5, itemStyle: { normal: { color: '#36FFFC' } } },
    { name: '黄石市', value: [115.077048, 30.000074], symbolSize: 5, itemStyle: { normal: { color: '#36FFFC' } } }]

  const option = {
    backgroundColor: 'rgba(1,1,1,0)',
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {
          type: 'png',
          backgroundColor: 'rgba(255,255,255,0)',
          pixelRatio: 3

        }
      }
    },
    geo: {
      map: '湖北',
      left: '10',
      right: '10',
      top: '10',
      bottom: '10',
      // center:[114.298572, 30.584355],
      label: {
        show: false,
        textStyle: {
          color: '#ff0'
        }
      },
      itemStyle: { // 地图区域的多边形 图形样式
        normal: {
          // areaColor: '#192C36',
          areaColor: 'rgba(1,1,1,0)',
          borderWidth: 0.1,
          // borderColor: '#2BF5EF',
          borderColor: 'rgba(1,1,1,0)'
        },
        emphasis: {
          label: {
            show: false
          },
          // areaColor: '#192C36',
          areaColor: 'rgba(1,1,1,0)',
          borderWidth: 0
        }
      }
    },
    series: [
      {
        type: 'map',
        mapType: '湖北',
        roam: false, // 是否开启鼠标缩放和平移漫游
        label: {
          // 决定了高亮时是否显示地理名称
          show: false,
          textStyle: {
            color: 'transparent'
          }
        },
        itemStyle: { // 地图区域的多边形 图形样式
          normal: {
            // areaColor: '#192C36',
            areaColor: 'rgba(1,1,1,0)',
            borderWidth: 1,
            borderType: 'solid',
            // borderColor: '#167d7c'
            borderColor: 'rgba(1,1,1,0)'
          },
          emphasis: {
            // 是图形在高亮状态下的样式,比如在鼠标悬浮或者图例联动高亮时
            borderWidth: 0,
            borderType: 'solid',
            borderColor: 'rgba(1,1,1,0)',
            areaColor: 'transparent'
          }
        }
      },
      {
        name: '地点',
        type: 'effectScatter',
        coordinateSystem: 'geo',
        zlevel: 2,
        rippleEffect: {
          brushType: 'stroke'
        },
        label: {
          emphasis: {
            show: true,
            position: 'right',
            formatter: '{b}'
          }
        },
        symbolSize: 2,
        showEffectOn: 'render',
        itemStyle: {
          normal: {
            color: '#46bee9'
          }
        },
        data: cityPoint
      },
      {
        name: '线路',
        type: 'lines',
        coordinateSystem: 'geo',
        zlevel: 2,
        large: true,
        effect: {
          show: true,
          constantSpeed: 30,
          symbol: 'pin',
          symbolSize: 3,
          trailLength: 0
        },
        lineStyle: {
          normal: {
            color: {
              type: 'linear',
              x: 1,
              y: 0,
              x2: 0,
              y2: 0,
              colorStops: [{
                offset: 0, color: '#58B3CC' // 0% 处的颜色
              }, {
                offset: 1, color: '#ffbf31' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            },
            width: 1,
            opacity: 0.3,
            curveness: 0.1
          }
        },
        data: moveLines
      }
    ]
  }

  // 使用上述配置显示图标
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default Hubei
