import Vue from 'vue'
import './weatherPredict.css'
// import { supersetUrl } from '~/api/config'
// import axios from 'axios'
import allChart from './all'

// let intervalList = []

function ChartFunc (element, props) {
  // const contentWidth = props.data[0].parentWidth
  // const contentHeight = props.data[0].parentHeight

  // const backgroundColor = props.data[0].backgroundColor
  // const siteNamePY = props.data[0].siteNamePY
  const selectedChart = allChart(props.data[0].barStandardSelect)

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const body = element.children[0]
  // body.setAttribute('style', 'width: 100%;height: 100%; overflow: hidden; position: relative; background-color: ' + backgroundColor)
  // 初始化

  // eslint-disable-next-line no-unused-vars
  const baseDom1 = new Vue({
    el: body,
    extends: selectedChart,
    data () {
      return {
        options: [],
        value: '',
        frontDict: props.data[0]
      }
    },
    props: {}
  })
}

export default ChartFunc // 修改为Classname
