<template>
  <div class="preview">
    <template v-if="type === 'demo'">
      <p class="preview_btn" @click="onPreview('demo')">
        <i class="iconfont iconyulan"></i>
        <span class="text_size_14 margin-left-5">预览样本数据</span>
      </p>
    </template>
    <template v-if="type === 'query'">
      <p class="preview_btn" @click="onPreview('query')">
        <i class="iconfont iconyulan"></i>
        <span class="text_size_14 margin-left-5">预览查询数据</span>
      </p>
    </template>
    <el-dialog :title="dialogTitle" :visible="dialogVisible" width="820px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="table">
        <vue-scroll>
          <div style="position:absolute;min-width:100%;width: -webkit-fit-content;">
            <el-table :data="tableData.allData" class="my-el-table" v-loading="tableLoading" element-loading-background="rgba(24,34,45,0.9)">
              <el-table-column v-for="item in tableData.columns" :key="item" :prop="item" :label="item" sortable></el-table-column>
            </el-table>
          </div>
        </vue-scroll>
      </div>
      <div slot="footer">
        <el-button type="primary" size="small" @click="dialogVisible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    tableLoading: Boolean,
    tableData: Object
  },
  data () {
    return {
      dialogType: '',
      dialogVisible: false
    }
  },
  computed: {
    dialogTitle () {
      let title = ''
      switch (this.dialogType) {
        case 'demo':
          title = '展示样本数据'
          break
        case 'query':
          title = '展示查询结果数据'
          break
      }
      return title
    }
  },
  methods: {
    onPreview (type) {
      this.dialogType = type
      this.dialogVisible = true
      if (type === 'query') {
        this.$emit('on-preview')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.preview {
  color: $color-text-primary;
  height: 30px;
  display: flex;
  align-items: center;
  &_btn {
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      color: $color-primary;
    }
  }
}
.table {
  height: 550px;
  margin: 0 20px;
}
</style>
