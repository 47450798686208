import echarts from 'echarts'
import './PicCarouselFour.css'

const activeIndexToListIndex = {
  4: 2,
  5: 0,
  6: 1
}

function downChart (targetDom, originData, xAxisData, maxValuesList, minValuesList, index) {
  const realindex = activeIndexToListIndex[index]
  const seriesData = originData[realindex]
  const myChart = echarts.init(targetDom)
  const option = {
    // backgroundColor: '',
    tooltip: {
      trigger: 'axis'
    },
    xAxis: [{
      type: 'category',
      data: xAxisData,
      axisTick: {
        show: false
      },
      axisLabel: {
        // rotate: '-45',
        margin: 10,
        textStyle: {
          fontSize: 20,
          color: 'rgba(255, 255, 255, 0.6)'
        }
      },
      axisLine: {
        lineStyle: {
          width: 1,
          color: 'rgba(174, 238, 237, 0)'
        }
      },
      splitLine: {
        show: false
      }
    }],
    yAxis: [
      {
        type: 'value',
        name: '',
        nameGap: 10,
        max: maxValuesList[realindex],
        min: minValuesList[realindex],
        minInterval: 0.2,
        axisTick: {
          show: false
        },
        axisLabel: {
          show: true,
          margin: 10,
          fontSize: 20,
          color: 'rgba(255, 255, 255, 0.6)'
        },
        nameTextStyle: {
          fontSize: 20,
          color: 'rgba(255, 255, 255, 0.6)'
        },
        axisLine: {
          lineStyle: {
            width: 1,
            color: 'rgba(174, 238, 237, 0.3)'
          }
        },
        splitLine: {
          show: true,
          interval: 2,
          lineStyle: {
            width: 1,
            type: 'solid',
            color: 'rgba(174, 238, 237, 0.3)'
          }
        }
      },
      {
        type: 'value',
        axisTick: {
          show: false
        },
        show: true,
        // name: secondyYAxisUnit,
        // 垂直方向的距离
        nameGap: 10,
        max: 100,
        // max: 0.6,
        yAxisIndex: 0,
        position: 'right',
        axisLabel: {
          show: false,
          margin: 10,
          fontSize: 20
        },
        nameTextStyle: {
          fontSize: 20
          // color: axisFontColor
        },
        axisLine: {
          lineStyle: {
            width: 1,
            color: 'rgba(174, 238, 237, 0.3)'
          }
        },
        splitLine: {
          show: false
        }
      }
    ],
    grid: {
      left: 20,
      right: 20,
      top: 30,
      buttom: 20,
      containLabel: true
    },
    series: [{
      name: '轮播图',
      type: 'line',
      // 设置圆点大小: 使用图标设置节点样式
      symbol: 'circle',
      symbolSize: 40, // 设置图标的大小
      data: seriesData,
      label: {
        show: true,
        fontSize: 30,
        fontFamily: 'Helvetica Narrow',
        color: '#9EDCAD'
      },
      lineStyle: {
        normal: {
          width: 2,
          color: '#9EDCAD',
          shadowColor: 'rgba(72,216,191, 0.3)',
          shadowBlur: 10,
          shadowOffsetY: 20
        }
      },
      itemStyle: {
        // 设置转折圆点的样式
        color: 'rgba(255,255,255,0)', // 设置圆点的颜色
        borderColor: '#A9F387',
        borderWidth: 2 // 设置圆点边线的宽度
      },
      smooth: false
    }, {
      name: 'pictorialBar',
      type: 'pictorialBar',
      symbol: 'circle',
      symbolPosition: 'end',
      symbolSize: [12, 12],
      symbolOffset: [0, -6],
      z: 2,
      tooltip: {
        show: false
      },
      itemStyle: {
        normal: {
          color: '#A9F387'
        }
      },
      data: seriesData
    }

    ]
  }
  myChart.setOption(option)
}

export default downChart
