import echarts from 'echarts'
import 'echarts-gl' // bar3d 图需要引入该文件
import { hideDelayTime, trigger } from '../chartCommonPart'

function Bar3d (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  // 初始化
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  const myChart = echarts.init(element.children[0])
  // eushow 3.0
  // const myChart = echarts.init(div[0][0].children[0], props.colorTheme)

  const columns = props.data[0].cols
  const data = props.data[0].all_data
  const max = props.data[0].max_value

  const option = {
    grid3D: {
      lfet: '30px',
      right: 15,
      top: 15,
      bottom: 20
    },
    tooltip: {
      triggerOn: trigger,
      hideDelay: hideDelayTime
    },
    xAxis3D: {
      type: 'category',
      nameGap: 15,
      nameTextStyle: {
        color: '#fff'
      },
      splitLine: {
        lineStyle: {
          width: 1,
          color: 'rgb(195,195,195)'
        }
      },
      axisLabel: {
        // show: false,
        // interval: 5,
        rotate: 30,
        textStyle: {
          fontSize: 14,
          color: 'rgb(195,195,195)'
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgb(195,195,195)'
          // width: 1
        }
      }
    },
    yAxis3D: {
      type: 'category',
      nameGap: 15,
      nameTextStyle: {
        color: '#fff'
      },
      splitLine: {
        lineStyle: {
          width: 1,
          color: 'rgb(195,195,195)'
        }
      },
      axisLabel: {
        // show: false,
        // interval: 5,
        rotate: 30,
        textStyle: {
          fontSize: 14,
          color: 'rgb(195,195,195)'
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgb(195,195,195)'
          // width: 1
        }
      }
    },
    zAxis3D: {
      nameTextStyle: {
        color: '#fff'
      },
      splitLine: {
        lineStyle: {
          width: 1,
          color: 'rgb(195,195,195)'
        }
      },
      axisLabel: {
        // show: false,
        // interval: 5,
        rotate: 30,
        textStyle: {
          fontSize: 14,
          color: 'rgb(195,195,195)'
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgb(195,195,195)'
          // width: 1
        }
      }
    },
    visualMap: {
      max: max, // 最大值，根据 columns[4]
      dimension: columns[4]
    },
    dataset: {
      dimensions: [
        // 这里顺序应该与 字段名称保持一致.
        { name: columns[0], type: 'ordinal' },
        columns[1],
        columns[2],
        columns[3],
        columns[4]
      ],
      source: data
    },
    series: [
      {
        type: 'bar3D',
        // symbolSize: symbolSize,
        shading: 'lambert',
        encode: {
          x: columns[0],
          y: columns[1],
          z: columns[3],
          tooltip: [0, 1, 2, 3, 4]
        },
        animationDuration: 2000
      }
    ]
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  var startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default Bar3d
