import echarts from 'echarts'
import 'echarts/map/js/china'
import 'echarts/map/js/province/hubei'
// import hubei from 'echarts/map/json/province/hubei.json'
import { hideDelayTime, trigger } from '../chartCommonPart'

import { API_BASE_URL } from '~/config'

function HubeiMap (element, props) {
  const divId = 'echarts_Hubei_Map'
  // 建立chart图表的容器， 在HTML中生成div标签 用来插入表格
  element.innerHTML = `<div id="${divId}" style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  var myChart = echarts.init(element.children[0])

  var geoCoordMap = {
    武汉市: [114.298572, 30.584355],
    黄石市: [115.077048, 30.220074],
    十堰市: [110.787916, 32.646907],
    宜昌市: [111.290843, 30.702636],
    襄阳市: [112.144146, 32.042426],
    // '鄂州市': [114.890593, 30.396536],
    荆门市: [112.204251, 31.03542],
    孝感市: [113.926655, 30.926423],
    荆州市: [112.23813, 30.326857],
    黄冈市: [114.879365, 30.447711],
    咸宁市: [114.328963, 29.832798],
    随州市: [113.37377, 31.717497],
    恩施土家族苗族自治州: [109.48699, 30.283114],
    仙桃市: [113.453974, 30.364953],
    潜江市: [112.896866, 30.421215],
    天门市: [113.165862, 30.653061]
    // '神农架林区': [110.671525, 31.744449],
  }
  var data = [
    {
      name: '武汉市',
      value: 71
    },
    {
      name: '黄石市',
      value: 72
    }, {
      name: '十堰市',
      value: 73
    }, {
      name: '宜昌市',
      value: 74
    }, {
      name: '襄阳市',
      value: 75
    },
    {
      name: '鄂州市',
      value: 76
    },
    {
      name: '荆门市',
      value: 77
    }, {
      name: '孝感市',
      value: 78
    }, {
      name: '荆州市',
      value: 79
    }, {
      name: '黄冈市',
      value: 80
    }, {
      name: '咸宁市',
      value: 81
    }, {
      name: '随州市',
      value: 82
    }, {
      name: '恩施土家族苗族自治州',
      value: 83
    }, {
      name: '仙桃市',
      value: 84
    }, {
      name: '潜江市',
      value: 85
    }, {
      name: '天门市',
      value: 86
    },
    {
      name: '神农架林区',
      value: 87
    }
  ]

  var siteData = [
    {
      name: '武汉市',
      value: 71
    }, {
      name: '十堰市',
      value: 73
    }, {
      name: '宜昌市',
      value: 74
    }, {
      name: '襄阳市',
      value: 75
    }, {
      name: '咸宁市',
      value: 81
    }, {
      name: '荆州市',
      value: 79
    }, {
      name: '孝感市',
      value: 78
    }, {
      name: '黄冈市',
      value: 80
    }
  ]

  var convertData = function (data) {
    var res = []
    for (var i = 0; i < data.length; i++) {
      var SiteName = data[i].name
      if (SiteName !== '武汉市') {
        var geoCoord = geoCoordMap[data[i].name]
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value)
          })
        }
      }
    }
    return res
  }

  const option = {
    tooltip: {
      trigger: 'item',
      // backgroundColor: 'rgba(234,234,234,0.13)',
      // borderColor: '#FFFFCC',
      showDelay: 0,
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      enterable: true,
      transitionDuration: 0,
      extraCssText: 'z-index:100',
      formatter: function (params, ticket, callback) {
        // 根据业务自己拓展要显示的内容
        var seriesType = params.seriesType
        console.log('params', params)
        // 判断当前图像类别
        if (seriesType === 'scatter') {
          // ProvinceName = MapData[name_object].name;
          return '技术合同交易金额' + ': ' + params.value[2]
        }
      }
    },
    visualMap: { // 颜色的设置  dataRange
      show: false,
      x: 'left',
      y: 'center',
      seriesIndex: [2],
      min: 71,
      max: 87,
      text: ['高', '低'], // 文本，默认为数值文本
      textStyle: {
        color: '#fff'
      },
      inRange: {
        color: [
          'rgba(49, 153, 221,1)', // 武汉
          'rgba(5, 61, 97, 1)', // 黄石
          'rgba(5, 61, 97, 1)', // 十堰市
          'rgba(49, 153, 221,1)', // 宜昌
          'rgba(49, 153, 221,1)', // 襄阳市
          'rgba(22, 104, 161, 1)', // 鄂州市
          'rgba(22, 104, 161, 1)', // 荆门市
          'rgba(49, 153, 221,1)', // 孝感市
          'rgba(22, 104, 161, 1)', // 荆州市
          'rgba(22, 104, 161, 1)', // 黄冈市
          'rgba(5, 61, 97, 1)', // 咸宁市
          'rgba(5, 61, 97, 1)', // 随州市
          'rgba(5, 61, 97, 1)', // 恩施土家族苗族自治州
          'rgba(22, 104, 161, 1)', // 仙桃市
          'rgba(22, 104, 161, 1)', // 潜江市
          'rgba(22, 104, 161, 1)', // 天门市
          'rgba(5, 61, 97, 1)'// 神农架林区
        ]
      }
    },
    geo: {
      map: '湖北',
      // left: '500',
      // center:[114.298572, 30.584355],
      label: {
        show: false
      },
      itemStyle: { // 地图区域的多边形 图形样式
        color: '#00E1E7'
        // normal: { // 是图形在默认状态下的样式
        // borderWidth: 1,
        // borderColor: 'rgba(37,124,169)',
        //   shadowColor: '#4c4c4c',
        //   shadowOffsetY: 15,
        //   shadowOffsetX: 8,
        // }
      }
    },
    series: [
      {
        name: 'credit_pm2.5',
        type: 'scatter',
        // left: '150',
        coordinateSystem: 'geo',
        data: convertData(siteData),
        // symbol: "path://M54.679612 140.427184a457.320388 258.485437 0 1 1 914.640776 0 457.320388 258.485437 0 1 1-914.640776 0ZM512-128C254.309903-128 44.737864-7.506019 44.737864 140.427184s209.572039 268.427184 467.262136 268.427185S979.262136 288.360388 979.262136 140.427184 769.690097-128 512-128z m0 516.970874C265.245825 388.970874 64.621359 277.424466 64.621359 140.427184s200.624466-248.543689 447.378641-248.543689S959.378641 3.429903 959.378641 140.427184 758.754175 388.970874 512 388.970874zM273.398058 180.194175a238.601942 139.184466 0 1 1 477.203884 0 238.601942 139.184466 0 1 1-477.203884 0ZM512 31.067961C375.002718 31.067961 263.456311 97.876505 263.456311 180.194175s111.546408 149.126214 248.543689 149.126213S760.543689 262.511845 760.543689 180.194175s-111.546408-149.126214-248.543689-149.126214z m0 278.368932C385.938641 309.436893 283.339806 251.377087 283.339806 180.194175s102.598835-129.242718 228.660194-129.242719S740.660194 109.011262 740.660194 180.194175s-102.598835 129.242718-228.660194 129.242718zM512 896a298.252427 298.252427 0 0 1-298.252427-298.252427c0-164.834175 238.601942-397.669903 298.252427-397.669903s298.252427 232.835728 298.252427 397.669903a298.252427 298.252427 0 0 1-298.252427 298.252427z m0-447.378641A149.126214 149.126214 0 1 0 661.126214 597.747573a149.126214 149.126214 0 0 0-149.126214-149.126214z",
        symbol: `image://${API_BASE_URL}/assets/images/SiteSilver.png`,
        symbolSize: function (val) {
          var iconWidth = val[2] / 3
          var iconHeight = val[2] / 3 * 33 / 26
          return [iconWidth, iconHeight]
        },
        // 图表旋转角度
        // symbolRotate: 180,
        symbolKeepAspect: true,
        // showEffectOn: 'render',
        // rippleEffect: {
        //   brushType: 'stroke'
        // },
        // hoverAnimation: true,
        label: {
          normal: {
            formatter: '{b}',
            position: 'bottom',
            color: '#fff',
            show: true
          }
        },
        // itemStyle: {
        //   normal: {
        //     color: '#c8d8ff',
        //     // shadowBlur: 0,
        //     // shadowColor: '#05C3F9'
        //   }
        // },
        z: 10,
        animationDuration: 2000
      },
      {
        name: 'center',
        type: 'scatter',
        coordinateSystem: 'geo',
        data: [{ name: '武汉', value: [114.298572, 30.584355, 130] }],
        // symbol: "path://M54.679612 140.427184a457.320388 258.485437 0 1 1 914.640776 0 457.320388 258.485437 0 1 1-914.640776 0ZM512-128C254.309903-128 44.737864-7.506019 44.737864 140.427184s209.572039 268.427184 467.262136 268.427185S979.262136 288.360388 979.262136 140.427184 769.690097-128 512-128z m0 516.970874C265.245825 388.970874 64.621359 277.424466 64.621359 140.427184s200.624466-248.543689 447.378641-248.543689S959.378641 3.429903 959.378641 140.427184 758.754175 388.970874 512 388.970874zM273.398058 180.194175a238.601942 139.184466 0 1 1 477.203884 0 238.601942 139.184466 0 1 1-477.203884 0ZM512 31.067961C375.002718 31.067961 263.456311 97.876505 263.456311 180.194175s111.546408 149.126214 248.543689 149.126213S760.543689 262.511845 760.543689 180.194175s-111.546408-149.126214-248.543689-149.126214z m0 278.368932C385.938641 309.436893 283.339806 251.377087 283.339806 180.194175s102.598835-129.242718 228.660194-129.242719S740.660194 109.011262 740.660194 180.194175s-102.598835 129.242718-228.660194 129.242718zM512 896a298.252427 298.252427 0 0 1-298.252427-298.252427c0-164.834175 238.601942-397.669903 298.252427-397.669903s298.252427 232.835728 298.252427 397.669903a298.252427 298.252427 0 0 1-298.252427 298.252427z m0-447.378641A149.126214 149.126214 0 1 0 661.126214 597.747573a149.126214 149.126214 0 0 0-149.126214-149.126214z",
        symbol: `image://${API_BASE_URL}/assets/images//site.png`,
        symbolSize: function (val) {
          // 按照比例进行缩放处理
          var iconWidth = val[2] / 3
          var iconHeight = val[2] / 3 * 52 / 48
          return [iconWidth, iconHeight]
        },
        // symbolRotate: 180,
        symbolKeepAspect: true,
        label: {
          normal: {
            formatter: '{b}',
            position: 'bottom',
            color: '#fff',
            show: true
          }
        },
        // itemStyle: {
        //   normal: {
        //     color: '#f19d44',
        //     // shadowBlur: 0,
        //     // shadowColor: '#05C3F9'
        //   }
        // },
        z: 9,
        animationDuration: 2000
      },
      {
        type: 'map',
        mapType: '湖北',
        roam: false, // 是否开启鼠标缩放和平移漫游
        label: {
          // 决定了高亮时是否显示地理名称
          show: false,
          textStyle: {
            color: 'transparent'
          }
        },
        itemStyle: { // 地图区域的多边形 图形样式
          borderWidth: 1,
          borderColor: 'rgba(81, 255, 254)'
        },
        emphasis: {
          // 是图形在高亮状态下的样式,比如在鼠标悬浮或者图例联动高亮时
          label: {
            show: false,
            color: '#fff'
          },
          itemStyle: {
            borderWidth: 2,
            borderType: 'solid',
            borderColor: 'rgba(81, 255, 254)',
            // #0097DB
            areaColor: '#0097DB'
          }
        },

        data: data,
        animationDuration: 2000
      }
    ]
  }
  // 使用上述配置显示图标
  myChart.setOption(option)

  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default HubeiMap
