const pieData = {
  Topic2: {
    sValue: 11833,
    nValue: 3809,
    nPer: 32.19,
    oValue: 3305,
    oPer: 28,
    mValue: 4719,
    mPer: 39.81
  },
  Topic1: {
    sValue: 15253,
    nValue: 7572,
    nPer: 49.64,
    oValue: 2934,
    oPer: 19,
    mValue: 4747,
    mPer: 31.36
  },
  Topic3: {
    sValue: 17121,
    nValue: 8147,
    nPer: 47.58,
    oValue: 4078,
    oPer: 24,
    mValue: 4896,
    mPer: 28.42
  },
  Topic6: {
    sValue: 22017,
    nValue: 9643,
    nPer: 43.8,
    oValue: 6544,
    oPer: 30,
    mValue: 5830,
    mPer: 26.20
  },
  Topic4: {
    sValue: 72451,
    nValue: 29378,
    nPer: 40.55,
    oValue: 21601,
    oPer: 30,
    mValue: 21472,
    mPer: 29.45
  },
  Topic5: {
    sValue: 91696,
    nValue: 40247,
    nPer: 43.89,
    oValue: 22497,
    oPer: 25,
    mValue: 28952,
    mPer: 31.11
  }
}

export { pieData }
