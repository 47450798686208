import './html-1.css'

const createDom = function (domType, className, text) {
  const oneDom = document.createElement(domType)
  if (className !== '') {
    oneDom.setAttribute('class', className)
  }
  if (text !== '') {
    oneDom.innerHTML = text
  }
  return oneDom
}

const cityData = [
  {
    name: '武汉市',
    en: 'Wuhan city'
  }, {
    name: '江岸区',
    en: "Jiang'an District "
  }, {
    name: '江汉区',
    en: 'Jianghan District '
  }, {
    name: '硚口区',
    en: 'Qiaokou District '
  }, {
    name: '汉阳区',
    en: 'Hanyang District '
  }, {
    name: '武昌区',
    en: 'WUCHANG District '
  }, {
    name: '青山区',
    en: 'QINGSHAN District '
  }, {
    name: '洪山区',
    en: 'hongshan District '
  }, {
    name: '东西湖区',
    en: 'dongxihu District '
  }, {
    name: '汉南区',
    en: 'hannan District '
  }, {
    name: '蔡甸区',
    en: 'caidian District '
  }, {
    name: '江夏区',
    en: 'jiangxia District '
  }, {
    name: '黄陂区',
    en: 'huangpi District '
  }, {
    name: '新洲区',
    en: 'xinzhou District  '
  }
]

function html2 (targetDom, parmas) {
  const targetCity = parmas.currentSite
  // 整个图表的div
  const scrollDom = document.createElement('div')
  scrollDom.setAttribute('style', 'height: 100%; overflow: hidden ')
  scrollDom.setAttribute('class', 'htmlChart1-html2-scroll')
  const originDiv = document.createElement('div')
  originDiv.setAttribute('style', 'height:' + parmas.parentHeight + 'px; width: ' + parmas.parentWidth + 'px;')
  originDiv.setAttribute('class', 'htmlChart1-html1-table')
  scrollDom.appendChild(originDiv)
  targetDom.appendChild(scrollDom)

  for (let i = 0; i < cityData.length; i++) {
    const city = cityData[i]
    const eachDom = createDom('div', 'htmlChart1-html1-col', '')
    if (city.name === targetCity) {
      eachDom.innerHTML = `
      <div class="htmlChart2-title-block">
        <div class="htmlChart1-html2-title">${city.name}</div>
        <div class="htmlChart1-html2-title-en">${city.en}</div>
      </div>`

      originDiv.appendChild(eachDom)
    }
  }

  return originDiv
}

export default html2
