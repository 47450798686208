const mapData = [
  { name: '上海', value: 1561 },
  { name: '云南', value: 1781 },
  { name: '其他', value: 9756 },
  { name: '内蒙古', value: 265 },
  { name: '北京', value: 3180 },
  { name: '台湾', value: 138 },
  { name: '吉林', value: 256 },
  { name: '四川', value: 1095 },
  { name: '天津', value: 393 },
  { name: '宁夏', value: 71 },
  { name: '安徽', value: 534 },
  { name: '山东', value: 1083 },
  { name: '山西', value: 329 },
  { name: '广东', value: 2978 },
  { name: '广州', value: 6 },
  { name: '广西', value: 468 },
  { name: '新疆', value: 260 },
  { name: '江苏', value: 1410 },
  { name: '江西', value: 405 },
  { name: '河北', value: 608 },
  { name: '河南', value: 909 },
  { name: '浙江', value: 1182 },
  { name: '海南', value: 138 },
  { name: '海外', value: 1478 },
  { name: '湖北', value: 777 },
  { name: '湖南', value: 581 },
  { name: '澳门', value: 38 },
  { name: '甘肃', value: 197 },
  { name: '福州', value: 1 },
  { name: '福建', value: 761 },
  { name: '西藏', value: 42 },
  { name: '贵州', value: 320 },
  { name: '辽宁', value: 676 },
  { name: '重庆', value: 510 },
  { name: '陕西', value: 592 },
  { name: '青海', value: 57 },
  { name: '香港', value: 195 },
  { name: '黑龙江', value: 360 }
]
export default mapData
