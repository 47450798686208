<template>
  <div class="htmlchartset1-2" :style="boxStyle">
    <vue-scroll>
      <el-timeline class="htmlchartset1-chart2">
        <el-timeline-item
          v-for="item in frontDict.originData"
          :key="item.Date"
          :timestamp="changeDate(item)"
          placement="top"
          class="box-date"
        >
          <el-card class="right-box">
            <span class="box-title">{{ item.Event }}</span>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </vue-scroll>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data () {
    return {}
  },
  methods: {
    getDate (data) {
      return data.Date
    },
    changeDate (item) {
      let timestamp
      let date = moment(item.Date).format('YYYY-MM-DD')
      console.log(date)
      if (date === 'Invalid date') {
        date = item.Date
      }
      if (item.Country === undefined) {
        timestamp = date
      } else {
        timestamp = date + ' ' + item.Country
      }
      return timestamp
    }
  },
  computed: {
    boxStyle () {
      const style = {
        height: this.frontDict.parentHeight + 'px'
      }
      return style
    },
    chartWidth () {
      return this.props.width + 'px'
    },
    chartHeight () {
      return this.props.height + 'px'
    }
  }
}
</script>
<style lang="scss" scoped>
.htmlchartset1-chart2 {
  width: 380px;
  height: 462px;
  margin-left: 3px;
}

.right-box {
  width: 330px;
  border-radius: 0;
  padding: 10px 10px;
  background: rgba(94, 105, 123, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(67, 167, 253, 1);
  border-bottom: 1px solid rgba(67, 167, 253, 1);
  color: #ffffff;
}

ul.htmlchartset1-chart2
  > li:nth-child(1)
  > div.el-timeline-item__wrapper
  > div.el-timeline-item__content
  > div {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 111, 86, 1);
  border-bottom: 1px solid rgba(255, 111, 86, 1);
}

.box-title {
  font-size: 12px;
  font-family: "PingFangMedium";
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
  opacity: 1;
}
</style>

// 必须添加顶层类,再在新建的scss内嵌套修改element样式
<style lang="scss">
.htmlchartset1-2 {
  .el-timeline-item__timestamp {
    font-size: 14px;
    font-family: "PingFangMedium";
    font-weight: 500;
    line-height: 20px;
    color: #ffffff;
  }
  .el-timeline-item__node {
    background-color: rgba(67, 167, 253, 1);
  }
  .el-timeline-item__node:first-child {
    background-color: rgba(255, 111, 86, 1);
  }
  .el-card__body {
    padding: 0 0;
  }
  .el-timeline-item__tail {
    border-left: 1px solid rgba(255, 255, 255, 0.4);
  }
}
</style>
