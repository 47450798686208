<template>
  <div class="select">
    <el-button size="small" @click="dialogVisible = true">选择图表</el-button>
    <el-button type="text" size="small" @click="getChartSuggestion">图表智能推荐</el-button>
    <p class="select_tips text_size_12 text_color_white" v-if="value !== ''">已选择"{{chartChName[value]}}"</p>

    <!-- 图表类型 -->
    <el-dialog title="图表类型" :visible="dialogVisible" width="870px" top="135px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="select_body">
        <el-tabs class="my-el-tabs" v-model="activeTab">
          <el-tab-pane :label="item.label" :name="item.value" v-for="item in list" :key="item.value">
            <div class="select_list">
              <vue-scroll>
                <div class="select_list_wrapper">
                  <div
                    v-for="chart in item.children"
                    :key="chart.value"
                    class="select_list_item"
                    :class="{
                      select: activeTab === item.value && value === chart.value
                    }"
                    :title="chartChName[chart.value]"
                    @click="chartSelected(chart.value)">
                    <el-image
                      class="select_list_item_img"
                      :src="`${API_BASE_URL}/static/assets/images/sliceImages/${chart.value}.png`">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <p class="">{{chartChName[chart.value]}}</p>
                  </div>
                </div>
              </vue-scroll>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
      </div>
    </el-dialog>

    <!-- 图表推荐 -->
     <el-dialog
      title="图表推荐"
      :visible="showSuggestion" width="870px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div class="select_body" v-loading="suggestionLoading" element-loading-background="rgba(24,34,45,0.9)">
        <div class="select_list">
          <vue-scroll>
            <div class="select_list_wrapper">
              <div
                v-for="item in suggestionList" :key="item"
                class="select_list_item"
                :class="{
                  select: value === item
                }"
                :title="chartChName[item]"
                @click="chartSelected(item)">
                <el-image
                  class="select_list_item_img"
                  :src="`${API_BASE_URL}/static/assets/images/sliceImages/${item}.png`">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <p class="">{{chartChName[item]}}</p>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
      <div slot="footer">
        <el-button size="small" @click="showSuggestion = false">取消</el-button>
        <!-- <el-button size="small" type="primary" @click="">确定</el-button> -->
      </div>
     </el-dialog>
  </div>
</template>

<script>
import * as api from '~/api'
import { API_BASE_URL } from '~/config/index'
import { charts, chartChName, chartTypeGroup } from '../libs/chart-database'
export default {
  props: {
    value: String,
    dataSource: Object,
    formData: Object
  },
  data () {
    return {
      chartChName: chartChName,
      dialogVisible: false,
      activeTab: 'all',
      API_BASE_URL: API_BASE_URL,
      showSuggestion: false,
      // 推荐列表loading
      suggestionLoading: false,
      // 推荐列表
      suggestionList: []
    }
  },
  computed: {
    // 图表选择列表
    list () {
      return [
        {
          label: '全部',
          value: 'all',
          charts
        },
        ...chartTypeGroup
      ]
    }
  },
  methods: {
    // 选中某个图表
    chartSelected (value) {
      this.$emit('selected', value)
      this.dialogVisible = false
      this.showSuggestion = false
    },
    getChartSuggestion () {
      this.showSuggestion = true
      this.suggestionLoading = true
      api.getChartSuggestion({
        dataSource: { ...this.dataSource },
        form_data: { ...this.formData }
      }).then(res => {
        this.suggestionLoading = false
        if (res.data.code === 0) {
          this.suggestionList = res.data.data
        } else {
          this.suggestionList = []
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.suggestionLoading = false
        console.log(err)
        this.$message.error('网络错误！')
      })
    }
  }
}
</script>

<style lang="scss">
.image-slot {
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #18222D;
  color: #DDDFE1;
}
</style>

<style lang="scss" scoped>
.select {
  padding-top: 10px;
  &_tips {
    height: 30px;
    line-height: 30px;
  }
  &_body {
    margin: 0 20px;
  }
  &_list {
    height: 520px;
    &_wrapper {
      display: flex;
      flex-wrap: wrap;
      padding: 5px 0px 0 0px;
    }
    &_item {
      display: flex;
      flex-direction: column;
      width: 250px;
      height: 250px;
      padding: 10px;
      margin: 0px 20px 20px 0px;
      border: 1px solid #606A75;
      cursor: pointer;
      &:hover {
        border-color: #CBFFFE;
      }
      &.select {
        position: relative;
        border-color: #CBFFFE;
        & > p {
          color: #6AEBFF;
          text-align: left;
        }
      }
      &_img {
        width: 230px;
        height: 200px;
        margin-bottom: 10px;
        object-fit: cover;
      }
      & > p {
        width: 80%;
        line-height: 20px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &:nth-child(3n) {
        margin-right: 0px;
      }
    }
  }
}
</style>
