const chart = function (params) {
  const data = params.content_data
  const xAxisData = params.xAxisData
  const backgroundBarList = params.background_bar_list
  const YlabelToAxis = params.YlabelToAxis

  const legend = params.legend
  const barBorderRadius = params.barBorderRadius
  const barWidth = params.barWidth
  const axisFontSize = params.axisFontSize
  const axisFontColor = params.axisFontColor
  const axisLableColor = params.axisLableColor

  // 添加象形图案
  const isAddPictorialBar = params.isAddPictorialBar
  const pictorialBarType = params.pictorialBarType
  const pictorialBarHeight = params.pictorialBarHeight
  const pictorialBarOffsetY = params.pictorialBarOffsetY
  const pictorialBarWidth = params.pictorialBarWidth
  const pictorialBarOffsetX = params.pictorialBarOffsetX
  const pictorialBarColor = params.pictorialBarColor
  const axisLableFontSize = params.axisLableFontSize

  const backgroundColor = params.backgroundColor

  const series = []
  for (let i = 0; i < data.length; i++) {
    const currentData = data[i]
    series.push(
      {
        name: legend[i],
        type: 'bar',
        // gridIndex:0,
        xAxisIndex: 0,
        yAxisIndex: 0,
        data: currentData,
        barWidth: barWidth,
        barGap: '100%',
        backgroundStyle: {
          color: params.barBackgroundColor
        },
        label: {
          show: false,
          position: 'right',
          //  label 距离顶部的位置
          distance: 20,
          //   data ==> 该范围内的 data
          formatter: '{c}',
          // formatter: '{b}数量: {c}',
          textStyle: {
            color: axisLableColor,
            fontSize: axisLableFontSize,
            fontFamily: 'Helvetica Narrow'
          }
        },
        itemStyle: {
          normal: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 1, color: params.gradientColorStart // 0% 处的颜色
              }, {
                offset: 0, color: params.gradientColorEnd // 100% 处的颜色
              }],
              global: false // 缺省为 false
            },
            barBorderRadius: barBorderRadius
          }
        },
        z: 2
      },
      {
        name: '背景色',
        type: 'bar',
        barWidth: barWidth,
        barGap: '100%',
        hoverAnimation: false,
        data: backgroundBarList,
        xAxisIndex: 1,
        yAxisIndex: 1,
        symbolSize: 35,
        label: {
          show: true,
          position: 'right',
          distance: 20,
          //   data ==> 该范围内的 data
          formatter: function (data) {
            return currentData[data.dataIndex]
          },
          textStyle: {
            color: axisLableColor,
            fontSize: axisLableFontSize,
            fontFamily: 'Helvetica Narrow'
          }
        },
        itemStyle: {
          normal: {
            color: params.barBackgroundColor,
            barBorderRadius: barBorderRadius
          }
        },
        z: 1
      }

    )
    if (isAddPictorialBar === true) {
      let symbolMove
      if (i === 0) {
        symbolMove = -barWidth
      } else {
        symbolMove = barWidth
      }
      series.push(
        {
          name: 'pictorialBar',
          type: 'pictorialBar',
          xAxisIndex: 0,
          yAxisIndex: 0,
          symbolPosition: 'end',
          symbol: pictorialBarType,
          symbolSize: [pictorialBarWidth, pictorialBarHeight],
          symbolOffset: [pictorialBarOffsetX, symbolMove + pictorialBarOffsetY],
          z: 10 + i * 2,
          tooltip: {
            show: false
          },
          itemStyle: {
            normal: {
              color: pictorialBarColor
            }
          },
          data: data[i]
        }
      )
    }
  }

  const option = {
    backgroundColor: backgroundColor,
    legend: {
      show: false,
      top: '3%',
      right: '7%',
      itemWidth: 10,
      itemHeight: 10,
      // 如何控制图标的颜色??
      data: legend
    },
    tooltip: {
      trigger: 'item',
      textStyle: {
        fontSize: 16
      },
      formatter: function (params) {
        const name = params.seriesName
        let res = ''
        if (name !== '背景色') {
          res = params.name + ':' + '<bar>' + params.value
          return res
        }
      }
      // formatter: '{b}数量: {c}'
    },
    grid: [{
      left: '8%',
      top: '5%',
      right: '8%',
      // width: '80%',
      height: '90%',
      containLabel: true
    },
    {
      left: '8%',
      top: '5%',
      // right: '8%',
      width: '80%',
      height: '90%',
      containLabel: true
    }],
    xAxis: [{
      gridIndex: 0,
      max: backgroundBarList[0],
      show: false
    },
    {
      gridIndex: 0,
      show: false
    },
    {
      gridIndex: 1,
      max: backgroundBarList[0],
      show: false
    },
    {
      gridIndex: 1,
      show: false
    }
    ],
    yAxis: [{
      axisTick: 'none',
      axisLine: 'none',
      splitLine: 'none',
      gridIndex: 0,
      // bar 距离 y轴的距离
      offset: YlabelToAxis,
      axisLabel: {
        textStyle: {
          color: axisFontColor,
          fontSize: axisFontSize,
          align: 'left'
        }
      },
      data: xAxisData
    },
    {
      axisTick: 'none',
      axisLine: 'none',
      splitLine: 'none',
      gridIndex: 0,
      // 消除底部的超出部分
      offset: '11',
      data: []
    }
    ],
    series: series
  }
  return option
}

export default chart
