// import echarts from 'echarts'
import './numberFlop.css'

import { EchartsSchemes } from '../echarts_theme/echarts_color_schemes'
function numberFlop (element, props) {
  // console.log(props.data)

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const data = props.data[0].data

  // 前缀符号, 后缀符号
  const unitIcon = props.data[0].x_axis_label
  const unit = props.data[0].x_axis_unit
  const colorTheme = EchartsSchemes[props.colorTheme]
  const colorArray = colorTheme.colors
  const digtalfontFamily = props.data[0].digtalfontFamily

  const dataLength = data.length
  const colOfWidth = Math.ceil(100 / dataLength)
  const noPlay = false

  // 生成dom节点
  onload = function (isStart) {
    var body = element.children[0]
    body.setAttribute('style', 'margin: 0 auto; width: ' + 0.9 * props.width + 'px')
    // console.log(typeof target_div, target_div)
    body.appendChild(createTitle(data, colOfWidth))
    body.appendChild(createNumber(data, colOfWidth))

    if (isStart === true) {
      for (var i = 0; i < dataLength; i++) {
        const targetNode = body.children[1].childNodes[i].childNodes[1]
        // console.log('targetNode: ', targetNode)
        // const NodeID = 'number' + i;
        myMove(targetNode)
      }
    }
  }

  var createTitle = function (data, colWidth) {
    var titleDiv = document.createElement('div')
    titleDiv.setAttribute('style', 'font-size: 32px; display: flex;text-align: center; color: ' + colorArray[0])
    for (var i = 0; i < dataLength; i++) {
      const eachData = data[i]
      var spanName = document.createElement('span')
      spanName.setAttribute('style', 'width: ' + colWidth + '%')
      spanName.innerHTML = eachData.name
      titleDiv.appendChild(spanName)
    }
    return titleDiv
  }

  var createNumber = function (data, colWidth) {
    var NumberDiv = document.createElement('div')
    NumberDiv.setAttribute('style', 'display: flex; text-align: center')
    for (var i = 0; i < dataLength; i++) {
      const eachData = data[i]
      // console.log(eachData)

      var NumberDivChild = document.createElement('div')
      NumberDivChild.setAttribute('style', ' width: ' + colWidth + '%')

      var unitPreifx = document.createElement('span')
      unitPreifx.setAttribute('style', 'font-size: 48px; color: rgba(255, 255, 255, 0.8)')
      unitPreifx.innerHTML = unitIcon

      var numberTarget = document.createElement('span')
      numberTarget.setAttribute('class', 'number-style')
      numberTarget.setAttribute('style', 'color: ' + colorArray[1] + '; font-family: ' + digtalfontFamily)
      numberTarget.setAttribute('id', 'number' + i)
      numberTarget.innerHTML = eachData.value

      var unitSuffix = document.createElement('span')
      unitSuffix.setAttribute('style', 'font-size:30px; color: rgba(255, 255, 255, 0.8); vertical-align: auto')
      unitSuffix.innerHTML = unit

      NumberDivChild.appendChild(unitPreifx)
      NumberDivChild.appendChild(numberTarget)
      NumberDivChild.appendChild(unitSuffix)

      NumberDiv.appendChild(NumberDivChild)
    }

    return NumberDiv
  }

  function myMove (targetnode) {
    var count = 0
    var result = ''
    // console.log(targetnode);
    var elem3 = targetnode
    var targetNum = elem3.innerText
    // var id3 = setInterval(ascIteration, 0.3);
    var move = setInterval(numAsc, 50)

    // TODO 新方法思路: 针对每一位进行递增
    // 1. 获取数字的每一位上的数字
    // 2. 每次 +1, 直到 除以 10 余数等于自身,停止,
    // 3. 每次运行结果 合并为新的字符

    function splitNum (str) {
      var numArray = []
      for (let i = 0; i < str.length; i++) {
        var currentStr = str.charAt(i)
        if (currentStr !== ',' && currentStr !== '.' && currentStr !== ',' && currentStr !== ' ') {
          var currentNum = parseInt(currentStr)
          // console.log(currentNum)
          currentNum += Math.round(Math.random() * 10)
          if (currentNum < 10) {
            numArray.push(currentNum)
          } else {
            currentNum = currentNum % 10
            // console.log('获取余数 ', currentNum)
            numArray.push(currentNum)
          }
        } else {
          numArray.push(currentStr)
        }
      }
      numArray.reverse()
      var res = ''
      for (var i = 0; i < numArray.length; i++) {
        res = res + numArray[i]
      }
      return res
    }

    function numAsc () {
      if (count < 15) {
        if (result === '') {
          result = targetNum
        }
        // console.log(result)
        result = splitNum(result)
        elem3.innerHTML = result
        count++
      } else {
        // console.log(result)
        elem3.innerHTML = targetNum
        clearInterval(move)
      }
    }
  }

  //  不能使用 setInterval 方法,本身就是一个递归方法
  //  props返回的内容增加 mode[edit show]  autoplay[True false],只有当 show, True时, 才执行循环刷新的方法
  const mode = props.mode
  const autoPlay = props.autoPlay
  console.log(mode, autoPlay)
  if (mode === 'show' && autoPlay === true) {
    // isPlay = true
    onload(autoPlay)
  } else {
    onload(noPlay)
  }
  //  启动
  // onload()
}

export default numberFlop // 修改为Classname
