import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function Radar (element, props, id) {
  // const fd = slice.formData;
  const params = props.data // 这是从后端viz.py获取数据的标准写法
  const indicator = params[0].indicator // 因为雷达图有两个地方要用到后端传来的数据，根据我刚刚处理过的数据格式取出来
  const data = params[0].data // 同上
  // const ColorEchartsTheme = params[0]['color_echarts_theme'] // 同上
  // const dataName = [] // echarts需要的是两个数组
  // 以下开始是从echarts官网抄的，当然也可以自己设置，可以对比我这一段跟官网的设置（增加了tooltip显示规则和label)

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  // eushow 3.0
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const option = {
    legend: {
      orient: 'vertical',
      x: 'right',
      y: 'bottom',
      itemWidth: 10,
      itemHeight: 10
    },
    tooltip: {
      triggerOn: trigger,
      hideDelay: hideDelayTime
    },
    grid: {
      // left: '20px',
      // right: '20px',
      top: '5%',
      // // height: '80%',
      buttom: '5%',
      containLabel: true
    },
    radar: {
      indicator: indicator, // 传入显示的数据
      name: {
        color: '#fff',
        borderRadius: 2,
        padding: [3, 5]
      }
    },
    series: [
      {
        type: 'radar',
        data: data,
        // 为雷达图添加阴影，封面
        itemStyle: { normal: { areaStyle: { type: 'default' } } },
        label: {
          show: true,
          position: ['120%', '120%'],
          distance: 10
        },
        animationDuration: 2000
      }
    ]
  }
  // 使用刚指定的配置项和数据显示图表。
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default Radar
