import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function CircleNumber (element, props) {
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  // eushow 3.0
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const title = props.data[0].title
  const circleStyle = props.data[0].circleStyle
  const numberFontSize = props.data[0].numberFontSize
  const titleColor = props.data[0].titleColor
  const titlePosition = props.data[0].titlePosition
  const titleUnit = props.data[0].titleUnit
  const pieWidth = props.data[0].pieWidth
  const secondPieWidth = props.data[0].secondPieWidth
  const piePosition = props.data[0].piePosition

  const oneData = [{
    value: 335
    // legendname: '种类01',
    // name: "种类01  335"
  }]

  const series = []
  if (circleStyle === '单环蓝色') {
    series.push(
      {
        name: '标题',
        type: 'pie',
        center: piePosition,
        radius: pieWidth,
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [{
              offset: 1,
              color: 'rgba(29,134,133,0.5)'
            },
            {
              offset: 0,
              color: 'rgba(43,245,239,0.5)'
            }
            ]
          }
        },
        labelLine: {
          show: false
        },
        data: oneData
      }
    )
  } else if (circleStyle === '双环蓝色') {
    series.push(
      {
        name: '标题',
        type: 'pie',
        hoverOffset: '0.5%',
        center: piePosition,
        radius: pieWidth,
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [{
              offset: 1,
              color: '#1D8685'
            },
            {
              offset: 0,
              color: '#2BF5EF'
            }
            ]
          }
        },
        labelLine: {
          show: false
        },
        data: oneData
      },
      {
        name: '标题',
        type: 'pie',
        center: piePosition,
        radius: secondPieWidth,
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [{
              offset: 1,
              color: 'rgba(29,134,133,0.5)'
            },
            {
              offset: 0,
              color: 'rgba(43,245,239,0.5)'
            }
            ]
          }
        },
        labelLine: {
          show: false
        },
        data: oneData
      }
    )
  } else if (circleStyle === '双环黄色') {
    series.push(
      {
        name: '标题',
        type: 'pie',
        hoverOffset: '0.5%',
        center: piePosition,
        radius: pieWidth,
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [{
              offset: 1,
              color: '#FD8C57'
            },
            {
              offset: 0,
              color: '#FEDC45'
            }
            ]
          }
        },
        labelLine: {
          show: false
        },
        data: oneData
      },
      {
        name: '标题',
        type: 'pie',
        center: piePosition,
        radius: secondPieWidth,
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [{
              offset: 1,
              color: 'rgba(253,140,87,0.50)'
            },
            {
              offset: 0,
              color: 'rgba(254,220,69,0.50)'
            }
            ]
          }
        },
        labelLine: {
          show: false
        },
        data: oneData
      }
    )
  } else {
    series.push(
      {
        name: '标题',
        type: 'pie',
        center: piePosition,
        radius: pieWidth,
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [{
              offset: 1,
              color: 'rgba(253,140,87,0.50)'
            },
            {
              offset: 0,
              color: 'rgba(254,220,69,0.50)'
            }
            ]
          }
        },
        labelLine: {
          show: false
        },
        data: oneData
      }
    )
  }

  const option = {
    title: [{
      text: title,
      textStyle: {
        fontSize: numberFontSize,
        color: titleColor
      },
      textAlign: 'center',
      x: titlePosition[0],
      y: titlePosition[1]
    }],
    tooltip: {
      trigger: 'item',
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      formatter: function (parms) {
        const str = titleUnit + parms.data.value + '</br>'
        return str
      }
    },
    legend: {
      show: false
    },
    series: series
  }

  myChart.on('brushSelected', renderBrushed)

  function renderBrushed (params) {
    var brushed = []
    var brushComponent = params.batch[0]

    for (var sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
      var rawIndices = brushComponent.selected[sIdx].dataIndex
      brushed.push('[Series ' + sIdx + '] ' + rawIndices.join(', '))
    }

    myChart.setOption({
      title: {
        backgroundColor: 'rgba(251,255,219,0)',
        text: 'SELECTED DATA INDICES: \n' + brushed.join('\n'),
        bottom: 0,
        right: 0,
        width: 100,
        textStyle: {
          fontSize: 12,
          color: '#fff'
        }
      }
    })
  }

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default CircleNumber // 修改为Classname
