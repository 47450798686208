/* eslint-disable camelcase */

import { axisSettins } from '../echartSetting/axisSetting'

const arearadar = [
  {
    className: '坐标轴',
    fieldList: [
      'axisLineColor',
      'axisLineWidth',
      'XlabelToAxis',
      'axisFontSize'
    ]
  },
  {
    className: '颜色',
    fieldList: ['backgroundColor', 'color_echarts_theme']
  },
  {
    className: '折线图',
    fieldList: ['areaColor', 'lineWidth', 'lineColor']
  },
  {
    className: '文本',
    fieldList: ['textColor']
  }
]

const bar3d = [{ className: '颜色', fieldList: ['color_echarts_theme'] }]

const bubble_multi = [{ className: '颜色', fieldList: ['color_echarts_theme'] }]

const bar_brush = [
  {
    className: '坐标轴',
    fieldList: axisSettins
  },
  { className: '图例', fieldList: ['legend'] },
  { className: '类型', fieldList: ['table_type', 'stack_type'] },
  { className: '柱状图', fieldList: ['cancelBarLineColor', 'barBorderRadius', 'barWidth'] },
  { className: '颜色', fieldList: ['backgroundColor', 'color_echarts_theme'] },
  { className: '折线图', fieldList: ['lineWidth', 'lineColor'] },
  { className: '文本', fieldList: ['textColorList', 'endTextColorList'] }]

const bar_polar = [{ className: '标题', fieldList: ['title', 'sub_title'] }, { className: '图例', fieldList: ['legend'] }, { className: '柱状图', fieldList: ['barHeight'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const boxplot_echarts = [{ className: '颜色', fieldList: ['color_echarts_theme'] }]

const bar_animation = [{ className: '坐标轴', fieldList: ['x_axis_unit', 'x_ticks_namelocation', 'x_ticks_namegap', 'y_axis_unit'] }, { className: '标题', fieldList: ['title', 'sub_title'] }, { className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const barpictorialbarfirst = [
  {
    className: '坐标轴',
    fieldList: ['y_axis_max_value', 'axisLableColor', 'axisFontColor', 'axisFontSize', 'y_axis_unit']
  }, {
    className: '柱状图',
    fieldList: ['barLabelFontSize', 'barWidth']
  }, {
    className: '颜色',
    fieldList: ['backgroundColor']
  }, {
    className: '象形图',
    fieldList: ['pictorialSymbolType']
  }, {
    className: '折线图',
    fieldList: ['lineColor']
  }
]

const barpictorialbarsecond = [{ className: '坐标轴', fieldList: ['y_axis_unit', 'axisLableColor', 'YlabelToAxis', 'axisFontColor', 'inlineType', 'y_axis_max_value', 'axisFontSize', 'XlabelToAxis', 'isShowRightYAxis', 'YAxisLineWidth', 'XAxisLineColor', 'axisLineColor', 'isShowRightYLabel', 'isShowLeftYLabel', 'axisLineWidth', 'second_y_axis_unit'] }, { className: '图例', fieldList: ['legend'] }, { className: '柱状图', fieldList: ['barLabelFontSize', 'barBorderRadius', 'barWidth'] }, { className: '颜色', fieldList: ['backgroundColor'] }, { className: '象形图', fieldList: ['pictorialBarColor', 'pictorialBarOffsetX', 'pictorialBarType', 'pictorialBarHeight', 'pictorialBarOffsetY', 'pictorialBarWidth'] }, { className: '其他', fieldList: ['picOffsetXList'] }]

const barsort = [{ className: '坐标轴', fieldList: ['textFontSize', 'textColor', 'YlabelToAxis', 'iconHeight', 'iconWidth'] }, { className: '柱状图', fieldList: ['isAescending', 'barWidth', 'barBorderRadius', 'barMinHeight', 'isShowBarLabel', 'textAlign', 'barLabelColor', 'barLabelFontSize'] }, { className: '颜色', fieldList: ['textColorList', 'endTextColorList'] }]

const barstandard = [
  { className: '图表类型', fieldList: ['barStandardSelect'] },
  {
    className: '图表尺寸',
    fieldList: ['parentHeight', 'parentWidth']
  },
  {
    className: '坐标轴',
    fieldList: axisSettins
  },
  { className: '图例', fieldList: ['legend'] },
  { className: '类型', fieldList: ['table_type', 'stack_type'] },
  { className: '柱状图', fieldList: ['cancelBarLineColor', 'barBorderRadius', 'barWidth', 'sumNum'] },
  { className: '颜色', fieldList: ['backgroundColor', 'color_echarts_theme'] },
  { className: '折线图', fieldList: ['lineWidth', 'lineColor'] },
  { className: '文本', fieldList: ['textColorList', 'endTextColorList'] }]

const basefilter = [{ className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['iconBackgroundColor'] }, { className: '轮播图', fieldList: ['rowHeight', 'iconHeight'] }, { className: '其他', fieldList: ['groupbyNum', 'isSearchIcon'] }]

const barlinepictorialbar = [
  {
    className: '图表类型',
    fieldList: ['barStandardSelect']
  },
  {
    className: '坐标轴',
    fieldList: axisSettins
  },
  {
    className: '图例',
    fieldList: ['legend']
  }, {
    className: '柱状图',
    fieldList: ['barLabelFontSize', 'barLabelColor', 'is_exchange_bar_line', 'barBorderRadius', 'barWidth']
  }, {
    className: '颜色',
    fieldList: ['circleColor', 'backgroundColor', 'gradientColorEnd', 'gradientColorStart']
  }, {
    className: '象形图',
    fieldList: ['pictorialBarColor', 'pictorialBarOffsetX', 'pictorialBarType', 'pictorialBarHeight',
      'pictorialBarOffsetY', 'pictorialBarWidth', 'pictorialSymbolType', 'iconLeftGap'
    ]
  }, {
    className: '折线图',
    fieldList: ['lineColor']
  }
]

const bubble_timeline = [{ className: '坐标轴', fieldList: ['x_axis_unit', 'y_axis_unit'] }, { className: '标题', fieldList: ['title'] }, { className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const directed_force = []

const otherchart = [
  {
    className: '图表类型',
    fieldList: ['barStandardSelect']
  }, {
    className: '词云',
    fieldList: ['textColorList', 'fontSizeList', 'textGap', 'targetWidth', 'targetHeight']
  }
]

const calendar_echarts = [{ className: '标题', fieldList: ['title', 'sub_title'] }, { className: '图例', fieldList: ['legend'] }]

const custom_error_bar = [{ className: '坐标轴', fieldList: ['echarts_timestamp_format'] }, { className: '标题', fieldList: ['title'] }, { className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const circlenumber = [{ className: '标题', fieldList: ['title'] }, { className: '颜色', fieldList: ['titleColor'] }, { className: '饼状图', fieldList: ['piePosition', 'pieWidth', 'secondPieWidth', 'titlePosition'] }, { className: '象形图', fieldList: ['circleStyle'] }, { className: '文本', fieldList: ['numberFontSize'] }, { className: '其他', fieldList: ['titleUnit'] }]

const doublecolorbar = [{ className: '坐标轴', fieldList: ['YlabelToAxis', 'axisFontSize'] }, { className: '图例', fieldList: ['legend'] }, { className: '柱状图', fieldList: ['barWidth', 'barRadius'] }, { className: '颜色', fieldList: ['gradientColorEnd', 'backgroundColor', 'color_echarts_theme', 'barBackgroundColor', 'gradientColorStart'] }]

const directional_force = []

const digitalflop = [
  { className: '坐标轴', fieldList: ['x_axis_unit', 'textGap'] },
  { className: '文本', fieldList: ['numberFontSize', 'letterSpacing', 'numberColor', 'textFontSize', 'textColor'] },
  { className: '其他', fieldList: ['digtalfontFamily', 'isShowSign', 'tailZeroNum'] }]

const digitalflopcarousel = [{ className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['iconColor', 'titleColor', 'backgroundColor'] }, { className: '轮播图', fieldList: ['targetWidth', 'parentHeight', 'targetHeight', 'speedTime', 'delayTime', 'textBodyWidth', 'parentWidth', 'iconHeight', 'targetMarginLeft'] }, { className: '文本', fieldList: ['textFontSize'] }]

const dataset_link = [{ className: '坐标轴', fieldList: ['echarts_timestamp_format'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const effectscatter_bmap = [{ className: '标题', fieldList: ['title', 'sub_title'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const first_bar_one = [{ className: '坐标轴', fieldList: ['axisLableFontSize', 'isShowRightYAxis', 'isShowRightYLabel', 'axisLableColor', 'YlabelToAxis', 'axisFontColor', 'axisFontSize'] }, { className: '图例', fieldList: ['legend'] }, { className: '柱状图', fieldList: ['barBorderRadius', 'barRadius', 'barWidth'] }, { className: '颜色', fieldList: ['backgroundColor', 'color_echarts_theme', 'gradientColorEnd', 'barBackgroundColor', 'gradientColorStart'] }, { className: '象形图', fieldList: ['pictorialBarType', 'pictorialBarColor', 'isAddPictorialBar', 'pictorialBarHeight', 'pictorialBarOffsetY', 'pictorialBarWidth', 'pictorialBarOffsetX'] }]

const flow_diagram = [{ className: '颜色', fieldList: ['color_echarts_theme'] }]

const first_line_double = [
  {
    className: '图表类型',
    fieldList: ['barStandardSelect']
  },
  {
    className: '坐标轴',
    fieldList: axisSettins
  },
  { className: '图例', fieldList: ['legend'] },
  { className: '类型', fieldList: ['table_type', 'isShowSeriesLabel'] },
  { className: '颜色', fieldList: ['backgroundColor', 'color_echarts_theme'] },
  { className: '折线图', fieldList: ['isShowAreaColor', 'boundaryGap', 'areaColorOpacity', 'lineColorFour', 'lineColor', 'lineWidth', 'lineColorThird', 'lineColorSecond'] }]

const firstbaronesecond = [
  {
    className: '图表类型',
    fieldList: ['barStandardSelect']
  }, {
    className: '坐标轴',
    fieldList: ['axisLableColor', 'YlabelToAxis', 'axisFontColor', 'axisLableFontSize', 'axisFontSize']
  }, {
    className: '图例',
    fieldList: ['legend']
  }, {
    className: '柱状图',
    fieldList: ['barRadius', 'barBorderRadius', 'barWidth']
  }, {
    className: '颜色',
    fieldList: ['backgroundColor', 'color_echarts_theme', 'gradientColorEnd', 'barBackgroundColor', 'gradientColorStart']
  }, {
    className: '象形图',
    fieldList: ['pictorialBarColor', 'isAddPictorialBar', 'pictorialBarOffsetX', 'pictorialBarType', 'pictorialBarHeight', 'pictorialBarOffsetY', 'pictorialBarWidth']
  }
]

const globe_layers = [
  {
    className: '图表类型',
    fieldList: ['barStandardSelect']
  }, {
    className: '颜色',
    fieldList: ['backgroundColor', 'color_echarts_theme']
  }, {
    className: '地图',
    fieldList: ['areaColor', 'lineColor', 'lineWidth', 'hoverColor', 'textFontSize']
  }
]

const gauge = [{ className: '坐标轴', fieldList: ['axisLineWidth', 'x_axis_max_value', 'XlabelToAxis', 'axisFontSize', 'splitNumber', 'x_axis_min_value'] }, { className: '标题', fieldList: ['sub_title'] }, { className: '颜色', fieldList: ['backgroundColor'] }, { className: '文本', fieldList: ['textFontSize'] }]

const hubei = [{ className: '坐标轴', fieldList: ['y_axis_unit'] }, { className: '标题', fieldList: ['title'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const horizontaldoublebar = [
//   {
//   'className': '坐标轴',
//   'fieldList': ['isShowAxis', 'YlabelToAxis', 'axisFontSize']
// },
  {
    className: '坐标轴',
    fieldList: axisSettins
  },
  {
    className: '图例',
    fieldList: ['legend']
  }, {
    className: '柱状图',
    fieldList: ['barWidth', 'barRadius', 'isShowSeriesCircle', 'barBackgroundColor']
  }, {
    className: '颜色',
    fieldList: ['secondGradientColorStart', 'secondGradientColorEnd', 'gradientColorEnd', 'backgroundColor', 'color_echarts_theme', 'gradientColorStart']
  }, {
    className: '文本',
    fieldList: ['textColor']
  }, {
    className: '其他',
    fieldList: ['eachBarTableWidth', 'tableToBorderDistance', 'secondBarTableToLeft']
  }
]

const htmlchart1 = [
  { className: '图表类型', fieldList: ['barStandardSelect'] },
  { className: '轮播图', fieldList: ['parentWidth', 'targetWidth', 'delayTime', 'targetHeight', 'parentHeight'] },
  { className: '颜色', fieldList: ['backgroundColor', 'titleColor'] },
  { className: '文本', fieldList: ['textFontSize'] },
  { className: '图例', fieldList: ['legend'] }
]

const htmlchartset1 = [
  {
    className: '图表类型',
    fieldList: ['htmlSelect']
  }, {
    className: '图表尺寸',
    fieldList: ['parentHeight', 'parentWidth', 'circleSize']
  }, {
    className: '坐标轴',
    fieldList: ['axisFontColor', 'y_axis_max_value', 'targetMarginLeft', 'splitLineWidth']
  }, {
    className: '折线图',
    fieldList: ['legend', 'textColorList', 'lineWidth', 'lineSmoothNumber']
  }
]

const hubei_map = [{ className: '坐标轴', fieldList: ['y_axis_unit'] }, { className: '标题', fieldList: ['title'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const hubeiline = [{ className: '坐标轴', fieldList: ['y_axis_unit'] }, { className: '标题', fieldList: ['title'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const horizontalbar = [{ className: '坐标轴', fieldList: ['echarts_timestamp_format'] }, { className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const mapflowline = [{ className: '颜色', fieldList: ['gradientColorEnd', 'backgroundColor', 'gradientColorStart'] }, { className: '折线图', fieldList: ['lineWidth'] }, { className: '其他', fieldList: ['rippleScale'] }]

const map2d_and_bar = [{ className: '坐标轴', fieldList: ['y_axis_unit'] }, { className: '标题', fieldList: ['title'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const movetablerimless = [{ className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['backgroundColor', 'titleBackgroundColor'] }, { className: '折线图', fieldList: ['lineColor'] }, { className: '轮播图', fieldList: ['show_row_limit', 'isAddHeader', 'rowHeight', 'isMoveUp'] }, { className: '文本', fieldList: ['textColor', 'textFontSize'] }, { className: '其他', fieldList: ['textAlign'] }]

const mapchina = [
  {
    className: '图表类型',
    fieldList: ['barStandardSelect']
  }, {
    className: '地图',
    fieldList: ['areaColor', 'lineColor', 'lineWidth', 'targetMarginLeft']
  }, {
    className: '颜色',
    fieldList: ['backgroundColor']
  }
]

const movetablefontstyle = [{ className: '坐标轴', fieldList: ['inlineType'] }, { className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['titleColor', 'backgroundColor', 'titleBackgroundColor'] }, { className: '折线图', fieldList: ['lineWidth', 'lineColor'] }, { className: '轮播图', fieldList: ['show_row_limit', 'firstRowHeight', 'rowRealWidthList', 'isAddHeader', 'colPrefixList', 'rowHeight', 'rowBackcolor', 'rowWidthList', 'isMoveUp'] }, { className: '文本', fieldList: ['textColorList', 'textFontSize'] }, { className: '其他', fieldList: ['textAlign'] }]

const mix_timeline_finance = [{ className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const map_and_bar = [
  {
    className: '图表类型',
    fieldList: ['barStandardSelect']
  }, {
    className: '坐标轴',
    fieldList: ['y_axis_unit']
  }, {
    className: '标题',
    fieldList: ['title']
  }, {
    className: '颜色',
    fieldList: ['color_echarts_theme']
  }
]

const map_and_bar3d = [{ className: '颜色', fieldList: ['color_echarts_theme'] }]

const mix_line_bar = [{ className: '坐标轴', fieldList: ['echarts_timestamp_format', 'y_axis_unit'] }, { className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const movehovertable = [{ className: '坐标轴', fieldList: ['inlineType'] }, { className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['titleColor', 'backgroundColor', 'titleBackgroundColor'] }, { className: '折线图', fieldList: ['lineWidth', 'lineColor'] }, { className: '轮播图', fieldList: ['delayTime', 'rowHeight', 'rowBackcolor', 'hoverColor', 'marginTop', 'isMoveUp', 'show_row_limit', 'isAddHeader', 'speedTime', 'colPrefixList', 'fontSizeList', 'targetMarginLeft', 'rowWidthList'] }, { className: '文本', fieldList: ['textColorList'] }, { className: '其他', fieldList: ['textAlign'] }]

const mapflowlinetwo = [{ className: '颜色', fieldList: ['gradientColorEnd', 'backgroundColor', 'gradientColorStart'] }, { className: '折线图', fieldList: ['lineWidth'] }, { className: '其他', fieldList: ['rippleScale'] }]

const maptooltipmove = [
  {
    className: '图表类型',
    fieldList: ['barStandardSelect']
  }, {
    className: '提示框',
    fieldList: ['titlePosition', 'delayTime', 'textFontSize', 'textColorList']
  },
  {
    className: '地图',
    fieldList: ['siteNamePY', 'areaColor', 'lineColor', 'hoverColor', 'isShowAreaTitle', 'titleColor', 'titleUnit']
  },
  {
    className: '颜色',
    fieldList: ['circleColor', 'circleSize']
  }
]

const movebackcolortable = [
  {
    className: '图表类型',
    fieldList: ['barStandardSelect']
  }, {
    className: '坐标轴',
    fieldList: ['inlineType']
  }, {
    className: '图例',
    fieldList: ['legend']
  }, {
    className: '颜色',
    fieldList: ['titleColor', 'backgroundColor', 'titleBackgroundColor']
  }, {
    className: '折线图',
    fieldList: ['lineWidth', 'lineColor']
  }, {
    className: '轮播图',
    fieldList: [
      'delayTime',
      'show_row_limit', 'firstRowHeight',
      'isAddHeader', 'colPrefixList', 'rowHeight', 'rowBackcolor', 'rowWidthList', 'isMoveUp']
  }, {
    className: '文本',
    fieldList: ['textColorList', 'textFontSize']
  }, {
    className: '其他',
    fieldList: ['textAlign']
  }
]

const multiline = [
  {
    className: '图表类型',
    fieldList: ['barStandardSelect']
  },
  {
    className: '坐标轴',
    fieldList: axisSettins
  }, {
    className: '图例',
    fieldList: ['legend']
  }, {
    className: '颜色',
    fieldList: ['backgroundColor', 'color_echarts_theme']
  }, {
    className: '折线图',
    fieldList: ['textColorList', 'lineWidth', 'lineSmoothNumber', 'lineColor', 'borderColor', 'y_axis_max_value', 'y_axis_min_value']
  }
]

const numbertrendicon = [{ className: '坐标轴', fieldList: ['x_axis_unit', 'textGap'] }, { className: '颜色', fieldList: ['backgroundColor'] }, { className: '轮播图', fieldList: ['iconTopGap', 'selectArrowIcon', 'iconHeight', 'iconLeftGap'] }, { className: '文本', fieldList: ['numberFontSize', 'textColor', 'textFontSize', 'numberColor'] }]

const number_flop = [{ className: '坐标轴', fieldList: ['x_axis_unit', 'x_ticks_namelocation', 'x_ticks_namegap'] }, { className: '标题', fieldList: ['block_name', 'title', 'sub_title'] }, { className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }, { className: '其他', fieldList: ['digtalfontFamily'] }]

const numflip = [
  { className: '图表类型', fieldList: ['chartSelect'] },
  { className: '坐标轴', fieldList: ['x_axis_unit', 'textGap'] },
  { className: '文本', fieldList: ['numberFontSize', 'letterSpacing', 'numberColor', 'textFontSize', 'textColor', 'textShadowColor', 'delayTime'] },
  { className: '其他', fieldList: ['digtalfontFamily', 'isShowSign', 'tailZeroNum'] }
]

const pierose = [{ className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['backgroundColor', 'color_echarts_theme'] }, { className: '饼状图', fieldList: ['selected_part', 'pie_table_type', 'startLine', 'endLine'] }, { className: '文本', fieldList: ['numberFontSize'] }]

const progressbar = [{ className: '柱状图', fieldList: ['barWidth', 'barRadius'] }, { className: '颜色', fieldList: ['gradientColorEnd', 'backgroundColor', 'barBackgroundColor', 'gradientColorStart'] }, { className: '饼状图', fieldList: ['numerator', 'titlePosition'] }, { className: '文本', fieldList: ['numberFontSize', 'numberColor'] }]

const piccarousel = [{ className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['iconColor', 'titleColor', 'backgroundColor'] }, { className: '轮播图', fieldList: ['targetWidth', 'parentHeight', 'targetHeight', 'speedTime', 'delayTime', 'textBodyWidth', 'parentWidth', 'iconHeight', 'targetMarginLeft'] }, { className: '文本', fieldList: ['textFontSize'] }]

const piccarouselevn = [{ className: '颜色', fieldList: ['titleColor', 'backgroundColor'] }, { className: '轮播图', fieldList: ['parentHeight', 'parentWidth', 'speedTime', 'delayTime', 'pagination'] }]

const piccarouselfive = [{ className: '颜色', fieldList: ['backgroundColor'] }, { className: '轮播图', fieldList: ['parentHeight', 'speedTime', 'delayTime', 'parentWidth'] }]

const piccarouselfour = [{ className: '坐标轴', fieldList: ['y_axis_max_value', 'y_axis_min_value'] }, { className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['titleColor', 'backgroundColor'] }, { className: '轮播图', fieldList: ['targetWidth', 'parentHeight', 'textBodyWidth', 'speedTime', 'delayTime', 'parentWidth', 'targetHeight'] }, { className: '文本', fieldList: ['textFontSize'] }]

const piccarouselnine = [{ className: '颜色', fieldList: ['titleColor', 'backgroundColor'] }, { className: '轮播图', fieldList: ['parentHeight', 'parentWidth', 'speedTime', 'delayTime', 'pagination'] }]

const pictorialbar = [{ className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }, { className: '饼状图', fieldList: ['picture_symbols'] }]

const pie_doughnut = [{ className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['backgroundColor'] }, { className: '饼状图', fieldList: ['pieWidth', 'selected_part', 'startLine', 'endLine', 'secondPieWidth'] }, { className: '折线图', fieldList: ['lineColor'] }, { className: '文本', fieldList: ['numberFontSize'] }]

const pieliquid = [{ className: '饼状图', fieldList: ['pieWidth', 'piePosition', 'secondPieWidth', 'pieLiquidHeight', 'textColorList', 'backgroundColor', 'lineColor', 'period'] }, { className: '文本', fieldList: ['isShowSeriesLabel', 'numberFontSize'] }]

const pietypefour = [
  { className: '图表类型', fieldList: ['barStandardSelect'] },
  {
    className: '图例',
    fieldList: ['legend_left', 'legend', 'legend_top', 'legendTextSize', 'legendItemGap', 'legendItemWidth', 'legendItemHeight']
  }, {
    className: '颜色',
    fieldList: ['backgroundColor']
  }, {
    className: '饼状图',
    fieldList: ['piePosition', 'pieWidth', 'startLine', 'endLine', 'secondPieWidth', 'sumNum']
  }, {
    className: '文本',
    fieldList: ['numberFontSize', 'textColorList']
  }
]

const piccarouselseven = [{ className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['iconColor', 'titleColor', 'backgroundColor'] }, { className: '轮播图', fieldList: ['targetWidth', 'parentHeight', 'targetHeight', 'speedTime', 'delayTime', 'textBodyWidth', 'parentWidth', 'iconHeight', 'targetMarginLeft'] }, { className: '文本', fieldList: ['textFontSize'] }]

const piccarouseleight = [{ className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['titleColor', 'backgroundColor'] }, { className: '轮播图', fieldList: ['targetWidth', 'parentHeight', 'delayTime', 'parentWidth', 'targetHeight'] }, { className: '文本', fieldList: ['textFontSize'] }]

const pietypethird = [{ className: '坐标轴', fieldList: ['splitNumber'] }, { className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['backgroundColor'] }, { className: '饼状图', fieldList: ['pieWidth', 'startLine', 'endLine', 'secondPieWidth', 'sumNum'] }, { className: '折线图', fieldList: ['lineColor'] }, { className: '文本', fieldList: ['numberFontSize'] }]

const piccarouselten = [{ className: '轮播图', fieldList: ['parentWidth', 'targetWidth', 'delayTime', 'targetHeight', 'parentHeight'] }, { className: '颜色', fieldList: ['backgroundColor', 'titleColor'] }, { className: '文本', fieldList: ['textFontSize'] }, { className: '图例', fieldList: ['legend'] }]

const piccarouselsix = [{ className: '颜色', fieldList: ['iconColor', 'titleColor', 'backgroundColor'] }, { className: '轮播图', fieldList: ['targetWidth', 'parentHeight', 'targetHeight', 'speedTime', 'delayTime', 'textBodyWidth', 'parentWidth', 'iconHeight', 'targetMarginLeft'] }]

const pietypesecond = [{ className: '图例', fieldList: ['legend_left', 'legend', 'legend_top'] }, { className: '颜色', fieldList: ['backgroundColor'] }, { className: '饼状图', fieldList: ['piePosition', 'pieWidth', 'startLine', 'endLine', 'secondPieWidth', 'sumNum'] }, { className: '文本', fieldList: ['numberFontSize', 'textColorList'] }]

const pietypefirst = [{ className: '坐标轴', fieldList: ['splitNumber'] }, { className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['backgroundColor'] }, { className: '饼状图', fieldList: ['pieWidth', 'startLine', 'endLine', 'secondPieWidth', 'sumNum'] }, { className: '折线图', fieldList: ['lineColor'] }, { className: '文本', fieldList: ['numberFontSize'] }]

const piccarouselthird = [{ className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['iconColor', 'titleColor', 'backgroundColor'] }, { className: '轮播图', fieldList: ['targetWidth', 'parentHeight', 'targetHeight', 'speedTime', 'delayTime', 'textBodyWidth', 'parentWidth', 'iconHeight', 'targetMarginLeft'] }, { className: '文本', fieldList: ['textFontSize'] }]

const piccarouselsecond = [{ className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['iconColor', 'titleColor', 'backgroundColor'] }, { className: '轮播图', fieldList: ['targetWidth', 'parentHeight', 'targetHeight', 'speedTime', 'delayTime', 'textBodyWidth', 'parentWidth', 'iconHeight', 'targetMarginLeft'] }, { className: '文本', fieldList: ['textFontSize'] }]

const ringproportionoftwo = [{ className: '颜色', fieldList: ['gradientColorEnd', 'backgroundColor', 'barBackgroundColor', 'gradientColorStart'] }, { className: '饼状图', fieldList: ['pieWidth', 'secondPieWidth'] }, { className: '文本', fieldList: ['numberFontSize', 'numberColor'] }]

const radar = [{ className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const scatter_weight = [{ className: '坐标轴', fieldList: ['x_axis_unit', 'x_ticks_namelocation', 'x_ticks_namegap', 'y_axis_unit'] }, { className: '标题', fieldList: ['block_name', 'title', 'sub_title'] }, { className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const treemap_echarts = []

const treemap_disk = [{ className: '颜色', fieldList: ['color_echarts_theme'] }]

const twocolumnbar = [{ className: '坐标轴', fieldList: ['YlabelToAxis', 'axisFontSize'] }, { className: '图例', fieldList: ['legend'] }, { className: '柱状图', fieldList: ['barWidth', 'barRadius'] }, { className: '颜色', fieldList: ['gradientColorEnd', 'titleColor', 'backgroundColor', 'color_echarts_theme', 'barBackgroundColor', 'gradientColorStart'] }, { className: '文本', fieldList: ['textColor'] }]

const textbarvertical = [{ className: '坐标轴', fieldList: ['YlabelToAxis', 'axisFontSize'] }, { className: '图例', fieldList: ['legend'] }, { className: '柱状图', fieldList: ['barWidth', 'barRadius'] }, { className: '颜色', fieldList: ['gradientColorEnd', 'titleColor', 'backgroundColor', 'color_echarts_theme', 'barBackgroundColor', 'gradientColorStart'] }, { className: '饼状图', fieldList: ['emphasisObject'] }, { className: '文本', fieldList: ['textColor'] }, { className: '其他', fieldList: ['isAescending'] }]

const tablestatic = [{ className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['backgroundColor', 'titleBackgroundColor'] }, { className: '折线图', fieldList: ['lineColor'] }, { className: '轮播图', fieldList: ['showIcon', 'rowHeight', 'iconHeight'] }, { className: '文本', fieldList: ['textColor', 'textFontSize'] }]

const tablerimless = [{ className: '颜色', fieldList: ['titleColor', 'backgroundColor', 'selectedColor'] }, { className: '饼状图', fieldList: ['selected_part'] }, { className: '轮播图', fieldList: ['rowWidth', 'rowHeight'] }, { className: '文本', fieldList: ['textColor', 'textFontSize'] }]

const third_bar_line = [
  {
    className: '坐标轴',
    fieldList: axisSettins
  },
  { className: '图例', fieldList: ['legend'] },
  { className: '柱状图', fieldList: ['is_exchange_bar_line', 'lineSmoothNumber', 'barWidth', 'barBorderRadius', 'cancelBarLineColor'] },
  { className: '颜色', fieldList: ['gradientColorStart', 'gradientColorEnd', 'backgroundColor', 'color_echarts_theme'] },
  { className: '折线图', fieldList: ['lineColor'] }
]

const tree_basic = [{ className: '颜色', fieldList: ['color_echarts_theme'] }]

const table_doublecol = [{ className: '柱状图', fieldList: ['barWidth'] }, { className: '轮播图', fieldList: ['show_row_limit'] }, { className: '文本', fieldList: ['dateFontSize', 'textFontSize'] }]

const timetext = [
  {
    className: '图表类型',
    fieldList: ['barStandardSelect']
  }, {
    className: '坐标轴',
    fieldList: ['textGap', 'x_axis_unit', 'echarts_timestamp_format']
  }, {
    className: '颜色',
    fieldList: ['color_echarts_theme']
  }, {
    className: '文本',
    fieldList: ['textColor', 'textFontSize', 'digtalfontFamily']
  }, {
    className: '其他',
    fieldList: ['isCurrentDate', 'timeLabel']
  }
]

const table_carousel = [{ className: '坐标轴', fieldList: ['x_axis_unit', 'x_ticks_namelocation', 'x_ticks_namegap'] }, { className: '标题', fieldList: ['block_name', 'title', 'sub_title'] }, { className: '图例', fieldList: ['legend'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }, { className: '轮播图', fieldList: ['show_row_limit'] }]

const world_map_echart = [
  {
    className: '标题',
    fieldList: ['title']
  }, {
    className: '图例',
    fieldList: ['legend']
  }, {
    className: '类型',
    fieldList: ['countryMap']
  }, {
    className: '颜色',
    fieldList: ['color_echarts_theme']
  }, {
    className: '折线图',
    fieldList: ['areaColor']
  }, {
    className: '轮播图',
    fieldList: ['iconWidth', 'iconHeight']
  }, {
    className: '文本',
    fieldList: ['textFontSize']
  }
]

const watermark = [{ className: '标题', fieldList: ['title'] }, { className: '颜色', fieldList: ['color_echarts_theme'] }]

const weatherpredict = [
  {
    className: '图表类型',
    fieldList: ['barStandardSelect']
  },
  {
    className: '颜色',
    fieldList: ['backgroundColor']
  }, {
    className: '轮播图',
    fieldList: ['parentHeight', 'iconHeight', 'iconWidth', 'parentWidth']
  }, {
    className: '文本',
    fieldList: ['textFontSize']
  }, {
    className: '其他',
    fieldList: ['siteNamePY']
  }
]

// 图表配置项对象
export const chartSettings = {
  arearadar,
  bubble_multi,
  bar_polar,
  bar3d,
  boxplot_echarts,
  bar_animation,
  barpictorialbarsecond,
  barpictorialbarfirst,
  barsort,
  barstandard,
  bar_brush,
  basefilter,
  barlinepictorialbar,
  bubble_timeline,
  otherchart,
  calendar_echarts,
  custom_error_bar,
  circlenumber,
  doublecolorbar,
  directional_force,
  digitalflop,
  digitalflopcarousel,
  dataset_link,
  directed_force,
  effectscatter_bmap,
  first_bar_one,
  flow_diagram,
  first_line_double,
  firstbaronesecond,
  globe_layers,
  gauge,
  hubei,
  horizontaldoublebar,
  htmlchart1,
  htmlchartset1,
  hubei_map,
  hubeiline,
  horizontalbar,
  mapflowline,
  map2d_and_bar,
  movetablerimless,
  mapchina,
  movetablefontstyle,
  mix_timeline_finance,
  map_and_bar,
  map_and_bar3d,
  mix_line_bar,
  movehovertable,
  mapflowlinetwo,
  maptooltipmove,
  movebackcolortable,
  multiline,
  numbertrendicon,
  number_flop,
  numflip,
  pierose,
  progressbar,
  piccarousel,
  piccarouselevn,
  piccarouselfive,
  piccarouselfour,
  piccarouselnine,
  pie_doughnut,
  pieliquid,
  pietypefour,
  piccarouselseven,
  piccarouseleight,
  pietypethird,
  piccarouselsix,
  piccarouselten,
  pictorialbar,
  pietypesecond,
  pietypefirst,
  piccarouselthird,
  piccarouselsecond,
  ringproportionoftwo,
  radar,
  scatter_weight,
  treemap_echarts,
  treemap_disk,
  twocolumnbar,
  textbarvertical,
  tablestatic,
  tablerimless,
  third_bar_line,
  tree_basic,
  table_doublecol,
  timetext,
  table_carousel,
  world_map_echart,
  watermark,
  weatherpredict
  // @config
}
