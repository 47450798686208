import echarts from 'echarts'
import { commonFunc } from '../chartCommonPart'
import { setXaxisDefault, setYaxisDefault, setGrid } from '../../echartSetting/axis'

function BarBrush (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const originData = props.data[0].content_data
  const legend = props.data[0].legend
  const stackRes = props.data[0].stack_res
  const tableType = props.data[0].table_type

  const lineColor = props.data[0].lineColor
  const backgroundColor = props.data[0].backgroundColor
  const cancelBarLineColor = props.data[0].cancelBarLineColor
  const barWidth = props.data[0].barWidth
  const lineWidth = props.data[0].lineWidth
  const textColorList = props.data[0].textColorList
  const endTextColorList = props.data[0].endTextColorList
  // 设置坐标轴的样式
  const barBorderRadius = props.data[0].barBorderRadius

  var itemStyle = {
    normal: {
      barBorderRadius: barBorderRadius
    },
    emphasis: {
      barBorderRadius: barBorderRadius,
      barBorderWidth: 1,
      shadowBlur: 10,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowColor: 'rgba(0,0,0,0.5)'
    }
  }
  // TODO 给bar图 添加渐变色
  if (cancelBarLineColor === false) {
    itemStyle.normal = {
      color: lineColor
    }
  }

  const series = []
  originData.forEach(function (item, i) {
    if (tableType === 'bar' && originData.length === 1) {
      series.push({
        name: legend[i],
        type: tableType,
        stack: stackRes[i],
        barWidth: barWidth,
        itemStyle: {
          // color: barColor
          barBorderRadius: barBorderRadius,
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 1, color: '#124A4C' // 0% 处的颜色
            }, {
              offset: 0, color: lineColor // 100% 处的颜色
            }],
            global: false // 缺省为 false
          }
        },
        data: item
      })
    } else if (tableType === 'line' && originData.length === 1) {
      series.push({
        name: legend[i],
        type: 'line',
        stack: stackRes[i],
        itemStyle: itemStyle,
        lineStyle: {
          width: lineWidth
        },
        data: item,
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 1, color: 'rgba(255,255,255,0)'
            }, {

              offset: 0.3, color: lineColor
            }, {

              offset: 0, color: lineColor
            }
            ]
          }
          // opacity: 0.4
        }
      })
    } else {
      // console.log('third', endTextColorList)
      series.push({
        name: legend[i],
        type: tableType,
        barWidth: barWidth,
        stack: stackRes[i],
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 1.0, color: textColorList[i] // 0% 处的颜色
            }, {
              offset: 0.2, color: endTextColorList[i] // 100% 处的颜色
            }],
            global: false // 缺省为 false
          },
          barBorderRadius: barBorderRadius,
          barBorderWidth: 1,
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'rgba(0,0,0,0.5)'
        },
        data: item
      })
    }
  })
  const option = {
    backgroundColor: backgroundColor,
    legend: {
      // legend: 可设置
      show: false,
      itemHeight: 10,
      itemWidth: 10,
      data: legend,
      align: 'right',
      right: '7%',
      top: '5%'
    },
    tooltip: {},
    xAxis: setXaxisDefault(props.data[0]),
    yAxis: setYaxisDefault(props.data[0]),
    grid: setGrid(props.data[0]),
    series: series
  }

  myChart.on('brushSelected', renderBrushed)

  function renderBrushed (params) {
    var brushed = []
    var brushComponent = params.batch[0]

    for (var sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
      var rawIndices = brushComponent.selected[sIdx].dataIndex
      brushed.push('[Series ' + sIdx + '] ' + rawIndices.join(', '))
    }

    myChart.setOption({
      title: {
        backgroundColor: 'rgba(251,255,219,0)',
        text: 'SELECTED DATA INDICES: \n' + brushed.join('\n'),
        bottom: 0,
        right: 0,
        width: 100,
        textStyle: {
          fontSize: 12,
          color: '#fff'
        }
      }
    })
  }

  const startPlay = commonFunc(myChart, option, props)

  return startPlay
};

export default BarBrush // 修改为Classname
