import { SUPERSET_URL } from '~/config'
// 图表点击设置
const hideDelayTime = 3000
// mousemove|click
const trigger = 'click'

const commonFunc = function (myChart, option, props) {
  // 参数： echart实例，option配置项，传入的porps
  // 实现的功能：渲染 echart图表;添加事件点击监听\; 设置图表刷新功能

  option.backgroundColor = 'rgba(0,0,0,0)'

  // 使用上述配置显示图标
  myChart.setOption(option)

  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    console.log(gorupByValue)
    props.onInteract(gorupByValue)
  })

  // 设置图表生成动画, 根据页面设置,确定是否开启动画
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

const manageImgPath = function (path) {
  const imgPath = SUPERSET_URL + path
  return imgPath
}

const numFormatter = function (num) {
  var str = num.toString()
  var reg =
    str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
  return str.replace(reg, '$1,')
}

const chinaMapData = {
  黑龙江: [127.9688, 45.368],
  内蒙古: [110.3467, 41.4899],
  吉林: [125.8154, 44.2584],
  北京市: [116.4551, 40.2539],
  辽宁: [123.1238, 42.1216],
  河北: [114.4995, 38.1006],
  天津: [117.4219, 39.4189],
  山西: [112.3352, 37.9413],
  陕西: [109.1162, 34.2004],
  甘肃: [103.5901, 36.3043],
  宁夏: [106.3586, 38.1775],
  青海: [101.4038, 36.8207],
  新疆: [87.9236, 43.5883],
  西藏: [91.11, 29.97],
  四川: [103.9526, 30.7617],
  重庆: [108.384366, 30.439702],
  山东: [117.1582, 36.8701],
  河南: [113.4668, 34.6234],
  江苏: [118.8062, 31.9208],
  安徽: [117.29, 32.0581],
  湖北: [114.3896, 30.6628],
  浙江: [119.5313, 29.8773],
  福建: [119.4543, 25.9222],
  江西: [116.0046, 28.6633],
  湖南: [113.0823, 28.2568],
  贵州: [106.6992, 26.7682],
  云南: [102.9199, 25.4663],
  广东: [113.12244, 23.009505],
  广西: [108.479, 23.1152],
  海南: [110.3893, 19.8516],
  上海: [121.4648, 31.2891]
}

const proviceIndex = [
  '黑龙江',
  '内蒙古',
  '吉林',
  '北京市',
  '辽宁',
  '河北',
  '天津',
  '山西',
  '陕西',
  '甘肃',
  '宁夏',
  '青海',
  '新疆',
  '西藏',
  '四川',
  '重庆',
  '山东',
  '河南',
  '江苏',
  '安徽',
  '湖北',
  '浙江',
  '福建',
  '江西',
  '湖南',
  '贵州',
  '云南',
  '广东',
  '广西',
  '海南'
]

export {
  hideDelayTime,
  trigger,
  commonFunc,
  manageImgPath,
  numFormatter,
  chinaMapData,
  proviceIndex

}
