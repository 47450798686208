<template>
  <div>
    <div>
      <!-- <div @click @mousemove></div> -->
      <div :width="chartWidth" :height="chartHeight" ref="chartEarth">
        <div class="earthChart-label"></div>
      </div>
      <!-- <canvas ref="chart" :width="chartWidth" :height="chartHeight"></canvas> -->
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
export default {
  data () {
    return {}
  },
  computed: {
    chartWidth () {
      return this.props.width
    },
    chartHeight () {
      return this.props.height
    }
  },
  mounted () {
    this.toView()
  },
  methods: {
    toView () {
      const chartViewWidth = this.props.width
      const chartViewHeight = this.props.height

      var camera, scene, renderer
      let controls
      const DEFAULT = true
      const params = new function () {
        this.earthSpeed = 1
        this.ambientLight = DEFAULT // 环境光
        this.directionalLight = false // 平行光
        this.pointLight = false // 点光源
        this.showEarthCopy = DEFAULT // 显示外层透明地球
        this.showLayer = DEFAULT // 添加 遮罩层
        this.showFlyLine = DEFAULT // 是否显示飞线
        this.showEarthLine = DEFAULT // 是否显示地球线(真正的渐变线条)
        this.showParticle = DEFAULT // 是否显示粒子特效的粒子(线)
        // this.showOuterPoints = false //添加外层粒子
        this.showOuterPoints = DEFAULT // 添加外层粒子
        this.usePass = DEFAULT // 后期处理
        this.strength = 0.05 // 处理强度
        this.showDots = true
        this.showLines = true
        this.minDistance = 50
        this.limitConnections = false
        this.maxConnections = 14
        this.particleCount = 440
      }()
      const R = 200 // 地球半径
      const outGap = 30 // 内外球体之间的距离
      const moonGroup = new THREE.Group()
      let circleNum = 0 // 球体表面圆形的数量
      const loader = new THREE.TextureLoader()
      const light = {}
      const flyLines = []
      const earthParticles = new THREE.Object3D()
      let earth
      let outPointCloud
      const hexagon = new THREE.Object3D() // 光柱
      const allGroup = new THREE.Group()
      const lightCross = new THREE.Group()
      let number = 0
      const clock = new THREE.Clock()

      // viewStatus
      let isChinaView = false

      // 添加点击交互事件
      const raycaster = new THREE.Raycaster()
      const mouse = new THREE.Vector2()
      let selectObject
      const cityPlanes = []
      let cameraMixer
      const cameraDiv = 2
      let moveEnd = false
      // let controlsMixer
      // 添加标记,确保当窗口已经放大完毕后,再渲染更新文本
      let mouseOverObject

      const init = () => {
        renderer = new THREE.WebGLRenderer({
          antialias: true,
          autoClear: true
          // canvas: this.$refs.chart
        })
        renderer.setPixelRatio(window.devicePixelRatio)
        renderer.setSize(this.props.width, this.props.height) // 设置Canvas画布大小
        renderer.toneMapping = THREE.ReinhardToneMapping
        // todo 必须打开该阴影，才能生效
        // renderer.shadowMapEnabled = true
        // TODO 修改dom 绑定方法
        this.$refs.chartEarth.appendChild(renderer.domElement) // 将画布渲染器绑定到新增的dom节点上；
        // document.getElementById('example').appendChild(renderer.domElement) // 将画布渲染器绑定到新增的dom节点上；

        scene = new THREE.Scene()
        // scene.add(new THREE.GridHelper(10, 50))
        // fixme 添加雾化效果,
        scene.fog = new THREE.Fog('#000000', 550, 900)

        camera = new THREE.PerspectiveCamera(
          45,
          this.props.width / this.props.height,
          10,
          1000
        )
        camera.position.set(0, 0, 400)
        camera.lookAt(0, 0, 0)

        scene.add(new THREE.AmbientLight(0xaaaaaa, 1))

        controls = baseControls(camera, renderer.domElement)
        controls.addEventListener('change', render)
        controls.minDistance = 540
        controls.maxDistance = 550
        controls.enabled = true // 是否开启交互
        controls.autoRotateSpeed = 1 // 自转速度

        // ========================================== 添加点击事件 ====================================
        this.$refs.chartEarth.addEventListener('resize', onWindowResize, false)
        // window.addEventListener("click", onDocumentMouseClick, false)
        // window.addEventListener('click', onMouseClick, false)
        // window.addEventListener('mousemove', onMouseOver, false)
        this.$refs.chartEarth.addEventListener('mousemove', onMouseOver, false)
        this.$refs.chartEarth.addEventListener('click', onMouseClick, false)
      }

      function baseControls (camera, dom) {
        // 相机, dom节点, 渲染函数
        const control = new OrbitControls(camera, dom)
        control.enableDamping = true // 使动画循环使用时阻尼或自转 意思是否有惯性
        control.enableZoom = true // 是否可以缩放
        control.autoRotate = true // 开启自转
        control.autoRotateSpeed = 0.3 // 自传速度
        control.enablePan = true // 是否开启右键拖拽
        // control.maxPolarAngle = Math.PI * 0.5;
        // control.minDistance = 1;
        // control.maxDistance = 100;
        // 需要设置该参数,添加拖动效果
        return control
      }
      // ========================================== earthLib 函数 ========================================
      const geographicToVector = (radius, lng, lat) =>
        new THREE.Vector3().setFromSpherical(
          new THREE.Spherical(
            radius,
            (90 - lat) * (Math.PI / 180),
            (90 + lng) * (Math.PI / 180)
          )
        )

      const addCircle = function (position) {
        const CircleRadius = 4
        const hexagonLine = new THREE.CircleGeometry(CircleRadius, 15)
        const vertices = hexagonLine.vertices
        vertices.shift() // 第一个节点是中心点
        const circleLineGeom = new THREE.Geometry()
        circleLineGeom.vertices = vertices
        const circleLine = new THREE.LineLoop(
          circleLineGeom,
          new THREE.MeshBasicMaterial({
            color: '#009A65',
            side: THREE.DoubleSide,
            depthTest: true
          })
        )
        circleLine.position.copy(position)
        // 设置朝向圆点
        circleLine.lookAt(new THREE.Vector3(0, 0, 0))
        return circleLine
      }

      const setCircle = function (divisions = 100, raduis = 100) {
        const vertices = []
        for (let i = 0; i <= divisions; i++) {
          const v = (i / divisions) * (Math.PI * 2)
          const x = raduis * Math.sin(v)
          const z = raduis * Math.cos(v)
          vertices.push(x, 0, z)
        }
        return vertices
      }

      // 绘制轨道卫星线条
      const moonLine = function (R, edg) {
        const pointNum = 60
        const points = setCircle(pointNum, R)
        const geometryArc = new THREE.BufferGeometry()
        geometryArc.setAttribute(
          'position',
          new THREE.Float32BufferAttribute(points, 3)
        )
        // 为外部的圆弧添加样式
        const mt = new THREE.LineBasicMaterial({
          color: '#00ffa7',
          renderOrder: 10,
          transparent: true,
          linewidth: 1.2,
          opacity: 0.6
        })

        const line = new THREE.Line(geometryArc, mt)
        line.rotateX(edg)
        line.rotateZ(edg)
        // line.material.linewidth = 2;
        // line.material.color = '#00ffa';
        // line.material.transparent = true;
        return line
      }

      const skyTexture = function (scene) {
        const texture = new THREE.TextureLoader().load(
          require('./earth/images/backgroupImg.png')
        )
        console.log('skyTexture', texture)
        scene.background = texture
      }

      const cloud = new THREE.Object3D()
      const createCloudGrid = function (scene, radius) {
        const XRayMaterial = function (options) {
          const uniforms = {
            uTex: {
              type: 't',
              value: options.map || new THREE.Texture()
            },
            offsetRepeat: {
              value: new THREE.Vector4(0, 0, 1, 1)
            },
            alphaProportion: {
              type: '1f',
              value: options.alphaProportion || 0.5
            },
            diffuse: {
              value: options.color || new THREE.Color(16777215)
            },
            opacity: {
              value: options.opacity || 1
            },
            gridOffset: {
              value: 0
            }
          }
          return new THREE.ShaderMaterial({
            uniforms: uniforms,
            vertexShader: `
                        varying float _alpha;
                        varying vec2 vUv;
                        uniform vec4 offsetRepeat;
                        uniform float alphaProportion;
                        void main() {
                        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                        vUv = uv * offsetRepeat.zw + offsetRepeat.xy;
                        vec4 worldPosition = modelMatrix * vec4( vec3( position ), 1.0 );
                        vec3 cameraToVertex = normalize( cameraPosition - worldPosition.xyz);
                        _alpha = 1.0 - max( 0.0, dot( normal, cameraToVertex ) );
                        _alpha = max( 0.0, (_alpha - alphaProportion) / (1.0 - alphaProportion) );
                        }`,
            fragmentShader: `
                            uniform sampler2D uTex;
                            uniform vec3 diffuse;
                            uniform float opacity;
                            uniform float gridOffset;
                            varying float _alpha;
                            varying vec2 vUv;
                            void main() {
                            vec4 texColor = texture2D( uTex, vUv );
                            float _a = _alpha * opacity;
                            if( _a <= 0.0 ) discard;
                            _a = _a * ( sin( vUv.y * 2000.0 + gridOffset ) * .5 + .5 );
                            gl_FragColor = vec4( texColor.rgb * diffuse, _a );
                            }`,
            transparent: !0,
            blending: THREE.AdditiveBlending,
            depthTest: !1
          })
        }
        const geometry = new THREE.SphereGeometry(1.25 * radius, 66, 44)
        const map = new THREE.TextureLoader().load(
          require('./earth/images/clouds.jpg')
        )
        map.wrapT = THREE.ClampToEdgeWrapping
        map.wrapS = THREE.ClampToEdgeWrapping
        const material = XRayMaterial({
          map: map,
          alphaProportion: 0.25,
          color: new THREE.Color(263385797),
          opacity: 0,
          gridOffsetSpeed: 0.6
        })
        const mesh = new THREE.Mesh(geometry, material)
        mesh.matrixAutoUpdate = !1
        cloud.add(mesh)
        scene.add(cloud)
      }

      const colorIndex = function (r, g, b) {
        let index = 0
        const currentColor = 'rgba(' + r + ',' + g + ',' + b + ')'
        const distractColors = [
          'rgba(17,255,0)',
          'rgba(255,52,38)',
          'rgba(39,54,255)',
          'rgba(255,255,255)',
          'rgba(0,154,101)',
          'rgba(255,0,204)',
          'rgba(255,243,80)'
        ]
        // console.log('current color', currentColor)
        if (currentColor === distractColors[0]) {
          index = 1
        } else if (currentColor === distractColors[1]) {
          index = 2
        } else if (currentColor === distractColors[2]) {
          index = 3
        } else if (currentColor === distractColors[3]) {
          index = 4
        } else if (currentColor === distractColors[4]) {
          index = 5
        } else if (currentColor === distractColors[5]) {
          index = 6
        } else if (currentColor === distractColors[6]) {
          index = 7
        } else if (currentColor === distractColors[7]) {
          index = 8
        } else if (currentColor === distractColors[8]) {
          index = 9
        } else {
          // 非空
          index = 0
        }
        return index
      }

      // ========================================== 添加物体 ========================================
      // ================== add earth
      const loadFile = callback => {
        // earth_line2 worldAndChind worldMap  ChinaInner
        const textureImg = require('./earth/images/havingChinaLine.png')
        loader.load(textureImg, textureNormal => {
          earth = new THREE.Mesh(
            new THREE.SphereBufferGeometry(R, 50, 50),
            new THREE.MeshPhongMaterial({
              transparent: true,
              depthTest: false,
              opacity: 1.0,
              shininess: 80,
              specular: '#00FFA7',
              shadowSide: THREE.DoubleSide,
              castShadow: false,
              map: textureNormal,
              needsUpdate: true
            })
          )
          allGroup.add(earth)
          earth.receiveShadow = true
          callback()
        })
      }

      function addLight () {
        // 环境光越弱, 光柱的效果越明显
        const ambientLight = new THREE.AmbientLight('#009A65', 1)
        scene.add(ambientLight)

        // 添加平行光  ==> 不显示
        const directionalLight = new THREE.DirectionalLight('#009A65', 30)
        directionalLight.position.set(R * 4, R * 4, R * 4)
        scene.add(directionalLight)
        // 点光源 ==> 不显示
        const pointLight = new THREE.PointLight('#009A65', 10)
        pointLight.position.set(R * 4, R * 4, R * 4)
        scene.add(pointLight)

        // 设置光源的可见性
        ambientLight.visible = params.ambientLight
        directionalLight.visible = false // 不显示
        pointLight.visible = false // 不显示

        light.ambientLight = ambientLight
        light.directionalLight = directionalLight
        light.pointLight = pointLight
      }

      // 外部 点点星光效果
      function addOutPointCloud () {
        // Geometry 作为 BufferGeometry 的替代品, 大型项目用 BufferGeometry
        const geo = new THREE.Geometry()
        /** 绕圈取点 **/
        const vector = new THREE.Vector3()
        // l点的数量 500
        for (let i = 1, l = 50; i <= l; i++) {
          // 计算 反余弦弧度值
          const phi = Math.acos(-1 + (2 * i) / l)
          // 计算 平方根
          const theta = Math.sqrt(l * Math.PI) * phi
          // 生成半径为R + 30 球体上的点
          vector.setFromSphericalCoords(R + outGap, phi, theta)

          const v0 = new THREE.Vector3(vector.x, vector.y, vector.z)
          geo.vertices.push(v0)

          // 根据上面球体上的点, 再随机生成10个点
          for (let j = 0; j < 5; j++) {
            const v1 = v0.clone()
            const v = new THREE.Vector3(
              v1.x * (1.2 + Math.random() / (j + 1)),
              v1.y * (1.4 + Math.random() / (j + 1)),
              v1.z * (1.3 + Math.random() / (j + 1))
            )
            geo.vertices.push(v)
          }
        }
        outPointCloud = new THREE.Points(
          geo,
          new THREE.PointsMaterial({
            color: '#07D46E',
            size: 6, // 外部点的尺,寸
            transparent: true, // 透明
            opacity: 0.5
          })
        )

        outPointCloud.geometry.verticesNeedUpdate = true

        outPointCloud.visible = params.showOuterPoints

        allGroup.add(outPointCloud)
      }

      /**
       *  添加光柱和底座
       */
      function addLightPillars () {
        const D_VALUE = 1 // 差值
        const HEXAGON_RADIUS = 5 // 底座的半径
        const coneImg = require('./earth/images/lightray.png')
        const hexagonColor = ['#FFF350', '#FFF350', '#FFFFFF', '#FFFFFF']

        const countries = mark.marking

        const texture = new THREE.TextureLoader().load(coneImg)
        // 添加光柱和底座
        for (let i = 0, length = countries.length; i < length; i++) {
          const cityName = countries[i].name
          // 修改柱子与台子的位置
          const position = geographicToVector(
            R + 1,
            countries[i].pos[0],
            countries[i].pos[1]
          )
          const index = Math.floor(Math.random() * 4)
          addPedestal(position, index, cityName) // 地标
          addLightCross(position, index, texture) // 光锥
        }

        scene.add(lightCross)

        // 添加底座
        function addPedestal (position, index, name) {
          const color = hexagonColor[index]
          // 生成六边形
          const hexagonLine = new THREE.CircleGeometry(HEXAGON_RADIUS, 6)
          const hexagonPlane = new THREE.CircleGeometry(
            HEXAGON_RADIUS - D_VALUE,
            6
          )
          const vertices = hexagonLine.vertices
          vertices.shift() // 第一个节点是中心点
          const circleLineGeom = new THREE.Geometry()
          circleLineGeom.vertices = vertices
          const circleLine = new THREE.LineLoop(
            circleLineGeom,
            new THREE.MeshBasicMaterial({
              color: color,
              side: THREE.DoubleSide,
              depthTest: true
            })
          )
          const circlePlane = new THREE.Mesh(
            hexagonPlane,
            new THREE.MeshBasicMaterial({
              color: color,
              side: THREE.DoubleSide,
              opacity: 0.5,
              depthTest: true
            })
          )
          circleLine.position.copy(position)
          circlePlane.position.copy(position)
          // 设置朝向圆点
          circlePlane.lookAt(new THREE.Vector3(0, 0, 0))
          circleLine.lookAt(new THREE.Vector3(0, 0, 0))

          circlePlane.name = name
          cityPlanes.push(circlePlane)
          hexagon.add(circleLine)
          hexagon.add(circlePlane)
          scene.add(hexagon)
        }

        // 添加光柱
        function addLightCross (position, index, texture) {
          const height = 80
          const geometry = new THREE.PlaneGeometry(HEXAGON_RADIUS * 3, height)
          const matrix1 = new THREE.Matrix4()
          const plane1 = new THREE.Mesh(
            geometry,
            new THREE.MeshBasicMaterial({
              map: texture,
              color: hexagonColor[index],
              transparent: true,
              depthTest: true,
              opacity: 1,
              side: THREE.DoubleSide,
              blending: THREE.AdditiveBlending
            })
          )
          matrix1.makeRotationX(Math.PI / 2)
          matrix1.setPosition(new THREE.Vector3(0, 0, height / -2))
          geometry.applyMatrix(matrix1)
          const plane2 = plane1.clone()
          plane2.rotation.z = Math.PI / 2
          plane1.add(plane2)
          plane1.position.copy(position)
          plane1.lookAt(0, 0, 0)
          lightCross.add(plane1)
        }
      }

      function createEarthParticles () {
        // 必须先创建一个节点, 作为canvas的画板, 绘制点
        const earthImg = document.createElement('img')
        // chinaAndcolor  earthSpec
        earthImg.src = require('./earth/images/chinaAndcolor.png')
        // 图片加载后,再绘制图片
        earthImg.onload = () => {
          const earthCanvas = document.createElement('canvas')
          const earthCtx = earthCanvas.getContext('2d')
          // 设置画板的大小
          earthCanvas.width = earthImg.width
          earthCanvas.height = earthImg.height
          // 在canvas上绘制图片: 图片实例, 坐标位置(2个参数), 图片尺寸(两个参数)
          earthCtx.drawImage(earthImg, 0, 0, earthImg.width, earthImg.height)
          // 获取图片的像素数据, 每个像素点 对应四个数值: r g b a
          const earthImgData = earthCtx.getImageData(
            0,
            0,
            earthImg.width,
            earthImg.height
          )
          // console.log(earthImgData)
          const BLINT_SPEED = 0.05
          const positions = []
          const materials = []
          const sizes = []
          // 根据颜色分为8个数组
          for (let i = 0; i < 9; i++) {
            positions[i] = {
              positions: []
            }
            sizes[i] = {
              sizes: []
            }
            const mat = new THREE.PointsMaterial({
              size: 4, // 设置圈的尺寸
              // color: new THREE.Color('#00FF6F'),  // 区域点颜色 0x03d98e ,
              color: new THREE.Color(distractColors[i]), // 区域点颜色 0x03d98e ,
              map: new THREE.TextureLoader().load(
                require('./earth/images/dot.png')
              ),
              depthWrite: false,
              depthTest: false,
              transparent: true,
              opacity: 0, // 透明度为0 , 默认不显示
              side: THREE.FrontSide,
              blending: THREE.AdditiveBlending
            })
            const n = i / 2
            mat.t_ = n * Math.PI * 2 // 取值: 0 => 0 ；1=> 2 * pi
            mat.speed_ = BLINT_SPEED
            mat.min_ = 0.2 * Math.random() + 0.5
            mat.delta_ = 0.1 * Math.random() + 0.1
            mat.opacity_coef_ = 1
            materials.push(mat)
          }
          // 绘制等大小的球体
          const spherical = new THREE.Spherical()
          spherical.radius = R
          const step = 230 // 每圈点的密度: 250
          // todo 控制维度方向的圆点的密度, 维度越高, 点的密度越小
          for (let i = 0; i < step; i++) {
            const vec = new THREE.Vector3()
            // let radians = step * (1 - Math.sin(i / step * Math.PI)) / step + .5; // 每个纬线圈内的角度均分
            const radians = 1 - Math.sin((i / step) * Math.PI) + 0.5 // 每个纬线圈内的角度均分
            for (let j = 0; j < step; j += radians) {
              const c = j / step // 底图上的横向百分比  ==> 纬度方向的分割: 0~1
              const f = i / step // 底图上的纵向百分比  ==> 经度方向的分割: 0~1
              const colorIndex = isLandByUV(c, f)
              if (colorIndex !== 10000) {
                // 根据横纵百分比判断在底图中的像素值的透明度是否为0
                // 范围: -Math.PI/2 ~ Math.PI * 1.5
                // 必须是这个范围, 否则 点会与下面的图对不上
                const pos = positions[colorIndex]
                const size = sizes[colorIndex]
                spherical.theta = c * Math.PI * 2 - Math.PI / 2 // 横纵百分比转换为theta和phi夹角
                // spherical.theta = c * Math.PI * 2; // 横纵百分比转换为theta和phi夹角
                spherical.phi = f * Math.PI // 横纵百分比转换为theta和phi夹角
                vec.setFromSpherical(spherical) // 夹角转换为世界坐标
                pos.positions.push(vec.x)
                pos.positions.push(vec.y)
                pos.positions.push(vec.z)
                //
                if (j % 3 === 0) {
                  size.sizes.push(6.0)
                }
              } else {
                // fixme 判断花费的时间太多了 ==> 考虑保存坐标,直接添加, 不再判断
                // 填加球体上圆圈
                if (Math.random() < 0.003 && circleNum < 100) {
                  circleNum++
                  spherical.theta = c * Math.PI * 2 - Math.PI / 2 // 横纵百分比转换为theta和phi夹角
                  // spherical.theta = c * Math.PI * 2; // 横纵百分比转换为theta和phi夹角
                  spherical.phi = f * Math.PI // 横纵百分比转换为theta和phi夹角
                  vec.setFromSpherical(spherical) // 夹角转换为世界坐标
                  // console.log('vec', vec)
                  const circleLine = addCircle(vec)
                  earthParticles.add(circleLine)
                }
              }
            }
          }
          for (let i = 0; i < positions.length; i++) {
            const pos = positions[i]
            const size = sizes[i]
            const bufferGeom = new THREE.BufferGeometry()
            const typedArr1 = new Float32Array(pos.positions.length)
            const typedArr2 = new Float32Array(size.sizes.length)
            // 将一般的数组转化为 Float32Array
            for (let j = 0; j < pos.positions.length; j++) {
              typedArr1[j] = pos.positions[j]
            }
            for (let j = 0; j < size.sizes.length; j++) {
              typedArr2[j] = size.sizes[j]
            }
            bufferGeom.addAttribute(
              'position',
              new THREE.BufferAttribute(typedArr1, 3)
            )
            bufferGeom.addAttribute(
              'size',
              new THREE.BufferAttribute(typedArr2, 1)
            )
            bufferGeom.computeBoundingSphere()
            const particle = new THREE.Points(bufferGeom, materials[i])
            // 为每个区域命名
            particle.name = 'China-' + i
            earthParticles.add(particle)
          }

          function isLandByUV (c, f) {
            if (!earthImgData) {
              // 底图数据
              console.error('data error!')
            }

            // 根据百分比, 计算图片中对应的坐标
            const n = parseInt(earthImg.width * c) // 横坐标   根据横纵百分比计算图象坐标系中的坐标
            const o = parseInt(earthImg.height * f) // 纵坐标   根据横纵百分比计算图象坐标系中的坐标
            // 计算坐标对应像素点的 A-alpha值, 判断透明度是否为0
            // 计算思路: 像素点为整数,(10, 20) 位置的点之前有 10 * 每行像素点数量 + 20
            // 每个像素点 的颜色由 4个数值记录, 所以再乘以4
            // 计算每个点的颜色值
            const potR = earthImgData.data[4 * (o * earthImgData.width + n)]
            const potG = earthImgData.data[4 * (o * earthImgData.width + n) + 1]
            const potB = earthImgData.data[4 * (o * earthImgData.width + n) + 2]
            const potA = earthImgData.data[4 * (o * earthImgData.width + n) + 3]
            let index = 10000
            if (potA !== 0) {
              index = colorIndex(potR, potG, potB)
              // console.log('each pot r g b a', pot_r, pot_g, pot_b, pot_a, index)
            }
            return index
          }

          scene.add(earthParticles)
        }
      }

      function addMoon () {
        const rList = [295, 268]
        const edgList = [Math.PI, Math.PI * 1.34]
        for (const i in rList) {
          const edg = edgList[i]
          const l = moonLine(rList[i], edg)
          moonGroup.add(l)
        }
        scene.add(moonGroup)
      }

      function onWindowResize () {
        var width = window.innerWidth
        var height = window.innerHeight

        camera.aspect = width / height
        renderer.setSize(width, height)
        camera.updateProjectionMatrix()
        // camera.position.set(450, 450, 450);
        render()
      }

      function onMouseOver (event) {
        event.preventDefault()
        // 通过鼠标点击位置,计算出 raycaster 所需点的位置,以屏幕为中心点,范围 -1 到 1
        // 鼠标文档坐标 转化为 空间坐标
        mouse.x = (event.offsetX / chartViewWidth) * 2 - 1
        mouse.y = -(event.offsetY / chartViewHeight) * 2 + 1
        // console.log(chartViewWidth, chartViewHeight, mouse);
        mouseOverIntersects()
      }

      const onMouseClick = event => {
        event.preventDefault()
        // 通过鼠标点击位置,计算出 raycaster 所需点的位置,以屏幕为中心点,范围 -1 到 1
        // 鼠标文档坐标 转化为 空间坐标
        mouse.x = (event.offsetX / chartViewWidth) * 2 - 1
        mouse.y = -(event.offsetY / chartViewHeight) * 2 + 1
        // console.log(chartViewWidth, chartViewHeight, mouse);
        getIntersects()
      }

      // 获取与射线相交的对象数组
      function getIntersects () {
        // 通过鼠标点击的位置(二维坐标)和当前相机的矩阵计算出射线位置
        raycaster.setFromCamera(mouse, camera)
        // 获取与射线相交的对象数组，其中的元素按照距离排序，越近的越靠前
        const intersects = raycaster.intersectObjects(cityPlanes)
        // console.log(intersects);
        if (
          intersects.length !== 0 &&
          intersects[0].object instanceof THREE.Mesh
        ) {
          const target = intersects[0].object
          if (target.name !== '') {
            // console.log(intersects);
            const meshPositon = target.position
            // selectObject = intersects[0].object;
            controls.minDistance = 200
            cameraMove(meshPositon) // 阻塞；动画执行完后才会向后执行
            // 标记是否已经放大
            moveEnd = true
            controls.autoRotate = false
            // 弱化背景
            earthParticles.children[100].material.size = 2
            earthParticles.children[100].material.opacity = 0.5
            earthParticles.children[100].material.needsUpdate = true
            // 进入中国视图
            isChinaView = true
            earth.material.map = changeMapMaterial()
            //  隐藏柱子
            hexagon.visible = false
            scene.remove(lightCross)
          }
        } else {
          // selectObject = undefined;
          if (moveEnd === true) {
            controls.minDistance = 550
            // todo 修改为动画，添加过渡效果
            // cameraReset();
            moveEnd = false
            controls.autoRotate = true
            // 弱化背景
            // earthParticles.children[100].material.size = 4;
            // 退出中国视图
            isChinaView = false
            earth.material.map = changeMapMaterial()
            // 显示柱子
            hexagon.visible = true
            scene.add(lightCross)
          }
        }
      }

      // 获取与射线相交的对象数组
      function mouseOverIntersects () {
        // 通过鼠标点击的位置(二维坐标)和当前相机的矩阵计算出射线位置
        raycaster.setFromCamera(mouse, camera)
        // 获取与射线相交的对象数组，其中的元素按照距离排序，越近的越靠前
        let intersects = raycaster.intersectObjects(earthParticles.children)
        if (intersects.length !== 0) {
          const target = intersects[0].object
          if (target.name !== '' && target.name !== 'China-0') {
            if (mouseOverObject !== undefined) {
              // 每次需要将上一个对象属性恢复
              mouseOverObject.material.size = 4
              mouseOverObject = undefined
            }
            // 标记鼠标悬浮对象, 并修改点的尺寸
            mouseOverObject = intersects[0].object
            mouseOverObject.material.size = 2
          } else {
            // 鼠标移到其他国家, 恢复hover 状态
            if (mouseOverObject !== undefined) {
              mouseOverObject.material.size = 4
              mouseOverObject = undefined
            }
          }
        }
        // fixme 添加鼠标悬浮弹窗
        intersects = raycaster.intersectObjects(hexagon.children)
        if (
          intersects.length !== 0 &&
          intersects[0].object instanceof THREE.Mesh
        ) {
          const target = intersects[0].object
          if (target.name !== '') {
            // 暂停旋转
            selectObject = intersects[0].object
            controls.autoRotate = false
          }
        } else {
          // 鼠标移到其他国家, 恢复hover 状态
          selectObject = undefined
          if (isChinaView === false) {
            controls.autoRotate = true
          }
        }
      }

      // 调整弹窗的位置
      function renderDiv (object) {
        const labelDom = document.getElementsByClassName('earthChart-label')[0]
        // console.log("view", labelDom);
        if (
          object !== undefined &&
          controls.autoRotate === false &&
          isChinaView === false
        ) {
          const halfWidth = window.innerWidth / 2
          const halfHeight = window.innerHeight / 2
          // 整个窗口中心点 0.0 ； x右边为正；y向上为正
          const vector = object.position.clone().project(camera)
          const sumValue = Math.round(object.position.x * 2500, 2)
          const EnName = countryToEn[object.name]
          labelDom.innerHTML = `<div id='tooltip' style="background-color: #FFFFFF; width: 380px; height: 160px; z-index:100000">
  <div class="tooltip-title">
    ${object.name}
  </div>
  <div class="tooltip-sub-title">
    ${EnName}
  </div>
  <div class="tooltip-number">
    <div class="tooltip-pot"></div>
    <span style="margin-right: 20px">2019年总发电量</span>
    <span>${sumValue}</span>
    <span class="tooltip-unit">千万瓦时</span>
  </div>
</div>`
          // console.log('vector', vector)
          const leftDist = vector.x * halfWidth + halfWidth + 200
          const topDist = -1 * vector.y * halfHeight + halfHeight + 80
          labelDom.setAttribute(
            'style',
            'left: ' + leftDist + 'px; top : ' + topDist + 'px;'
          )
        } else {
          labelDom.innerHTML = ''
          labelDom.setAttribute('style', 'visible: hidden')
        }
      }

      // ================================================ 相机运动
      // todo 运动过程抖动, 不流畅
      function cameraMove (finalPosition) {
        const cameraPosition = camera.position
        // 添加相机前后运动
        const positions = [
          cameraPosition.x,
          cameraPosition.y,
          cameraPosition.z,
          finalPosition.x * cameraDiv + 50,
          finalPosition.y * cameraDiv - 20,
          finalPosition.z * cameraDiv - 20
          // finalPosition.x * cameraDiv, finalPosition.y * cameraDiv, finalPosition.z * cameraDiv,
        ]
        // console.log("positions", positions);

        // QuaternionKeyframeTrack( name : String, times : Array, values : Array )
        // 关键帧时间指的是 各个时间点, 而不是持续时间
        const positionKF = new THREE.NumberKeyframeTrack(
          '.position',
          [0, 2],
          positions
        )
        const clip = new THREE.AnimationClip('move', 23, [positionKF])
        cameraMixer = new THREE.AnimationMixer(camera)
        const actionCamera = cameraMixer.clipAction(clip)
        actionCamera.setLoop(THREE.LoopOnce, 1)
        actionCamera.clampWhenFinished = true
        actionCamera.play()
      }

      function changeMapMaterial () {
        let textureImg = require('./earth/images/havingChinaLine.png')
        if (isChinaView) {
          // console.log("... china inner");
          textureImg = require('./earth/images/ChinaInner.png')
        }
        return loader.load(textureImg)
      }

      // ========================================== 添加运动事件 ========================================
      function animate () {
        render()
        requestAnimationFrame(animate)
        renderDiv(selectObject) // 渲染文本标签
        // 更新controls；放在animate内, 不要放在render里
        controls.update(clock.getDelta())

        // TWEEN.update();
        // 外层地球自转  ==> 独立于 controls的自转效果
        if (moonGroup) {
          moonGroup.rotateY(0.002)
          moonGroup.rotateX(0.002)
        }

        // 飞线动画
        flyLines.forEach(
          line => (line.material.uniforms.dashOffset.value -= 0.01)
        )

        // 光柱 修改透明度
        if (lightCross) {
          number = number + 0.01
          lightCross.traverse(e => {
            if (e.isMesh) {
              e.material.opacity = Math.abs(Math.sin(number)) + 0.3
            }
          })
        }

        // 球面粒子闪烁: 必须添加该动画,地区内的圆点才会显示 ==> 默认为透明的
        const objects = earthParticles.children
        if (!isChinaView) {
          objects.forEach(obj => {
            const material = obj.material
            material.t_ += material.speed_
            material.size = 4
            // 动态变化点的透明度
            material.opacity =
              (Math.sin(material.t_) * material.delta_ + material.min_) *
                material.opacity_coef_ +
              0.2
            // 更新材质
            material.needsUpdate = true
          })
        } else {
          objects.forEach(obj => {
            const material = obj.material
            material.size = 3
            // 动态变化点的透明度
            material.opacity = 0.98
            // 更新材质
            material.needsUpdate = true
            // 将背景点设置为50%
            if (obj.name === 'China-0') {
              material.opacity = 0.5
            }
          })
        }
      }

      function render () {
        const delta = clock.getDelta()
        // controls.update(delta)

        if (cameraMixer) {
          cameraMixer.update(delta)
        }

        // if (controlsMixer) {
        //   controlsMixer.update(delta)
        // }
        // console.log(scene.children)
        renderer.render(scene, camera)
      }
      const mark = {
        marking: [
          {
            name: '中国',
            pos: [116.4551, 40.2539]
          },
          {
            name: '俄罗斯',
            pos: [37.35, 55.45]
          },
          {
            name: '美国',
            pos: [-77.02182, 38.53707]
          },
          {
            name: '澳大利亚',
            pos: [149.07, -35.17]
          },
          {
            name: '巴西',
            pos: [-47.56, -15.47]
          },
          {
            name: '南非',
            pos: [18, -34]
          },
          {
            name: '英国',
            pos: [0.5, 51.3]
          }
        ]
      }
      const countryToEn = {
        中国: 'China',
        俄罗斯: 'Russia',
        美国: 'Usa',
        澳大利亚: 'Australia',
        巴西: 'Brazil',
        南非: 'Southafrica',
        英国: 'England'

      }

      const distractColors = [
        'rgba(0,255,111,1)',
        'rgba(0,255,111,1)',
        'rgba(0,255,111,1)',
        'rgba(0,255,111,1)',
        'rgba(0,255,111,1)',
        'rgba(0,255,111,1)',
        'rgba(0,255,111,1)',
        'rgba(0,255,111,1)',
        'rgba(0,255,111,1)'
      ]

      init()
      loadFile(function () {
        camera.position.set(450, 450, 450)
        skyTexture(scene) // 背景贴图
        createCloudGrid(scene, R)
        addLight()
        addOutPointCloud()
        addLightPillars()
        scene.add(allGroup)
        // 添加地球表面的点
        createEarthParticles()
        addMoon()
        console.log('--- end view')
      })
      animate()
      // console.log('--- end view')
    }
  }
}
</script>

<style lang="scss" scoped>
.earthChart-label {
  position: absolute;
  /*border: 1px solid white;*/
  padding: 0 0;
  font-size: 30px;
  color: white;
  z-index: 100;
  // visibility: hidden;
}
</style>
