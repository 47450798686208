const chart1 = function (params) {
  const pieWidth = params.pieWidth
  const secondPieWidth = params.secondPieWidth
  const resultData = params.resultData
  const textColorList = params.textColorList
  const legendTop = params.legend_top
  const legendLeft = params.legend_left
  const legendTextSize = params.legendTextSize
  const legendItemGap = params.legendItemGap
  const legendItemWidth = params.legendItemWidth
  const legendItemHeight = params.legendItemHeight
  const piePosition = params.piePosition
  const backgroundColor = params.backgroundColor

  const legentTextColorList = ['rgba(54, 255, 252)', 'rgba(104, 208, 255)', 'rgba(255, 162, 0)', 'rgba(210, 111, 88)', 'rgba(174, 238, 237)']

  let sum = 0
  for (let i = 0; i < resultData.length; i++) {
    const oneDict = resultData[i]
    sum = sum + oneDict.value
  }
  for (let i = 0; i < resultData.length; i++) {
    const targetValue = resultData[i].value
    resultData[i].percent = (targetValue / sum * 100).toFixed(2)
  }
  const legendData = []
  for (let j = 0; j < resultData.length; j++) {
    const data = {
      name: resultData[j].name,
      textStyle: {
        color: legentTextColorList[j],
        fontSize: legendTextSize,
        fontfamily: 'PingFangMedium'
      }
    }
    legendData.push(data)
  }

  function array2obj (array, key) {
    var resObj = {}
    for (var i = 0; i < array.length; i++) {
      resObj[array[i][key]] = array[i]
    }
    return resObj
  }

  const objData = array2obj(resultData, 'name')
  const series = [{
    name: 'first_pie',
    type: 'pie',
    radius: pieWidth,
    center: piePosition,
    hoverAnimation: false,
    zlevel: 10,
    itemStyle: {
      normal: {
        borderColor: '#fff',
        borderWidth: 0
      }
    },
    label: {
      show: false
    },
    labelLine: {
      normal: {
        show: false
      }
    },
    data: resultData
  },
  {
    name: 'second_pie',
    type: 'pie',
    zlevel: 0,
    center: piePosition,
    radius: secondPieWidth,
    data: [100],
    hoverAnimation: false,
    itemStyle: {
      color: 'rgba(54, 255, 252, 1)'
    },
    label: {
      show: false,
      normal: {
        position: 'inner'
      }
    }
  }
  ]
  const option = {
    backgroundColor: backgroundColor,
    color: textColorList,
    legend: {
      show: true,
      left: legendLeft,
      top: legendTop,
      formatter: function (name) {
        // return `{title|${name}}   {percent|${objData[name].percent}}{unit|%}`
        return `  ${name}     ${objData[name].percent}%`
      },
      itemWidth: legendItemWidth,
      itemHeight: legendItemHeight,
      itemGap: legendItemGap,
      orient: 'vertical',
      icon: 'roundRect',
      data: legendData
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    series: series
  }
  return option
}

export default chart1
