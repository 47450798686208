
const zeroFormat = function (obj) {
  if (obj < 10) {
    return '0' + obj
  } else {
    return obj
  }
}

// 格式化时间 时 分 秒
const timeHMS = function () {
  const now = new Date()
  const currentTime = {
    hour: zeroFormat(now.getHours()),
    minutes: zeroFormat(now.getMinutes()),
    seconds: zeroFormat(now.getSeconds())
  }
  return currentTime
}

// 格式化日期
function getNowFormatDate () {
  var date = new Date()
  var seperator1 = '-'
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var strDate = date.getDate()
  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate
  return currentdate
}

// 传入具体的时间格式 进行格式化
// https://www.runoob.com/js/js-obj-date.html
function formatDate (date, fmt) {
  var o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  }

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }

  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        // eslint-disable-next-line eqeqeq
        RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }

  return fmt
}

export { timeHMS, getNowFormatDate, formatDate }
