export default {
  color: ['#43A7FD', '#A5E9CA'],
  tooltip: {
    show: true,
    trigger: 'axis',
    confine: true,
    axisPointer: {
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.3)'
      }
    },
    backgroundColor: 'rgba(56, 71, 96, 0.7)',
    padding: [15, 40, 15, 10]
  },
  series: [
    {
      type: 'line',
      name: '',
      data: [],
      lineStyle: {
        color: '#43A7FD'
      },
      symbol: 'none',
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [{
            offset: 0, color: 'rgba(67, 167, 253, 0.5)' // 0% 处的颜色
          }, {
            offset: 1, color: 'rgba(67, 167, 253, 0.1)' // 100% 处的颜色
          }],
          global: false // 缺省为 false
        }
      }
    },
    {
      type: 'line',
      name: '',
      data: [],
      lineStyle: {
        color: '#A5E9CA'
      },
      symbol: 'none',
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [{
            offset: 0, color: 'rgba(165, 233, 202, 0.4)' // 0% 处的颜色
          }, {
            offset: 1, color: 'rgba(165, 233, 202, 0.1)' // 100% 处的颜色
          }],
          global: false // 缺省为 false
        }
      }
    }
  ],
  grid: {
    left: 11,
    top: 20,
    bottom: 5,
    right: 10,
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      data: [1976, 1977, 1978, 1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019],
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.8)'
        }
      }
    }
  ],
  yAxis: {
    axisTick: {
      show: false
    },
    axisLine: {
      show: false,
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.8)'
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.1)'
      }
    }
  }
}
