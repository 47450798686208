const mapData = [
  { name: '上海', value: 13910 },
  { name: '云南', value: 1845 },
  { name: '其他', value: 24222 },
  { name: '内蒙古', value: 1129 },
  { name: '北京', value: 17139 },
  { name: '台湾', value: 1309 },
  { name: '吉林', value: 1680 },
  { name: '四川', value: 6791 },
  { name: '天津', value: 3240 },
  { name: '宁夏', value: 321 },
  { name: '安徽', value: 3270 },
  { name: '山东', value: 7498 },
  { name: '山西', value: 1930 },
  { name: '广东', value: 25031 },
  { name: '广西', value: 3049 },
  { name: '新疆', value: 745 },
  { name: '江苏', value: 11880 },
  { name: '江西', value: 2413 },
  { name: '河北', value: 3729 },
  { name: '河南', value: 4839 },
  { name: '浙江', value: 8855 },
  { name: '海南', value: 815 },
  { name: '海外', value: 21108 },
  { name: '湖北', value: 6156 },
  { name: '湖南', value: 3889 },
  { name: '澳门', value: 343 },
  { name: '甘肃', value: 662 },
  { name: '福建', value: 4899 },
  { name: '西藏', value: 217 },
  { name: '贵州', value: 1102 },
  { name: '辽宁', value: 4436 },
  { name: '重庆', value: 3345 },
  { name: '陕西', value: 3248 },
  { name: '青海', value: 187 },
  { name: '香港', value: 1998 },
  { name: '黑龙江', value: 2165 }
]

export default mapData
