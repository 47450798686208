import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function PieDoughnut (element, props) {
  // console.log(props)
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const resultData = props.data[0].resultData
  const backgroundColor = 'rgba(0,0,0,0)'
  const lineColor = props.data[0].lineColor
  const numberFontSize = props.data[0].numberFontSize
  const pieWidth = props.data[0].pieWidth
  const secondPieWidth = props.data[0].secondPieWidth
  const startLine = props.data[0].startLine
  const endLine = props.data[0].endLine
  const thirdPieWidth = props.data[0].thirdPieWidth

  const rich = {
    title: {
      color: '#ffffff',
      fontSize: numberFontSize,
      align: 'center'
    },
    percentText: {
      fontSize: numberFontSize,
      align: 'center'
    }
  }

  const option = {
    backgroundColor: backgroundColor,
    grid: {
      left: 10,
      right: 10,
      containLabel: true
    },
    color: ['#8B52D6', '#AEEEED', '#FD8C57', '#4F7BF4', '#38C589'],
    tooltip: {
      trigger: 'item',
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      formatter: '{b}:<br/>   {c} ({d}%)'
    },
    legend: {
      show: false,
      orient: 'vertical',
      // x: 'right',
      right: '10%',
      top: '45%',
      height: '500',
      // padding:10,
      itemHeight: 10,
      itemWidth: 10,
      // data: legend,
      textStyle: {
        fontSize: 10,
        color: '#fff'
      }
    },
    series: [
      {
        name: '饼状图',
        type: 'pie',
        radius: pieWidth,
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        selectedOffset: 10,
        label: {
          show: false
        },
        labelLine: {
          normal: {
            show: false,
            length: 20,
            length2: 30
          },
          emphasis: {
            show: false
          }
        },
        data: resultData
      },
      {
        name: '饼状图2',
        type: 'pie',
        radius: secondPieWidth,
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        selectedOffset: 0,
        label: {
          show: false
        },
        labelLine: {
          normal: {
            show: false,
            length: 20,
            length2: 30
          },
          emphasis: {
            show: false
          }
        },
        data: [{
          value: 10,
          itemStyle: {
            color: lineColor
          }
        }]
      },
      {
        name: '饼状图3',
        type: 'pie',
        radius: thirdPieWidth,
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        selectedOffset: 10,
        label: {
          // formatter: '{c} ({d}%)'
          formatter: function (params) {
            // console.log(params)
            // let res = params.name + '\n' + params.percent + '%';
            const res = '{title|' + params.name + '}' + '\n\n' + '{percentText|' + params.percent + '%}'
            return res
          },
          rich: rich
        },
        labelLine: {
          show: true,
          length: startLine,
          length2: endLine,
          lineStyle: {
            color: lineColor,
            width: 2
          }
        },
        itemStyle: {
          color: 'rgba（0,0,0,0)',
          opacity: 0,
          borderWidth: 0
        },
        data: resultData
      }
    ]
  }

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default PieDoughnut // 修改为Classname
