// 昆明火车站暴力恐怖案
import { SUPERSET_URL } from '~/config'
import mapData from './data-source/chart-5'
import { visualmapPiece } from '../../echartSetting/visualmap'
const chart = function (params) {
  const newVisual = {
    left: params.targetMarginLeft,
    pieces: [
      { gte: 2000, label: '>=2000', color: 'rgba(67, 167, 253, 1)' },
      { gte: 1500, lte: 1999, label: '1500-1999', color: 'rgba(67, 167, 253, 0.8)' },
      { gte: 1000, lte: 1499, label: '1000-1499', color: 'rgba(67, 167, 253, 0.6)' },
      { gte: 500, lte: 999, label: '500-999', color: 'rgba(67, 167, 253, 0.4)' },
      { gte: 0, lte: 499, label: '0-499', color: 'rgba(67, 167, 253, 0.2)' }
    ],
    textStyle: {
      fontSize: 16,
      color: 'rgba(255, 255, 255, 1)'
    },
    selectedModel: 'single',
    itemWidth: 24,
    itemHeight: 16
  }
  const visualMapDict = Object.assign(visualmapPiece, newVisual)
  delete visualMapDict.text // 设置text 属性后, pieces 属性失效
  const option = {
    tooltip: {
      show: true,
      enterable: true,
      // 背景设置为0 解决有圆点
      backgroundColor: 'rgba(50,50,50,0)',
      trigger: 'item',
      confine: true, // tooltip 不超出图表范围
      formatter: function (params, ticket, callback) {
        const name = params.name
        let value = params.value
        if (name === '云南') {
          value = 1781
        }
        const html = `
          <div class="chart_3 tooltip_box">
            <h1>${name}</h1>
            <div class="tooltip_box_main">
              <span class="label">发博人数</span>
              <span class="data">${value}</span>
            </div>
          </div>
        `
        return html
      }
    },
    visualMap: visualMapDict,
    geo: {
      map: 'china',
      // left: '500',
      // center:[114.298572, 30.584355],
      label: {
        show: false,
        textStyle: {
          color: '#ff0'
        }
      },
      // 地图区域的多边形 图形样式
      itemStyle: {
        normal: {
          // geo 会产生颜色叠加 需要把透明度设置为0
          areaColor: 'rgba(0,0,0,0)',
          borderWidth: params.lineWidth,
          borderColor: params.lineColor
        }
      },
      // 高亮
      emphasis: {
        label: {
          show: false
        },
        itemStyle: {
          areaColor: 'rgba(38, 171, 255, 1)',
          borderWidth: params.lineWidth
        }
      },
      roam: false
    },
    series: [
      {
        type: 'map',
        mapType: 'china',
        label: {
          show: false,
          textStyle: {
            color: '#ff0'
          }
        },
        // 传入进来的地图款背景颜色、边框颜色、边框粗细
        itemStyle: {
          normal: {
            areaColor: params.areaColor,
            borderWidth: params.lineWidth,
            borderColor: params.lineColor
          }
        },
        // 高亮
        emphasis: {
          label: {
            show: false
          },
          itemStyle: {
            areaColor: 'rgba(38, 171, 255, 1)',
            borderWidth: params.lineWidth
          }
        },
        roam: false,
        data: mapData
      },
      {
        name: 'center',
        type: 'scatter',
        coordinateSystem: 'geo',
        hoverAnimation: false,
        symbolSize: function (val) {
          return [val[2], val[3]]
        },
        data: [
          {
            name: '云南',
            symbol: `image://${SUPERSET_URL}/static/assets/images/202011/KunMingEvent.png`,
            label: { show: false },
            // symbolSize: [370, 120],
            symbolOffset: [-128, -5],
            value: [102.73, 25.04, 370, 120]
          }
        ],
        label: {
          normal: {
            formatter: '{b}',
            position: 'bottom',
            color: '#fff',
            show: true
          }
        },
        z: 9,
        animationDuration: 2000
      }
    ]
  }
  return option
}

export default chart
