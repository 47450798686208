import Vue from 'vue'
import allChart from './all'
import './htmlchartset.css'

function ChartFunc (element, props) {
  const chartFunc = allChart(props.data[0].htmlSelect)

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const body = element.children[0]

  let defaultValue
  try {
    defaultValue = props.data[0].options[0].value
  } catch (e) {
    defaultValue = '2017'
  }

  // console.log(props.data[0])
  // eslint-disable-next-line no-unused-vars
  const baseDom1 = new Vue({
    el: body,
    extends: chartFunc,
    data () {
      return {
        value: defaultValue,
        frontDict: props.data[0],
        timer: '',
        props: props,
        isPositve: true,
        isSelected: 'china',
        selectedYear: '2017',
        onInteract: props.onInteract,
        // 瓜达尔港图表
        selectedCountry: '巴基斯坦'
      }
    },
    props: {}
  })
}

export default ChartFunc // 修改为Classname
