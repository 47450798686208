export default {
  series: [
    {
      type: 'line',
      data: [0, 40, 180, 30],
      lineStyle: {
        color: '#43A7FD'
      },
      symbol: 'none'
    }
  ],
  grid: {
    left: 30,
    top: 10,
    bottom: 24,
    right: 0
  },
  xAxis: [
    {
      type: 'category',
      data: ['起始期', '爆发期', '衰退期', '平息期'],
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.6)'
        }
      }
    }
  ],
  yAxis: {
    axisTick: {
      show: false
    },
    axisLine: {
      show: false,
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.6)'
      }
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.2)'
      }
    }
  }
}
