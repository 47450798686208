import echarts from 'echarts'
import {
  setXaxisDefault,
  setYaxisDefault,
  setGrid
} from '../../echartSetting/axis'
import allChart from './all'

function barStandard (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  // 初始化
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const barStandardSelect = props.data[0].barStandardSelect
  const legend = props.data[0].legend
  const backgroundColor = props.data[0].backgroundColor
  const series = allChart(barStandardSelect)(props.data[0])
  let option

  if (barStandardSelect === 'chart1' || barStandardSelect === 'chart2' || barStandardSelect === 'chart2') {
    if (barStandardSelect === 'chart3') {
      const xAxisCategory = []
      for (let i = 0; i < props.data[0].axisTickInterval; i++) {
        xAxisCategory.push('name' + i)
      }
      props.data[0].xAxisData = xAxisCategory
    }

    option = {
      backgroundColor: backgroundColor,
      legend: {
        // legend: 可设置
        show: false,
        itemHeight: 10,
        itemWidth: 10,
        data: legend,
        align: 'right',
        right: '7%',
        top: '5%'
      },
      tooltip: {
        show: false
      },
      xAxis: setXaxisDefault(props.data[0]),
      yAxis: setYaxisDefault(props.data[0]),
      grid: setGrid(props.data[0]),
      series: series
    }
  } else if (barStandardSelect === 'chart4') {
    let startIndex = 0
    let endIndex = 100
    let partAxisData = props.data[0].xAxisData.slice(startIndex, endIndex)
    let partMaxValue = Math.max(props.data[0].content_data[0].slice(startIndex, endIndex))
    const originData = props.data[0].content_data[0]
    const allDataLength = props.data[0].xAxisData.length - 1
    const shiftAxisData = function () {
      if (endIndex === allDataLength) {
        startIndex = 0
        endIndex = 100
      }
      startIndex = startIndex + 1
      endIndex = endIndex + 1
      partAxisData = props.data[0].xAxisData.slice(startIndex, endIndex)
      partMaxValue = Math.max(...originData.slice(startIndex, endIndex)) * 1.2
      console.log('origin part', partMaxValue)
    }
    console.log(partAxisData, partMaxValue)

    option = allChart(barStandardSelect)(props.data[0])
    option.xAxis.data = partAxisData
    myChart.setOption(option)
    setInterval(function () {
      shiftAxisData()
      // option.yAxis[0].max = partMaxValue * 1.2
      // option.yAxis[1].max = partMaxValue * 1.2
      myChart.setOption({
        xAxis: { data: partAxisData },
        yAxis: [
          { max: partMaxValue, min: -1 * partMaxValue },
          { max: partAxisData, min: -1 * partMaxValue }
        ]
      })
    }, 500)
  } else {
    option = series
  }

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  function Play () {
    myChart.clear()
    myChart.setOption(option)
    // 监听页面点击事件
    myChart.on('click', function (params) {
      const gorupByValue = params.name
      props.onInteract(gorupByValue)
    })
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default barStandard // 修改为Classname
