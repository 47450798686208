import './PicCarouselSix.css'
import Swiper from 'swiper'

function PicCarouselSix (element, props) {
  // console.log(props)
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const textData = props.data[0].data
  const contentWidth = props.data[0].parentWidth
  const contentHeight = props.data[0].parentHeight
  const delayTime = props.data[0].delayTime
  const speedTime = props.data[0].speedTime

  const createDom = function (domType, className, text) {
    const oneDom = document.createElement(domType)
    if (className !== '') {
      oneDom.setAttribute('class', className)
    }
    if (text !== '') {
      oneDom.innerHTML = text
    }
    return oneDom
  }

  const numToZeroString = function (number, zeroNum = 3) {
    let zeroString = ''
    const iLength = number.toString().length
    if (zeroNum === 3) {
      if (iLength === 1) {
        zeroString = '00' + number
      } else if (iLength === 2) {
        zeroString = '0' + number
      } else if (iLength === 3) {
        zeroString = number
      }
    } else if (zeroNum === 2) {
      if (iLength === 1) {
        zeroString = '0' + number
      } else if (iLength === 2) {
        zeroString = number
      } else if (iLength === 3) {
        zeroString = number
      }
    }
    return zeroString
  }

  const createContentList = function (parentDiv, data) {
    const len = data.length
    const groupNum = Math.ceil(len / 11)
    for (let i = 1; i <= groupNum; i++) {
      // console.log('target dict', targetDict)
      const oneContent = document.createElement('div')
      oneContent.setAttribute('class', 'swiper-slide')

      // 整体用于布局的div
      const layContent = document.createElement('div')
      layContent.setAttribute('class', 'slide-content')
      oneContent.appendChild(layContent)

      const picSixTable = createDom('div', 'carousel6-table-body', '')
      layContent.appendChild(picSixTable)
      const picSixTableBody = createDom('div', 'carousel6-tbody-style', '')
      picSixTable.appendChild(picSixTableBody)

      const startIndex = (i - 1) * 11
      let endIndex = i * 11
      if (endIndex > len) {
        endIndex = len
      }
      for (let j = startIndex; j < endIndex; j++) {
        // 类标序号从 1 开始
        const realIndex = j + 1
        const indexNum = numToZeroString(realIndex)

        const currentDict = {
          index: indexNum,
          name: data[j]['成果名称'],
          field: data[j]['技术领域']
        }

        // console.log('index', currentDict)
        const eachRow = createDom('div', 'carousel6-row-style', '')
        eachRow.innerHTML = `
        <div class="carousel6-cell-border index-num">${currentDict.index}</div>
        <div class="carousel6-cell-border mid-pic"><img class="pic-style" src="/static/img/chart-icon/circle.png"></div>
        <div class="carousel6-cell-border">
          <div class="all-right-part">
            <div class="rightPart-one">${currentDict.name}</div>
            <div class="rightPart-two">
              <div class="two-tech">技术领域</div>
              <div class="two-name">${currentDict.field}</div>
            </div>
          </div>
        </div>`

        picSixTableBody.appendChild(eachRow)
      }

      parentDiv.append(oneContent)
    }
  }

  const body = element.children[0]
  body.setAttribute('style', 'width: 100%;height: 100%; overflow: hidden;')
  onload = function () {
    // 整个图表的div
    const originDiv = document.createElement('div')
    // originDiv.setAttribute('id', 'pic-box')
    originDiv.setAttribute('style', 'height:' + contentHeight + 'px; width: ' + contentWidth + 'px; background-color: rgba(255, 255, 255, 0)')
    originDiv.setAttribute('class', 'swiper-container pic-box')
    body.appendChild(originDiv)

    //  轮播内容div
    const carouselContent = document.createElement('div')
    carouselContent.setAttribute('class', 'swiper-wrapper')
    originDiv.appendChild(carouselContent)

    createContentList(carouselContent, textData)

    return originDiv
  }
  const originDiv = onload()

  // 添加页码
  const pageDom = createDom('div', 'carousel6-table-body', '')
  pageDom.setAttribute('style', 'margin-top: 102px')
  body.appendChild(pageDom)
  const pageNumDom = createDom('div', 'carousel6-page-parent', '')
  pageNumDom.innerHTML = `<span class="no-number">当前</span>
      <span class="page-num" id="pic6-current-page">01</span>
      <span class="no-number">页</span>`
  pageDom.appendChild(pageNumDom)

  const pageTotalDom = createDom('div', 'carousel6-page-parent', '')
  pageTotalDom.setAttribute('style', 'margin-top: 47px')
  const pageTotal = Math.ceil(textData.length / 11)
  pageTotalDom.innerHTML = '<span class="carousel6-total-page">总共: ' + pageTotal + '页</span>'
  pageDom.appendChild(pageTotalDom)

  const changePage = function (index) {
    const pageNumDom = document.getElementById('pic6-current-page')
    const zeroString = numToZeroString(index, 2)
    pageNumDom.innerText = zeroString
  }

  // eslint-disable-next-line no-new
  new Swiper(originDiv, {
    speed: speedTime,
    autoplay: {
      disableOnInteraction: true,
      // 每次切换的时间间隔
      delay: delayTime
    }, // 可选选项，自动滑动
    direction: 'vertical',
    setWrapperSize: true,
    loop: true,
    on: {
      slideChangeTransitionStart: function () {
        console.log('====', this.activeIndex)
        const currentIndex = this.activeIndex
        changePage(currentIndex)
      }
    }
  })

  const Play = function () {

    // mySwiper()
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default PicCarouselSix // 修改为Classname
