const provinceData = {
  台湾省: [121.0135, 24.2308],
  黑龙江省: [125.9688, 48.868],
  内蒙古自治区: [110.3467, 41.4899],
  吉林省: [125.8154, 44.2584],
  北京省: [116.4551, 40.2539],
  辽宁省: [124.1238, 42.1216],
  河北省: [115.4995, 38.1006],
  天津省: [117.4219, 39.4189],
  山西省: [113.3352, 37.9413],
  陕西省: [109.1162, 34.2004],
  甘肃省: [105.2901, 36.3043],
  宁夏回族自治区: [107.6586, 37.7775],
  青海省: [101.4038, 36.8207],
  新疆维吾尔自治区: [90.9236, 45.5883],
  西藏自治区: [91.11, 29.97],
  四川省: [103.9526, 30.7617],
  重庆市: [109.384366, 30.239702],
  山东省: [117.1582, 36.8701],
  河南省: [114.4668, 34.6234],
  江苏省: [119.6062, 32.5],
  安徽省: [117.29, 32.0581],
  湖北省: [114.3896, 30.6628],
  浙江省: [119.5313, 29.8773],
  福建省: [119.0543, 25.9222],
  江西省: [116.0046, 28.6633],
  湖南省: [113.0823, 28.2568],
  贵州省: [108.6992, 26.7682],
  云南省: [102.9199, 25.4663],
  广东省: [114.12244, 22.209505],
  广西壮族自治区: [108.479, 23.1152],
  海南省: [111.3893, 19.6516],
  北京市: [121.4648, 31.2891],
  上海市: [111.5248, 28.2651],
  天津市: [121.4648, 31.2891]
}

export {
  provinceData
}
