/* eslint-disable camelcase */
// 引入图表所需要的公共样式文件
import './style/index.scss'

// 先引入主题样式
import './charts/echarts_theme/default'
import './charts/echarts_theme/themeOne'
import './charts/echarts_theme/themeTwo'
import './charts/echarts_theme/dark'

import otherchart from './charts/otherchart'
import radar from './charts/radar'
import mix_line_bar from './charts/mix_line_bar'
import mix_timeline_finance from './charts/mix_timeline_finance'
import effectscatter_bmap from './charts/effectscatter_bmap'
import custom_error_bar from './charts/custom_error_bar'
import flow_diagram from './charts/flow_diagram'
import pictorialbar from './charts/pictorialbar'
import bar3d from './charts/bar3d'
import treemap_disk from './charts/treemap_disk'
import treemap_echarts from './charts/treemap_echarts'
import bubble_multi from './charts/bubble_multi'
import scatter_weight from './charts/scatter_weight'
import boxplot_echarts from './charts/boxplot_echarts'
import tree_basic from './charts/tree_basic'
import calendar_echarts from './charts/calendar_echarts'
import globe_layers from './charts/globe_layers'
import bar_polar from './charts/bar_polar'
import bar_brush from './charts/bar_brush'
import bubble_timeline from './charts/bubble_timeline'
import map_and_bar3d from './charts/map_and_bar3d'
import map_and_bar from './charts/map_and_bar'
import watermark from './charts/watermark'
import dataset_link from './charts/dataset_link'
import gauge from './charts/gauge'
import bar_animation from './charts/bar_animation'
import map2d_and_bar from './charts/map2d_and_bar'
import horizontaldoublebar from './charts/horizontaldoublebar'
import arearadar from './charts/arearadar'
import pierose from './charts/pierose'
import hubei_map from './charts/hubei_map'
import table_carousel from './charts/table_carousel'
import table_doublecol from './charts/table_doublecol'
import number_flop from './charts/number_flop'
import first_bar_one from './charts/first_bar_one'
import pie_doughnut from './charts/pie_doughnut'
import horizontalbar from './charts/horizontalbar'
import third_bar_line from './charts/third_bar_line'
import digitalflop from './charts/digitalflop'
import hubei from './charts/hubei'
import mapchina from './charts/mapchina'
import circlenumber from './charts/circlenumber'
import mapflowline from './charts/mapflowline'
import textbarvertical from './charts/textbarvertical'
import doublecolorbar from './charts/doublecolorbar'
import numbertrendicon from './charts/numbertrendicon'
import tablestatic from './charts/tablestatic'
import tablerimless from './charts/tablerimless'
import progressbar from './charts/progressbar'
import ringproportionoftwo from './charts/ringproportionoftwo'
import twocolumnbar from './charts/twocolumnbar'
import movetablerimless from './charts/movetablerimless'
import multiline from './charts/multiline'
import basefilter from './charts/basefilter'
import world_map_echart from './charts/world_map_echart'
import movebackcolortable from './charts/movebackcolortable'
import timetext from './charts/timetext'
import piccarousel from './charts/piccarousel'
import piccarouselsecond from './charts/piccarouselsecond'
import piccarouselthird from './charts/piccarouselthird'
import piccarouselfour from './charts/piccarouselfour'
import piccarouselnine from './charts/piccarouselnine'
import piccarouselfive from './charts/piccarouselfive'
import piccarouselsix from './charts/piccarouselsix'
import piccarouselten from './charts/piccarouselten'
import piccarouselseven from './charts/piccarouselseven'
import piccarouselevn from './charts/piccarouselevn'
import piccarouseleight from './charts/piccarouseleight'

import pieliquid from './charts/pieliquid'
import pietypefirst from './charts/pietypefirst'
import pietypesecond from './charts/pietypesecond'
import pietypethird from './charts/pietypethird'
import barlinepictorialbar from './charts/barlinepictorialbar'
import movehovertable from './charts/movehovertable'
import first_line_double from './charts/first_line_double'
import firstbaronesecond from './charts/firstbaronesecond'
import digitalflopcarousel from './charts/digitalflopcarousel'
import movetablefontstyle from './charts/movetablefontstyle'
import barpictorialbarfirst from './charts/barpictorialbarfirst'
import hubeiline from './charts/hubeiline'
import mapflowlinetwo from './charts/mapflowlinetwo'
import barpictorialbarsecond from './charts/barpictorialbarsecond'
import pietypefour from './charts/pietypefour'
import weatherpredict from './charts/weatherpredict'
import maptooltipmove from './charts/maptooltipmove'
import barsort from './charts/barsort'

import barstandard from './charts/barstandard'
import htmlchart1 from './charts/htmlchart1'
import htmlchartset1 from './charts/htmlchartset1'
import numflip from './charts/numflip'
/**
 * 可用图表的生成方法列表
 */
export const chartRender = {
  otherchart,
  radar,
  mix_line_bar,
  mix_timeline_finance,
  effectscatter_bmap,
  custom_error_bar,
  flow_diagram,
  pictorialbar,
  bar3d,
  treemap_disk,
  treemap_echarts,
  bubble_multi,
  scatter_weight,
  boxplot_echarts,
  tree_basic,
  calendar_echarts,
  globe_layers,
  bar_polar,
  bar_brush,
  bubble_timeline,
  map_and_bar3d,
  map_and_bar,
  mapchina,
  watermark,
  dataset_link,
  gauge,
  bar_animation,
  map2d_and_bar,
  arearadar,
  horizontaldoublebar,
  pierose,
  hubei_map,
  hubei,
  table_carousel,
  table_doublecol,
  number_flop,
  first_bar_one,
  pie_doughnut,
  horizontalbar,
  third_bar_line,
  digitalflop,
  circlenumber,
  mapflowline,
  textbarvertical,
  doublecolorbar,
  numbertrendicon,
  tablestatic,
  tablerimless,
  progressbar,
  ringproportionoftwo,
  twocolumnbar,
  movetablerimless,
  multiline,
  basefilter,
  world_map_echart,
  movebackcolortable,
  movetablefontstyle,
  timetext,
  piccarousel,
  piccarouselsecond,
  piccarouselthird,
  piccarouselfour,
  piccarouselnine,
  piccarouselfive,
  piccarouselsix,
  piccarouselevn,
  piccarouselseven,
  piccarouseleight,
  piccarouselten,

  pieliquid,
  pietypefirst,
  pietypesecond,
  pietypethird,
  barlinepictorialbar,
  barpictorialbarfirst,
  movehovertable,
  first_line_double,
  firstbaronesecond,
  digitalflopcarousel,
  hubeiline,
  mapflowlinetwo,
  barpictorialbarsecond,
  pietypefour,
  weatherpredict,
  maptooltipmove,
  barsort,
  barstandard,
  htmlchart1,
  htmlchartset1,
  numflip
}
