<template>
  <div class="medical_chart1">
    <div class="header_box">
      <div class="title">
        <h1>王国庆</h1>
        <img src="../image/logo.png" alt="医疗可视化平台">
      </div>
      <p>患者病例档案</p>
    </div>
    <div class="info_box">
      <div class="info_item">
        <div class="item_label">患者ID</div>
        <div class="item_value">02480054802178</div>
      </div>
      <div class="info_item">
        <div class="item_label">患者医保</div>
        <div class="item_value">02480054802178</div>
      </div>
      <div class="info_item">
        <div class="item_label">患者性别</div>
        <div class="item_value">男</div>
      </div>
      <div class="info_item">
        <div class="item_label">联系电话</div>
        <div class="item_value">13815478514</div>
      </div>
      <div class="info_item">
        <div class="item_label">出生年月</div>
        <div class="item_value">1970年2月</div>
      </div>
      <div class="info_item">
        <div class="item_label">民族</div>
        <div class="item_value">汉族</div>
      </div>
    </div>
    <div class="history_box">
      <div class="history_item yichuan">
        <div class="item_header">
          <div class="header_title">
            <p>家族遗传病史</p>
          </div>
          <div class="header_line"></div>
          <img src="../image/arrows.png" alt="">
        </div>
        <div class="item_content">
          <div class="case">
            <img src="../image/dachang.png" alt="">
            <span>大肠癌</span>
          </div>
          <div class="case">
            <img src="../image/xingzang.png" alt="">
            <span>心脏病</span>
          </div>
          <div class="case">
            <img src="../image/guzhi.png" alt="">
            <span>骨质疏松</span>
          </div>
        </div>
      </div>
      <div class="history_item yichuan">
        <div class="item_header">
          <div class="header_title">
            <p>药物过敏史</p>
          </div>
          <div class="header_line"></div>
          <img src="../image/arrows.png" alt="">
        </div>
        <div class="item_content">
          <div class="case">
            <img src="../image/yaopian.png" alt="">
            <span>青霉素</span>
          </div>
          <div class="case">
            <img src="../image/yaopian.png" alt="">
            <span>阿司匹林</span>
          </div>
          <div class="case">
            <img src="../image/zhengtong.png" alt="">
            <span>破伤风</span>
          </div>
        </div>
      </div>
    </div>
    <div class="right_top_box"></div>
    <div class="left_bottom_box"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .medical_chart1 {
    position: relative;
    width: 556px;
    height: 450px;
    background-color: RGBA(15, 25, 37, 1);
    // background: linear-gradient(90deg, rgba(219, 246, 255, 0.1) 0%, rgba(219, 246, 255, 0.05) 100%);
    padding: 15px 12px 30px 20px;
    .header_box {
      margin-bottom: 27px;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 11px;
        margin-bottom: 10px;
        & > h1 {
          font-size: 30px;
          font-weight: bold;
          line-height: 42px;
          color: #FFFFFF;
        }
      }
      & > p {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #7DE0FF;
      }
    }
    .info_box {
      display: grid;
      grid-template-columns: 260px 260px;
      grid-template-rows: 20px 20px 20px ;
      row-gap: 16px;
      margin-bottom: 30px;
      .info_item {
        display: grid;
        grid-template-columns: 90px auto;
        .item_label {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: rgba(255, 255, 255, 0.6);
        }
        .item_value {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #FFFFFF;
        }
      }
    }
    .history_box {
      width: 100%;
      .history_item {
        width: 100%;
        .item_header {
          display: flex;
          align-items: center;
          .header_title {
            display: flex;
            align-items: center;
            width: 120px;
            height: 24px;
            background-image: url('../image/title_box_bg.png');
            background-repeat: no-repeat;
            padding-left: 6px;
            & > p {
              font-size: 12px;
              font-weight: bold;
              line-height: 17px;
              color: #FFFFFF;
            }
          }
          .header_line {
            width: 380px;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.2);
            margin: 0 3px 0 10px;
          }
        }
        .item_content {
          display: flex;
          width: 100%;
          padding-top: 9px;
          .case {
            display: flex;
            align-items: center;
            & > span {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color: #FFFFFF;
              margin-left: 3px;
            }
            &+.case {
              margin-left: 35px;
            }
          }
        }
        &+.history_item {
          margin-top: 19px;
        }
      }
    }
    .right_top_box {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 6px;
      height: 4px;
      background: RGBA(137, 144, 154, 1);
      opacity: 1;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 1px;
        height: 40px;
        background: RGBA(137, 144, 154, 1);
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 40px;
        height: 1px;
        background: RGBA(137, 144, 154, 1);
      }
    }
    .left_bottom_box {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      width: 6px;
      height: 4px;
      background: RGBA(137, 144, 154, 1);
      opacity: 1;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 1px;
        height: 40px;
        background: RGBA(137, 144, 154, 1);
      }
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 40px;
        height: 1px;
        background: RGBA(137, 144, 154, 1);
      }
    }
  }
</style>
