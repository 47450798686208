import echarts from 'echarts'

function ProgressBar (element, props) {
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  // eushow 3.0
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const sumNumber = props.data[0].sumNumber
  // const xAxisData = props.data[0].xAxisData;
  const legend = props.data[0].legend
  const numberFontSize = props.data[0].numberFontSize
  const numerator = props.data[0].numerator
  const titlePosition = props.data[0].titlePosition
  const barRadius = props.data[0].barRadius

  const barWidth = props.data[0].barWidth
  const gradientColorStart = props.data[0].gradientColorStart
  const gradientColorEnd = props.data[0].gradientColorEnd
  const barBackgroundColor = props.data[0].barBackgroundColor
  const numberColor = props.data[0].numberColor

  const backgroundColor = 'rgba(0,0,0,0)'

  const finalPercent = Math.round(numerator / sumNumber * 100)
  // console.log(finalPercent, numerator, sumNumber)
  console.log(titlePosition)

  const option = {
    backgroundColor: backgroundColor,
    // toolbox: {
    //   feature: {
    //     saveAsImage: {
    //       show: true,
    //       type: 'png',
    //       // 保存的图片的像素比例， 默认为1， 容器大小， 越大越清晰
    //       pixelRatio: 3
    //     }
    //   }
    // },
    grid: {
      top: '50%'
    },
    title: {
      show: true,
      text: finalPercent + '%',
      textStyle: {
        fontSize: numberFontSize,
        fontWeight: 'normal',
        fontFamily: 'LESLIE ',
        color: numberColor
      },
      left: titlePosition[0],
      top: titlePosition[1]
    },
    tooltip: {
      show: false,
      // formatter: "{a} <br> {c}%"
      formatter: '{c}%'
    },
    legend: {
      show: false,
      icon: 'circle',
      bottom: '43%',
      left: '10%',
      itemWidth: 7,
      itemHeight: 7,
      itemGap: 40,
      textStyle: {
        color: '#89A7AF'
      },
      data: legend
    },
    xAxis: [{
      type: 'value',
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      splitLine: {
        show: false
      }
    }],
    yAxis: [{
      // type: 'category',
      data: [''],
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      splitLine: {
        show: false
      }

    }],
    series: [
      {
        name: 'firstPart',
        type: 'bar',
        barWidth: barWidth,
        barRadius: barRadius,
        stack: 'oneStack',
        itemStyle: {
          barBorderRadius: barRadius
          // color: '#E8A61F'
        },
        data: [{
          value: numerator,
          itemStyle: {
            normal: {
              color: {
                type: 'bar',
                colorStops: [{
                  offset: 0,
                  color: gradientColorEnd // 0% 处的颜色
                }, {
                  offset: 1,
                  color: gradientColorStart // 100% 处的颜色
                }
                ],
                globalCoord: false // 缺省为 false

              }
            }
          }
        }]
      },
      {
        name: 'secondPart',
        type: 'bar',
        barWidth: 10,
        stack: 'oneStack',
        itemStyle: {
          barBorderRadius: barRadius
          // color: '#E67C26'
        },
        label: {
          normal: {
            borderWidth: 10,
            distance: 20,
            align: 'center',
            verticalAlign: 'middle',
            borderRadius: 1,
            borderColor: '#E67C26',
            backgroundColor: '#E67C26',
            show: false,
            position: 'top',
            formatter: '{c}%',
            color: '#000'
          }
        },
        data: [{
          value: sumNumber - numerator,
          itemStyle: {
            normal: {
              color: {
                type: 'line',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(0,0,0,0)'
                }, {
                  offset: 0.125,
                  color: 'rgba(0,0,0,0)'
                }, {
                  offset: 0.125,
                  color: barBackgroundColor
                }, {
                  offset: 0.875,
                  color: barBackgroundColor

                }, {
                  offset: 0.875,
                  color: 'rgba(0,0,0,0)'

                }, {
                  offset: 1,
                  color: 'rgba(0,0,0,0)'

                }
                ],
                globalCoord: false // 缺省为 false
              }
            }
          }
        }]
      }
    ]
  }

  myChart.on('brushSelected', renderBrushed)

  function renderBrushed (params) {
    var brushed = []
    var brushComponent = params.batch[0]

    for (var sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
      var rawIndices = brushComponent.selected[sIdx].dataIndex
      brushed.push('[Series ' + sIdx + '] ' + rawIndices.join(', '))
    }

    myChart.setOption({
      title: {
        backgroundColor: 'rgba(251,255,219,0)',
        text: 'SELECTED DATA INDICES: \n' + brushed.join('\n'),
        bottom: 0,
        right: 0,
        width: 100,
        textStyle: {
          fontSize: 12,
          color: '#fff'
        }
      }
    })
  }

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default ProgressBar // 修改为Classname
