<template>
  <div class="htmlchartset1-3">
    <div v-if="isSelected === 'china'">
      <img src="./country/china_hover.png" />
    </div>
    <div v-else @click="clickChina()">
      <img src="./country/china.png" />
    </div>
    <div v-if="isSelected === 'usa'">
      <img src="./country/usa_hover.png" />
    </div>
    <div v-else @click="clickUSA()">
      <img src="./country/usa.png" />
    </div>
    <div v-if="isSelected === 'japan'">
      <img src="./country/japan_hover.png" />
    </div>
    <div v-else @click="clickJapan()">
      <img src="./country/japan.png" />
    </div>

    <div v-if="isSelected === 'russia'">
      <img src="./country/russia_hover.png" />
    </div>
    <div v-else @click="clickRussia()">
      <img src="./country/russia.png" />
    </div>

    <div v-if="isSelected === 'korea'">
      <img src="./country/korea_hover.png" />
    </div>
    <div v-else @click="clickKorea()">
      <img src="./country/korea.png" />
    </div>

    <div v-if="isSelected === 'india'">
      <img src="./country/india_hover.png" />
    </div>
    <div v-else @click="clickIndia()">
      <img src="./country/india.png" />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    clickChina () {
      this.isSelected = 'china'
      this.onInteract('中国')
    },
    clickUSA () {
      console.log('click usa')
      this.isSelected = 'usa'
      this.onInteract('美国')
    },
    clickJapan () {
      this.isSelected = 'japan'
      this.onInteract('日本')
    },
    clickKorea () {
      this.isSelected = 'korea'
      this.onInteract('韩国')
    },
    clickIndia () {
      this.isSelected = 'india'
      this.onInteract('印度')
    },
    clickRussia () {
      this.isSelected = 'russia'
      this.onInteract('俄国')
    }
  },
  computed: {}
}
</script>
<style lang="scss" scoped></style>

// 必须添加顶层类,再在新建的scss内嵌套修改element样式
<style lang="scss"></style>
