<!--
  功能点理解
  https://share.mubu.com/doc/8MjhHbR3d4
 -->
<template>
  <div class="chart_detail" :class="mode === 'share' ? 'isShare' : ''">
    <!-- 面包屑 -->
    <section-title multiple style="margin-left:26px;" v-if="mode !== 'share'">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="sidebarPath">
          <span>{{ sidebarTitle }}</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="sidebar === 'chart-group' || sidebar === 'type-group'" :to="groupPath">
          <span>{{ groupTitle | formatType(sidebar)}}</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span class="text_color_active" v-if="mode === 'create'">新建图表</span>
          <span class="text_color_active" v-if="mode === 'edit'">编辑图表</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </section-title>

    <!-- 主区域 -->
    <div class="chart_detail_main" v-loading="pageLoading" element-loading-background="rgba(24,34,45,0.9)">
      <!-- 配置 -->
      <div class="chart_detail_main-left form" v-if="mode !== 'share'">
        <div class="form_header">
          <div
            class="form_header_item text_size_14 text_color_primary"
            :class="settingItem === 'data' ? 'select' : ''"
            @click="settingItem = 'data'">
            数据设置
          </div>
          <div
            class="form_header_item text_size_14 text_color_primary"
            :class="settingItem === 'chart' ? 'select' : ''"
            @click="settingItem = 'chart'">
            可视化属性设置
          </div>
        </div>
        <div class="form_body">
          <vue-scroll>
            <div class="form_body_wrapper">

              <!-- 数据设置 -->
              <template v-if="settingItem === 'data'">
                <!-- 数据源&图表类型 -->
                <div class="form_body_item">
                  <p class="form_body_item-title text_size_14 text_color_active">数据源&图表类型</p>

                  <div class="form_body_item-setting">
                    <p class="form_body_item-title text_size_14 text_color_primary">数据源类型</p>
                    <el-select
                      v-model="chartData.dataSourceType"
                      size="small" style="width:100%;"
                      @change="onDataSourceTypeChange">
                      <el-option label="数据库" value="1"></el-option>
                      <el-option label="CSV文件" value="2"></el-option>
                      <el-option label="API" value="3"></el-option>
                    </el-select>
                  </div>

                  <div class="form_body_item-setting">
                    <p class="form_body_item-title text_size_14 text_color_primary">选择已添加的数据源</p>
                    <el-select
                      filterable
                      v-model="chartData.dataSource.dataSourceId"
                      :loading="dataSourceLoading"
                      size="small"
                      style="width:100%;"
                      @change="onDataSourceIdChange">
                      <el-option v-for="item in dataSourceList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                    </el-select>
                  </div>

                  <!-- 基于数据便还是基于SQL模型 -->
                  <template v-if="chartData.dataSourceType === '1'">
                    <div class="form_body_item-setting flex align_center" style="height:30px;" v-if="chartData.dataSource.dataSourceId">
                      <el-radio v-model="dataSourceBase" size="small" label="db" @change="onDataSourceBaseChange">基于数据表</el-radio>
                      <el-radio v-model="dataSourceBase" size="small" label="sql" @change="onDataSourceBaseChange">基于SQL模型</el-radio>
                    </div>
                    <div class="form_body_item-setting" v-if="dataSourceBase === 'db'">
                      <p class="form_body_item-title text_size_14 text_color_primary">选择数据表</p>
                      <el-select
                        filterable
                        v-model="chartData.dataSource.tableName"
                        :loading="dataSourceTableLoading"
                        size="small" style="width:100%;"
                        @change="onTableNameChange">
                        <el-option v-for="item in dataSourceTableList" :key="item.datatable" :label="item.datatable" :value="item.datatable"></el-option>
                      </el-select>
                    </div>
                    <div class="form_body_item-setting" v-if="dataSourceBase === 'sql'">
                      <p class="form_body_item-title text_size_14 text_color_primary">选择已保存的SQL模型</p>
                      <el-select
                        filterable
                        v-model="chartData.dataSource.sqlId"
                        :loading="dataSourceSqlLoading"
                        size="small" style="width:100%;"
                        @change="onSqlIdChange">
                        <el-option v-for="item in dataSourceSqlList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                      </el-select>
                    </div>
                  </template>

                  <template v-if="chartData.dataSourceType === '3'">
                    <div class="form_body_item-setting">
                      <p class="form_body_item-title text_size_14 text_color_primary">选择已保存的SQL模型</p>
                      <el-select
                        filterable
                        v-model="chartData.dataSource.sqlId"
                        :loading="dataSourceSqlLoading"
                        size="small" style="width:100%;"
                        @change="onSqlIdChange">
                        <el-option v-for="item in dataSourceSqlList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                      </el-select>
                    </div>
                  </template>

                  <div class="form_body_item-setting">
                    <!-- 预览数据 -->
                    <data-preview
                      type="demo"
                      :table-loading="tableLoading"
                      :table-data="demoTableData">
                    </data-preview>
                  </div>
                  <div class="form_body_item-setting">
                    <p class="form_body_item-title text_size_14 text_color_primary">选择图表类型</p>
                    <type-select
                      :value="chartData.form_data.viz_type"
                      @selected="onSelectedChartType"
                      :form-data="chartData.form_data"
                      :data-source="chartData.dataSource">
                    </type-select>
                  </div>
                </div>

                <!-- 字段配置 -->
                <div class="form_body_item">
                  <p class="form_body_item-title text_size_14 text_color_active">字段配置</p>
                  <div class="form_body_item-setting">
                    <p class="form_body_item-title text_size_14 text_color_primary">指标</p>
                    <metrics-edit
                      v-model="chartData.form_data.metrics"
                      :columns="demoTableData.columns">
                    </metrics-edit>
                  </div>
                  <div class="form_body_item-setting">
                    <p class="form_body_item-title text_size_14 text_color_primary">分组</p>
                    <groupby-edit
                      v-model="chartData.form_data.groupby"
                      :columns="demoTableData.columns">
                    </groupby-edit>
                  </div>
                  <div class="form_body_item-setting flex flex_direction">
                    <div class="form_body_item-title">
                      <el-checkbox v-model="hasAdhocFilters">过滤器</el-checkbox>
                    </div>
                    <filter-edit
                      :filters.sync="chartData.form_data.adhoc_filters"
                      :disabled="!hasAdhocFilters"
                      :columns="demoTableData.columns">
                    </filter-edit>
                  </div>
                  <div class="form_body_item-setting flex justify_between align_center">
                    <el-checkbox v-model="hasRowLimit">行限制</el-checkbox>
                    <el-select
                      :disabled="!hasRowLimit"
                      v-model.number="chartData.form_data.row_limit"
                      :allow-create="true"
                      :filterable="true"
                      size="small"
                      style="width:190px;">
                      <el-option label="10" :value="10"></el-option>
                      <el-option label="50" :value="50"></el-option>
                      <el-option label="100" :value="100"></el-option>
                      <el-option label="500" :value="500"></el-option>
                      <el-option label="1000" :value="1000"></el-option>
                      <el-option label="10000" :value="10000"></el-option>
                    </el-select>
                  </div>
                </div>

                <!-- 时间条件配置 -->
                <div class="form_body_item">
                  <p class="form_body_item-title text_size_14 text_color_active">时间条件配置</p>
                  <div class="form_body_item-setting">
                    <p class="form_body_item-title text_size_14 text_color_primary">时间字段</p>
                    <el-select filterable v-model="chartData.form_data.granularity_sqla" size="small" style="width:100%;">
                      <el-option v-for="item in demoTableData.columns" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                  </div>
                  <div class="form_body_item-setting">
                    <p class="form_body_item-title text_size_14 text_color_primary">时间粒度</p>
                    <el-select filterable v-model="chartData.form_data.time_grain_sqla" size="small" style="width:100%;">
                      <el-option label="second" value="PT1S"></el-option>
                      <el-option label="minute" value="PT1M"></el-option>
                      <el-option label="hour" value="PT1H"></el-option>
                      <el-option label="day" value="P1D"></el-option>
                      <el-option label="week" value="P1W"></el-option>
                      <el-option label="month" value="P1M"></el-option>
                      <el-option label="quarter" value="P0.25Y"></el-option>
                      <el-option label="year" value="P1Y"></el-option>
                      <!-- <el-option label="week_start_monday" value="1969-12-29T00:00:00Z/P1W"></el-option> -->
                    </el-select>
                  </div>
                  <div class="form_body_item-setting flex flex_direction">
                    <div class="form_body_item-title">
                      <el-checkbox v-model="hasTimeRange">时间范围</el-checkbox>
                    </div>
                    <el-date-picker
                      type="datetimerange"
                      v-model="chartData.form_data.time_range"
                      value-format="yyyy-MM-ddTHH:mm:ss"
                      :disabled="!hasTimeRange"
                      class="my-el-date-picker"
                      popper-class="my-el-date-picker-panel"
                      style="width:100%;"
                      size="small"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间">
                    </el-date-picker>
                  </div>
                  <div class="form_body_item-setting">
                    <!-- 查询预览数据 -->
                    <data-preview
                      type="query"
                      :table-loading="tableLoading"
                      :table-data="queryTableData"
                      @on-preview="runChart(true)">
                    </data-preview>
                  </div>
                </div>
              </template>

              <!-- 可视化属性配置 -->
              <template v-if="settingItem === 'chart'">
                <!-- 存在可视化属性 -->
                <template v-for="(item) in chartSettingList">
                  <!-- 大类配置项 -->
                  <div class="form_body_item" :key="item.className" v-if="!(item.fieldList.length === 1 && chartFieldData[item.fieldList[0]].isDelete)">
                    <p class="form_body_item-title text_size_14 text_color_active">{{ item.className }}</p>
                    <!-- 表单配置项 -->
                    <template v-for="fieldItem in item.fieldList">
                      <div class="form_body_item-setting" :key="fieldItem" v-if="!chartFieldData[fieldItem].isDelete">
                        <p class="form_body_item-title text_size_14 text_color_primary">{{ chartFieldData[fieldItem].label }}</p>
                        <setting-item
                          :value.sync="chartData.form_data[fieldItem]"
                          :setting="chartFieldData[fieldItem]">
                        </setting-item>
                      </div>
                    </template>
                  </div>
                </template>

                <!-- 可视化属性为空 -->
                <div v-if="chartSettingList.length === 0" class="flex flex_direction align_center justify_center" style="height: 100%;">
                  <p class="text_size_14 text_color_primary">可视化属性为空</p>
                </div>
              </template>
            </div>
          </vue-scroll>
        </div>
        <div class="form_footer">
          <el-button size="small" type="primary" :loading="chartLoading" @click="runChart(false)">运行查询</el-button>
        </div>
      </div>

      <!-- 预览 -->
      <div class="chart_detail_main-right panel">

        <!-- 功能组 -->
        <div class="panel_header" v-if="mode !== 'share'">
          <p class="panel_header_title text_size_16 text_color_white">
            <template v-if="mode === 'create'">未命名</template>
            <template v-if="mode === 'edit'">{{chartTitle}}</template>
          </p>
          <div class="panel_header_btns">
            <el-button size="small" type="primary" :loading="saveBtnLoading" @click="onSaveChart('1')">保存图表</el-button>
            <el-button size="small" @click="onSaveChart('2')" v-if="mode === 'edit'">另存为</el-button>
            <el-button size="small" @click="checkMetricsWarning" v-if="mode === 'edit'">度量预警</el-button>
            <el-button size="small" @click="onShareChart">分享</el-button>
          </div>
        </div>

        <!-- 图表 -->
        <div class="panel_body" v-loading="chartLoading" element-loading-background="rgba(24,34,45,0.9)">
          <div class="panel_body_container" :class="chartId ? '' : 'bgImage'">
            <div class="chart_wrapper">
              <!-- 图表 -->
              <div class="chart" ref="chart" :class="chartData.form_data.viz_type"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 图表保存和另存为 -->
    <el-dialog
      :title="saveTitle"
      :visible="showSaveDialog"
      width="600px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onClose">
      <el-form
        :model="saveForm"
        ref="saveForm"
        :rules="saveRules"
        class="margin-left-20 margin-right-20"
        size="small"
        label-width="160px"
        label-position="left"
        hide-required-asterisk>
        <el-form-item label="图表名称" prop="title">
          <el-input v-model="saveForm.title"></el-input>
        </el-form-item>
        <el-form-item prop="oldGroupId">
          <template slot="label">
            <el-radio v-model="saveForm.addNewGroup" :label="0" @change="onAddNewGroupChange">保存至已有分组</el-radio>
          </template>
          <el-select filterable v-model="saveForm.oldGroupId" :disabled="saveForm.addNewGroup === 1" style="width: 100%;">
            <el-option v-for="item in groupList" :key="item.groupId" :label="item.groupName" :value="item.groupId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="newGroupTitle">
          <template slot="label">
            <el-radio v-model="saveForm.addNewGroup" :label="1" @change="onAddNewGroupChange">保存至新的分组</el-radio>
          </template>
          <el-input v-model="saveForm.newGroupTitle" :disabled="saveForm.addNewGroup === 0"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="saveForm.description" type="textarea" :rows="3"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="showSaveDialog = false">取消</el-button>
        <el-button type="primary" size="small" :loading="saveAsBtnLoading" @click="onSaveChartConfirm">确定</el-button>
      </div>
    </el-dialog>

    <!-- 分享图表 -->
    <el-dialog
      title="分享图表"
      :visible="showShareDialog"
      width="600px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div class="share">
        <p class="share_label">复制链接</p>
        <el-input :value="shareUrl" ref="shareInput" size="small"></el-input>
        <el-button type="primary" size="small" @click="shareChart">点击复制</el-button>
      </div>
      <div slot="footer">
        <el-button size="small" @click="showShareDialog = false">取消</el-button>
      </div>
    </el-dialog>

    <!-- 度量预警 -->
    <metrics-warning
      :visible.sync="showWarningDialog"
      :chart-id="chartId">
    </metrics-warning>
  </div>
</template>

<script>
// 图表配置项
import settingItem from './components/chart-setting/setting-item/index'

import * as api from '~/api'
import dataPreview from './components/data-preview'
import typeSelect from './components/type-select'
import metricsEdit from './components/metrics-edit'
import groupbyEdit from './components/groupby-edit'
import filterEdit from './components/filter-edit'
import metricsWarning from './components/metrics-warning'
// 图表字典
import { chartTypes } from './libs/chart-database'
// 图表的渲染方法
import { chartRender } from './libs/chart-renders'
// 图表配置列表
import { chartSettings } from './libs/chartSettings/index'
// 配置项数据字典
import { chartFieldData } from './libs/chartSettings/data'
// 配置项方法map 键是配置项名，值是处理此配置项方法名
import methodMap from './libs/chartSettings/methodMap'
// 配置项方法对象 通过属性名在此对象中调用方法
import methods from './libs/chartSettings/methods'

export default {
  inject: ['$_reload'],
  data () {
    return {
      settingItem: 'data',
      // 页面的loading
      pageLoading: false,
      // 预览的loading
      chartLoading: false,
      // 选择已添加的数据源列表loading
      dataSourceLoading: false,
      // 下拉数据表列表的Loading
      dataSourceTableLoading: false,
      // 下拉选择SQL模型列表的loading
      dataSourceSqlLoading: false,
      // 预览数据的dialog
      tableLoading: false,
      // 保存按钮loading
      saveBtnLoading: false,
      // 另存为按钮loading
      saveAsBtnLoading: false,
      // 另存为的dialog
      showSaveDialog: false,
      // 分享的dialog
      showShareDialog: false,
      // 度量预警的dialog
      showWarningDialog: false,

      // 数据源列表
      dataSourceList: [],
      // 数据源类型为数据库时基于数据库表（db）或已保存的sql模型（sql）
      dataSourceBase: '',
      // 数据表列表
      dataSourceTableList: [],
      // sql模型列表
      dataSourceSqlList: [],
      // 预览样本数据
      demoTableData: {
        allData: [],
        columns: []
      },
      // 预览查询数据
      queryTableData: {
        allData: [],
        columns: []
      },
      hasAdhocFilters: false,
      hasRowLimit: false,
      hasTimeRange: false,
      chartTitle: '', // 图表名称
      // 图表数据
      chartData: {
        chartEntityId: '',
        dataSourceType: '', // 数据源类型
        dataSource: {
          dataSourceId: '', // 数据源id
          tableName: '', // 数据源类型为数据库时可选择数据库表
          sqlId: '' // 数据源类型为数据库或API时可选择sql模型
        },
        form_data: {
          viz_type: '', // 图表类型
          metrics: [], // 指标
          groupby: [], // 分组
          adhoc_filters: [], // 过滤器
          columns: [], //
          row_limit: '', // 行限制
          granularity_sqla: '', // 时间字段
          time_grain_sqla: '', // 时间粒度
          time_range: [] // 时间范围
        }
      },
      // 1:保存,2:另存为
      saveType: '',
      // 保存表单
      saveForm: {
        title: '', // 图表名称
        addNewGroup: 0, // 是否保存到新分组
        oldGroupId: '', // 当前图表分组id
        newGroupTitle: '', // 新分组的名称
        description: '' // 图表描述
      },
      // 保存表单的表单验证
      saveRules: {
        title: [
          { required: true, message: '请输入图表名称', trigger: 'blur' }
        ],
        oldGroupId: [
          {
            validator: (rule, value, callback) => {
              if (this.saveForm.addNewGroup === 1) {
                callback()
              } else if (this.saveForm.addNewGroup === 0) {
                if (value === '') {
                  callback(new Error('请选择分组'))
                } else {
                  callback()
                }
              }
            },
            trigger: 'blur'
          }
        ],
        newGroupTitle: [
          {
            validator: (rule, value, callback) => {
              if (this.saveForm.addNewGroup === 0) {
                callback()
              } else if (this.saveForm.addNewGroup === 1) {
                if (value === '') {
                  callback(new Error('请输入分组名称'))
                } else {
                  callback()
                }
              }
            },
            trigger: 'blur'
          }
        ]
      },
      // 分组列表
      groupList: [],
      // 图表配置项列表
      chartSettingList: []
    }
  },
  computed: {
    // create(新建图表)/edit(编辑图表)/share(分享)
    mode () {
      return this.$route.query.mode || ''
    },
    // 项目id
    projectId () {
      return this.$route.query.projectId || ''
    },
    projectName () {
      return this.$route.query.projectName || ''
    },
    dataSourceType () {
      return this.$route.query.dataSourceType || ''
    },
    dataSourceId () {
      return this.$route.query.dataSourceId || ''
    },
    sqlId () {
      return this.$route.query.sqlId || ''
    },
    groupId () {
      // 分组id
      return this.$route.query.groupId || ''
    },
    chartId () {
      // 图表id
      return this.$route.query.chartId || ''
    },
    sidebar () {
      // 项目下侧边栏路由
      return this.$route.query.sidebar || ''
    },
    sidebarTitle () {
      // 项目下侧边栏名
      return {
        'chart-group': '图表分组',
        'type-group': '图表类型',
        'sql-editor': 'SQL编辑器',
        'sql-query': 'SQL查询库'
      }[this.sidebar]
    },
    sidebarPath () {
      // 项目下侧边栏完整路由
      return {
        path: `project-detail/${this.sidebar}`,
        query: {
          projectId: this.projectId,
          projectName: this.projectName
        }
      }
    },
    groupTitle () {
      // 图表分组名/图表类型名
      return decodeURIComponent(this.$route.query.groupTitle) || ''
    },
    groupType () {
      return this.$route.query.groupType || ''
    },
    groupPath () {
      // 分组完整路由/类型完整路由
      return {
        path: '/project-detail/chart-list',
        query: {
          listSource: this.sidebar,
          sourceTitle: encodeURIComponent(this.groupTitle),
          projectId: this.projectId,
          projectName: this.projectName,
          groupId: this.groupId,
          groupType: this.groupType
        }
      }
    },
    saveTitle () {
      let title = ''
      switch (this.saveType) {
        case '1':
          title = '保存图表'
          break
        case '2':
          title = '图表另存为'
          break
      }
      return title
    },
    // 分享链接
    shareUrl () {
      let url = ''
      if (this.mode === 'edit') {
        url = `${location.origin}/chart-detail?mode=share&chartId=${this.chartId}`
      }
      return url
    },
    // 配置项数据字典
    chartFieldData () {
      return chartFieldData
    }
  },
  components: {
    settingItem,
    dataPreview,
    typeSelect,
    metricsEdit,
    groupbyEdit,
    filterEdit,
    metricsWarning
  },
  filters: {
    /**
     * 格式化图表类型
     *  + 格式化图表类型
     */
    formatType (value, listSource) {
      let result = ''
      if (listSource === 'type-group') {
        const element = chartTypes.find(element => {
          return element.value === value
        })
        result = element ? element.label : ''
      } else {
        result = value
      }
      return result
    }
  },
  mounted () {
    // 初始化数据
    this.initChartData()
  },
  methods: {
    // 根据 是分享的还是编辑还是创建 来初始化数据
    async initChartData () {
      // 分享来的
      if (this.mode === 'share') {
        this.getSharedChartInfo()
      } else {
        // 带着图表id来的，编辑模式
        if (this.chartId) {
          this.getChartData(this.chartId)
        // 创建
        } else {
          // 获取数据源类型
          this.chartData.dataSourceType = this.dataSourceType
          if (this.dataSourceType !== '') {
            await this.onDataSourceTypeChange(this.dataSourceType)
          }

          this.chartData.dataSource.dataSourceId = this.dataSourceId
          this.chartData.dataSource.sqlId = this.sqlId
          await this.onDataSourceIdChange(this.dataSourceId)
          if (this.sqlId) {
            this.onSqlIdChange(this.sqlId)
          }
        }
      }
    },
    // 获取分享的图表数据
    getSharedChartInfo () {
      this.pageLoading = true
      api.getSharedChartInfo({
        id: this.chartId
      }).then(res => {
        if (res.data.code === 0) {
          this.renderChart(res.data.data)
        } else {
          this.$message.error(res.data.message)
        }
        this.pageLoading = false
      }).catch(err => {
        console.log(err)
        this.pageLoading = false
        this.$message.error('网络错误！')
      })
    },
    /**
     * 根据图表id获取图表配置信息
     */
    getChartData (id) {
      this.pageLoading = true
      api.getChartDetail({
        chartEntityId: id
      }).then(async res => {
        if (res.data.code === 0) {
          const data = res.data.data

          // 图表Id
          this.chartData.chartEntityId = id

          // 图表title
          this.chartTitle = data.title

          // 赋值图表数据源类型
          this.chartData.dataSourceType = data.dataSourceType
          await this.onDataSourceTypeChange(data.dataSourceType)

          this.chartData.dataSource.dataSourceId = data.dataSourceId
          this.chartData.dataSource.sqlId = data.sqlId
          this.chartData.dataSource.tableName = data.tableName
          await this.onDataSourceIdChange(data.dataSourceId)

          if (data.sqlId) {
            this.onSqlIdChange(data.sqlId)
          }
          if (data.tableName) {
            this.onTableNameChange(data.tableName)
          }

          // 赋值对象
          for (const key in data.form_data) {
            if (key === 'time_range') {
              if (data.form_data[key] === 'No filter') {
                this.hasTimeRange = false
                this.chartData.form_data[key] = []
              } else {
                this.hasTimeRange = true
                this.chartData.form_data[key] = data.form_data[key].split(' : ')
              }
            } else if (key === 'adhoc_filters') {
              this.hasAdhocFilters = data.form_data[key].length > 0
              this.chartData.form_data[key] = data.form_data[key]
            } else if (key === 'row_limit') {
              this.hasRowLimit = !data.form_data[key] === ''
              this.chartData.form_data[key] = data.form_data[key]
            } else {
              this.chartData.form_data[key] = data.form_data[key]
            }
          }

          // 新的可视化属性配置项列表
          const chartAllFieldList = this.getChartFieldList(this.chartData.form_data.viz_type)
          // 循环此图表所有的可视化字段列表 如果form_data中不存在 则添加进去 默认值为字典中此字段默认值
          chartAllFieldList.forEach(item => {
            // 不存在此属性则使用默认值
            if (!Object.prototype.hasOwnProperty.call(this.chartData.form_data, item)) {
              this.chartData.form_data[item] = this.chartFieldData[item].default
            }
          })

          // 赋值为一个新的对象 确保新增的属性有响应式
          this.chartData.form_data = this.$clone(this.chartData.form_data)
          // 根据图表的type 赋值可视化属性配置列表
          this.chartSettingList = this.getChartSettingList(this.chartData.form_data.viz_type)

          // 运行 生成图表
          this.runChart(false)
        } else {
          this.$message.error(res.data.message)
        }
        this.pageLoading = false
      }).catch(err => {
        this.pageLoading = false
        console.log(err)
        this.$message.error('网络错误！')
      })
    },

    /**
     * 数据源类型发生变化
     *  + 重置数据源id = ‘’
     */
    async onDataSourceTypeChange (value) {
      // 重置数据源id
      this.chartData.dataSource.dataSourceId = ''

      // 切换数据源
      this.onDataSourceIdChange('')

      await this.getDataSourceList(this.projectId, this.chartData.dataSourceType)
    },
    // 根据选择的数据源类型获取数据源列表
    async getDataSourceList (spaceId, dataSourceType) {
      this.dataSourceLoading = true
      await api.getDataSourceList({
        spaceId,
        dataSourceType
      }).then(res => {
        this.dataSourceLoading = false
        if (res.data.code === 0) {
          this.dataSourceList = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.dataSourceLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },
    // 数据源为数据库类型时，获取数据源下的数据表列表
    async getDataSourceTableList (dataSourceId) {
      this.dataSourceTableLoading = true
      await api.getDatabaseTableList({
        dataSourceId
      }).then(res => {
        this.dataSourceTableLoading = false
        if (res.data.code === 0) {
          this.dataSourceTableList = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.dataSourceTableLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 切换数据源
    async onDataSourceIdChange (value) {
      if (value === '') {
        // 重置（基于数据表还是基于SQL模型）
        this.dataSourceBase = ''
        //
        this.onDataSourceBaseChange('')
        return
      }
      if (this.chartData.dataSourceType === '1') {
        // 数据库
        if (this.chartData.dataSource.sqlId !== '') {
          this.dataSourceBase = 'sql'
        } else {
          this.dataSourceBase = 'db'
        }
        await this.onDataSourceBaseChange(this.dataSourceBase)
      } else if (this.chartData.dataSourceType === '2') {
        // CSV
        this.getDataSourcePreview(this.chartData.dataSource)
      } else if (this.chartData.dataSourceType === '3') {
        // api
        this.getDataSourceSqlList(this.projectId, this.chartData.dataSource.dataSourceId)
      }
    },
    /**
     * 选择基于数据便还是基于SQL模型
     */
    async onDataSourceBaseChange (value) {
      if (value === '') {
        // 重置数据表列表
        this.dataSourceTableList = []
        // 重置sql模型id
        this.chartData.dataSource.sqlId = ''
        //
        this.onSqlIdChange('')

        this.dataSourceSqlList = []
        this.chartData.dataSource.tableName = ''
        this.onTableNameChange('')
        return
      }
      if (value === 'db') {
        // 基于数据表制作图表
        this.chartData.dataSource.sqlId = ''
        this.onSqlIdChange('')
        this.dataSourceTableList = []
        await this.getDataSourceTableList(this.chartData.dataSource.dataSourceId)
      }
      if (value === 'sql') {
        // 基于sql模型制作图表
        this.chartData.dataSource.tableName = ''
        this.onTableNameChange('')
        this.dataSourceSqlList = []
        await this.getDataSourceSqlList(this.projectId, this.chartData.dataSource.dataSourceId)
      }
    },
    // 切换数据表
    onTableNameChange (value) {
      if (value) {
        this.getDataSourcePreview(this.chartData.dataSource)
      } else {
        this.demoTableData.allData = []
        this.demoTableData.columns = []
      }
    },

    // 获取数据源下已保存的sql模型列表
    async getDataSourceSqlList (spaceId, dataSourceId) {
      this.dataSourceSqlLoading = true
      await api.getDataSourceSql({
        spaceId,
        dataSourceId
      }).then(res => {
        this.dataSourceSqlLoading = false
        if (res.data.code === 0) {
          this.dataSourceSqlList = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.dataSourceSqlLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },
    // 切换sql模型
    onSqlIdChange (value) {
      if (value) {
        this.getDataSourcePreview(this.chartData.dataSource)
      } else {
        this.demoTableData.allData = []
        this.demoTableData.columns = []
      }
    },

    // 获取样本数据
    getDataSourcePreview ({ dataSourceId, tableName, sqlId }) {
      this.tableLoading = true
      this.demoTableData.allData = []
      this.demoTableData.columns = []
      api.getPreviewData({
        dataSourceId: dataSourceId,
        tableName: tableName,
        sqlId: sqlId,
        rows: 10
      }).then(res => {
        this.tableLoading = false
        if (res.data.code === 0) {
          this.demoTableData = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.tableLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },
    /**
     * 获得配置项的处理结果
     * 遍历此图表类型所有的配置项
     *  + 1、判断此配置项是否有处理函数 需要进行处理
     *  + 2、如果需要处理，根据此配置项名在map中作为key取出，拿到处理方法名
     *  + 3、根据处理方法名在methods对象中渠道处理方法
     *  + 4、根据配置项名在form_data中拿到原始值
     *  + 5、调用处理方法，传入原始值
     *  + 6、得到处理结果对象
    */
    getSettingsResult () {
      // 图表类型
      const chartType = this.chartData.form_data.viz_type
      // 此图表类型所有的配置项列表
      const allFieldList = this.getChartFieldList(chartType)
      const result = {}
      allFieldList.forEach(item => {
        let resultValue = ''
        const hasValue = methodMap.has(item)
        if (hasValue) {
          // 处理配置项的函数名
          const methodName = methodMap.get(item)
          // 处理函数
          const method = methods[methodName]
          try {
            if (!method) {
              throw new Error(`不存在此处理函数 函数名为：${methodName}`)
            }
            const fieldValue = this.chartData.form_data[item]
            resultValue = method(fieldValue)
          } catch (error) {
            console.error(error)
            this.$message.error(error.message)
          }
        } else {
          resultValue = item
        }
        result[item] = resultValue
      })

      console.log('配置项处理结果', result, JSON.stringify(result))

      return result
    },
    /**
     * 对配置项、过滤器、行限制，时间范围进行处理
     *  + 把chartData.form_data 中的原始配置项数据传给后端
     *    后端对需要进行处理的配置项进行处理
     *    处理好后，在runChart此接口的res.data.data.data[0] 中可以拿到处理好的值
     *    @todo 需要把处理配置项的过程迁移到前端，现在已完成了配置项处理函数的迁移
     */
    getQueryChartData () {
      const data = this.$clone(this.chartData)

      // 对配置项进行处理
      this.getSettingsResult()

      if (!this.hasAdhocFilters) {
        data.form_data.adhoc_filters = []
      }

      if (!this.hasRowLimit) {
        data.form_data.row_limit = ''
      }

      if (this.hasTimeRange) {
        data.form_data.time_range = data.form_data.time_range.length > 0 ? `${data.form_data.time_range[0]} : ${data.form_data.time_range[1]}` : 'No filter'
      } else {
        data.form_data.time_range = 'No filter'
      }
      return data
    },

    // 获取查询数据 / 获取图表所需数据
    runChart (previewData) {
      previewData ? this.tableLoading = true : this.chartLoading = true
      this.queryTableData.allData = []
      this.queryTableData.columns = []
      const queryChartData = this.getQueryChartData()
      api.runChart({
        ...queryChartData,
        previewData
      }).then(res => {
        if (res.data.code === 0) {
          if (previewData) {
            // 预览查询数据
            this.queryTableData.allData = res.data.data.allData
            this.queryTableData.columns = res.data.data.columns
          } else {
            // 生成图表
            this.renderChart(res.data.data)
          }
        } else {
          this.$message.error(res.data.message)
        }
        previewData ? this.tableLoading = false : this.chartLoading = false
      }).catch(err => {
        previewData ? this.tableLoading = false : this.chartLoading = false
        console.log(err)
        this.$message.error('网络错误！')
      })
    },
    // 生成chart
    renderChart (data) {
      chartRender[data.form_data.viz_type](this.$refs.chart, {
        width: this.$refs.chart.offsetWidth,
        height: this.$refs.chart.offsetHeight,
        mode: 'edit',
        autoPlay: false,
        colorTheme: '',
        data: data.data,
        form_data: data.form_data
      })
    },

    // 保存/另存为图表
    onSaveChart (type) {
      this.saveType = type
      this.getGroupList()
      if (type === '1') {
        // 点击保存
        if (this.mode === 'create') {
          // 创建图表
          if (this.groupType === 'chart_group') {
            this.saveForm.oldGroupId = this.groupId
          }
          this.showSaveDialog = true
        } else if (this.mode === 'edit') {
          this.saveBtnLoading = true
          // 编辑图表
          const queryChartData = this.getQueryChartData()
          api.saveChart({
            spaceId: this.projectId,
            ...queryChartData
          }).then(res => {
            if (res.data.code === 0) {
              this.$message.success('图表已保存')
            } else {
              this.$message.error(res.data.message)
            }
            this.saveBtnLoading = false
          }).catch(err => {
            console.log(err)
            this.saveBtnLoading = false
            this.$message.error('网络错误！')
          })
        }
      }
      if (type === '2') {
        // 点击另存为
        this.showSaveDialog = true
      }
    },
    // 图表另存为及保存dialog
    onClose () {
      this.$refs.saveForm.resetFields()
    },
    // 获取当前项目下的分组
    getGroupList () {
      api.getChartGroupList({
        projectSpaceId: this.projectId
      }).then(res => {
        if (res.data.code === 0) {
          this.groupList = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },
    onAddNewGroupChange (value) {
      if (value === 0) {
        this.saveForm.newGroupTitle = ''
      }
      if (value === 1) {
        this.saveForm.oldGroupId = ''
      }
    },
    // 保存/另存为图表点击确定
    onSaveChartConfirm () {
      this.$refs.saveForm.validate(valid => {
        if (valid) {
          const queryChartData = this.getQueryChartData()
          if (this.saveType === '2') {
            this.saveAsBtnLoading = true
            // 另存为
            api.saveAsChart({
              spaceId: this.projectId,
              ...queryChartData,
              ...this.saveForm
            }).then(res => {
              if (res.data.code === 0) {
                this.$message.success('图表已保存')
                this.showSaveDialog = false
              } else {
                this.$message.error(res.data.message)
              }
              this.saveAsBtnLoading = false
            }).catch(err => {
              this.saveAsBtnLoading = false
              console.log(err)
              this.$message.error('网络错误！')
            })
          } else if (this.saveType === '1') {
            this.saveAsBtnLoading = true
            // 创建图表
            api.createChart({
              spaceId: this.projectId,
              ...queryChartData,
              ...this.saveForm,
              chartEntityId: ''
            }).then(res => {
              if (res.data.code === 0) {
                this.$message.success('图表已保存')
                this.showSaveDialog = false
                // 刷新为编辑
                this.$router.replace({
                  path: '/chart-detail',
                  query: {
                    mode: 'edit',
                    projectId: this.projectId,
                    projectName: this.projectName,
                    chartId: res.data.data.id,
                    sidebar: this.sidebar,
                    groupId: this.groupId,
                    groupTitle: encodeURIComponent(this.groupTitle),
                    groupType: this.groupType
                  }
                })
                this.$_reload()
              } else {
                this.$message.error(res.data.message)
              }
              this.saveAsBtnLoading = false
            }).catch(err => {
              console.log(err)
              this.saveAsBtnLoading = false
              this.$message.error('网络错误！')
            })
          }
        } else {
          return false
        }
      })
    },
    // 分享图表
    onShareChart () {
      this.showShareDialog = true
    },
    // 分析图表 赋值链接
    shareChart () {
      this.$refs.shareInput.select()
      document.execCommand('copy')
    },
    // 度量预警
    checkMetricsWarning () {
      this.showWarningDialog = true
    },

    /**
     * 获取图表配置项,用于循环出来可视化配置
     *  如果是创建的话 还需要根据fieldList中的字段向form_data中添加属性
     *  +  拿到的结构参见chartSettings/index.js
     */
    getChartSettingList (type) {
      // 此图表类型的配置项
      const settingList = chartSettings[type]
      if (!settingList) {
        this.$message.error('不存在此类型图表配置')
        throw new Error('不存在此类型图表配置 类型为：', type)
      }
      return settingList
    },
    /**
     * 获取图表的所有的配置项数组
     *  + 拿到的是配置项名称数组
     */
    getChartFieldList (type) {
      const settingList = this.getChartSettingList(type)
      const allFieldList = []
      settingList.forEach(sItem => {
        sItem.fieldList && sItem.fieldList.forEach(fItem => {
          allFieldList.push(fItem)
        })
      })
      return allFieldList
    },
    // 选择图表类型
    onSelectedChartType (type) {
      // 旧的图表类型
      const oldChartType = this.chartData.form_data.viz_type
      // 旧的可视化属性配置项列表 不存在为空数组
      const oldChartFieldList = oldChartType ? this.getChartFieldList(oldChartType) : []
      // 新的可视化属性配置项列表
      const newChartFieldList = this.getChartFieldList(type)

      // 赋值新的类型
      this.chartData.form_data.viz_type = type

      // 新的formData
      const newFormData = {}
      /**
       * form_data不止有可视化属性，还有其他属性
       * 把旧的图表类型其可视化属性之外的属性取出来，存在新的form_data中去
       */
      for (const key in this.chartData.form_data) {
        if (!oldChartFieldList.includes(key)) {
          newFormData[key] = this.chartData.form_data[key]
        }
      }

      // 遍历新的图表配置项列表 为formData添加这些属性
      newChartFieldList.forEach(itme => {
        newFormData[itme] = this.chartFieldData[itme].default
      })

      // 赋值为一个新的对象 为后添加的属性支持响应式
      this.chartData.form_data = this.$clone(newFormData)
      // 赋值可视化属性项数组
      this.chartSettingList = this.getChartSettingList(this.chartData.form_data.viz_type)
    }
  }
}
</script>

<style lang="scss" scoped>
.chart_detail {
  width: 100%;
  height: 100%;
  padding: 30px 40px 20px 20px;
  display: flex;
  flex-direction: column;
  &.isShare {
    padding: 0;
    .chart_detail_main {
      margin: 0;
      &-right {
        margin: 0;
      }
      .panel {
        .panel_body {
          padding: 0;
        }
      }
    }
  }
  &_main {
    flex: 1;
    margin-top: 20px;
    display: flex;
    overflow: hidden;
    &-left {
      width: 340px;
      height: 100%;
      background-color: $black-color-light-1;
      border-radius: 4px;
    }
    &-right {
      min-width: 340px;
      flex: 1;
      height: 100%;
      margin: 0 20px;
      background-color: $black-color-light-1;
    }
  }
}
.form {
  display: flex;
  flex-direction: column;
  &_header {
    height: 40px;
    background-color: #0D1721;
    display: flex;
    &_item {
      cursor: pointer;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      &.select {
        background-color: $black-color-light-1;
        color: $color-primary;
      }
    }
  }
  &_body {
    flex: 1;
    background-color: $black-color-light-1;
    &_wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0 20px;
    }
    &_item {
      padding: 20px 0 30px 0;
      &-title {
        height: 30px;
        // line-height: 30px;
        display: flex;
        align-items: center;
      }
      &-setting {
        margin-top: 18px;
      }
      &+.form_body_item {
        border-top: 1px solid #606A75;
      }
    }
    &_divider {
      width: 100%;
      height: 1px;
      background-color: $black-color-light-6;
      margin: 30px 0 20px 0;
    }
  }
  &_footer {
    height: 60px;
    background-color: $black-color-light-1;
    box-shadow:0px 0px 20px rgba(3,13,23,1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.panel {
  display: flex;
  flex-direction: column;
  &_header {
    height: 60px;
    padding: 0 20px;
    background-color: #0D1721;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_title {
      flex: 1;
      margin-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &_body {
    flex: 1;
    padding: 20px 10px 40px 10px;
    overflow: hidden;
    display: flex;
    &_container {
      flex: 1;
      background-color: $black-color-light-2;
      border-radius: 4px;
      display: flex;
      overflow: hidden;
      .chart_wrapper {
        flex: 1;
        padding: 10px;
        overflow: auto;
      }
      .chart {
        min-width: 100%;
        min-height: 100%;
        display: flex;
      }
      &.bgImage {
        background-image: url('../../../assets/img/chart_bg.png');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
.share {
  margin: 0 20px;
  display: flex;
  align-items: center;
  &_label {
    width: 160px;
    line-height: 32px;
  }
}
</style>
