import chart1 from './chart-1'
import chart2 from './chart-2'
import chart3 from './chart-3'
import chart4 from './chart-4'
import chart5 from './chart-5'
import chart6 from './chart-6'
import chart7 from './chart-7'

const allChart = function (name) {
  if (name === 'chart1') {
    return chart1
  } else if (name === 'chart2') {
    return chart2
  } else if (name === 'chart3') {
    return chart3
  } else if (name === 'chart4') {
    // 瓜达尔港 搜索热度
    return chart4
  } else if (name === 'chart5') {
    // 舆情爆发情况
    return chart5
  } else if (name === 'chart6') {
    // 小黄车主体分布柱状图
    return chart6
  } else if (name === 'chart7') {
    // ...
    return chart7
  }
}

export default allChart
