/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
// import echarts from 'echarts'
// import {EchartsSchemes} from '../echarts_theme/echarts_color_schemes'
import './table_doublecol.css'

function TableCarousel (element, props) {
  // console.log(wonderland.color)
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  var data = props.data[0].data

  // const colName = props.data[0].legend
  const rowCount = props.data[0].show_row_limit
  // 当 rowCount 超过 data的数据长度时候, 只会显示最大长度, 不会产生空行
  // const rowHeight = Math.round(props.height * 0.9 / (rowCount + 1))
  // 必须加上底边线的宽度 2 px
  const rowHeight = props.data[0].barWidth + 2
  console.log('rowHeight', rowHeight)

  // 网页加载完成后执行该onload事件
  onload = function () {
    const body = element.children[0]
    body.setAttribute('style', 'display: flex; justify-content: center;align-items: center; width: 95%;height: 95%; margin: 0; padding: 0;')
    const originDiv = document.createElement('div')
    originDiv.setAttribute('style', ' width: 100%; height: 100%')
    body.appendChild(originDiv)

    const secondDiv = document.createElement('div')
    secondDiv.setAttribute('class', 'doubleCol-demo')
    secondDiv.setAttribute('style', ' margin: 10px auto; overflow: hidden; height: ' + rowHeight * rowCount + 'px') // 设置行高的整数倍, 决定了页面显示多少行内容
    // 将div 添加到 预留的节点 下
    originDiv.appendChild(secondDiv)

    //  在 secondDiv 下创建 两个div, 用于生成 表格的行
    const firstDemo = document.createElement('div')
    firstDemo.setAttribute('class', 'demo1')
    const secondDemo = document.createElement('div')
    secondDemo.setAttribute('class', 'demo2')

    //  在 firstDemo 下生成数据行
    firstDemo.appendChild(createSecondTable(data))
    firstDemo.childNodes[0].setAttribute('style', 'display:flex;max-width:100%;')

    secondDiv.appendChild(firstDemo)
    secondDiv.appendChild(secondDemo)

    const demoTwo = body.childNodes[0].childNodes[0].children[1]
    const demoOne = body.childNodes[0].childNodes[0].children[0]

    // 设置文本播放功能
    // 选取所有的span标签， 表格中的cell对象
    // let allTableTrNodes = document.getElementsByClassName('double-tr')
    // setTextAnimation(allTableTrNodes)
    // setOddRowColor(allTableTrNodes);

    // 参考文章 https://www.cnblogs.com/trlanfeng/archive/2012/11/04/2753280.html
    // speed 调用向上移动函数的间隔时间, moveSpeed ==> 设置移动速度, 乘积可得移动时间,  两者时间差, 是暂停时间
    const speed = 20
    let startIndex = 0
    let isStop = false
    const waitTime = 1500
    // console.log(demoOne[0]);
    const demoParent = body.childNodes[0].childNodes[0]
    demoTwo.innerHTML = demoOne.innerHTML

    function Marquee () {
      // 两者差值 小于 行高,就可以切换 重新开始循环了
      if (demoTwo.offsetHeight - demoParent.scrollTop <= 0) {
        demoParent.scrollTop -= demoOne.offsetHeight
      } else {
        if (startIndex === rowHeight) {
          isStop = true
          // console.log(isStop)
          setTimeout(function () {
            demoParent.scrollTop++
            isStop = false
          }, waitTime)
          startIndex = 1
        } else if (startIndex !== rowHeight && isStop === false) {
          demoParent.scrollTop++
          startIndex++
        }
      }
    }

    let MyMar = setInterval(Marquee, speed)

    demoParent.onmouseover = function () {
      clearInterval(MyMar)
    }

    demoParent.onmouseout = function () {
      MyMar = setInterval(Marquee, speed)
    }
  }

  const createSecondTable = function () {
    const secondTable = document.createElement('table')
    secondTable.setAttribute('style', 'border:0; align: center')
    // secondTable.setAttribute('cellpading', '0');
    secondTable.setAttribute('cellspacing', '0')

    // 添加 tbody节点， 避免浏览器自己添加后，造成bug
    const secondTableTbody = document.createElement('tbody')
    secondTableTbody.setAttribute('class', 'table-double-tbody')
    // secondTableTbody.setAttribute('sytle', 'display: block')
    secondTable.append(secondTableTbody)

    // for循环json对象,然后将循环到的对象通过createTr()方法生成行，添加到table中
    // TODO 需要确定数据的长度 length ==>  行的数量
    for (let i = 0; !(i >= data.length); i++) {
      const eachData = data[i]
      const colData = []
      for (const key in eachData) {
        colData.push(eachData[key])
      }
      // 需要 根据 奇偶数 设置行的颜色
      const trNode = createTr(colData)
      trNode.setAttribute('style', 'min-height: ' + rowHeight + 'px')
      secondTableTbody.appendChild(trNode)
    }
    return secondTable
  }

  // ----------------------------------
  //   根据用户传过来的变量生成表格中行的方法
  // ----------------------------------
  const createTr = function (colName) {
    // 定义行元素标签
    const tr = document.createElement('tr')
    tr.setAttribute('class', 'double-tr')
    const colLength = colName.length
    // console.log('colLength:', colLength);
    // const colWidth = Math.floor(100 / colLength)
    // console.log('colWidth:', colWidth);
    for (let i = 0; i < colLength; i++) {
      const tdName = document.createElement('td')
      // tdName.setAttribute('style', 'width: ' + colWidth + '%')
      tdName.setAttribute('class', 'double-td')
      tr.append(tdName)

      // tdName.setAttribute('style', 'padding: 0px; text-align: center; margin: 0px 0px; border: ' +
      //   '0px black solid;border-collapse:collapse; width: ' + colWidth + '%');

      // 创建 包含文本的 span标签， 用来求文本的长度
      const spanNode = document.createElement('div')
      spanNode.setAttribute('class', 'text-move')
      tdName.append(spanNode)
      spanNode.innerHTML = colName[i]
      // console.log(colName[i]);
      tr.appendChild(tdName)
    }
    return tr
  }

  //  不能使用 setInterval 方法,本身就是一个递归方法
  //  props返回的内容增加 mode[edit show]  autoplay[True false],只有当 show, True时, 才执行循环刷新的方法
  const mode = props.mode
  const autoPlay = props.autoPlay
  // console.log(mode, autoPlay)
  if (mode === 'show' && autoPlay === true) {
    onload(autoPlay)
  } else {
    var noPlay = false
    onload(noPlay)
  }
}

export default TableCarousel // 修改为Classname
