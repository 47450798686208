<template>
  <div>
    <div>
      <div :width="chartWidth" :height="chartHeight" ref="chartEarth" style="position: relative">
        <div ref="chartTooltip" class="provinceInfo" style="visibility: hidden">
          <div class="china-province-title" ref="chinaProvince">湖北省</div>
          <div class="china-tip">
            <span>全省开店量：</span>
            <span class="china-num-1" ref="num1">1296</span>
            <span>家</span>
          </div>
          <div class="china-tip">
            <span>全省销售额：</span>
            <span class="china-num-2" ref="num2">6524811864</span>
            <span>元</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import * as d3 from 'd3-geo'
import { SUPERSET_URL } from '~/config'
import { provinceData } from './json/data'
export default {
  data () {
    return {}
  },
  computed: {
    chartWidth () {
      return this.props.width
    },
    chartHeight () {
      return this.props.height
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      // 省份的组
      this.mapGroup = new THREE.Group()
      this.circleGroup = new THREE.Group()
      this.firstZHeight = 2
      this.secondzHeight = 2
      this.mapMoveTop = 12
      this.firstRender = true
      this.hoverSite = []
      this.isMoveOnSite = false // 标注当前鼠标是否位于地区上方

      this.lineColor = 'rgba(255,255,255)'
      this.topAreaColor = ['#02A1E2', '#3480C4']
      this.hoverColor = ['', '']
      this.bottomAreaColor = ['#001E31', '#001E31']
      this.circleColor = '#FFCA37'

      // this.provinceInfo = document.getElementById('provinceInfo')
      // 渲染器
      this.renderer = new THREE.WebGLRenderer()
      this.renderer.setSize(this.props.width, this.props.height) // 设置Canvas画布大小
      this.$refs.chartEarth.appendChild(this.renderer.domElement) // 将画布渲染器绑定到新增的dom节点上；
      // 场景
      this.scene = new THREE.Scene()

      // 相机 透视相机
      this.camera = new THREE.PerspectiveCamera(
        45,
        this.props.width / this.props.height,
        0.1,
        1000
      )
      this.camera.position.set(0, -50, 100)
      this.camera.lookAt(0, 0, 0)
      this.setController() // 设置控制
      this.setLight() // 设置灯光

      this.setRaycaster()
      // this.circleGroup.position.y = this.mapMoveTop
      this.circleGroup.position.y = this.mapMoveTop
      this.scene.add(this.circleGroup)

      this.animate()

      this.loadMapData()
      // 启动地区轮播效果
      this.siteAnimate()

      this.setResize() // 绑定浏览器缩放事件
    },
    // 光照
    light () {
      // let light = new THREE.HemisphereLight(0xffffff);
      const light = new THREE.HemisphereLight('#fff', '#fff', 0.8)
      light.position.set(0, 0, 500)
      this.scene.add(light)

      // AmbientLight 环境灯光 均匀的照亮场景中的物体;无方向,没有投影
      // 参数: 颜色,  强度
      // var ambientLight = new THREE.AmbientLight(0x404040, 1);
      // this.scene.add(ambientLight);

      // Create a SpotLight and turn on shadows for the light
      // var Slight = new THREE.SpotLight(0xffffff);
      // Slight.castShadow = true; // default false
      // //Set up shadow properties for the light
      // Slight.shadow.mapSize.width = 850; // default
      // Slight.shadow.mapSize.height = 850; // default
      // Slight.shadow.camera.near = 500; // default
      // Slight.shadow.camera.far = 2500; // default
      // this.scene.add(Slight);

      // lights
      var sphere = new THREE.SphereBufferGeometry(10, 16, 8)

      // let light1 = new THREE.PointLight( "#fff", 500, 1000 );
      const light1 = new THREE.SpotLight('#fff')
      light1.position.set(0, 0, 1500)
      light1.castShadow = true
      light1.add(
        new THREE.Mesh(sphere, new THREE.MeshBasicMaterial({ color: 0xff0040 }))
      )
      this.scene.add(light1)
    },
    setResize () {
      const _this = this
      _this.$refs.chartEarth.addEventListener('resize', function () {
        _this.renderer.setSize(this.props.width, this.props.height)
      })
    },
    loadMapData () {
      const _this = this
      const path = SUPERSET_URL + '/static/assets/images/geoJson/china.json'
      // static/geojson/china.json
      // ./json/china.json
      // 加载json文件
      const loader = new THREE.FileLoader()
      loader.load(path, function (data) {
        const jsonData = JSON.parse(data)
        _this.initMap(jsonData)
      })

      loader.load(path, function (data) {
        const jsonData = JSON.parse(data)
        _this.initMapSecond(jsonData)
      })

      // 添加周围国家的数据
      const asiaPath = SUPERSET_URL + '/static/assets/images/geoJson/world.json'
      loader.load(asiaPath, function (data) {
        const jsonData = JSON.parse(data)
        _this.initWorldMap(jsonData)
      })
    },
    initMap (chinaJson) {
      // 建一个空对象存放对象
      this.map = new THREE.Object3D()

      const _this = this
      // 设置挤出高度
      const zHeight = _this.firstZHeight

      // 墨卡托投影转换
      const projection = d3
        .geoMercator()
        .center([104.0, 37.5])
        .scale(80)
        .translate([0, 0])

      chinaJson.features.forEach(elem => {
        // 定一个省份3D对象
        const province = new THREE.Object3D()
        // 每个的 坐标 数组
        const coordinates = elem.geometry.coordinates
        // 循环坐标数组
        coordinates.forEach(multiPolygon => {
          // console.log(multiPolygon)
          multiPolygon.forEach(polygon => {
            const shape = new THREE.Shape()
            const lineMaterial = new THREE.LineBasicMaterial({
              color: 'white'
            })
            const lineGeometry = new THREE.Geometry()
            for (let i = 0; i < polygon.length; i++) {
              const [x, y] = projection(polygon[i])
              if (i === 0) {
                shape.moveTo(x, -y)
              }
              shape.lineTo(x, -y)
              lineGeometry.vertices.push(new THREE.Vector3(x, -y, zHeight))
            }

            const extrudeSettings = {
              depth: zHeight - 0.1,
              bevelEnabled: false
            }

            const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings)
            const material = new THREE.MeshBasicMaterial({
              color: _this.topAreaColor[0],
              transparent: true,
              opacity: 0.8
            })
            const material1 = new THREE.MeshBasicMaterial({
              color: _this.topAreaColor[1],
              transparent: true,
              opacity: 0.8
            })
            const mesh = new THREE.Mesh(geometry, [material, material1])
            const line = new THREE.Line(lineGeometry, lineMaterial)
            province.add(mesh)
            province.add(line)
          })
        })

        // 将geo的属性放到省份模型中
        province.properties = elem.properties
        if (elem.properties.center) {
          // 属性值更改为 centroid
          // _this.addCircle([elem.properties.centroid[0], elem.properties.centroid[1]])
          const [x, y] = projection(elem.properties.center)
          province.properties._centroid = [x, y]
          // 获取每个区域的中心位置,然后添加圆点效果
          // 这里y轴必须添加负号, 参考上面, 需要考虑坐标系的正负轴
          _this.addCircle(x, -y, zHeight)
        }

        _this.map.add(province)
        // 先将省份添加到分组内,再添加到场景中
        _this.mapGroup.add(province)
      })
      _this.mapGroup.position.y = this.mapMoveTop
      this.scene.add(_this.mapGroup)
      // this.scene.add(this.map);
    },
    initMapSecond (chinaJson) {
      // 建一个空对象存放对象
      const secondMap = new THREE.Object3D()

      // 设置挤出高度
      const zHeight = this.secondzHeight

      // 墨卡托投影转换
      const projection = d3
        .geoMercator()
        .center([104.0, 37.5])
        .scale(80)
        .translate([0, 0])

      chinaJson.features.forEach(elem => {
        // 定一个省份3D对象
        const province = new THREE.Object3D()
        // 每个的 坐标 数组
        const coordinates = elem.geometry.coordinates
        // 循环坐标数组
        coordinates.forEach(multiPolygon => {
          // console.log(multiPolygon)
          multiPolygon.forEach(polygon => {
            const shape = new THREE.Shape()
            for (let i = 0; i < polygon.length; i++) {
              const [x, y] = projection(polygon[i])
              if (i === 0) {
                shape.moveTo(x, -y)
              }
              shape.lineTo(x, -y)
            }

            const extrudeSettings = {
              depth: zHeight - 0.1,
              bevelEnabled: false
            }

            const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings)
            const material = new THREE.MeshBasicMaterial({
              // 设置底部地图的颜色
              color: this.bottomAreaColor[0],
              transparent: true,
              opacity: 1
            })
            const material1 = new THREE.MeshBasicMaterial({
              color: this.bottomAreaColor[0],
              transparent: true,
              opacity: 1
            })
            const mesh = new THREE.Mesh(geometry, [material, material1])
            province.add(mesh)
          })
        })

        // 将geo的属性放到省份模型中
        province.properties = elem.properties
        if (elem.properties.contorid) {
          const [x, y] = projection(elem.properties.contorid)
          province.properties._centroid = [x, y]
        }

        secondMap.add(province)
      })
      secondMap.position.z = -zHeight
      secondMap.position.y = this.mapMoveTop
      this.scene.add(secondMap)
    },
    initWorldMap (chinaJson) {
      // 建一个空对象存放对象
      this.map = new THREE.Object3D()

      const _this = this
      // 设置挤出高度
      const zHeight = 0

      // 墨卡托投影转换
      const projection = d3
        .geoMercator()
        .center([104.0, 37.5])
        .scale(80)
        .translate([0, 0])

      chinaJson.features.forEach(elem => {
        // 定一个省份3D对象
        const province = new THREE.Object3D()
        // 每个的 坐标 数组
        const coordinates = elem.geometry.coordinates
        // 循环坐标数组
        coordinates.forEach(multiPolygon => {
          // console.log(multiPolygon)
          multiPolygon.forEach(polygon => {
            const shape = new THREE.Shape()
            const lineMaterial = new THREE.LineBasicMaterial({
              color: _this.lineColor,
              transparent: true,
              opacity: 0.2
            })
            const lineGeometry = new THREE.Geometry()
            for (let i = 0; i < polygon.length; i++) {
              const [x, y] = projection(polygon[i])
              if (i === 0) {
                shape.moveTo(x, -y)
              }
              shape.lineTo(x, -y)
              lineGeometry.vertices.push(new THREE.Vector3(x, -y, zHeight))
            }

            const line = new THREE.Line(lineGeometry, lineMaterial)
            province.add(line)
          })
        })
        _this.map.add(province)
      })
      _this.map.position.y = this.mapMoveTop
      _this.map.position.z = -this.secondzHeight
      this.scene.add(this.map)
    },
    setRaycaster () {
      this.raycaster = new THREE.Raycaster()
      this.mouse = new THREE.Vector2()
      // 修改鼠标位置,避免初始化的时候某个地区被选中
      this.mouse.x = -500
      this.mouse.y = -500
      this.eventOffset = {}
      var _this = this

      function onMouseMove (event) {
        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components

        _this.mouse.x = (event.offsetX / _this.props.width) * 2 - 1
        _this.mouse.y = -(event.offsetY / _this.props.height) * 2 + 1
        // _this.eventOffset.x = event.clientX
        // _this.eventOffset.y = event.clientY

        _this.eventOffset.x = event.offsetX
        _this.eventOffset.y = event.offsetY
        // console.log(_this.isMoveOnSite)
        if (_this.isMoveOnSite) {
          _this.$refs.chartTooltip.style.left = _this.eventOffset.x + 40 + 'px'
          _this.$refs.chartTooltip.style.top = _this.eventOffset.y - 130 + 'px'
        }
      }

      _this.$refs.chartEarth.addEventListener('mousemove', onMouseMove, false)
    },

    setLight () {
      const ambientLight = new THREE.AmbientLight(0xffffff, 10) // 环境光
      this.scene.add(ambientLight)
    },

    addCircle (x, y, z) {
      const _this = this
      const radius = Math.random() * 0.5 + 0.1
      const circlePlane = new THREE.CircleGeometry(radius, 10)
      const innerCircle = new THREE.Mesh(circlePlane, new THREE.MeshBasicMaterial({
        color: _this.circleColor,
        side: THREE.DoubleSide,
        opacity: 1,
        depthTest: true
      }))
      const circlePlane2 = new THREE.CircleGeometry(radius + 0.5, 10)
      const outerCircle = new THREE.Mesh(circlePlane2, new THREE.MeshBasicMaterial({
        color: _this.circleColor,
        side: THREE.DoubleSide,
        transparent: true,
        depthTest: false, // 必须设置为false, 否则透明图片会出现黑色
        opacity: 0.21
      }))

      innerCircle.position.set(x, y, z + 0.3)
      outerCircle.position.set(x, y, z + 0.3)
      _this.circleGroup.add(innerCircle)
      _this.circleGroup.add(outerCircle)
    },
    siteAnimate () {
      let index = 0
      const _this = this
      setInterval(() => {
        // 先清除前一个的效果
        // console.log('hoverSite', _this.hoverStie)
        _this.hoverSite.forEach(element => {
          element.material[0].color.set(this.topAreaColor[0])
          element.material[1].color.set(this.topAreaColor[1])
          element.position.z = 0
        })
        // console.log('update site selected. ', this.mapGroup.children)
        // 地区总过35
        const hoverStieElm = _this.mapGroup.children[index % 35].children[0]
        console.log(hoverStieElm)
        hoverStieElm.material[0].color.set('rgba(68, 197, 255, 0.8)')
        hoverStieElm.material[1].color.set(this.topAreaColor[1])
        hoverStieElm.position.z = _this.firstZHeight / 2
        // 修改提示框位置与内容
        var properties = hoverStieElm.parent.properties
        const targetName = properties.name
        this.$refs.chinaProvince.textContent = targetName
        try {
          this.$refs.num1.textContent = parseInt(
            provinceData[targetName][0] * 2500 + 1000
          )
          this.$refs.num2.textContent = parseInt(
            provinceData[targetName][0] * 250000000 + 10000000
          )
        } catch (err) {
          console.log(targetName)
        }
        _this.hoverSite.push(hoverStieElm)
        index++
      }, 1000 * 5)
    },

    setController () {
      this.controller = new OrbitControls(this.camera, this.renderer.domElement)
      this.controller.enableRotate = false
      this.controller.enableZoom = false
      this.controller.maxDistance = 200
      this.controller.minDistance = 200
      this.controller.minZoom = 1
      this.controller.maxZoom = 5
      // this.controller.enablePan = false // 禁止右键拖拽

      // this.controller.enableZoom = true // false-禁止右键缩放

      // this.controller.maxDistance = 200 // 最大缩放 适用于 PerspectiveCamera
      // this.controller.minDistance = 50 // 最大缩放

      // this.controller.enableRotate = true // false-禁止旋转

      /* this.controller.minZoom = 0.5; // 最小缩放 适用于OrthographicCamera
      this.controller.maxZoom = 2; // 最大缩放 */
    },
    animate () {
      requestAnimationFrame(this.animate.bind(this))
      // this.cube.rotation.x += 0.05;
      // this.cube.rotation.y += 0.05;
      this.raycaster.setFromCamera(this.mouse, this.camera)

      if (this.firstRender) {
        this.activeInstersect = [] // 设置为空
        this.firstRender = false
      }

      // calculate objects intersecting the picking ray
      var intersects = this.raycaster.intersectObjects(
        this.mapGroup.children,
        true
      )
      if (this.activeInstersect && this.activeInstersect.length > 0) {
        // 将上一次选中的恢复颜色
        this.activeInstersect.forEach(element => {
          element.object.material[0].color.set('rgba(68, 197, 255, 0.8)')
          element.object.material[1].color.set(this.topAreaColor[1])
          element.object.position.z = 0
        })
      }

      this.activeInstersect = [] // 设置为空

      for (var i = 0; i < intersects.length; i++) {
        if (
          intersects[i].object.material &&
          intersects[i].object.material.length === 2
        ) {
          this.isMoveOnSite = true
          // console.log('intersects[i]', intersects[i])
          this.activeInstersect.push(intersects[i])
          const targetSite = intersects[i].object
          targetSite.material[0].color.set(this.topAreaColor[0])
          targetSite.material[1].color.set(this.topAreaColor[1])
          targetSite.position.z = this.firstZHeight / 2
          break // 只取第一个
        } else {
          this.isMoveOnSite = false
        }
      }

      // 添加自动播放的效果

      this.createProvinceInfo()

      this.renderer.render(this.scene, this.camera)
    },
    createProvinceInfo () {
      // 显示省份的信息
      if (
        this.activeInstersect.length !== 0 &&
        this.activeInstersect[0].object.parent.properties.name
      ) {
        var properties = this.activeInstersect[0].object.parent.properties
        const targetName = properties.name
        this.$refs.chinaProvince.textContent = properties.name
        // console.log(targetName, provinceData[targetName])
        this.$refs.num1.textContent = parseInt(
          provinceData[targetName][0] * 2500 + 1000
        )
        this.$refs.num2.textContent = parseInt(
          provinceData[targetName][0] * 250000000 + 10000000
        )

        this.$refs.chartTooltip.style.visibility = 'visible'
      } else {
        this.$refs.chartTooltip.style.visibility = 'hidden'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.provinceInfo {
  position: absolute;
  z-index: 2;
  background: rgba(9, 39, 62, 0.5);
  border-radius: 2px;
  // padding: 10px;
  visibility: hidden;
  width: 225px;
  height: 100px;
}
</style>
