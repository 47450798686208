import { manageImgPath } from '../chartCommonPart'
import './PicCarouselTen.css'
import drawChart from './chart.js'

const intervalList = []

function ChartFunc (element, props) {
  // ----------------------------------
  //   todo 项目变量设置
  // ----------------------------------
  // ----------------------------------
  const contentWidth = props.data[0].parentWidth
  const contentHeight = props.data[0].parentHeight
  const delayTime = props.data[0].delayTime

  const backgroundColor = 'rgba(1,1,1,0)'
  // const backgroundColor = props.data[0].backgroundColor

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const body = element.children[0]
  body.setAttribute('style', 'width: 100%;height: 100%; overflow: hidden; position: relative; background-color: ' + backgroundColor)
  // 初始化

  const createBody = function () {
    // 整个图表的div
    const originDiv = document.createElement('div')
    originDiv.setAttribute('style', 'height:' + contentHeight + 'px; width: ' + contentWidth + 'px;')
    originDiv.setAttribute('class', 'piccarouselTen-box')
    body.appendChild(originDiv)

    //  轮播内容div
    // /static/img/chart-icon/rection.png
    const imgPath = manageImgPath('/static/assets/images/icons/rectIcon.png')
    const carouselContent = document.createElement('div')
    carouselContent.setAttribute('class', 'carouselTen-body')
    carouselContent.innerHTML = `  <div class="carouselTen-pic-move">
    <img id="move-pic" src="${imgPath}">
  </div>
  <div class="carouselTen-left-part">
    <div class="carouselTen-title">成果</div>
    <div class="carouselTen-title">专利</div>
    <div class="carouselTen-title">科技项目</div>
  </div>
  <div class="carouselTen-table-body">
    <div id="carouselTenId"></div>
  </div>`

    originDiv.appendChild(carouselContent)
    return originDiv
  }

  createBody()

  // 清空已经存在的定时器
  for (let i = 0; i < intervalList.length; i++) {
    clearInterval(intervalList[i])
  }

  //  添加轮博动画
  let moveIndex = 1

  let moveToD = true
  let preIndex = 0

  // 先初始化一次
  const echartDom = document.getElementById('carouselTenId')
  drawChart(echartDom, 0)
  const move = function () {
  // 判断当前定时器的数目，如果超过1个，就清除多余的定时器; 每次保留最新的定时器
    if (intervalList.length > 1) {
    // console.log('intervals ', intervalList)
      for (let i = 0; i < intervalList.length - 1; i++) {
        clearInterval(intervalList[i])
      }
    }

    var targetDom = document.getElementsByClassName('carouselTen-pic-move')[0]
    const targetTextDomList = document.getElementsByClassName('carouselTen-title')

    // 修改文本的颜色
    const preTargetText = targetTextDomList[preIndex]
    preTargetText.setAttribute('style', 'color: rgba(255, 255, 255, 0.3); background-color: rgba(20, 75, 146, 0.5);')

    if (moveIndex > 2) {
      moveIndex -= 2
      moveToD = !moveToD
    }
    if (moveIndex === 0) {
      moveToD = !moveToD
    }

    const moveDwon = 180 * moveIndex
    targetDom.setAttribute('style', 'left: ' + moveDwon + 'px')
    const nextTargetText = targetTextDomList[moveIndex]
    nextTargetText.setAttribute('style', 'color: #FFFFFF; background-color: rgba(20, 75, 146, 1);')
    drawChart(echartDom, moveIndex)

    preIndex = moveIndex
    console.log(preIndex, moveIndex)
    if (moveToD) {
      moveIndex += 1
    } else {
      moveIndex -= 1
    }
  }
  const moveLoop = setInterval(move, delayTime)
  intervalList.push(moveLoop)

  const Play = function () {

    // mySwiper()
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default ChartFunc // 修改为Classname
