/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
// import echarts from 'echarts'
// import {EchartsSchemes} from '../echarts_theme/echarts_color_schemes'
import './movebackcolortable.css'
import allChart from './all'

function MoveBackcolorTable (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  let chartSelected = props.data[0].barStandardSelect
  if (chartSelected === undefined) {
    chartSelected = 'chart1'
  }
  const targetChart = allChart(chartSelected)
  const onload = targetChart(element, props)

  //  不能使用 setInterval 方法,本身就是一个递归方法
  //  props返回的内容增加 mode[edit show]  autoplay[True false],只有当 show, True时, 才执行循环刷新的方法
  const mode = props.mode
  const autoPlay = props.autoPlay
  // console.log(mode, autoPlay)
  if (mode === 'show' && autoPlay === true) {
    onload(autoPlay)
  } else {
    var noPlay = false
    onload(noPlay)
  }
}

export default MoveBackcolorTable // 修改为Classname
