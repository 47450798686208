import './PicCarouselFour.css'
import Swiper from 'swiper'
import downChart from './chart.js'

function PicCarouselFour (element, props) {
  // ----------------------------------
  //   todo 项目变量设置
  // ----------------------------------
  // ----------------------------------
  const originData = props.data[0].originData
  const xAxisData = props.data[0].xAxisData
  const contentWidth = props.data[0].parentWidth
  const contentHeight = props.data[0].parentHeight
  const targetWidth = props.data[0].targetWidth
  const targetHeight = props.data[0].targetHeight
  const maxValuesList = props.data[0].maxValuesList
  const minValuesList = props.data[0].minValuesList
  const legend = props.data[0].legend
  const delayTime = props.data[0].delayTime
  const speedTime = props.data[0].speedTime

  const backgroundColor = 'rgba(1,1,1,0)'

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const body = element.children[0]
  body.setAttribute('style', 'width: 100%;height: 100%; overflow: hidden; position: relative; background-color: ' + backgroundColor)
  // 初始化

  // 添加轮播的内容
  const createDom = function (domType, className, text) {
    const oneDom = document.createElement(domType)
    if (className !== '') {
      oneDom.setAttribute('class', className)
    }
    if (text !== '') {
      oneDom.innerHTML = text
    }
    return oneDom
  }

  // 添加swiper内容的内容
  const createContentList = function (parentDiv, data) {
    const len = data.length
    for (let i = 0; i < len; i++) {
      const oneContent = document.createElement('div')
      oneContent.setAttribute('class', 'swiper-slide')

      // 整体用于布局的div
      const layContent = document.createElement('div')
      layContent.setAttribute('class', 'slide-content')
      // layContent.setAttribute('style', 'margin-left:' + targetMarginLeft + 'px')
      oneContent.appendChild(layContent)
      // 标题
      const titleText = data[i]
      const titleDom = createDom('div', 'picFour-title', titleText)

      layContent.appendChild(titleDom)
      parentDiv.append(oneContent)
    }
  }

  onload = function () {
    // 整个图表的div
    const originDiv = document.createElement('div')
    // originDiv.setAttribute('id', 'pic-box')
    originDiv.setAttribute('style', 'height:' + contentHeight + 'px; width: ' + contentWidth + 'px;')
    originDiv.setAttribute('class', 'swiper-container pic-box')
    body.appendChild(originDiv)

    //  轮播内容div
    const carouselContent = document.createElement('div')
    carouselContent.setAttribute('class', 'swiper-wrapper')
    originDiv.appendChild(carouselContent)

    createContentList(carouselContent, legend)
    return originDiv
  }

  const coverDom = createDom('div', 'picF-parent-cover', '')
  coverDom.setAttribute('style', 'width: 100%;')
  body.appendChild(coverDom)

  const coverLayout = createDom('div', 'picF-layout-cover', '')
  coverLayout.setAttribute('style', 'width: 940px;')
  coverDom.appendChild(coverLayout)

  // 添加渐变色来遮盖文本
  const leftCoverDom = createDom('div', 'picF-img-cover', '')
  const leftImg = createDom('img', 'colorImg', '')
  leftImg.setAttribute('src', '/static/img/chart-icon/PicFLeft.png')
  leftCoverDom.appendChild(leftImg)

  // todo 添加中间的框
  const centerCoverDom = createDom('div', 'picF-img-cover', '')
  const centerImg = createDom('img', 'colorImg', '')
  centerImg.setAttribute('src', '/static/img/chart-icon/boxBorderPicF.png')
  centerCoverDom.appendChild(centerImg)

  const rightCoverDom = createDom('div', 'picF-img-cover', '')
  const RightImg = createDom('img', 'colorImg', '')
  RightImg.setAttribute('src', '/static/img/chart-icon/PicFRight.png')
  rightCoverDom.appendChild(RightImg)

  coverLayout.appendChild(leftCoverDom)
  // coverLayout.appendChild(centerCoverDom)
  coverLayout.appendChild(rightCoverDom)
  const originDom = onload()

  const chartDom = createDom('div', 'echart-dom', '')
  // 设置echart图表的宽度
  chartDom.setAttribute('style', 'width: ' + targetWidth + 'px; height: ' + targetHeight + 'px')
  body.appendChild(chartDom)

  // eslint-disable-next-line no-new
  new Swiper(originDom, {
    speed: speedTime,
    autoplay: {
      disableOnInteraction: true,
      // 每次切换的时间间隔
      delay: delayTime
    }, // 可选选项，自动滑动
    direction: 'horizontal',
    setWrapperSize: true,
    //  开启循环放到最后面
    loop: true,
    watchSlidesProgress: true,
    slidesPerView: 3,
    // slidesPerGroup:3,
    on: {
      slideChangeTransitionStart: function () {
        const currentIndex = this.activeIndex
        downChart(chartDom, originData, xAxisData, maxValuesList, minValuesList, currentIndex)
      }
    }
  })

  const Play = function () {

    // mySwiper()
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default PicCarouselFour // 修改为Classname
