import { numFormatter } from '../chartCommonPart'
const dashedLineData = function (color = 'rgba(255, 255, 255, 0.2)', ratio = 2, num = 50) {
  const res = []
  for (let i = 0; i < num; i++) {
    if (i % 2 === 0) {
      res.push(
        {
          name: 'dash-' + i,
          value: 1,
          itemStyle: {
            color: 'rgba(0,0,0,0)'
          }

        }
      )
    } else {
      res.push(
        {
          name: 'dash-' + i,
          value: 1 * ratio,
          itemStyle: {
            color: color
          }
        }
      )
    }
  }
  console.log(res)
  return res
}

const legendNum = function (data) {
  const res = []
  let sum = 0
  data.forEach(function (item) {
    sum = sum + item.value
  })
  let ratioSum = 0
  for (let i = 0; i < data.length; i++) {
    const eachData = data[i]
    const ratio = parseInt(eachData.value / sum * 10000) / 100
    if (i === (data.length - 1)) {
      res.push({
        value: numFormatter(eachData.value),
        percent: parseInt((100 - ratioSum) * 100) / 100
      })
    } else {
      res.push({
        value: numFormatter(eachData.value),
        percent: ratio
      })
    }
    ratioSum = ratioSum + ratio
  }
  return res
}

const randomVlaue = function () {
  return parseInt(66666666666 * (Math.random() + 0.5))
}

export { dashedLineData, legendNum, randomVlaue }
