import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function renderItem (params, api) {
  var xValue = api.value(0)
  var highPoint = api.coord([xValue, api.value(1)])
  var lowPoint = api.coord([xValue, api.value(2)])
  var halfWidth = api.size([1, 0])[0] * 0.1
  var style = api.style({
    stroke: api.visual('color'),
    fill: null
  })

  return {
    type: 'group',
    children: [{
      type: 'line',
      shape: {
        x1: highPoint[0] - halfWidth,
        y1: highPoint[1],
        x2: highPoint[0] + halfWidth,
        y2: highPoint[1]
      },
      style: style
    }, {
      type: 'line',
      shape: {
        x1: highPoint[0],
        y1: highPoint[1],
        x2: lowPoint[0],
        y2: lowPoint[1]
      },
      style: style
    }, {
      type: 'line',
      shape: {
        x1: lowPoint[0] - halfWidth,
        y1: lowPoint[1],
        x2: lowPoint[0] + halfWidth,
        y2: lowPoint[1]
      },
      style: style
    }]
  }
}

// 创建对象
function CustomErrorBar (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const myChart = echarts.init(div[0][0].children[0], props.data[0].color_echarts_theme)
  // eushow 3.0
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const title = props.data[0].title
  const legend = props.data[0].legend

  // 图表参数配置
  const option = {
    tooltip: {
      trigger: 'axis',
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      axisPointer: {
        type: 'shadow'
      }
    },
    title: {
      show: false,
      text: title
    },
    legend: {
      right: '15px',
      top: '3%',
      data: legend,
      itemWidth: 10,
      itemHeight: 10,
      textStyle: {
        fontSize: 10,
        color: '#fff'
      }
    },
    grid: {
      top: '30px',
      left: '15px',
      right: '15px',
      bottom: '50px',
      containLabel: true
    },
    dataZoom: [{
      type: 'slider',
      start: 50,
      end: 70
    }, {
      type: 'inside',
      start: 50,
      end: 70
    }],
    xAxis: {
      data: props.data[0].categoryData, // 横坐标的名称
      axisLabel: {
        textStyle: {
          fontSize: 10,
          color: '#fff'
        }
      },
      splitLine: {
        show: true,
        interval: 1,
        lineStyle: {
          width: 1,
          color: 'rgba(109,109, 109)'
        }
      }
    },
    yAxis: {
      axisLabel: {
        textStyle: {
          fontSize: 10,
          color: '#fff'
        }
      },
      splitLine: {
        show: true,
        interval: 1,
        lineStyle: {
          width: 1,
          color: 'rgba(109,109, 109)'
        }
      }
    },
    series: [{
      type: 'bar',
      name: legend[0],
      data: props.data[0].barData, // 柱状图的数值
      animationDuration: 2000
    }, {
      type: 'custom',
      name: legend[1],
      itemStyle: {
        normal: {
          borderWidth: 1.5
        }
      },
      renderItem: renderItem, // 自定义的函数
      encode: {
        x: 0,
        y: [1, 2]
      },
      data: props.data[0].errorData, // 误差数值
      z: 100,
      animationDuration: 2000
    }]
  }

  // 参数加载
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  var startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default CustomErrorBar
