import echarts from 'echarts'
import './PicCarouselEight.css'

const lineColor = ['#36FFFC', '#68D0FF', '#FFA200', '#68D0FF', '#36FFFC']
const legendIcon = {
  0: 'image:///static/img/chart-icon/legendOne.png',
  1: 'image:///static/img/chart-icon/legendTwo.png',
  2: 'image:///static/img/chart-icon/legendThree.png',
  3: 'image:///static/img/chart-icon/legendOne.png',
  4: 'image:///static/img/chart-icon/legendTwo.png'
}

const legendName = ['省科技成果数量（项）', '国内科技论文数量（篇）', '省级科技奖励项目（项）', '发明专利授权数（件）', '发明专利拥有量（件）']

const originData = [
  [98287, 6611, 6611, 8138, 10599],
  [27779, 31215, 30413, 30527, 29220],
  [330, 307, 333, 311, 325],
  [4052, 4855, 7766, 8517, 10880],
  [18189, 22536, 30204, 43789, 51569]
]
const xAxisData = [
  [2015, 2016, 2017, 2018, 2019],
  [2013, 2014, 2015, 2016, 2017],
  [2015, 2016, 2017, 2018, 2019],
  [2013, 2014, 2015, 2016, 2017]
]
const maxValuesList = [120000, 32000, 350, 60000]
const minValuesList = [5000, 25000, 300, 3000]

function drawChart (targetDom, index) {
  const myChart = echarts.init(targetDom)
  const legendData = []
  const series = []
  series.push(
    {
      name: legendName[index],
      type: 'line',
      lineStyle: {
        color: lineColor[index],
        width: 2
      },
      data: originData[index],
      label: {
        show: false,
        position: 'top',
        color: lineColor[index],
        fontFamily: 'Helvetica Narrow',
        fontSize: 24
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 1, color: 'rgba(255,255,255,0)'
          }, {

            offset: 0.3, color: lineColor[index]
          }, {

            offset: 0, color: lineColor[index]
          }
          ]
        }
        // opacity: 0.4
      }
    }
  )

  legendData.push(
    {
      name: legendName[index],
      icon: legendIcon[index]
    }
  )

  if (index >= 3) {
    const nextIndex = index + 1
    series.push(
      {
        name: legendName[nextIndex],
        type: 'line',
        lineStyle: {
          color: lineColor[nextIndex],
          width: 2
        },
        data: originData[nextIndex],
        label: {
          show: false,
          position: 'top',
          color: lineColor[nextIndex],
          fontFamily: 'Helvetica Narrow',
          fontSize: 24
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 1, color: 'rgba(255,255,255,0)'
            }, {

              offset: 0.3, color: lineColor[nextIndex]
            }, {

              offset: 0, color: lineColor[nextIndex]
            }
            ]
          }
          // opacity: 0.4
        }
      }
    )
    legendData.push(
      {
        name: legendName[nextIndex],
        icon: legendIcon[nextIndex]
      }
    )
  }

  const option = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      show: true,
      itemWidth: 36,
      itemHeight: 20,
      top: 0,
      left: 0,
      textStyle: {
        color: 'rgba(255,255,255,0.81)',
        fontSize: 20,
        fontFamily: 'PingFang Medium'
      },
      data: legendData
    },
    xAxis: [{
      type: 'category',
      data: xAxisData[index],
      axisTick: {
        show: false
      },
      axisLabel: {
        // rotate: '-45',
        margin: 10,
        textStyle: {
          fontSize: 20,
          color: 'rgba(255, 255, 255, 0.6)'
        }
      },
      axisLine: {
        lineStyle: {
          width: 1,
          color: 'rgba(174, 238, 237, 0)'
        }
      },
      splitLine: {
        show: false
      }
    }],
    yAxis: [
      {
        type: 'value',
        name: '',
        nameGap: 10,
        max: maxValuesList[index],
        min: minValuesList[index],
        minInterval: 0.2,
        axisTick: {
          show: false
        },
        axisLabel: {
          show: true,
          margin: 10,
          fontSize: 20,
          color: 'rgba(255, 255, 255, 0.6)'
        },
        nameTextStyle: {
          fontSize: 20,
          color: 'rgba(255, 255, 255, 0.6)'
        },
        axisLine: {
          lineStyle: {
            width: 1,
            color: 'rgba(174, 238, 237, 0.3)'
          }
        },
        splitLine: {
          show: true,
          interval: 2,
          lineStyle: {
            width: 1,
            type: 'solid',
            color: 'rgba(174, 238, 237, 0.3)'
          }
        }
      },
      {
        type: 'value',
        axisTick: {
          show: false
        },
        show: true,
        // name: secondyYAxisUnit,
        // 垂直方向的距离
        nameGap: 10,
        max: 100,
        // max: 0.6,
        yAxisIndex: 0,
        position: 'right',
        axisLabel: {
          show: false,
          margin: 10,
          fontSize: 20
        },
        nameTextStyle: {
          fontSize: 20
          // color: axisFontColor
        },
        axisLine: {
          lineStyle: {
            width: 1,
            color: 'rgba(174, 238, 237, 0.3)'
          }
        },
        splitLine: {
          show: false
        }
      }
    ],
    grid: {
      left: 0,
      right: 5,
      top: 60,
      buttom: 5,
      containLabel: true
    },
    series: series
  }

  myChart.setOption(option, true)
}

export default drawChart
