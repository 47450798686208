import echarts from 'echarts'

function ringproportionoftwo (element, props) {
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const originData = props.data[0].originData
  // const xAxisData = props.data[0].xAxisData;
  const pieWidth = props.data[0].pieWidth
  const secondPieWidth = props.data[0].secondPieWidth
  const backgroundColor = props.data[0].backgroundColor
  const numberColor = props.data[0].numberColor
  const gradientColorStart = props.data[0].gradientColorStart
  const gradientColorEnd = props.data[0].gradientColorEnd
  const barBackgroundColor = props.data[0].barBackgroundColor
  const numberFontSize = props.data[0].numberFontSize

  const numerator = originData[0]
  let sum = 0
  for (let i = 0; i < originData.length; i++) {
    sum = sum + originData[i]
  }

  const finalPercent = Math.round(numerator / sum * 100)

  const option = {
    backgroundColor: backgroundColor,
    // toolbox: {
    //   feature: {
    //     saveAsImage: {
    //       show: true,
    //       type: 'png',
    //       // 保存的图片的像素比例， 默认为1， 容器大小， 越大越清晰
    //       pixelRatio: 3
    //     }
    //   }
    // },
    title: {
      // 需要计算获取相应的值
      text: finalPercent + '%',
      x: 'center',
      y: 'center',
      textStyle: {
        color: numberColor,
        fontSize: numberFontSize,
        fontWeight: 'normal',
        fontFamily: 'LESLIE'
      }
    },
    tooltip: {
      show: false,
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)'
    },
    legend: {
      x: 'center',
      y: 'bottom',
      data: ['rose3', 'rose5', 'rose6', 'rose7', 'rose8']
    },
    calculable: true,
    series: [
      {
        name: '亮色部分',
        type: 'pie',
        radius: pieWidth,
        center: ['50%', '50%'],
        data: [{
          value: numerator,
          // name: '吴际帅\n牛亚莉',
          itemStyle: {
            // 设置渐变颜色
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
              offset: 0,
              color: gradientColorEnd
            }, {
              offset: 1,
              color: gradientColorStart
            }])
          },
          labelLine: { show: false },
          emphasis: {
            labelLine: { show: false }
          },
          label: {
            show: false,
            color: 'rgba(255,255,255,.45)',
            fontSize: 14,
            formatter: '完成梳理部门\n{a|34}个',
            rich: {
              a: {
                color: '#fff',
                fontSize: 20,
                lineHeight: 30
              }
            }
          }
        },
        {
          value: sum,
          name: 'rose2',
          itemStyle: {
            color: 'transparent'
          }
        }
        ]
      },
      {
        name: '暗色部分',
        type: 'pie',
        radius: secondPieWidth,
        center: ['50%', '50%'],
        data: [{
          value: numerator,
          name: 'firstPie',
          itemStyle: {
            color: 'transparent'
          }
        },
        {
          value: sum,
          name: 'rose2',
          itemStyle: {
            color: barBackgroundColor
          },
          labelLine: { show: false },
          emphasis: {
            labelLine: { show: false }
          },
          label: {
            show: false,
            color: 'rgba(255,255,255,.45)',
            fontSize: 14,
            formatter: '部门总量\n{a|52}个',
            rich: {
              a: {
                color: '#fff',
                fontSize: 20,
                lineHeight: 30
              }
            }
          }
        }
        ]
      }
    ]
  }

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  //  props返回的内容增加 mode[edit show]  autoplay[True false],只有当 show, True时, 才执行循环刷新的方法
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default ringproportionoftwo // 修改为Classname
