<!-- 用户特征分析 -->
<template>
  <div class="chart_21">
    <div class="content_main">
      <div class="chart_box" ref="chartDom" :style="chartBox"></div>
      <div class="legend_box">
        <div
          class="legend_item"
          v-for="(item, index) in legendList"
          :key="index">
          <div class="info">
            <span class="title">{{ item.label }}</span>
            <span class="count">{{ item.value }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content_footer">
      <div
        class="tab_item"
        :class="{'active':item.value === activeName}"
        v-for="item in tabList"
        :key="item.value"
        @click="toggleTab(item.value)">
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import getOptions from '../chart/yq_charts/chart-21'
export default {
  data () {
    return {
      // 激活的tab
      activeName: '1'
      // tabList: tabData
    }
  },
  computed: {
    // 图表宽高
    chartBox () {
      const style = {
        width: this.frontDict.parentWidth + 'px',
        height: this.frontDict.parentHeight + 'px'
      }
      return style
    },
    // 图例
    legendList () {
      const legenItem = this.tabList.find(item => {
        return item.value === this.activeName
      })

      if (legenItem) {
        return legenItem.data
      }
      return []
    },
    tabList () {
      const selectChart = this.frontDict.lineWidth
      console.log(selectChart)
      let tabData

      if (selectChart === 0) {
        tabData = [
          {
            label: '是否大v',
            value: '1',
            data: [
              {
                label: '大v',
                value: 2344,
                color: '#FF6F56'
              },
              {
                label: '非大v',
                value: 5712,
                color: '#43A7FD'
              }
            ]
          },
          {
            label: '机构用户',
            value: '2',
            data: [
              {
                label: '机构用户',
                value: 7093,
                color: '#FF6F56'
              },
              {
                label: '非机构用户',
                value: 972,
                color: '#43A7FD'
              }
            ]
          },
          {
            label: '用户性别',
            value: '3',
            data: [
              {
                label: '男性',
                value: 2959,
                color: '#FF6F56'
              },
              {
                label: '女性',
                value: 5106,
                color: '#43A7FD'
              }
            ]
          }
        ]
      } else {
        tabData = [
          {
            label: '认证类型',
            value: '1',
            data: [
              {
                label: '个人认证',
                value: 6974,
                color: '#FF6F56'
              },
              {
                label: '机构认证',
                value: 3932,
                color: '#43A7FD'
              }
            ]
          },
          {
            label: '原创/转发',
            value: '2',
            data: [
              {
                label: '原创',
                value: 23207,
                color: '#FF6F56'
              },
              {
                label: '转发',
                value: 15033,
                color: '#43A7FD'
              }
            ]
          },
          {
            label: '是否有话题',
            value: '3',
            data: [
              {
                label: '有',
                value: 8474,
                color: '#FF6F56'
              },
              {
                label: '无',
                value: 29766,
                color: '#43A7FD'
              }
            ]
          }
        ]
      }
      console.log(tabData, 'tabData')
      return tabData
    }
  },
  mounted () {
    // 初始化图表
    this.initChart()
  },
  methods: {
    // 初始化数据
    initChart () {
      const option = getOptions(this.legendList)
      const targetDom = this.$refs.chartDom
      if (targetDom) {
        const myChart = echarts.init(targetDom)
        myChart.setOption(option)
      }
    },
    toggleTab (value) {
      this.activeName = value
      this.initChart()
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart_21 {
    .content_main {
      display: flex;
      .chart_box {
        min-width: 140px;
        min-height: 140px;
        // border: 1px solid #000;
      }
      .legend_box {
        width: 204px;
        margin-left: 38px;
        .legend_item {
          position: relative;
          display: flex;
          padding: 0 0 8px 14px;
          width: 100%;
          .info {
            display: flex;
            align-items: baseline;
            .title {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              line-height: 20px;
              color: rgba(255, 255, 255, 0.8);
            }
            .count {
              font-size: 20px;
              font-family: DIN;
              font-weight: bold;
              line-height: 24px;
              margin-left: 8px;
            }
          }
          &::before {
            content: "";
            position: absolute;
            left: -8px;
            bottom: -4px;
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }
          &+.legend_item {
            margin-top: 40px;
          }
          &:first-child {
            border-bottom: 1px solid #FF6F56;
            color: #FF6F56;
            &::before {
              background: #FF6F56;
            }
          }
          &:last-child {
            border-bottom: 1px solid #43A7FD;
            color: #43A7FD;
            &::before {
              background: #43A7FD;
            }
          }
        }
      }
    }
    .content_footer {
      display: flex;
      height: 32px;
      min-height: 32px;
      margin-top: 20px;
      .tab_item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 32px;
        background-image: url('../pic/btn-border-1.png');
        background-size: 100%;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.9);
        cursor: pointer;
        &.active {
          background-image: url('../pic/btn-bg-blue.png');
        }
        &+.tab_item {
          margin-left: 10px;
        }
      }
    }
  }
</style>
