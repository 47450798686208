// import echarts from 'echarts'
const chart5 = function (params) {
  const legend = params.legend
  const lineWidth = params.lineWidth

  const category = params.category
  const targetData = params.baseData[category]

  const originDates = targetData.datetime
  const lineData = targetData.data
  // let textColorList = ['rgba(255, 111, 86, 1)', 'rgba(138, 213, 0, 1)', 'rgba(254, 204, 81, 1)',
  //   'rgba(109, 67, 253, 1)', 'rgba(67, 253, 247, 1)', 'rgba(67, 167, 253, 1)']
  const textColorList = params.textColorList

  // console.log(legend, lineData)
  const series = []
  for (let i = 0; i < lineData.length; i++) {
    series.push(
      {
        name: legend[i],
        type: 'line',
        // 线上的圆点
        symbolSize: 1,
        smooth: params.lineSmoothNumber,
        lineStyle: {
          color: textColorList[i],
          width: lineWidth
        },
        itemStyle: {
          // 设置数据点的颜色 与 标注原点的尺寸
          color: textColorList[i]
        },
        data: lineData[i]
      }
    )
  }

  const option = {
    tooltip: {
      alwaysShowContent: true,
      confine: true,
      padding: [12, 50, 12, 20],
      textStyle: {
        fontSize: 14,
        lineHeight: 28,
        height: 28
      },
      backgroundColor: 'rgba(56, 71, 96, 0.7)',
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.3)',
          width: 1,
          type: 'solid'
        }
      }
    },
    legend: {
      show: false
    },
    grid: {
      top: 10,
      left: 10,
      right: 10,
      bottom: 10,
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      axisLabel: {
        margin: 10,
        textStyle: {
          // color: 'rgba(255, 255, 255, 0.8)',
          color: params.axisFontColor,
          fontSize: 14
        }
      },
      splitLine: {
        show: false
      },
      data: originDates
    },
    yAxis: {
      splitLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.1)'
        }
      },
      axisLabel: {
        show: false
      }
    },
    series: series
  }

  return option
}

export { chart5 }
