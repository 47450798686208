import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function TwoColumnBar (element, props) {
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  const myChart = echarts.init(element.children[0])

  console.log(props)
  const originData = props.data[0].originData
  const xAxisData = props.data[0].xAxisData
  const backgroundBarList = props.data[0].backgroundBarList
  const YlabelToAxis = props.data[0].YlabelToAxis

  const gradientColorStart = props.data[0].gradientColorStart
  const gradientColorEnd = props.data[0].gradientColorEnd
  const barRadius = props.data[0].barRadius
  const barWidth = props.data[0].barWidth
  const barBackgroundColor = props.data[0].barBackgroundColor
  const backgroundColor = 'rgba(0,0,0,0)'
  const axisFontSize = props.data[0].axisFontSize
  const titleColor = props.data[0].titleColor
  const textColor = props.data[0].textColor

  const halfLength = Math.round(originData.length / 2)

  const firstGroup = originData.slice(0, halfLength)
  const firstAxis = xAxisData.slice(0, halfLength)
  const secondGroup = originData.slice(halfLength)
  const secondAxis = xAxisData.slice(halfLength)

  const option = {
    backgroundColor: backgroundColor,
    tooltip: {
      trigger: 'item',
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      formatter: '{b}: {c}'
    },
    grid: [{
      left: axisFontSize + 10,
      top: 10,
      bottom: 10,
      width: '35%',
      containLabel: true
    },
    {
      right: axisFontSize + 20,
      top: 10,
      width: '35%',
      bottom: 10,
      containLabel: true
    }
    ],
    xAxis: [{
      gridIndex: 0,
      show: false
    },
    {
      gridIndex: 0,
      show: false
    },
    {
      gridIndex: 1,
      show: false
    },
    {
      gridIndex: 1,
      show: false
    }
    ],
    yAxis: [{
      axisTick: 'none',
      axisLine: 'none',
      gridIndex: 0,
      // bar 距离 y轴的距离
      offset: YlabelToAxis,
      axisLabel: {
        textStyle: {
          color: titleColor,
          fontSize: axisFontSize,
          align: 'left'
        }
      },
      data: firstAxis
    },
    {
      axisTick: 'none',
      axisLine: 'none',
      gridIndex: 0,
      // 消除底部的超出部分
      offset: '11',
      data: []
    },
    {
      axisTick: 'none',
      axisLine: 'none',
      gridIndex: 1,
      // bar 距离 y轴的距离
      offset: YlabelToAxis,
      axisLabel: {
        textStyle: {
          color: titleColor,
          fontSize: axisFontSize,
          align: 'left'
        }
      },
      data: secondAxis
    },
    {
      axisTick: 'none',
      axisLine: 'none',
      gridIndex: 1,
      // 消除底部的超出部分
      offset: '11',
      data: []
    }
    ],
    series: [
      {
        name: '条',
        type: 'bar',
        gridIndex: 0,
        xAxisIndex: 0,
        yAxisIndex: 0,
        data: firstGroup,
        barWidth: barWidth,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              //  x, y, x2, y2
              0, 0, 1, 0,
              [{
                offset: 0,
                color: gradientColorStart
              },
              {
                offset: 1,
                color: gradientColorEnd
              }
              ]
            ),
            barBorderRadius: barRadius
          }
        },
        z: 2
      },
      {
        name: '背景色',
        type: 'bar',
        gridIndex: 0,
        barWidth: barWidth,
        hoverAnimation: false,
        data: backgroundBarList.slice(0, halfLength),
        xAxisIndex: 1,
        yAxisIndex: 1,
        symbolSize: 35,
        label: {
          normal: {
            show: true,
            position: 'right',
            //  label 距离顶部的位置
            distance: 5,
            //   data ==> 该范围内的 data
            formatter: function (data) {
              return firstGroup[data.dataIndex]
            },
            textStyle: {
              color: textColor,
              fontSize: axisFontSize
            }
          }
        },
        itemStyle: {
          normal: {
            color: barBackgroundColor,
            barBorderRadius: barRadius
          }
        },
        z: 1
      },
      {
        name: '条',
        type: 'bar',
        gridIndex: 1,
        xAxisIndex: 2,
        yAxisIndex: 2,
        data: secondGroup,
        barWidth: barWidth,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              //  x, y, x2, y2
              0, 0, 1, 0,
              [{
                offset: 0,
                color: gradientColorStart
              },
              {
                offset: 1,
                color: gradientColorEnd
              }
              ]
            ),
            barBorderRadius: barRadius
          }
        },
        z: 2
      },
      {
        name: '背景色',
        type: 'bar',
        barWidth: barWidth,
        gridIndex: 1,
        hoverAnimation: false,
        data: backgroundBarList.slice(halfLength),
        xAxisIndex: 3,
        yAxisIndex: 3,
        symbolSize: 35,
        label: {
          normal: {
            show: true,
            position: 'right',
            //  label 距离顶部的位置
            distance: 5,
            //   data ==> 该范围内的 data
            formatter: function (data) {
              return secondGroup[data.dataIndex]
            },
            textStyle: {
              color: textColor,
              fontSize: axisFontSize
            }
          }
        },
        itemStyle: {
          normal: {
            color: barBackgroundColor,
            barBorderRadius: barRadius
          }
        },
        z: 1
      }
    ]
  }

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default TwoColumnBar // 修改为Classname
