import echarts from 'echarts'

function PieTypeSecond (element, props) {
  const backgroundColor = 'rgba(1,1,1,0)'

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const myChart = echarts.init(element.children[0], props.colorTheme)

  const pieWidth = props.data[0].pieWidth
  const secondPieWidth = props.data[0].secondPieWidth
  const resultData = props.data[0].resultData
  const textColorList = props.data[0].textColorList
  // const legendTop = props.data[0].legendTop
  // const legendLeft = props.data[0].legendLeft
  // const piePosition = props.data[0].piePosition
  const piePosition = [270, 90]

  const legentTextColorList = ['rgba(54, 255, 252)', 'rgba(104, 208, 255)', 'rgba(255, 162, 0)', 'rgba(210, 111, 88)', 'rgba(174, 238, 237)']

  let sum = 0
  for (let i = 0; i < resultData.length; i++) {
    const oneDict = resultData[i]
    sum = sum + oneDict.value
  }
  for (let i = 0; i < resultData.length; i++) {
    const targetValue = resultData[i].value
    resultData[i].percent = (targetValue / sum * 100).toFixed(2)
  }
  const legendData = []
  for (let j = 0; j < resultData.length; j++) {
    const data = {
      name: resultData[j].name,
      textStyle: {
        color: legentTextColorList[j],
        fontSize: 10,
        fontfamily: 'PingFang Medium'
      }
    }
    legendData.push(data)
  }

  function array2obj (array, key) {
    var resObj = {}
    for (var i = 0; i < array.length; i++) {
      resObj[array[i][key]] = array[i]
    }
    return resObj
  }

  const objData = array2obj(resultData, 'name')

  const option = {
    backgroundColor: backgroundColor,
    color: textColorList,
    legend: {
      show: true,
      left: 10,
      top: 40,
      formatter: function (name) {
        // return `{title|${name}}   {percent|${objData[name].percent}}{unit|%}`
        return `${name}     ${objData[name].percent}%`
      },

      itemWidth: 15,
      itemHeight: 8,
      itemGap: 20,
      orient: 'vertical',
      icon: 'roundRect',
      data: legendData
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    series: [{
      name: 'first_pie',
      type: 'pie',
      radius: pieWidth,
      center: piePosition,
      hoverAnimation: false,
      zlevel: 10,
      itemStyle: {
        normal: {
          borderColor: '#fff',
          borderWidth: 0
        }
      },
      label: {
        show: false
      },
      labelLine: {
        normal: {
          show: false
        }
      },
      data: resultData
    },
    {
      name: 'second_pie',
      type: 'pie',
      zlevel: 0,
      center: piePosition,
      radius: secondPieWidth,
      data: [100],
      hoverAnimation: false,
      itemStyle: {
        color: 'rgba(54, 255, 252, 1)'
      },
      label: {
        show: false,
        normal: {
          position: 'inner'
        }
      }
    }
    ]
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  function Play () {
    myChart.clear()
    myChart.setOption(option)
    // 监听页面点击事件
    myChart.on('click', function (params) {
      const gorupByValue = params.name
      props.onInteract(gorupByValue)
    })
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default PieTypeSecond // 修改为Classname
