import echarts from 'echarts'
import 'echarts/map/js/china'
import 'echarts/map/js/province/hubei'
// import hubei from 'echarts/map/json/province/hubei.json'
import { hideDelayTime, trigger } from '../chartCommonPart'

function Hubei (element, props) {
  const divId = 'echarts_Hubei'
  // 建立chart图表的容器， 在HTML中生成div标签 用来插入表格
  element.innerHTML = `<div id="${divId}" style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  var myChart = echarts.init(element.children[0])

  var data = [
    {
      name: '武汉市',
      value: 71
    },
    {
      name: '黄石市',
      value: 72
    }, {
      name: '十堰市',
      value: 73
    }, {
      name: '宜昌市',
      value: 74
    }, {
      name: '襄阳市',
      value: 75
    },
    {
      name: '鄂州市',
      value: 76
    },
    {
      name: '荆门市',
      value: 77
    }, {
      name: '孝感市',
      value: 78
    }, {
      name: '荆州市',
      value: 79
    }, {
      name: '黄冈市',
      value: 80
    }, {
      name: '咸宁市',
      value: 81
    }, {
      name: '随州市',
      value: 82
    }, {
      name: '恩施土家族苗族自治州',
      value: 83
    }, {
      name: '仙桃市',
      value: 84
    }, {
      name: '潜江市',
      value: 85
    }, {
      name: '天门市',
      value: 86
    },
    {
      name: '神农架林区',
      value: 87
    }
  ]

  const option = {
    backgroundColor: 'rgba(0,0,0,0)',
    tooltip: {
      trigger: 'item',
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      backgroundColor: 'rgba(234,234,234,0.13)',
      // borderColor: '#FFFFCC',
      showDelay: 0,
      enterable: true,
      transitionDuration: 0,
      extraCssText: 'z-index:100',
      formatter: function (params, ticket, callback) {
        // 根据业务自己拓展要显示的内容
        var nameObject = params.name
        // console.log(params);
        // console.log(name_object);
        if (nameObject === '1') {
          // ProvinceName = MapData[name_object].name;
          return '技术合同交易金额' + ': ' + params.value
        }
        // var res = "";
        // var name = params.name;
        // var value = params.value;
        // res = "<span style='color:#fff;'>" + name + "</span><br/>数据：" + value;
        // return res;
      }
    },
    geo: {
      map: '湖北',
      // left: '500',
      // center:[114.298572, 30.584355],
      label: {
        show: false,
        textStyle: {
          color: '#ff0'
        }
      },
      itemStyle: { // 地图区域的多边形 图形样式
        normal: {
          areaColor: '#192C36',
          borderWidth: 3,
          borderColor: '#2BF5EF'
        },
        emphasis: {
          label: {
            show: false
          },
          areaColor: '#192C36',
          borderWidth: 0
        }
      }
    },
    series: [
      {
        type: 'map',
        mapType: '湖北',
        roam: false, // 是否开启鼠标缩放和平移漫游
        label: {
          // 决定了高亮时是否显示地理名称
          show: false,
          textStyle: {
            color: 'transparent'
          }
        },
        itemStyle: { // 地图区域的多边形 图形样式
          normal: {
            areaColor: '#192C36',
            borderWidth: 1,
            borderType: 'solid',
            borderColor: '#167d7c'
          },
          emphasis: {
            // 是图形在高亮状态下的样式,比如在鼠标悬浮或者图例联动高亮时
            label: {
              show: false,
              color: '#fff'
            },
            borderWidth: 1,
            borderType: 'solid',
            // borderColor: 'rgba(81, 255, 254)',
            areaColor: 'transparent'
          }
        },
        data: data
      }
    ]
  }
  // 使用上述配置显示图标
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default Hubei
