import './PicCarousel.scss'
import Swiper from 'swiper'

function PicCarousel (element, props) {
  console.log('piccarousel', props)
  // console.log(props)
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  // eushow 3.0
  // 预留的div  let body = div[0][0].children[0];
  const textData = props.data[0].data
  // ----------------------------------
  //   todo 项目变量设置
  // ----------------------------------
  const contentWidth = props.data[0].parentWidth
  const contentHeight = props.data[0].parentHeight
  // const imgWidth = props.data[0].targetWidth
  // const imgHeight = props.data[0].targetHeight

  // const buttomIconSize = props.data[0].iconHeight
  // const buttomIconColor = props.data[0].iconColor
  const textBodyWidth = props.data[0].textBodyWidth
  // const backgroundColor = props.data[0].backgroundColor
  const delayTime = props.data[0].delayTime
  const speedTime = props.data[0].speedTime
  console.log(delayTime, speedTime)

  // const targetMarginLeft = props.data[0].targetMarginLeft;
  const targetMarginLeft = (100 - textBodyWidth) / 2

  const createDom = function (domType, className, text) {
    const oneDom = document.createElement(domType)
    if (className !== '') {
      oneDom.setAttribute('class', className)
    }
    if (text !== '') {
      oneDom.innerHTML = text
    }
    return oneDom
  }

  const createContentList = function (parentDiv, data) {
    const len = data.length
    for (let i = 0; i < len; i++) {
      const targetDict = data[i]
      // console.log('target dict', targetDict)
      const oneContent = document.createElement('div')
      // oneContent.setAttribute('class', 'pic-li_list');
      oneContent.setAttribute('class', 'swiper-slide')

      // 整体用于布局的div
      const layContent = document.createElement('div')
      layContent.setAttribute('class', 'slide-content piccarousel')
      // layContent.setAttribute('style', 'margin-left:' + targetMarginLeft + 'px')
      oneContent.appendChild(layContent)

      const domainText = targetDict['技术领域']
      const domainDom = createDom('div', 'pic-domain-text', domainText)

      // 标题
      const titleText = targetDict['需求名称']
      const titleDom = createDom('div', 'pic-title', titleText)

      const subTitleText = targetDict['所属企业']
      const subTitleDom = createDom('div', 'pic-sub-title', subTitleText)

      // 第一部分的文本
      const firstText = createDom('div', 'pic-pre-first', '')
      firstText.setAttribute('style', 'width: ' + textBodyWidth + '%;margin-left:' + targetMarginLeft + '%')
      const firstTitle = createDom('span', 'pic-first-text', '拟投入金额: ')
      let firstBodyText = targetDict['拟投入金额（万元）']
      if (firstBodyText !== '面议') {
        firstBodyText = firstBodyText + '万元'
      }
      const firstBody = createDom('p', 'pic-number', firstBodyText)
      firstText.append(firstTitle)
      firstText.append(firstBody)
      // 添加图片下方的文本
      // 利用循环来写
      const secondText = createDom('div', 'pic-next-text pic-text', '')
      secondText.setAttribute('style', 'width: ' + textBodyWidth + '%;margin-left:' + targetMarginLeft + '%')
      const secondTitle = createDom('span', 'pic-second-title', '需解决的主要问题: ')
      const secondBodyText = targetDict['需解决的主要技术问题']
      const secondBody = createDom('p', 'pic-body-text', secondBodyText)
      secondText.append(secondTitle)
      secondText.append(secondBody)

      const thirdText = createDom('div', 'pic-next-text pic-text', '')
      thirdText.setAttribute('style', 'width: ' + textBodyWidth + '%;margin-left:' + targetMarginLeft + '%')
      const thirdTitle = createDom('span', 'pic-second-title', '拟实现的主要技术目标: ')
      const thirdBodyText = targetDict['拟实现的主要技术目标']
      const thirdBody = createDom('p', 'pic-body-text', thirdBodyText)
      thirdText.append(thirdTitle)
      thirdText.append(thirdBody)

      layContent.appendChild(domainDom)
      layContent.appendChild(titleDom)
      layContent.appendChild(subTitleDom)
      layContent.appendChild(firstText)
      // layContent.appendChild(imgDiv)
      layContent.appendChild(secondText)
      layContent.appendChild(thirdText)
      parentDiv.append(oneContent)
    }
  }

  onload = function () {
    const body = element.children[0]
    body.setAttribute('style', 'width: 100%;height: 100%; overflow: hidden;')

    // 整个图表的div
    const originDiv = document.createElement('div')
    // originDiv.setAttribute('id', 'pic-box')
    originDiv.setAttribute('style', 'height:' + contentHeight + 'px; width: ' + contentWidth + 'px; background-color: rgba(255, 255, 255, 0.89)')
    originDiv.setAttribute('class', 'swiper-container pic-box')
    body.appendChild(originDiv)

    //  轮播内容div
    const carouselContent = document.createElement('div')
    carouselContent.setAttribute('class', 'swiper-wrapper')
    originDiv.appendChild(carouselContent)

    createContentList(carouselContent, textData)

    return originDiv
  }
  const originDiv = onload()
  // eslint-disable-next-line no-new
  new Swiper(originDiv, {
    speed: speedTime,
    autoplay: {
      disableOnInteraction: true,
      // 每次切换的时间间隔
      delay: delayTime
    },
    direction: 'horizontal',

    // 设置slide 的宽度
    // width: 600,
    // autoHeight: true,
    setWrapperSize: true,
    // 设置前进 or 后退的按钮
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
    //  开启循环放到最后面
    loop: true
  })

  const Play = function () {

    // mySwiper()
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default PicCarousel // 修改为Classname
