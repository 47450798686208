import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function DatasetLink (element, props) {
  const AllData = props.data[0].all_data
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  // eushow 3.0
  const myChart = echarts.init(element.children[0], props.colorTheme)

  // 定义函数
  const series = []
  for (let j = 0; j < AllData.length; j++) {
    series.push(
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        animationDuration: 2000
      })
  }
  series.push(
    {
      type: 'pie',
      id: 'pie',
      radius: '30%',
      center: ['50%', '30%'],
      label: {
        formatter: '{b}: {@1} ({d}%)'
      },
      encode: {
        itemName: 'product',
        value: 1,
        tooltip: 1
      }
    })

  const option = {
    legend: {
      // orient: 'vertical',
      // right: '2%',
      // top: 'center',
      left: 'center',
      // itemHeight: 10,
      // itemWidth: 10,
      textStyle: {
        fontSize: 10,
        color: '#fff'
      }
    },
    tooltip: {
      trigger: 'axis',
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      showContent: false
    },
    dataset: {
      source: AllData
    },
    xAxis: {
      type: 'category',
      axisTick: {
        show: false,
        color: '#fff'
      },
      axisLabel: {
        // rotate: '-45',
        // margin: 30,
        textStyle: {
          fontSize: 10,
          color: '#fff'
        }
      },
      axisLine: {
        lineStyle: {
          width: 1,
          color: '#fff'
        }
      },
      splitLine: {
        show: true,
        width: 5,
        lineStyle: {
          width: 1,
          color: 'rgba(109,109, 109)'
        }
      }
    },
    yAxis: {
      gridIndex: 0,
      axisLabel: {
        // formatter: '{value}',
        // margin: 15,
        fontSize: 10,
        color: '#fff'
      },
      nameTextStyle: {
        fontSize: 10,
        color: 'rgb(195,195,195)'
      },
      axisLine: {
        lineStyle: {
          width: 1,
          color: '#fff'
        }
      },
      splitLine: {
        show: true,
        width: 5,
        lineStyle: {
          width: 1,
          color: 'rgba(109,109, 109)'
        }
      }
    },
    grid: {
      top: '55%',
      left: '20px',
      right: '20px',
      height: '40%',
      buttom: '5%',
      containLabel: true
    },
    series: series
  }

  myChart.on('updateAxisPointer', function (event) {
    var xAxisInfo = event.axesInfo[0]
    if (xAxisInfo) {
      var dimension = xAxisInfo.value + 1
      myChart.setOption({
        series: {
          id: 'pie',
          label: {
            formatter: '{b}: {@[' + dimension + ']} ({d}%)'
          },
          encode: {
            value: dimension,
            tooltip: dimension
          }
        }
      })
    }
  })
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  //  props返回的内容增加 mode[edit show]  autoplay[True false],只有当 show, True时, 才执行循环刷新的方法
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  var startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default DatasetLink
