
const initChart = function (params) {
  const topicData = [{ id: 0, name: '小黄车退押金套路多，困难重重', symbolSize: 53.12, x: 37.94, y: -69.3, value: 2656, category: 0 }, { id: 1, name: '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款', symbolSize: 41.98, x: 93.21, y: 56.67, value: 2099, category: 0 }, { id: 2, name: '小黄车退押金周期长', symbolSize: 19.6, x: -25.54, y: 48.51, value: 980, category: 0 }, { id: 3, name: '退押金排队（系统）人数多', symbolSize: 26.54, x: -48.56, y: 47.05, value: 1327, category: 0 }, { id: 4, name: 'ofo推出押金新政策，押金投资理财项目后可免押金', symbolSize: 44.84, x: -59.22, y: 32.62, value: 2242, category: 0 }, { id: 5, name: '小黄车推出折扣商城，押金兑换金币购物', symbolSize: 26.36, x: -68.88, y: 90.23, value: 1318, category: 0 }]
  const scaleValue = params.circleSize
  const newData = []
  topicData.forEach(function (ele) {
    ele.symbolSize = Math.round(ele.symbolSize * scaleValue, 2)
    newData.push(ele)
  })
  const option = {
    tooltip: {
      show: true,
      confine: true,
      padding: [12, 50, 12, 20],
      textStyle: {
        fontSize: 14,
        lineHeight: 28,
        height: 28
      },
      formatter: function (params) {
        const targetType = params.dataType
        const res = ''
        if (targetType === 'node') {
          return `<span>${params.seriesName}</span><p>${params.name}: ${params.value}</p>`
        }
        return res
      },
      backgroundColor: 'rgba(56, 71, 96, 0.7)'
    },
    grid: {
      left: 10,
      right: 10,
      top: 10,
      bottom: 10
    },
    legend: {
      selectedModel: 'multi',
      itemWidth: 30,
      itemHeight: 16,
      borderRadius: 2,
      bottom: 10,
      data: ['主题'],
      textStyle: {
        color: 'rgba(255, 255, 255, 1)'
      },
      selected: {
        主题: true
      }
    },
    animationDuration: 1500,
    animationEasingUpdate: 'quinticInOut',
    series: [
      {
        name: '小黄车事件',
        type: 'graph',
        layout: 'circular',
        // width: '100%',
        // height: '100%',
        label: {
          show: false,
          color: 'rgba(0,0,0,0)'
        },
        categories: [
          { name: '主题', itemStyle: { color: 'rgba(60, 202, 236, 1)' } }
        ],
        data: newData,
        lineStyle: {
          color: 'source',
          curveness: 0.2
        },
        links: [
          {
            id: '0',
            source: '1',
            target: '0'
          }, {
            id: '1',
            source: '2',
            target: '0'
          }, {
            id: '2',
            source: '3',
            target: '0'
          }, {
            id: '3',
            source: '3',
            target: '2'
          }, {
            id: '4',
            source: '4',
            target: '0'
          }, {
            id: '5',
            source: '5',
            target: '0'
          }, {
            id: '6',
            source: '6',
            target: '0'
          }, {
            id: '7',
            source: '7',
            target: '0'
          }, {
            id: '8',
            source: '8',
            target: '0'
          }, {
            id: '9',
            source: '9',
            target: '0'
          }, {
            id: '13',
            source: '11',
            target: '0'
          }, {
            id: null,
            source: '11',
            target: '2'
          }, {
            id: '11',
            source: '11',
            target: '3'
          }, {
            id: '10',
            source: '11',
            target: '10'
          }, {
            id: '14',
            source: '12',
            target: '11'
          }, {
            id: '15',
            source: '13',
            target: '11'
          }, {
            id: '16',
            source: '14',
            target: '11'
          }, {
            id: '17',
            source: '15',
            target: '11'
          }, {
            id: '18',
            source: '17',
            target: '16'
          }, {
            id: '19',
            source: '18',
            target: '16'
          }, {
            id: '20',
            source: '18',
            target: '17'
          }, {
            id: '21',
            source: '19',
            target: '16'
          }, {
            id: '22',
            source: '19',
            target: '17'
          }, {
            id: '23',
            source: '19',
            target: '18'
          }, {
            id: '24',
            source: '20',
            target: '16'
          }, {
            id: '25',
            source: '20',
            target: '17'
          }, {
            id: '26',
            source: '20',
            target: '18'
          }, {
            id: '27',
            source: '20',
            target: '19'
          }, {
            id: '28',
            source: '21',
            target: '16'
          }, {
            id: '29',
            source: '21',
            target: '17'
          }, {
            id: '30',
            source: '21',
            target: '18'
          }, {
            id: '31',
            source: '21',
            target: '19'
          }, {
            id: '32',
            source: '21',
            target: '20'
          }, {
            id: '33',
            source: '22',
            target: '16'
          }, {
            id: '34',
            source: '22',
            target: '17'
          }, {
            id: '35',
            source: '22',
            target: '18'
          }, {
            id: '36',
            source: '22',
            target: '19'
          }, {
            id: '37',
            source: '22',
            target: '20'
          }, {
            id: '38',
            source: '22',
            target: '21'
          }, {
            id: '47',
            source: '23',
            target: '11'
          }, {
            id: '46',
            source: '23',
            target: '12'
          }, {
            id: '39',
            source: '23',
            target: '16'
          }, {
            id: '40',
            source: '23',
            target: '17'
          }, {
            id: '41',
            source: '23',
            target: '18'
          }, {
            id: '42',
            source: '23',
            target: '19'
          }, {
            id: '43',
            source: '23',
            target: '20'
          }, {
            id: '44',
            source: '23',
            target: '21'
          }, {
            id: '45',
            source: '23',
            target: '22'
          }, {
            id: null,
            source: '24',
            target: '11'
          }, {
            id: '48',
            source: '24',
            target: '23'
          }, {
            id: '52',
            source: '25',
            target: '11'
          }, {
            id: '51',
            source: '25',
            target: '23'
          }, {
            id: '50',
            source: '25',
            target: '24'
          }, {
            id: null,
            source: '26',
            target: '11'
          }, {
            id: null,
            source: '26',
            target: '16'
          }, {
            id: '53',
            source: '26',
            target: '24'
          }, {
            id: '56',
            source: '26',
            target: '25'
          }, {
            id: '57',
            source: '27',
            target: '11'
          }, {
            id: '58',
            source: '27',
            target: '23'
          }, {
            id: null,
            source: '27',
            target: '24'
          }, {
            id: '59',
            source: '27',
            target: '25'
          }, {
            id: '61',
            source: '27',
            target: '26'
          }, {
            id: '62',
            source: '28',
            target: '11'
          }, {
            id: '63',
            source: '28',
            target: '27'
          }, {
            id: '66',
            source: '29',
            target: '11'
          }, {
            id: '64',
            source: '29',
            target: '23'
          }, {
            id: '65',
            source: '29',
            target: '27'
          }, {
            id: '67',
            source: '30',
            target: '23'
          }, {
            id: null,
            source: '31',
            target: '11'
          }, {
            id: null,
            source: '31',
            target: '23'
          }, {
            id: null,
            source: '31',
            target: '27'
          }, {
            id: '68',
            source: '31',
            target: '30'
          }, {
            id: '72',
            source: '32',
            target: '11'
          }, {
            id: '73',
            source: '33',
            target: '11'
          }, {
            id: '74',
            source: '33',
            target: '27'
          }, {
            id: '75',
            source: '34',
            target: '11'
          }, {
            id: '76',
            source: '34',
            target: '29'
          }, {
            id: '77',
            source: '35',
            target: '11'
          }, {
            id: null,
            source: '35',
            target: '29'
          }, {
            id: '78',
            source: '35',
            target: '34'
          }, {
            id: '82',
            source: '36',
            target: '11'
          }, {
            id: '83',
            source: '36',
            target: '29'
          }, {
            id: '80',
            source: '36',
            target: '34'
          }, {
            id: '81',
            source: '36',
            target: '35'
          }, {
            id: '87',
            source: '37',
            target: '11'
          }, {
            id: '88',
            source: '37',
            target: '29'
          }, {
            id: '84',
            source: '37',
            target: '34'
          }, {
            id: '85',
            source: '37',
            target: '35'
          }, {
            id: '86',
            source: '37',
            target: '36'
          }, {
            id: '93',
            source: '38',
            target: '11'
          }, {
            id: '94',
            source: '38',
            target: '29'
          }, {
            id: '89',
            source: '38',
            target: '34'
          }, {
            id: '90',
            source: '38',
            target: '35'
          }, {
            id: '91',
            source: '38',
            target: '36'
          }, {
            id: '92',
            source: '38',
            target: '37'
          }, {
            id: '95',
            source: '39',
            target: '25'
          }, {
            id: '96',
            source: '40',
            target: '25'
          }, {
            id: '97',
            source: '41',
            target: '24'
          }, {
            id: '98',
            source: '41',
            target: '25'
          }, {
            id: '101',
            source: '42',
            target: '24'
          }, {
            id: '100',
            source: '42',
            target: '25'
          }, {
            id: '99',
            source: '42',
            target: '41'
          }, {
            id: '102',
            source: '43',
            target: '11'
          }, {
            id: '103',
            source: '43',
            target: '26'
          }, {
            id: '104',
            source: '43',
            target: '27'
          }, {
            id: null,
            source: '44',
            target: '11'
          }, {
            id: '105',
            source: '44',
            target: '28'
          }, {
            id: '107',
            source: '45',
            target: '28'
          }, {
            id: '108',
            source: '47',
            target: '46'
          }, {
            id: '112',
            source: '48',
            target: '11'
          }, {
            id: '110',
            source: '48',
            target: '25'
          }, {
            id: '111',
            source: '48',
            target: '27'
          }, {
            id: '109',
            source: '48',
            target: '47'
          }, {
            id: null,
            source: '49',
            target: '11'
          }, {
            id: '113',
            source: '49',
            target: '26'
          }, {
            id: null,
            source: '50',
            target: '24'
          }, {
            id: '115',
            source: '50',
            target: '49'
          }, {
            id: '119',
            source: '51',
            target: '11'
          }, {
            id: '118',
            source: '51',
            target: '26'
          }, {
            id: '117',
            source: '51',
            target: '49'
          }, {
            id: null,
            source: '52',
            target: '39'
          }, {
            id: '120',
            source: '52',
            target: '51'
          }, {
            id: '122',
            source: '53',
            target: '51'
          }, {
            id: '125',
            source: '54',
            target: '26'
          }, {
            id: '124',
            source: '54',
            target: '49'
          }, {
            id: '123',
            source: '54',
            target: '51'
          }, {
            id: '131',
            source: '55',
            target: '11'
          }, {
            id: '132',
            source: '55',
            target: '16'
          }, {
            id: '133',
            source: '55',
            target: '25'
          }, {
            id: null,
            source: '55',
            target: '26'
          }, {
            id: '128',
            source: '55',
            target: '39'
          }, {
            id: '134',
            source: '55',
            target: '41'
          }, {
            id: '135',
            source: '55',
            target: '48'
          }, {
            id: '127',
            source: '55',
            target: '49'
          }, {
            id: '126',
            source: '55',
            target: '51'
          }, {
            id: '129',
            source: '55',
            target: '54'
          }, {
            id: '136',
            source: '56',
            target: '49'
          }, {
            id: '137',
            source: '56',
            target: '55'
          }, {
            id: null,
            source: '57',
            target: '41'
          }, {
            id: null,
            source: '57',
            target: '48'
          }, {
            id: '138',
            source: '57',
            target: '55'
          }, {
            id: '145',
            source: '58',
            target: '11'
          }, {
            id: null,
            source: '58',
            target: '27'
          }, {
            id: '142',
            source: '58',
            target: '48'
          }, {
            id: '141',
            source: '58',
            target: '55'
          }, {
            id: '144',
            source: '58',
            target: '57'
          }, {
            id: '148',
            source: '59',
            target: '48'
          }, {
            id: '147',
            source: '59',
            target: '55'
          }, {
            id: null,
            source: '59',
            target: '57'
          }, {
            id: '146',
            source: '59',
            target: '58'
          }, {
            id: '150',
            source: '60',
            target: '48'
          }, {
            id: '151',
            source: '60',
            target: '58'
          }, {
            id: '152',
            source: '60',
            target: '59'
          }, {
            id: '153',
            source: '61',
            target: '48'
          }, {
            id: '158',
            source: '61',
            target: '55'
          }, {
            id: '157',
            source: '61',
            target: '57'
          }, {
            id: '154',
            source: '61',
            target: '58'
          }, {
            id: '156',
            source: '61',
            target: '59'
          }, {
            id: '155',
            source: '61',
            target: '60'
          }, {
            id: '164',
            source: '62',
            target: '41'
          }, {
            id: '162',
            source: '62',
            target: '48'
          }, {
            id: '159',
            source: '62',
            target: '55'
          }, {
            id: null,
            source: '62',
            target: '57'
          }, {
            id: '160',
            source: '62',
            target: '58'
          }, {
            id: '161',
            source: '62',
            target: '59'
          }, {
            id: null,
            source: '62',
            target: '60'
          }, {
            id: '165',
            source: '62',
            target: '61'
          }, {
            id: null,
            source: '63',
            target: '48'
          }, {
            id: '174',
            source: '63',
            target: '55'
          }, {
            id: null,
            source: '63',
            target: '57'
          }, {
            id: null,
            source: '63',
            target: '58'
          }, {
            id: '167',
            source: '63',
            target: '59'
          }, {
            id: null,
            source: '63',
            target: '60'
          }, {
            id: '172',
            source: '63',
            target: '61'
          }, {
            id: '169',
            source: '63',
            target: '62'
          }, {
            id: '184',
            source: '64',
            target: '11'
          }, {
            id: null,
            source: '64',
            target: '48'
          }, {
            id: '175',
            source: '64',
            target: '55'
          }, {
            id: '183',
            source: '64',
            target: '57'
          }, {
            id: '179',
            source: '64',
            target: '58'
          }, {
            id: '182',
            source: '64',
            target: '59'
          }, {
            id: '181',
            source: '64',
            target: '60'
          }, {
            id: '180',
            source: '64',
            target: '61'
          }, {
            id: '176',
            source: '64',
            target: '62'
          }, {
            id: '178',
            source: '64',
            target: '63'
          }, {
            id: '187',
            source: '65',
            target: '48'
          }, {
            id: '194',
            source: '65',
            target: '55'
          }, {
            id: '193',
            source: '65',
            target: '57'
          }, {
            id: null,
            source: '65',
            target: '58'
          }, {
            id: '192',
            source: '65',
            target: '59'
          }, {
            id: null,
            source: '65',
            target: '60'
          }, {
            id: '190',
            source: '65',
            target: '61'
          }, {
            id: '188',
            source: '65',
            target: '62'
          }, {
            id: '185',
            source: '65',
            target: '63'
          }, {
            id: '186',
            source: '65',
            target: '64'
          }, {
            id: '200',
            source: '66',
            target: '48'
          }, {
            id: '196',
            source: '66',
            target: '58'
          }, {
            id: '197',
            source: '66',
            target: '59'
          }, {
            id: '203',
            source: '66',
            target: '60'
          }, {
            id: '202',
            source: '66',
            target: '61'
          }, {
            id: '198',
            source: '66',
            target: '62'
          }, {
            id: '201',
            source: '66',
            target: '63'
          }, {
            id: '195',
            source: '66',
            target: '64'
          }, {
            id: '199',
            source: '66',
            target: '65'
          }, {
            id: '204',
            source: '67',
            target: '57'
          }, {
            id: null,
            source: '68',
            target: '11'
          }, {
            id: null,
            source: '68',
            target: '24'
          }, {
            id: '205',
            source: '68',
            target: '25'
          }, {
            id: '208',
            source: '68',
            target: '27'
          }, {
            id: null,
            source: '68',
            target: '41'
          }, {
            id: '209',
            source: '68',
            target: '48'
          }, {
            id: '213',
            source: '69',
            target: '11'
          }, {
            id: '214',
            source: '69',
            target: '24'
          }, {
            id: '211',
            source: '69',
            target: '25'
          }, {
            id: null,
            source: '69',
            target: '27'
          }, {
            id: '217',
            source: '69',
            target: '41'
          }, {
            id: '216',
            source: '69',
            target: '48'
          }, {
            id: '212',
            source: '69',
            target: '68'
          }, {
            id: '221',
            source: '70',
            target: '11'
          }, {
            id: '222',
            source: '70',
            target: '24'
          }, {
            id: '218',
            source: '70',
            target: '25'
          }, {
            id: '223',
            source: '70',
            target: '27'
          }, {
            id: '224',
            source: '70',
            target: '41'
          }, {
            id: '225',
            source: '70',
            target: '58'
          }, {
            id: '220',
            source: '70',
            target: '68'
          }, {
            id: '219',
            source: '70',
            target: '69'
          }, {
            id: '230',
            source: '71',
            target: '11'
          }, {
            id: '233',
            source: '71',
            target: '25'
          }, {
            id: '226',
            source: '71',
            target: '27'
          }, {
            id: '232',
            source: '71',
            target: '41'
          }, {
            id: null,
            source: '71',
            target: '48'
          }, {
            id: '228',
            source: '71',
            target: '68'
          }, {
            id: '227',
            source: '71',
            target: '69'
          }, {
            id: '229',
            source: '71',
            target: '70'
          }, {
            id: '236',
            source: '72',
            target: '11'
          }, {
            id: '234',
            source: '72',
            target: '26'
          }, {
            id: '235',
            source: '72',
            target: '27'
          }, {
            id: '237',
            source: '73',
            target: '48'
          }, {
            id: '238',
            source: '74',
            target: '48'
          }, {
            id: '239',
            source: '74',
            target: '73'
          }, {
            id: '242',
            source: '75',
            target: '25'
          }, {
            id: '244',
            source: '75',
            target: '41'
          }, {
            id: null,
            source: '75',
            target: '48'
          }, {
            id: '241',
            source: '75',
            target: '68'
          }, {
            id: '240',
            source: '75',
            target: '69'
          }, {
            id: '245',
            source: '75',
            target: '70'
          }, {
            id: '246',
            source: '75',
            target: '71'
          }, {
            id: '252',
            source: '76',
            target: '48'
          }, {
            id: '253',
            source: '76',
            target: '58'
          }, {
            id: '251',
            source: '76',
            target: '62'
          }, {
            id: '250',
            source: '76',
            target: '63'
          }, {
            id: '247',
            source: '76',
            target: '64'
          }, {
            id: '248',
            source: '76',
            target: '65'
          }, {
            id: '249',
            source: '76',
            target: '66'
          }
        ]
      }
    ]
  }
  return option
}

export default initChart
