import echarts from 'echarts'
import 'echarts/map/js/china'
import 'echarts/map/js/province/hubei'
import 'echarts/map/js/province/shandong'
import 'echarts/map/js/province/jilin'
// import hubei from 'echarts/map/json/province/hubei.json'
import allChart from './all'
import './style/chart-3.scss'

function MapChina (element, props) {
  // 建立chart图表的容器， 在HTML中生成div标签 用来插入表格
  const divId = 'echarts_Hubei'
  element.innerHTML = `<div class="${divId}" style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  var myChart = echarts.init(element.children[0])

  const frontDict = props.data[0]
  const option = allChart(frontDict.barStandardSelect)(frontDict)

  // 使用上述配置显示图标
  myChart.setOption(option, true)
}

export default MapChina
