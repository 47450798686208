// import echarts from 'echarts'
import './numflip.css'
import allChart from './all'
import { numMove, autoChange } from './com'

// import {EchartsSchemes} from '../echarts_theme/echarts_color_schemes'
function numflip (element, props) {
  // console.log(props.data)

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const oriData = props.data[0].data
  const frontDict = props.data[0]
  const dataLength = oriData.length
  const colOfWidth = Math.ceil(100 / dataLength)
  const isShowSign = props.data[0].isShowSign

  const targetDiv = element.children[0]

  const data = [
    {
      name: 'one',
      value: oriData[0].value
    }, {
      name: 'two',
      value: '+' + Math.round(Math.random() * 30, 0)
    }
  ]

  const chartSelect = props.data[0].chartSelect
  const createNumber = allChart(chartSelect)
  // 生成dom节点
  const onload = function () {
    targetDiv.appendChild(createNumber(data, colOfWidth, frontDict))
    for (var i = 0; i < dataLength; i++) {
      // 选择数字节点
      if (isShowSign === true) {
        const targetNode = targetDiv.children[0].children[0].children[i].children[1]
        numMove(targetNode)
      } else {
        const targetNode = targetDiv.children[0].children[0].children[i].children[0]
        numMove(targetNode)
      }
    }
  }

  onload()
  if (chartSelect === 'chart2') {
    const autoAsc = autoChange(targetDiv)
    // 更新数值
    setInterval(autoAsc, frontDict.delayTime)
  }

  function Play () {
    // console.log('循环')
    targetDiv.innerHTML = ''
    onload()
  }

  //  props返回的内容增加 mode[edit show]  autoplay[True false],只有当 show, True时, 才执行循环刷新的方法
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000 * 2)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default numflip // 修改为Classname
