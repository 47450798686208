const chart1 = function (div, props) {
  const legend = props.data[0].legend
  const isAddHeader = props.data[0].isAddHeader
  const textAlign = props.data[0].textAlign

  const rowHeight = props.data[0].rowHeight
  const textFontSize = props.data[0].textFontSize

  const backgroundColor = 'rgba(255,255,255,0)'
  // const backgroundColor = props.data[0].backgroundColor
  const textColorList = props.data[0].textColorList
  const titleColor = props.data[0].titleColor
  const lineColor = props.data[0].lineColor
  const titleBackgroundColor = props.data[0].titleBackgroundColor
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const showRowLimit = props.data[0].showRowLimit || props.data[0].show_row_limit
  const isMoveUp = props.data[0].isMoveUp
  const inlineType = props.data[0].inlineType
  const rowBackcolor = props.data[0].rowBackcolor
  const lineWidth = props.data[0].lineWidth
  const colPrefixList = props.data[0].colPrefixList
  const isLimitRowNum = props.data[0].isLimitRowNum
  const rowWidthList = props.data[0].rowWidthList
  const firstRowHeight = props.data[0].firstRowHeight

  const data = props.data[0].data

  const boxHeight = (rowHeight * showRowLimit)
  let nodeIndex = 0
  if (isAddHeader === true) {
    nodeIndex = 1
  }

  let tableTextAlign = ''

  if (textAlign === '左对齐') {
    tableTextAlign = 'flex-start'
  } else if (textAlign === '右对齐') {
    tableTextAlign = 'flex-end'
  } else {
    tableTextAlign = 'center'
  }

  // ----------------------------------
  //   在给定的div下,生成两个 div子节点
  // ----------------------------------
  // 网页加载完成后执行该onload事件
  onload = function () {
    const body = div[0][0].children[0]
    body.setAttribute(
      'style',
      'width: 100%;height: 100%; overflow: hidden; background-color: ' +
        backgroundColor
    )

    const originDiv = document.createElement('div')
    originDiv.setAttribute(
      'style',
      'display: flex; justify-content: start ;align-items: center; height: 100%; flex-direction: column'
    )
    body.appendChild(originDiv)

    // 添加表头
    if (isAddHeader === true) {
      const firstDiv = document.createElement('div')
      firstDiv.setAttribute(
        'style',
        'width: 90%;border-left: 1px solid ' +
          lineColor +
          ';border-right: 1px solid ' +
          lineColor +
          ';border-top: 1px solid ' +
          lineColor
      )
      originDiv.appendChild(firstDiv)
      firstDiv.appendChild(createTableHeader(legend))
    }

    const secondDiv = document.createElement('div')
    secondDiv.setAttribute('class', 'move-demo')
    if (isAddHeader === true) {
      if (isLimitRowNum === true) {
        secondDiv.setAttribute(
          'style',
          'overflow: hidden; width: 90%; border: 1px solid ' +
            lineColor +
            ';' +
            ' height:' +
            boxHeight +
            'px'
        )
      } else {
        secondDiv.setAttribute(
          'style',
          'overflow: hidden; width: 90%; border: 1px solid ' + lineColor + ';'
        )
      }
    } else {
      if (isLimitRowNum === true) {
        secondDiv.setAttribute(
          'style',
          'overflow: hidden; width: 90%; border-bottom: 2px solid ' +
            lineColor +
            ';' +
            'border-top: 2px solid ' +
            lineColor +
            ';' +
            ' height:' +
            boxHeight +
            'px;'
        )
      } else {
        secondDiv.setAttribute(
          'style',
          'overflow: hidden; width: 90%; border-bottom: 2px solid ' +
            lineColor +
            ';' +
            'border-top: 2px solid ' +
            lineColor +
            ';'
        )
      }
    }

    // 将div 添加到 预留的节点 下
    originDiv.appendChild(secondDiv)

    //  在 secondDiv 下创建 两个div, 用于生成 表格的行
    const firstDemo = document.createElement('div')
    firstDemo.setAttribute('class', 'move-demo1')
    const secondDemo = document.createElement('div')
    secondDemo.setAttribute('class', 'move-demo2')

    //  在 firstDemo 下生成数据行
    firstDemo.appendChild(createSecondTable(data))
    firstDemo.childNodes[0].setAttribute(
      'style',
      'display: flex; max-width: 100%'
    )

    secondDiv.appendChild(firstDemo)
    secondDiv.appendChild(secondDemo)

    const demoTwo = body.childNodes[0].childNodes[nodeIndex].children[1]
    const demoOne = body.childNodes[0].childNodes[nodeIndex].children[0]
    const demoParent = body.childNodes[0].childNodes[nodeIndex]

    // 参考文章 https://www.cnblogs.com/trlanfeng/archive/2012/11/04/2753280.html
    // speed 调用向上移动函数的间隔时间, moveSpeed ==> 设置移动速度, 乘积可得移动时间,  两者时间差, 是暂停时间
    const speed = 20
    let startIndex = 0
    let isStop = false
    const waitTime = 1500
    // console.log(demoOne[0]);
    demoTwo.innerHTML = demoOne.innerHTML

    function Marquee () {
      // 两者差值 小于 行高,就可以切换 重新开始循环了
      // console.log(demoTwo.offsetHeight, demoParent.scrollTop)
      if (demoTwo.offsetHeight - demoParent.scrollTop <= 0) {
        demoParent.scrollTop -= demoOne.offsetHeight
      } else {
        if (startIndex === rowHeight) {
          isStop = true
          // console.log(isStop)
          setTimeout(function () {
            demoParent.scrollTop++
            isStop = false
          }, waitTime)
          startIndex = 1
        } else if (startIndex !== rowHeight && isStop === false) {
          // console.log('demoParent.scrollTop', demoParent.scrollTop)
          demoParent.scrollTop++
          startIndex++
        }
      }
    }

    if (isMoveUp === true) {
      let MyMar = setInterval(Marquee, speed)

      demoParent.onmouseover = function () {
        clearInterval(MyMar)
      }
      demoParent.onmouseout = function () {
        MyMar = setInterval(Marquee, speed)
      }
    }
  }

  const createSecondTable = function () {
    const secondTable = document.createElement('table')
    secondTable.setAttribute('style', 'border:0; align: center')
    // secondTable.setAttribute('cellpading', '0');
    secondTable.setAttribute('cellspacing', '0')

    // 添加 tbody节点， 避免浏览器自己添加后，造成bug
    const secondTableTbody = document.createElement('tbody')
    secondTableTbody.setAttribute('class', 'table-move-tbody ')
    secondTable.append(secondTableTbody)

    // for循环json对象,然后将循环到的对象通过createTr()方法生成行，添加到table中
    // TODO 需要确定数据的长度 length ==>  行的数量
    for (let i = 0; !(i >= data.length); i++) {
      const eachData = data[i]
      const colData = []
      for (const key in eachData) {
        colData.push(eachData[key])
      }
      // 需要 根据 奇偶数 设置行的颜色
      // 设置表格主体的行背景色
      const trNode = createTr(colData)
      trNode.setAttribute(
        'style',
        'height: ' +
          rowHeight +
          'px; border-bottom: ' +
          lineWidth +
          'px ' +
          inlineType +
          ' ' +
          lineColor +
          '; background-color: ' +
          rowBackcolor
      )
      secondTableTbody.appendChild(trNode)
    }
    return secondTable
  }

  // ----------------------------------
  //   根据用户传过来的变量生成表格中行的方法
  // ----------------------------------
  const createTr = function (colName) {
    // 定义行元素标签
    const tr = document.createElement('tr')
    tr.setAttribute('class', 'move-body-tr')
    const colLength = colName.length
    // console.log('colLength:', colLength);
    for (let i = 0; i < colLength; i++) {
      let rowWidth = 1
      if (i < rowWidthList.length) {
        rowWidth = rowWidthList[i]
      }
      const tdName = document.createElement('td')
      tdName.setAttribute(
        'style',
        'justify-content: ' +
          tableTextAlign +
          '; padding: 0px 20px; flex-grow: ' +
          rowWidth +
          ';'
      )
      // tdName.setAttribute('style', 'justify-content: ' + tableTextAlign + '; padding: 0px ' + rowHeight * 0.6 + 'px')
      tdName.setAttribute('class', 'move-td')
      tr.append(tdName)
      // 创建 包含文本的 span标签， 用来求文本的长度
      const spanNode = document.createElement('div')
      spanNode.setAttribute('class', 'move-text')
      let textColor = '#fff'
      if (i < textColorList.length) {
        textColor = textColorList[i]
      }
      let finalColText = colName[i]
      if (i < colPrefixList.length) {
        finalColText = colPrefixList[i] + colName[i]
      }
      spanNode.setAttribute(
        'style',
        'font-size: ' + textFontSize + 'px; color: ' + textColor
      )
      tdName.append(spanNode)
      spanNode.innerHTML = finalColText
      // console.log(colName[i]);
      tr.appendChild(tdName)
    }
    return tr
  }

  const createHeadTr = function (colName) {
    // 定义行元素标签
    const tr = document.createElement('tr')
    tr.setAttribute('class', 'move-tr')
    // tr.setAttribute('style',  'color: ' + textColor);
    const colLength = colName.length
    // console.log('colLength:', colLength);
    for (let i = 0; i < colLength; i++) {
      let rowWidth = 1
      if (i < rowWidthList.length) {
        rowWidth = rowWidthList[i]
      }
      const tdName = document.createElement('td')
      tdName.setAttribute(
        'style',
        'justify-content: ' +
          tableTextAlign +
          '; padding: 0px 20px; flex-grow: ' +
          rowWidth +
          ';'
      )
      // tdName.setAttribute('style', 'justify-content: ' + tableTextAlign + '; padding: 0px ' + rowHeight * 0.6 + 'px')
      tdName.setAttribute('class', 'move-td')
      tr.append(tdName)
      // 创建 包含文本的 span标签， 用来求文本的长度
      const spanNode = document.createElement('div')
      spanNode.setAttribute('class', 'move-text')
      spanNode.setAttribute(
        'style',
        'font-size: ' + textFontSize + 'px; color: ' + titleColor
      )
      tdName.append(spanNode)
      spanNode.innerHTML = colName[i]
      // console.log(colName[i]);
      tr.appendChild(tdName)
    }
    return tr
  }

  const createTableHeader = function (colName) {
    const parentNode = document.createElement('table')
    parentNode.setAttribute(
      'style',
      'border:0; align: center; display: flex; background-color: ' +
        titleBackgroundColor
    )
    parentNode.setAttribute('cellspacing', '0')

    // 添加 tbody节点， 避免浏览器自己添加后，造成bug
    const tableTbody = document.createElement('tbody')
    tableTbody.setAttribute('class', 'head-move-tbody')
    parentNode.append(tableTbody)

    // 定义行元素标签
    const rowNode = createHeadTr(colName)
    rowNode.setAttribute('style', 'height: ' + firstRowHeight + 'px')
    tableTbody.appendChild(rowNode)
    return parentNode
  }

  return onload
}

export default chart1
