import echarts from 'echarts'
import 'echarts/map/js/china' // 需要引入echarts 的地图文件

function FlowDiagram (element, props) {
  // 后端传入的数据，转化为 echarts自身定义的同类型， 不用改动echarts的输入数据类型，主要依靠后端代码处理数据格式
  const chinaGeoCoordMap = props.data[0].chinaGeoCoordMap
  const chinaDatas = props.data[0].many_sites
  const oneSite = props.data[0].one_site
  const maxValue = props.data[0].max_value
  const minValue = props.data[0].min_value
  const colorEchartsTheme = props.data[0].color_echarts_theme

  const convertData = function (data, onePoint) {
    var res = []
    for (var i = 0; i < data.length; i++) {
      var dataItem = data[i]
      var toCoord = chinaGeoCoordMap[dataItem[0].name] // [{name: '甘肃', value: 0}]
      var fromCoord = onePoint // 定义了 oneSite 的具体坐标信息,特殊点
      if (fromCoord && toCoord) {
        res.push([{
          coord: fromCoord // 更换起终点坐标，控制流向
        }, {
          coord: toCoord,
          value: dataItem[0].value
        }])
      }
    }
    return res
  }
  const series = [];
  // 将 oneSite chinaDatas 组合成数组 {0：oneSite, 1: chianDatas}
  // oneSite ==>  图上的标识符号
  // 直接利用后端定义的字典的键值来获取对象
  [[oneSite.name, chinaDatas]].forEach(function (item, i) {
    series.push({
      type: 'lines',
      zlevel: 2, // ?
      effect: {
        show: true,
        period: 4, // 箭头指向速度，值越小速度越快
        trailLength: 0.02, // 特效尾迹长度[0,1]值越大，尾迹越长重
        symbol: 'arrow', // 箭头图标
        symbolSize: 5 // 图标大小
      },
      lineStyle: {
        normal: {
          width: 1, // 尾迹线条宽度
          opacity: 1, // 尾迹线条透明度
          curveness: 0.3 // 尾迹线条曲直度
        }
      },
      data: convertData(item[1], oneSite.coord) // 处理的是 chinaDatas 的数据
    }, {
      type: 'effectScatter',
      coordinateSystem: 'geo',
      zlevel: 2,
      rippleEffect: { // 涟漪特效
        period: 4, // 动画时间，值越小速度越快
        brushType: 'stroke', // 波纹绘制方式 stroke, fill
        scale: 4 // 波纹圆环最大限制，值越大波纹越大
      },
      label: {
        normal: {
          show: true,
          position: 'right', // 显示位置
          offset: [5, 0], // 偏移设置
          formatter: function (params) { // 圆环显示文字
            return params.data.name
          },
          fontSize: 13
        },
        emphasis: {
          show: true
        }
      },
      symbol: 'circle',
      symbolSize: function (val) {
        return 5 + (val[2] / maxValue) * 5 // 每个地点的圆环大小
      },
      itemStyle: {
        normal: {
          show: false,
          color: '#f00'
        }
      },
      data: item[1].map(function (dataItem) {
        return {
          name: dataItem[0].name,
          value: chinaGeoCoordMap[dataItem[0].name].concat([dataItem[0].value]) // 定义了 many_sites 的点的效果
        }
      })
    },
    // 单点的样式定义
    {
      type: 'scatter',
      coordinateSystem: 'geo',
      zlevel: 2,
      rippleEffect: {
        period: 4,
        brushType: 'stroke',
        scale: 4
      },
      label: {
        normal: {
          show: true,
          position: 'right',
          // offset:[5, 0],
          color: '#000000',
          formatter: '{b}',
          textStyle: {
            color: '#000000'
          }
        }
        // emphasis: {
        //     show: true,
        //     color: "#f60"
        // }
      },
      symbol: 'pin',
      symbolSize: 50,
      data: [{
        name: item[0],
        value: chinaGeoCoordMap[item[0]].concat([10]) //  concat() 函数将 数值与 name连接起来，定义了 图示的大小
      }]
    }
    )
  })

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const myChart = echarts.init(element.children[0], colorEchartsTheme)

  const option = {

    tooltip: {
      trigger: 'item',
      // backgroundColor: 'rgba(166, 200, 76, 0.82)',
      // borderColor: '#FFFFCC',
      showDelay: 0,
      hideDelay: 0,
      enterable: true,
      transitionDuration: 0,
      extraCssText: 'z-index:100',
      // 显示
      formatter: function (params, ticket, callback) {
        // 根据业务自己拓展要显示的内容
        var res = ''
        var name = params.name
        var value = params.value[params.seriesIndex + 1]
        res = "<span style='color:#fff;'>" + name + '</span><br/>数据：' + value
        return res
      }
    },
    // backgroundColor:"#013954",
    visualMap: { // 图例值控制
      min: minValue,
      max: maxValue, // 左侧颜色控制范围
      calculable: true,
      show: true,
      // color: ['#f44336', '#fc9700', '#ffde00', '#ffde00', '#00eaff'],
      textStyle: {
        color: '#fff'
      }
    },
    geo: {
      map: 'china',
      zoom: 1.2,
      label: {
        emphasis: {
          show: false
        }
      },
      roam: true, // 是否允许缩放
      itemStyle: {
        normal: {
          // color: 'rgba(51, 69, 89, .5)', //地图背景色
          // borderColor: '#516a89', //省市边界线00fcff 516a89
          borderWidth: 1
        }
        // emphasis: {
        //     color: 'rgba(37, 43, 61, .5)' //悬浮背景
        // }
      }
    },
    series: series
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default FlowDiagram
