import echarts from 'echarts'

function PieTypeThird (element, props) {
  // console.log(props)
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const myChart = echarts.init(element.children[0], props.colorTheme)
  // const backgroundColor = props.data[0].backgroundColor;

  // 计算一下百分比
  var value = 23.6

  const option = {
    // backgroundColor: 'black',
    color: ['rgba(54, 255, 252, 0.5)', 'rgba(104, 208, 255, 0.5)', 'rgba(255, 162, 0, 0.5)',
      'rgba(210, 111, 88, 0.5)', 'rgba(174, 238, 237, 0.1)'
    ],
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    series: [{
      name: '访问来源',
      type: 'pie',
      radius: [0, '50%'],
      hoverAnimation: false,
      zlevel: 10,
      itemStyle: {
        normal: {
          borderColor: '#fff',
          borderWidth: 0
        }
      },
      label: {
        show: false
      },
      labelLine: {
        normal: {
          show: false
        }
      },
      data: [{
        value: value,
        name: value,
        zlevel: 20,
        label: {
          rotate: false,
          padding: 10,
          fontSize: 20,
          distanceToLabelLine: 0
        },
        labelLine: {
          show: false,
          length: 44,
          length2: 0,
          lineStyle: {
            width: 4
          }
        },
        itemStyle: {
          borderWidth: 0,
          color: 'rgba(255, 162, 0, 0.5)',
          label: {
            show: false
          }

        }
      },
      {
        value: 100 - value,
        zlevel: 11,
        labelLine: {
          show: false
        },
        itemStyle: {
          color: 'rgba(174, 238, 237, 0.1)',
          borderWidth: 0
        },
        emphasis: {
          labelLine: {
            show: false
          }
        }
      }
      ]
    },
    {
      name: '访问来源',
      type: 'pie',
      zlevel: 0,
      radius: ['35%', '40%'],
      data: [100],
      hoverAnimation: false,
      itemStyle: {
        color: 'rgba(174, 238, 237, 0.5)'
      },
      label: {
        show: false,
        normal: {
          position: 'inner'
        }
      }
    }
    ]
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  function Play () {
    myChart.clear()
    myChart.setOption(option)
    // 监听页面点击事件
    myChart.on('click', function (params) {
      const gorupByValue = params.name
      props.onInteract(gorupByValue)
    })
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default PieTypeThird // 修改为Classname
