import chart1 from './chart-1'
import chart2 from './chart-2'
import chart3 from './chart-3'
import chart4 from './chart-4'
import chart5 from './chart-5'
import chart6 from './chart-6'
import chart7 from './chart-7'
import chart8 from './chart-8'
import chart9 from './chart-9'

import chart10 from './chart-10'
import chart11 from './chart-11'

import chart12 from './chart-12/index.vue'
import chart13 from './chart-13'

import chart20 from './yq_charts/chart-20'
import chart21 from './yq_charts/chart-21'
import chart22 from './yq_charts/chart-22'
import chart23 from './yq_charts/chart-23'
import chart24 from './yq_charts/chart-24'
import chart25 from './yq_charts/chart-25'
import chart26 from './yq_charts/chart-26'
import chart27 from './yq_charts/chart-27/index.vue'
import chart28 from './yq_charts/chart-28'
import chart29 from './yq_charts/chart-29'
import chart30 from './yq_charts/chart-30'
import chart31 from './yq_charts/chart-31'
import chart32 from './yq_charts/chart-32'

import chart40 from './sy-chart/chart-40'
import chart41 from './sy-chart/chart-41'

// 展识医疗
import medicalChart1 from './medical/chart1/index.vue'
import medicalChart2 from './medical/chart2/index.vue'
import medicalChart3 from './medical/chart3/index.vue'
import medicalChart4 from './medical/chart4/index.vue'

const allChart = function (name) {
  const chartMap = {
    // 舆情-正负报道
    chart1,
    // 舆情-时间轴
    chart2,
    // 舆情-国家标签
    chart3,
    // 舆情(2)-筛选器+单折线图 情感走势
    chart4,
    // 舆情(2)-筛选器+多折线图
    chart5,
    // 瓜达尔 多国家媒体文章情感分布
    chart6,
    // 舆情-- 主题分布
    chart7,
    // 舆情-事件画像-长春长生疫苗事件
    chart8,
    // 舆情-事件画像-昆明火车站暴力恐怖案
    chart9,
    // 舆情-事件画像-中东呼吸综合症
    chart10,
    // 舆情-事件画像-山东问题疫苗事件
    chart11,
    // 舆情-产业大地图
    chart12,
    // 舆情-小黄车-主题分布
    chart13,
    // 舆情-事件关注信息
    chart20,
    // 舆情-用户特征分析
    chart21,
    // 舆情-高影响力微博
    chart22,
    // 舆情-首页-时间轴
    chart23,
    // 舆情-利益相关者
    chart24,
    // 舆情-利益相关者关注点及演化模式
    chart25,
    // 舆情-利益相关者
    chart26,
    // 舆情-利益相关者
    chart27,
    // 舆情-话题传播特征分布
    chart28,
    // 舆情-
    chart29,
    // 舆情-山东问题疫苗事件-不同阶段用户行为及话题
    chart30,
    // 舆情-山东问题疫苗事件-利益相关者关注点及演化模式
    chart31,
    // 产业右边-中文
    chart32,
    chart40,
    // 技术规模变化趋势
    chart41,
    // 医疗案例-患者基本信息
    medicalChart1,
    // 医疗案例-历史诊疗记录
    medicalChart2,
    // 医疗案例-本次诊疗情况
    medicalChart3,
    // 医疗案例-治疗日程安排患者检查记录
    medicalChart4
  }
  let target = chartMap[name]
  if (target === undefined) {
    target = chart1
  }

  return target
}

export default allChart
