import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function BarPolar (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const allData = props.data[0].all_data
  const xAxisData = props.data[0].xAxisData
  const legend = props.data[0].legend
  // const title = props.data[0].title
  // const subTitle = props.data[0].sub_title
  const barHeight = props.data[0].barHeight

  const option = {
    // 标题
    // title: {
    //   text: title,
    //   subtext: subTitle
    // },
    legend: {
      show: true,
      data: legend,
      itemWidth: 10,
      itemHeight: 10,
      textStyle: {
        fontSize: 10,
        color: '#fff'
      },
      align: 'right',
      right: 3,
      top: 3,
      orient: 'vertical'
    },
    grid: {
      top: '100px',
      containLabel: true
    },
    angleAxis: {
      show: true,
      type: 'category',
      data: xAxisData,
      axisLabel: {
        textStyle: {
          fontSize: 10,
          color: '#fff'
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgb(195,195,195)',
          width: 1
        }
      },
      splitLine: {
        lineStyle: {
          width: 1,
          color: 'rgba(109,109, 109)'
        }
      }

    },
    tooltip: {
      show: true,
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      formatter: function (params) {
        var id = params.dataIndex
        return xAxisData[id] + '<br>最低：' + allData[id][0] + '<br>最高：' + allData[id][1] + '<br>平均：' + allData[id][2]
      }
    },
    radiusAxis: {
      axisLabel: {
        textStyle: {
          fontSize: 10,
          color: '#fff'
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgb(195,195,195)',
          width: 1
        }
      },
      splitLine: {
        lineStyle: {
          width: 1,
          color: 'rgba(109,109, 109)'
        }
      }
    },
    polar: {
    },
    series: [{
      type: 'bar',
      itemStyle: {
        normal: {
          color: 'transparent'
        }
      },
      data: allData.map(function (d) {
        return d[0]
      }),
      coordinateSystem: 'polar',
      stack: '最大最小值',
      silent: true,
      animationDuration: 2000
    }, {
      type: 'bar',
      data: allData.map(function (d) {
        return d[1] - d[0]
      }),
      coordinateSystem: 'polar',
      name: legend[0],
      stack: '最大最小值',
      animationDuration: 2000
    }, {
      type: 'bar',
      itemStyle: {
        normal: {
          color: 'transparent'
        }
      },
      data: allData.map(function (d) {
        return d[2] - barHeight
      }),
      coordinateSystem: 'polar',
      stack: '均值',
      silent: true,
      z: 10,
      animationDuration: 2000
    }, {
      type: 'bar',
      data: allData.map(function (d) {
        return barHeight * 2
      }),
      coordinateSystem: 'polar',
      name: legend[1],
      stack: '均值',
      barGap: '-100%',
      z: 10,
      animationDuration: 2000
    }]
    // legend: {
    //     show: true,
    //     data: ['A', 'B', 'C']
    // }
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  // 添加页面点击事件的监听
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  var startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default BarPolar // 修改为Classname
