import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function DoubleColorBar (element, props) {
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const myChart = echarts.init(element.children[0], props.colorTheme)
  const originData = props.data[0].content_data[0]
  const xAxisData = props.data[0].xAxisData
  const backgroundBarList = props.data[0].background_bar_list
  const YlabelToAxis = props.data[0].YlabelToAxis

  const legend = props.data[0].legend
  const barRadius = props.data[0].barRadius
  const barWidth = props.data[0].barWidth
  const barBackgroundColor = props.data[0].barBackgroundColor
  const axisFontSize = props.data[0].axisFontSize
  const backgroundColor = 'rgba(0,0,0,0)'

  var YData = originData

  const convertData = function (data) {
    const newData = []
    for (let i = 0; i < data.length; i++) {
      if (i % 2 === 0) {
        newData.push(
          {
            name: xAxisData[i],
            value: data[i],
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                //  x, y, x2, y2
                0, 0, 1, 0,
                [{
                  offset: 0,
                  color: '#80492d'
                },
                {
                  offset: 1,
                  color: '#ff8e51'
                }
                ]
              )
            }
          }
        )
      } else {
        newData.push(
          {
            name: xAxisData[i],
            value: data[i],
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                //  x, y, x2, y2
                0, 0, 1, 0,
                [{
                  offset: 0,
                  color: '#167d7c'
                },
                {
                  offset: 1,
                  color: '#2bf5ef'
                }
                ]
              )
            }
          }
        )
      }
    }
    console.log(newData)
    return newData
  }

  const option = {
    // toolbox: {
    //   feature: {
    //     saveAsImage: {
    //       show: true,
    //       type: 'png',
    //       // 保存的图片的像素比例， 默认为1， 容器大小， 越大越清晰
    //       pixelRatio: 2
    //     }
    //   }
    // },
    backgroundColor: backgroundColor,
    legend: {
      show: false,
      top: '3%',
      right: '7%',
      itemWidth: 10,
      itemHeight: 10,
      // 如何控制图标的颜色??
      data: legend
    },
    tooltip: {
      trigger: 'item',
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      textStyle: {
        fontSize: 16
      },
      formatter: function (params) {
        const name = params.seriesName
        let res = ''
        if (name === '条') {
          res = params.name + ':' + '<bar>' + params.value
          return res
        }
      }
      // formatter: '{b}数量: {c}'
    },
    grid: [{
      left: '8%',
      top: '5%',
      right: '8%',
      // width: '80%',
      height: '90%',
      containLabel: true
    },
    {
      left: '8%',
      top: '5%',
      // right: '8%',
      width: '80%',
      height: '90%',
      containLabel: true
    }],
    xAxis: [{
      gridIndex: 0,
      max: backgroundBarList[0],
      show: false
    },
    {
      gridIndex: 0,
      show: false
    },
    {
      gridIndex: 1,
      max: backgroundBarList[0],
      show: false
    },
    {
      gridIndex: 1,
      show: false
    }
    ],
    yAxis: [{
      axisTick: 'none',
      axisLine: 'none',
      splitLine: 'none',
      gridIndex: 0,
      // bar 距离 y轴的距离
      offset: YlabelToAxis,
      axisLabel: {
        textStyle: {
          color: '#ffffff',
          fontSize: axisFontSize,
          align: 'left'
        }
      },
      data: xAxisData
    },
    {
      axisTick: 'none',
      axisLine: 'none',
      splitLine: 'none',
      gridIndex: 0,
      // 消除底部的超出部分
      offset: '11',
      data: []
    }
    ],
    series: [
      {
        name: legend[0],
        type: 'bar',
        // gridIndex:0,
        xAxisIndex: 0,
        yAxisIndex: 0,
        data: convertData(YData),
        barWidth: barWidth,
        label: {
          show: true,
          position: 'right',
          //  label 距离顶部的位置
          distance: 20,
          //   data ==> 该范围内的 data
          formatter: '{c}',
          // formatter: '{b}数量: {c}',
          textStyle: {
            color: '#fff',
            fontSize: axisFontSize
          }
        },
        itemStyle: {
          normal: {
            barBorderRadius: barRadius
          }
        },
        z: 2
      },
      {
        name: '背景色',
        type: 'bar',
        barWidth: barWidth,
        hoverAnimation: false,
        data: backgroundBarList,
        xAxisIndex: 1,
        yAxisIndex: 1,
        symbolSize: 35,
        label: {
          show: false,
          position: 'right',
          //  label 距离顶部的位置
          distance: 20,
          //   data ==> 该范围内的 data
          formatter: function (data) {
            return YData[data.dataIndex]
          },
          textStyle: {
            color: '#ffffff',
            fontSize: '16'
          }
        },
        itemStyle: {
          normal: {
            color: barBackgroundColor,
            barBorderRadius: barRadius
          }
        },
        z: 1
      }
    ]
  }

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default DoubleColorBar // 修改为Classname
