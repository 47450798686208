<template>
  <div class="medical_chart2">
    <div class="left_tab">
      <div
        :class="['tab_item', { 'active':curActiveTabName === item }]"
        v-for="item in tabList"
        :key="item"
        @click="curActiveTabName = item">
        {{ item }}
      </div>
    </div>
    <div class="right_tabpane">
      <div class="panel_top">
          <div class="swiper_wrapper">
            <swiper
              v-if="curData.images.length > 0"
              class="swiper_box"
              :options="swiperOptions">
              <swiper-slide
                class="swiper_slide_box"
                v-for="(item, index) in curData.images"
                :key="index">
                <img :src="item" alt="">
              </swiper-slide>
              <div class="my-swiper-pagination" slot="pagination"></div>
            </swiper>
            <p v-else>暂无图片！</p>
          </div>
        <div class="info_box">
          <div class="info_item">
            <p>就诊科室</p>
            <p>{{ curData.type }}</p>
          </div>
          <div class="info_item">
            <p>主治医师</p>
            <p>{{ curData.doctor }}</p>
          </div>
          <div class="info_item">
            <p>诊疗时间</p>
            <p>{{ curData.date_str }}</p>
          </div>
        </div>
      </div>
      <div class="panel_bottom">
        <div class="info_box">
          <div class="info_item">
            <h1>病史摘要</h1>
            <p>{{ curData.description }}</p>
          </div>
          <div class="info_item">
            <h1>诊&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;断</h1>
            <p>{{ curData.diagnosis }}</p>
          </div>
          <div class="info_item">
            <h1>建&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;议</h1>
            <p>{{ curData.suggest }}</p>
          </div>
        </div>
        <div class="footer_box">
          <span>详细信息</span><div class="min_triangle"></div><div class="min_triangle"></div><div class="max_triangle"></div>
        </div>
      </div>
    </div>
    <div class="right_top_box"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import data from '../data-source/medical.sheet2.json'
export default {
  data () {
    return {
      // 当前激活的项
      curActiveTabName: '拔智齿'
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    // 数据源
    dataSource () {
      console.log(data)
      return data
    },
    // 选项卡list
    tabList () {
      const list = this.dataSource.map(item => {
        return item.name
      })
      return list
    },
    // 当前所选历史
    curData () {
      const curData = this.dataSource.find(item => {
        return item.name === this.curActiveTabName
      })
      console.log(curData)

      return curData
    },
    // Swiper 配置项
    swiperOptions () {
      return {
        pagination: {
          el: '.my-swiper-pagination',
          bulletClass: 'my-bullet',
          bulletActiveClass: 'my-bullet-active',
          clickable: true
        }
        // 左右切换按钮
        // navigation: {
        //   nextEl: '.button-next',
        //   prevEl: '.button-prev'
        // }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .medical_chart2 {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 546px;
    .left_tab {
      width: 140px;
      .tab_item {
        display: flex;
        align-items: center;
        // justify-content: center;
        height: 30px;
        background-color: rgba(219, 246, 255, 0.1);
        padding: 0 10px;
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
        cursor: pointer;
        &::before {
          content: "";
          display: block;
          width: 4px;
          height: 4px;
          background: #009EE4;
          border-radius: 50%;
          margin-right: 10px;
        }
        &.active {
          font-weight: bold;
          color: #ffffff;;
          background-color: rgba(0, 158, 228, 1);
          &::before {
            background-color: #ffffff;
          }
        }
        &+.tab_item {
          margin-top: 4px;
        }
      }
    }
    .right_tabpane {
      width: 402px;
      height: 440px;
      .panel_top {
        display: flex;
        width: 100%;
        height: 100px;
        margin-bottom: 1px;
        background-color: rgba(219, 246, 255, 0.1);
        .swiper_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 130px;
          height: 100%;
          .swiper_box {
            width: 130px;
            height: 100%;
            // background-color: grey;
            .swiper_slide_box {
              width: 100%;
              & > img {
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
            }
          }
          & > p {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.8);
          }
        }
        .info_box {
          display: flex;
          width: 272px;
          height: 100%;
          padding-left: 16px;
          .info_item {
            flex: 1;
            padding-top: 18px;
            & > p {
              &:first-child {
                font-size: 12px;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.6);
                margin-bottom: 19px;
              }
              &:last-child {
                font-size: 12px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
              }
            }
          }
        }
      }
      .panel_bottom {
        width: 100%;
        height: 339px;
        padding: 16px 20px 0 20px;
        background-color: rgba(219, 246, 255, 0.1);
        .info_box {
          width: 100%;
          height: calc(100% - 40px);
          .info_item {
            & > h1 {
              font-size: 12px;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.6);
              margin-bottom: 12px;
            }
            & > p {
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
              color: #FFFFFF;
            }
            &+.info_item {
              margin-top: 22px;
            }
          }
        }
        .footer_box {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          height: 40px;
          color: rgba(125, 224, 255, 1);
          border-top: 1px solid rgba(255, 255, 255, 0.2);
          & > span {
            margin-right: 6px;
          }
          .min_triangle {
            width: 0;
            height: 0;
            border-top: 2px solid transparent;
            border-left: 4px solid rgba(125, 224, 255, 1);
            border-bottom: 2px solid transparent;
            margin-right: 2px;
          }
          .max_triangle {
            width: 0;
            height: 0;
            border-top: 4px solid transparent;
            border-left: 6px solid rgba(125, 224, 255, 1);
            border-bottom: 4px solid transparent;
            margin-right: 2px;
          }
        }
      }
    }
    .right_top_box {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 6px;
      height: 4px;
      background: RGBA(137, 144, 154, 1);
      opacity: 1;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 1px;
        height: 80px;
        background: RGBA(137, 144, 154, 1);
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 80px;
        height: 1px;
        background: RGBA(137, 144, 154, 1);
      }
    }
  }
</style>

<style lang="scss">
.medical_chart2 {
  .right_tabpane {
    .my-swiper-pagination {
      position: absolute;
      bottom: 6px !important;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-right: 2px;
      z-index: 10;
      // 指示器
      .my-bullet {
        cursor: pointer;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin: 0 4px;
        display: inline-block;
        background-color: #ffffff;
        opacity: 0.5;
      }
      .my-bullet-active {
        opacity: 1;
      }
    }
  }
}
</style>
