const lineData = {
  Topic2: [0, 0, 0, 2, 3, 14, 685, 319, 175, 129],
  Topic1: [3, 1, 6, 3, 136, 210, 161, 191, 269],
  Topic3: [69, 11, 13, 30, 24, 328, 876, 457, 356, 492],
  Topic6: [72, 11, 5, 15, 12, 248, 122, 43, 36, 754],
  Topic4: [47, 23, 14, 35, 51, 489, 813, 287, 172, 311],
  Topic5: [26, 2, 5, 28, 32, 290, 989, 318, 192, 218]
}

export { lineData }
