<template>
  <div class="timetext">
    <div class="timetext-now" style="color: rgba(255, 255, 255, 0.81)">
    <span>{{this.currentTime.hour}}</span>
    <span style="margin:0 10px">:</span>
    <span>{{this.currentTime.minutes}}</span>
    <span style="margin:0 10px">:</span>
    <span>{{this.currentTime.seconds}}</span>
    </div>
    <div :style="newStyle" style="line-height: 17px">{{this.frontDict.targetTime}}</div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  mounted () {
    const zeroFormat = function (obj) {
      if (obj < 10) {
        return '0' + obj
      } else {
        return obj
      }
    }
    const _this = this
    this.timer = setInterval(function () {
      const now = new Date()
      // _this.currentTime = zeroFormat(now.getHours()) +
      //    ' : ' + zeroFormat(now.getMinutes()) + ' : ' + zeroFormat(now.getSeconds())
      _this.currentTime = {
        hour: zeroFormat(now.getHours()),
        minutes: zeroFormat(now.getMinutes()),
        seconds: zeroFormat(now.getSeconds())
      }
    }, 1000)
  },
  computed: {
    newStyle () {
      const size = this.frontDict.textFontSize
      console.log(this.frontDict.textFontSize)
      return {
        fontSize: size + 'px',
        color: this.frontDict.textColor,
        fontFamily: this.frontDict.digtalfontFamily,
        letterSpacing: this.frontDict.textGap + 'px'
      }
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>
<style lang="scss" scoped>
.timetext {
  display: flex;
  flex-direction: column;
}
.timetext-now{
  // color:'rgba(255, 255, 255, 0.81)';
  font-size: 32px;
  margin-bottom: 7px;
  line-height: 38px;
  letter-spacing: 5;
  font-family: 'SonyEricssonLogo'
}
</style>
