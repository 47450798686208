import echarts from 'echarts'
const lineChart = function (params) {
  const category = params.category
  const targetData = params.baseData[category]

  const originDates = targetData.datetime

  function dealAxis (data) {
    const res = []
    data.forEach(element => {
      res.push(echarts.format.formatTime('MM月', element))
    })
    return res
  }

  const option = {
    tooltip: {
      alwaysShowContent: true,
      padding: [12, 20, 12, 20],
      textStyle: {
        fontSize: 14,
        lineHeight: 28,
        height: 28
      },
      formatter: function (data) {
        const date = echarts.format.formatTime('yyyy年MM月dd日', originDates[data[0].dataIndex])
        const value = data[0].value
        let color = 'rgba(67, 167, 253, 1)'
        // 根据值的正负,改变数字的颜色
        if (parseFloat(value) < 0) {
          color = 'rgba(138, 213, 0, 1)'
        }
        const res = `<div style="margin-bottom: 18px">${date}</div>
        <div style="width:140px; word-break: break-all;word-wrap: wrap;white-space: normal">
        情感指数:  <span style="color: ${color}; margin-left: 17px">${value}</span></div>`
        return res
      },
      backgroundColor: 'rgba(56, 71, 96, 0.7)',
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.3)',
          width: 1,
          type: 'solid'
        }
      }
    },
    grid: {
      top: 20,
      left: 20,
      right: 20,
      // bottom: 20,
      containLabel: true,
      backgroundColor: 'rgba(94, 105, 123, 0.18)'
    },
    xAxis: {
      type: 'category',
      margin: 10,
      splitNumber: 5,
      boundaryGap: true,
      axisTick: 'none',
      axisLabel: {
        textStyle: {
          color: 'rgba(255, 255, 255, 0.8)',
          fontSize: 14
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.8)'
        }
      },
      splitLine: {
        show: false
      },
      data: dealAxis(targetData.datetime)
    },
    yAxis: {
      splitLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.1)'
        }
      },
      axisLabel: {
        textStyle: {
          color: 'rgba(255, 255, 255, 0.8)',
          fontSize: 14
        }
      }
    },
    series: [
      {
        type: 'line',
        showSymbol: false,
        clip: true,
        lineStyle: {
          color: 'rgba(67, 167, 253, 1)',
          width: 1
        },
        markPoint: {
          label: {}
          // data: [{name: '最大值', type: 'max'}, {name: '最小值', type: 'min'}]
        },
        data: targetData.data
        // data: generateData()
      }
    ]
  }

  return option
}

export { lineChart }
