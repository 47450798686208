import { setXaxisDefault, setYaxisDefault } from '../../echartSetting/axis'
const chart1 = function (params) {
  const originData = params.originData
  const legend = params.legend
  // const xAxisData = params.xAxisData

  const lineWidth = params.lineWidth

  const series = []
  for (let i = 0; i < originData.length; i++) {
    series.push(
      {
        name: legend[i],
        type: 'line',
        // 线上的圆点
        symbolSize: 0,
        smooth: params.lineSmoothNumber,
        // stack: '总量',
        lineStyle: {
          color: params.textColorList[i],
          width: lineWidth
        },
        itemStyle: {
          // 设置数据点的颜色 与 标注原点的尺寸
          color: params.textColorList[i]
          // borderWidth: 10
        },
        data: originData[i]
      }
    )
  }

  const option = {
    tooltip: {
      // alwaysShowContent: true,
      padding: [12, 50, 12, 20],
      textStyle: {
        fontSize: 14,
        lineHeight: 28,
        height: 28
      },
      backgroundColor: 'rgba(56, 71, 96, 0.7)',
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.3)',
          width: 1,
          type: 'solid'
        }
      }
    },
    legend: {
      show: false,
      icon: 'rect',
      itemHeight: 10,
      itemWidth: 20,
      align: 'right',
      right: '7%',
      top: '5%',
      textStyle: {
        color: '#ffffff',
        fontSize: 12,
        padding: [12, 0, 12, 10]
      },
      data: legend
    },
    grid: {
      top: params.gridPosition[0],
      left: params.gridPosition[3],
      right: params.gridPosition[1],
      bottom: params.gridPosition[2],
      containLabel: true
    },
    xAxis: setXaxisDefault(params),
    yAxis: setYaxisDefault(params),
    series: series
  }

  return option
}

export default chart1
