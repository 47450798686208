import { isOfType } from '../utils/index'

/**
 * 获取颜色
 *  + 传入的是rgba格式的颜色
 *  + 如果传入的是字符串则返回此字符串颜色，否则返回默认值
 * @param {string} color
 * @returns color
 */
function getColor (color) {
  return isOfType.string(color) && color !== '' ? color : 'rgba(0,0,0,1)'
}

/**
 * 获取布尔值类型
 *  + 传入的是false或true
 *  +如果传入的是布尔值则返回值，否则转化为布尔值
 * @param {boolean} bol
 * @returns bol
 */
function getBol (bol) {
  return isOfType.boolean(bol) ? bol : Boolean(bol)
}

/**
 * 传入字符串数字获得数字值
 *  + 有整数浮点数
 * @param {number} value
 * @returns number
 */
function getNumber (value) {
  return Number(value)
}

/**
 * 获取字符串值
 *  + 带px的数，带%的数
 * @param {string} str
 * @returns str
 */
function getStr (str) {
  return str || ''
}

/**
 * 拿到逗号分隔的字符串，进行分隔成数组
 *  + 例如"300,120"分割成[300, 120]
 * @param {string} str
 * @returns arr
 */
function getSplitStrArr (str) {
  return isOfType.string(str) && str !== '' ? str.split(/;|；|:|,|，/) : []
}

/**
 * ,|;分割的字符串，转换成数组
 *  再把数组中的值，转为数字
 * @param {string} str
 * @returns array
 */
function getSplitStrNumArr (str) {
  // 如果使字符串的话 则进行分割为数组
  let array = isOfType.string(str) && str !== '' ? str.split(/;|；|:|,|，/) : []
  // 如果分割的是数组的话则把元素转为数字
  if (isOfType.array(array) && array.length > 0) {
    array = array.map(item => {
      return Number(item)
    })
  } else {
    array = [0, 0, 0, 0]
  }
  return array
}

/**
 * ,|;分割的字符串，转换成数组
 *  + 先判断是rgb颜色还是#颜色
 * @param {string} str
 * @returns array
 */
function getSplitStrColorArr (str) {
  // 如果使字符串的话 则进行分割为数组
  let array = []
  if (isOfType.string(str) && str !== '') {
    const isRgb = str.includes('rgb')
    if (isRgb) {
      array = str.split(/;|:|；/)
    } else {
      array = str.split(/,|，/)
    }
  }
  return array
}

export default {
  getColor,
  getBol,
  getNumber,
  getStr,
  getSplitStrArr,
  getSplitStrNumArr,
  getSplitStrColorArr
}
