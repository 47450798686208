// 话题类型 利益相关者关注点及演化模式
const eventTypes = {
  事件进展: {
    title: ['问题疫苗流入的24省市名单公布', '澎湃新闻报道未冷藏疫苗流入多省，或致人命', '多家药品批发企业涉山东“疫苗”案被通报', '疫苗流通监管制度存在漏洞', '问题疫苗销售流程', '山东公布问题疫苗事件多条上线和下线线索以及买卖疫苗的人员名单', '多地涉案嫌疑人已被警方控制', '涉案问题疫苗清单公布'],
    action: {
      repost: 1,
      comment: 1,
      attitude: 0
    }
  },
  群众意见: {
    title: ['网友要求政府部门尽快查清涉事医院和受害者', '网友怀疑自己注射过问题疫苗', '网友强烈要求有关部门必须公布五亿元无效疫苗流向', '网友要求政府回应群众关切问题', '网友呼吁大家关注问题疫苗事件'],
    action: {
      repost: 1,
      comment: 1,
      attitude: 1
    }
  },
  政府回应: {
    title: ['世卫组织称问题疫苗的几乎不会产生毒性反应', '世卫组织批驳关于疫苗的十大传言', '食药监总局官员：中国疫苗监管体系达世界先进水平', '食药监总局：问题疫苗主要流向农村偏远地区', '河源4岁幼儿打疫苗后身亡 广东卫计委：所打疫苗与山东问题疫苗无关'],
    action: {
      repost: 0,
      comment: 1,
      attitude: 0
    }
  },
  知识普及: {
    title: ['注射未冷藏疫苗不能产生病毒抗体', '家长可根据疫苗接种记录判断自己的孩子是否接种了问题疫苗', '一类疫苗与二类疫苗知识普及'],
    action: {
      repost: 0,
      comment: 1,
      attitude: 0
    }
  },
  事后措施: {
    title: ['对疫苗冷链实施全程实时监控引起重视', '多地拟将全面实施在预防接种异常反应补偿中全程引入商业保险补偿机制', '沃森生物子公司被撤销经营执照'],
    action: {
      repost: 1,
      comment: 1,
      attitude: 0
    }
  }
}

export {
  eventTypes
}
