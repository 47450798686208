import echarts from 'echarts'
import { commonFunc } from '../chartCommonPart'
import allChart from './all'
function FirstLineDouble (element, props) {
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const myChart = echarts.init(element.children[0])

  const frontDict = props.data[0]
  // 如果传入了具体传来的图表类型 则使用具体传入的，否则使用chart1
  if (!frontDict.barStandardSelect) {
    frontDict.barStandardSelect = 'chart1'
  }
  const barStandardSelect = frontDict.barStandardSelect
  const option = allChart(barStandardSelect)(frontDict)
  // 使用上述配置显示图标 ， 保留不变
  const startPlay = commonFunc(myChart, option, props)

  return startPlay
};

export default FirstLineDouble // 修改为Classname
