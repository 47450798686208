<template>
  <div class="chart">
    <vue-scroll :ops="{
      scrollPanel: {
          scrollingY: false
        },
        rail: {
          background: 'rgba(255, 255, 255, 0.2)',
          size: '4px',
          keepShow: false
        },
        bar: {
          keepShow: true,
          background: '#3E84A6',
          specifyBorderRadius: '6px',
          size: '6px'
        }
    }">
      <div class="chart_wrapper">
        <div class="chart_item" v-for="item in data" :key="item.id">
          <img class="chart_item_img" :src="item.img" />

          <div class="chart_item_right">
            <p class="chart_item_title">{{item.title}}</p>
            <p class="chart_item_content">{{item.content}}</p>
          </div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import img1 from './img/1.png'
import img2 from './img/2.png'
import img3 from './img/3.png'
import img4 from './img/4.png'
import img5 from './img/5.png'
import img6 from './img/6.png'

export default {
  data () {
    return {
      data: [
        {
          id: '6',
          img: img6,
          title: '郑州暴雨事件',
          content: '2021年7月，河南省郑州市发生特大暴雨，多地受到暴雨影响，相关部门作出积极防汛措施保障人民生命安全和财产安全。 '
        },
        {
          id: '1',
          img: img4,
          title: '长春长生疫苗事件',
          content: '2017年11月，国家食品药品监督管理总局通报在抽检中发现长春长生百白破疫不达标。 '
        },
        {
          id: '2',
          img: img5,
          title: '小黄车退押金事件',
          content: '小黄车陷债务危机，用户退押金套路多，困难重重，造成大量不满。'
        },
        {
          id: '3',
          img: img1,
          title: '中东呼吸综合征事件',
          content: '2015年5月20日韩国确诊首例患者，2015年12月24日宣布疫情结束，共有186人感染病毒'
        },
        {
          id: '4',
          img: img2,
          title: '山东问题疫苗事件',
          content: '2016年爆发的一起公共安全事件，涉及中华人民共和国的十八个省市的无效或过期的疫苗'
        },
        {
          id: '5',
          img: img3,
          title: '昆明火车站暴力恐怖案事件',
          content: '2014年3月1日21时20分左右，在云南省昆明市昆明火车站发生严重暴力恐怖事件'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  width: 900px;
  height: 160px;
  overflow: auto;
  &_wrapper {
    display: flex;
    padding: 10px 0;
  }
  &_item {
    width: 400px;
    height: 140px;
    margin-right: 20px;
    border-radius: 6px;
    background-color: rgba($color: #000000, $alpha: 0.25);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    &_img {
      width: 180px;
      height: 140px;
    }
    &_right {
      flex: 1;
      margin-left: 12px;
    }
    &_title {
      color: #ffffff;
      line-height: 20px;
      margin-top: 20px
    }
    &_content {
      color: rgba($color: #ffffff, $alpha: 0.6);
      line-height: 22px;
      margin-top: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
