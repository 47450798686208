import echarts from 'echarts'
// eslint-disable-next-line import/no-duplicates
import { hideDelayTime, trigger } from '../chartCommonPart'

function WaterMark (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const myChart = echarts.init(div[0][0].children[0], props.data[0].color_echarts_theme)
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const allData = props.data[0].all_data

  const title = props.data[0].title

  const option = {
    tooltip: {
      triggerOn: trigger,
      hideDelay: hideDelayTime
    },
    title: [{
      text: title[0],
      x: '50%',
      textAlign: 'center',
      textStyle: {
        color: '#fff',
        fontSize: 14
      }
    }, {
      text: title[1],
      // subtext: '总计 ' + Object.keys(themeJson).reduce(function (all, key) {
      //     return all + themeJson[key];
      // }, 0),
      x: '50%',
      y: '50%',
      textAlign: 'center',
      textStyle: {
        color: '#fff',
        fontSize: 14
      }
    }],
    grid: [{
      top: 40,
      width: '50%',
      bottom: '55%',
      left: 10,
      containLabel: true
    }, {
      top: '55%',
      width: '50%',
      bottom: 10,
      left: 10,
      containLabel: true
    }],
    xAxis: [{
      type: 'value',
      max: allData.max,
      splitLine: {
        show: false
      },
      axisLabel: {
        textStyle: {
          fontSize: 10,
          color: '#fff'
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgb(195,195,195)',
          width: 1
        }
      }
    }, {
      type: 'value',
      max: allData.max,
      gridIndex: 1,
      splitLine: {
        show: false
      },
      axisLabel: {
        textStyle: {
          fontSize: 10,
          color: '#fff'
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgb(195,195,195)',
          width: 1
        }
      }
    }],
    yAxis: [{
      type: 'category',
      data: Object.keys(allData.one_data),
      splitLine: {
        show: false
      },
      axisLabel: {
        interval: 0,
        rotate: 30,
        textStyle: {
          fontSize: 14,
          color: '#fff'
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgb(195,195,195)',
          width: 1
        }
      }
    }, {
      gridIndex: 1,
      type: 'category',
      data: Object.keys(allData.two_data),
      splitLine: {
        show: false
      },
      axisLabel: {
        interval: 0,
        rotate: 30,
        textStyle: {
          fontSize: 14,
          color: '#fff'
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgb(195,195,195)',
          width: 1
        }
      }
    }],
    series: [{
      type: 'bar',
      stack: 'chart',
      z: 3,
      label: {
        normal: {
          position: 'right',
          show: true
        }
      },
      data: Object.keys(allData.one_data).map(function (key) {
        return allData.one_data[key]
      }),
      animationDuration: 2000
    }, {
      type: 'bar',
      stack: 'chart',
      silent: true,
      itemStyle: {
        normal: {
          color: '#eee'
        }
      },
      data: Object.keys(allData.one_data).map(function (key) {
        return allData.max - allData.one_data[key]
      }),
      animationDuration: 2000
    }, {
      type: 'bar',
      stack: 'component',
      xAxisIndex: 1,
      yAxisIndex: 1,
      z: 3,
      label: {
        normal: {
          position: 'right',
          show: true
        }
      },
      data: Object.keys(allData.two_data).map(function (key) {
        return allData.two_data[key]
      }),
      animationDuration: 2000
    }, {
      type: 'bar',
      stack: 'component',
      silent: true,
      xAxisIndex: 1,
      yAxisIndex: 1,
      itemStyle: {
        normal: {
          color: '#eee'
        }
      },
      data: Object.keys(allData.two_data).map(function (key) {
        return allData.max - allData.two_data[key]
      }),
      animationDuration: 2000
    }, {
      type: 'pie',
      radius: [0, '30%'],
      center: ['75%', '25%'],
      data: Object.keys(allData.one_data).map(function (key) {
        return {
          name: key,
          value: allData.one_data[key]
        }
      }),
      animationDuration: 2000
    }, {
      type: 'pie',
      radius: [0, '30%'],
      center: ['75%', '75%'],
      data: Object.keys(allData.two_data).map(function (key) {
        return {
          name: key,
          value: allData.two_data[key]
        }
      }),
      animationDuration: 2000
    }]
  }

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })
  //  props返回的内容增加 mode[edit show]  autoplay[True false],只有当 show, True时, 才执行循环刷新的方法
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default WaterMark // 修改为Classname
