<template>
  <div class="chart_31">
    <section class="event">
      <div class="select_box">
        <el-select
          class="my-lib-el-select transparency"
          size="small"
          v-model="eventTypesValue"
          placeholder="请选择">
          <el-option
            v-for="item in eventTypesOption"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </div>
      <div class="table_box">
        <div class="th">
          <span>{{ eventTypesValue }}的用户行为：</span>
          <div class="tag_list">
            <div class="tag" :class="{'active':curEvent.action.repost}">点赞</div>
            <div class="tag" :class="{'active':curEvent.action.comment}">评论</div>
            <div class="tag" :class="{'active':curEvent.action.attitude}">转发</div>
          </div>
        </div>
        <div class="tb">
          <vue-scroll>
            <div class="tr" v-for="(item, index) in curEvent.title" :key="index">
              <p class="" :title="item">{{ item }}</p>
            </div>
          </vue-scroll>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { eventTypes } from './data-source/data'
export default {
  data () {
    return {
      // 话题类型选项
      eventTypesValue: '事件进展'
    }
  },
  computed: {
    // 话题类型
    eventTypes () {
      return eventTypes
    },
    // 事件类型
    eventTypesOption () {
      const kes = Object.keys(eventTypes)
      return kes
    },
    // 当前时间周期
    curEvent () {
      const list = this.eventTypes[this.eventTypesValue]
      return list
    }
  }
}
</script>
<style lang="scss" scoped>
  .chart_31 {
    position: relative;
    width: 366px;
    .event {
      width: 100%;
      .select_box {
        margin-bottom: 20px;
      }
      .table_box {
        width: 100%;
        border: 1px solid rgba(255, 255, 255, 0.3);
        .th {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 40px;
          background-color: rgba(22, 215, 255, 0.05);
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          padding: 0 30px 0 10px;
          & > span {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.8);
          }
          .tag_list {
            display: flex;
            .tag {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: bold;
              line-height: 20px;
              color: rgba(255, 255, 255, 0.6);
              &+.tag {
                margin-left: 26px;
              }
              &.active {
                &:nth-child(1) {
                  color: #67E0E3;
                }
                &:nth-child(2) {
                  color: #FFDB5C;
                }
                &:nth-child(3) {
                  color: #FF9F7F;
                }
              }
            }
          }
        }
        .tb {
          width: 100%;
          // max-height: 160px;
          height: 160px;
          .tr {
            display: flex;
            align-items: center;
            width: 364px;
            height: 40px;
            padding: 0 10px;
            & > p {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              line-height: 20px;
              color: #FFFFFF;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            &+.tr {
              border-top: 1px solid rgba(255, 255, 255, 0.3);
            }
            &:nth-child(2n) {
              background-color: rgba(22, 215, 255, 0.05);
            }
          }
        }
      }
    }
  }
</style>
