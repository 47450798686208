const fontFamily = ['Microsoft YaHei', 'STHeiti', 'STKaiti', 'STSong', 'STFangsong', 'SimHei', 'SimSun', 'NSimSun', 'FangSong', 'KaiTi', 'FangSong_GB2312', 'KaiTi_GB2312', 'Microsoft JhengHei', 'Microsoft YaHei', 'YouYuan', 'LiSu', 'STXihei', 'STSong', 'STZhongsong']

const digitalFont = ['LESLIE', 'PingFangHeavy', 'PingFangMedium', 'PingFangBold', 'HelveticaNarrow', 'YouSheBiaoTiHei', 'SonyEricssonLogo']

const countryList = [
  '澳大利亚',
  '巴西',
  '加拿大',
  '英国',
  '芬兰',
  '法国',
  '德国',
  '冰岛',
  '印度',
  '日本',
  '荷兰',
  '新西兰',
  '挪威',
  '葡萄牙',
  '俄国',
  '新加坡',
  '南非',
  '韩国',
  '西班牙',
  '瑞典',
  '瑞士',
  '美国',
  '中国',
  '阿富汗',
  '阿尔巴尼亚',
  '阿尔及利亚',
  '安道尔',
  '安哥拉',
  '安圭拉岛',
  '安提瓜和巴布达',
  '阿根廷',
  '亚美尼亚',
  '奥地利',
  '阿塞拜疆',
  '巴林',
  '孟加拉国',
  '巴巴多斯',
  '白俄罗斯',
  '比利时',
  '伯利兹',
  '贝宁',
  '百慕大',
  '不丹',
  '玻利维亚',
  '波斯尼亚和黑塞哥维那',
  '博茨瓦纳',
  '英属印度洋领地',
  '英属维尔京群岛',
  '文莱',
  '保加利亚',
  '布基纳法索',
  '布隆迪',
  '柬埔寨',
  '喀麦隆'
]

const SHOW_ROW_COUNT = [1, 2, 3, 5, 7, 8, 9, 10, 15, 20, 25, 30]

export const D3_TIME_FORMAT_OPTIONS = [
  ['smart_date', 'Adaptative formating'],
  ['%d/%m/%Y', '%d/%m/%Y | 14/01/2019'],
  ['%m/%d/%Y', '%m/%d/%Y | 01/14/2019'],
  ['%Y-%m-%d', '%Y-%m-%d | 2019-01-14'],
  ['%Y-%m-%d %H:%M:%S', '%Y-%m-%d %H:%M:%S | 2019-01-14 01:32:10'],
  ['%d-%m-%Y %H:%M:%S', '%Y-%m-%d %H:%M:%S | 14-01-2019 01:32:10'],
  ['%H:%M:%S', '%H:%M:%S | 01:32:10']
]

const D3_FORMAT_OPTIONS = [
  ['.1s', '.1s (12345.432 => 10k)'],
  ['.3s', '.3s (12345.432 => 12.3k)'],
  [',.1%', ',.1% (12345.432 => 1,234,543.2%)'],
  ['.3%', '.3% (12345.432 => 1234543.200%)'],
  ['.4r', '.4r (12345.432 => 12350)'],
  [',.3f', ',.3f (12345.432 => 12,345.432)'],
  ['+,', '+, (12345.432 => +12,345.432)'],
  ['$,.2f', '$,.2f (12345.432 => $12,345.43)']
]

const ECHARTS_TIME_FORMAT_OPTIONS = [
  '%Y', '%Y | 2019',
  '%Y-%m | 2019-01',
  '%H:%M:%S | 01:32:10',
  '%m-%d | 01-14',
  '%d/%m/%Y | 14/01/2019',
  '%m/%d/%Y | 01/14/2019',
  '%Y-%m-%d | 2019-01-14',
  '%Y-%m-%d %H:%M:%S | 2019-01-14 01:32:10',
  '%d-%m-%Y %H:%M:%S | 14-01-2019 01:32:10'
]

const nameGapList = [5, 10, 15, 20, 25, 30, 35, 40]

export const chartFieldData = {
  TailColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置图表最后后一列柱子的颜色',
    label: 'TailColor',
    type: 'colorPicker'
  },
  XAxisFontSize: {
    default: '10',
    description: '设置横坐标轴刻度的字体大小',
    label: 'XAxisFontSize',
    type: 'input'
  },
  XAxisLineColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置图表中x坐标轴的颜色',
    label: 'XAxisLineColor',
    type: 'colorPicker'
  },
  XlabelToAxis: {
    default: '10',
    description: 'x轴刻度标签到坐标轴的距离, 用于调整x轴标签的位置',
    label: 'XlabelToAxis',
    type: 'input'
  },
  YAxisFontSize: {
    default: '10',
    description: '设置纵坐标轴刻度的字体大小',
    label: 'YAxisFontSize',
    type: 'input'
  },
  YAxisLineWidth: {
    default: '2',
    description: '请输入数字,设置Y轴坐标轴线的线宽',
    label: 'YAxisLineWidth',
    type: 'input'
  },
  YShowSplitline: {
    default: false,
    description: '是否显示y轴分割线',
    label: 'y轴分割线',
    type: 'checkBox'
  },
  YlabelToAxis: {
    default: '10',
    description: 'Y轴刻度标签到坐标轴的距离, 用于调整y轴标签的位置',
    label: 'YlabelToAxis',
    type: 'input'
  },
  areaColor: {
    default: 'rgba(0, 122, 135, 0)',
    description: '设置图表中填充区域的颜色',
    label: 'areaColor',
    type: 'colorPicker'
  },
  axisFontColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '坐标轴文本的颜色',
    label: 'axisFontColor',
    type: 'colorPicker'
  },
  axisFontSize: {
    default: '10',
    description: '设置横纵坐标轴刻度的字体大小',
    label: 'axisFontSize',
    type: 'input'
  },
  axisLableColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置图表中label的颜色',
    label: 'axisLableColor',
    type: 'colorPicker'
  },
  axisLableFontSize: {
    default: '20',
    description: '请输入数字,设置柱状图标签的字体大小',
    label: 'axisLableFontSize',
    type: 'input'
  },
  axisLineColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置图表中y坐标轴or分割线的颜色',
    label: 'axisLineColor',
    type: 'colorPicker'
  },
  axisLineWidth: {
    default: '2',
    description: '请输入数字,设置坐标轴线的线宽',
    label: 'axisLineWidth',
    type: 'input'
  },
  axis_names: {
    default: '',
    description: '请用中文逗号隔开多个名称',
    label: '坐标轴名称',
    type: 'input'
  },
  backgroundColor: {
    default: 'rgba(0, 122, 135, 0)',
    description: '设置整个图表的背景颜色',
    label: 'backgroundColor',
    type: 'colorPicker'
  },
  barBackgroundColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '图表中柱子的背景色',
    label: 'barBackgroundColor',
    type: 'colorPicker'
  },
  barBorderRadius: {
    default: '10,10,0,0',
    description: '请输入四个数字,设置柱状图的圆角大小',
    label: 'barBorderRadius',
    type: 'input'
  },
  barColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置图表中柱子的颜色',
    label: 'barColor',
    type: 'colorPicker'
  },
  barHeight: {
    default: '60',
    description: '',
    label: '均值的高度',
    options: [10, 20, 50, 60, 100, 150],
    type: 'select'
  },
  barLabelFontSize: {
    default: '20',
    description: '请输入数字,设置柱状图上的文本大小',
    label: 'barLabelFontSize',
    type: 'input'
  },
  barRadius: {
    default: '',
    description: '请输入数字,设置图形的圆角大小',
    label: 'barRadius',
    type: 'input'
  },
  barWidth: {
    default: '16',
    description: '请输入数字,设置柱子的宽度',
    label: 'barWidth',
    type: 'input'
  },
  barMinHeight: {
    default: '30',
    description: '设置柱子最小高度，避免极小值造成柱子显示畸形',
    label: 'barMinHeight',
    type: 'input'
  },
  block_name: {
    default: 'xx分布区间，xx分布区间',
    description: '需要按照格式填写，中文逗号分隔',
    label: '分区名称',
    type: 'input'
  },
  borderColor: {
    default: 'rgba(225, 255, 255, 1)',
    description: '设置边框的颜色',
    label: 'borderColor',
    type: 'colorPicker'
  },
  cancelBarLineColor: {
    default: true,
    description: '取消直接给柱状图与折线图添加颜色的功能',
    label: 'cancelBarLineColor',
    type: 'checkBox'
  },
  cancelCustomizeColor: {
    default: false,
    description: '取消自定义的颜色',
    label: 'cancelCustomizeColor',
    type: 'checkBox'
  },
  circleColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置圆的颜色',
    label: 'circleColor',
    type: 'colorPicker'
  },
  circleSize: {
    default: '16',
    description: '请输入数字,设置圆形的尺寸',
    label: 'circleSize',
    type: 'input'
  },
  circleStyle: {
    default: '单环蓝色',
    description: '',
    label: '选择不同的环形样式',
    options: ['单环蓝色', '双环蓝色', '单环黄色', '双环黄色'],
    type: 'select'
  },
  colPrefixList: {
    default: '',
    description: '设置每列文本的前缀',
    label: 'colPrefixList',
    type: 'input'
  },
  colWidth: {
    default: '16',
    description: '请输入数字,设置列宽',
    label: 'colWidth',
    type: 'input'
  },
  color_echarts_theme: {
    default: 'themeDefault',
    description: 'The color scheme for rendering chart',
    label: 'Color Scheme',
    options: ['themeDefault',
      'themeOne',
      'themeTwo'
    ],
    type: 'select',
    isDelete: true
  },
  countryMap: {
    default: '中国',
    description: '',
    label: '选择国家地图',
    options: countryList,
    type: 'select'
  },
  customizeFontFamily: {
    default: '',
    description: '请输入字体名称',
    label: 'customizeFontFamily',
    type: 'input'
  },
  dateFontSize: {
    default: '12',
    description: '请输入数字,设置文本字体大小',
    label: 'dateFontSize',
    type: 'input'
  },
  delayTime: {
    default: '3000',
    description: '设置停留时间',
    label: 'delayTime',
    type: 'input'
  },
  digtalfontFamily: {
    default: 'Microsoft YaHei',
    description: '',
    label: 'digtalfontFamily',
    options: digitalFont,
    type: 'select'
  },
  eachBarTableWidth: {
    default: '40%',
    description: '设置单个柱状图表的宽度,建议使用百分数',
    label: 'eachbartablewidth',
    type: 'input'
  },
  echarts_timestamp_format: {
    default: '%Y-%m-%d %H:%M:%S',
    description: '修改时间格式',
    label: 'X轴时间格式',
    options: ECHARTS_TIME_FORMAT_OPTIONS,
    type: 'select'
  },
  emphasisObject: {
    default: '',
    description: '输入突出强调对象的横坐标',
    label: 'emphasisObject',
    type: 'input'
  },
  endLine: {
    default: '20',
    description: '设置饼状图标签线的终止部分长度',
    label: 'endLine',
    type: 'input'
  },
  firstRowHeight: {
    default: '16',
    description: '请输入数字,设置第一行的行高',
    label: 'firstRowHeight',
    type: 'input'
  },
  fontFamily: {
    default: 'Microsoft YaHei',
    description: '',
    label: 'fontFamily',
    options: fontFamily,
    type: 'select'
  },
  fontSizeList: {
    default: '16,14',
    description: '输入一系列数值,用逗号隔开,用来控制每列的字体大小',
    label: 'fontSizeList',
    type: 'input'
  },
  gradientColorEnd: {
    default: 'rgba(0, 122, 135, 1)',
    description: '选择终止的渐变色',
    label: 'gradientColorEnd',
    type: 'colorPicker'
  },
  gradientColorStart: {
    default: 'rgba(0, 122, 135, 1)',
    description: '选择起始的渐变色',
    label: 'gradientColorStart',
    type: 'colorPicker'
  },
  groupbyNum: {
    default: '3',
    description: '设置图表分组的字段数量',
    label: 'groupbyNumBase',
    type: 'input'
  },
  hoverColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置高亮颜色',
    label: 'hoverColor',
    type: 'colorPicker'
  },
  iconBackgroundColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '图表中图表的背景色',
    label: 'iconBackgroundColor',
    type: 'colorPicker'
  },
  iconColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '图表中图表的颜色',
    label: 'iconColor',
    type: 'colorPicker'
  },
  iconHeight: {
    default: '10',
    description: '设置图标的高度',
    label: 'iconHeight',
    type: 'input'
  },
  iconLeftGap: {
    default: '10',
    description: '设置图标左侧的间距',
    label: 'iconLeftGap',
    type: 'input'
  },
  iconTopGap: {
    default: '10',
    description: '设置图标顶部的padding',
    label: 'iconTopGap',
    type: 'input'
  },
  iconWidth: {
    default: '10',
    description: '设置图标的宽度',
    label: 'iconWidth',
    type: 'input'
  },
  inlineType: {
    default: 'dashed',
    description: '',
    label: '选择线的样式',
    options: ['solid', 'dashed'],
    type: 'select'
  },
  xInlineType: {
    default: 'dashed',
    description: '选择x轴分割线的样式',
    label: 'xInlineType',
    options: ['solid', 'dashed'],
    type: 'select'
  },
  inputNumberOrText: {
    default: '请输入内容',
    description: '请输入文本或者数值',
    label: 'inputNumberOrText',
    type: 'input'
  },
  isAddHeader: {
    default: true,
    description: '是否添加表头?',
    label: 'isAddHeader',
    type: 'checkBox'
  },
  isAddPictorialBar: {
    default: true,
    description: '是否显示象形图案',
    label: 'isAddPictorialBar',
    type: 'checkBox'
  },
  isAddTailColor: {
    default: true,
    description: '是否给最后一列添加指定颜色',
    label: 'isAddTailColor',
    type: 'checkBox'
  },
  isAescending: {
    default: true,
    description: 'Whether to sort descending or ascending',
    label: 'isAescending',
    type: 'checkBox'
  },
  isMoveUp: {
    default: true,
    description: '图表是否竖向滚动?',
    label: 'isMoveUp',
    type: 'checkBox'
  },
  isSearchIcon: {
    default: true,
    description: '是否添加搜索按钮',
    label: 'isSearchIcon',
    type: 'checkBox'
  },
  isShowAreaColor: {
    default: true,
    description: '是否显示折线下的区域颜色?',
    label: 'isShowAreaColor',
    type: 'checkBox'
  },
  isShowAxis: {
    default: true,
    description: '是否显示坐标轴',
    label: 'isShowAxis',
    type: 'checkBox'
  },
  isShowAreaTitle: {
    default: true,
    description: '是否显示地区名称',
    label: 'isShowAreaTitle',
    type: 'checkBox'
  },
  isShowBarLabel: {
    default: true,
    description: '是否显示柱状图上的文本',
    label: 'isShowBarLabel',
    type: 'checkBox'
  },
  barLabelColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置柱状图标注的颜色',
    label: 'barLabelColor',
    type: 'colorPicker'
  },
  isShowLeftYAxis: {
    default: true,
    description: '是否显示左侧纵坐标',
    label: 'isShowLeftYAxis',
    type: 'checkBox'
  },
  isShowLeftYLabel: {
    default: true,
    description: '是否显示左侧纵坐标上的文本',
    label: 'isShowLeftYLabel',
    type: 'checkBox'
  },
  isShowRightYAxis: {
    default: true,
    description: '是否显示右侧纵坐标',
    label: 'isShowSecondYAxis',
    type: 'checkBox'
  },
  isShowRightYLabel: {
    default: true,
    description: '是否显示右侧纵坐标上的文本',
    label: 'isShowRightYLabel',
    type: 'checkBox'
  },
  isShowSeriesCircle: {
    default: true,
    description: '是否显示系列上的特殊效果',
    label: 'isShowSeriesCircle',
    type: 'checkBox'
  },
  isShowSeriesLabel: {
    default: true,
    description: '是否显示系列图上的文本',
    label: 'isShowSeriesLabel',
    type: 'checkBox'
  },
  isShowSign: {
    default: false,
    description: '是否显示正负号',
    label: 'isShowSign',
    type: 'checkBox'
  },
  is_exchange_bar_line: {
    default: false,
    description: '是否交换柱状图与折线图的显示方式',
    label: '交换柱状图与折线图的显示方式',
    type: 'checkBox'
  },
  legend: {
    default: '',
    description: '请用中文逗号隔开多个图例名称',
    label: '图例名称',
    type: 'input'
  },
  legendTextSize: {
    default: 12,
    description: '图例字体大小（数字格式，不带单位）',
    label: '图例字体大小',
    type: 'input'
  },
  legendItemGap: {
    default: 10,
    description: '图例每项之间的间隔。横向布局时为水平间隔，纵向布局时为纵向间隔。',
    label: '图例每项之间的间隔',
    type: 'input'
  },
  legendItemWidth: {
    default: 10,
    description: '图例标记的图形宽度（数字格式，不带单位）',
    label: '图例标记的图形宽度',
    type: 'input'
  },
  legendItemHeight: {
    default: 14,
    description: '图例标记的图形高度（数字格式，不带单位）',
    label: '图例标记的图形高度',
    type: 'input'
  },
  legend_left: {
    default: '10%',
    description: '设置图例距离左侧边界的距离',
    label: 'legend_left',
    type: 'input'
  },
  legend_top: {
    default: '50%',
    description: '设置图例距离顶部边界的距离',
    label: 'legend_top',
    type: 'input'
  },
  lineColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置图表中线形图的颜色',
    label: 'lineColor',
    type: 'colorPicker'
  },
  lineColorFour: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置图表中第四条线的颜色',
    label: 'lineColorFour',
    type: 'colorPicker'
  },
  lineColorSecond: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置图表中第二条线的颜色',
    label: 'lineColorSecond',
    type: 'colorPicker'
  },
  lineColorThird: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置图表中第三条线的颜色',
    label: 'lineColorThird',
    type: 'colorPicker'
  },
  lineWidth: {
    default: '2',
    description: '请输入数字,设置线的宽度',
    label: 'lineWidth',
    type: 'input'
  },
  map_type: {
    default: '3d地图',
    description: '',
    label: '选择',
    options: ['3d地图'],
    type: 'select'
  },
  marginTop: {
    default: '100',
    description: '设置对象的margin-top距离',
    label: 'marginTop',
    type: 'input'
  },
  numberColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置数字的颜色',
    label: 'numberColor',
    type: 'colorPicker'
  },
  numberFontSize: {
    default: '20',
    description: '请输入数字,设置数字字体大小',
    label: 'numberFontSize',
    type: 'input'
  },
  numerator: {
    default: '100',
    description: '',
    label: 'numerator(输入分子',
    type: 'input'
  },
  parentHeight: {
    default: '500',
    description: '设置最外层的高度',
    label: 'parentHeight',
    type: 'input'
  },
  parentWidth: {
    default: '500',
    description: '设置最外层的宽度',
    label: 'parentWidth',
    type: 'input'
  },
  picOffsetXList: {
    default: '0,0,0',
    description: '设置象形图案x方向的偏移距离',
    label: 'picOffsetXList',
    type: 'input'
  },
  pictorialBarColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置图表中象形图案的颜色',
    label: 'pictorialBarColor',
    type: 'colorPicker'
  },
  pictorialBarHeight: {
    default: '2',
    description: '请输入数字,设置象形图案的高度',
    label: 'pictorialBarHeight',
    type: 'input'
  },
  pictorialBarOffsetX: {
    default: '2',
    description: '请输入数字,设置象形图案在x轴方向上的移动距离',
    label: 'pictorialBarOffsetX',
    type: 'input'
  },
  pictorialBarOffsetY: {
    default: '2',
    description: '请输入数字,设置象形图案在y轴方向上的移动距离',
    label: 'pictorialBarOffsetY',
    type: 'input'
  },
  pictorialBarType: {
    default: 'circle',
    description: '',
    label: '选择象形图表的类型',
    options: ['circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'],
    type: 'select'
  },
  pictorialBarWidth: {
    default: '2',
    description: '请输入数字,设置象形图案的宽度',
    label: 'pictorialBarWidth',
    type: 'input'
  },
  pictorialSymbolType: {
    default: 'people',
    description: '',
    label: 'pictorialSymbolType',
    options: ['people', 'lineBar'],
    type: 'select'
  },
  picture_symbols: {
    default: '',
    description: '按顺序输入图片名称',
    label: '展示的图片',
    type: 'input'
  },
  piePosition: {
    default: '50%, 50%',
    description: '设置饼图中心点的位置,可以使用百分比或者数值来定位,例如 50, 50',
    label: 'piePosition',
    type: 'input'
  },
  pieWidth: {
    default: '50%, 50%',
    description: '设置环形的宽度,例如 20%, 22%',
    label: 'pieWidth',
    type: 'input'
  },
  pieLiquidHeight: {
    default: '0.5,0.5,0.5',
    description: '设置流动环形图的高度分层',
    label: 'pieLiquidHeight',
    type: 'input'
  },
  pie_table_type: {
    default: '普通饼状图',
    description: '',
    label: '选择不同的饼状图',
    options: ['普通饼状图', '南丁格尔玫瑰图'],
    type: 'select'
  },
  rippleScale: {
    default: '40',
    description: '设置涟漪的扩散范围大小',
    label: 'rippleScale',
    type: 'input'
  },
  rowBackcolor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置行的背景色',
    label: 'rowBackcolor',
    type: 'colorPicker'
  },
  rowHeight: {
    default: '16',
    description: '请输入数字,设置行高',
    label: 'rowHeight',
    type: 'input'
  },
  rowRealWidthList: {
    default: '50,50',
    description: '输入一系列数值,用逗号隔开,设置每列的实际宽度，单位默认px，为空，则不设置',
    label: 'rowRealWidthList',
    type: 'input'
  },
  rowWidth: {
    default: '200',
    description: '设置行宽',
    label: 'rowWidth',
    type: 'input'
  },
  rowWidthList: {
    default: '1,1',
    description: '输入一系列数值,用逗号隔开,用来控制每列的相对宽度',
    label: 'rowWidthList',
    type: 'input'
  },
  secondBarTableToLeft: {
    default: '54%',
    description: '设置右侧图表距离左边界的距离,建议使用百分数',
    label: 'secondBarTableToLeft',
    type: 'input'
  },
  secondGradientColorEnd: {
    default: 'rgba(0, 122, 135, 1)',
    description: '选择终止的渐变色',
    label: 'secondGradientColorEnd',
    type: 'colorPicker'
  },
  secondGradientColorStart: {
    default: 'rgba(0, 122, 135, 1)',
    description: '选择起始的渐变色',
    label: 'secondGradientColorStart',
    type: 'colorPicker'
  },
  secondPieWidth: {
    default: '50%, 50%',
    description: '设置环形的宽度,例如 20%, 22%',
    label: 'secondPieWidth',
    type: 'input'
  },
  second_y_axis_unit: {
    default: '',
    description: '',
    label: '第二个Y轴单位',
    type: 'input'
  },
  selectArrowIcon: {
    default: 'down',
    description: '',
    label: 'selectArrowIcon',
    options: ['down', 'up', '无'],
    type: 'select'
  },
  selectedColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置选中对象的颜色',
    label: 'selectedColor',
    type: 'colorPicker'
  },
  selected_part: {
    default: '',
    description: '输入对象的名称',
    label: '突出展示的对象',
    type: 'input'
  },
  showIcon: {
    default: true,
    description: 'Whether to show Icon',
    label: 'showIcon',
    type: 'checkBox'
  },
  show_row_limit: {
    default: '8',
    description: '',
    label: '设置图表显示行的数量',
    options: SHOW_ROW_COUNT,
    type: 'select'
  },
  speedTime: {
    default: '300',
    description: '设置运动时间',
    label: 'speedTime',
    type: 'input'
  },
  axisTickWidth: {
    default: '1',
    description: '设置坐标轴上刻度线的宽度',
    label: 'axisTickWidth',
    type: 'input'
  },
  axisTickLength: {
    default: '3',
    description: '设置坐标轴上刻度线的长度',
    label: 'axisTickLength',
    type: 'input'
  },
  axisTickColor: {
    default: 'rgba(0, 122, 135, 0)',
    description: '设置坐标轴上刻度线的颜色',
    label: 'axisTickColor',
    type: 'colorPicker'
  },
  splitLineColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置坐标轴分割线的颜色',
    label: 'splitLineColor',
    type: 'colorPicker'
  },
  splitLineWidth: {
    default: '1',
    description: '设置坐标轴分割线的宽度',
    label: 'splitLineWidth',
    type: 'input'
  },
  xSplitLineColor: {
    default: 'rgba(0, 122, 135, 0)',
    description: '设置x轴分割线的颜色',
    label: 'xSplitLineColor',
    type: 'colorPicker'
  },
  xSplitLineWidth: {
    default: '0',
    description: '设置x轴分割线的宽度',
    label: 'xSplitLineWidth',
    type: 'input'
  },
  interval: {
    default: '0',
    description: '设置坐标轴分割线的间隔',
    label: 'interval',
    type: 'input'
  },
  axisTickInterval: {
    default: '0',
    description: '设置坐标轴刻度线的间隔',
    label: 'axisTickInterval',
    type: 'input'
  },
  splitNumber: {
    default: '5',
    description: '设置等分的数量',
    label: 'splitNumber',
    type: 'input'
  },
  stack_table_type: {
    default: '3d柱形图',
    description: '',
    label: '设置叠加的图表',
    options: ['3d柱形图', '3d散点图'],
    type: 'select'
  },
  stack_type: {
    default: '平铺',
    description: '',
    label: '设置图表堆叠方式',
    options: ['平铺', '全部堆叠', '两两堆叠'],
    type: 'select'
  },
  startLine: {
    default: '10',
    description: '设置饼状图标签线的起始部分长度',
    label: 'startLine',
    type: 'input'
  },
  sub_title: {
    default: '',
    description: '多个副标题可用中文逗号隔开',
    label: '副标题',
    type: 'input'
  },
  sumNum: {
    default: '100',
    description: '设置饼状图的总和',
    label: 'sumNum',
    type: 'input'
  },
  tableToBorderDistance: {
    default: '20, 20',
    description: '设置图表距离左右边界的距离,建议使用数值',
    label: 'tableToBorderDistance',
    type: 'input'
  },
  table_type: {
    default: 'bar',
    description: '',
    label: '设置图表类型',
    options: ['bar', 'scatter', 'line'],
    type: 'select'
  },
  targetHeight: {
    default: '100',
    description: '设置对象的高度',
    label: 'targetHeight',
    type: 'input'
  },
  targetMarginLeft: {
    default: '100',
    description: '设置对象的margin-left距离',
    label: 'targetMarginLeft',
    type: 'input'
  },
  targetWidth: {
    default: '100',
    description: '设置对象的宽度',
    label: 'targetWidth',
    type: 'input'
  },
  textAlign: {
    default: '居中对齐',
    description: '',
    label: '设置文本水平对其方式',
    options: ['左对齐', '右对齐', '居中对齐'],
    type: 'select'
  },
  textBodyWidth: {
    default: '300',
    description: '设置文本框的宽度',
    label: 'textBodyWidth',
    type: 'input'
  },
  textColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置图表中文本的颜色',
    label: 'textColor',
    type: 'colorPicker'
  },
  textShadowColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置图表中文本的投影颜色',
    label: 'textShadowColor',
    type: 'colorPicker'
  },
  textColorList: {
    default: '#fff',
    description: '输入一系列颜色值,用分号隔开,用来控制每列的颜色',
    label: 'textColorList',
    type: 'input'
  },
  endTextColorList: {
    default: '#fff',
    description: '输入一系列颜色值,用分号隔开,用来控制每列的颜色',
    label: 'endTextColorList',
    type: 'input'
  },
  textFontSize: {
    default: '12',
    description: '请输入数字,设置文本字体大小',
    label: 'textFontSize',
    type: 'input'
  },
  textGap: {
    default: '10',
    description: '设置字符串之间的间距',
    label: 'textGap',
    type: 'input'
  },
  timeLabel: {
    default: '最近的日期',
    description: '',
    label: 'timeLabel',
    options: ['最近的日期', '最早的日期'],
    type: 'select'
  },
  title: {
    default: '',
    description: '多个标题可用中文逗号隔开',
    label: '标题',
    type: 'input'
  },
  titleBackgroundColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置title背景的颜色',
    label: 'titleBackgroundColor',
    type: 'colorPicker'
  },
  titleColor: {
    default: 'rgba(0, 122, 135, 1)',
    description: '设置title的颜色',
    label: 'titleColor',
    type: 'colorPicker'
  },
  titlePosition: {
    default: '50%, 50%',
    description: '设置图表title的位置,使用百分比定位,例如 50%, 50%',
    label: 'titlePosition',
    type: 'input'
  },
  titleUnit: {
    default: '',
    description: '设置标题中的单位',
    label: 'titleUnit',
    type: 'input'
  },
  top_num: {
    default: '10',
    description: '',
    label: 'top N',
    options: [5, 10, 15, 25, 50, 75, 100],
    type: 'select'
  },
  x_axis_max_value: {
    default: '',
    description: '',
    label: '横坐标的上限,多个横坐标用逗号隔开',
    type: 'input'
  },
  x_axis_min_value: {
    default: '',
    description: '',
    label: '横坐标的下限,多个横坐标用逗号隔开',
    type: 'input'
  },
  x_axis_unit: {
    default: '',
    description: '',
    label: 'X轴单位',
    type: 'input'
  },
  x_ticks_namegap: {
    default: '10',
    description: '坐标轴名称与轴线之间的距离',
    label: 'X轴名称与轴线的距离',
    options: nameGapList,
    type: 'select'
  },
  x_ticks_namelocation: {
    default: 'center',
    description: '坐标轴名称显示位置',
    label: 'X轴名称布局',
    options: ['start', 'center', 'end'],
    type: 'select'
  },
  x_ticks_rotate: {
    default: '0',
    description: 'The way the ticks are laid out on the X-axis',
    label: 'X轴名称旋转角度',
    options: [0, 30, 45, 60, 90],
    type: 'select'
  },
  yAxisLabelValue: {
    default: '',
    description: '',
    label: '纵坐标的上限,多个纵坐标用逗号隔开',
    type: 'input'
  },
  y_axis_max_value: {
    default: '',
    description: '',
    label: '纵坐标的上限,多个纵坐标用逗号隔开',
    type: 'input'
  },
  y_axis_min_value: {
    default: '',
    description: '',
    label: '纵坐标的下限,多个纵坐标用逗号隔开',
    type: 'input'
  },
  y_axis_unit: {
    default: '',
    description: '',
    label: 'Y轴单位',
    type: 'input'
  },
  y_ticks_namegap: {
    default: '10',
    description: '坐标轴名称与轴线之间的距离',
    label: 'Y Tick namegap',
    options: nameGapList,
    type: 'select'
  },

  number_format: {
    type: 'select',
    label: '数字样式',
    default: '.3s',
    options: D3_FORMAT_OPTIONS,
    description: '数字样式'
  },

  treemap_ratio: {
    type: 'input',
    label: 'Ratio',
    default: 0.5 * (1 + Math.sqrt(5)),
    description: 'Target aspect ratio for treemap tiles.'
  },

  lineSmoothNumber: {
    type: 'input',
    label: 'lineSmoothNumber',
    default: '0.5',
    description: '设置线条的平滑程度'
  },

  boundaryGap: {
    default: true,
    description: '是否与坐标轴脱离',
    label: 'boundaryGap',
    type: 'checkBox'
  },
  isStack: {
    default: false,
    description: '是否为堆积图',
    label: 'isStack',
    type: 'checkBox'
  },
  isCurrentDate: {
    default: false,
    description: '是否显现当前日期',
    label: 'isCurrentDate',
    type: 'checkBox'
  },
  areaColorOpacity: {
    type: 'input',
    label: 'areaColorOpacity',
    default: 0.5,
    description: '设置面积区域颜色的透明度'
  },
  siteNamePY: {
    type: 'input',
    label: 'siteNamePY',
    default: 'chibi',
    description: '输入地区名称的拼音'
  },
  tailZeroNum: {
    type: 'input',
    label: 'tailZeroNum',
    default: 0,
    description: '设置末尾显示的0的个数'
  },
  period: {
    type: 'input',
    label: 'period',
    default: 2000,
    description: '设置周期时间'
  },
  pagination: {
    type: 'input',
    label: 'pagination',
    default: '10,10',
    description: '设置分页数量;可以设置多个分页数值'
  },
  gridPosition: {
    type: 'input',
    label: 'gridPosition',
    default: '7%;10%;7%;10%',
    description: '设置图表主体位置，四个值分别设置左边距，上边距，右边距，下边距'
  },
  letterSpacing: {
    type: 'input',
    label: 'letterSpacing',
    default: 1,
    description: '设置字间距'
  },
  barStandardSelect: {
    default: 'chart1',
    description: '选择不同的图表样式',
    label: 'barStandardSelect',
    options: ['chart1', 'chart2', 'chart3', 'chart4', 'chart5', 'chart6', 'chart7'],
    type: 'select'
  },
  htmlSelect: {
    default: 'chart1',
    description: '选择不同图表',
    label: 'htmlSelect',
    options: ['chart1', 'chart2', 'chart3', 'chart4', 'chart5', 'chart6', 'chart7', 'chart8', 'chart9', 'chart10', 'chart11',
      'chart12', 'chart13', 'chart20', 'chart21', 'chart22', 'chart23', 'chart40', 'chart41', 'chart24', 'chart25', 'chart26', 'chart27',
      'chart28', 'chart30', 'chart31', 'chart32', 'medicalChart1', 'medicalChart2', 'medicalChart3', 'medicalChart4'],
    type: 'select'
  },
  chartSelect: {
    default: 'chart1',
    description: '选择不同的图表样式',
    label: 'chartSelect',
    options: ['chart1', 'chart2', 'chart3', 'chart4'],
    type: 'select'
  }

}
