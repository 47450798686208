/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
// import echarts from 'echarts'
// import {EchartsSchemes} from '../echarts_theme/echarts_color_schemes'
import './tablerimless.css'

function TableRimless (element, props) {
  // console.log(wonderland.color)
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const data = props.data[0].data

  const rowHeight = props.data[0].rowHeight
  const textFontSize = props.data[0].textFontSize

  const backgroundColor = 'rgba(0,0,0,0)'
  const textColor = props.data[0].textColor
  const titleColor = props.data[0].titleColor
  const selectedColor = props.data[0].selectedColor
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const selectedPart = props.data[0].selectedPart || props.data[0].selected_part
  const rowWidth = props.data[0].rowWidth

  onload = function () {
    const body = element.children[0]
    body.setAttribute('style', 'width: 100%; max-height: 100%; background-color: ' + backgroundColor)

    const originDiv = document.createElement('div')
    // originDiv.setAttribute('style', 'height: ' + props.height + 'px')
    originDiv.setAttribute('style', 'width: 90%; height: 90%; margin: 0 auto')
    body.appendChild(originDiv)

    const firstDiv = document.createElement('div')
    firstDiv.setAttribute('class', 'oneColumn')
    firstDiv.setAttribute('style', 'height: ' + props.height * 0.9 + 'px')

    // 添加 td
    // for循环json对象,然后将循环到的对象通过createTr()方法生成行，添加到table中
    // TODO 需要确定数据的长度 length ==>  行的数量
    for (let i = 0; !(i >= data.length); i++) {
      const eachData = data[i]
      const colData = []
      for (const key in eachData) {
        // console.log(eachData[key])
        colData.push(eachData[key])
      }
      // 需要 根据 奇偶数 设置行的颜色
      const trNode = createTr(colData)

      firstDiv.appendChild(trNode)
    }

    originDiv.appendChild(firstDiv)

    // 设置间距
    const rows = document.getElementsByClassName('oneColumn-row')
    for (let i = 0; i < rows.length; i++) {
      // 排除被选中的地区
      const selectedNode = rows[i].children[0]
      const selectedText = selectedNode.innerText
      // console.log(selectedText)

      const secondNode = rows[i].children[1]
      if (selectedText !== selectedPart) {
        secondNode.setAttribute('style', 'color: ' + textColor)
        selectedNode.setAttribute('style', ' color: ' + titleColor)
      }
    }

    // 添加事件监听
    // let targetDiv = document.getElementById()
  }

  // ----------------------------------
  //   根据用户传过来的变量生成表格中行的方法
  // ----------------------------------
  const createTr = function (colData) {
    // 定义行元素标签
    const textDiv = document.createElement('div')
    textDiv.setAttribute('class', 'oneColumn-row')
    textDiv.setAttribute('style', 'font-size: ' + textFontSize + 'px; height: ' + rowHeight + 'px; width: ' + rowWidth + 'px')
    const colLength = colData.length
    // console.log('colLength:', colLength);
    // const colWidth = Math.floor(100 / colLength)
    // console.log('colWidth:', colWidth);
    for (let i = 0; i < colLength; i++) {
      const eachRow = document.createElement('div')
      // eachRow.setAttribute('style', 'width: ' + colWidth + '%;');
      eachRow.setAttribute('class', 'firstCell')
      textDiv.append(eachRow)

      // 创建 包含文本的 span标签， 用来求文本的长度
      const spanNode = document.createElement('span')
      spanNode.setAttribute('class', 'oneColumnText')
      eachRow.append(spanNode)
      spanNode.innerHTML = colData[i]
      // console.log('colData', colData[i])
      if (colData[i] === selectedPart) {
        // console.log('colData', colData[i])
        textDiv.setAttribute('style', 'font-size: ' + textFontSize + 'px; color: ' + selectedColor + '; height: ' + rowHeight + 'px; width: ' + rowWidth + 'px')
      }

      textDiv.appendChild(eachRow)
    }
    return textDiv
  }

  //  不能使用 setInterval 方法,本身就是一个递归方法
  //  props返回的内容增加 mode[edit show]  autoplay[True false],只有当 show, True时, 才执行循环刷新的方法
  const mode = props.mode
  const autoPlay = props.autoPlay
  // console.log(mode, autoPlay)
  if (mode === 'show' && autoPlay === true) {
    onload(autoPlay)
  } else {
    var noPlay = false
    onload(noPlay)
  }
}

export default TableRimless // 修改为Classname
