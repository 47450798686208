// import echarts from 'echarts'
import './digitalFlop.css'

// import {EchartsSchemes} from '../echarts_theme/echarts_color_schemes'
function DigitalFlop (element, props) {
  // console.log(props.data)
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  // 初始化
  const data = props.data[0].data
  const frontDict = props.data[0]

  // 前缀符号, 后缀符号 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const unit = props.data[0].xAxisUnit || props.data[0].x_axis_unit
  const numberFontSize = props.data[0].numberFontSize
  const numberColor = props.data[0].numberColor
  const textFontSize = props.data[0].textFontSize
  const textColor = props.data[0].textColor
  const dataLength = data.length
  const colOfWidth = Math.ceil(100 / dataLength)
  const textGap = props.data[0].textGap
  const isShowSign = props.data[0].isShowSign
  const digtalfontFamily = props.data[0].digtalfontFamily
  const tailZeroNum = props.data[0].tailZeroNum

  const targetDiv = element.children[0]
  // 生成dom节点
  const onload = function () {
    targetDiv.appendChild(createNumber(data, colOfWidth))
    for (var i = 0; i < dataLength; i++) {
      // 选择数字节点
      if (isShowSign === true) {
        const targetNode = targetDiv.children[0].children[i].children[1]
        myMove(targetNode)
      } else {
        const targetNode = targetDiv.children[0].children[i].children[0]
        myMove(targetNode)
      }
    }
  }

  var createNumber = function (data, colWidth) {
    var NumberDiv = document.createElement('div')
    NumberDiv.setAttribute('style', 'display: flex; text-align: center; height: 100%')
    for (var i = 0; i < dataLength; i++) {
      const eachData = data[i]
      // console.log(eachData)

      var NumberDivChild = document.createElement('div')
      NumberDivChild.setAttribute('style', 'display: flex; justify-content: center;align-items: center;  width: ' + colWidth + '%; font-family: ' + digtalfontFamily)

      if (isShowSign === true) {
        const unitPreifx = document.createElement('span')
        unitPreifx.setAttribute('style', 'margin-left: ' + textGap + 'px; text-align: center; vertical-align: auto; color: ' + textColor + '; font-size: ' + textFontSize + 'px')
        unitPreifx.innerHTML = unit
        if (eachData.value <= 0) {
          unitPreifx.innerHTML = '- '
        } else {
          unitPreifx.innerHTML = '+ '
        }
        NumberDivChild.appendChild(unitPreifx)
      }

      var zeroNum = (str, num) => str.repeat(num)
      var numberTarget = document.createElement('span')
      numberTarget.setAttribute('class', 'target-number')
      numberTarget.setAttribute('style', 'font-size: ' + numberFontSize + 'px ; color: ' + numberColor + ';letter-spacing: ' + frontDict.letterSpacing + 'px')
      numberTarget.setAttribute('id', 'number' + i)
      numberTarget.innerHTML = Math.abs(eachData.value).toString() + zeroNum('0', tailZeroNum)

      var unitSuffix = document.createElement('span')
      unitSuffix.setAttribute('style', 'margin-left: ' + textGap + 'px; text-align: center; vertical-align: auto; color: ' + textColor + '; font-size: ' + textFontSize + 'px')
      unitSuffix.innerHTML = unit

      // NumberDivChild.appendChild(unitPreifx);
      NumberDivChild.appendChild(numberTarget)
      NumberDivChild.appendChild(unitSuffix)

      NumberDiv.appendChild(NumberDivChild)
    }

    return NumberDiv
  }

  function myMove (targetnode) {
    var count = 0
    var result = ''
    // console.log(targetnode);
    var elem3 = targetnode
    var targetNum = elem3.innerText
    // var id3 = setInterval(ascIteration, 0.3);
    var move = setInterval(numAsc, 50)

    // TODO 新方法思路: 针对每一位进行递增
    // 1. 获取数字的每一位上的数字
    // 2. 每次 +1, 直到 除以 10 余数等于自身,停止,
    // 3. 每次运行结果 合并为新的字符

    function splitNum (str) {
      var numArray = []
      for (let i = 0; i < str.length; i++) {
        var currentStr = str.charAt(i)
        if (currentStr !== ',' && currentStr !== '.' && currentStr !== ',' && currentStr !== ' ') {
          var currentNum = parseInt(currentStr)
          // console.log(currentNum)
          currentNum += Math.round(Math.random() * 10)
          if (currentNum < 10) {
            numArray.push(currentNum)
          } else {
            currentNum = currentNum % 10
            // console.log('获取余数 ', currentNum);
            numArray.push(currentNum)
          }
        } else {
          numArray.push(currentStr)
        }
      }
      numArray.reverse()
      var res = ''
      for (var i = 0; i < numArray.length; i++) {
        res = res + numArray[i]
      }
      return res
    }

    function numAsc () {
      if (count < 20) {
        if (result === '') {
          result = targetNum
        }
        // console.log(result)
        result = splitNum(result)
        elem3.innerHTML = result
        count++
      } else {
        // console.log(result)
        elem3.innerHTML = targetNum
        clearInterval(move)
      }
    }
  }
  onload()
  function Play () {
    // console.log('循环')
    targetDiv.innerHTML = ''
    onload()
  }

  //  props返回的内容增加 mode[edit show]  autoplay[True false],只有当 show, True时, 才执行循环刷新的方法
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000 * 2)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default DigitalFlop // 修改为Classname
