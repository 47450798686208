<!-- 长春长生疫苗事件 -->
<template>
  <div class="chart_8">
    <div ref="chartDom" :style="chartBox"></div>
    <div class="modal" v-show="showModal" v-click-outside="clickOutside">
      <p
        class="modal_title"
        :title="curData.title"
      >{{ curData.title }}</p>
      <div class="bar_box">
        <div class="bar" :style="barStyle"></div>
        <span class="bar_num">{{ curData.barNum }}</span>
      </div>
      <div class="footer_box">
        <div class="footer_num">{{ curData.similarity }}</div>
        <div class="footer_bol">{{ curData.bol ? '是' : '否' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import initChart from '../chart/chart8'
import { topicToLike, articleCount } from './data-source/topic'

export default {
  data () {
    return {
      // 是否显示弹窗
      showModal: false,
      // 当前所点击的数据，可以在点击事件时赋值，重置整个对象
      curData: {
        // 标题
        title:
          '公安机关立案调查；董事长被刑事拘留公安机关立案调查；董事长被刑事拘留',
        // 博文相关数量
        barNum: 351387,
        // 与原始事件相似度
        similarity: 0.015715721,
        // 是否次生衍生
        bol: false
      }
    }
  },
  computed: {
    chartBox () {
      const style = {
        width: this.frontDict.parentWidth + 'px',
        height: this.frontDict.parentHeight + 'px'
      }
      return style
    },
    // 当前数据的柱状图宽
    barStyle () {
      let width = 0
      if (this.curData && this.curData.barNum) {
        const barNum = Number(this.curData.barNum)
        // 假设最大值为50万
        width = parseInt((barNum / 20000) * 240)
      }
      return {
        width: `${width}px`
      }
    }
  },
  mounted () {
    this.initChart()
  },
  methods: {
    initChart (category) {
      if (category === undefined) {
        category = '2017'
      }
      this.frontDict.category = category
      const option = initChart(this.frontDict)
      const targetDom = this.$refs.chartDom
      if (targetDom) {
        const myChart = echarts.init(targetDom)
        myChart.setOption(option)

        const self = this
        myChart.on('click', function (params) {
          if (params.dataType === 'node') {
            const name = params.name
            self.curData.title = name
            self.curData.similarity = topicToLike[name].like

            self.curData.bol = topicToLike[name].yn
            self.curData.barNum = articleCount[name].count
            self.showModal = !self.showModal
          }
        })
      }
    },
    clickOutside (e) {
      if (this.showModal) {
        this.showModal = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chart_8 {
  position: relative;
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    height: 300px;
    background-image: url("./img/chart_8_model.png");
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 0 30px;
    .modal_title {
      width: 100%;
      margin-top: 58px;
      padding-right: 30px;
      line-height: 25px;
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .bar_box {
      position: absolute;
      display: flex;
      align-items: center;
      height: 30px;
      top: 112px;
      left: 120px;
      .bar {
        height: 16px;
        background: #3ccaec;
        transition: all 0.3s;
        margin-right: 10px;
      }
      .bar_num {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 28px;
        color: #3ccaec;
      }
    }
    .footer_box {
      position: absolute;
      display: flex;
      justify-content: flex-start;
      left: 70px;
      bottom: 60px;
      height: 30px;
      align-items: center;
      .footer_num {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 28px;
        color: #fecc51;
        width: 125px;
        min-width: 125px;
        margin-right: 95px;
      }
      .footer_bol {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 28px;
        color: #68e5ba;
      }
    }
  }
}
</style>
