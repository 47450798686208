import echarts from 'echarts'
// eslint-disable-next-line no-unused-vars
import liquidFill from 'echarts-liquidfill'
import { commonFunc } from '../chartCommonPart'

function chartFunc (element, props) {
  const frontDict = props.data[0]

  const backgroundColor = props.data[0].backgroundColor

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  const myChart = echarts.init(element.children[0], props.colorTheme)

  var option = {
    backgroundColor: 'rgba(0,0,0,0)',
    title: {
      text: '',
      textStyle: {
        fontWeight: 'normal',
        fontSize: 25,
        color: 'rgb(97, 142, 205)'
      }
    },
    series: [{
      type: 'liquidFill',
      radius: frontDict.pieWidth[0],
      center: frontDict.piePosition,
      data: frontDict.pieLiquidHeight, // data个数代表波浪数
      color: frontDict.textColorList,
      backgroundStyle: {
        borderWidth: 1,
        color: backgroundColor
      },
      label: {
        // 设置中间文本
        normal: {
          show: frontDict.isShowSeriesLabel,
          formatter: '100%',
          // color: frontDict.textColor,
          textStyle: {
            fontSize: frontDict.numberFontSize
          }
        }
      },
      period: frontDict.period,
      // animationDuration: 100,
      // animationDurationUpdate: 5000,
      // animationEasingUpdate: 'cubicOut',
      outline: {
        show: false
      }
    },
    {
      type: 'pie',
      center: frontDict.piePosition,
      radius: frontDict.secondPieWidth,
      hoverAnimation: false,
      data: [{
        name: '',
        value: 500,
        labelLine: {
          show: false
        },
        itemStyle: {
          color: frontDict.lineColor
        },
        emphasis: {
          labelLine: {
            show: false
          },
          itemStyle: {
            color: frontDict.lineColor
          }
        }
      }
      ]
    }

    ]
  }

  const startPlay = commonFunc(myChart, option, props)

  return startPlay
};

export default chartFunc // 修改为Classname
