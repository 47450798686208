import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function BoxplotEcharts (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const myChart = echarts.init(div[0][0].children[0], props.data[0].color_echarts_theme)
  // eushow 3.0
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const boxData = props.data[0].boxData
  const outliers = props.data[0].outliers
  const legend = props.data[0].legend

  const option = {
    // title: [
    //   {
    //     // text: 'Michelson-Morley Experiment',
    //     left: 'center'
    //   },
    //   {
    //     text: 'upper: Q3 + 1.5 * IQR \nlower: Q1 - 1.5 * IQR',
    //     borderColor: '#fff',
    //     borderWidth: 0.5,
    //     textStyle: {
    //       fontSize: 10,
    //       color: '#fff'
    //     },
    //     left: '1%',
    //     top: '88%'
    //   }
    // ],
    tooltip: {
      trigger: 'item',
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      top: '5%',
      left: '5%',
      right: '5%',
      bottom: '5%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: legend,
      boundaryGap: true,
      nameGap: 5,
      splitArea: {
        show: false
      },
      axisLabel: {
        formatter: '{value}',
        textStyle: {
          fontSize: 10,
          color: '#fff'
        }
      },
      nameTextStyle: {
        fontSize: 10,
        color: 'rgb(195,195,195)'
      },
      axisLine: {
        lineStyle: {
          width: 1,
          color: '#fff'
        }
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      // name: 'km/s minus 299,000',
      splitArea: {
        show: false
      },
      axisLabel: {
        textStyle: {
          fontSize: 10,
          color: '#fff'
        }
      },
      splitLine: {
        show: true,
        width: 1,
        lineStyle: {
          width: 1,
          color: 'rgba(109,109, 109)'
        }

      }
    },
    series: [
      {
        name: 'boxplot',
        type: 'boxplot',
        data: boxData,
        tooltip: {
          formatter: function (param) {
            return [
              'Experiment ' + param.name + ': ',
              'upper: ' + param.data[5],
              'Q3: ' + param.data[4],
              'median: ' + param.data[3],
              'Q1: ' + param.data[2],
              'lower: ' + param.data[1]
            ].join('<br/>')
          }
        },
        itemStyle: {
          markPoint: {
            symbolSize: 5

          }
        },
        animationDuration: 2000
      },
      {
        name: 'outlier',
        type: 'scatter',
        data: outliers,
        animationDuration: 2000
      }
    ]
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  //  不能使用 setInterval 方法,本身就是一个递归方法
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  var startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default BoxplotEcharts
