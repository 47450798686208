import './PicCarouselEight.css'
import drawChart from './chart.js'

const intervalList = []

function PicCarouselEight (element, props) {
  // ----------------------------------
  //   todo 项目变量设置
  // ----------------------------------
  // ----------------------------------
  const contentWidth = props.data[0].parentWidth
  const contentHeight = props.data[0].parentHeight
  const delayTime = props.data[0].delayTime

  const backgroundColor = 'rgba(1,1,1,0)'

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const body = element.children[0]
  body.setAttribute('style', 'width: 100%;height: 100%; overflow: hidden; position: relative; background-color: ' + backgroundColor)
  // 初始化

  const createBody = function () {
    // 整个图表的div
    const originDiv = document.createElement('div')
    // originDiv.setAttribute('id', 'pic-box')
    originDiv.setAttribute('style', 'height:' + contentHeight + 'px; width: ' + contentWidth + 'px;')
    originDiv.setAttribute('class', 'piccarouselEight-box')
    body.appendChild(originDiv)

    //  轮播内容div
    const carouselContent = document.createElement('div')
    carouselContent.setAttribute('class', 'carouselEight-body')
    carouselContent.innerHTML = `  <div class="carouselEight-pic-move">
    <img id="move-pic" src="/static/img/chart-icon/boxIcon.png">
  </div>
  <div class="carouselEight-pic">
    <img class="carouselEight-pic-style" src="/static/img/chart-icon/boxIcon.png">
    <img class="carouselEight-pic-style" src="/static/img/chart-icon/boxIcon.png">
    <img class="carouselEight-pic-style" src="/static/img/chart-icon/boxIcon.png">
    <img class="carouselEight-pic-style" src="/static/img/chart-icon/boxIcon.png">
  </div>
  <div class="carouselEight-left-part">
    <div class="carouselEight-title">省科技成果</div>
    <div class="carouselEight-title">国内科技论文数量</div>
    <div class="carouselEight-title">省级科技奖励</div>
    <div class="carouselEight-title">专利拥有/授权量</div>
  </div>
  <div class="carouselEight-table-body">
  <div id="carouselEightId"></div>
  </div>`

    originDiv.appendChild(carouselContent)
    return originDiv
  }

  createBody()

  // 清空已经存在的定时器
  for (let i = 0; i < intervalList.length; i++) {
    clearInterval(intervalList[i])
  }

  //  添加轮博动画
  var moveIndex = 1
  let preIndex = 0
  var moveToD = true
  const move = function () {
    // 判断当前定时器的数目，如果超过1个，就清除多余的定时器; 每次保留最新的定时器
    if (intervalList.length > 1) {
      console.log('intervals ', intervalList)
      for (let i = 0; i < intervalList.length - 1; i++) {
        clearInterval(intervalList[i])
      }
    }

    var targetDom = document.getElementsByClassName('carouselEight-pic-move')[0]
    const targetTextDomList = document.getElementsByClassName('carouselEight-title')

    // 修改文本的颜色
    const preTargetText = targetTextDomList[preIndex]
    preTargetText.setAttribute('style', 'color: rgba(255, 255, 255, 0.3)')

    if (moveIndex > 3) {
      moveIndex -= 2
      moveToD = !moveToD
    }
    if (moveIndex === 0) {
      moveToD = !moveToD
    }

    const moveDwon = 77 * moveIndex
    targetDom.setAttribute('style', 'top: ' + moveDwon + 'px')
    const nextTargetText = targetTextDomList[moveIndex]
    nextTargetText.setAttribute('style', 'color: #FFFFFF')

    // 添加echarts图表
    const echartDom = document.getElementById('carouselEightId')
    drawChart(echartDom, moveIndex)

    preIndex = moveIndex

    if (moveToD) {
      moveIndex += 1
    } else {
      moveIndex -= 1
    }
  }
  move()
  const moveLoop = setInterval(move, delayTime)
  intervalList.push(moveLoop)

  const Play = function () {

    // mySwiper()
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default PicCarouselEight // 修改为Classname
