import Vue from 'vue'
import filterChart from './filterChart'
import nameGroup from './nameGroup'

function BaseFilter (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const baseDom = element.children[0]

  // eslint-disable-next-line no-unused-vars
  const baseDom1 = new Vue({
    el: baseDom,
    extends: filterChart,
    data () {
      return {
        options: nameGroup,
        value: '',
        onInteractFunc: props.onInteract
      }
    },
    props: {}
  })
}

export default BaseFilter // 修改为Classname
