import './PicCarouselFive.css'
import Swiper from 'swiper'

function PicCarouselFive (element, props) {
  // console.log(props)
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  // eushow 3.0
  // 预留的div  let body = div[0][0].children[0];
  // ----------------------------------
  //   todo 项目变量设置
  // ----------------------------------
  console.log('piccrousel five', props.data)
  const contentWidth = props.data[0].parentWidth
  const contentHeight = props.data[0].parentHeight
  const delayTime = props.data[0].delayTime
  const speedTime = props.data[0].speedTime

  const htmlData = [
    '<div class="table-body">\n' +
    '    <div class="table-title">\n' +
    '      <span>2017年数据对比</span>\n' +
    '    </div>\n' +
    '    <div class="tbody-style">\n' +
    '      <div class="first-row row-style">\n' +
    '        <div class="cell-border" width="120" align="center">科技成果类型</div>\n' +
    '        <div class="cell-border" width="130" align="center">企业</div>\n' +
    '        <div class="cell-border" width="150" align="center">高校</div>\n' +
    '        <div class="cell-border" width="100" align="center">研究机构</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">申请专利（件）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">22610</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">10113</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">1123</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">专利授权（件）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">5761</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">4228</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">74</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">省级农业植物新品种（个）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">20</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">14</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">17</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">国际论文（篇）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">147</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">28040</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">1923</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">国内论文（篇）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">1213</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">308</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">86</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">登记科技成果（项）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">1006</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">308</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">86</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">国家科技奖励（项）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">2</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">5</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">2</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">省级科技奖励（项）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">103</div>\n' +
    '        <div class="cell-border table-second-num table-second-num" width="150" align="center">110</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">39</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">技术市场交易（亿元）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">897</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">5123</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">3196</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>',
    // 第二年的数据
    '<div class="table-body">\n' +
    '    <div class="table-title">\n' +
    '      <span>2018年数据对比</span>\n' +
    '    </div>\n' +
    '    <div class="tbody-style">\n' +
    '      <div class="first-row row-style">\n' +
    '        <div class="cell-border" width="120" align="center">科技成果类型</div>\n' +
    '        <div class="cell-border" width="130" align="center">企业</div>\n' +
    '        <div class="cell-border" width="150" align="center">高校</div>\n' +
    '        <div class="cell-border" width="100" align="center">研究机构</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">申请专利（件）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">29349</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">11647</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">1808</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">专利授权（件）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">6245</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">4043</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">557</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">省级农业植物新品种（个）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">42</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">7</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">17</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">国际论文（篇）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">303</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">27281</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">2098</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">国内论文（篇）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">1133</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">20423</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">1725</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">登记科技成果（项）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">835</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">475</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">60</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">国家科技奖励（项）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">8</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">14</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">2</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">省级科技奖励（项）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">100</div>\n' +
    '        <div class="cell-border table-second-num table-second-num" width="150" align="center">113</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">41</div>\n' +
    '      </div>\n' +
    '      <div class="row-style">\n' +
    '        <div class="cell-border" width="120" align="center">技术市场交易（亿元）</div>\n' +
    '        <div class="cell-border table-first-num" width="130" align="center">1055.61</div>\n' +
    '        <div class="cell-border table-second-num" width="150" align="center">72.12</div>\n' +
    '        <div class="cell-border table-third-num" width="100" align="center">41.66</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>'
  ]

  const createContentList = function (parentDiv, data) {
    const len = data.length
    for (let i = 0; i < len; i++) {
      // console.log('target dict', targetDict)
      const oneContent = document.createElement('div')
      oneContent.setAttribute('class', 'swiper-slide')

      // 整体用于布局的div
      const layContent = document.createElement('div')
      layContent.setAttribute('class', 'slide-content')
      // layContent.setAttribute('style', 'margin-left:' + targetMarginLeft + 'px')
      oneContent.appendChild(layContent)

      layContent.innerHTML = data[i]

      parentDiv.append(oneContent)
    }
  }

  onload = function () {
    const body = element.children[0]
    body.setAttribute('style', 'width: 100%;height: 100%; overflow: hidden;')

    // 整个图表的div
    const originDiv = document.createElement('div')
    // originDiv.setAttribute('id', 'pic-box')
    originDiv.setAttribute('style', 'height:' + contentHeight + 'px; width: ' + contentWidth + 'px; background-color: rgba(255, 255, 255, 0)')
    originDiv.setAttribute('class', 'swiper-container pic-box')
    body.appendChild(originDiv)

    //  轮播内容div
    const carouselContent = document.createElement('div')
    carouselContent.setAttribute('class', 'swiper-wrapper')
    originDiv.appendChild(carouselContent)

    createContentList(carouselContent, htmlData)

    return originDiv
  }
  const originDiv = onload()
  // eslint-disable-next-line no-new
  new Swiper(originDiv, {
    speed: speedTime,
    autoplay: {
      disableOnInteraction: true,
      // 每次切换的时间间隔
      delay: delayTime
    }, // 可选选项，自动滑动
    direction: 'horizontal',

    // 设置slide 的宽度
    // width: 600,
    // autoHeight: true,
    setWrapperSize: true,
    // 设置前进 or 后退的按钮
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
    //  开启循环放到最后面
    loop: true
  })

  const Play = function () {

    // mySwiper()
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default PicCarouselFive // 修改为Classname
