import { setXaxisDefault, setYaxisDefault } from '../../echartSetting/axis'
import { numFormatter } from '../chartCommonPart'
const chart2 = function (params) {
  const originData = params.content_data[0]
  const xAxisData = params.xAxisData
  const lineColor = params.lineColor
  const barWidth = params.barWidth
  // const yAxisMaxValue = params.yMaxValueList

  const axisFontSize = params.axisFontSize
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const yAxisUnit = params.yAxisUnit || params.y_axis_unit

  const maxValue = Math.max(...originData)

  const outerBarData = []
  for (let i = 0; i < originData.length; i++) {
    let item
    if (originData[i] === maxValue) {
      item = {
        value: originData[i] * 0.5,
        name: xAxisData[i],
        itemStyle: {
          color: 'rgba(68, 197, 255, 0.4)',
          barBorderRadius: params.barBorderRadius,
          borderColor: params.gradientColorStart
        }
      }
    } else {
      item = {
        value: originData[i],
        name: xAxisData[i],
        itemStyle: {
          color: 'rgba(68, 197, 255, 0)',
          barBorderRadius: [0, 0, 0, 0]
        }
      }
    }
    outerBarData.push(item)
  }

  const xAxisBase = setXaxisDefault(params)
  // xAxisBase[0].axisLabel.formatter = function (params) {
  //   return `{a|${params}}`
  // }
  xAxisBase[0].axisLabel.borderColor = 'rgba(255, 255, 255, 0.6)'
  xAxisBase[0].axisLabel.borderWidth = 1
  xAxisBase[0].axisLabel.padding = [6, 4, 4, 4]

  const option = {
    backgroundColor: params.backgroundColor,
    xAxis: xAxisBase,
    yAxis: setYaxisDefault(params),
    series: [
      {
        // 外部的单线框
        name: '外框',
        type: 'bar',
        // barGap: '-200%', // 设置外框间距
        data: originData,
        // 设置外边框的宽度
        barWidth: barWidth,
        itemStyle: {
          normal: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [{
                offset: 1, color: params.gradientColorStart // 0% 处的颜色
              }, {
                offset: 0, color: params.gradientColorEnd // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }, // 填充色
            barBorderColor: lineColor, // 边框色
            barBorderWidth: 1, // 边框宽度
            // barBorderRadius: 0, //圆角半径
            label: {
              // 标签显示位置
              show: true,
              position: 'top', // insideTop 或者横向的 insideLeft,
              distance: 4,
              formatter: function (params) {
                let value = params.data
                // console.log(name)
                if (value === maxValue) {
                  value = numFormatter(value)
                  return `{c|${value}} {b|${yAxisUnit}}`
                } else {
                  value = numFormatter(value)
                  return `{a|${value}} {b|${yAxisUnit}}`
                }
              },
              rich: {
                a: {
                  fontSize: params.barLabelFontSize,
                  color: params.barLabelColor,
                  fontFamily: 'SonyEricssonLogo'
                },
                c: {
                  fontSize: params.barLabelFontSize,
                  color: 'rgba(68, 197, 255, 1)',
                  fontFamily: 'SonyEricssonLogo'
                },
                b: {
                  fontSize: axisFontSize,
                  color: 'rgba(255, 255, 255, 0.61)',
                  fontFamily: 'PingFangRegular',
                  marginLeft: 4,
                  verticalAlign: 'center'
                }
              }
            }
          }
        },
        z: 0
      },
      {
        // 外部的单线框
        name: '外框',
        type: 'bar',
        barGap: '-100%', // 设置外框间距
        data: outerBarData,
        // 设置外边框的宽度
        barWidth: barWidth,
        z: 0
      }
    ]
  }
  return option
}

export default chart2
