import echarts from 'echarts'
import allChart from './all'

function MultiLine (element, props) {
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  // eushow 3.0
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const frontDict = props.data[0]
  const selectedChart = frontDict.barStandardSelect
  const option = allChart(selectedChart)(frontDict)

  // if (selectedChart === 'chart1') {
  //   option = allChart(frontDict.barStandardSelect)(frontDict, myChart)
  // } else {
  // // 使用上述配置显示图标 ， 保留不变
  //   option = allChart(frontDict.barStandardSelect)(frontDict)
  //   myChart.setOption(option)
  // }
  myChart.setOption(option)
  // 监听页面点击事件
  // myChart.on('click', function (params) {
  //   let gorupByValue = params.name
  //   props.onInteract(gorupByValue)
  // })
  // function Play () {
  //   myChart.clear()
  //   myChart.setOption(option)
  // }

  // const mode = props.mode
  // const autoPlay = props.autoPlay
  // // 记录Interval的ID值，用于前端清空
  // let startPlay = ''
  // // var intervalCount = []
  // if (mode === 'show' && autoPlay === true) {
  //   startPlay = setInterval(Play, 5000)
  //   // intervalCount.push(startPlay)
  //   // console.log('setInterval true :', startPlay, intervalCount)
  // }
  // // 返回当期 interval的ID， 没有时，返回空字符串
  // return startPlay
};

export default MultiLine // 修改为Classname
