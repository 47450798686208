// 引入图片
import earth from './data_gl/earth.jpg'
import bathymetryBwComposite4k from './data_gl/bathymetry_bw_composite_4k.jpg'
import starfield from './data_gl/starfield.jpg'
import night from './data_gl/night.jpg'
import clouds from './data_gl/clouds.png'

const chart1 = function (params) {
  const option = {
    backgroundColor: '#000',
    globe: {
      baseTexture: earth,
      heightTexture: bathymetryBwComposite4k,

      displacementScale: 0.1,

      shading: 'lambert',

      environment: starfield,

      light: {
        ambient: {
          intensity: 0.1
        },
        main: {
          intensity: 1.5
        }
      },

      layers: [{
        type: 'blend',
        blendTo: 'emission',
        texture: night
      }, {
        type: 'overlay',
        texture: clouds,
        shading: 'lambert',
        distance: 5
      }]
    },
    series: []
  }

  return option
}

export default chart1
