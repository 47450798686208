const listData = [
  {
    title: '小黄车退押金套路多，困难重重',
    spread: '10,684',
    speed: 9
  },
  {
    title: '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款',
    spread: '111,902',
    speed: 13
  },
  {
    title: 'To小黄车退押金周期长pic1',
    spread: '4,149',
    speed: 1
  },
  {
    title: '退押金排队（系统）人数多',
    spread: '15,937',
    speed: 1
  },
  {
    title: 'ofo推出押金新政策，押金投资理财项目后可免押金',
    spread: '94,262',
    speed: 85
  },
  {
    title: '小黄车推出折扣商城，押金兑换金币购物',
    spread: '20,647',
    speed: 411
  }
]
export default listData
