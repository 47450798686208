const nameGroup = [{
  label: '默认',
  options: [{
    value: '0',
    label: '世界'
  }]
}, {
  label: '亚洲',
  options: [{
    value: '33',
    label: '中国'
  }, {
    value: '84',
    label: '韩国'
  }, {
    value: '72',
    label: '印度'
  }, {
    value: '80',
    label: '日本'
  }, {
    value: '94',
    label: '马来西亚'
  }, {
    value: '120',
    label: '菲律宾'
  }, {
    value: '74',
    label: '伊朗'
  }, {
    value: '73',
    label: '印度尼西亚'
  }, {
    value: '146',
    label: '泰国'
  }, {
    value: '115',
    label: '巴基斯坦'
  }]
}, {
  label: '欧洲',
  options: [{
    value: '78',
    label: '意大利'
  }, {
    value: '139',
    label: '西班牙'
  }, {
    value: '61',
    label: '德国'
  }, {
    value: '144',
    label: '瑞士'
  },
  // {
  //   value: '154',
  //   label: '英国'
  // },
  {
    value: '17',
    label: '比利时'
  }, {
    value: '10',
    label: '奥地利'
  }, {
    value: '122',
    label: '葡萄牙'
  }, {
    value: '113',
    label: '挪威'
  }, {
    value: '143',
    label: '瑞典'
  }, {
    value: '125',
    label: '俄罗斯'
  }, {
    // ====
    value: '124',
    label: '罗马尼亚'
  }, {
    // ====
    value: '76',
    label: '爱尔兰'
  }, {
    // ====
    value: '121',
    label: '波兰'
  }, {
    // ====
    value: '43',
    label: '捷克'
  }
  ]
}, {
  label: '北美洲',
  options: [{
    value: '156',
    label: '美国'
  }, {
    value: '29',
    label: '加拿大'
  }, {
    value: '99',
    label: '墨西哥'
  }]
}, {
  label: '南美洲',
  options: [{
    value: '22',
    label: '巴西'
  }, {
    value: '32',
    label: '智利'
  }, {
    value: '47',
    label: '厄瓜多尔'
  }, {
    value: '119',
    label: '秘鲁'
  }, {
    value: '34',
    label: '哥伦比亚'
  }, {
    value: '7',
    label: '阿根廷'
  }, {
    // =====
    value: '138',
    label: '南非'
  }]
}, {
  label: '大洋洲',
  options: [{
    value: '9',
    label: '澳大利亚'
  }]
}]

export default nameGroup
