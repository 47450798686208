/* eslint-disable no-unused-vars */
import echarts from 'echarts'
import 'echarts/map/js/china' // 需要引入echarts 的地图文件
import 'echarts/map/js/province/hubei'
import hubei from 'echarts/map/json/province/hubei.json'

function Map2dAndBar (element, props) {
  // 后端传入的数据，转化为 echarts自身定义的同类型， 不用改动echarts的输入数据类型，主要依靠后端代码处理数据格式
//   const cityData = props.data[0].city_data
//   const title = props.data[0].title
//   const yAxisUnit = props.data[0].y_axis_unit
//   const maxValue = props.data[0].max_value
//   const minValue = props.data[0].min_value
  const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const myChart = echarts.init(element.children[0], colorEchartsTheme)

  // 数据定义区
  var typeIndex = 1
  var option = null
  var data = []
  var geoCoordMap = {}
  var mapName = '湖北'
  var BarHeight = 120
  var Barwidth = 8
  // var mapName = 'hubei';

  //   var mapFeatures = echarts.getMap(mapName).geoJson.features;
  //  直接引入了湖北的js文件
  var mapFeatures = hubei.features
  // console.log('mapFeatures', mapFeatures);
  mapFeatures.forEach(function (v) {
    // 地区名称
    var name = v.properties.name
    // console.log('name', name);
    // 地区经纬度
    geoCoordMap[name] = v.properties.cp
    data.push({
      // 需要挨个处理各地区数据（不使用随机产生的数）：另外需要构造的数据是柱图数据
      name: name,
      value: [
        { name: '鲜花', value: Math.round(Math.random() * 100 + 10) },
        { name: '星星', value: Math.round(Math.random() * 100 + 10) },
        { name: '香蕉', value: Math.round(Math.random() * 100 + 10) },
        { name: '嫌弃', value: Math.round(Math.random() * 100 + 10) }
      ]
    })
  })

  /* resetPie */
  function resetPie (myChart, params, geoCoordMap, typeIndex) {
    var op = myChart.getOption()
    // eslint-disable-next-line no-unused-vars
    var ops = op.series
    var gd = option.grid

    myChart.setOption(op, true)
  }

  /* addPie */
  function addPie (chart, data) {
    var op = chart.getOption()
    var sd = option.series
    var gd = option.grid
    for (var i = 0; i < data.length; i++) {
      var randomValue = Math.round(Math.random() * 30)
      var radius = randomValue <= 10 ? 10 : randomValue
      var geoCoord = geoCoordMap[data[i].name]
      var barheight = Math.round(Math.random() * BarHeight + 20)
      if (geoCoord) {
        var vr = [];
        (data[i].value).map(function (v, j) {
          vr.push({ name: v.name, value: v.value })
        })
        var p = chart.convertToPixel({ seriesIndex: 0 }, geoCoord)

        gd.push({
          width: Barwidth,
          height: barheight,
          left: p[0] - Barwidth,
          top: p[1] - barheight - 15,
          z: 5

        })
        option.yAxis.push({
          gridIndex: i,
          type: 'value',
          show: false,
          axisLabel: { show: false },
          z: 5
        })
        option.xAxis.push({
          type: 'category',
          data: ['1', '2'],
          show: false,
          gridIndex: i
        })
        if (barheight > 100) {
          sd.push(
            {
              name: '年报上报率2',
              type: 'pictorialBar',
              yAxisIndex: i,
              xAxisIndex: i,
              // 宽度=bar宽度， 高度为1/2宽度
              symbolSize: [Barwidth, Barwidth * 0.6],
              //   偏移： 0， 上面的一般
              symbolOffset: [0, Barwidth * 0.3],
              z: 12,
              itemStyle: {
                normal: {
                  color: 'rgba(239,156,77,.8)'
                }
              },
              data: [barheight]
            },
            //  底部的圆圈
            {
              name: '年报上报率1',
              type: 'pictorialBar',
              yAxisIndex: i,
              xAxisIndex: i,
              symbolSize: [Barwidth * 1.3, Barwidth * 0.8],
              symbolOffset: [0, Barwidth * 0.4],
              z: 11,
              itemStyle: {
                normal: {
                  color: 'rgba(239,156,77, .7)',
                  borderColor: 'rgba(239,156,77,1)',
                  borderWidth: 3
                }
              },
              data: [barheight]
            },
            {
              //  外侧的断点线条
              name: '年报上报率',
              type: 'pictorialBar',
              yAxisIndex: i,
              xAxisIndex: i,
              // bar宽度的2倍
              symbolSize: [Barwidth * 1.8, Barwidth * 1],
              symbolOffset: [0, Barwidth * 0.5],
              z: 10,
              itemStyle: {
                normal: {
                  color: 'transparent',
                  borderColor: 'rgba(239,156,77,1)',
                  borderType: 'dashed',
                  borderWidth: 2
                }
              },
              data: [barheight]
            },
            {
              //  外侧的断点线条
              name: '年报上报率',
              type: 'pictorialBar',
              yAxisIndex: i,
              xAxisIndex: i,
              // bar宽度的2倍
              symbolSize: [Barwidth * 2.5, Barwidth * 1.25],
              symbolOffset: [0, Barwidth * 0.62],
              z: 10,
              itemStyle: {
                normal: {
                  color: 'transparent',
                  borderColor: 'rgba(239,156,77,1)',
                  // borderType: 'dashed',
                  borderWidth: 1
                }
              },
              data: [barheight]
            },
            {
              type: 'bar',
              itemStyle: {
                normal: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0,
                      // 也可以考虑给透明色
                      color: 'rgb(239,156,77, 0.1)' // 0% 处的颜色
                    }, {
                      offset: 0.8,
                      color: 'rgba(239,156,77,0.8)' // 100% 处的颜色
                    }, {
                      offset: 1,
                      color: 'rgba(239,156,77,1)' // 100% 处的颜色
                    }
                    ],
                    global: false // 缺省为 false
                  },
                  opacity: 1
                }
              },
              yAxisIndex: i,
              xAxisIndex: i,
              silent: true,
              // 柱子的宽度
              barWidth: Barwidth,
              barGap: '-100%', // Make series be overlap
              // 柱子的高度
              data: [barheight],
              // 设置图层的位置,保证不被其他图层遮挡
              z: 999
            }
          )
        } else if (barheight > 50 && barheight < 100) {
          sd.push(
            {
              name: '年报上报率2',
              type: 'pictorialBar',
              yAxisIndex: i,
              xAxisIndex: i,
              // 宽度=bar宽度， 高度为1/2宽度
              symbolSize: [Barwidth, Barwidth * 0.6],
              //   偏移： 0， 上面的一般
              symbolOffset: [0, Barwidth * 0.3],
              z: 12,
              itemStyle: {
                normal: {
                  color: 'rgba(59, 241, 250,.8)'
                }
              },
              data: [barheight]
            },
            //  底部的圆圈
            {
              name: '年报上报率1',
              type: 'pictorialBar',
              yAxisIndex: i,
              xAxisIndex: i,
              symbolSize: [Barwidth * 1.3, Barwidth * 0.8],
              symbolOffset: [0, Barwidth * 0.4],
              z: 11,
              itemStyle: {
                normal: {
                  color: 'rgba(59, 241, 250, .7)',
                  borderColor: 'rgba(59, 241, 250,1)',
                  borderWidth: 3
                }
              },
              data: [barheight]
            },
            {
              //  外侧的断点线条
              name: '年报上报率',
              type: 'pictorialBar',
              yAxisIndex: i,
              xAxisIndex: i,
              // bar宽度的2倍
              symbolSize: [Barwidth * 1.8, Barwidth * 1],
              symbolOffset: [0, Barwidth * 0.5],
              z: 10,
              itemStyle: {
                normal: {
                  color: 'transparent',
                  borderColor: 'rgba(59, 241, 250,1)',
                  borderType: 'dashed',
                  borderWidth: 2
                }
              },
              data: [barheight]
            },
            {
              type: 'bar',
              itemStyle: {
                normal: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0,
                      // 也可以考虑给透明色
                      color: 'rgb(59, 241, 250, 0.1)' // 0% 处的颜色
                    }, {
                      offset: 0.8,
                      color: 'rgba(59, 241, 250.8)' // 100% 处的颜色
                    }, {
                      offset: 1,
                      color: 'rgba(59, 241, 250,1)' // 100% 处的颜色
                    }
                    ],
                    global: false // 缺省为 false
                  },
                  opacity: 1
                }
              },
              yAxisIndex: i,
              xAxisIndex: i,
              silent: true,
              // 柱子的宽度
              barWidth: Barwidth,
              barGap: '-100%', // Make series be overlap
              // 柱子的高度
              data: [barheight],
              // 设置图层的位置,保证不被其他图层遮挡
              z: 999
            }
          )
        } else {
          sd.push(
            {
              name: '年报上报率2',
              type: 'pictorialBar',
              yAxisIndex: i,
              xAxisIndex: i,
              // 宽度=bar宽度， 高度为1/2宽度
              symbolSize: [Barwidth, Barwidth * 0.6],
              //   偏移： 0， 上面的一般
              symbolOffset: [0, Barwidth * 0.3],
              z: 12,
              itemStyle: {
                normal: {
                  color: 'rgba(201, 216, 254,.8)'
                }
              },
              data: [barheight]
            },
            //  底部的圆圈
            {
              name: '年报上报率1',
              type: 'pictorialBar',
              yAxisIndex: i,
              xAxisIndex: i,
              symbolSize: [Barwidth * 1.3, Barwidth * 0.8],
              symbolOffset: [0, Barwidth * 0.4],
              z: 11,
              itemStyle: {
                normal: {
                  color: 'rgba(201, 216, 254, .7)',
                  borderColor: 'rgba(201, 216, 254,1)',
                  borderWidth: 3
                }
              },
              data: [barheight]
            },
            {
              //  外侧的断点线条
              name: '年报上报率',
              type: 'pictorialBar',
              yAxisIndex: i,
              xAxisIndex: i,
              // bar宽度的2倍
              symbolSize: [Barwidth * 1.8, Barwidth * 1],
              symbolOffset: [0, Barwidth * 0.5],
              z: 10,
              itemStyle: {
                normal: {
                  color: 'transparent',
                  borderColor: 'rgba(201, 216, 254,1)',
                  borderType: 'dashed',
                  borderWidth: 2
                }
              },
              data: [barheight]
            },
            {
              type: 'bar',
              itemStyle: {
                normal: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0,
                      // 也可以考虑给透明色
                      color: 'rgba(201, 216, 254, 0.1)' // 0% 处的颜色
                    }, {
                      offset: 0.8,
                      color: 'rgba(201, 216, 254,0.8)' // 100% 处的颜色
                    }, {
                      offset: 1,
                      color: 'rgba(201, 216, 254,1)' // 100% 处的颜色
                    }
                    ],
                    global: false // 缺省为 false
                  },
                  opacity: 1
                }
              },
              yAxisIndex: i,
              xAxisIndex: i,
              silent: true,
              // 柱子的宽度
              barWidth: Barwidth,
              barGap: '-100%', // Make series be overlap
              // 柱子的高度
              data: [barheight],
              // 设置图层的位置,保证不被其他图层遮挡
              z: 999
            }
          )
        }
      }
    }
    return option
  }

  /* 指定图表的配置项和数据:pie */

  // eslint-disable-next-line no-redeclare
  var option = {
    // backgroundColor: "rgba(18,66,111, .9)",
    title: [
      {
        text: '技术合同成交金额(亿元)\n黄色: 金额>100\n青色: 100>金额>50\n灰色:金额<50',
        // borderColor: '#999',
        // borderWidth: 1,
        textStyle: {
          fontSize: 12,
          fontWeight: 'lighter',
          lineHeight: 20
        },
        left: '2%',
        top: '85%'
      }
    ],
    // legend:{
    //   data:['金额>100', '100>金额>50', '金额<50']
    // },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        if (params.value) {
          var NameObject = params.name
          // console.log(params);
          // console.log(name_object);
          // eslint-disable-next-line eqeqeq
          if (NameObject == '1') {
            // ProvinceName = MapData[name_object].name;
            return '技术合同交易金额' + ': ' + params.value
          }
          // console.log('params', params);
        }
      }
    },
    grid: [],
    yAxis: [],
    xAxis: [],
    visualMap: { // 颜色的设置  dataRange
      show: false,
      x: 'left',
      y: 'center',
      seriesIndex: [0],
      min: 70,
      max: 90,
      text: ['高', '低'], // 文本，默认为数值文本
      textStyle: {
        color: '#fff'
      },
      inRange: {
        color: [
          '#0070AB',
          '#005C86',
          '#0D3652',
          '#0070AB',
          '#0070AB',
          '#00688F',
          '#005C86',
          '#0070AB',
          '#005C86',
          '#005C86', // 黄冈
          '#005C86',
          '#005C86',
          '#0D3652',
          '#025E87',
          '#005D86', // 潜江市
          '#005D86',
          '#0D3853'
        ]
      }
    },
    geo: {
      map: '湖北',
      label: {
        show: false
      },
      itemStyle: {
        // shadowColor: '#686868',
        // shadowOffsetY: 12,
        // shadowOffsetX: 8
      }

    },
    series: [{
      name: 'chinaMap',
      type: 'map',
      mapType: mapName,
      roam: false,
      label: {
        show: true,
        color: '#fff'
      },
      itemStyle: {
        borderWidth: 1,
        borderColor: 'rgba(81, 255, 254)'

      },
      emphasis: {
        label: {
          show: false,
          color: '#fff'
        },
        itemStyle: {
          borderWidth: 2,
          borderType: 'solid',
          borderColor: 'rgba(81, 255, 254)',
          areaColor: '#0097DB'
        }
      },
      data: [
        {
          name: '武汉市',
          value: 71
        },
        {
          name: '黄石市',
          value: 72
        }, {
          name: '十堰市',
          value: 73
        }, {
          name: '宜昌市',
          value: 74
        }, {
          name: '襄阳市',
          value: 75
        },
        {
          name: '鄂州市',
          value: 76
        },
        {
          name: '荆门市',
          value: 77
        }, {
          name: '孝感市',
          value: 78
        }, {
          name: '荆州市',
          value: 79
        }, {
          name: '黄冈市',
          value: 80
        }, {
          name: '咸宁市',
          value: 81
        }, {
          name: '随州市',
          value: 82
        }, {
          name: '恩施土家族苗族自治州',
          value: 83
        }, {
          name: '仙桃市',
          value: 84
        }, {
          name: '潜江市',
          value: 85
        }, {
          name: '天门市',
          value: 86
        },
        {
          name: '神农架林区',
          value: 87
        }
      ],
      z: 2
    }]
  }

  if (option && typeof option === 'object') {
    myChart.setOption(option, true)
  }
  /* pie */
  addPie(myChart, data)
  /* bar */
  // addBar(myChart,data);
  //   console.log(option);
  myChart.setOption(option, true)

  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  /* 饼图跟着地图移动:pie */
  myChart.on('georoam', function (params) {
    resetPie(myChart, params, geoCoordMap, typeIndex)
  })
  myChart.on('datarangeselected', function (params) {
    resetPie(myChart, params, geoCoordMap, typeIndex)
  })
  window.addEventListener('resize', function () {
    myChart.resize()
    resetPie(myChart, 0, geoCoordMap)
  })

  // 使用上述配置显示图标 ， 保留不变
//   myChart.setOption(option)
};

export default Map2dAndBar // 修改为Classname
