<template>
  <div class="timetext">
    <span class="timetext-chart1" :style="newStyle">{{this.frontDict.targetTime}}</span>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  created () {
    console.log('2', this.frontDict)
  },
  computed: {
    update () {
      // 添加定时更新操作
      return ''
    },
    newStyle () {
      const size = this.frontDict.textFontSize
      console.log(this.frontDict.textFontSize)
      return {
        fontSize: size + 'px',
        color: this.frontDict.textColor,
        fontFamily: this.frontDict.digtalfontFamily,
        letterSpacing: this.frontDict.textGap + 'px'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
 </style>
