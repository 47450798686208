<!-- 首页-时间轴 -->
<template>
  <div class="chart_23">
    <vue-scroll :ops="scrollOptions">
      <div class="scroll_content">
        <div class="info_item" v-for="(item, index) in list" :key="index">
          <div class="item_header">{{ item.date }}</div>
          <div class="item_main">
            <p class="">{{ item.content }}</p>
          </div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import data from './data/02-gjsjx.json'

export default {
  data () {
    return {
      // 滚动条配置
      scrollOptions: {
        scrollPanel: {
          scrollingY: false
        },
        rail: {
          background: 'rgba(255, 255, 255, 0.2)',
          size: '4px',
          keepShow: false
        },
        bar: {
          keepShow: true,
          background: '#3E84A6',
          specifyBorderRadius: '6px',
          size: '6px'
        }
      },
      // 列表
      list: data
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart_23 {
    width: 700px;
    height: 170px;
    overflow: hidden;
    .scroll_content {
      display: flex;
      height: 150px;
      .info_item {
        position: relative;
        flex:0 0 230px;
        height: 100%;
        .item_header {
          display: flex;
          align-items: center;
          width: 100%;
          height: 30px;
          padding-left: 37px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 20px;
          color: #FFFFFF;
        }
        .item_main {
          width: 100%;
          height: 120px;
          padding: 10px 34px 10px 37px;
          border-top: 2px solid rgba(255, 255, 255, 0.3);
          & > p {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.8);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5
          }
        }
        &::before {
          content: "";
          position: absolute;
          top: 11px;
          left: 11px;
          display: block;
          width: 8px;
          height: 8px;
          background: #44C5FF;
          border-radius: 50%;
        }
        &::after {
          content: "";
          position: absolute;
          top: 19px;
          left: 14px;
          display: block;
          width: 1px;
          height: 100px;
          border: 1px dashed rgba(255, 255, 255, 0.4);
        }
      }
    }
  }
</style>
