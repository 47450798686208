<template>
  <div class="medical_chart1">
    <div class="header_box">
      <div class="header_top">
        <h1>心肌梗死</h1>
        <p>心脏搭桥手术已完成</p>
        <div class="days_box">
          <p>住院中-06天</p>
        </div>
      </div>
    </div>
    <div class="info_box">
      <h1>诊&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;断</h1>
      <p>过重的体力劳动，尤其是负重登楼，过度体育活动，连续紧张劳累等，都可使心脏负担加重，心肌需氧量突然增加，而冠心病患者的冠状动脉已发生硬化、狭窄，不能充分扩张而造成心肌缺血。剧烈体力负荷也可诱发斑块破裂，导致急性心肌梗死。</p>
    </div>
    <div class="right_top_box"></div>
    <div class="left_bottom_box"></div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
  .medical_chart1 {
    position: relative;
    width: 556px;
    height: 196px;
    background-color: rgba(15, 25, 37, 1);
    // background: linear-gradient(90deg, rgba(219, 246, 255, 0.1) 0%, rgba(219, 246, 255, 0.05) 100%);
    padding: 0 20px;
    .header_box {
      width: 100%;
      .header_top {
        display: flex;
        align-items: baseline;
        height: 64px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding: 13px 0 8px 0;
        & > h1 {
          font-size: 30px;
          font-weight: 500;
          line-height: 42px;
          color: #FFFFFF;
          margin-right: 20px;
        }
        & > p {
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          color: #7DFFA0;
        }
        .days_box {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 30px;
          background: linear-gradient(45deg, transparent 8px,rgba(255, 255, 255, 0.2) 0);
          & > p {
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
            color: #7DE0FF;
          }
        }
      }
    }
    .info_box {
      padding-top: 16px;
      & > h1 {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 10px;
      }
      & > p {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #FFFFFF;
      }
    }
    .right_top_box {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 6px;
      height: 4px;
      background: RGBA(137, 144, 154, 1);
      opacity: 1;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 1px;
        height: 40px;
        background: RGBA(137, 144, 154, 1);
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 40px;
        height: 1px;
        background: RGBA(137, 144, 154, 1);
      }
    }
    .left_bottom_box {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      width: 6px;
      height: 4px;
      background: RGBA(137, 144, 154, 1);
      opacity: 1;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 1px;
        height: 40px;
        background: RGBA(137, 144, 154, 1);
      }
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 40px;
        height: 1px;
        background: RGBA(137, 144, 154, 1);
      }
    }
  }
</style>
