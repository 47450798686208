<template>
  <div class="timetext">
    <p class="timetext-chart1" :style="newStyle">{{ currentDate | formatTime(fmt) }}</p>
  </div>
</template>

<script>
import { formatDate } from './com'
export default {
  data () {
    return {
      currentDate: new Date(),
      fmt: 'yyyy-MM-dd hh-mm-ss',
      timer: null
    }
  },
  computed: {
    // 文字样式
    newStyle () {
      return {
        fontSize: this.frontDict.textFontSize + 'px',
        color: this.frontDict.textColor,
        fontFamily: this.frontDict.digtalfontFamily,
        letterSpacing: this.frontDict.textGap + 'px'
      }
    },
    // 如果是当前时间
    isCurrentDate () {
      return this.frontDict.isCurrentDate
    }
  },
  filters: {
    // 格式化时间 dayjs是通过script引入的
    formatTime (value, fmt) {
      return formatDate(value, fmt)
    }
  },
  mounted () {
    // 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      // 修改数据date
      this.currentDate = new Date()
    }, 1000)
  },
  created () {
    console.log('time-chart6', this.frontDict)
  },
  beforeDestroy () {
    // 在Vue实例销毁前，清除我们的定时器
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
 </style>
