import { dataMap } from './data-source/chart-3'
const chart1 = function (params) {
  const originData = params.originData
  const legend = params.legend
  // 临时使用变量
  const targetData = legend[1]
  let dateToPeriod = dataMap[targetData]
  if (dateToPeriod === undefined) {
    dateToPeriod = dataMap.kunming
  }

  const xAxisData = params.xAxisData

  const lineWidth = params.lineWidth

  const series = []
  for (let i = 0; i < originData.length; i++) {
    series.push(
      {
        name: legend[i],
        type: 'line',
        // 线上的圆点
        symbolSize: 5,
        markPoint: {
          symbol: 'circle',
          symbolSize: 10,
          itemStyle: { color: 'rgba(254, 204, 81, 1)' },
          emphasis: {
            itemStyle: { color: 'rgba(254, 204, 81, 1)' }
          }
        },
        smooth: params.lineSmoothNumber,
        // stack: '总量',
        lineStyle: {
          color: params.textColorList[i],
          width: lineWidth
        },
        itemStyle: {
          // 设置数据点的颜色 与 标注原点的尺寸
          color: params.textColorList[i]
          // borderWidth: 10
        },
        data: originData[i]
      }
    )
  }

  const option = {
    tooltip: {
      // alwaysShowContent: true,
      padding: [12, 50, 12, 20],
      formatter: function (params, ticket, callback) {
        const name = params[0].seriesName
        const data = params[0].data
        const dataIndex = params[0].dataIndex
        const axisValue = params[0].axisValue
        const stageName = dateToPeriod[axisValue]
        console.log(stageName, targetData, params)
        console.log(data, dataIndex, axisValue)
        let res
        if (targetData !== 'shandong' && targetData !== 'kunming') {
          res = `          <div class="">
          <h1>${axisValue}</h1>
          <div class="">
            <span class="label">${name}</span>
            <span class="data">${data}</span>
          </div>
        </div>`
        } else {
          res = `          <div>
          <h1>${axisValue}</h1>
          <div style="color: rgba(159, 230, 184, 1); font-size: 14px; margin: 10px 0 16px 0;">${stageName}</div>
          <div style="display: flex; flex-direction: row; align-items: center; padding-right: 8px">
          <div style="width: 20px; height: 10px; background: rgba(67, 167, 253, 1)"></div>
          <span style="margin-left: 8px">${name}: </span>
            <span>${data}</span>
          </div>
        </div>`
        }
        return res
      },
      textStyle: {
        fontSize: 14,
        lineHeight: 28,
        height: 28
      },
      backgroundColor: 'rgba(56, 71, 96, 0.7)',
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.3)',
          width: 1,
          type: 'solid'
        }
      }
    },
    legend: {
      icon: 'rect',
      itemHeight: 10,
      itemWidth: 20,
      align: 'right',
      right: '7%',
      top: '5%',
      data: xAxisData
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [{
      data: xAxisData,
      silent: false,
      nameLocation: 'end',
      nameTextStyle: {
        fontSize: 10,
        color: 'rgb(195,195,195)',
        align: 'left'
      },
      nameGap: 5,
      splitLine: {
        show: false
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: params.areaColor
        }
      },
      axisTick: { show: false },
      axisLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.6)',
          lineWidth: 1
        }
      },
      axisLabel: {
        formatter: '{value} ',
        textStyle: {
          fontSize: 10,
          color: params.axisFontColor
        }
      }
    }, {
      // data: ['起始期', '起始期', '起始期', '起始期', '起始期', '起始期', '爆发期', '衰退期', '平息期'],
      data: xAxisData,
      category: 'value',
      showMinLabel: false,
      showMaxLabel: false,
      triggerEvent: false,
      axisPointer: { show: false },
      silent: false,
      nameLocation: 'end',
      axisTick: { show: false },
      nameGap: 5,
      splitLine: {
        show: true,
        interval: function (index, value) {
          const lastDate = xAxisData[index - 1]
          const lastCategory = dateToPeriod[lastDate]
          const currentCategory = dateToPeriod[value]
          // console.log('splitline interval', value, index)
          if (lastCategory === currentCategory) {
            // console.log('no show', currentCategory, lastCategory)
            return false
          } else {
            // console.log('show', currentCategory, lastCategory)
            return true
          }
        },
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.2)',
          lineWidth: 1
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.6)',
          lineWidth: 1
        }
      },
      axisLabel: {
        formatter: function (value, index) {
          const lastDate = xAxisData[index - 2]
          const lastCategory = dateToPeriod[lastDate]
          const currentCategory = dateToPeriod[value]
          // console.log(lastCategory, currentCategory)
          if (lastCategory !== currentCategory) {
            // console.log('show', currentCategory, lastCategory)
            return currentCategory
          } else {
            // console.log('no show', currentCategory, lastCategory)
            return ''
          }
        },
        textStyle: {
          fontSize: 12,
          color: 'rgba(1,1,1,0)'
        }
      }
    }],
    yAxis: {
      axisTick: { show: false },
      axisLine: {
        show: false
      },
      axisLabel: {
        formatter: '{value} ',
        textStyle: {
          fontSize: 10,
          color: '#fff'
        }
      },
      splitLine: {
        lineStyle: {
          width: 1,
          color: 'rgba(109,109, 109)'
        }
      }
    },
    series: series
  }

  return option
}

export default chart1
