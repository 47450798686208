const firstLink = [{ id: 0, source: '51', target: '38' },
  { id: 1, source: '24', target: '12' },
  { id: 2, source: '50', target: '33' },
  { id: 3, source: '27', target: '24' },
  { id: 4, source: '3', target: '35' },
  { id: 5, source: '10', target: '50' },
  { id: 6, source: '15', target: '34' },
  { id: 7, source: '12', target: '45' },
  { id: 8, source: '54', target: '1' },
  { id: 9, source: '21', target: '22' },
  { id: 10, source: '30', target: '28' },
  { id: 11, source: '9', target: '53' },
  { id: 12, source: '14', target: '48' },
  { id: 13, source: '47', target: '8' },
  { id: 14, source: '1', target: '3' },
  { id: 15, source: '46', target: '49' },
  { id: 16, source: '37', target: '23' },
  { id: 17, source: '19', target: '2' },
  { id: 18, source: '23', target: '13' },
  { id: 19, source: '17', target: '46' },
  { id: 20, source: '22', target: '39' },
  { id: 21, source: '32', target: '21' },
  { id: 22, source: '5', target: '30' },
  { id: 23, source: '16', target: '43' },
  { id: 24, source: '6', target: '26' },
  { id: 25, source: '26', target: '11' },
  { id: 26, source: '11', target: '20' },
  { id: 27, source: '31', target: '16' },
  { id: 28, source: '44', target: '15' },
  { id: 29, source: '29', target: '37' },
  { id: 30, source: '41', target: '5' },
  { id: 31, source: '33', target: '0' },
  { id: 32, source: '25', target: '40' },
  { id: 33, source: '38', target: '51' },
  { id: 34, source: '39', target: '7' },
  { id: 35, source: '48', target: '47' },
  { id: 36, source: '52', target: '41' },
  { id: 37, source: '55', target: '29' },
  { id: 38, source: '49', target: '55' },
  { id: 39, source: '20', target: '31' },
  { id: 40, source: '18', target: '19' },
  { id: 41, source: '45', target: '17' },
  { id: 42, source: '7', target: '6' },
  { id: 43, source: '35', target: '52' },
  { id: 44, source: '53', target: '32' },
  { id: 45, source: '40', target: '44' },
  { id: 46, source: '36', target: '9' },
  { id: 47, source: '42', target: '18' },
  { id: 48, source: '0', target: '42' },
  { id: 49, source: '2', target: '54' },
  { id: 50, source: '4', target: '36' },
  { id: 51, source: '13', target: '27' },
  { id: 52, source: '43', target: '4' },
  { id: 53, source: '34', target: '25' },
  { id: 54, source: '28', target: '14' },
  { id: 55, source: '8', target: '10' },
  { id: 56, source: '5', target: '16' },
  { id: 57, source: '15', target: '12' },
  { id: 58, source: '21', target: '12' },
  { id: 59, source: '32', target: '29' },
  { id: 60, source: '29', target: '44' },
  { id: 61, source: '28', target: '43' },
  { id: 62, source: '13', target: '44' },
  { id: 63, source: '52', target: '31' },
  { id: 64, source: '39', target: '44' },
  { id: 65, source: '27', target: '19' },
  { id: 66, source: '20', target: '36' },
  { id: 67, source: '16', target: '55' },
  { id: 68, source: '55', target: '34' },
  { id: 69, source: '52', target: '30' },
  { id: 70, source: '22', target: '24' },
  { id: 71, source: '44', target: '52' }]

const secondLinks = [{ id: 0, source: '32', target: '92' },
  { id: 1, source: '1', target: '27' },
  { id: 2, source: '51', target: '32' },
  { id: 3, source: '36', target: '58' },
  { id: 4, source: '67', target: '7' },
  { id: 5, source: '57', target: '29' },
  { id: 6, source: '58', target: '88' },
  { id: 7, source: '59', target: '47' },
  { id: 8, source: '79', target: '37' },
  { id: 9, source: '28', target: '47' },
  { id: 10, source: '65', target: '48' },
  { id: 11, source: '19', target: '78' },
  { id: 12, source: '13', target: '35' },
  { id: 13, source: '71', target: '72' },
  { id: 14, source: '18', target: '42' },
  { id: 15, source: '47', target: '52' },
  { id: 16, source: '5', target: '1' },
  { id: 17, source: '80', target: '41' },
  { id: 18, source: '63', target: '73' },
  { id: 19, source: '42', target: '40' },
  { id: 20, source: '30', target: '90' },
  { id: 21, source: '81', target: '75' },
  { id: 22, source: '39', target: '67' },
  { id: 23, source: '31', target: '99' },
  { id: 24, source: '20', target: '84' },
  { id: 25, source: '99', target: '10' },
  { id: 26, source: '85', target: '43' },
  { id: 27, source: '70', target: '74' },
  { id: 28, source: '74', target: '33' },
  { id: 29, source: '15', target: '56' },
  { id: 30, source: '91', target: '19' },
  { id: 31, source: '64', target: '93' },
  { id: 32, source: '40', target: '57' },
  { id: 33, source: '16', target: '77' },
  { id: 34, source: '37', target: '66' },
  { id: 35, source: '93', target: '44' },
  { id: 36, source: '92', target: '8' },
  { id: 37, source: '52', target: '81' },
  { id: 38, source: '10', target: '85' },
  { id: 39, source: '89', target: '49' },
  { id: 40, source: '46', target: '63' },
  { id: 41, source: '95', target: '82' },
  { id: 42, source: '88', target: '45' },
  { id: 43, source: '62', target: '2' },
  { id: 44, source: '8', target: '3' },
  { id: 45, source: '68', target: '30' },
  { id: 46, source: '48', target: '69' },
  { id: 47, source: '35', target: '83' },
  { id: 48, source: '86', target: '11' },
  { id: 49, source: '56', target: '12' },
  { id: 50, source: '3', target: '76' },
  { id: 51, source: '96', target: '16' },
  { id: 52, source: '9', target: '55' },
  { id: 53, source: '53', target: '86' },
  { id: 54, source: '87', target: '28' },
  { id: 55, source: '41', target: '15' },
  { id: 56, source: '90', target: '61' },
  { id: 57, source: '77', target: '34' },
  { id: 58, source: '50', target: '23' },
  { id: 59, source: '49', target: '68' },
  { id: 60, source: '11', target: '89' },
  { id: 61, source: '75', target: '91' },
  { id: 62, source: '82', target: '79' },
  { id: 63, source: '83', target: '13' },
  { id: 64, source: '26', target: '46' },
  { id: 65, source: '22', target: '39' },
  { id: 66, source: '72', target: '9' },
  { id: 67, source: '84', target: '17' },
  { id: 68, source: '14', target: '95' },
  { id: 69, source: '34', target: '4' },
  { id: 70, source: '17', target: '60' },
  { id: 71, source: '73', target: '97' },
  { id: 72, source: '2', target: '18' },
  { id: 73, source: '25', target: '62' },
  { id: 74, source: '21', target: '70' },
  { id: 75, source: '61', target: '64' },
  { id: 76, source: '98', target: '0' },
  { id: 77, source: '69', target: '50' },
  { id: 78, source: '60', target: '65' },
  { id: 79, source: '23', target: '21' },
  { id: 80, source: '29', target: '94' },
  { id: 81, source: '44', target: '31' },
  { id: 82, source: '4', target: '71' },
  { id: 83, source: '33', target: '22' },
  { id: 84, source: '97', target: '53' },
  { id: 85, source: '55', target: '36' },
  { id: 86, source: '66', target: '38' },
  { id: 87, source: '27', target: '54' },
  { id: 88, source: '43', target: '20' },
  { id: 89, source: '38', target: '26' },
  { id: 90, source: '76', target: '80' },
  { id: 91, source: '45', target: '87' },
  { id: 92, source: '78', target: '5' },
  { id: 93, source: '24', target: '98' },
  { id: 94, source: '12', target: '25' },
  { id: 95, source: '6', target: '24' },
  { id: 96, source: '0', target: '51' },
  { id: 97, source: '54', target: '96' },
  { id: 98, source: '7', target: '6' },
  { id: 99, source: '94', target: '14' },
  { id: 100, source: '22', target: '42' },
  { id: 101, source: '52', target: '13' },
  { id: 102, source: '76', target: '93' },
  { id: 103, source: '83', target: '32' },
  { id: 104, source: '60', target: '33' },
  { id: 105, source: '81', target: '53' },
  { id: 106, source: '28', target: '31' },
  { id: 107, source: '95', target: '20' },
  { id: 108, source: '3', target: '20' },
  { id: 109, source: '2', target: '65' },
  { id: 110, source: '40', target: '42' },
  { id: 111, source: '7', target: '50' },
  { id: 112, source: '54', target: '57' },
  { id: 113, source: '87', target: '66' },
  { id: 114, source: '92', target: '72' },
  { id: 115, source: '30', target: '88' },
  { id: 116, source: '39', target: '94' },
  { id: 117, source: '23', target: '62' },
  { id: 118, source: '77', target: '16' },
  { id: 119, source: '79', target: '6' },
  { id: 120, source: '10', target: '80' },
  { id: 121, source: '49', target: '99' },
  { id: 122, source: '17', target: '85' },
  { id: 123, source: '23', target: '89' },
  { id: 124, source: '41', target: '58' },
  { id: 125, source: '2', target: '32' },
  { id: 126, source: '50', target: '77' },
  { id: 127, source: '19', target: '53' },
  { id: 128, source: '26', target: '99' },
  { id: 129, source: '26', target: '28' }]

const links = {
  topicFirst: firstLink,
  topicSecond: secondLinks
}

export { links }
