import echarts from 'echarts'
import { hideDelayTime, trigger, commonFunc } from '../chartCommonPart'

function AreaRadar (element, props) {
  const originData = props.data[0].originData
  const indicator = props.data[0].indicator
  const lineColor = props.data[0].lineColor
  const areaColor = props.data[0].areaColor
  const lineWidth = props.data[0].lineWidth
  const axisFontSize = props.data[0].axisFontSize
  const XlabelToAxis = props.data[0].XlabelToAxis

  const axisLineColor = props.data[0].axisLineColor
  const textColor = props.data[0].textColor
  const axisLineWidth = props.data[0].axisLineWidth

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  // 初始化
  const myChart = echarts.init(element.children[0], props.colorTheme)
  //   const myChart = echarts.init(div[0][0].children[0])

  const option = {
    backgroundColor: 'rgba(0,0,0,0)',
    grid: {
      containLabel: true,
      left: '3%',
      right: '3%',
      top: '5%',
      bottom: '3%'
    },
    tooltip: {
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      show: true
    },
    legend: {
      show: false
    },
    radar:
      {
        indicator: indicator,
        radius: '60%',
        center: ['50%', '50%'],
        startAngle: 0,
        name: {
          // 文本标签
          show: true,
          color: textColor,
          fontSize: axisFontSize
        },
        nameGap: XlabelToAxis,
        axisLine: {
          // 连接中心点的线
          show: false
        },
        splitArea: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            width: axisLineWidth,
            color: axisLineColor
          }
        }
      },
    series:
      {
        type: 'radar',
        //   itemStyle: {normal: {areaStyle: {type: 'default'}}},
        // itemStyle:{
        //     //   内部边线的颜色
        //     color:'rgba(241,157,68,0.5)',
        //     borderColor:'#f19d44',
        // },
        areaStyle: {
          color: areaColor,
          opacity: 1
        },
        lineStyle: {
          color: lineColor,
          width: lineWidth
        },
        data: originData
      }
  }

  const startPlay = commonFunc(myChart, option, props)

  return startPlay
};

export default AreaRadar // 修改为Classname
