<template>
  <div class="htmlchartset1-4">
    <el-select class="my-lib-el-select transparency" v-model="value" placeholder="请选择" @change="selectChange">
      <el-option
        v-for="item in getOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <div ref="chartDom" :style="chartBox"></div>
  </div>
</template>
<script>
import echarts from 'echarts'
import { chart5 } from './chart/chart5'
export default {
  data () {
    return {}
  },
  mounted () {
    this.initChart()
  },
  methods: {
    initChart (category) {
      if (category === undefined) {
        category = this.frontDict.options[0].value
      }
      this.frontDict.category = category
      const option = chart5(this.frontDict)
      const targetDom = this.$refs.chartDom
      if (targetDom) {
        const myChart = echarts.init(targetDom)
        myChart.setOption(option)
      }
    },
    selectChange (value) {
      this.initChart(value)
    }
  },
  computed: {
    getOptions () {
      const options = this.frontDict.options
      return options
    },
    chartBox () {
      const style = {
        width: this.frontDict.parentWidth + 'px',
        height: this.frontDict.parentHeight + 'px'
      }
      return style
    }
  }
}
</script>
<style lang="scss" scoped></style>

// 必须添加顶层类,再在新建的scss内嵌套修改element样式
<style lang="scss">
.htmlchartset1-4 {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 10px;
}
</style>
