import { numFormatter } from '../chartCommonPart'
import { maxIconColor, maxBarColor } from './com'
const chart2 = function (params) {
  const originData = params.content_data[0]
  const xAxisData = params.xAxisData
  const axisFontColor = params.axisFontColor
  const lineColor = params.lineColor
  const barWidth = params.barWidth
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const yAxisMaxValue = params.yMaxValueList || params.y_axis_max_value

  const axisFontSize = params.axisFontSize
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const yAxisUnit = params.yAxisUnit || params.y_axis_unit

  const maxValue = Math.max(...originData)
  const outerBarData = []
  for (let i = 0; i < xAxisData.length; i++) {
    outerBarData.push(yAxisMaxValue[0])
  }
  const picData = maxIconColor(originData, maxValue)
  const barData = maxBarColor(originData, maxValue)
  const option = {
    backgroundColor: params.backgroundColor,
    xAxis: {
      max: yAxisMaxValue[0],
      splitLine: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisTick: {
        show: false
      }
    },
    grid: {
      left: 30,
      top: 20, // 设置条形图的边距
      right: 180,
      bottom: 20,
      containLabel: true
    },
    yAxis: [{
      type: 'category',
      inverse: false,
      data: xAxisData,
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: true,
        margin: params.YlabelToAxis,
        color: axisFontColor,
        fontFamily: 'PingFangRegular',
        fontSize: axisFontSize
      },
      splitLine: {
        show: false
      }
    }],
    series: [
      {
        // 柱状图中间的重复图标
        type: 'pictorialBar',
        // itemStyle: {
        //   color: 'rgba(255, 255, 255, 1)'
        // },
        emphasis: {
          show: false,
          // 取消鼠标高亮效果
          itemStyle: {
            color: '#FFA200'
          }
        },
        symbol: 'triangle',
        symbolSize: [12, 6], // 单个图标的长宽
        symbolPosition: 'end', // 图标的位置
        symbolOffset: [params.iconLeftGap, 0], // 图标的位置偏移
        symbolRotate: -90,
        data: picData,
        animationEasing: 'elasticOut',
        label: {
          show: false
        },
        z: 50
      },
      {
        // 外部的单线框
        name: '外框',
        type: 'bar',
        barGap: '-120%', // 设置外框间距
        data: barData,
        // 设置外边框的宽度
        barWidth: barWidth,
        itemStyle: {
          normal: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [{
                offset: 1, color: params.gradientColorStart // 0% 处的颜色
              }, {
                offset: 0, color: params.gradientColorEnd // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }, // 填充色
            barBorderColor: lineColor, // 边框色
            barBorderWidth: 1, // 边框宽度
            // barBorderRadius: 0, //圆角半径
            label: {
              // 标签显示位置
              show: true,
              position: 'right', // insideTop 或者横向的 insideLeft,
              distance: 20,
              formatter: function (params) {
                let value = params.value
                if (value === maxValue) {
                  value = numFormatter(value)
                  return `{c|${value}} {b|${yAxisUnit}}`
                } else {
                  value = numFormatter(value)
                  return `{a|${value}} {b|${yAxisUnit}}`
                }
              },
              rich: {
                a: {
                  fontSize: params.barLabelFontSize,
                  color: params.barLabelColor,
                  fontFamily: 'SonyEricssonLogo'
                },
                c: {
                  fontSize: params.barLabelFontSize,
                  color: 'rgba(55, 255, 155, 1)',
                  fontFamily: 'SonyEricssonLogo'
                },
                b: {
                  fontSize: axisFontSize,
                  color: 'rgba(255, 255, 255, 0.61)',
                  fontFamily: 'PingFangRegular',
                  marginLeft: 4,
                  verticalAlign: 'center'
                }
              }
            }
          }
        },
        z: 0
      }
    ]
  }
  return option
}

export default chart2
