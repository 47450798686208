import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function HorizontalDoubleBar (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const originData = props.data[0].originData
  const xAxisData = props.data[0].xAxisData
  const legend = props.data[0].legend
  const gradientColorStart = props.data[0].gradientColorStart
  const gradientColorEnd = props.data[0].gradientColorEnd
  const secondGradientColorStart = props.data[0].secondGradientColorStart
  const secondGradientColorEnd = props.data[0].secondGradientColorEnd
  const backgroundColor = 'rgba(0,0,0,0)'
  const textColor = props.data[0].textColor
  const barWidth = props.data[0].barWidth
  const barRadius = props.data[0].barRadius
  const isShowAxis = props.data[0].isShowAxis
  const YlabelToAxis = props.data[0].YlabelToAxis
  const axisFontSize = props.data[0].axisFontSize

  const eachBarTableWidth = props.data[0].eachBarTableWidth
  const secondBarTableToLeft = props.data[0].secondBarTableToLeft
  const tableToBorderDistance = props.data[0].tableToBorderDistance

  const firstBarColor = new echarts.graphic.LinearGradient(
    //  x, y, x2, y2
    0, 0, 1, 0,
    [{
      offset: 0,
      color: gradientColorEnd
    }, {
      offset: 1,
      color: gradientColorStart
    }])

  const secondBarColor = new echarts.graphic.LinearGradient(
    //  x, y, x2, y2
    0, 0, 1, 0,
    [{
      offset: 0,
      color: secondGradientColorStart
    }, {
      offset: 1,
      color: secondGradientColorEnd
    }])

  const series = []
  for (let i = 0; i < 2; i++) {
    const labelPosition = { 0: 'left', 1: 'right' }
    const barColor = { 0: firstBarColor, 1: secondBarColor }
    const labelColor = { 0: gradientColorEnd, 1: secondGradientColorEnd }
    series.push(
      {
        name: legend[i],
        type: 'bar',
        barWidth: barWidth,
        barMaxWidth: 50,
        xAxisIndex: i,
        yAxisIndex: i,
        gridIndex: i,
        itemStyle: {
          normal: {
            show: true,
            color: barColor[i],
            // 设置柱状图的圆角
            barBorderRadius: barRadius,
            borderWidth: 0,
            borderColor: '#333',
            // 设置柱子上显示的提示信息
            label: {
              show: true,
              color: labelColor[i],
              position: labelPosition[i],
              formatter: function (v) {
                return (v.value)
              }
            }
          }
        },
        data: originData[i]
      }
    )
  }

  // 是否显示坐标轴
  const notAxis = [{
    type: 'value',
    max: function (value) {
      return value.max * 1.1
    },
    position: 'top',
    gridIndex: 0,
    inverse: true,
    axisTick: {
      show: false
    },
    axisLine: {
      show: false
    },
    axisLabel: {
      show: false
    },
    splitLine: {
      show: false
    }
  }, {
    type: 'value',
    max: function (value) {
      return value.max * 1.1
    },
    position: 'top',
    gridIndex: 1,
    axisTick: {
      show: false
    },
    axisLine: {
      show: false
    },
    axisLabel: {
      show: false
    },
    splitLine: {
      show: false
    }
  }]
  const xAxis = [
    {
      type: 'value',
      // min: -100,
      max: function (value) {
        return value.max * 1.1
      },
      position: 'top',
      gridIndex: 0,
      // 反向坐标轴
      inverse: true,
      axisTick: {
        show: false
      },
      axisLabel: {
        // X轴的上部坐标轴的刻度
        show: true,
        formatter: function (v) {
          return (v)
        }
      },
      axisLine: {
        lineStyle: {
          width: 1,
          color: 'rgba(12,12,12,0.6)'
        },
        show: true
      },
      splitLine: {
        lineStyle: {
          width: 1,
          color: 'rgba(197,197,197,0.6)'
        },
        show: true
      }
    },
    {
      type: 'value',
      gridIndex: 1,
      max: function (value) {
        return value.max * 1.1
      },
      position: 'top',
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          width: 1,
          color: 'rgba(12,12,12,0.6)'
        },
        show: true
      },
      axisLabel: {
        show: true
      },
      splitLine: {
        lineStyle: {
          width: 1,
          color: 'rgba(197,197,197,0.6)'
        },
        show: true
      }
    }]

  let finalXAxis = []
  if (isShowAxis === true) {
    finalXAxis = xAxis
  } else {
    finalXAxis = notAxis
  }

  const option = {
    backgroundColor: backgroundColor,
    legend: {
      show: false,
      top: 10,
      left: '43%',
      itemHeight: 10,
      itemWidth: 10,
      textStyle: {
        fontSize: 10
      },
      data: legend
    },
    tooltip: {
      trigger: 'axis',
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (a) {
        var v = a[0]
        return v.name + '<br/>' + v.marker + v.seriesName + '：' + Math.abs(v.value)
      }
    },
    xAxis: finalXAxis,
    yAxis: [{
      type: 'category',
      gridIndex: 0,
      inverse: true,
      data: xAxisData,
      splitLine: { show: false },
      axisTick: {
        show: false
      },
      axisLabel: {
        // y轴的刻度值设置
        show: false
      },
      axisLine: {
        show: false
      }
    }, {
      type: 'category',
      gridIndex: 1,
      inverse: true,
      data: xAxisData,
      splitLine: { show: false },
      axisTick: {
        show: false
      },
      axisLabel: {
        fontSize: axisFontSize,
        margin: YlabelToAxis,
        color: textColor
      },
      axisLine: {
        show: false // 是否显示轴线
      }
    }],
    grid: [{
      top: 5,
      bottom: 5,
      width: eachBarTableWidth,
      // height: '80%',
      left: tableToBorderDistance[0],
      gridIndex: 0,
      containLabel: true
    }, {
      top: 5,
      bottom: 5,
      left: secondBarTableToLeft,
      width: eachBarTableWidth,
      // height: '80%',
      right: tableToBorderDistance[1],
      gridIndex: 1,
      containLabel: true
    }],
    // color: ['#2FACFA', '#F5A623'],
    series: series
  }

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })
  //  props返回的内容增加 mode[edit show]  autoplay[True false],只有当 show, True时, 才执行循环刷新的方法
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  var startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default HorizontalDoubleBar // 修改为Classname
