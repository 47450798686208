import { links } from '../chart-11/data-source/links'
const initChart = function ({ topics, category }) {
  const legendInfo = {
    topicFirst: {
      isShow: false,
      legend: ['潜伏期'],
      categories: [
        { name: '潜伏期', itemStyle: { color: 'rgba(55, 162, 218, 1)' } }
      ],
      selected: {
        潜伏期: true
      }
    },
    topicSecond: {
      isShow: true,
      legend: ['潜伏期', '爆发期', '蔓延期', '恢复期'],
      categories: [
        { name: '潜伏期', itemStyle: { color: 'rgba(55, 162, 218, 1)' } },
        { name: '爆发期', itemStyle: { color: 'rgba(60, 202, 236, 1)' } },
        { name: '蔓延期', itemStyle: { color: 'rgba(115, 228, 234, 1)' } },
        { name: '恢复期', itemStyle: { color: 'rgba(165, 233, 202, 1)' } }
      ],
      selected: {
        潜伏期: true,
        爆发期: true,
        蔓延期: true,
        恢复期: true
      }
    }
  }

  const targetLegend = legendInfo[category]
  const link = links[category]
  const option = {
    tooltip: {
      show: true,
      padding: [12, 50, 12, 20],
      textStyle: {
        fontSize: 14,
        lineHeight: 28,
        height: 28
      },
      formatter: function (params) {
        const targetType = params.dataType
        const res = ''
        if (targetType === 'node') {
          return `<span>${params.seriesName}</span><p>${params.name}: ${params.value}</p>`
        }
        return res
      },
      backgroundColor: 'rgba(56, 71, 96, 0.7)',
      confine: true
    },
    legend: {
      show: targetLegend.isShow,
      selectedModel: 'single',
      itemWidth: 30,
      itemHeight: 16,
      borderRadius: 2,
      bottom: 10,
      data: targetLegend.legend,
      textStyle: {
        color: 'rgba(255, 255, 255, 1)'
      },
      selected: targetLegend.selected
    },
    animationDuration: 1500,
    animationEasingUpdate: 'quinticInOut',
    series: [{
      name: '山东问题疫苗事件',
      type: 'graph',
      layout: 'circular',
      label: {
        show: false,
        color: 'rgba(0,0,0,0)'
      },
      lineStyle: {
        color: 'source',
        curveness: 0.2
      },
      categories: targetLegend.categories,
      // 接收传入进来的数据
      data: topics,
      // links: links
      links: link
    }]
  }
  return option
}

export default initChart
