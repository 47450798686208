const topicToLike = {
  长春长生疫苗造假事件: {
    like: 1.0,
    yn: false
  },
  谴责长春长生: {
    like: 0.070519559,
    yn: true
  },
  发票显示接种过长春长生造假批次疫苗: {
    like: 0.523616016,
    yn: false
  },
  国家药监局立案调查: {
    like: 0.076571636,
    yn: true
  },
  '长春长生疫苗毛利率超过茅台，质疑国有资产流失': {
    like: 0.200244397,
    yn: false
  },
  百白破生产车间停产整改: {
    like: 0.065098301,
    yn: true
  },
  长生长春公司就造假疫苗致歉: {
    like: 0.079812445,
    yn: true
  },
  造假疫苗去向拷问: {
    like: 0.071512178,
    yn: true
  },
  '公安机关立案调查；董事长被刑事拘留': {
    like: 0.061949667,
    yn: true
  },
  '武汉生物疫苗造假；疫苗事件涉及行贿': {
    like: 0.061949667,
    yn: true
  },
  国家药监局彻查疫苗造假案: {
    like: 0.276966065,
    yn: true
  },
  医药板块股票集体下跌: {
    like: 0.083306834,
    yn: true
  },
  长生生物董事长儿媳炫富: {
    like: 0.213927612,
    yn: false
  },
  山东疾控中心查明疫苗流向: {
    like: 0.405392736,
    yn: true
  },
  '长生生物股票跌停临时停牌，存在退市风险': {
    like: 0.186986013,
    yn: true
  },
  国务院调查组开展疫苗调查处理工作: {
    like: 0.125848472,
    yn: true
  },
  黑客攻击长生生物官网首页: {
    like: 0.033492252,
    yn: true
  },
  公安机关逮捕犯罪嫌疑人: {
    like: 0.035193384,
    yn: true
  },
  证监会修改上市公司退市制度: {
    like: 0.044070803,
    yn: true
  },
  山东省疾控中心官员自杀: {
    like: 0.072239131,
    yn: true
  }
}

const articleCount = { 长春长生疫苗造假事件: { count: 16709 }, 谴责长春长生: { count: 1491 }, '长春长生百白破、狂犬疫苗造假事件': { count: 6740 }, '疫苗生产记录造假，效价不达标': { count: 3743 }, 国家药监局立案调查: { count: 1681 }, '长春长生疫苗毛利率超过茅台，质疑国有资产流失': { count: 1095 }, 百白破生产车间停产整改: { count: 602 }, 发票显示接种过长春长生造假批次疫苗: { count: 652 }, 长生长春公司就造假疫苗致歉: { count: 303 }, 造假疫苗去向拷问: { count: 2663 }, 山东疾控中心查明疫苗流向: { count: 3923 }, 国家药监局通报疫苗案件情况: { count: 1680 }, '公安机关立案调查；董事长被刑事拘留': { count: 6436 }, 长生生物董事长儿媳炫富: { count: 6541 }, '长生生物股票跌停临时停牌，存在退市风险': { count: 3346 }, '武汉生物疫苗造假；疫苗事件涉及行贿': { count: 3573 }, 国家药监局彻查疫苗造假案: { count: 5672 }, 医药板块股票集体下跌: { count: 4327 }, 公司配合监管部门停产自查: { count: 3648 }, 微博上就长生生物退市发起投票: { count: 1058 }, 国务院调查组开展疫苗调查处理工作: { count: 860 }, 武汉生物百白破疫苗不合格: { count: 3763 }, 公安机关逮捕犯罪嫌疑人: { count: 729 }, 山东省疾控中心官员自杀: { count: 3684 }, 证监会修改上市公司退市制度: { count: 2430 }, 资金冻结且估值下调: { count: 8446 }, 黑客攻击长生生物官网首页: { count: 4129 }, 山东疾控涉嫌收受长春长生贿赂: { count: 1081 } }

export { topicToLike, articleCount }
