const nameToNo = {
  '小黄车退押金套路多，困难重重': 'Topic3',
  '小黄车被曝办公区人去楼空深陷欠款危机，多名用户现场排队退款': 'Topic5',
  小黄车退押金周期长: 'Topic1',
  '退押金排队（系统）人数多': 'Topic2',
  'ofo推出押金新政策，押金投资理财项目后可免押金': 'Topic4',
  '小黄车推出折扣商城，押金兑换金币购物': 'Topic6'
}
const spreads = {
  Topic3: {
    spread: '10,684',
    speed: 9
  },
  Topic5: {
    spread: '111,902',
    speed: 13
  },
  Topic1: {
    spread: '4,149',
    speed: 1
  },
  Topic2: {
    spread: '15,937',
    speed: 1
  },
  Topic4: {
    spread: '94,262',
    speed: 85
  },
  Topic6: {
    spread: '20,647',
    speed: 411
  }
}
export {
  nameToNo,
  spreads
}
