import './chart.scss'
const getOptions = function (params) {
  // 键值数组
  const names = params.map(item => {
    return item.name
  })
  // 系列数据
  const seriesData = params.map(item => {
    const tooltip = {
      position: [10, 10],
      formatter: function (p, t, c) {
        const html = `
          <div class="chart27 pie tooltip">
            <p style="white-space: normal;">${item.content}</p>
            <span>授权专利数: ${item.value}</span>
          </div>
        `
        return html
      }
    }

    return {
      ...item,
      tooltip
    }
  })

  const option = {
    // 颜色卡
    color: ['#37A2DA', '#32C5E9', '#67E0E3', '#9FE6B8', '#FFDB5C', '#FF9F7F', '#FB7293', '#E062AE', '#E690D1', '#E690D1'],
    tooltip: {
      show: true,
      trigger: 'item',
      backgroundColor: 'rgba(0, 0, 0, 0)'
      // 是否一直显示
      // alwaysShowContent: true
    },
    // 图例
    legend: {
      orient: 'vertical',
      itemWidth: 24,
      itemHeight: 10,
      align: 'right',
      top: 0,
      bottom: 0,
      right: 0,
      data: names,
      textStyle: {
        width: 65,
        fontSize: 10,
        padding: [0, 10, 0, 0],
        color: 'rgba(255, 255, 255, 0.8)'
      }
    },
    // 系列
    series: [
      {
        name: '主要技术领域',
        type: 'pie',
        radius: [70, 80],
        center: [114, 100],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          show: false
        },
        // 需要为每个
        data: seriesData
      }
    ]
  }

  return option
}

export default getOptions
