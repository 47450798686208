<template>
  <div class="chart_12">
    <div ref="chartDom" :style="chartBox"></div>
    <!-- 弹窗 -->
    <div
      class="modal"
      v-show="showModal"
      v-click-outside="clickOutside">
      <h1 class="modal_title">{{ curData.name }}</h1>
      <div class="line_chart" ref="lineChart"></div>
    </div>
  </div>
</template>
<script>
import echarts from 'echarts'
import { mapChart } from '../chart/chart12'
import getLineOptions from './options/line'
export default {
  data () {
    return {
      // 是否显示弹窗
      showModal: false,
      // 当前所点击的数据，可以在点击事件时赋值，重置整个对象
      curData: {
        // 国家名称
        name: ''
      }
    }
  },
  computed: {
    // 地图的大小
    chartBox () {
      const style = {
        width: this.frontDict.parentWidth + 'px',
        height: this.frontDict.parentHeight + 'px'
      }
      return style
    }
  },
  watch: {
    showModal: {
      immediate: true,
      handler: function (val, oldVal) {
        this.$nextTick(() => {
          if (val) {
            this.initLine()
          } else {
            if (this.lineChart) {
              this.lineChart.dispose()
            }
          }
        })
      }
    }
  },
  mounted () {
    this.initChart()
  },
  methods: {
    // 初始化地图
    initChart (category) {
      // 使用默认的数据
      this.frontDict.category = 'total'
      const option = mapChart(this.frontDict)
      const targetDom = this.$refs.chartDom
      if (targetDom) {
        const myChart = echarts.init(targetDom)
        myChart.setOption(option)

        myChart.on('click', (params) => {
          if (params.data) {
            this.curData.name = params.data.name
            this.showModal = !this.showModal
          }
        })
      }
    },
    // 外部点击
    clickOutside (e) {
      if (this.showModal) {
        this.showModal = false
      }
    },
    // 初始化折线图
    initLine () {
      this.lineChart = echarts.init(this.$refs.lineChart)
      const options = getLineOptions(this.curData.name)
      this.lineChart.setOption(options)
    }
  }
}
</script>
<style lang="scss" scoped>
  .chart_12 {
    position: relative;
    .modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 308px;
      height: 264px;
      // 主题详情背景图
      background-image: url('./img/chart_12_model.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 20px 18px 0px 16px;
      z-index: 100;
      .modal_title {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 25px;
        color: #FFFFFF;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .line_chart {
        margin-top: 17px;
        width: 265px;
        height: 170px;
      }
    }
  }
</style>
