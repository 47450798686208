// 方法对象
const method = {
  // 获取颜色 传入的是rgba格式的颜色
  getColor: [
    'lineColor', 'gradientColorStart', 'gradientColorEnd', 'numberColor', 'textColor', 'barColor',
    'iconColor', 'lineColorFour', 'lineColorSecond', 'lineColorThird', 'pictorialBarColor',
    'backgroundColor', 'axisLableColor', 'circleColor', 'axisLineColor', 'axisFontColor',
    'XAxisLineColor', 'splitLineColor', 'titleColor', 'areaColor', 'barBackgroundColor',
    'secondGradientColorEnd', 'secondGradientColorStart', 'selectedColor', 'titleBackgroundColor',
    'xSplitLineColor', 'axisFontColor', 'axisTickColor', 'textShadowColor',
    'rowBackcolor', 'hoverColor', 'iconBackgroundColor', 'barLabelColor', 'borderColor'
  ],
  // 获取布尔类型值
  getBol: [
    'is_exchange_bar_line', 'isAddTailColor', 'isShowSign', 'cancelBarLineColor', 'cancelCustomizeColor',
    'isStack', 'boundaryGap', 'isShowAreaColor', 'isAddPictorialBar', 'isShowSeriesLabel',
    'isShowSeriesCircle', 'isShowLeftYLabel', 'isShowBarLabel', 'barLabelFontSize', 'isShowRightYAxis',
    'isShowRightYLabel', 'isAescending', 'isShowAxis', 'isLimitRowNum', 'isMoveUp', 'isAddHeader',
    'isShowLeftYAxis', 'isCurrentDate', 'isShowAreaTitle'
  ],
  // 拿到字符串数字 转换成数字
  getNumber: [
    'iconHeight', 'iconWidth', 'textFontSize', 'numberFontSize', 'textGap', 'parentHeight', 'parentWidth',
    'lineSmoothNumber', 'splitNumber', 'sumNum', 'firstRowHeight', 'lineWidth', 'axisLineWidth',
    'YAxisLineWidth', 'axisFontSize', 'YlabelToAxis', 'XlabelToAxis', 'axisLableFontSize',
    'pictorialBarHeight', 'pictorialBarOffsetY', 'pictorialBarWidth', 'pictorialBarOffsetX', 'barWidth',
    'barRadius', 'startLine', 'endLine', 'XAxisFontSize', 'YAxisFontSize', 'rowWidth', 'iconLeftGap',
    'iconTopGap', 'numerator', 'show_row_limit', 'rowHeight', 'delayTime', 'marginTop', 'speedTime',
    'targetMarginLeft', 'groupbyNum', 'targetHeight', 'textBodyWidth', 'targetWidth', 'rippleScale',
    'splitLineWidth', 'interval', 'xSplitLineWidth', 'axisTickWidth', 'axisTickLength', 'axisTickInterval',
    'letterSpacing',
    'areaColorOpacity', 'tailZeroNum', 'period', 'circleSize', 'barMinHeight',
    'legendTextSize', 'legendItemGap', 'legendItemWidth', 'legendItemHeight'
  ],
  // 获取字符串 带px或%
  getStr: [
    'digtalfontFamily', 'siteNamePY', 'timeLabel', 'stack_type', 'y_axis_unit', 'x_axis_label',
    'table_type', 'timeField', 'pictorialSymbolType', 'legend_left', 'legend_top', 'second_y_axis_unit',
    'x_axis_unit', 'inlineType', 'x_ticks_namegap', 'y_ticks_namegap', 'x_ticks_namelocation',
    'y_axis_label', 'title', 'sub_title', 'titleUnit', 'circleStyle', 'emphasisObject',
    'selected_part', 'pie_table_type', 'eachBarTableWidth', 'secondBarTableToLeft', 'selectArrowIcon',
    'xAxisUnit', 'textAlign', 'inputNumberOrText', 'fontFamily', 'customizeFontFamily', 'xInlineType',
    'pictorialBarType', 'x_axis_max_value', 'x_axis_min_value', 'isSearchIcon', 'barStandardSelect', 'chartSelect',
    'htmlSelect'
  ],
  // ,|;分割的字符串，转换成数组
  getSplitStrArr: [
    'legend', 'titlePosition', 'pieWidth', 'secondPieWidth', 'piePosition', 'thirdPieWidth',
    'rowRealWidthList', 'rowWidthList', 'block_name', 'tableToBorderDistance', 'colPrefixList',
    'fontSizeList', 'pieLiquidHeight', 'pagination', 'gridPosition'
  ],
  // ,|;分割的字符串，转换成数组，再把数组中的值，转为数字 保留正负号
  getSplitStrNumArr: [
    'barBorderRadius', 'picOffsetXList', 'y_axis_max_value', 'y_axis_min_value'
  ],
  // 分隔字符串，得到颜色数组
  getSplitStrColorArr: [
    'textColorList', 'endTextColorList'
  ]
}
// yAxisMaxValue yMaxValueList
// yAxisMinValue yMinValueList

// 根据方法和值来做映射
const methodMap = new Map()

// 遍历方法
for (const key in method) {
  if (Object.hasOwnProperty.call(method, key)) {
    const fieldList = method[key]
    fieldList.forEach(item => {
      methodMap.set(item, key)
    })
  }
}

export default methodMap
