<!-- 利益相关者关注点及演化模式 -->
<template>
  <div class="chart_25">
    <div class="select_box">
      <el-select class="my-lib-el-select transparency" size="small" v-model="selectValue" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <div class="table_box">
      <p>利益相关者关注的话题（top5）</p>
      <div class="table_wrapper">
        <div class="th_wrapper">
          <table>
            <colgroup class="col1" width="230"></colgroup>
            <colgroup class="col2" width="133"></colgroup>
            <thead>
              <tr>
                <th>名称</th>
                <th>关注占比</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="tb_wrapper">
          <vue-scroll>
            <table>
              <colgroup class="col1" width="230"></colgroup>
              <colgroup class="col2" width="133"></colgroup>
              <tbody>
                <tr v-for="(item, index) in tableData" :key="index">
                  <td v-for="(value, name) in item" :key="name">{{ value }}%</td>
                </tr>
              </tbody>
            </table>
          </vue-scroll>
        </div>
      </div>
    </div>
    <div class="process_box">
      <div class="process_title">
        <p>利益相关者关注话题的演化</p>
      </div>
      <div class="process_list" :class="processBgClass">
        <div class="process_row">
          <span>潜伏期</span>
        </div>
        <div class="process_row">
          <span>爆发期</span>
        </div>
        <div class="process_row">
          <span>蔓延期</span>
        </div>
        <div class="process_row">
          <span>恢复期</span>
        </div>
        <div class="process_row">
          <span>稳定期</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { topicTable, topicBg } from '../data-source/chart-25'
export default {
  data () {
    return {
      // 选择器的值 一开始要填写一个默认值
      selectValue: '医疗卫生部门',
      options: [
        { value: '医疗卫生部门', label: '医疗卫生部门' },
        { value: '非卫生领域政府部门', label: '非卫生领域政府部门' },
        { value: '医院', label: '医院' },
        { value: '传统媒体', label: '传统媒体' },
        { value: '自媒体', label: '自媒体' },
        { value: '普通企业', label: '普通企业' },
        { value: '医疗健康企业', label: '医疗健康企业' },
        { value: '校园', label: '校园' },
        { value: '旅游娱乐与消费场所', label: '旅游娱乐与消费场所' },
        { value: '医药卫生领域人员', label: '医药卫生领域人员' },
        { value: '易感人群', label: '易感人群' },
        { value: '普通群众', label: '普通群众' }
      ]
    }
  },
  computed: {
    // 根据下拉选择器所选值返回不同的class类名
    // 为利益相关者关注话题的演化显示不同的流程背景图
    processBgClass () {
      const bgClass = topicBg[this.selectValue]
      return bgClass
    },
    // 表格数据
    tableData () {
      return topicTable[this.selectValue]
    }
  }
}
</script>

<style lang="scss" scoped>
.chart_25 {
  width: 366px;
  .select_box {
    margin-bottom: 14px;
  }
  .table_box {
    margin-bottom: 20px;
    & > p {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 12px;
    }
    .table_wrapper {
      position: relative;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      .tb_wrapper {
        height: 200px;
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.3);
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.3);
      }
      table {
        width: 364px;
        border-collapse: collapse;
        th,
        td {
          padding: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          border-right: 1px solid rgba(255, 255, 255, 0.3);
        }
        thead {
          th {
            text-align: left;
            color: rgba(255, 255, 255, 0.8);
            background-color: rgba(22, 215, 255, 0.05);
          }
        }
        tbody {
          // 每行第二个
          tr:nth-child(2n) {
            background-color: rgba(22, 215, 255, 0.05);
          }
          td {
            color: #ffffff;
            &:last-child {
              font-weight: bold;
              color: rgba(88, 238, 255, 1);
            }
          }
        }
      }
    }
  }
  .process_box {
    .process_title {
      padding: 10px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      & > p {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.8);
      }
    }
    .process_list {
      background-repeat: no-repeat;
      .process_row {
        display: flex;
        align-items: center;
        height: 90px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        & > span {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 17px;
          color: #ffffff;
        }
      }
      &.bg1 {
        background-position-x: 55px;
        background-image: url("../pic/chart25/ylwsbm.png");
      }
      &.bg2 {
        background-position-x: 55px;
        background-image: url("../pic/chart25/fwslyzfbm.png");
      }
      &.bg3 {
        background-position-x: 55px;
        background-image: url("../pic/chart25/yljkqy.png");
      }
      &.bg4 {
        background-position-x: 55px;
        background-image: url("../pic/chart25/yywslyry.png");
      }
      &.bg5 {
        background-position-x: 55px;
        background-image: url("../pic/chart25/ygrq.png");
      }
    }
  }
}
</style>
