<template>
  <div class="chart_26">
    <vue-scroll>
      <div class="info_box" v-for="(item, index) in list" :key="index">
        <div class="info_main">
          {{ item.content }}
        </div>
        <div class="info_footer">
          <span class="date">{{ item.date1 }}/{{ item.date2 }}</span>
          <span class="type">{{ item.type }}</span>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import allData from '../data-source/chart-26'
export default {
  data () {
    return {
    }
  },
  computed: {
    list () {
      return allData
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart_26 {
    width: 380px;
    height: 190px;
    .info_box {
      width: 100%;
      background: rgba(255, 255, 255, 0);
      border: 1px solid rgba(255, 255, 255, 0.3);
      .info_main {
        width: 100%;
        padding: 10px;
        background-color: rgba(22, 215, 255, 0.15);
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 20px;
        color: #FFFFFF;
      }
      .info_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 30px;
        padding: 0 10px;
        .date {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 20px;
          color: rgba(255, 255, 255, 0.8);
        }
        .type {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 20px;
          color: #FECC51;
        }
      }
      &+.info_box {
        margin-top: 10px;
      }
    }
  }
</style>
