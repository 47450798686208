/**
 * 小黄车主体分布柱状图
 */
import { lineData, pieData, nameMap } from './data-source/chart-6'
const chart = function (params) {
  // y 轴列表
  const yList = ['主题一', '主题二', '主题三', '主题四', '主题五', '主题六']
  const NList = []
  const OList = []
  const MList = []
  for (const key in lineData) {
    const comment = pieData[key]
    // let { sValue, nValue, oValue, oPer, mValue, mPer} = pieData[key]
    NList.push(comment.nValue)
    OList.push(comment.oValue)
    MList.push(comment.mValue)
  }

  const legend = ['负面评价', '中立评价', '正面评价']
  const barWidth = 10
  const colorList = ['rgba(255, 111, 86, 1)', 'rgba(104, 229, 186, 1)', 'rgba(107, 187, 255, 1)']

  const option = {
    color: colorList,
    tooltip: {
      show: true,
      padding: [12, 50, 12, 20],
      textStyle: {
        fontSize: 14,
        lineHeight: 28,
        height: 28
      },
      backgroundColor: 'rgba(56, 71, 96, 0.7)',
      axisShowContent: true,
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.3)',
          width: 1,
          type: 'solid'
        }
      },
      formatter: function (params, ticket, callback) {
        const topic = params[0].name
        // console.log(params)
        const barData = pieData[topic]
        const title = nameMap[topic]
        // console.log(barData)
        const marginTop = 8
        const html = `
          <div style="">
            <h1>${title}</h1>
            <div style="margin-top:${marginTop}px">
              <span style="color: ${colorList[0]}">负面评价</span>
              <span>${barData.oValue}</span>
            </div>

            <div style="margin-top:${marginTop}px" >
            <span  style="color: ${colorList[1]}">中立评价</span>
            <span>${barData.mValue}</span>
            </div>

            <div style="margin-top:${marginTop}px">
            <span  style="color: ${colorList[2]}">正面评价</span>
            <span>${barData.nValue}</span>
           </div>
          </div>
        `
        return html
      }
    },
    legend: {
      top: 5,
      left: 0,
      icon: 'rect',
      itemGap: 25,
      itemWidth: 30,
      itemHeight: 12,
      data: legend,
      textStyle: {
        color: 'rgba(255, 255, 255, 1)'
      }
    },
    grid: {
      left: 5,
      right: 40,
      bottom: 5,
      top: 40,
      containLabel: true
    },
    xAxis: [
      {
        type: 'value',
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.6)'
          }
        },
        axisLabel: {
          color: '#ffffff',
          fontSize: 14
        }
      },
      {
        type: 'value',
        axisTick: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.6)'
          }
        }
      }
    ],
    yAxis: {
      type: 'category',
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisLabel: {
        color: '#ffffff',
        margin: 16
      },
      data: yList
    },
    series: [
      {
        name: legend[0],
        type: 'bar',
        stack: '舆情',
        label: {
          show: false
        },
        barWidth: barWidth,
        data: NList
      },
      {
        name: legend[1],
        type: 'bar',
        stack: '舆情',
        label: {
          show: false
        },
        barWidth: barWidth,
        data: MList
      },
      {
        name: legend[2],
        type: 'bar',
        stack: '舆情',
        label: {
          show: false
        },
        barWidth: barWidth,
        data: OList
      }
    ]
  }
  return option
}

export default chart
