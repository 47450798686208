<template>
  <div class="groupby">
    <div class="groupby_item" v-for="(item, index) in result" :key="index">
      <p class="groupby_item_label text_size_14 text_color_primary">X{{index + 1}}</p>
      <el-select
        v-model="result[index].value"
        style="flex: 1;" size="small"
        :clearable="true"
        @change="value => onResultChange(value, index)">
        <el-option v-for="item in columns" :key="item" :label="item" :value="item"></el-option>
      </el-select>
    </div>
    <p class="groupby_add text_color_primary" @click="addItem">
      <i class="iconfont iconjia"></i>
      <span class="text_size_14">新增</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    columns: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data  () {
    return {
      result: []
    }
  },
  created () {
    if (this.value.length <= 0) {
      this.result = [
        {
          value: ''
        }
      ]
    } else {
      this.result = []
      this.value.forEach(element => {
        this.result.push({
          value: element
        })
      })
    }
  },
  watch: {
    value: function (newVal, oldVal) {
      if (newVal.length <= 0) {
        this.result = [
          {
            value: ''
          }
        ]
      } else {
        this.result = []
        newVal.forEach(element => {
          this.result.push({
            value: element
          })
        })
      }
    }
  },
  methods: {
    onResultChange (value, index) {
      if (value === '') {
        this.result.splice(index, 1)
        if (this.result.length === 0) {
          this.result = [
            {
              value: ''
            }
          ]
        }
      }
      const result = []
      this.result.forEach(element => {
        if (element.value) {
          result.push(element.value)
        }
      })
      this.$emit('input', result)
    },
    addItem () {
      if (this.result.length >= this.columns.length) {
        return
      }
      this.result.push({
        value: ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.groupby {
  &_item {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-top: 18px;
    }
    &_label {
      width: 40px;
    }
  }
  &_add {
    width: 45px;
    height: 20px;
    margin-left: 60px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      color: $color-primary;
    }
  }
}
</style>
