import './PicCarouselNine.css'
import Swiper from 'swiper'

function PicCarouselNine (element, props) {
  // console.log(props)
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const textData = props.data[0].data
  const contentWidth = props.data[0].parentWidth
  const contentHeight = props.data[0].parentHeight
  const delayTime = props.data[0].delayTime
  const speedTime = props.data[0].speedTime

  const pageLabelList = props.data[0].pageLabelList
  const sumNumList = props.data[0].pagination

  const createDom = function (domType, className, text) {
    const oneDom = document.createElement(domType)
    if (className !== '') {
      oneDom.setAttribute('class', className)
    }
    if (text !== '') {
      oneDom.innerHTML = text
    }
    return oneDom
  }

  const createContentList = function (parentDiv, data) {
    for (let j = 0; j < data.length; j++) {
      const oneContent = document.createElement('div')
      oneContent.setAttribute('class', 'swiper-slide')

      // 整体用于布局的div
      const layContent = document.createElement('div')
      layContent.setAttribute('class', 'slide-content')
      oneContent.appendChild(layContent)

      const dataDict = data[j]

      const eachRow = createDom('div', 'piccarouselnine-table-body', '')
      eachRow.innerHTML = `
    <div class="piccarouselnine-tbody-style">
      <div class="carousel9-top">
        <div class="carousel9-top-title">${dataDict['需求名称']}</div>
        <div class="carousel9-top-subtitle">${dataDict['所属行业']}</div>
      </div>
      <div class="carousel9-dashed-line"></div>
      <div class="carousel9-row">
        <div class="carousel9-1-col">企业名称</div>
        <div class="carousel9-2-col">${dataDict['企业名称']}</div>
      </div>
      <div class="carousel9-dashed-line"></div>
      <div class="carousel9-row">
        <div class="carousel9-1-col">拟投入金额</div>
        <div class="carousel9-2-col nine-hover">${dataDict['拟投入金额']}</div>
        <div class="carousel9-3-col">待解决时间</div>
        <div class="carousel9-2-col">${dataDict['待解决时间']}</div>
      </div>
      <div class="carousel9-dashed-line"></div>
      <div class="carousel9-row">
        <div class="carousel9-1-col">项目合作方式</div>
        <div class="carousel9-4-col">${dataDict['项目合作方式']}</div>
      </div>
      <div class="carousel9-dashed-line"></div>
      <div class="carousel9-row">
        <div class="carousel9-1-col">对需求方要求</div>
        <div class="carousel9-multi-text">${dataDict['对需求方要求']}</div>
      </div>
      <div class="carousel9-dashed-line"></div>
      <div class="carousel9-row">
        <div class="carousel9-1-col">需求内容</div>
        <div class="carousel9-special-row">
        ${dataDict['需求内容']}
        </div>
      </div>
      <div class="carousel9-dashed-line"></div>
      <div class="carousel9-row">
        <div class="carousel9-1-col">现有基础</div>
        <div class="carousel9-multi-text">
        ${dataDict['现有基础']}
        </div>
      </div>
    </div>
        `

      layContent.appendChild(eachRow)
      parentDiv.append(oneContent)
    }
  }

  const body = element.children[0]
  body.setAttribute('style', 'width: 100%;height: 100%; overflow: hidden; position: relative;')
  const domInit = function () {
    // 整个图表的div
    const originDiv = document.createElement('div')
    originDiv.setAttribute('style', 'height:' + contentHeight + 'px; width: ' + contentWidth + 'px; background-color: rgba(255, 255, 255, 0)')
    originDiv.setAttribute('class', 'swiper-container pic-box-nine')
    body.appendChild(originDiv)

    //  轮播内容div
    const carouselContent = document.createElement('div')
    carouselContent.setAttribute('class', 'swiper-wrapper')
    originDiv.appendChild(carouselContent)

    createContentList(carouselContent, textData)

    return originDiv
  }
  const originDiv = domInit()
  // 拼凑页码分类的标签
  let pageLableText = ''
  for (let i = 0; i < pageLabelList.length; i++) {
    pageLableText = pageLableText + `<span class="category-label">${pageLabelList[i]}</span>`
  }

  // 添加页码
  const pageDom = createDom('div', 'carousel9-page-bottom', '')
  pageDom.setAttribute('style', 'width: 100%')
  body.appendChild(pageDom)
  pageDom.innerHTML = `      <div class="carousel9-page-parent">
  <span id="page-num-nine">1</span>
  <span id="no-number-nine">/${sumNumList[0]}</span>
  </div>
  <div id= "carousel-title-nine" class="carousel9-page-parent" style="margin-top: 10px">
    ${pageLableText}
  </div>`

  // 根据当前索引更新页码与高亮标签

  const sumOne = sumNumList[0]
  let sumTwo = 100
  if (sumNumList.length > 1) {
    sumTwo = sumNumList[0] + sumNumList[1]
  }
  let sumThree = 200
  if (sumNumList.length > 2) {
    sumThree = sumNumList[0] + sumNumList[1] + sumNumList[2]
  }
  let sumFour = 1000
  if (sumNumList.length > 3) {
    sumFour = sumNumList[0] + sumNumList[1] + sumNumList[2] + sumNumList[3]
  }

  console.log(sumOne, sumTwo, sumThree, sumFour)
  console.log(sumOne, sumTwo, sumThree)
  const changePage = function (currentIndex) {
    const pageNumDom = document.getElementById('page-num-nine')
    const pageSumDom = document.getElementById('no-number-nine')
    const carouselTitleDom = document.getElementById('carousel-title-nine')

    let index = 0
    let labelNum = 1
    if (currentIndex < sumOne) {
      index = currentIndex + 1
    } else if (currentIndex >= sumOne && currentIndex < sumTwo) {
      index = currentIndex - sumOne + 1
      labelNum = 2
    } else if (sumTwo <= currentIndex && currentIndex < sumThree) {
      index = currentIndex - sumTwo + 1
      labelNum = 3
    } else if (sumThree <= currentIndex && currentIndex < sumFour) {
      index = currentIndex - sumThree + 1
      labelNum = 4
    }

    console.log(sumNumList[labelNum - 1], 'page sum ', pageSumDom)
    console.log(index, 'page num ', index)
    pageNumDom.innerText = index
    pageSumDom.innerText = '/' + sumNumList[labelNum - 1]
    const targetDom = carouselTitleDom.children[labelNum - 1]
    targetDom.setAttribute('class', 'category-label-active')
    for (let i = 0; i < carouselTitleDom.children.length; i++) {
      if (i !== labelNum - 1) {
        const preTargetDom = carouselTitleDom.children[i]
        preTargetDom.setAttribute('class', 'category-label')
      }
    }
  }

  // eslint-disable-next-line no-new
  new Swiper(originDiv, {
    speed: speedTime,
    autoplay: {
      disableOnInteraction: true,
      // 每次切换的时间间隔
      delay: delayTime
    }, // 可选选项，自动滑动
    direction: 'horizontal',
    // direction: 'vertical',
    setWrapperSize: true,
    //  开启循环放到最后面
    loop: true,
    on: {
      slideChangeTransitionStart: function () {
        // console.log('== activeIndex ==', this.activeIndex)
        console.log('== realIndex ==', this.realIndex)
        const currentIndex = this.realIndex
        changePage(currentIndex)
      }
    }
  })
}

export default PicCarouselNine // 修改为Classname
