import 'echarts-gl' // bar3d 图需要引入该文件
import 'echarts/map/js/china'
import 'echarts/map/js/world'
// import {
//   manageImgPath
// } from '../chartCommonPart'
import './maptooltipmove.css'
import {
  scatterData, initRegionData
} from './com'

const chart1 = function (params, myChart) {
  // let imgPathTwo = manageImgPath('/static/assets/images/icons/gornFour.png')
  const regionAttr = initRegionData(0)
  const oneNum = parseInt(Math.random() * 5555)
  const twoNum = parseInt(Math.random() * 9999999999)
  console.log(oneNum, twoNum)
  console.log(regionAttr)

  // 该列表顺序代表了循环展示的顺序
  const siteData = scatterData()
  const option = {
    grip: {
      top: 10,
      bottom: 10
    },
    geo3D: {
      map: 'asia',
      roam: false,
      zoom: 1,
      boxWidth: 200,
      boxHeight: 20,
      regionHeight: 4,
      shading: 'lambert',
      // shading: 'color',
      lambertMaterial: {
        detailTexture: '/static/img/textures/chinaMap.png',
        textureTiling: 1
      },
      colorMaterialObject: {
        detailTexture: '/static/img/textures/chinaMap.png',
        textureTiling: 1
      },
      itemStyle: {
        // color: 'rgb(56,56,56)', // 地图配色
        // 设置透明色
        // areaColor: 'rgba(10, 133, 171, 1)',
        opacity: 1,
        borderWidth: 1,
        borderColor: '#61a5df' // 地图边配色
      },
      // 整个背景的颜色
      // environment: 'rgba(255,255,255,0.2)',
      groundPlane: {
        show: false
        // color: '#fff'
      },
      viewControl: {
        autoRotate: false,
        cneter: [0, 0, 0],
        distance: 120,
        alpha: 58,
        beta: 1,
        minAlpha: 45,
        maxAlpha: 90,
        minBeta: -60,
        maxBeta: 60
      },
      light: {
        main: {
          color: '#fff',
          intensity: 1.2,
          shadow: true,
          shadowQuality: 'ultra',
          alpha: 75,
          beta: -180
        }
      },
      regions: regionAttr
    },
    series: [{
      // name: 'sGl',
      type: 'scatter3D',
      coordinateSystem: 'geo3D',
      zlevel: 1000,
      data: siteData,
      symbol: 'circle',
      itemStyle: {
        color: 'rgba(255, 202, 55, 1)',
        borderWidth: 6,
        borderColor: 'rgba(255, 202, 55, 0.05)'
      },
      label: {
        normal: {
          show: false
        }
      },
      emphasis: {
        label: {
          show: true,
          formatter: function (params) {
            console.log(params)
            return ` \n 全省开店量：${oneNum} 家\n 全省销售额：${twoNum} 元`
          },
          // 设置显示框的位置
          distance: 34,
          textStyle: {
            // color: 'rgba(55, 255, 155, 1)',
            borderWidth: 1,
            borderColor: 'rgba(255, 255, 255, 1)',
            fontSize: 20,
            fontFamily: 'SonyEricssonLogo'
          }
        }
      }
    }
    ]
  }

  myChart.setOption(option)
  let cityIndex = 0
  // 地区总数
  const cityTotal = 31
  console.log('city index')
  setInterval(() => {
    // 更新球体的selected状态
    siteData[cityIndex].selected = false

    option.series[0].data = []
    cityIndex += 1
    siteData[cityIndex].selected = true
    console.log('site', siteData)
    option.series[0].data = siteData
    if (cityIndex >= cityTotal) {
      cityIndex = 0
    }
    // 更新地图高亮区域
    const newAttr = initRegionData(cityIndex)
    option.geo3D.regions = newAttr

    myChart.setOption(option, true, true)
  }, params.delayTime)
}

export default chart1
