// 主题
const topics = [
  {
    id: 4,
    name: '急寻密切接触者',
    symbolSize: 16.65,
    value: 2498,
    category: 0
  },
  {
    id: 22,
    name: 'MERS对韩国明星活动的影响',
    symbolSize: 44.16,
    value: 6624,
    category: 0
  },
  {
    id: 9,
    name: '因MERS事件而辱骂韩国',
    symbolSize: 68.18,
    value: 10227,
    category: 0
  },
  {
    id: 14,
    name: '疫情发在中国大规模传播可能性',
    symbolSize: 48.82,
    value: 7323,
    category: 0
  },
  {
    id: 25,
    name: '进入昆明的疑似患者体检正常排除感染',
    symbolSize: 33.41,
    value: 5011,
    category: 0
  },
  {
    id: 32,
    name: '韩多所学校停课',
    symbolSize: 81.79,
    value: 12268,
    category: 0
  },
  {
    id: 23,
    name: '韩动物园隔离骆驼及四川官方提醒民众谨慎赴韩',
    symbolSize: 39.07,
    value: 5861,
    category: 0
  },
  {
    id: 34,
    name: 'MERS患者在我国治疗状况',
    symbolSize: 56.77,
    value: 8515,
    category: 0
  },
  {
    id: 2,
    name: '网民追讨患者800万治疗费',
    symbolSize: 21.12,
    value: 3168,
    category: 0
  },
  {
    id: 7,
    name: '疫情对全球影响',
    symbolSize: 30.37,
    value: 4556,
    category: 0
  },
  {
    id: 33,
    name: '韩国增强免疫力食品热销及上海确诊一例h7n9非MERS',
    symbolSize: 23.57,
    value: 3535,
    category: 0
  },
  {
    id: 5,
    name: '韩飞机未消毒进入日本',
    symbolSize: 20.9,
    value: 3135,
    category: 0
  },
  {
    id: 16,
    name: 'MERS传播能力及疫情是否重演SARS',
    symbolSize: 19.29,
    value: 2894,
    category: 0
  },
  {
    id: 29,
    name: '李敏镐担任韩国旅游景点海外宣传大使',
    symbolSize: 22.63,
    value: 3395,
    category: 0
  },
  {
    id: 19,
    name: '韩MRES确诊者曾去济州扬言“让大家都传染”',
    symbolSize: 29.35,
    value: 4402,
    category: 0
  },
  {
    id: 3,
    name: '疫情通报',
    symbolSize: 26.53,
    value: 3980,
    category: 0
  },
  {
    id: 24,
    name: 'MERS密切接触者全部解除医学观察',
    symbolSize: 24.02,
    value: 3603,
    category: 0
  },
  {
    id: 1,
    name: '疑似患者进入昆明遭网民指责',
    symbolSize: 51.05,
    value: 7658,
    category: 0
  },
  {
    id: 27,
    name: '抗体研究进展',
    symbolSize: 13.94,
    value: 2091,
    category: 0
  },
  {
    id: 36,
    name: '网民指责患者隐瞒病情进入中国',
    symbolSize: 14.75,
    value: 2213,
    category: 0
  },
  {
    id: 21,
    name: '韩国超过阿联酋，成MERS第二大发病国',
    symbolSize: 10.01,
    value: 1501,
    category: 0
  },
  {
    id: 13,
    name: '韩允许疑似患者进入中国事件评论',
    symbolSize: 16.96,
    value: 2544,
    category: 0
  },
  {
    id: 10,
    name: '患者病情加重，仍有多名密切接触者未找到',
    symbolSize: 22.34,
    value: 3351,
    category: 0
  },
  {
    id: 28,
    name: 'ISO投票“中医”英文单词保留Chinese',
    symbolSize: 30.24,
    value: 4536,
    category: 0
  },
  {
    id: 6,
    name: '代购广告',
    symbolSize: 25.13,
    value: 3769,
    category: 0
  },
  {
    id: 18,
    name: 'MERS预防措施',
    symbolSize: 38.89,
    value: 5834,
    category: 0
  },
  {
    id: 39,
    name: '韩三大政策失误导致MERS扩散',
    symbolSize: 17.49,
    value: 2623,
    category: 0
  },
  {
    id: 8,
    name: '广东出现首例输入性中病例',
    symbolSize: 28.37,
    value: 4256,
    category: 0
  },
  {
    id: 17,
    name: '护理病患护士的报道',
    symbolSize: 40.31,
    value: 6046,
    category: 0
  },
  {
    id: 37,
    name: 'MERS疫情引发国民对sars时期生活回忆',
    symbolSize: 15.25,
    value: 2288,
    category: 0
  },
  {
    id: 30,
    name: '长江游轮倾覆应急处置获网民称赞',
    symbolSize: 17.36,
    value: 2604,
    category: 0
  },
  {
    id: 12,
    name: '什么是MERS及临床表现',
    symbolSize: 18.76,
    value: 2814,
    category: 0
  },
  {
    id: 11,
    name: '外逃嫌烦担心染MERS回国自首',
    symbolSize: 14.83,
    value: 2225,
    category: 0
  },
  {
    id: 35,
    name: '韩国疑似患者在MERS隔离期间前往菲律宾',
    symbolSize: 14.22,
    value: 2133,
    category: 0
  },
  {
    id: 15,
    name: '中韩两国突发事件处置对比',
    symbolSize: 56.37,
    value: 8455,
    category: 0
  },
  {
    id: 31,
    name: '韩籍MERS患者出院',
    symbolSize: 16.71,
    value: 2506,
    category: 0
  },
  {
    id: 20,
    name: '取消韩国旅游退款问题',
    symbolSize: 10.81,
    value: 1622,
    category: 0
  },
  {
    id: 40,
    name: '韩炭疽病毒谣言澄清',
    symbolSize: 18.49,
    value: 2773,
    category: 0
  },
  {
    id: 26,
    name: '中国突发事件的快速响应彰显大国担当',
    symbolSize: 13.66,
    value: 2049,
    category: 0
  },
  {
    id: 38,
    name: '我为全民大捕筷点赞转发有奖营销活动',
    symbolSize: 18.29,
    value: 2743,
    category: 0
  }
]
const links = [
  {
    id: '0',
    source: '1',
    target: '0'
  }, {
    id: '1',
    source: '2',
    target: '0'
  }, {
    id: '2',
    source: '3',
    target: '0'
  }, {
    id: '3',
    source: '3',
    target: '2'
  }, {
    id: '4',
    source: '4',
    target: '0'
  }, {
    id: '5',
    source: '5',
    target: '0'
  }, {
    id: '6',
    source: '6',
    target: '0'
  }, {
    id: '7',
    source: '7',
    target: '0'
  }, {
    id: '8',
    source: '8',
    target: '0'
  }, {
    id: '9',
    source: '9',
    target: '0'
  }, {
    id: '13',
    source: '11',
    target: '0'
  }, {
    id: null,
    source: '11',
    target: '2'
  }, {
    id: '11',
    source: '11',
    target: '3'
  }, {
    id: '10',
    source: '11',
    target: '10'
  }, {
    id: '14',
    source: '12',
    target: '11'
  }, {
    id: '15',
    source: '13',
    target: '11'
  }, {
    id: '16',
    source: '14',
    target: '11'
  }, {
    id: '17',
    source: '15',
    target: '11'
  }, {
    id: '18',
    source: '17',
    target: '16'
  }, {
    id: '19',
    source: '18',
    target: '16'
  }, {
    id: '20',
    source: '18',
    target: '17'
  }, {
    id: '21',
    source: '19',
    target: '16'
  }, {
    id: '22',
    source: '19',
    target: '17'
  }, {
    id: '23',
    source: '19',
    target: '18'
  }, {
    id: '24',
    source: '20',
    target: '16'
  }, {
    id: '25',
    source: '20',
    target: '17'
  }, {
    id: '26',
    source: '20',
    target: '18'
  }, {
    id: '27',
    source: '20',
    target: '19'
  }, {
    id: '28',
    source: '21',
    target: '16'
  }, {
    id: '29',
    source: '21',
    target: '17'
  }, {
    id: '30',
    source: '21',
    target: '18'
  }, {
    id: '31',
    source: '21',
    target: '19'
  }, {
    id: '32',
    source: '21',
    target: '20'
  }, {
    id: '33',
    source: '22',
    target: '16'
  }, {
    id: '34',
    source: '22',
    target: '17'
  }, {
    id: '35',
    source: '22',
    target: '18'
  }, {
    id: '36',
    source: '22',
    target: '19'
  }, {
    id: '37',
    source: '22',
    target: '20'
  }, {
    id: '38',
    source: '22',
    target: '21'
  }, {
    id: '47',
    source: '23',
    target: '11'
  }, {
    id: '46',
    source: '23',
    target: '12'
  }, {
    id: '39',
    source: '23',
    target: '16'
  }, {
    id: '40',
    source: '23',
    target: '17'
  }, {
    id: '41',
    source: '23',
    target: '18'
  }, {
    id: '42',
    source: '23',
    target: '19'
  }, {
    id: '43',
    source: '23',
    target: '20'
  }, {
    id: '44',
    source: '23',
    target: '21'
  }, {
    id: '45',
    source: '23',
    target: '22'
  }, {
    id: null,
    source: '24',
    target: '11'
  }, {
    id: '48',
    source: '24',
    target: '23'
  }, {
    id: '52',
    source: '25',
    target: '11'
  }, {
    id: '51',
    source: '25',
    target: '23'
  }, {
    id: '50',
    source: '25',
    target: '24'
  }, {
    id: null,
    source: '26',
    target: '11'
  }, {
    id: null,
    source: '26',
    target: '16'
  }, {
    id: '53',
    source: '26',
    target: '24'
  }, {
    id: '56',
    source: '26',
    target: '25'
  }, {
    id: '57',
    source: '27',
    target: '11'
  }, {
    id: '58',
    source: '27',
    target: '23'
  }, {
    id: null,
    source: '27',
    target: '24'
  }, {
    id: '59',
    source: '27',
    target: '25'
  }, {
    id: '61',
    source: '27',
    target: '26'
  }, {
    id: '62',
    source: '28',
    target: '11'
  }, {
    id: '63',
    source: '28',
    target: '27'
  }, {
    id: '66',
    source: '29',
    target: '11'
  }, {
    id: '64',
    source: '29',
    target: '23'
  }, {
    id: '65',
    source: '29',
    target: '27'
  }, {
    id: '67',
    source: '30',
    target: '23'
  }, {
    id: null,
    source: '31',
    target: '11'
  }, {
    id: null,
    source: '31',
    target: '23'
  }, {
    id: null,
    source: '31',
    target: '27'
  }, {
    id: '68',
    source: '31',
    target: '30'
  }, {
    id: '72',
    source: '32',
    target: '11'
  }, {
    id: '73',
    source: '33',
    target: '11'
  }, {
    id: '74',
    source: '33',
    target: '27'
  }, {
    id: '75',
    source: '34',
    target: '11'
  }, {
    id: '76',
    source: '34',
    target: '29'
  }, {
    id: '77',
    source: '35',
    target: '11'
  }, {
    id: null,
    source: '35',
    target: '29'
  }, {
    id: '78',
    source: '35',
    target: '34'
  }, {
    id: '82',
    source: '36',
    target: '11'
  }, {
    id: '83',
    source: '36',
    target: '29'
  }, {
    id: '80',
    source: '36',
    target: '34'
  }, {
    id: '81',
    source: '36',
    target: '35'
  }, {
    id: '87',
    source: '37',
    target: '11'
  }, {
    id: '88',
    source: '37',
    target: '29'
  }, {
    id: '84',
    source: '37',
    target: '34'
  }, {
    id: '85',
    source: '37',
    target: '35'
  }, {
    id: '86',
    source: '37',
    target: '36'
  }, {
    id: '93',
    source: '38',
    target: '11'
  }, {
    id: '94',
    source: '38',
    target: '29'
  }, {
    id: '89',
    source: '38',
    target: '34'
  }, {
    id: '90',
    source: '38',
    target: '35'
  }, {
    id: '91',
    source: '38',
    target: '36'
  }, {
    id: '92',
    source: '38',
    target: '37'
  }, {
    id: '95',
    source: '39',
    target: '25'
  }, {
    id: '96',
    source: '40',
    target: '25'
  }, {
    id: '97',
    source: '41',
    target: '24'
  }, {
    id: '98',
    source: '41',
    target: '25'
  }, {
    id: '101',
    source: '42',
    target: '24'
  }, {
    id: '100',
    source: '42',
    target: '25'
  }, {
    id: '99',
    source: '42',
    target: '41'
  }, {
    id: '102',
    source: '43',
    target: '11'
  }, {
    id: '103',
    source: '43',
    target: '26'
  }, {
    id: '104',
    source: '43',
    target: '27'
  }, {
    id: null,
    source: '44',
    target: '11'
  }, {
    id: '105',
    source: '44',
    target: '28'
  }, {
    id: '107',
    source: '45',
    target: '28'
  }, {
    id: '108',
    source: '47',
    target: '46'
  }, {
    id: '112',
    source: '48',
    target: '11'
  }, {
    id: '110',
    source: '48',
    target: '25'
  }, {
    id: '111',
    source: '48',
    target: '27'
  }, {
    id: '109',
    source: '48',
    target: '47'
  }, {
    id: null,
    source: '49',
    target: '11'
  }, {
    id: '113',
    source: '49',
    target: '26'
  }, {
    id: null,
    source: '50',
    target: '24'
  }, {
    id: '115',
    source: '50',
    target: '49'
  }, {
    id: '119',
    source: '51',
    target: '11'
  }, {
    id: '118',
    source: '51',
    target: '26'
  }, {
    id: '117',
    source: '51',
    target: '49'
  }, {
    id: null,
    source: '52',
    target: '39'
  }, {
    id: '120',
    source: '52',
    target: '51'
  }, {
    id: '122',
    source: '53',
    target: '51'
  }, {
    id: '125',
    source: '54',
    target: '26'
  }, {
    id: '124',
    source: '54',
    target: '49'
  }, {
    id: '123',
    source: '54',
    target: '51'
  }, {
    id: '131',
    source: '55',
    target: '11'
  }, {
    id: '132',
    source: '55',
    target: '16'
  }, {
    id: '133',
    source: '55',
    target: '25'
  }, {
    id: null,
    source: '55',
    target: '26'
  }, {
    id: '128',
    source: '55',
    target: '39'
  }, {
    id: '134',
    source: '55',
    target: '41'
  }, {
    id: '135',
    source: '55',
    target: '48'
  }, {
    id: '127',
    source: '55',
    target: '49'
  }, {
    id: '126',
    source: '55',
    target: '51'
  }, {
    id: '129',
    source: '55',
    target: '54'
  }, {
    id: '136',
    source: '56',
    target: '49'
  }, {
    id: '137',
    source: '56',
    target: '55'
  }, {
    id: null,
    source: '57',
    target: '41'
  }, {
    id: null,
    source: '57',
    target: '48'
  }, {
    id: '138',
    source: '57',
    target: '55'
  }, {
    id: '145',
    source: '58',
    target: '11'
  }, {
    id: null,
    source: '58',
    target: '27'
  }, {
    id: '142',
    source: '58',
    target: '48'
  }, {
    id: '141',
    source: '58',
    target: '55'
  }, {
    id: '144',
    source: '58',
    target: '57'
  }, {
    id: '148',
    source: '59',
    target: '48'
  }, {
    id: '147',
    source: '59',
    target: '55'
  }, {
    id: null,
    source: '59',
    target: '57'
  }, {
    id: '146',
    source: '59',
    target: '58'
  }, {
    id: '150',
    source: '60',
    target: '48'
  }, {
    id: '151',
    source: '60',
    target: '58'
  }, {
    id: '152',
    source: '60',
    target: '59'
  }, {
    id: '153',
    source: '61',
    target: '48'
  }, {
    id: '158',
    source: '61',
    target: '55'
  }, {
    id: '157',
    source: '61',
    target: '57'
  }, {
    id: '154',
    source: '61',
    target: '58'
  }, {
    id: '156',
    source: '61',
    target: '59'
  }, {
    id: '155',
    source: '61',
    target: '60'
  }, {
    id: '164',
    source: '62',
    target: '41'
  }, {
    id: '162',
    source: '62',
    target: '48'
  }, {
    id: '159',
    source: '62',
    target: '55'
  }, {
    id: null,
    source: '62',
    target: '57'
  }, {
    id: '160',
    source: '62',
    target: '58'
  }, {
    id: '161',
    source: '62',
    target: '59'
  }, {
    id: null,
    source: '62',
    target: '60'
  }, {
    id: '165',
    source: '62',
    target: '61'
  }, {
    id: null,
    source: '63',
    target: '48'
  }, {
    id: '174',
    source: '63',
    target: '55'
  }, {
    id: null,
    source: '63',
    target: '57'
  }, {
    id: null,
    source: '63',
    target: '58'
  }, {
    id: '167',
    source: '63',
    target: '59'
  }, {
    id: null,
    source: '63',
    target: '60'
  }, {
    id: '172',
    source: '63',
    target: '61'
  }, {
    id: '169',
    source: '63',
    target: '62'
  }, {
    id: '184',
    source: '64',
    target: '11'
  }, {
    id: null,
    source: '64',
    target: '48'
  }, {
    id: '175',
    source: '64',
    target: '55'
  }, {
    id: '183',
    source: '64',
    target: '57'
  }, {
    id: '179',
    source: '64',
    target: '58'
  }, {
    id: '182',
    source: '64',
    target: '59'
  }, {
    id: '181',
    source: '64',
    target: '60'
  }, {
    id: '180',
    source: '64',
    target: '61'
  }, {
    id: '176',
    source: '64',
    target: '62'
  }, {
    id: '178',
    source: '64',
    target: '63'
  }, {
    id: '187',
    source: '65',
    target: '48'
  }, {
    id: '194',
    source: '65',
    target: '55'
  }, {
    id: '193',
    source: '65',
    target: '57'
  }, {
    id: null,
    source: '65',
    target: '58'
  }, {
    id: '192',
    source: '65',
    target: '59'
  }, {
    id: null,
    source: '65',
    target: '60'
  }, {
    id: '190',
    source: '65',
    target: '61'
  }, {
    id: '188',
    source: '65',
    target: '62'
  }, {
    id: '185',
    source: '65',
    target: '63'
  }, {
    id: '186',
    source: '65',
    target: '64'
  }, {
    id: '200',
    source: '66',
    target: '48'
  }, {
    id: '196',
    source: '66',
    target: '58'
  }, {
    id: '197',
    source: '66',
    target: '59'
  }, {
    id: '203',
    source: '66',
    target: '60'
  }, {
    id: '202',
    source: '66',
    target: '61'
  }, {
    id: '198',
    source: '66',
    target: '62'
  }, {
    id: '201',
    source: '66',
    target: '63'
  }, {
    id: '195',
    source: '66',
    target: '64'
  }, {
    id: '199',
    source: '66',
    target: '65'
  }, {
    id: '204',
    source: '67',
    target: '57'
  }, {
    id: null,
    source: '68',
    target: '11'
  }, {
    id: null,
    source: '68',
    target: '24'
  }, {
    id: '205',
    source: '68',
    target: '25'
  }, {
    id: '208',
    source: '68',
    target: '27'
  }, {
    id: null,
    source: '68',
    target: '41'
  }, {
    id: '209',
    source: '68',
    target: '48'
  }, {
    id: '213',
    source: '69',
    target: '11'
  }, {
    id: '214',
    source: '69',
    target: '24'
  }, {
    id: '211',
    source: '69',
    target: '25'
  }, {
    id: null,
    source: '69',
    target: '27'
  }, {
    id: '217',
    source: '69',
    target: '41'
  }, {
    id: '216',
    source: '69',
    target: '48'
  }, {
    id: '212',
    source: '69',
    target: '68'
  }, {
    id: '221',
    source: '70',
    target: '11'
  }, {
    id: '222',
    source: '70',
    target: '24'
  }, {
    id: '218',
    source: '70',
    target: '25'
  }, {
    id: '223',
    source: '70',
    target: '27'
  }, {
    id: '224',
    source: '70',
    target: '41'
  }, {
    id: '225',
    source: '70',
    target: '58'
  }, {
    id: '220',
    source: '70',
    target: '68'
  }, {
    id: '219',
    source: '70',
    target: '69'
  }, {
    id: '230',
    source: '71',
    target: '11'
  }, {
    id: '233',
    source: '71',
    target: '25'
  }, {
    id: '226',
    source: '71',
    target: '27'
  }, {
    id: '232',
    source: '71',
    target: '41'
  }, {
    id: null,
    source: '71',
    target: '48'
  }, {
    id: '228',
    source: '71',
    target: '68'
  }, {
    id: '227',
    source: '71',
    target: '69'
  }, {
    id: '229',
    source: '71',
    target: '70'
  }, {
    id: '236',
    source: '72',
    target: '11'
  }, {
    id: '234',
    source: '72',
    target: '26'
  }, {
    id: '235',
    source: '72',
    target: '27'
  }, {
    id: '237',
    source: '73',
    target: '48'
  }, {
    id: '238',
    source: '74',
    target: '48'
  }, {
    id: '239',
    source: '74',
    target: '73'
  }, {
    id: '242',
    source: '75',
    target: '25'
  }, {
    id: '244',
    source: '75',
    target: '41'
  }, {
    id: null,
    source: '75',
    target: '48'
  }, {
    id: '241',
    source: '75',
    target: '68'
  }, {
    id: '240',
    source: '75',
    target: '69'
  }, {
    id: '245',
    source: '75',
    target: '70'
  }, {
    id: '246',
    source: '75',
    target: '71'
  }, {
    id: '252',
    source: '76',
    target: '48'
  }, {
    id: '253',
    source: '76',
    target: '58'
  }, {
    id: '251',
    source: '76',
    target: '62'
  }, {
    id: '250',
    source: '76',
    target: '63'
  }, {
    id: '247',
    source: '76',
    target: '64'
  }, {
    id: '248',
    source: '76',
    target: '65'
  }, {
    id: '249',
    source: '76',
    target: '66'
  }]

const subjectTopics = [
  { id: 3, name: '医院', symbolSize: 9.23, value: 38, category: 0 },
  { id: 0, name: '医疗健康企业', symbolSize: 12.28, value: 41, category: 0 },
  {
    id: 5,
    name: '旅游娱乐与消费场所',
    symbolSize: 14.33,
    value: 44,
    category: 0
  },
  { id: 1, name: '医疗卫生部门', symbolSize: 15.67, value: 178, category: 0 },
  { id: 7, name: '普通企业', symbolSize: 23.59, value: 322, category: 0 },
  { id: 9, name: '校园', symbolSize: 24.93, value: 387, category: 0 },
  { id: 10, name: '自媒体', symbolSize: 36.51, value: 1061, category: 0 },
  {
    id: 11,
    name: '非卫生领域政府部门',
    symbolSize: 40.23,
    value: 1307,
    category: 0
  },
  { id: 4, name: '媒体', symbolSize: 47.46, value: 1391, category: 0 },
  {
    id: 2,
    name: '医药卫生领域人员',
    symbolSize: 49.08,
    value: 2541,
    category: 0
  },
  { id: 6, name: '易感人群', symbolSize: 50.5, value: 21750, category: 0 },
  { id: 8, name: '普通群众', symbolSize: 60.96, value: 122363, category: 0 }
]
const subjectLinks = [{ id: 0, source: '1', target: '4' },
  { id: 1, source: '10', target: '9' },
  { id: 2, source: '0', target: '6' },
  { id: 3, source: '11', target: '10' },
  { id: 4, source: '3', target: '11' },
  { id: 5, source: '2', target: '7' },
  { id: 6, source: '9', target: '8' },
  { id: 7, source: '5', target: '2' },
  { id: 8, source: '6', target: '0' },
  { id: 9, source: '4', target: '1' },
  { id: 10, source: '8', target: '5' },
  { id: 11, source: '7', target: '3' },
  { id: 12, source: '1', target: '11' },
  { id: 13, source: '9', target: '11' },
  { id: 14, source: '2', target: '6' },
  { id: 15, source: '2', target: '1' },
  { id: 16, source: '2', target: '2' },
  { id: 17, source: '7', target: '8' },
  { id: 18, source: '5', target: '9' },
  { id: 19, source: '4', target: '3' },
  { id: 20, source: '3', target: '11' },
  { id: 21, source: '7', target: '4' },
  { id: 22, source: '7', target: '2' },
  { id: 23, source: '4', target: '9' }]

// 图表数据
const chartData = {
  主题分布: {
    topics: topics,
    links: links,
    color: 'rgba(104, 229, 186, 1)'
  },
  利益相关者: {
    topics: subjectTopics,
    links: subjectLinks,
    color: 'rgba(55, 162, 218, 1)'
  }
}

export { chartData }
