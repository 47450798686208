const axisSettins = [
  'isShowRightYAxis',
  'isShowLeftYLabel',
  'isShowRightYLabel',
  'boundaryGap',
  'inlineType',
  'splitLineWidth',
  'splitLineColor',
  'xInlineType',
  'xSplitLineWidth',
  'xSplitLineColor',
  'interval',
  'axisLineWidth',
  'XAxisLineColor',
  'YAxisLineWidth',
  'axisLineColor',
  'YlabelToAxis',
  'XlabelToAxis',
  'axisFontSize',
  'YAxisFontSize',
  'axisFontColor',
  'axisTickColor',
  'axisTickWidth',
  'axisTickLength',
  'axisTickInterval',
  'y_axis_max_value',
  'y_axis_unit',
  'gridPosition',
  'areaColor',
  'echarts_timestamp_format'
]

export { axisSettins }
