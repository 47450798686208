import echarts from 'echarts'

function BarSort (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  // 初始化
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const AllData = props.data[0].AllData
  const frontField = props.data[0]

  var yData = []
  var barData = AllData
  // 排序： 降序
  barData = barData.sort(function (a, b) {
    if (frontField.isAescending === true) {
      return b.value - a.value
    } else {
      return a.value - b.value
    }
  })
  for (var j = 0; j < barData.length; j++) {
    if (j < 10) {
      yData.push('0' + j + barData[j].name)
    } else {
      yData.push(j + barData[j].name)
    }
  }

  function AfterTextAlign (res) {
    const base = {
      左对齐: 'left',
      右对齐: 'right',
      居中对齐: 'inside'
    }
    return base[res]
  }

  const option = {
    backgroundColor: 'rgba(0,0,0,0)',
    grid: {
      left: 10,
      top: 10,
      bottom: 20,
      containLabel: true,
      width: '800'
    },
    xAxis: {
      show: false
    },
    yAxis: {
      type: 'category',
      inverse: true,
      // nameGap: frontField.yNameGap,
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        interval: 0,
        margin: frontField.YlabelToAxis,
        textStyle: {
          color: '#fff',
          align: 'left',
          fontSize: frontField.textFontSize
        },
        rich: {
          a: {
            color: frontField.textColor,
            backgroundColor: frontField.textColorList[0],
            width: frontField.iconWidth,
            height: frontField.iconHeight,
            align: 'center',
            borderRadius: 2
          },
          b: {
            color: frontField.textColor,
            backgroundColor: frontField.textColorList[1],
            width: frontField.iconWidth,
            height: frontField.iconHeight,
            align: 'center',
            borderRadius: 2
          }
        },
        formatter: function (params) {
          if (parseInt(params.slice(0, 2)) < 3) {
            return [
              '{a|' + (parseInt(params.slice(0, 2)) + 1) + '}' + '  ' + params.slice(2)
            ].join('\n')
          } else {
            return [
              '{b|' + (parseInt(params.slice(0, 2)) + 1) + '}' + '  ' + params.slice(2)
            ].join('\n')
          }
        }
      },
      data: yData
    },
    series: [
      {
        name: 'barSer',
        type: 'bar',
        visualMap: false,
        zlevel: 2,
        barMaxWidth: frontField.barWidth,
        barMinHeight: 50,
        barGap: 0,
        // AfterTextAlign(frontField.textAlign)
        itemStyle: {
          normal: {
            color: function (params) {
              var colorList = [{
                colorStops: [{
                  offset: 0,
                  color: frontField.textColorList[0]
                }, {
                  offset: 1,
                  color: frontField.endTextColorList[0]
                }]
              },
              {
                colorStops: [{
                  offset: 0,
                  color: frontField.textColorList[1]
                }, {
                  offset: 1,
                  color: frontField.endTextColorList[1]
                }]
              }
              ]
              if (params.dataIndex < 3) {
                return colorList[0]
              } else {
                return colorList[1]
              }
            },
            barBorderRadius: frontField.barBorderRadius
          }
        },
        label: {
          position: AfterTextAlign(frontField.textAlign),
          show: frontField.isShowBarLabel,
          color: frontField.barLabelColor,
          fontSize: frontField.barLabelFontSize
        },
        data: barData
      }]
  }

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)
}

export default BarSort // 修改为Classname
