import chart1 from './chart-1'
import chart2 from './chart-2'
import chart3 from './chart-3'
import chart4 from './chart-4'
import chart5 from './chart-5'

const allChart = function (name) {
  const chartMap = {
    // 词云
    chart1,
    chart2,
    chart3,
    chart4,
    chart5
  }

  let target = chartMap[name]
  if (target === undefined) {
    target = chart1
  }

  return target
}

export default allChart
