// import echarts from 'echarts'
import { legendNum, randomVlaue } from './com'
const chart2 = function (params) {
  // 数据初始化
  const pieColors = ['rgba(68, 197, 255, 1)', 'rgba(255, 202, 55, 1)']

  const centerPosition = [110, 110]
  const echartData = [
    {
      name: '线上订单',
      // 生成随机数
      value: randomVlaue(),
      itemStyle: {
        color: pieColors[0],
        shadowColor: pieColors[0],
        borderWidth: 2,
        borderColor: pieColors[0],
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowBlur: 4
      }
    }, {
      name: '线下订单',
      value: randomVlaue(),
      itemStyle: {
        color: pieColors[1],
        shadowColor: pieColors[1]
      }
    }
  ]
  const legendInfo = legendNum(echartData)
  const legendSetting = {
    top: '40',
    left: '231',
    icon: 'diamond',
    itemWidth: 6,
    itemHeight: 12,
    itemGap: 50,
    orient: 'vertical',
    align: 'auto',
    data: [{ name: '线下订单' }, { name: '线上订单' }],
    formatter: function (name) {
      return `{a|${name}:}`
    },
    textStyle: {
      rich: {
        a: {
          fontSize: 14,
          fontFamily: 'PingFangRegular',
          color: 'rgba(255, 255, 255, 1)',
          lineHeight: 20,
          marginBottom: 50
        }
      }
    }
  }

  // option
  const option = {
    backgroundColor: '#FFFFFF',
    color: ['#06FDBC', '#F6FE05', '#07B0FE', '#FDB408', '#00DB1C', '#0188FE'],
    tooltip: {
      show: false
    },
    graphic: {
      // 自定义内容
      elements: [{
        type: 'rect',
        z: 3,
        shape: {
          width: 225,
          height: 148,
          r: [2, 2, 2, 2]
        },
        style: {
          lineWidth: 1,
          fill: 'rgba(68, 197, 255, 0.11)',
          stroke: 'rgba(68, 197, 255, 0.4)'
        },
        left: 217,
        top: 28,
        position: [100, 100]
      }, {
        type: 'text',
        z: 3,
        left: 235,
        top: 142,
        style: {
          // 线上
          text: `${legendInfo[0].value}  ${legendInfo[0].percent}%`,
          font: '20px "SonyEricssonLogo"',
          lineWidth: 0,
          fill: pieColors[0],
          stroke: pieColors[0]
        }
      }, {
        type: 'text',
        z: 3,
        left: 235,
        top: 75,
        style: {
          text: `${legendInfo[1].value}  ${legendInfo[1].percent}%`,
          font: '20px "SonyEricssonLogo"',
          lineWidth: 0,
          fill: pieColors[1],
          stroke: pieColors[1]
        }
      }]
    },
    title: [{
      text: '线 上',
      show: false,
      top: '75',
      textAlign: 'center',
      left: '105',
      textStyle: {
        color: pieColors[0],
        fontSize: 16,
        fontWeight: '400',
        fontFamily: 'YueRoundedGothic',
        lineHeight: 34
      }
    }, {
      text: '订 单',
      show: false,
      top: '100',
      textAlign: 'center',
      left: '105',
      textStyle: {
        color: pieColors[0],
        fontSize: 16,
        fontWeight: '400',
        fontFamily: 'YueRoundedGothic',
        lineHeight: 34
      }
    }],
    legend: legendSetting,
    toolbox: {
      show: false
    },
    series: [
    //   {
    //   name: '',
    //   type: 'pie',
    //   radius: [0, 40],
    //   center: centerPosition,
    //   label: {
    //     show: false
    //   },
    //   itemStyle: {
    //     normal: {
    //       color: 'rgba(255, 255, 255, 0.1)'
    //     }
    //   },
    //   hoverAnimation: false,
    //   data: [100],
    //   animation: false
    // }, {
    //   name: '',
    //   type: 'pie',
    //   radius: [46, 50],
    //   center: centerPosition,
    //   label: {
    //     show: false
    //   },
    //   itemStyle: {
    //     normal: {
    //       color: 'rgba(255, 255, 255, 0.2)'
    //     }
    //   },
    //   hoverAnimation: false,
    //   data: [100],
    //   animation: false
    // }, {
    //   name: 'dashed pie',
    //   type: 'pie',
    //   radius: [69, 70],
    //   center: centerPosition,
    //   label: {
    //     show: false
    //   },
    //   itemStyle: {
    //     normal: {
    //       color: 'rgba(255, 255, 255, 0.2)'
    //     }
    //   },
    //   hoverAnimation: false,
    //   data: dashedLineData('rgba(255, 255, 255, 0.2)', 1.6, 100),
    //   animation: false
    // },
      {
        name: 'second',
        type: 'pie',
        radius: [88, 90],
        startAngle: 90,
        center: centerPosition,
        label: {
          normal: {
            show: false
          }
        },
        itemStyle: {
          normal: {
          // shadowBlur: 10,
            shadowColor: pieColors[1]
          }
        },
        hoverAnimation: false,
        hoverOffset: 0,
        data: echartData
      }]
  }

  return option
}

export default chart2
