import './PicCarouselTen.css'
import allHtml from './all'

function htmlFunc (element, props) {
  console.log(props.data[0].barStandardSelect)
  // ----------------------------------
  //   todo 项目变量设置
  // ----------------------------------
  // ----------------------------------
  // const contentWidth = props.data[0].parentWidth
  // const contentHeight = props.data[0].parentHeight
  const params = props.data[0]
  // const delayTime = props.data[0].delayTime

  const backgroundColor = 'rgba(1,1,1,0)'
  // const backgroundColor = props.data[0].backgroundColor

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const body = element.children[0]
  body.setAttribute('style', 'width: 100%;height: 100%; overflow: hidden; position: relative; background-color: ' + backgroundColor)
  // 初始化
  // allHtml('html1')(body, params)
  const barStandardSelect = props.data[0].barStandardSelect
  allHtml(barStandardSelect)(body, params)

  const Play = function () {

    // mySwiper()
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default htmlFunc // 修改为Classname
