<!-- 事件画像-昆明火车站暴力恐怖案 -->
<template>
  <div class="chart_9">
    <div ref="chartDom" :style="chartBox"></div>
    <div class="modal" v-show="showModal" v-click-outside="clickOutside">
      <p class="modal_title" :title="curData.title">{{ curData.title }}</p>
      <div class="max_num_box">
        <div class="num_box">
          {{ curData.maxNum }}
        </div>
        <span class="tips">{{curData.maxType}}</span>
      </div>
      <div class="line_chart" ref="line"></div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import initChart from '../chart/chart9'
import lineOptions from './options/line'
import { influences, lineDataMap } from './data-source/topic'
export default {
  data () {
    return {
      // 是否显示弹窗
      showModal: false,
      // 当前所点击的数据，可以在点击事件时赋值，重置整个对象
      curData: {
        // 标题
        title: '',
        // 主影响力倾向
        maxNum: 0,
        maxType: ''
      },
      // 折线图实例
      lineChart: null
    }
  },
  computed: {
    chartBox () {
      const style = {
        width: this.frontDict.parentWidth + 'px',
        height: this.frontDict.parentHeight + 'px'
      }
      return style
    }
  },
  watch: {
    showModal: {
      immediate: true,
      handler: function (val, oldVal) {
        this.$nextTick(() => {
          if (val) {
            this.initLine()
          } else {
            if (this.lineChart) {
              this.lineChart.dispose()
            }
          }
        })
      }
    }
  },
  mounted () {
    this.initChart()
  },
  methods: {
    initChart (category) {
      if (category === undefined) {
        category = '2017'
      }
      this.frontDict.category = category
      const option = initChart(this.frontDict)
      const targetDom = this.$refs.chartDom
      if (targetDom) {
        const myChart = echarts.init(targetDom)
        myChart.setOption(option)

        const self = this
        myChart.on('click', function (params) {
          if (params.dataType === 'node') {
          // console.log('click params', params)
            const name = params.name
            // 修改弹窗数据
            self.curData.title = params.name
            self.curData.maxNum = influences[name].value
            self.curData.maxType = influences[name].type
            const lineData = lineDataMap[name]
            // console.log('line data', lineData)
            if (lineData === undefined) {
              lineOptions.series[0].data = []
            } else {
              lineOptions.series[0].data = lineData
            }
            self.showModal = !self.showModal
          }
        })
      }
    },
    // 初始化折线图
    initLine () {
      this.lineChart = echarts.init(this.$refs.line)
      this.lineChart.setOption(lineOptions)
    },
    clickOutside (e) {
      console.log('点击外部元素')
      if (this.showModal) {
        this.showModal = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart_9 {
    position: relative;
    .modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 480px;
      height: 400px;
      background-image: url('./img/chart_9_model.png');
      background-repeat: no-repeat;
      background-size: 100%;
      padding: 0 30px;
      .modal_title {
        width: 100%;
        margin-top: 58px;
        padding-right: 30px;
        line-height: 25px;
        color: #FFFFFF;
        font-size: 18px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .max_num_box {
        position: absolute;
        display: flex;
        align-items: center;
        top: 130px;
        left: 138px;
        height: 40px;
        .num_box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 40px;
          background: rgba(159, 230, 184, 0.1);
          border: 1px solid #9FE6B8;
          margin-right: 15px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 25px;
          color: #9FE6B8;
        }
        .tips {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 25px;
          color: #9FE6B8;
        }
      }
      .line_chart {
        position: absolute;
        left: 40px;
        right: 30px;
        bottom: 23px;
        height: 160px;
      }
    }
  }
</style>
