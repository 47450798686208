function numMove (targetnode) {
  var count = 0
  var result = ''
  // console.log(targetnode);
  var elem3 = targetnode
  var targetNum = elem3.innerText
  // var id3 = setInterval(ascIteration, 0.3);
  var move = setInterval(numAsc, 50)

  // TODO 新方法思路: 针对每一位进行递增
  // 1. 获取数字的每一位上的数字
  // 2. 每次 +1, 直到 除以 10 余数等于自身,停止,
  // 3. 每次运行结果 合并为新的字符

  function splitNum (str) {
    var numArray = []
    for (let i = 0; i < str.length; i++) {
      var currentStr = str.charAt(i)
      if (currentStr !== ',' && currentStr !== '.' && currentStr !== ',' && currentStr !== ' ') {
        var currentNum = parseInt(currentStr)
        // console.log(currentNum)
        currentNum += Math.round(Math.random() * 10)
        if (currentNum < 10) {
          numArray.push(currentNum)
        } else {
          currentNum = currentNum % 10
          // console.log('获取余数 ', currentNum);
          numArray.push(currentNum)
        }
      } else {
        numArray.push(currentStr)
      }
    }
    numArray.reverse()
    var res = ''
    for (var i = 0; i < numArray.length; i++) {
      res = res + numArray[i]
    }
    return res
  }

  function numAsc () {
    if (count < 20) {
      if (result === '') {
        result = targetNum
      }
      // console.log(result)
      result = splitNum(result)
      elem3.innerHTML = result
      count++
    } else {
      // console.log(result)
      elem3.innerHTML = targetNum
      clearInterval(move)
    }
  }
}

const autoChange = function (oriDom) {
  const targetDom = oriDom
  return function () {
    // console.log(targetDom)
    // 变化的数值 dom
    const ascDom = targetDom.children[0].children[1].children[0].children[0]
    // 数值的符号
    // let numIcon = targetDom.children[0].children[1].children[0].children[0]
    // console.log(ascDom)
    // let oriValue = ascDom.innerText
    // let oriValueInt = oriValue.replace('+', '')
    const ascValue = Math.round(Math.random() * 50 - 25)
    if (ascValue >= 0) {
      ascDom.innerHTML = '+' + ascValue
    } else {
      ascDom.innerHTML = ascValue
    }

    // 总值dom
    const sumDom = targetDom.children[0].children[0].children[0].children[0]
    const oriSum = sumDom.innerText
    sumDom.innerHTML = parseInt(oriSum) + parseInt(ascValue)
  }
}

export { numMove, autoChange }
