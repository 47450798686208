import echarts from 'echarts'
import { hideDelayTime, trigger, commonFunc } from '../chartCommonPart'

function BarAnimation (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  // 初始化
  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  // eushow 3.0
  console.log(props.colorTheme)
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const AllData = props.data[0].all_data
  const xAxisData = props.data[0].xAxisData
  const legend = props.data[0].legend
  // const title = props.data[0].title
  const YAxisUnit = props.data[0].y_axis_unit
  const XAxisUnit = props.data[0].x_axis_unit
  const XAxisLabel = props.data[0].x_axis_label

  var series = []
  for (let j = 0; j < AllData.length; j++) {
    series.push({
      name: legend[j],
      type: 'bar',
      data: AllData[j],
      animationDelay: function (idx) {
        return idx * 10 + 10 + 90 * j
      }
    })
  }

  const option = {
    title: {
      // text: title
    },
    legend: {
      right: '8%',
      top: '2%',
      data: legend,
      itemWidth: 10,
      itemHeight: 10,
      textStyle: {
        fontSize: 10,
        color: '#fff'
      }
    },
    tooltip: {
      triggerOn: trigger,
      hideDelay: hideDelayTime
    },
    grid: {
      // left: '3%',
      right: '9%',
      top: '15%',
      width: '88%',
      height: '80%',
      containLabel: true
    },
    xAxis: {
      data: xAxisData,
      silent: false,
      name: XAxisLabel,
      // middle center start
      nameLocation: 'end',
      nameTextStyle: {
        fontSize: 10,
        color: 'rgb(195,195,195)',
        align: 'left'
      },
      nameGap: 5,
      splitLine: {
        show: false
      },
      axisLabel: {
        formatter: '{value} ' + XAxisUnit,
        textStyle: {
          fontSize: 10,
          color: '#fff'
        }
      }
    },
    yAxis: {
      axisLabel: {
        formatter: '{value} ' + YAxisUnit,
        textStyle: {
          fontSize: 10,
          color: '#fff'
        }
      },
      splitLine: {
        lineStyle: {
          width: 1,
          color: 'rgba(109,109, 109)'
        }
      }
    },
    series: series,
    animationEasing: 'elasticOut',
    animationDelayUpdate: function (idx) {
      return idx * 5
    }
  }

  const startPlay = commonFunc(myChart, option, props)

  return startPlay
}

export default BarAnimation // 修改为Classname
