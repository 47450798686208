/**
 * 舆情爆发情况
 */
import allData from './data-source/chart-5'
const chart = function () {
  // y 轴列表
  const yList = Object.keys(allData)
  const themeList = []
  const othersList = []
  for (const key in allData) {
    const { theme, all } = allData[key]
    themeList.push(theme)
    othersList.push(all - theme)
  }

  const option = {
    color: ['#43A7FD', '#FECC51'],
    tooltip: {
      show: true
    },
    legend: {
      top: '0px',
      left: '0px',
      itemGap: 25,
      data: ['衍生主题', '非衍生主题'],
      textStyle: {
        color: 'rgba(255, 255, 255, 1)'
      }
    },
    grid: {
      left: 5,
      right: 50,
      bottom: 5,
      top: 40,
      containLabel: true
    },
    xAxis: [
      {
        type: 'value',
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.6)'
          }
        },
        axisLabel: {
          color: '#ffffff',
          fontSize: 14
        }
      },
      {
        type: 'value',
        axisTick: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.6)'
          }
        }
      }
    ],
    yAxis: {
      type: 'category',
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisLabel: {
        color: '#ffffff',
        margin: 16
      },
      data: yList
    },
    series: [
      {
        name: '衍生主题',
        type: 'bar',
        stack: '舆情',
        label: {
          show: false
        },
        barWidth: '12px',
        data: themeList
      },
      {
        name: '非衍生主题',
        type: 'bar',
        stack: '舆情',
        label: {
          show: false
        },
        barWidth: '12px',
        data: othersList
      }
    ]
  }
  return option
}

export default chart
