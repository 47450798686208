import echarts from 'echarts'

function barPictorialBarSecond (element, props) {
  // console.log(props)
  const backgroundColor = 'rgba(1,1,1,0)'
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const myChart = echarts.init(element.children[0], props.colorTheme)

  const originData = props.data[0].content_data
  const xAxisData = props.data[0].xAxisData
  // const echartsThemeColor = props.data[0].color_echarts_theme
  const legend = props.data[0].legend
  // const backgroundColor = props.data[0].backgroundColor
  const axisLineColor = props.data[0].axisLineColor
  const axisFontColor = props.data[0].axisFontColor
  const XAxisLineColor = props.data[0].XAxisLineColor
  const inlineType = props.data[0].inlineType
  const barWidth = props.data[0].barWidth
  const isShowRightYAxis = props.data[0].isShowRightYAxis
  const isShowRightYLabel = props.data[0].isShowRightYLabel
  const isShowLeftYLabel = props.data[0].isShowLeftYLabel
  const axisLableColor = props.data[0].axisLableColor
  const barLabelFontSize = props.data[0].barLabelFontSize
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const yAxisMaxValue = props.data[0].yMaxValueList || props.data[0].y_axis_max_value
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const yAxisUnit = props.data[0].yAxisUnit || props.data[0].y_axis_unit
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const secondyYAxisUnit = props.data[0].secondyYAxisUnit || props.data[0].second_y_axis_unit

  const axisFontSize = props.data[0].axisFontSize
  const YlabelToAxis = props.data[0].YlabelToAxis
  const XlabelToAxis = props.data[0].XlabelToAxis

  const barBorderRadius = props.data[0].barBorderRadius
  const axisLineWidth = props.data[0].axisLineWidth
  const YAxisLineWidth = props.data[0].YAxisLineWidth

  const pictorialBarType = props.data[0].pictorialBarType
  const pictorialBarHeight = props.data[0].pictorialBarHeight
  const pictorialBarOffsetY = props.data[0].pictorialBarOffsetY
  const pictorialBarWidth = props.data[0].pictorialBarWidth
  const pictorialBarOffsetX = props.data[0].pictorialBarOffsetX
  const picOffsetXList = props.data[0].picOffsetXList

  const colorList = ['#AEEEED', '#36FFFC', '#68D0FF']

  const barLabel = {
    position: 'top',
    //  label 距离顶部的位置
    distance: 20,
    //   data ==> 该范围内的 data
    formatter: '{c}',
    // formatter: '{b}数量: {c}',
    fontFamily: 'Helvetica Narrow',
    textStyle: {
      color: axisLableColor,
      fontSize: barLabelFontSize,
      fontFamily: 'Helvetica Narrow'
    }
  }

  const series = []
  for (let i = 0; i < originData.length; i++) {
    series.push(
      {
        name: legend[i],
        type: 'bar',
        label: barLabel,
        barGap: '100%',
        // barCategoryGap: '20%',
        // barGap: '16',
        // barCategoryGap: '150',
        itemStyle: {
          opacity: 0.4,
          color: colorList[i],
          barBorderRadius: barBorderRadius
        },
        barWidth: barWidth,
        yAxisIndex: 0,
        data: originData[i]
      }
    )
    series.push(
      {
        name: legend[i],
        type: 'pictorialBar',
        symbol: pictorialBarType,
        barGap: '100%',
        // barCategoryGap: '20%',
        symbolPosition: 'end',
        symbolSize: [pictorialBarWidth, pictorialBarHeight],
        symbolOffset: [pictorialBarOffsetX + picOffsetXList[i], pictorialBarOffsetY],
        z: 10,
        yaxisIndex: 1,
        tooltip: {
          show: false
        },
        itemStyle: {
          normal: {
            color: colorList[i]
          }
        },
        data: originData[i]
      }
    )
  }

  const option = {
    backgroundColor: backgroundColor,
    // color: ['#01effc', '#5affc2'],
    grid: {
      left: '10',
      right: '10',
      top: '10',
      bottom: '10',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          show: true,
          color: '#5affc2',
          backgroundColor: '#072B4D',
          //   borderColor:'none',
          //   shadowColor:'none',
          shadowBlur: 0
        }
      }
    },
    legend: {
      show: false,
      right: '7%',
      top: '3%',
      itemGap: 10,
      itemHeight: 10,
      itemWidth: 10,
      textStyle: {
        fontSize: 10,
        color: '#fff'
      },
      data: legend
    },
    xAxis: [
      {
        type: 'category',
        //   formatter: '{value}',
        axisTick: {
          show: false,
          color: axisFontColor
        },
        axisLabel: {
          // rotate: '-45',
          margin: XlabelToAxis,
          textStyle: {
            fontSize: axisFontSize,
            color: axisFontColor
          }
        },
        axisLine: {
          // onZero: false,
          lineStyle: {
            width: axisLineWidth,
            color: XAxisLineColor
          }
        },
        splitLine: {
          show: false
        },
        data: xAxisData
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: yAxisUnit,
        nameGap: 10,
        max: yAxisMaxValue[0],
        axisTick: {
          show: false
        },
        axisLabel: {
          show: isShowLeftYLabel,
          margin: YlabelToAxis,
          fontSize: axisFontSize,
          color: axisFontColor
        },
        nameTextStyle: {
          fontSize: axisFontSize,
          color: axisFontColor
        },
        axisLine: {
          lineStyle: {
            width: YAxisLineWidth,
            color: axisLineColor
          }
        },
        splitLine: {
          show: true,
          interval: 2,
          lineStyle: {
            width: axisLineWidth,
            type: inlineType,
            color: axisLineColor
            // color: '#eb1c2d'
          }
          //   color: 'rgb(230,23,121)',
        }
      },
      {
        type: 'value',
        axisTick: {
          show: false
        },
        show: isShowRightYAxis,
        name: secondyYAxisUnit,
        // 垂直方向的距离
        nameGap: 10,
        max: yAxisMaxValue[1],
        yAxisIndex: 0,
        position: 'right',
        axisLabel: {
          show: isShowRightYLabel,
          margin: YlabelToAxis,
          fontSize: axisFontSize,
          color: axisFontColor
        },
        nameTextStyle: {
          fontSize: axisFontSize,
          color: axisFontColor
        },
        axisLine: {
          lineStyle: {
            width: YAxisLineWidth,
            color: axisLineColor
          }
        },
        splitLine: {
          show: false
        }
      }
    ],
    series: series
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  function Play () {
    myChart.clear()
    myChart.setOption(option)
    // 监听页面点击事件
    myChart.on('click', function (params) {
      const gorupByValue = params.name
      props.onInteract(gorupByValue)
    })
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default barPictorialBarSecond // 修改为Classname
