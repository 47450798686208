import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

// eslint-disable-next-line no-extend-native
String.prototype.format = function () {
  if (arguments.length === 0) return this
  var param = arguments[0]
  var s = this
  if (typeof (param) === 'object') {
    for (var key in param) {
      s = s.replace(new RegExp('\\{' + key + '\\}', 'g'), param[key])
    }
    return s
  } else {
    for (var i = 0; i < arguments.length; i++) {
      s = s.replace(new RegExp('\\{' + i + '\\}', 'g'), arguments[i])
    }
    return s
  }
}

function MixTimelineFinance (element, props) {
  // --------------------------------------------------------------------------------------------------------//
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  // const myChart = echarts.init(div[0][0].children[0], props.data[0].color_echarts_theme)
  // eushow 3.0
  const myChart = echarts.init(element.children[0], props.colorTheme)

  // -------------------------------------------------------------------------------------------------------//

  // 获取数据
  const allYears = props.data[0].date
  const allYearData = props.data[0].all_year_data
  const sumDatas = props.data[0].sum_datas
  const labels = props.data[0].label

  const series = []
  const options = []

  // 定义循环
  labels.forEach(function (item, i) {
    series.push(
      {
        name: item,
        grid: {
          containLabel: true
        },
        type: 'bar'
      }
    )
  })

  series.push({
    // name: '前三類數據占比',
    type: 'pie',
    center: ['75%', '30%'],
    radius: '28%',
    z: 100,
    animationDuration: 2000
  }
  )

  allYears.forEach(function (item, i) {
    const ser = []
    const pieData = []
    for (let j = 0; j < allYearData.length; j++) {
      ser.push(
        {
          type: 'bar',
          data: allYearData[j][item]
        })
      pieData.push(
        { name: labels[j], value: [sumDatas[j]['{0}sum'.format(item)]] }
      )
    }
    ser.push({
      type: 'pie',
      data: pieData
    })
    options.push({
      title: {
        text: item + '年数据',
        textStyle: {
          color: '#fff'
        }
      },
      series: ser
    })
  })

  const option = {
    baseOption: {
      timeline: {
        // y: 0,
        axisType: 'category',
        // realtime: false,
        // loop: false,
        autoPlay: true,
        // currentIndex: 2,
        playInterval: 1000,
        // controlStyle: {
        //     position: 'left'
        // },

        // 设置底部时间轴：日期、特殊形状、提示词
        // 注意时间的格式 xxxx-xx-xx
        data: props.data[0].time,
        label: {
          formatter: function (s) {
            return (new Date(s)).getFullYear()
          }
        }
      },
      // 每个表的单独标题设置
      // title: {
      //     subtext: '数据来自国家统计局'
      // },
      tooltip: {
        triggerOn: trigger,
        hideDelay: hideDelayTime
      },
      // 图例设置，ｃｏｌｕｍｎｓ提取，表格分组的标题
      legend: {
        x: 'right',
        // 每一類數據的名稱：sum_boys sum_girls
        data: props.data[0].label,
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 10,
          color: '#fff'
        }
        // 默认不显示的内容
        // selected: {
        //     'GDP': false, '金融': false, '房地产': false
        // }
      },
      calculable: true,
      // 图标网格的设置
      grid: {
        top: 70,
        left: 15,
        right: 15,
        bottom: 60,
        containLabel: true,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true,
              formatter: function (params) {
                return params.value.replace('\n', '')
              }
            }
          }
        }
      },
      // 横纵坐标标签的设置
      xAxis: [
        {
          type: 'category',
          // \n  ==> 标签往下挪一行
          data: props.data[0].columns,
          // 是否显示纵向的分割线  ==> 网格线
          // splitLine: {show: false},
          nameTextStyle: {
            color: 'red',
            fontWeitht: 'lighter',
            borderColor: 'transparent'
          },
          axisLabel: {
            interval: 0,
            textStyle: {
              fontSize: 10,
              color: '#fff'
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              width: 1,
              color: 'rgba(109,109, 109)'
            }
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: props.data[1].yaxis_name,
          axisLabel: {
            textStyle: {
              fontSize: 10,
              color: '#fff'
            }
          },
          splitLine: {
            lineStyle: {
              width: 1,
              color: 'rgba(109,109, 109)'
            }
          }
        }
      ],
      // 表格类型: 这儿的名称 ==> 鼠标提示语
      series: series
    },
    options: options
  }
  // 参数加载
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default MixTimelineFinance
