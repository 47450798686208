var EchartsSchemes = {
  defaultKey: 'dark',
  default: {
    colors: [
      '#43bafe',
      '#51ffc1',
      '#f39e39',
      '#01effc',
      '#e8ea00',
      '#c8d8ff'
    ]
  },
  themeOne: {
    colors: [
      '#e01f54',
      '#001852',
      '#f5e8c8',
      '#b8d2c7',
      '#c6b38e',
      '#a4d8c2',
      '#f3d999',
      '#d3758f',
      '#dcc392',
      '#2e4783',
      '#82b6e9',
      '#ff6347',
      '#a092f1',
      '#0a915d',
      '#eaf889',
      '#6699FF',
      '#ff6666',
      '#3cb371',
      '#d5b158',
      '#38b6b6'
    ]
  },
  themeTwo: {
    colors: [
      '#118dee',
      '#25bef7',
      '#79e9ff',
      '#7b42dd',
      '#f8ae00',
      '#ef6553',
      '#e02e30',
      '#f7bb01',
      '#f28900'
    ]
  },
  dark: {
    colors: [
      '#dd6b66',
      '#759aa0',
      '#e69d87',
      '#8dc1a9',
      '#ea7e53',
      '#eedd78',
      '#73a373',
      '#73b9bc',
      '#7289ab',
      '#91ca8c',
      '#f49f42'
    ],
    description: '',
    id: 'dark',
    label: 'dark'
  },

  essos: {
    colors: [
      '#893448',
      '#d95850',
      '#eb8146',
      '#ffb248',
      '#f2d643',
      '#ebdba4'
    ],
    description: '',
    id: 'essos',
    label: 'essos'
  },

  roam: {
    colors: [
      '#e01f54',
      '#001852',
      '#f5e8c8',
      '#b8d2c7',
      '#c6b38e',
      '#a4d8c2',
      '#f3d999',
      '#d3758f',
      '#dcc392',
      '#2e4783',
      '#82b6e9',
      '#ff6347',
      '#a092f1',
      '#0a915d',
      '#eaf889',
      '#6699FF',
      '#ff6666',
      '#3cb371',
      '#d5b158',
      '#38b6b6'
    ],
    description: '',
    id: 'roma',
    label: 'roma'
  },

  vintage: {
    colors: [
      '#d87c7c',
      '#919e8b',
      '#d7ab82',
      '#6e7074',
      '#61a0a8',
      '#efa18d',
      '#787464',
      '#cc7e63',
      '#724e58',
      '#4b565b'
    ],
    description: '',
    id: 'vintage',
    label: 'vintage'
  },

  walden: {
    colors: [
      '#3fb1e3',
      '#6be6c1',
      '#626c91',
      '#a0a7e6',
      '#c4ebad',
      '#96dee8'
    ],
    description: '',
    id: 'walden',
    label: 'walden'
  },

  westeros: {
    colors: [
      '#516b91',
      '#59c4e6',
      '#edafda',
      '#93b7e3',
      '#a5e7f0',
      '#cbb0e3'
    ],
    description: '',
    id: 'westeros',
    label: 'westeros'
  },

  wonderland: {
    colors: [
      '#4ea397',
      '#22c3aa',
      '#7bd9a5',
      '#d0648a',
      '#f58db2',
      '#f2b3c9'
    ],
    description: '',
    id: 'wonderland',
    label: 'wonderland'
  }
}

// 导出变量
export { EchartsSchemes }
