const chart1 = function (params) {
  const series = []
  var itemStyle = {
    normal: {
      barBorderRadius: params.barBorderRadius
    },
    emphasis: {
      barBorderRadius: params.barBorderRadius,
      barBorderWidth: 1,
      shadowBlur: 10,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowColor: 'rgba(0,0,0,0.5)'
    }
  }
  // TODO 给bar图 添加渐变色
  if (params.cancelBarLineColor === false) {
    itemStyle.normal = {
      color: params.lineColor
    }
  }

  params.content_data.forEach(function (item, i) {
    if (params.table_type === 'bar' && params.content_data.length === 1) {
      series.push({
        name: params.legend[i],
        type: params.table_type,
        stack: params.stack_res[i],
        barWidth: params.barWidth,
        itemStyle: {
          // color: barColor
          barBorderRadius: params.barBorderRadius,
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 1, color: '#124A4C' // 0% 处的颜色
            }, {
              offset: 0, color: params.lineColor // 100% 处的颜色
            }],
            global: false // 缺省为 false
          }
        },
        data: item
      })
    } else if (params.table_type === 'line' && params.content_data.length === 1) {
      series.push({
        name: params.legend[i],
        type: 'line',
        stack: params.stack_res[i],
        itemStyle: itemStyle,
        lineStyle: {
          width: params.lineWidth
        },
        data: item,
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 1, color: 'rgba(255,255,255,0)'
            }, {

              offset: 0.3, color: params.lineColor
            }, {

              offset: 0, color: params.lineColor
            }
            ]
          }
          // opacity: 0.4
        }
      })
    } else {
      // console.log('third', endTextColorList)
      series.push({
        name: params.legend[i],
        type: params.table_type,
        barWidth: params.barWidth,
        stack: params.stack_res[i],
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 1.0, color: params.textColorList[i] // 0% 处的颜色
            }, {
              offset: 0.2, color: params.endTextColorList[i] // 100% 处的颜色
            }],
            global: false // 缺省为 false
          },
          barBorderRadius: params.barBorderRadius,
          barBorderWidth: 1,
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'rgba(0,0,0,0.5)'
        },
        data: item
      })
    }
  })

  return series
}

export default chart1
