import chart1 from './chart-1'
import chart2 from './chart-2'
import chart3 from './chart-3'
// import chart4 from './chart-4'
// import chart5 from './chart-5'

const allChart = function (name) {
  const chartMap = {
    // 天气-01
    chart1,
    // 天气-02
    chart2,
    // 天气-03
    chart3
  }

  let target = chartMap[name]
  if (target === undefined) {
    target = chart1
  }

  return target
}

export default allChart
