/* eslint-disable vue/require-v-for-key */
<!-- 事件画像-山东问题疫苗事件 -->
<template>
  <div class="chart_11">
    <!-- tab 切换 -->
    <div class="tabs">
      <div
        class="tab_item"
        :class="{'active':item === activeName}"
        v-for="item in tabList"
        :key="item"
        @click="toggleTab(item)">
        {{ item }}
      </div>
    </div>
    <!-- 关系图图表 -->
    <div ref="chartDom" :style="chartBox"></div>
    <!-- 弹窗 -->
    <div
      class="modal"
      v-show="showModal"
      v-click-outside="clickOutside">
      <p class="modal_title" :title="curData.title">{{ curData.title }}</p>
      <div class="bar_box">
        <div class="bar" :style="barStyle"></div>
        <span class="bar_num">{{ curData.barNum }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import getOptions from '../chart/chart11'
import { chartData, category } from './data-source/data'

export default {
  data () {
    return {
      // 是否显示弹窗
      showModal: false,
      // chart 实列
      myChart: null,
      // 当前激活项
      activeName: '主题分布',
      // tab切换
      tabList: ['主题分布', '主题演化'],
      // 当前所点击的数据，可以在点击事件时赋值，重置整个对象
      curData: {
        // 标题
        title: '',
        // 博文相关数量
        barNum: 0
      }
    }
  },
  computed: {
    chartBox () {
      const style = {
        width: this.frontDict.parentWidth + 'px',
        height: this.frontDict.parentHeight + 'px'
      }
      return style
    },
    // 当前数据的柱状图宽
    barStyle () {
      let width = 0
      const maxValue = this.activeName === '主题分布' ? 1170 : 820
      if (this.curData && this.curData.barNum) {
        const barNum = Number(this.curData.barNum)
        // 假设最大值为50万
        width = parseInt((barNum / maxValue) * 240)
      }
      return {
        width: `${width}px`
      }
    },
    // 分类
    category () {
      return category
    },
    // 图表数据
    chartData () {
      return chartData
    },
    // 当前所选择的tab data
    curChartData () {
      const topicData = this.$clone(chartData[this.activeName])
      // console.log(topicData, this.frontDict, 'res')
      const scale = this.frontDict.circleSize
      // 修改点的比例
      const newData = []
      topicData.topics.forEach(function (ele) {
        ele.symbolSize = Math.round(ele.symbolSize * scale, 2)
        newData.push(ele)
      })
      return topicData
    }
  },
  mounted () {
    console.log(this.frontDict)
    this.initChart()
  },
  methods: {
    // 初始化图表
    initChart () {
      const option = getOptions(this.curChartData)
      const targetDom = this.$refs.chartDom
      if (targetDom) {
        this.myChart = echarts.init(targetDom)
        this.myChart.setOption(option)

        const self = this
        this.myChart.on('click', function (params) {
          if (params.dataType === 'node') {
            self.curData.title = params.data.name
            self.curData.barNum = params.data.value
            self.showModal = !self.showModal
          }
        })
      }
    },
    // 外部点击
    clickOutside (e) {
      if (this.showModal) {
        this.showModal = false
      }
    },
    /**
     * 切换tab
     *  + 设置当前激活的选项
     *  + 如果弹窗打开，关闭弹窗
     */
    toggleTab (value) {
      this.activeName = value
      const chartOption = getOptions(this.curChartData)
      this.myChart.setOption(chartOption)
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart_11 {
    position: relative;
    .tabs {
      position: absolute;
      top: 20px;
      left: 20px;
      display: flex;
      height: 32px;
      min-height: 32px;
      z-index: 10000;
      .tab_item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        height: 32px;
        background-image: url('../pic/btn-border-1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.9);
        cursor: pointer;
        &.active {
          background-image: url('../pic/btn-bg-blue.png');
        }
        &+.tab_item {
          margin-left: 10px;
        }
      }
    }
    .modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 480px;
      height: 300px;
      // 主题详情背景图
      background-image: url('./img/chart_11_model1.png');
      background-repeat: no-repeat;
      background-size: 100%;
      padding: 0 30px;
      .modal_title {
        width: 100%;
        margin-top: 58px;
        padding-right: 30px;
        line-height: 25px;
        color: #FFFFFF;
        font-size: 18px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .bar_box {
        position: absolute;
        display: flex;
        align-items: center;
        height: 30px;
        top: 112px;
        left: 120px;
        .bar {
          height: 16px;
          max-width: 240px;
          background: #3CCAEC;
          transition: all 0.3s;
          margin-right: 10px;
        }
        .bar_num {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 28px;
          color: #3CCAEC;
        }
      }
    }
  }
</style>
