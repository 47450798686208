/**
 * 在此图表
 *  + lineColor
 *    此配置项是一个颜色选择器，只单单选择了一个颜色，但是因为历史原因
 *    旧的图表数据库存储的是数组，返回的是一个颜色数组
 *    但配置项移动到前端处理后，lineColor，只会是一个string
 *    所以，此处的lineColor会有两类图表会用到
 *      + 以前制作的旧图表，其配置项以存储在了数据库，lineColor可能是一个数组
 *      + superset 中制作的图表，其lineColor可能是颜色数组
 *    对其进行兼容处理
 *      + 返回的是字符串的话，则new Array一个contentData.length长度的数组，值都为此lineColor
 *      + 返回的是数组的话，是老数据，则不做处理
 */
import { isOfType } from '../../utils/index'
import { setXaxisDefault, setYaxisDefault, setGrid } from '../../echartSetting/axis'
const chart = function (params) {
  const contentData = params.content_data
  const legend = params.legend
  const tableType = params.table_type

  const lineWidth = params.lineWidth
  const backgroundColor = params.backgroundColor
  const isShowAreaColor = params.isShowAreaColor
  const isShowSeriesLabel = params.isShowSeriesLabel
  const isStack = params.isStack
  const backendDict = params
  let lineColor = params.lineColor

  // 如果lineColor 是字符串的话 则是新的配置项 需要做兼容处理
  if (isOfType.string(lineColor)) {
    const colorList = []
    contentData.forEach(item => {
      colorList.push(lineColor)
    })
    lineColor = colorList
  }

  const baseStyle = function (i, yIndex) {
    let stackName
    if (isStack === true) {
      yIndex = 0
      stackName = 'sameAxis'
    } else {
      stackName = i
    }

    const setting = {
      name: legend[i],
      type: tableType,
      lineStyle: {
        color: lineColor[i],
        width: lineWidth
      },
      // FIXME, 选择不同的坐标轴
      yAxisIndex: yIndex,
      stack: stackName,
      data: contentData[i],
      label: {
        show: isShowSeriesLabel,
        position: 'top',
        color: lineColor[i],
        fontFamily: 'Helvetica Narrow',
        fontSize: 24
      }
    }

    if (isShowAreaColor === true) {
      setting.areaStyle = {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 1, color: 'rgba(255,255,255,0)'
          }, {
            offset: 0, color: lineColor[i]
          }
          ]
        },
        opacity: backendDict.areaColorOpacity
      }
    }
    return setting
  }

  const seriesList = []
  let yIndex = 0
  for (let i = 0; i < contentData.length; i++) {
    // FIXME yAxisIndex 只有两个
    if (i > 1) {
      yIndex = 0
    } else {
      yIndex = i
    }
    seriesList.push(baseStyle(i, yIndex))
  }

  const option = {
    backgroundColor: backgroundColor,
    grid: setGrid(params),
    legend: {
      show: false,
      right: '7%',
      top: '3%',
      // itemGap: 20,
      icon: 'roundRect',
      itemWidth: 10,
      itemHeight: 10,
      textStyle: {
        fontSize: 10,
        color: '#fff'
      },
      data: legend
    },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: setXaxisDefault(params),
    yAxis: setYaxisDefault(params),
    series: seriesList
  }

  return option
}

export default chart
