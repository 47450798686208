import echarts from 'echarts'

function barPictorialBarFirst (element, props) {
  const backgroundColor = 'rgba(1,1,1,0)'
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const myChart = echarts.init(element.children[0], props.colorTheme)

  const originData = props.data[0].content_data
  const xAxisData = props.data[0].xAxisData
  const axisFontColor = props.data[0].axisFontColor
  const lineColor = props.data[0].lineColor
  const barWidth = props.data[0].barWidth
  const axisLableColor = props.data[0].axisLableColor
  const barLabelFontSize = props.data[0].barLabelFontSize
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const yAxisMaxValue = props.data[0].yMaxValueList || props.data[0].y_axis_max_value
  // 此配置项新传来的name可能是下划线，但旧数据可能是驼峰
  const yAxisUnit = props.data[0].yAxisUnit || props.data[0].y_axis_unit

  const axisFontSize = props.data[0].axisFontSize
  const pictorialSymbolType = props.data[0].pictorialSymbolType

  // 切换填充内容
  const symbolTypeDict = {
    // 需要调整 象形图的类型， 大小
    people: ['image:///static/img/chart-icon/people.png', 'image:///static/img/chart-icon/people.png', [14, 12]],
    lineBar: ['image:///static/img/chart-icon/lineBar.png', 'rect', [4, 12]]
  }
  const symbolType = symbolTypeDict[pictorialSymbolType]

  const yLabel = '{b|' + yAxisUnit + '}'

  const outerBarData = []
  for (let i = 0; i < xAxisData.length; i++) {
    outerBarData.push(yAxisMaxValue[0])
  }
  const option = {
    backgroundColor: backgroundColor,
    tooltip: {
      show: false
    },
    xAxis: {
      max: yAxisMaxValue[0],
      splitLine: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisTick: {
        show: false
      }
    },
    grid: {
      left: 30,
      top: 20, // 设置条形图的边距
      right: 180,
      bottom: 20,
      containLabel: true
    },
    yAxis: [{
      type: 'category',
      inverse: false,
      data: xAxisData,
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: true,
        margin: 20,
        color: axisFontColor,
        fontFamily: 'PingFang Medium',
        fontSize: axisFontSize
      },
      splitLine: {
        show: false
      }
    }],
    series: [
      {
        // 柱状图中间的重复图标
        type: 'pictorialBar',
        itemStyle: {
          opacity: 0.5
        },
        emphasis: {
          // 取消鼠标高亮效果
          itemStyle: {}
        },
        symbolRepeat: 'fixed', // 重复规则
        symbolMargin: 0, //  设置重复图标的间隔
        // symbol: "image:///static/assets/images/icons/lineBar.png",
        symbol: symbolType[0],
        symbolClip: true, // 是否可以切割图片，当数据量占比不满一个图片的时候
        symbolSize: [14, 12], // 单个图标的长宽
        symbolPosition: 'start', // 图标的位置
        symbolOffset: [6, -0], // 图标的位置偏移
        symbolBoundingData: yAxisMaxValue[0],
        data: originData[0],
        animationEasing: 'elasticOut',
        label: {
          show: true,
          distance: 20,
          formatter: [
            '{a|{c}  }',
            yLabel
          ].join('\t'),
          position: 'right',
          rich: {
            a: {
              fontSize: barLabelFontSize,
              color: axisLableColor,
              fontFamily: 'Helvetica Narrow',
              marginRight: 10
            },
            b: {
              fontSize: axisFontSize,
              color: axisFontColor
            }
          }
        },
        z: 10
      },
      {
        // 柱状图中间的重复图标
        type: 'pictorialBar',
        itemStyle: {
          color: axisLableColor

        },
        emphasis: {
          // 取消鼠标高亮效果
          itemStyle: {
            color: '#FFA200'
          }
        },
        symbol: symbolType[1],
        symbolSize: symbolType[2], // 单个图标的长宽
        symbolPosition: 'end', // 图标的位置
        symbolOffset: [6, -0], // 图标的位置偏移
        data: originData[0],
        animationEasing: 'elasticOut',
        label: {
          show: false
        },
        z: 50
      },
      {
        // 外部的单线框
        name: '外框',
        type: 'bar',
        barGap: '-120%', // 设置外框间距
        data: outerBarData,
        // 设置外边框的宽度
        barWidth: barWidth,
        itemStyle: {
          normal: {
            color: 'transparent', // 填充色
            barBorderColor: lineColor, // 边框色
            barBorderWidth: 1, // 边框宽度
            // barBorderRadius: 0, //圆角半径
            label: {
              // 标签显示位置
              show: false,
              position: 'top' // insideTop 或者横向的 insideLeft
            }
          }
        },
        z: 0
      }
    ]
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  function Play () {
    myChart.clear()
    myChart.setOption(option)
    // 监听页面点击事件
    myChart.on('click', function (params) {
      const gorupByValue = params.name
      props.onInteract(gorupByValue)
    })
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default barPictorialBarFirst // 修改为Classname
