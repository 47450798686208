import echarts from 'echarts'
import 'echarts/map/js/province/hubei'
// import hubei from 'echarts/map/json/province/hubei.json'
import 'echarts/map/js/world'
import 'echarts/map/js/china'
import { visualmapPiece } from '../../echartSetting/visualmap'
// import mapZoom from './mapZoom'

async function WorldMapEchart (element, props) {
  const divId = 'echarts_Hubei'
  // 建立chart图表的容器， 在HTML中生成div标签 用来插入表格
  element.innerHTML = `<div id="${divId}" style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  var myChart = echarts.init(element.children[0])

  const countryName = props.data[0].countryName
  const newNameMap = props.data[0].nameMap
  const finalData = props.data[0].finalData
  // const xAxisData = props.data[0].xAxisData
  // const legend = props.data[0].legend
  const title = props.data[0].title
  const areaColor = props.data[0].areaColor
  const textFontSize = props.data[0].textFontSize
  const iconHeight = props.data[0].iconHeight
  const iconWidth = props.data[0].iconWidth

  // console.log('countryName', countryName)
  const mapStyle = {
    areaColor: areaColor,
    emphasis: { label: { show: true } }
  }

  let finalSeries = {}

  if (countryName === 'usa') {
    finalSeries = {
      name: title,
      type: 'map',
      mapType: countryName,
      roam: false,
      // 需要地区的经纬度, 西经 用 负值表示; 南纬用 负值表示
      center: [-120, 45],
      zoom: 3,
      label: {
        // 默认是否显示国家名称, 关闭==> 显示会过于拥挤
        show: false
      },
      itemStyle: mapStyle,
      data: finalData,
      nameMap: newNameMap
    }
  } else {
    finalSeries = {
      name: title,
      type: 'map',
      mapType: countryName,
      roam: false,
      // zoom: targetZoom,
      label: {
        show: false
      },
      itemStyle: mapStyle,
      data: finalData,
      nameMap: newNameMap
    }
  }

  if (countryName !== 'world') {
    const data = require(`../../mapGeo/countryJson/${countryName}.json`)
    echarts.registerMap(countryName, data)

    // await import(`../../mapGeo/countryJson/${countryName}.json`).then(data => {
    //   console.log(data)
    //   echarts.registerMap(countryName, data)
    // })
  }

  const newVisual = {
    pieces: [
      { gte: 1000000, label: '>=1000000人', color: '#841710' },
      { gte: 100000, lte: 999999, label: '100000-999999人', color: '#C51623' },
      { gte: 10000, lte: 99999, label: '10000-99999人', color: '#F04B4A' },
      { gte: 1000, lte: 9999, label: '1000-9999人', color: '#FF8A74' },
      { gte: 100, lte: 999, label: '100-999人', color: '#FFD0A3' },
      { gte: 1, lte: 99, label: '1-99人', color: '#FFF1D0' },
      { lte: 0, label: '0', color: '#fff' }
    ],
    textStyle: {
      fontSize: textFontSize
    },
    itemWidth: iconWidth,
    itemHeight: iconHeight
  }
  const visualMapDict = Object.assign(visualmapPiece, newVisual)
  delete visualMapDict.text // 设置text 属性后, pieces 属性失效
  // console.log(visualMapDict);
  const option = {
    title: {
      show: false,
      text: '国际STEM教育研究国家（地区）分布',
      left: 'center',
      top: 'top'
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        // console.log(params)
        const value = params.value
        const res = params.name + ' ： ' + value
        return res
      }
    },
    visualMap: visualMapDict,
    series: [
      finalSeries
    ]
  }
  // 使用上述配置显示图标
  myChart.setOption(option)
  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default WorldMapEchart
