<!-- 产业右边大图 -->
<template>
  <div class="chart_27">
    <div class="select_box">
      <el-select
        class="my-lib-el-select transparency"
        size="small"
        v-model="selectValue"
        placeholder="请选择年份"
        @change="topSelectChange"
        style="width: 190px;">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <!-- 主要专利权人 - 表格 -->
    <div class="section_box">
      <div class="table_wrapper">
        <div class="th_wrapper">
          <table>
            <colgroup class="col1" width="230"></colgroup>
            <colgroup class="col2" width="133"></colgroup>
            <thead>
              <tr>
                <th>名称</th>
                <th>授权专利数</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="tb_wrapper">
          <vue-scroll>
            <table>
              <colgroup class="col1" width="230"></colgroup>
              <colgroup class="col2" width="133"></colgroup>
              <tbody>
                <tr v-for="(item, index) in curAllData['tableData']" :key="index">
                  <td v-for="(value, name) in item" :key="name">
                    {{ value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </vue-scroll>
        </div>
      </div>
    </div>
    <!-- 主要技术领域 -->
    <div class="section_box">
      <div class="pie_chart" ref="pieChart"></div>
    </div>
    <!-- 技术领域对比分析 -->
    <div class="radar_chart_box">
      <div class="radar_select_box">
        <el-select
          class="my-lib-el-select transparency"
          size="small"
          v-model="radarSelectValue"
          placeholder="请选择"
          @change="radarSelectChange"
          style="width: 190px;">
          <el-option
            v-for="item in radarOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="section_box">
        <!-- 雷达图 -->
        <div class="radar_chart" ref="radarChart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import getPieOptions from './options/pie'
import getRadarOptions from './options/radar'
import { allData, radarMaxValue, options, radarOptions } from './data-soucre/index'
export default {
  data () {
    return {
      // 选择器的值 一开始要填写一个默认值
      selectValue: 'total',
      // 雷达图选择器的值
      radarSelectValue: 'system',
      // 表格
      tableData: [],
      // 饼图假数据
      pieData: [],
      // 饼图实列
      pieChart: null,
      // 雷达图实列
      radarChart: null,
      //
      valueList: [
        {
          label: 'h1',
          value: 216841
        },
        {
          label: 'h1',
          value: 216841
        },
        {
          label: 'h1',
          value: 216841
        },
        {
          label: 'h1',
          value: 216841
        },
        {
          label: 'h1',
          value: 216841
        },
        {
          label: 'h1',
          value: 216841
        },
        {
          label: 'h1',
          value: 216841
        },
        {
          label: 'h1',
          value: 216841
        },
        {
          label: 'h1',
          value: 216841
        }
      ]
    }
  },
  computed: {
    // 下拉选择器
    options () {
      return options
    },
    // 雷达图下拉选择器
    radarOptions () {
      return radarOptions
    },
    // 当前所选数据
    curAllData () {
      return allData[this.selectValue]
    }
  },
  mounted () {
    this.initChart()
  },
  methods: {
    // 初始化图表
    initChart () {
      // 初始化饼图
      this.initPie()
      // 初始化雷达图
      this.initRadar()
    },
    // 初始化饼图
    initPie () {
      this.pieChart = echarts.init(this.$refs.pieChart)
      const options = getPieOptions(this.curAllData.pieData)
      this.pieChart.setOption(options)
    },
    // 初始化雷达图
    initRadar () {
      this.radarChart = echarts.init(this.$refs.radarChart)
      console.log('radarMaxValue[this.radarSelectValue]', radarMaxValue[this.radarSelectValue], this.curAllData.secondPieData[this.radarSelectValue])
      const options = getRadarOptions(this.curAllData.secondPieData[this.radarSelectValue], radarMaxValue[this.radarSelectValue])
      this.radarChart.setOption(options)
    },
    /**
     * 顶部选择器改变
     *  + 改变饼图
     *  + 改变雷达图
     */
    topSelectChange (value) {
      this.initChart()
    },
    /**
     * 雷达图选择器
     */
    radarSelectChange (value) {
      this.initRadar()
    }
  }
}
</script>

<style lang="scss" scoped>
.chart_27 {
  width: 386px;
  .section_box {
    position: relative;
    padding: 0 10px;
  }
  .select_box {
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .table_wrapper {
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 60px;
    .tb_wrapper {
      height: 160px;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.3);
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.3);
    }
    table {
      width: 364px;
      border-collapse:collapse;
      th, td {
        padding: 10px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        border-right: 1px solid rgba(255, 255, 255, 0.3);
      }
      thead {
        th {
          text-align: left;
          color: rgba(255, 255, 255, 0.8);
          background-color: rgba(22, 215, 255, 0.05);
        }
      }
      tbody {
        // 每行第二个
        tr:nth-child(2n) {
          background-color: rgba(22, 215, 255, 0.05);
        }
        td {
          color: #ffffff;
          &:last-child {
            font-weight: bold;
            color: rgba(88, 238, 255, 1);
          }
        }
      }
    }
  }
  .pie_chart {
    width: 100%;
    height: 210px;
    margin-bottom: 40px;
  }
  .radar_chart_box {
    position: relative;
    .radar_chart {
      width: 100%;
      height: 250px;
    }
    .radar.tooltip {
      position: absolute;
      top: 0;
      left: 0;
      width: 340px;
      padding: 10px;
      background-color: rgba(56, 71, 96, 0.7);
      & > p {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 20px;
        color: #FFFFFF;
        margin-bottom: 15px;
      }
      .value_list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .value_item {
          min-width: 50%;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 17px;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
