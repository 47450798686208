const mapData = {
  total: [
    {
      name: '美国',
      value: 131105
    }, {
      name: '日本',
      value: 108817
    }, {
      name: '中国',
      value: 9155
    }, {
      name: '中国台湾',
      value: 37911
    }, {
      name: '中国香港',
      value: 394
    }, {
      name: '中国澳门',
      value: 2
    }, {
      name: '韩国',
      value: 40377
    }, {
      name: '德国',
      value: 14024
    }, {
      name: '法国',
      value: 5192
    }, {
      name: '新加坡',
      value: 4777
    }, {
      name: '荷兰',
      value: 2945
    }, {
      name: '英国',
      value: 1813
    }, {
      name: '意大利',
      value: 1705
    }, {
      name: '奥地利',
      value: 1313
    }, {
      name: '加拿大',
      value: 1117
    }, {
      name: '瑞士',
      value: 1051
    }, {
      name: '比利时',
      value: 820
    }, {
      name: '以色列',
      value: 621
    }, {
      name: '瑞典',
      value: 532
    }, {
      name: '百慕大',
      value: 363
    }, {
      name: '芬兰',
      value: 351
    }, {
      name: '爱尔兰',
      value: 324
    }, {
      name: '澳大利亚',
      value: 310
    }, {
      name: '卢森堡',
      value: 285
    }, {
      name: '巴巴多斯',
      value: 117
    }, {
      name: '挪威',
      value: 111
    }, {
      name: '沙特阿拉伯',
      value: 106
    }, {
      name: '丹麦',
      value: 104
    }, {
      name: '不列颠岛(英)',
      value: 95
    }, {
      name: '马来西亚',
      value: 74
    }, {
      name: '印度',
      value: 71
    }, {
      name: '西班牙',
      value: 69
    }, {
      name: '泰国',
      value: 56
    }, {
      name: '萨摩亚',
      value: 43
    }, {
      name: '菲律宾',
      value: 38
    }, {
      name: '俄罗斯',
      value: 38
    }, {
      name: '南非',
      value: 29
    }, {
      name: '毛里求斯',
      value: 23
    }, {
      name: '马耳他',
      value: 22
    }, {
      name: '阿拉伯联合酋长国',
      value: 18
    }, {
      name: '埃及',
      value: 17
    }, {
      name: '波兰',
      value: 16
    }, {
      name: '匈牙利',
      value: 15
    }, {
      name: '土尔其',
      value: 15
    }, {
      name: '新西兰',
      value: 14
    }, {
      name: '文莱',
      value: 12
    }, {
      name: '捷克',
      value: 11
    }, {
      name: '塞浦路斯',
      value: 10
    }, {
      name: '葡萄牙',
      value: 10
    }, {
      name: '保加利亚',
      value: 9
    }, {
      name: '墨西哥',
      value: 8
    }, {
      name: '卡塔尔',
      value: 4
    }, {
      name: '爱沙尼亚',
      value: 4
    }, {
      name: '立陶宛',
      value: 4
    }, {
      name: '巴基斯坦',
      value: 3
    }, {
      name: '罗马尼亚',
      value: 3
    }, {
      name: '伯利兹',
      value: 2
    }, {
      name: '巴西',
      value: 2
    }, {
      name: '委内瑞拉',
      value: 2
    }, {
      name: '黎巴嫩',
      value: 2
    }, {
      name: '乌克兰',
      value: 2
    }, {
      name: '印度尼西亚',
      value: 2
    }, {
      name: '科威特',
      value: 1
    }, {
      name: '哥伦比亚',
      value: 1
    }, {
      name: '伊朗',
      value: 1
    }, {
      name: '摩洛哥',
      value: 1
    }
  ],
  five: [
    {
      name: '美国',
      value: 37533
    }, {
      name: '日本',
      value: 33744
    }, {
      name: '中国大陆',
      value: 7447
    }, {
      name: '中国台湾',
      value: 17045
    }, {
      name: '中国香港',
      value: 150
    }, {
      name: '中国澳门',
      value: 2
    }, {
      name: '韩国',
      value: 18077
    }, {
      name: '德国',
      value: 4945
    }, {
      name: '法国',
      value: 1957
    }, {
      name: '新加坡',
      value: 1814
    }, {
      name: '荷兰',
      value: 1287
    }, {
      name: '英国',
      value: 678
    }, {
      name: '意大利',
      value: 449
    }, {
      name: '奥地利',
      value: 863
    }, {
      name: '加拿大',
      value: 376
    }, {
      name: '瑞士',
      value: 346
    }, {
      name: '比利时',
      value: 375
    }, {
      name: '以色列',
      value: 283
    }, {
      name: '瑞典',
      value: 185
    }, {
      name: '百慕大',
      value: 77
    }, {
      name: '芬兰',
      value: 165
    }, {
      name: '爱尔兰',
      value: 258
    }, {
      name: '澳大利亚',
      value: 96
    }, {
      name: '卢森堡',
      value: 208
    }, {
      name: '巴巴多斯',
      value: 67
    }, {
      name: '挪威',
      value: 57
    }, {
      name: '沙特阿拉伯',
      value: 92
    }, {
      name: '丹麦',
      value: 31
    }, {
      name: '不列颠岛(英)',
      value: 43
    }, {
      name: '马来西亚',
      value: 35
    }, {
      name: '印度',
      value: 38
    }, {
      name: '西班牙',
      value: 48
    }, {
      name: '泰国',
      value: 20
    }, {
      name: '萨摩亚',
      value: 40
    }, {
      name: '菲律宾',
      value: 36
    }, {
      name: '俄罗斯',
      value: 6
    }, {
      name: '南非',
      value: 15
    }, {
      name: '毛里求斯',
      value: 0
    }, {
      name: '马耳他',
      value: 13
    }, {
      name: '阿拉伯联合酋长国',
      value: 12
    }, {
      name: '埃及',
      value: 13
    }, {
      name: '波兰',
      value: 4
    }, {
      name: '匈牙利',
      value: 1
    }, {
      name: '土尔其',
      value: 13
    }, {
      name: '新西兰',
      value: 5
    }, {
      name: '文莱',
      value: 1
    }, {
      name: '捷克',
      value: 9
    }, {
      name: '塞浦路斯',
      value: 3
    }, {
      name: '葡萄牙',
      value: 7
    }, {
      name: '保加利亚',
      value: 2
    }, {
      name: '墨西哥',
      value: 6
    }, {
      name: '卡塔尔',
      value: 4
    }, {
      name: '爱沙尼亚',
      value: 1
    }, {
      name: '立陶宛',
      value: 4
    }, {
      name: '巴基斯坦',
      value: 3
    }, {
      name: '罗马尼亚',
      value: 2
    }, {
      name: '伯利兹',
      value: 0
    }, {
      name: '巴西',
      value: 2
    }, {
      name: '委内瑞拉',
      value: 0
    }, {
      name: '黎巴嫩',
      value: 2
    }, {
      name: '乌克兰',
      value: 0
    }, {
      name: '印度尼西亚',
      value: 0
    }, {
      name: '科威特',
      value: 1
    }, {
      name: '哥伦比亚',
      value: 1
    }, {
      name: '伊朗',
      value: 1
    }, {
      name: '摩洛哥',
      value: 0
    }],
  ten: [
    {
      name: '美国',
      value: 64502
    }, {
      name: '日本',
      value: 59413
    }, {
      name: '中国大陆',
      value: 8898
    }, {
      name: '中国台湾',
      value: 25591
    }, {
      name: '中国香港',
      value: 250
    }, {
      name: '中国澳门',
      value: 2
    }, {
      name: '韩国',
      value: 29440
    }, {
      name: '德国',
      value: 8292
    }, {
      name: '法国',
      value: 3253
    }, {
      name: '新加坡',
      value: 3546
    }, {
      name: '荷兰',
      value: 2123
    }, {
      name: '英国',
      value: 1062
    }, {
      name: '意大利',
      value: 740
    }, {
      name: '奥地利',
      value: 1231
    }, {
      name: '加拿大',
      value: 621
    }, {
      name: '瑞士',
      value: 567
    }, {
      name: '比利时',
      value: 596
    }, {
      name: '以色列',
      value: 404
    }, {
      name: '瑞典',
      value: 290
    }, {
      name: '百慕大',
      value: 286
    }, {
      name: '芬兰',
      value: 246
    }, {
      name: '爱尔兰',
      value: 301
    }, {
      name: '澳大利亚',
      value: 164
    }, {
      name: '卢森堡',
      value: 279
    }, {
      name: '巴巴多斯',
      value: 108
    }, {
      name: '挪威',
      value: 75
    }, {
      name: '沙特阿拉伯',
      value: 103
    }, {
      name: '丹麦',
      value: 60
    }, {
      name: '不列颠岛(英)',
      value: 71
    }, {
      name: '马来西亚',
      value: 49
    }, {
      name: '印度',
      value: 58
    }, {
      name: '西班牙',
      value: 58
    }, {
      name: '泰国',
      value: 47
    }, {
      name: '萨摩亚',
      value: 43
    }, {
      name: '菲律宾',
      value: 38
    }, {
      name: '俄罗斯',
      value: 17
    }, {
      name: '南非',
      value: 27
    }, {
      name: '毛里求斯',
      value: 15
    }, {
      name: '马耳他',
      value: 22
    }, {
      name: '阿拉伯联合酋长国',
      value: 18
    }, {
      name: '埃及',
      value: 17
    }, {
      name: '波兰',
      value: 8
    }, {
      name: '匈牙利',
      value: 5
    }, {
      name: '土尔其',
      value: 15
    }, {
      name: '新西兰',
      value: 10
    }, {
      name: '文莱',
      value: 9
    }, {
      name: '捷克',
      value: 11
    }, {
      name: '塞浦路斯',
      value: 8
    }, {
      name: '葡萄牙',
      value: 10
    }, {
      name: '保加利亚',
      value: 4
    }, {
      name: '墨西哥',
      value: 7
    }, {
      name: '卡塔尔',
      value: 4
    }, {
      name: '爱沙尼亚',
      value: 4
    }, {
      name: '立陶宛',
      value: 4
    }, {
      name: '巴基斯坦',
      value: 3
    }, {
      name: '罗马尼亚',
      value: 2
    }, {
      name: '伯利兹',
      value: 0
    }, {
      name: '巴西',
      value: 2
    }, {
      name: '委内瑞拉',
      value: 0
    }, {
      name: '黎巴嫩',
      value: 2
    }, {
      name: '乌克兰',
      value: 2
    }, {
      name: '印度尼西亚',
      value: 0
    }, {
      name: '科威特',
      value: 1
    }, {
      name: '哥伦比亚',
      value: 1
    }, {
      name: '伊朗',
      value: 1
    }, {
      name: '摩洛哥',
      value: 0
    }],
  twenty: [
    {
      name: '美国',
      value: 105950
    }, {
      name: '日本',
      value: 89964
    }, {
      name: '中国大陆',
      value: 9137
    }, {
      name: '中国台湾',
      value: 35730
    }, {
      name: '中国香港',
      value: 380
    }, {
      name: '中国澳门',
      value: 2
    }, {
      name: '韩国',
      value: 38550
    }, {
      name: '德国',
      value: 12232
    }, {
      name: '法国',
      value: 4317
    }, {
      name: '新加坡',
      value: 4655
    }, {
      name: '荷兰',
      value: 2895
    }, {
      name: '英国',
      value: 1428
    }, {
      name: '意大利',
      value: 1321
    }, {
      name: '奥地利',
      value: 1293
    }, {
      name: '加拿大',
      value: 881
    }, {
      name: '瑞士',
      value: 762
    }, {
      name: '比利时',
      value: 801
    }, {
      name: '以色列',
      value: 576
    }, {
      name: '瑞典',
      value: 460
    }, {
      name: '百慕大',
      value: 363
    }, {
      name: '芬兰',
      value: 337
    }, {
      name: '爱尔兰',
      value: 321
    }, {
      name: '澳大利亚',
      value: 279
    }, {
      name: '卢森堡',
      value: 283
    }, {
      name: '巴巴多斯',
      value: 117
    }, {
      name: '挪威',
      value: 106
    }, {
      name: '沙特阿拉伯',
      value: 106
    }, {
      name: '丹麦',
      value: 90
    }, {
      name: '不列颠岛(英)',
      value: 92
    }, {
      name: '马来西亚',
      value: 74
    }, {
      name: '印度',
      value: 70
    }, {
      name: '西班牙',
      value: 63
    }, {
      name: '泰国',
      value: 55
    }, {
      name: '萨摩亚',
      value: 43
    }, {
      name: '菲律宾',
      value: 38
    }, {
      name: '俄罗斯',
      value: 23
    }, {
      name: '南非',
      value: 28
    }, {
      name: '毛里求斯',
      value: 23
    }, {
      name: '马耳他',
      value: 22
    }, {
      name: '阿拉伯联合酋长国',
      value: 18
    }, {
      name: '埃及',
      value: 17
    }, {
      name: '波兰',
      value: 14
    }, {
      name: '匈牙利',
      value: 13
    }, {
      name: '土尔其',
      value: 15
    }, {
      name: '新西兰',
      value: 12
    }, {
      name: '文莱',
      value: 12
    }, {
      name: '捷克',
      value: 11
    }, {
      name: '塞浦路斯',
      value: 9
    }, {
      name: '葡萄牙',
      value: 10
    }, {
      name: '保加利亚',
      value: 8
    }, {
      name: '墨西哥',
      value: 7
    }, {
      name: '卡塔尔',
      value: 4
    }, {
      name: '爱沙尼亚',
      value: 4
    }, {
      name: '立陶宛',
      value: 4
    }, {
      name: '巴基斯坦',
      value: 3
    }, {
      name: '罗马尼亚',
      value: 2
    }, {
      name: '伯利兹',
      value: 1
    }, {
      name: '巴西',
      value: 2
    }, {
      name: '委内瑞拉',
      value: 0
    }, {
      name: '黎巴嫩',
      value: 2
    }, {
      name: '乌克兰',
      value: 2
    }, {
      name: '印度尼西亚',
      value: 2
    }, {
      name: '科威特',
      value: 1
    }, {
      name: '哥伦比亚',
      value: 1
    }, {
      name: '伊朗',
      value: 1
    }, {
      name: '摩洛哥',
      value: 1
    }]
}

const nameMap = {
  Afghanistan: '阿富汗',
  Albania: '阿尔巴尼亚',
  Algeria: '阿尔及利亚',
  Andorra: '安道尔',
  Angola: '安哥拉',
  'Antigua and Barbuda': '安提瓜和巴布达',
  Argentina: '阿根廷',
  Armenia: '亚美尼亚',
  Australia: '澳大利亚',
  Austria: '奥地利',
  Azerbaijan: '阿塞拜疆',
  Bahamas: '巴哈马',
  Bahrain: '巴林',
  Bangladesh: '孟加拉国',
  Barbados: '巴巴多斯',
  Belarus: '白俄罗斯',
  Belgium: '比利时',
  Benin: '贝宁',
  Bhutan: '不丹',
  Bolivia: '玻利维亚',
  'Bosnia and Herzegovina': '波黑',
  Brazil: '巴西',
  Brunei: '汶莱',
  Bulgaria: '保加利亚',
  'Burkina Faso': '布基纳法索',
  'Cabo Verde': '佛得角',
  Cambodia: '柬埔寨',
  Cameroon: '喀麦隆',
  Canada: '加拿大',
  'Central African Republic': '中非',
  Chad: '乍得',
  Chile: '智利',
  China: '中国',
  Colombia: '哥伦比亚',
  'Congo (Brazzaville)': '刚果（布）',
  'Congo (Kinshasa)': '刚果（金）',
  'Costa Rica': '哥斯达黎加',
  "Cote d'Ivoire": '科特迪瓦',
  Croatia: '克罗地亚',
  'International Conveyance': '国际运输',
  Cuba: '古巴',
  Cyprus: '塞浦路斯',
  Czechia: '捷克',
  Denmark: '丹麦',
  Djibouti: '吉布提',
  'Dominican Republic': '多米尼加',
  Ecuador: '厄瓜多尔',
  Egypt: '埃及',
  'El Salvador': '萨尔瓦多',
  'Equatorial Guinea': '赤道几内亚',
  Eritrea: '厄立特里亚',
  Estonia: '爱沙尼亚',
  Eswatini: '斯威士兰',
  Ethiopia: '埃塞俄比亚',
  Fiji: '斐济',
  Finland: '芬兰',
  France: '法国',
  Gabon: '加蓬',
  Gambia: '冈比亚',
  Georgia: '格鲁吉亚',
  Germany: '德国',
  Ghana: '加纳',
  Greece: '希腊',
  Guatemala: '瓜地马拉',
  Guinea: '几内亚',
  Guyana: '圭亚那',
  Haiti: '海地',
  'Holy See': '梵蒂冈',
  Honduras: '洪都拉斯',
  Hungary: '匈牙利',
  Iceland: '冰岛',
  India: '印度',
  Indonesia: '印度尼西亚',
  Iran: '伊朗',
  Iraq: '伊拉克',
  Ireland: '爱尔兰',
  Israel: '以色列',
  Italy: '意大利',
  Jamaica: '牙买加',
  Japan: '日本',
  Jordan: '约旦',
  Kazakhstan: '哈萨克斯坦',
  Kenya: '肯尼亚',
  'South Korea': '韩国',
  Kuwait: '科威特',
  Kyrgyzstan: '吉尔吉斯斯坦',
  Latvia: '拉脱维亚',
  Lebanon: '黎巴嫩',
  Liberia: '利比里亚',
  Liechtenstein: '列支敦士登',
  Lithuania: '立陶宛',
  Luxembourg: '卢森堡',
  Madagascar: '马达加斯加',
  Malaysia: '马来西亚',
  Maldives: '马尔代夫',
  Malta: '马耳他',
  Mauritania: '毛里塔尼亚',
  Mauritius: '毛里求斯',
  Mexico: '墨西哥',
  Moldova: '摩尔多瓦',
  Monaco: '摩纳哥',
  Mongolia: '蒙古',
  Montenegro: '黑山',
  Morocco: '摩洛哥',
  Namibia: '纳米比亚',
  Nepal: '尼泊尔',
  Netherlands: '荷兰',
  'New Zealand': '新西兰',
  Nicaragua: '尼加拉瓜',
  Niger: '尼日尔',
  Nigeria: '尼日利亚',
  'North Macedonia': '北马其顿',
  Norway: '挪威',
  Oman: '阿曼',
  Pakistan: '巴基斯坦',
  Panama: '巴拿马',
  'Papua New Guinea': '巴布亚新几内亚',
  Paraguay: '巴拉圭',
  Peru: '秘鲁',
  Philippines: '菲律宾',
  Poland: '波兰',
  Portugal: '葡萄牙',
  Qatar: '卡塔尔',
  Romania: '罗马尼亚',
  Russia: '俄罗斯',
  Rwanda: '卢旺达',
  'Saint Lucia': '圣卢西亚',
  'Saint Vincent and the Grenadines': '圣文森特和格林纳丁斯',
  'San Marino': '圣马力诺',
  'Saudi Arabia': '沙特阿拉伯',
  Senegal: '塞内加尔',
  Serbia: '塞尔维亚',
  Seychelles: '塞舌尔',
  Singapore: '新加坡',
  Slovakia: '斯洛伐克',
  Slovenia: '斯洛文尼亚',
  Somalia: '索马里',
  'South Africa': '南非',
  Spain: '西班牙',
  'Sri Lanka': '斯里兰卡',
  Sudan: '苏丹',
  Suriname: '苏里南',
  Sweden: '瑞典',
  Switzerland: '瑞士',
  Tanzania: '坦桑尼亚',
  Thailand: '泰国',
  Togo: '多哥',
  'Trinidad and Tobago': '特立尼达和多巴哥',
  Tunisia: '突尼斯',
  Turkey: '土耳其',
  Uganda: '乌干达',
  Ukraine: '乌克兰',
  'United Arab Emirates': '阿联酋',
  'United Kingdom': '英国',
  Uruguay: '乌拉圭',
  'United States': '美国',
  Uzbekistan: '乌兹别克斯坦',
  Venezuela: '委内瑞拉',
  Vietnam: '越南',
  Zambia: '赞比亚',
  Zimbabwe: '津巴布韦',
  Dominica: '多米尼克',
  Grenada: '格林纳达',
  Mozambique: '莫桑比克',
  Syria: '叙利亚',
  'Timor-Leste': '东帝汶',
  Belize: '伯利兹',
  Laos: '老挝',
  Libya: '利比亚',
  Palestine: '巴勒斯坦',
  'Guinea-Bissau': '几内亚比绍',
  Mali: '马里',
  'Saint Kitts and Nevis': '圣基茨和尼维斯',
  Kosovo: '科索沃',
  Myanmar: '缅甸',
  'MS Zaandam': 'MS Zaandam',
  Botswana: '博茨瓦纳',
  Burundi: '布隆迪',
  'Sierra Leone': '塞拉利昂',
  Malawi: '马拉维',
  'South Sudan': '南苏丹',
  'Western Sahara': '西撒哈拉',
  'Sao Tome and Principe': '圣多美和普林西比',
  Yemen: '也门',
  Comoros: 'Comoros',
  Tajikistan: 'Tajikistan',
  'Buenos Aires': '布宜诺斯艾利斯省',
  Catamarca: '卡塔马卡省',
  Chaco: '查科省',
  Chubut: '丘布特省',
  'Ciudad de Buenos Aires': '布宜诺斯艾利斯市',
  Cordoba: '科尔多瓦省',
  Corrientes: '科连特斯省',
  'Entre Rios': '恩特雷里奥斯省',
  Formosa: '福莫萨省',
  Jujuy: '胡胡伊省',
  'La Pampa': '拉潘帕省',
  'La Rioja': '拉里奥哈省',
  Mendoza: '门多萨省',
  Misiones: '米西奥内斯省',
  Neuquen: '内乌肯省',
  'Rio Negro': '内格罗河省',
  Salta: '萨尔塔省',
  'San Juan': '圣胡安省',
  'San Luis': '圣路易斯省',
  'Santa Cruz': '圣克鲁斯省',
  'Santa Fe': '圣菲省',
  'Santiago del Estero': '圣地亚哥-德尔埃斯特罗省',
  'Tierra del Fuego': '火地省',
  Tucuman: '图库曼省',
  'Australian Capital Territory': '澳大利亚首都领地',
  'New South Wales': '新南威尔士州',
  'Northern Territory': '北领地',
  Queensland: '昆士兰州',
  'South Australia': '南澳大利亚州',
  Tasmania: '塔斯马尼亚州',
  Victoria: '维多利亚州',
  'Western Australia': '西澳大利亚州',
  Tirol: '蒂罗尔州',
  Salzburg: '萨尔茨堡州',
  Steiermark: '施蒂利亚州',
  Niederösterreich: '下奥地利州',
  Wien: '维也纳州',
  Kärnten: '克恩顿州',
  Vorarlberg: '福拉尔贝格州',
  Oberösterreich: '上奥地利州',
  Burgenland: '布尔根兰州',
  Flanders: '弗拉芒大区',
  Brussels: '布鲁塞尔-首都大区',
  Wallonia: '瓦隆大区',
  Acre: '阿克里州',
  Amapá: '阿马帕州',
  Amazonas: '亚马孙省',
  Pará: '帕拉州',
  Rondônia: '朗多尼亚州',
  Roraima: '罗赖马州',
  Tocantins: '托坎廷斯州',
  Alagoas: '阿拉戈斯州',
  Bahia: '巴伊亚州',
  Ceará: '塞阿腊州',
  Maranhão: '马拉尼昂州',
  Paraíba: '帕拉伊巴州',
  Pernambuco: '伯南布哥州',
  Piauí: '皮奥伊州',
  'Rio Grande do Norte': '北里约格朗德州',
  Sergipe: '塞尔希培州',
  'Espirito Santo': '圣埃斯皮里图州',
  'Minas Gerais': '米纳斯吉拉斯州',
  'Rio de Janeiro': '里约热内卢州',
  'São Paulo': '圣保罗州',
  Paraná: '巴拉那州',
  'Rio Grande do Sul': '南里约格朗德州',
  'Santa Catarina': '圣卡塔琳娜州',
  'Distrito Federal': '联邦区',
  Goiás: '戈亚斯州',
  'Mato Grosso': '马托格罗索州',
  'Mato Grosso do Sul': '南马托格罗索州',
  Alberta: '艾伯塔省',
  'British Columbia': '不列颠哥伦比亚省',
  'Grand Princess': '至尊公主号',
  Manitoba: '曼尼托巴省',
  'New Brunswick': '新布伦瑞克省',
  'Newfoundland and Labrador': '纽芬兰与拉布拉多省',
  'Nova Scotia': '新斯科舍省',
  Ontario: '安大略省',
  'Prince Edward Island': '爱德华王子岛省',
  Quebec: '魁北克省',
  Saskatchewan: '萨斯喀彻温省',
  'Diamond Princess': '钻石公主号',
  'Northwest Territories': '西北地区',
  Yukon: '育空地区',
  Antofagasta: '安托法加斯塔大区',
  Araucanía: '阿劳卡尼亚大区',
  'Arica y Parinacota': '阿里卡和帕里纳科塔大区',
  Atacama: '阿塔卡马大区',
  Aysén: '艾森大区',
  Biobío: '比奥比奥大区',
  Coquimbo: '科金博大区',
  'Los Lagos': '湖大区',
  'Los Ríos': '河大区',
  Magallanes: '麦哲伦大区',
  Maule: '马乌莱大区',
  'Santiago Metropolitan': '圣地亚哥首都大区',
  Ñuble: '纽布莱大区',
  "O'Higgins": '奥伊金斯大区',
  Tarapacá: '塔拉帕卡大区',
  Valparaíso: '瓦尔帕莱索大区',
  Ningxia: '宁夏回族自治区',
  Hebei: '河北省',
  Jilin: '吉林省',
  Shanxi: '山西省',
  Yunnan: '云南省',
  Gansu: '甘肃省',
  Heilongjiang: '黑龙江省',
  Liaoning: '辽宁省',
  Guizhou: '贵州省',
  Shaanxi: '陕西省',
  Jiangxi: '江西省',
  Hainan: '海南省',
  Henan: '河南省',
  Jiangsu: '江苏省',
  Fujian: '福建省',
  Guangxi: '广西壮族自治区',
  Shandong: '山东省',
  Sichuan: '四川省',
  Chongqing: '重庆市',
  Anhui: '安徽省',
  Hunan: '湖南省',
  Beijing: '北京市',
  Zhejiang: '浙江省',
  Guangdong: '广东省',
  Hubei: '湖北省',
  Neimenggu: '内蒙古自治区',
  Xinjiang: '新疆维吾尔自治区',
  Qinghai: '青海省',
  Tianjin: '天津市',
  Shanghai: '上海市',
  Xizang: '西藏自治区',
  'Hong Kong': '香港',
  Taiwan: '中国台湾',
  Macau: '澳门',
  Antioquia: '安蒂奥基亚省',
  Arauca: '阿劳卡省',
  Atlantico: '大西洋省',
  Bogota: '波哥大',
  Bolivar: '玻利瓦尔省',
  Boyaca: '博亚卡省',
  Caldas: '卡尔达斯省',
  Caqueta: '卡克塔省',
  Casanare: '卡萨纳雷省',
  Cauca: '考卡省',
  Cesar: '塞萨尔省',
  Choco: '乔科省',
  Cundinamarca: '昆迪纳马卡省',
  Guainia: '瓜伊尼亚省',
  Guaviare: '瓜维亚雷省',
  Huila: '乌伊拉省',
  'La Guajira': '瓜希拉省',
  Magdalena: '马格达莱纳省',
  Meta: '梅塔省',
  Narino: '纳里尼奥省',
  'Norte de Santander': '北桑坦德省',
  Putumayo: '普图马约省',
  Quindio: '金迪奥省',
  Risaralda: '里萨拉尔达省',
  'San Andres y Providencia': '圣安德列斯-普罗维登西亚省',
  Santander: '桑坦德省',
  Sucre: '苏克雷省',
  Tolima: '托利马省',
  'Valle del Cauca': '考卡山谷省',
  Vaupes: '沃佩斯省',
  Vichada: '比查达省',
  Liberec: '利贝雷茨州',
  Vysočina: '维索基纳州',
  'Hradec Králové': '赫拉德茨-克拉洛韦州',
  Plzeň: '比尔森州',
  Pardubice: '帕尔杜比采州',
  'South Bohemian': '南波希米亚州',
  'Karlovy Vary': '卡罗维发利州',
  'Ústí nad Labem': '拉贝河畔乌斯季州',
  Zlín: '兹林州',
  'South Moravian': '南摩拉维亚州',
  Olomouc: '奥洛穆茨州',
  'Moravian-Silesian': '摩拉维亚-西里西亚州',
  'Central Bohemian': '中波希米亚州',
  Prague: '布拉格',
  Unassigned: '未明确',
  'Faroe Islands': '法罗群岛',
  Greenland: '格陵兰',
  Azuay: '阿苏艾省',
  Canar: '卡尼亚尔省',
  Carchi: '卡尔奇省',
  Chimborazo: '钦博拉索省',
  Cotopaxi: '科托帕希省',
  'El Oro': '埃尔奥罗省',
  Esmeraldas: '埃斯梅拉达斯省',
  Galapagos: '加拉帕戈斯省',
  Guayas: '瓜亚斯省',
  Imbabura: '因巴布拉省',
  Loja: '洛哈省',
  'Los Rios': '洛斯里奥斯省',
  Manabi: '马纳比省',
  'Morona Santiago': '莫罗纳-圣地亚哥省',
  Napo: '纳波省',
  Orellana: '奥雷亚纳省',
  Pastaza: '帕斯塔萨省',
  Pichincha: '皮钦查省',
  'Santa Elena': '圣埃伦娜省',
  'Santo Domingo de los Tsachilas': '圣多明各-德洛斯查奇拉斯省',
  Sucumbios: '苏昆比奥斯省',
  Tungurahua: '通古拉瓦省',
  'Zamora-Chinchipe': '萨莫拉-钦奇佩省',
  'Metropolitan France': '法国本土',
  'Overseas France': '海外领土',
  'Rheinland-Pfalz': '莱茵兰-普法尔茨州',
  'Schleswig-Holstein': '石勒苏益格-荷尔斯泰因州',
  Evacuation: '撤侨',
  Hessen: '黑森州',
  'Baden-Württemberg': '巴登-符腾堡州',
  Bayern: '巴伐利亚州',
  'Nordrhein-Westfalen': '北莱茵-威斯特法伦州',
  Bremen: '不来梅州',
  Hamburg: '汉堡',
  Niedersachsen: '下萨克森州',
  Berlin: '柏林',
  Brandenburg: '勃兰登堡州',
  Sachsen: '萨克森州',
  Thüringen: '图林根州',
  Saarland: '萨尔兰州',
  'Mecklenburg-Vorpommern': '梅克伦堡-前波美拉尼亚州',
  'Sachsen-Anhalt': '萨克森-安哈尔特州',
  Kerala: '喀拉拉邦',
  Delhi: '德里',
  Telangana: '特伦甘纳邦',
  Rajasthan: '拉贾斯坦邦',
  Haryana: '哈里亚纳邦',
  'Uttar Pradesh': '北方邦',
  Ladakh: '拉达克',
  'Tamil Nadu': '泰米尔纳德邦',
  'Jammu and Kashmir': '查谟和克什米尔',
  Karnataka: '卡纳塔克邦',
  Maharashtra: '马哈拉施特拉邦',
  Punjab: '旁遮普省',
  'Andhra Pradesh': '安得拉邦',
  Uttarakhand: '北阿坎德邦',
  Odisha: '奥里萨邦',
  Puducherry: '本地治里',
  'West Bengal': '西孟加拉邦',
  Chandigarh: '昌迪加尔',
  Chhattisgarh: '恰蒂斯加尔邦',
  Gujarat: '古吉拉特邦',
  'Himachal Pradesh': '喜马偕尔邦',
  'Madhya Pradesh': '中央邦',
  Bihar: '比哈尔邦',
  Manipur: '曼尼普尔邦',
  Mizoram: '米佐拉姆邦',
  Goa: '果阿邦',
  'Andaman and Nicobar Islands': '安达曼-尼科巴群岛',
  Jharkhand: '贾坎德邦',
  Assam: '阿萨姆邦',
  'Arunachal Pradesh': '阿鲁纳恰尔邦',
  Tripura: '特里普拉邦',
  Meghalaya: '梅加拉亚邦',
  Aceh: '亚齐特区',
  'Sumatera Utara': '北苏门答腊省',
  'Sumatera Barat': '西苏门答腊省',
  Riau: '廖内省',
  Jambi: '占碑省',
  'Sumatera Selatan': '南苏门答腊省',
  Bengkulu: '明古鲁省',
  Lampung: '楠榜省',
  'Kepulauan Bangka Belitung': '邦加-勿里洞省',
  'Kepulauan Riau': '廖内群岛省',
  'DKI Jakarta': '雅加达首都特区',
  'Jawa Barat': '西爪哇省',
  'Jawa Tengah': '中爪哇省',
  'DI Yogyakarta': '日惹特区',
  'Jawa Timur': '东爪哇省',
  Banten: '万丹省',
  Bali: '巴厘省',
  'Nusa Tenggara Barat': '西努沙登加拉省',
  'Nusa Tenggara Timur': '东努沙登加拉省',
  'Kalimantan Barat': '西加里曼丹省',
  'Kalimantan Tengah': '中加里曼丹省',
  'Kalimantan Selatan': '南加里曼丹省',
  'Kalimantan Timur': '东加里曼丹省',
  'Kalimantan Utara': '北加里曼丹省',
  'Sulawesi Utara': '北苏拉威西省',
  'Sulawesi Tengah': '中苏拉威西省',
  'Sulawesi Selatan': '南苏拉威西省',
  'Sulawesi Tenggara': '东南苏拉威西省',
  Gorontalo: '哥伦打洛省',
  'Sulawesi Barat': '西苏拉威西省',
  Maluku: '马鲁古省',
  'Maluku Utara': '北马鲁古省',
  Papua: '巴布亚省',
  'Papua Barat': '西巴布亚省',
  Qom: '库姆省',
  Tehran: '德黑兰省',
  Mazandaran: '马赞德兰省',
  Alborz: '厄尔布尔士省',
  Semnan: '塞姆南省',
  Golestan: '戈勒斯坦省',
  Qazvin: '加兹温省',
  Isfahan: '伊斯法罕省',
  Fars: '法尔斯省',
  Hormozgan: '霍尔木兹甘省',
  'Kohgiluyeh and Boyer-Ahmad': '科吉卢耶-博韦艾哈迈德省',
  'Chaharmahal and Bakhtiari': '恰哈马哈勒-巴赫蒂亚里省',
  Bushehr: '布什尔省',
  Gilan: '吉兰省',
  Ardabil: '阿尔达比勒省',
  'East Azerbaijan': '东阿塞拜疆省',
  'West Azerbaijan': '西阿塞拜疆省',
  Kordestan: '库尔德斯坦省',
  Zanjan: '赞詹省',
  Markazi: '中央省',
  Hamadan: '哈马丹省',
  Khuzestan: '胡齐斯坦省',
  Kermanshah: '克尔曼沙汗省',
  Lorestan: '洛雷斯坦省',
  Ilam: '伊拉姆省',
  'Razavi Khorasan': '礼萨呼罗珊省',
  'Sistan and Baluchestan': '锡斯坦-俾路支斯坦省',
  Yazd: '亚兹德省',
  'South Khorasan': '南呼罗珊省',
  Kerman: '克尔曼省',
  'North Khorasan': '北呼罗珊省',
  Dublin: '都柏林郡',
  Carlow: '卡娄郡',
  Cavan: '卡文郡',
  Clare: '克莱尔郡',
  Donegal: '当尼戈尔郡',
  Kildare: '基尔代尔郡',
  Kilkenny: '基尔肯尼郡',
  Longford: '朗福德郡',
  Louth: '劳斯郡',
  Mayo: '梅欧郡',
  Meath: '米斯郡',
  Cork: '科克郡',
  Roscommon: '罗斯康门郡',
  Limerick: '利默里克郡',
  Galway: '高维郡',
  Wicklow: '威克娄郡',
  Westmeath: '西米斯郡',
  Offaly: '奥法利郡',
  Kerry: '凯瑞郡',
  Wexford: '韦克斯福德郡',
  Tipperary: '蒂珀雷里郡',
  Sligo: '斯莱戈郡',
  Waterford: '沃特福德郡',
  Laois: '利施郡',
  Leitrim: '利特里姆郡',
  Monaghan: '莫纳亨郡',
  Abruzzo: '阿布鲁佐大区',
  Basilicata: '巴西利卡塔大区',
  'Trentino-Alto Adige': '特伦蒂诺-上阿迪杰大区',
  Calabria: '卡拉布里亚大区',
  Campania: '坎帕尼亚大区',
  'Emilia Romagna': '艾利亚-罗马涅大区',
  'Friuli V. G.': '弗留利-威尼斯朱利亚大区',
  Lazio: '拉齐奥大区',
  Liguria: '利古里亚大区',
  Lombardia: '伦巴第大区',
  Marche: '马尔凯大区',
  Molise: '莫利塞大区',
  Piemonte: '皮埃蒙特大区',
  Puglia: '普利亚大区',
  Sardegna: '撒丁大区',
  Sicilia: '西西里大区',
  Toscana: '托斯卡纳大区',
  Umbria: '翁布里亚大区',
  "Valle d'Aosta": '瓦莱达奥斯塔大区',
  Veneto: '威尼托大区',
  Hokkaido: '北海道',
  Aomori: '青森县',
  Iwate: '岩手县',
  Miyagi: '宫城县',
  Akita: '秋田县',
  Yamagata: '山形县',
  Fukushima: '福岛县',
  Ibaraki: '茨城县',
  Tochigi: '栃木县',
  Gunma: '群马县',
  Saitama: '埼玉县',
  Chiba: '千叶县',
  Tokyo: '东京都',
  Kanagawa: '神奈川县',
  Niigata: '新潟县',
  Toyama: '富山县',
  Ishikawa: '石川县',
  Fukui: '福井县',
  Yamanashi: '山梨县',
  Nagano: '长野县',
  Gifu: '岐阜县',
  Shizuoka: '静冈县',
  Aichi: '爱知县',
  Mie: '三重县',
  Shiga: '滋贺县',
  Kyoto: '京都府',
  Osaka: '大阪府',
  Hyogo: '兵库县',
  Nara: '奈良县',
  Wakayama: '和歌山县',
  Tottori: '鸟取县',
  Shimane: '岛根县',
  Okayama: '冈山县',
  Hiroshima: '广岛县',
  Yamaguchi: '山口县',
  Tokushima: '德岛县',
  Kagawa: '香川县',
  Ehime: '爱媛县',
  Kōchi: '高知县',
  Fukuoka: '福冈县',
  Saga: '佐贺县',
  Nagasaki: '长崎县',
  Kumamoto: '熊本县',
  Oita: '大分县',
  Miyazaki: '宫崎县',
  Kagoshima: '鹿儿岛县',
  Okinawa: '冲绳县',
  'Quarantine Officers': '检疫人员',
  'Costa Atlantica': '歌诗达大西洋号',
  Daegu: '大邱市',
  'Gyeongsangbuk-do': '庆尚北道',
  'Gyeongsangnam-do': '庆尚南道',
  'Gangwon-do': '江原道',
  'Gyeonggi-do': '京畿道',
  Seoul: '首尔市',
  Gwangju: '光州市',
  Daejeon: '大田市',
  Busan: '釜山市',
  Ulsan: '蔚山市',
  'Jeollanam-do': '全罗南道',
  'Jeollabuk-do': '全罗北道',
  'Chungcheongbuk-do': '忠清北道',
  'Chungcheongnam-do': '忠清南道',
  'Sejong City': '世宗市',
  Incheon: '仁川市',
  'Jeju-do': '济川道',
  Imported: '输入病例',
  Perlis: '玻璃市州',
  Kedah: '吉打州',
  'Pulau Pinang': '槟城州',
  Perak: '霹雳州',
  Selangor: '雪兰莪州',
  'Negeri Sembilan': '森美兰州',
  Melaka: '马六甲州',
  Johor: '柔佛州',
  Pahang: '彭亨州',
  Terengganu: '登嘉楼州',
  Kelantan: '吉兰丹州',
  Sabah: '沙巴州',
  Sarawak: '砂拉越州',
  'Kuala Lumpur': '吉隆坡',
  Putrajaya: '布城',
  Labuan: '纳闽',
  Aguascalientes: '阿瓜斯卡连特斯州',
  'Baja California': '下加利福尼亚州',
  'Baja California Sur': '南下加利福尼亚州',
  Campeche: '坎佩切州',
  Chiapas: '恰帕斯州',
  Chihuahua: '奇瓦瓦州',
  'Ciudad de Mexico': '墨西哥城',
  Coahuila: '科阿韦拉州',
  Colima: '科利马州',
  Durango: '杜兰戈州',
  Guanajuato: '瓜纳华托州',
  Guerrero: '格雷罗州',
  Hidalgo: '伊达尔戈州',
  Jalisco: '哈利斯科州',
  Michoacan: '米却肯州',
  Morelos: '莫雷洛斯州',
  México: '墨西哥州',
  Nayarit: '纳亚里特州',
  'Nuevo Leon': '新莱昂州',
  Oaxaca: '瓦哈卡州',
  Puebla: '普埃布拉州',
  Queretaro: '克雷塔罗州',
  'Quintana Roo': '金塔纳罗奥州',
  'San Luis Potosi': '圣路易斯波托西州',
  Sinaloa: '锡那罗亚州',
  Sonora: '索诺拉州',
  Tabasco: '塔巴斯科州',
  Tamaulipas: '塔毛利帕斯州',
  Tlaxcala: '特拉斯卡拉州',
  Veracruz: '韦拉克鲁斯州',
  Yucatan: '尤卡坦州',
  Zacatecas: '萨卡特卡斯州',
  Aruba: '阿鲁巴',
  Curacao: '库拉索',
  'Sint Maarten': '荷属圣马丁',
  'Bonaire, Sint Eustatius and Saba': '荷兰加勒比区',
  Nordland: '诺尔兰郡',
  'Møre og Romsdal': '默勒-鲁姆斯达尔郡',
  'Troms og Finnmark': '特罗姆斯-芬马克郡',
  'Vestfold og Telemark': '西福尔-泰勒马克郡',
  Agder: '阿格德尔郡',
  Trøndelag: '特伦德拉格郡',
  Innlandet: '内陆郡',
  Vestland: '韦斯特兰郡',
  Rogaland: '罗加兰郡',
  Oslo: '奥斯陆',
  Viken: '维肯郡',
  'Outside mainland Norway': '挪威本土外',
  Sindh: '信德省',
  Balochistan: '俾路支省',
  'Khyber Pakhtunkhwa': '开伯尔-普什图省',
  Islamabad: '伊斯兰堡',
  'Gilgit-Baltistan': '吉尔吉特-巴尔蒂斯坦',
  'Azad Kashmir': '阿扎德克什米尔',
  Ancash: '安卡什大区',
  Apurimac: '阿普里马克大区',
  Arequipa: '阿雷基帕大区',
  Ayacucho: '阿亚库乔大区',
  Cajamarca: '卡哈马卡大区',
  Callao: '卡亚俄大区',
  Cusco: '库斯科大区',
  Huancavelica: '万卡韦利卡大区',
  Huanuco: '瓦努科大区',
  Ica: '伊卡大区',
  Junin: '胡宁大区',
  'La Libertad': '拉利伯塔德大区',
  Lambayeque: '兰巴耶克大区',
  Lima: '利马大区',
  Loreto: '洛雷托大区',
  'Madre de Dios': '马德雷德迪奥斯大区',
  Moquegua: '莫克瓜大区',
  Pasco: '帕斯科大区',
  Piura: '皮乌拉大区',
  Puno: '普诺大区',
  'San Martin': '圣马丁大区',
  Tacna: '塔克纳大区',
  Tumbes: '通贝斯大区',
  Ucayali: '乌卡亚利大区',
  'Metro Manila': '马尼拉都会区',
  Samar: '萨马省',
  Cavite: '甲米地省',
  Cagayan: '卡加延省',
  Aklan: '阿克兰省',
  'Negros Occidental': '西内格罗斯省',
  Iloilo: '伊洛伊洛省',
  Rizal: '黎刹省',
  Abra: '阿布拉省',
  Capiz: '卡皮斯省',
  Bulacan: '布拉干省',
  Laguna: '内湖省',
  Batangas: '八打雁省',
  Cebu: '宿务省',
  'Negros Oriental': '东内格罗斯省',
  'Nueva Ecija': '新怡诗夏省',
  Benguet: '本格特省',
  'Zamboanga del Sur': '南三宝颜省',
  Pampanga: '邦板牙省',
  Bataan: '巴坦省',
  'Davao del Sur': '南达沃省',
  'La Union': '拉乌尼翁省',
  Tarlac: '丹辘省',
  'Lanao del Norte': '北拉瑙省',
  'Lanao del Sur': '南拉瑙省',
  Pangasinan: '邦阿西楠省',
  Zambales: '三描礼士省',
  'Nueva Vizcaya': '新比斯开省',
  'Oriental Mindoro': '东民都洛省',
  Isabela: '伊莎贝拉省',
  'Sultan Kudarat': '苏丹库达拉省',
  'Misamis Oriental': '东米萨米斯省',
  'Northern Samar': '北萨马省',
  Quezon: '奎松省',
  Palawan: '巴拉望省',
  Maguindanao: '马京达瑙省',
  'Davao de Oro': '金达沃省',
  'Camarines Sur': '南甘马粦省',
  Podlaskie: '波德拉谢省',
  Lubusz: '卢布斯卡省',
  Świętokrzyskie: '圣十字省',
  'Lesser Poland': '小波兰省',
  'West Pomeranian': '西波美拉尼亚省',
  'Kuyavian-Pomeranian': '库亚维-波美拉尼亚省',
  Pomeranian: '波美拉尼亚省',
  Opole: '奥波莱省',
  'Warmian-Masurian': '瓦尔米亚-马祖里省',
  'Greater Poland': '大波兰省',
  Podkarpackie: '喀尔巴阡山省',
  Lublin: '卢布林省',
  Silesian: '西里西亚省',
  'Lower Silesian': '下西里西亚省',
  Łódź: '罗兹省',
  Masovian: '马佐夫舍省',
  Norte: '北部',
  Centro: '中部',
  'Lisbon and Tagus Valley': '里斯本与塔霍河谷',
  Alentejo: '阿连特茹',
  Algarve: '阿尔加维',
  Azores: '亚速尔自治区',
  Madeira: '马德拉自治区',
  Foreigners: '外国人',
  Suceava: '苏恰瓦县',
  București: '布加勒斯特',
  Arad: '阿拉德县',
  Neamț: '尼亚姆茨县',
  Mureș: '穆列什县',
  Botoșani: '博托沙尼县',
  Hunedoara: '胡内多阿拉县',
  Brașov: '布拉索夫县',
  Bihor: '比霍尔县',
  Timiș: '蒂米什县',
  Cluj: '克鲁日县',
  Galați: '加拉茨县',
  Vrancea: '弗朗恰县',
  Sibiu: '锡比乌县',
  Ilfov: '伊尔福夫县',
  Bacău: '巴克乌县',
  Iași: '雅西县',
  Ialomița: '雅洛米察县',
  'Bistrița-Năsăud': '比斯特里察-讷瑟乌德县',
  Constanța: '康斯坦察县',
  Alba: '阿尔巴县',
  Covasna: '科瓦斯纳县',
  Argeș: '阿尔杰什县',
  Giurgiu: '久尔久县',
  Dolj: '多尔日县',
  Vaslui: '瓦斯卢伊县',
  Dâmbovița: '登博维察县',
  'Caraș-Severin': '卡拉什-塞维林县',
  Teleorman: '特列奥尔曼县',
  Tulcea: '图尔恰县',
  Gorj: '戈尔日县',
  Mehedinți: '梅赫丁茨县',
  Prahova: '普拉霍瓦县',
  Maramureș: '马拉穆列什县',
  Călărași: '克勒拉希县',
  Buzău: '布泽乌县',
  'Satu Mare': '萨图马雷县',
  Olt: '奥尔特县',
  Sălaj: '瑟拉日县',
  Vâlcea: '沃尔恰县',
  Harghita: '哈尔吉塔县',
  Brăila: '布勒伊拉县',
  Moscow: '莫斯科',
  'Saint Petersburg': '圣彼得堡',
  'Moscow Oblast': '莫斯科州',
  'Samara Oblast': '萨马拉州',
  'Saha Republic': '萨哈共和国',
  'Sverdlov Oblast': '斯维尔德洛夫斯克州',
  'Kaliningrad Oblast': '加里宁格勒州',
  'Kirov Oblast': '基洛夫州',
  'Novosibirsk Oblast': '新西伯利亚州',
  'Krasnoyarskiy Kray': '克拉斯诺亚尔斯克边疆区',
  'Tambov Oblast': '坦波夫州',
  'Lipetsk Oblast': '利佩茨克州',
  'Tver Oblast': '特维尔州',
  'Habarovskiy Kray': '哈巴罗夫斯克边疆区',
  'Tumen Oblast': '秋明州',
  'Tula Oblast': '图拉州',
  'Perm Oblast': '彼尔姆边疆区',
  'Nizhegorodskaya Oblast': '下诺夫哥罗德州',
  'Krasnodarskiy Kray': '拉斯诺达尔边疆区',
  'Voronezh Oblast': '沃罗涅日州',
  'Kemerovo Oblast': '科麦罗沃州',
  'Republic of Hakassia': '哈卡斯共和国',
  'Murmansk Oblast': '摩尔曼斯克州',
  'Komi Republic': '科米共和国',
  'Kaluga Oblast': '卡卢加州',
  'Ivanovo Oblast': '伊万诺沃州',
  'Zabaykalskiy Kray': '外贝加尔边疆区',
  'Tomsk Oblast': '托木斯克州',
  'Arkhangelsk Oblast': '阿尔汉格尔斯克州',
  'Ryazan Oblast': '梁赞州',
  'Republic of Chuvashia': '楚瓦什共和国',
  'Ulianovsk Oblast': '乌里扬诺夫斯克州',
  'Yaroslavl Oblast': '雅罗斯拉夫尔州',
  'Pensa Oblast': '奔萨州',
  'Belgorod Oblast': '别尔哥罗德州',
  'Hanty-Mansiyskiy AO': '汉特-曼西自治区',
  'Leningradskaya Oblast': '列宁格勒州',
  'Orenburg Oblast': '奥伦堡州',
  'Saratov Oblast': '萨拉托夫州',
  'Republic of Tatarstan': '鞑靼斯坦共和国',
  'Kurgan Oblast': '库尔干州',
  'Republic of Kabardino-Balkaria': '卡巴尔达-巴尔卡尔共和国',
  'Cheliabinsk Oblast': '车里雅宾斯克州',
  'Stavropolskiy Kray': '斯塔夫罗波尔边疆区',
  'Briansk Oblast': '布良斯克州',
  'Republic of Udmurtia': '乌德穆尔特共和国',
  'Novgorod Oblast': '诺夫哥罗德州',
  'Republic of Crimea': '克里米亚共和国',
  'Republic of Bashkortostan': '巴什科尔托斯坦共和国',
  'Chechen Republic': '车臣共和国',
  'Primorskiy Kray': '滨海边疆区',
  'Volgograd Oblast': '伏尔加格勒州',
  'Orel Oblast': '奥廖尔州',
  'Pskov Oblast': '普斯科夫州',
  'Rostov Oblast': '罗斯托夫州',
  'Republic of Buriatia': '布里亚特共和国',
  'Republic of Mordovia': '莫尔多瓦共和国',
  'Republic of Dagestan': '达吉斯坦共和国',
  'Sakhalin Oblast': '萨哈林州',
  'Kostroma Oblast': '科斯特罗马州',
  'Smolensk Oblast': '斯摩棱斯克州',
  'Republic of Adygeia': '阿迪格共和国',
  'Omsk Oblast': '鄂木斯克州',
  'Irkutsk Oblast': '伊尔库茨克州',
  'Amursk Oblast': '阿穆尔州',
  'Altayskiy Kray': '阿尔泰边疆区',
  'Vladimir Oblast': '弗拉基米尔州',
  'Vologda Oblast': '沃洛格达州',
  'Republic of Kalmykia': '卡尔梅克共和国',
  'Republic of Mariy El': '马里埃尔共和国',
  'Astrahan Oblast': '阿斯特拉罕州',
  'Magadan Oblast': '马加丹州',
  Sevastopol: '塞瓦斯托波尔',
  'Kursk Oblast': '库尔斯克州',
  'Republic of North Osetia-Alania': '北奥塞梯-阿兰共和国',
  'Yamalo-Nenetskiy AO': '亚马尔-涅涅茨自治区',
  'Jewish Autonomous Oblast': '犹太自治州',
  'Ingushetia Republic': '印古什共和国',
  'Kamchatskiy Kray': '堪察加边疆区',
  'Republic of Karelia': '卡累利阿共和国',
  'Republic of Karachaevo-Cherkessia': '卡拉恰伊-切尔克斯共和国',
  'Republic of Tyva': '图瓦共和国',
  'Nenetskiy Autonomous Oblast': '涅涅茨自治区',
  'Chukotskiy Autonomous Oblast': '楚科奇自治区',
  'Altai Republic': '阿尔泰共和国',
  'Eastern Province': '东部省',
  Makkah: '麦加省',
  Najran: '奈季兰省',
  'Al Bahah': '巴哈省',
  Riyadh: '利雅得省',
  Asir: '阿西尔省',
  Jizan: '吉赞省',
  Medina: '麦地那省',
  Jawf: '焦夫省',
  Tabuk: '塔布克省',
  'Northern Borders': '北部边疆省',
  Qassim: '盖西姆省',
  "Ha'il": '哈伊勒省',
  'Eastern Cape': '东开普省',
  'Free State': '自由邦省',
  Gauteng: '豪登省',
  'KwaZulu-Natal': '夸祖鲁-纳塔尔省',
  Limpopo: '林波波省',
  Mpumalanga: '普马兰加省',
  'Northern Cape': '北开普省',
  'North West': '西北省',
  'Western Cape': '西开普省',
  Unknown: '未知地区',
  Andalucía: '安达卢西亚',
  Aragón: '阿拉贡',
  Asturias: '阿斯图里亚斯',
  Baleares: '巴利阿里群岛',
  Canarias: '加那利群岛',
  Cantabria: '坎塔布里亚',
  'Castilla-La Mancha': '卡斯蒂利亚-拉曼恰',
  'Castilla y León': '卡斯蒂利亚-莱昂',
  Cataluña: '加泰罗尼亚',
  Ceuta: '休达',
  'C. Valenciana': '巴伦西亚',
  Extremadura: '埃斯特雷马杜拉',
  Galicia: '加利西亚',
  Madrid: '马德里',
  Melilla: '梅利利亚',
  Murcia: '穆尔西亚',
  Navarra: '纳瓦拉',
  'País Vasco': '巴斯克',
  Gotland: '哥特兰省',
  Dalarna: '达拉纳省',
  Västmanland: '西曼兰省',
  Norrbotten: '北博滕省',
  Kalmar: '卡尔马省',
  Gävleborg: '耶夫勒堡省',
  Örebro: '厄勒布鲁省',
  Blekinge: '布莱金厄省',
  Västernorrland: '西诺尔兰省',
  Västerbotten: '西博滕省',
  Kronoberg: '克鲁努贝里省',
  Södermanland: '南曼兰省',
  Jämtland: '耶姆特兰省',
  Östergötland: '东约特兰省',
  Värmland: '韦姆兰省',
  Uppsala: '乌普萨拉省',
  Halland: '哈兰省',
  Jönköping: '延雪平省',
  'Västra Götaland': '西约塔兰省',
  Skåne: '斯科讷省',
  Stockholm: '斯德哥尔摩省',
  Aargau: '阿尔高州',
  'Appenzell Innerrhoden': '内阿彭策尔州',
  'Appenzell Ausserrhoden': '外阿彭策尔州',
  Bern: '伯尔尼州',
  'Basel-Landschaft': '巴塞尔乡村州',
  'Basel-Stadt': '巴塞尔城市州',
  Fribourg: '弗里堡州',
  Genève: '日内瓦州',
  Glarus: '格拉鲁斯州',
  Graubünden: '格劳宾登州',
  Jura: '汝拉州',
  Luzern: '卢塞恩州',
  Neuchâtel: '纳沙泰尔州',
  Nidwalden: '下瓦尔登州',
  Obwalden: '上瓦尔登州',
  'St. Gallen': '圣加仑州',
  Schaffhausen: '沙夫豪森州',
  Solothurn: '索洛图恩州',
  Schwyz: '施维茨州',
  Thurgau: '图尔高州',
  Ticino: '提契诺州',
  Uri: '乌里州',
  Vaud: '沃州',
  Valais: '瓦莱州'
}

export {
  mapData,
  nameMap
}
