import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function ScatterWeight (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  // const myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  // eushow 3.0
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const dataList = props.data[0].data_list
  const maxXAxis = props.data[0].max_xAxis
  const legend = props.data[0].legend
  const blockName = props.data[0].block_name
  // const color = props.data[0].color

  const option = {
    title: {
      text: props.data[0].title,
      textStyle: {
        color: '#fff',
        fontSize: 14
      },
      subtext: props.data[0].sub_title,
      subtextStyle: {
        color: '#ccc',
        fontSize: 10
      }
    },
    grid: {
      top: 60,
      right: 50,
      left: 15,
      bottom: 30,
      containLabel: true
    },
    tooltip: {
      // trigger: 'axis',
      showDelay: 0,
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      formatter: function (params) {
        if (params.value.length > 1) {
          return params.seriesName + ' :<br/>' +
              params.value[0] + props.data[0].x_axis_unit +
              '  ' + params.value[1] + props.data[0].y_axis_unit
        } else {
          return params.seriesName + ' :<br/>' +
              params.name + ' : ' +
              '  ' + params.value + props.data[0].y_axis_unit
        }
      },
      axisPointer: {
        show: true,
        type: 'cross',
        lineStyle: {
          type: 'dashed',
          width: 1
        }
      }
    },
    // 始终不显示
    legend: {
      data: legend, // 页面可以设置
      right: 15,
      itemWidth: 10,
      itemHeight: 10,
      textStyle: {
        fontSize: 10,
        color: '#fff'
      }
    },
    xAxis: [
      {
        name: props.data[0].x_axis_label,
        nameLocation: props.data[0].x_ticks_namelocation,
        nameGap: props.data[0].x_ticks_namegap,
        type: 'value',
        scale: true,
        axisLabel: {
          formatter: '{value} ' + props.data[0].x_axis_unit,
          textStyle: {
            fontSize: 14,
            color: 'rgb(195,195,195)'
          }
        },
        splitLine: {
          show: false,
          lineStyle: {
            width: 1,
            color: 'rgb(195,195,195)'
          }
        },
        nameTextStyle: {
          color: '#fff'
        },
        axisLine: {
          lineStyle: {
            color: 'rgb(195,195,195)'
            // width: 1
          }
        }
      }
    ],
    yAxis: [
      {
        name: props.data[0].y_axis_label,
        type: 'value',
        scale: true,
        axisLabel: {
          formatter: '{value} ' + props.data[0].y_axis_unit,
          textStyle: {
            fontSize: 14,
            color: 'rgb(195,195,195)'
          }
        },
        splitLine: {
          show: false
        },
        nameTextStyle: {
          color: '#fff'
        },
        axisLine: {
          lineStyle: {
            color: 'rgb(195,195,195)'
            // width: 1
          }
        }
      }
    ],
    series: [
      {
        name: legend[0],
        type: 'scatter',
        data: dataList[0],
        markArea: {
          silent: true,
          itemStyle: {
            normal: {
              color: 'transparent',
              borderWidth: 1,
              borderType: 'dashed'
            }
          },
          data: [[{
            name: blockName[0],
            xAxis: 'min',
            yAxis: 'min'
          }, {
            xAxis: 'max',
            yAxis: 'max'
          }]]
        },
        markPoint: {
          data: [
            { type: 'max', name: '最大值' },
            { type: 'min', name: '最小值' }
          ]
        },
        markLine: {
          lineStyle: {
            normal: {
              type: 'solid'
            }
          },
          data: [
            { type: 'average', name: '平均值' },
            { xAxis: maxXAxis[0] }
          ]
        },
        animationDuration: 2000
      },
      {
        name: legend[1],
        type: 'scatter',
        data: dataList[1],
        markArea: {
          silent: true,
          itemStyle: {
            normal: {
              color: 'transparent',
              borderWidth: 1,
              borderType: 'dashed'
            }
          },
          data: [[{
            name: blockName[1],
            xAxis: 'min',
            yAxis: 'min'
          }, {
            xAxis: 'max',
            yAxis: 'max'
          }]]
        },
        markPoint: {
          data: [
            { type: 'max', name: '最大值' },
            { type: 'min', name: '最小值' }
          ]
        },
        markLine: {
          lineStyle: {
            normal: {
              type: 'solid'
            }
          },
          data: [
            { type: 'average', name: '平均值' },
            { xAxis: maxXAxis[1] }
          ]
        },
        animationDuration: 2000
      }
    ]
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })

  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default ScatterWeight
