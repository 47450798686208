import echarts from 'echarts'

import allChart from './all'
import { commonFunc } from '../chartCommonPart'
function PieTypeFour (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`

  const myChart = echarts.init(element.children[0], props.colorTheme)

  const frontDict = props.data[0]

  // 如果传入了具体传来的图表类型 则使用具体传入的，否则使用chart1
  if (!frontDict.barStandardSelect) {
    frontDict.barStandardSelect = 'chart1'
  }

  const option = allChart(frontDict.barStandardSelect)(frontDict)
  option.backgroundColor = 'rgba(0,0,0,0)'
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  const startPlay = commonFunc(myChart, option, props)

  return startPlay
}

export default PieTypeFour // 修改为Classname
