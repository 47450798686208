import echarts from 'echarts'
import { hideDelayTime, trigger } from '../chartCommonPart'

function MixLineBar (element, props) {
  // const colorEchartsTheme = props.data[0].color_echarts_theme
  const XAxisLabel = props.data[0].x_axis_label
  const legend = props.data[0].legend
  const YAxisUnit = props.data[0].y_axis_unit

  // 建立chart图表的容器， 在HTML中生成div标签 用来插入表格
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  // var myChart = echarts.init(div[0][0].children[0], colorEchartsTheme)
  // eushow 3.0
  const myChart = echarts.init(element.children[0], props.colorTheme)

  // 图表参数配置
  var option = {
    tooltip: {
      trigger: 'axis',
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    legend: {
      right: '12%',
      top: '2%',
      data: legend,
      itemWidth: 10,
      itemHeight: 10,
      textStyle: {
        fontSize: 10,
        color: '#fff'
      }
    },
    grid: {
      left: '5%',
      right: '5%',
      top: '10%',
      bottom: '30px',
      containLabel: true
    },
    xAxis: {
      name: XAxisLabel,
      nameLocation: 'center',
      nameGap: '30',
      type: 'category',
      data: props.data[0].category,
      axisPointer: {
        type: 'shadow'
      },
      nameTextStyle: {
        fontSize: 14,
        color: 'rgb(195,195,195)',
        align: 'left'
      },
      splitLine: {
        show: false
      },
      axisLabel: {
        textStyle: {
          fontSize: 10,
          color: '#fff'
        }
      }
    },
    yAxis: [
      {
        type: 'value',
        // name: '单位',
        // min: props.data[0].y_min,
        // max: props.data[0].y_max,
        // interval: props.data[0].y_interval,
        axisLabel: {
          formatter: '{value} ' + YAxisUnit[0],
          textStyle: {
            fontSize: 10,
            color: '#fff'
          }
        },
        splitLine: {
          show: false
        }
      },
      {
        type: 'value',
        // name: '单位',
        // min: props.data[0].x_min,
        // max: props.data[0].x_max,
        // interval: props.data[0].x_interval,
        axisLabel: {
          formatter: '{value} ' + YAxisUnit[1],
          textStyle: {
            fontSize: 10,
            color: '#fff'
          }
        },
        splitLine: {
          lineStyle: {
            width: 1,
            color: 'rgba(109,109, 109)'
          }
        }
      }
    ],
    series: [
      {
        name: legend[0],
        type: 'bar',
        data: props.data[0].first_col,
        animationDuration: 2000
      },
      {
        name: legend[1],
        type: 'bar',
        data: props.data[0].second_col,
        animationDuration: 2000
      },
      {
        name: legend[2],
        type: 'line',
        yAxisIndex: 1,
        data: props.data[0].third_col,
        animationDuration: 2000
      }
    ]
  }
  // 使用上述配置显示图标
  myChart.setOption(option)

  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    console.log(gorupByValue)
    props.onInteract(gorupByValue)
  })

  // 设置图表生成动画, 根据页面设置,确定是否开启动画
  function Play () {
    myChart.clear()
    myChart.setOption(option)
  }
  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
};

export default MixLineBar
