<template>
  <div>
    <el-dialog title="度量预警" :visible.sync="$visible" width="820px"
      :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      @opened="onOpen">
      <div class="margin-left-20 margin-right-20">
        <el-table :data="tableData" class="my-el-table" v-loading="tableLoading" element-loading-background="rgba(24,34,45,0.9)">
          <el-table-column label="预警名称" width="220px" prop="title"></el-table-column>
          <el-table-column label="创建人" prop="createdByUser"></el-table-column>
          <el-table-column label="创建时间" prop="created_at"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div class="table_icons">
                <i class="iconfont iconbianji" title="编辑" @click="onEdit(scope.row)"></i>
                <i class="iconfont iconshanchu" title="删除" @click="onDelete(scope.row, scope.$index)"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="margin-top-20">
          <el-button type="primary" size="small" @click="onCreate">新建预警</el-button>
        </div>
      </div>
      <div slot="footer">
        <el-button size="small" @click="$visible = false">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="title" :visible.sync="showFormDialog" width="600px"
      :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="margin-left-20 margin-right-20">
        <el-form :model="form" :rules="rules" ref="form" v-loading="formLoading" element-loading-background="rgba(24,34,45,0.9)"
          size="small" label-width="160px" label-position="left" :hide-required-asterisk="true">
          <el-form-item label="预警名称" prop="title">
            <el-input placeholder="请输入预警名称" v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="规则" prop="isActive">
            <el-radio-group v-model="form.isActive">
              <el-radio :label="true">开</el-radio>
              <el-radio :label="false">关</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="添加情形" prop="situation">
            <div class="situation">
              <div class="situation_item" v-for="(item, index) in form.situation" :key="index">
                <p class="situation_item_name">情形{{index + 1}}</p>
                <el-button type="text" @click="editSituation(index, item)">编辑</el-button>
                <el-button type="text" @click="deleteSituation(index)">删除</el-button>
              </div>
              <el-button type="primary" size="small" @click="addSituation">添加情形</el-button>
            </div>
          </el-form-item>
          <el-form-item label="高亮显示">
            <el-checkbox v-model="form.highLight">开启</el-checkbox>
            <el-checkbox v-show="form.highLight" v-model="form.flicker">闪烁</el-checkbox>
          </el-form-item>
          <el-form-item label="高亮颜色">
            <el-color-picker popper-class="my-el-color-picker" v-model="form.highlightColor"></el-color-picker>
          </el-form-item>
          <el-form-item label="通知间隔">
            <el-select v-model="form.noticeInterval">
              <el-option value="每1小时"></el-option>
              <el-option value="每3小时"></el-option>
              <el-option value="每6小时"></el-option>
              <el-option value="每12小时"></el-option>
              <el-option value="每天"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="通知方式">
            <div class="notify">
              <el-checkbox v-model="form.noticeWay[0].name" true-label="mail" false-label="">邮箱通知</el-checkbox>
              <el-input v-model="form.noticeWay[0].value" class="notify_input" placeholder="可输入多个邮箱地址，以分号间隔"></el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="notify">
              <el-checkbox v-model="form.noticeWay[1].name" true-label="phone" false-label="">手机通知</el-checkbox>
              <el-input v-model="form.noticeWay[1].value" class="notify_input" placeholder="可输入多个手机号码，以分号间隔"></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button size="small" @click="showFormDialog = false">取消</el-button>
        <el-button type="primary" size="small" :loading="saveLoading" @click="onSave">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="situationTitle" :visible.sync="showConditionDialog" width="600px"
      :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="condition margin-left-20 margin-right-20">
        <el-form size="small" label-width="90px" label-position="left">
          <el-form-item v-for="(item, index) in situation" :key="index" :label="`条件${index + 1}`">
            <div class="condition_item" >
              <el-cascader v-model="item.metric" class="condition_item_metrics" :options="options"></el-cascader>
              <el-select v-model="item.operator" class="condition_item_operator">
                <el-option value=">" label="大于"></el-option>
                <el-option value=">=" label="大于等于"></el-option>
                <el-option value="<" label="小于"></el-option>
                <el-option value="<=" label="小于等于"></el-option>
                <el-option value="==" label="等于"></el-option>
                <el-option value="!=" label="不等于"></el-option>
              </el-select>
              <el-input v-model="item.valueLimit" class="condition_item_value"></el-input>
              <el-button type="text" @click="deleteCondition(index)">删除</el-button>
            </div>
          </el-form-item>
          <el-form-item>
            <span class="condition_add text_size_14" @click="addCondition"><i class="iconfont iconjia"></i>新增条件</span>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button size="small" @click="showConditionDialog = false">取消</el-button>
        <el-button type="primary" size="small" @click="saveSituation">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as api from '~/api'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    chartId: String
  },
  data () {
    return {
      tableData: [],
      columns: [],
      tableLoading: false,
      formType: '', // create/edit
      showFormDialog: false,
      formLoading: false,
      form: {
        title: '', // 名称
        isActive: true, // 规则
        situation: [], // 情形
        highLight: false, // 是否开启高亮
        flicker: false, // 高亮是否闪烁
        highlightColor: '#6AFFF0', // 高亮颜色
        noticeInterval: '', // 通知间隔
        noticeWay: [
          {
            name: '', // mail
            value: ''
          },
          {
            name: '', // phone
            value: ''
          }
        ] // 通知方式
      },
      rules: {
        title: [
          { required: true, message: '请输入预警名称', trigger: 'blur' }
        ],
        isActive: [
          { required: true, message: '请选择规则开关', trigger: 'blur' }
        ],
        situation: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error('至少添加一个情形'))
              } else {
                callback()
              }
            }
          }
        ]
      },
      saveLoading: false, // 保存按钮loading
      showConditionDialog: false,
      situationIndex: -1, //
      situation: [
        // { column: '', function: '', operator: '', valueLimit: '' }
      ]
    }
  },
  computed: {
    $visible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    title () {
      let result = ''
      switch (this.formType) {
        case 'create':
          result = '创建度量预警'
          break
        case 'edit':
          result = '编辑度量预警'
          break
      }
      return result
    },
    situationTitle () {
      if (this.situationIndex === -1) {
        return '添加情形'
      } else {
        return '编辑情形'
      }
    },
    // 条件级联
    options () {
      const result = []
      const children = [
        { label: 'max', value: 'max' },
        { label: 'min', value: 'min' },
        { label: 'count', value: 'count' },
        { label: 'sum', value: 'sum' },
        { label: 'median', value: 'median' },
        { label: 'std', value: 'std' }
      ]
      for (let i = 0; i < this.columns.length; i++) {
        const element = this.columns[i]
        result.push({
          label: element,
          value: element,
          children: children
        })
      }
      return result
    }
  },
  methods: {
    // dialog打开
    onOpen () {
      this.getTableData()
      this.getColumns()
    },
    // 获取度量预警列表
    getTableData () {
      this.tableData = []
      this.tableLoading = true
      api.getWarningList({
        id: this.chartId
      }).then(res => {
        this.tableLoading = false
        if (res.data.code === 0) {
          this.tableData = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.tableLoading = false
        console.log(err)
        this.$message.error('网络错误！')
      })
    },
    // 获取预警可用的字段
    getColumns () {
      api.getWarningColumns({
        id: this.chartId
      }).then(res => {
        if (res.data.code === 0) {
          this.columns = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('网络错误！')
      })
    },
    // 点击新建预警
    onCreate () {
      this.formType = 'create'
      this.form = this.$clone(this.$options.data().form)
      this.showFormDialog = true
    },
    // 点击编辑预警
    onEdit (item) {
      this.formType = 'edit'
      this.formLoading = true
      api.getWarningDetail({
        id: item.id
      }).then(res => {
        this.formLoading = false
        if (res.data.code === 0) {
          this.form = this.$clone(res.data.data)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.formLoading = false
        console.log(err)
        this.$message.error('网络错误！')
      })
      this.showFormDialog = true
    },
    // 添加情形
    addSituation () {
      this.situationIndex = -1
      this.situation = []
      this.addCondition()
      this.showConditionDialog = true
    },
    // 编辑情形
    editSituation (index, item) {
      this.situationIndex = index
      this.situation = []
      item.forEach(element => {
        this.situation.push({
          metric: [element.column, element.function],
          operator: element.operator,
          valueLimit: element.valueLimit
        })
      })
      this.showConditionDialog = true
    },
    // 删除情形
    deleteSituation (index) {
      this.form.situation.splice(index, 1)
    },
    // 添加条件
    addCondition () {
      this.situation.push({
        metric: [],
        operator: '',
        valueLimit: ''
      })
    },
    // 删除条件
    deleteCondition (index) {
      this.situation.splice(index, 1)
      if (this.situation.length <= 1) {
        this.addCondition()
      }
    },
    // 保存情形
    saveSituation () {
      const result = []
      this.situation.forEach(element => {
        result.push({
          column: element.metric[0] || '',
          function: element.metric[1] || '',
          operator: element.operator,
          valueLimit: element.valueLimit
        })
      })
      if (this.situationIndex === -1) {
        this.form.situation.push(result)
      } else {
        this.form.situation[this.situationIndex] = result
      }
      this.showConditionDialog = false
    },
    // 点击保存预警
    onSave () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.saveConfirm()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 保存创建预警或保存编辑预警
    saveConfirm () {
      this.saveLoading = true
      if (this.formType === 'create') {
        api.createWarning({
          id: this.chartId,
          ...this.form
        }).then(res => {
          this.saveLoading = false
          if (res.data.code === 0) {
            this.$message.success('预警已创建')

            this.$refs.form.resetFields()
            this.showFormDialog = false
            // 重新获取列表
            this.getTableData()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          this.saveLoading = false
          console.log(err)
          this.$message.error('网络错误！')
        })
      } else if (this.formType === 'edit') {
        api.editWarning({
          ...this.form
        }).then(res => {
          this.saveLoading = false
          if (res.data.code === 0) {
            this.$message.success('编辑已保存')

            this.$refs.form.resetFields()
            this.showFormDialog = false
            // 重新获取列表
            this.getTableData()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          this.saveLoading = false
          console.log(err)
          this.$message.error('网络错误！')
        })
      }
    },
    // 点击删除
    onDelete (item, index) {
      this.$confirm('确定要删除该度量预警吗？删除后将无法恢复', '删除度量预警', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.deleteWarning({
          id: item.id
        }).then(res => {
          if (res.data.code === 0) {
            this.tableData.splice(index, 1)
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('网络错误！')
        })
      }).catch(() => {
        this.$message.info('操作已取消')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.table_icons {
  color: $black-color-light-10;
  display: flex;
  align-items: center;
  & > i {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin: 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.situation {
  &_item {
    height: 32px;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    &_name {
      flex: 1;
    }
  }
}
.notify {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_input {
    width: 240px;
  }
}
.condition {
  &_item {
    display: flex;
    align-items: center;
    &_metrics {
      flex: none;
      width: 160px;
    }
    &_operator {
      flex: none;
      width: 100px;
      margin: 0 10px;
    }
    &_value {
      flex: none;
      width: 130px;
      margin-right: 10px;
    }
  }
  &_add {
    cursor: pointer;
    margin: 10px 0 0 20px;
    color: #D0D2D5;
    line-height: 20px;
    & > i {
      margin-right: 4px;
      color: #CED8E3;
    }
  }
}
</style>
