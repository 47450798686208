import echarts from 'echarts'
import 'echarts/map/js/china'
import { hideDelayTime, trigger } from '../chartCommonPart'

function EffectScatterBmap (element, props) {
  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  // 初始化
  const myChart = echarts.init(element.children[0], props.colorTheme)

  const Title = props.data[0].title
  const SubTtitle = props.data[0].sub_title
  const NData = props.data[0].n_data

  const option = {
    title: {
      text: Title,
      subtext: SubTtitle,
      left: 'center'
    },
    tooltip: {
      trigger: 'item',
      triggerOn: trigger,
      hideDelay: hideDelayTime,
      // formatter:'{a}:<br>{b}{c}'
      formatter: function (params, ticket, callback) {
        // 先打印查看返回的params数据格式，再选择对象构成html显示
        let res = ''
        const title = params.seriesName
        const name = params.data.name
        const value = params.data.value[2]
        res = "<span style='color:#fff;'>" + title + '</span><br/>' + name + ':' + value
        return res
      }
    },
    geo: {
      map: 'china',
      center: [104.114129, 37.550339],
      zoom: 1, // 设置初始图表的缩放大小
      roam: true,
      label: {
        emphasis: {
          show: false // 高亮时是否显示 地区名称
        }
      }
    },
    series: [
      {
        name: 'pm2.5',
        type: 'scatter',
        coordinateSystem: 'geo', // 修改 ｇｅｏ
        data: props.data[0].all_data, // 修改数据
        symbolSize: function (val) {
          // val[2] 取到位于第3 位的value，然后计算出点的大小
          // return val[3] * 15 / props.data[0].max_value;
          return val[2] / 8
        }
      },
      {
        name: 'Top 5',
        type: 'effectScatter',
        coordinateSystem: 'geo',
        data: NData,
        symbolSize: function (val) {
          return val[2] / 3
        },
        showEffectOn: 'render',
        rippleEffect: {
          brushType: 'stroke'
        },
        hoverAnimation: true,
        label: {
          normal: {
            formatter: '{@[2]}',
            position: 'right',
            show: false
          }
        },
        // itemStyle: {
        //     normal: {
        //         color: '#ff5317',
        //         shadowBlur: 10,
        //         shadowColor: '#333'
        //     }
        // },
        zlevel: 1
      }
    ]
  }
  // 参数加载
  myChart.setOption(option)

  // 监听页面点击事件
  myChart.on('click', function (params) {
    const gorupByValue = params.name
    props.onInteract(gorupByValue)
  })
}

export default EffectScatterBmap
