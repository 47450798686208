import echarts from 'echarts'

function PieTypeFirst (element, props) {
  // console.log(props)
  // const colorEchartsTheme = props.data[0].color_echarts_theme

  element.innerHTML = `<div style="width: ${props.width}px;height: ${props.height}px;"></div>`
  const myChart = echarts.init(element.children[0], props.colorTheme)

  // const backgroundColor = props.data[0].backgroundColor
  // const lineColor = props.data[0].lineColor
  // const numberFontSize = props.data[0].numberFontSize
  const pieWidth = props.data[0].pieWidth
  const secondPieWidth = props.data[0].secondPieWidth
  const startLine = props.data[0].startLine
  const endLine = props.data[0].endLine
  const resultData = props.data[0].resultData
  // const legend = props.data[0].legend

  const splitNumber = props.data[0].splitNumber
  const sumNum = props.data[0].sumNum

  const titleValue = resultData[0].value
  const value = Math.floor((titleValue / sumNum) * 10000) / 100
  const option = {
    // backgroundColor: backgroundColor,
    title: {
      text: `${titleValue}`,
      left: 'center',
      top: 'center', // top待调整
      textStyle: {
        color: '#36FFFC',
        fontSize: 32,
        fontFamily: 'Helvetica Narrow'
      },
      itemGap: -4 // 主副标题间距
    },
    xAxis: {
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisLine: {
        show: false
      }
      // data: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    },
    yAxis: {
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisLine: {
        show: false
      }
    },
    series: [
      // 进度圈
      {
        type: 'pie',
        clockWise: true,
        radius: pieWidth,
        startAngle: -90,
        selectedOffset: false,
        hoverOffset: 10,
        hoverAnimation: false,
        data: [{
          value: value,
          name: value,
          selected: true,
          zlevel: 20,
          label: {
            show: false,
            rotate: false,
            padding: 10,
            fontSize: 20,
            distanceToLabelLine: 0
          },
          labelLine: {
            show: false,
            length: 44,
            length2: 0,
            lineStyle: {
              width: 4
            }
          },
          itemStyle: {
            borderWidth: 0,
            borderColor: '#FFFF00',
            color: '#FFFF00',
            label: {
              show: true
            }

          }
        },
        {
          value: 100 - value,
          zlevel: 11,
          labelLine: {
            show: false
          },
          itemStyle: {
            color: 'rgba(174, 238, 237, 0.3)',
            borderWidth: 0
          },
          emphasis: {
            labelLine: {
              show: false
            }
          }
        }
        ],
        zlevel: 10
      },
      {
        type: 'pie',
        clockWise: true,
        radius: secondPieWidth,
        startAngle: -90,
        selectedOffset: false,
        hoverOffset: 10,
        hoverAnimation: false,
        data: [{
          value: value,
          name: value,
          selected: true,
          zlevel: 20,
          label: {
            rotate: false,
            padding: 10,
            fontSize: 20,
            distanceToLabelLine: 0
          },
          labelLine: {
            show: true,
            length: startLine,
            length2: endLine,
            lineStyle: {
              width: 4
            }
          },
          itemStyle: {
            borderWidth: 0,
            borderColor: '#FFFF00',
            color: '#FFFF00',
            label: {
              show: true,
              fontFamily: 'Helvetica Narrow'
            }

          }
        },
        {
          value: 100 - value,
          zlevel: 11,
          labelLine: {
            show: false
          },
          itemStyle: {
            color: 'rgba(174, 238, 237, 0)',
            borderWidth: 0
          },
          emphasis: {
            labelLine: {
              show: false
            }
          }
        }
        ],
        zlevel: 10
      },
      // 刻度尺
      {
        // name: "白色圈刻度",
        type: 'gauge',
        radius: pieWidth[0],
        startAngle: 225, // 刻度起始
        endAngle: -134.8, // 刻度结束
        splitNumber: splitNumber,
        z: 4,
        axisTick: {
          show: false
        },
        splitLine: {
          length: -23, // 刻度节点线长度
          lineStyle: {
            width: 4,
            color: 'rgba(1,244,255, 0.3)'
          } // 刻度节点线
        },
        axisLabel: {
          show: false
        }, // 刻度节点文字颜色
        pointer: {
          show: false
        },
        axisLine: {
          lineStyle: {
            opacity: 0
          }
        },
        detail: {
          show: false
        },
        data: [{
          value: 0,
          name: ''
        }],
        zlevel: 1
      }
    ]
  }
  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  // 使用上述配置显示图标 ， 保留不变
  myChart.setOption(option)

  function Play () {
    myChart.clear()
    myChart.setOption(option)
    // 监听页面点击事件
    myChart.on('click', function (params) {
      const gorupByValue = params.name
      props.onInteract(gorupByValue)
    })
  }

  const mode = props.mode
  const autoPlay = props.autoPlay
  // 记录Interval的ID值，用于前端清空
  let startPlay = ''
  // var intervalCount = []
  if (mode === 'show' && autoPlay === true) {
    startPlay = setInterval(Play, 5000)
    // intervalCount.push(startPlay)
    // console.log('setInterval true :', startPlay, intervalCount)
  }
  // 返回当期 interval的ID， 没有时，返回空字符串
  return startPlay
}

export default PieTypeFirst // 修改为Classname
