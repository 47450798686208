import { xData, yData } from '../data-source/line-data'

export default function (name) {
  const seriesData = []
  const legendObj = {}
  const grid = {
    containLabel: true,
    left: 15,
    top: 30,
    bottom: 10,
    right: 0
  }
  if (name === '中国') {
    const list = yData[name]
    const nameList = ['中国大陆', '中国台湾', '中国香港', '中国澳门']
    list.forEach((item, index) => {
      seriesData.push({
        name: nameList[index],
        data: item,
        type: 'line',
        symbol: 'none'
      })
    })
    legendObj.data = nameList
    legendObj.width = 160
    grid.top = 57
  } else {
    seriesData.push({
      name: '授权专利数',
      data: yData[name],
      type: 'line',
      symbol: 'none'
    })
    legendObj.data = ['授权专利数']
  }

  const options = {
    color: ['#37A2DA', '#3CCAEC', '#73E4EA', '#A5E9CA'],
    legend: {
      show: true,
      align: 'right',
      left: 0,
      icon: 'rect',
      itemWidth: 20,
      height: 10,
      textStyle: {
        color: 'rgba(255, 255, 255, 0.8)',
        fontSize: 10
      },
      ...legendObj
    },
    tooltip: {
      show: true,
      trigger: 'axis'
    },
    grid: grid,
    xAxis: [
      {
        type: 'category',
        data: xData,
        axisTick: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 1)'
          }
        },
        axisLabel: {
          show: false
        }
      }
    ],
    yAxis: {
      axisTick: {
        show: false
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(255, 255, 255, 1)'
        }
      },
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      }
    },
    series: seriesData
  }

  return options
}
